<template>
  <div class="service-calculation">
    <FieldGroupWrapper :title="$t('Calculation.ServiceCostCalculation')">
      <CalculateBestValue
        :loading="isLoading"
        v-show="isCalculateBestValueVisible"
        :request-disabled="props.requestDisabled"
        @calculate-best-value="getCalculatePrice" />
    </FieldGroupWrapper>
    <FieldGroupWrapper v-show="isAlertContentVisible">
      <AlertContent />
    </FieldGroupWrapper>
    <FieldGroupWrapper>
      <CalculationOptimalCost v-show="isOptimalCostVisible" :calculation-data="marketCalculation" />
      <div class="service-calculation__total-cost">
        <div class="service-calculation__total-cost-title">
          {{ $t('Calculation.TotalCostService') }}
        </div>
        <div class="service-calculation__total-cost-inputs">
          <FormInfoField
            field-name="market_calculations.cost_price"
            :label="$t('Calculation.CostPrice')"
            format="price" />
          <FormTextField
            field-name="market_calculations.margin"
            :label="$t('Calculation.Markup')"
            :disabled="isFieldsDisabled"
            @input="setDebouncedMargin"
            mask-name="float-12.2-with-minus" />
          <FormTextField
            field-name="price"
            :label="$t('Calculation.TotalPrice')"
            :description="$t('Calculation.DoctorWantsAdd')"
            :disabled="isFieldsDisabled"
            @input="setDebouncedPrice"
            mask-name="int-12-with-minus" />
        </div>
      </div>
    </FieldGroupWrapper>
  </div>
</template>

<script lang="ts">
export default {
  name: 'ServiceCalculation',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import axios from 'axios';
import { ref, watch } from 'vue';
import { FormValidationResult, useField } from 'vee-validate';
import { computed } from 'vue';
import {
  CalculatePriceRequestData,
  ServiceGroupBase,
  ExpenseCategoryFormData,
} from '@/models/accounting/Service.model';
import { ExpenseProduct, MarketCalculation } from '@/types/api';
import debounce from 'lodash.debounce';

import FieldGroupWrapper from '@/components/common/form/FieldGroupWrapper/index.vue';
import FormTextField from '@/components/common/form/ui/FormTextField/index.vue';
import FormInfoField from '@/components/common/form/ui/FormInfoField/index.vue';
import CalculateBestValue from '@/components/accounting/modals/ServiceCrudModal/ServiceCalculation/CalculateBestValue/index.vue';
import AlertContent from '@/components/accounting/modals/ServiceCrudModal/ServiceCalculation/AlertContent/index.vue';
import CalculationOptimalCost from '@/components/accounting/modals/ServiceCrudModal/ServiceCalculation/CalculationOptimalCost/index.vue';

const props = defineProps<{
  preValidate: () => Promise<FormValidationResult<unknown>>;
  requestDisabled: boolean;
}>();

const isCalculateBestValueVisible = ref(true);
const isOptimalCostVisible = ref(false);
const isLoading = ref(false);
const isAlertContentVisible = ref(false);

const showOptimalCost = () => {
  isOptimalCostVisible.value = true;
};

const { value: price, setValue: setPriceValue } =
  useField<CalculatePriceRequestData['price']>('price');
const { value: change, setValue: setChangeValue } =
  useField<CalculatePriceRequestData['change']>('change');
const { value: marketCalculation, setValue: setMarketCalculations } =
  useField<MarketCalculation>('market_calculations');
const { value: expensesCategories } = useField<ExpenseCategoryFormData[]>('expenses_categories');
const { value: expensesProducts } = useField<ExpenseProduct[]>('expenses_products');

const isFieldsDisabled = computed(
  () => marketCalculation.value?.margin === null || marketCalculation.value?.margin === undefined
);

const requestPayload = computed(() => ({
  price: price.value,
  change: change.value,
  market_calculations: marketCalculation.value,
  expenses_categories: expensesCategories.value.map((item) => item).filter((f) => !f.deleted),
  expenses_products: expensesProducts.value.map((item) => item),
}));

const getCalculatePrice = async () => {
  isLoading.value = true;

  const result = await props.preValidate();
  if (!result.valid) {
    isLoading.value = false;
    return;
  }

  const response = await ServiceGroupBase.calculatePrice(requestPayload.value);
  if (axios.isAxiosError(response)) {
    if (response.response?.status === 409) isAlertContentVisible.value = true;
  } else if (response?.data) {
    setPriceValue(response.data.price);
    setMarketCalculations(response.data.market_calculations);
    isAlertContentVisible.value = false;
    showOptimalCost();
  }
  isLoading.value = false;
};

const debouncedPrice = ref('');
const debouncedMargin = ref('');
const setDebouncedMargin = debounce((value: string) => {
  if (!value) {
    return;
  }

  debouncedMargin.value = value;
}, 500);
const setDebouncedPrice = debounce((value: string) => (debouncedPrice.value = value), 500);

watch(
  () => debouncedPrice.value,
  () => {
    setChangeValue('price');
    getCalculatePrice();
  }
);

watch(
  () => debouncedMargin.value,
  () => {
    setChangeValue('margin');
    getCalculatePrice();
  }
);
</script>

<style lang="scss" scoped>
.service-calculation {
  display: flex;
  flex-direction: column;
  gap: 28px;

  &__total-cost {
    margin-top: 30px;
  }

  &__total-cost-title {
    font-size: 16px;
    font-weight: bold;
  }

  &__total-cost-inputs {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
  }

  .el-form-item {
    margin-bottom: 0;
  }
}
</style>
<i18n src="@/locales/accounting.locales.json" />
