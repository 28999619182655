<template>
  <LayoutByUserRole content-class="v-doctors-content" fix-height>
    <LayoutContentHeader class="v-doctors-content-header">
      <ElInput
        class="v-doctors-content-header__search"
        v-model="searchString"
        :placeholder="$t('Base.Search')"
        clearable
        @input="debounceInput" />
      <ElDatePicker
        class="v-doctors-content-header__date"
        v-model="date.value"
        type="daterange"
        unlink-panels
        :format="DATE_FORMAT"
        :value-format="DATE_FORMAT"
        :start-placeholder="$t('DateAndTime.StartDate')"
        :end-placeholder="$t('DateAndTime.EndDate')" />
      <UiModelsAutocompleteSearch
        class="v-doctors-content-header__specialties"
        :model-value="microdistrictIds.value"
        :model-for-use="Microdistrict"
        method-name="getItems"
        label="title"
        value="id"
        :placeholder="$t('Analytics.Mictrodistrict')"
        multiple
        clearable
        @update:modelValue="microdistrictIds.value = $event" />
    </LayoutContentHeader>
    <AnalyticsPatronageTable
      class="v-doctors-content__table"
      :data="items"
      :loading="loading"
      :total="total"
      :default-sort="defaultSort"
      @sort-change="onSortChange"
      v-model:page="page.value"
      v-model:per-page="perPage.value" />
  </LayoutByUserRole>
</template>

<script lang="ts">
export default {
  name: 'VAnalyticsPatronage',
  inheritAttrs: false,
  customOptions: {},
};
</script>
<script setup lang="ts">
import { DATE_FORMAT } from '@/config/dateAndTime.config';
import { useDatePeriod, usePage, usePerPage } from '@/hooks/query';
import { getMonthPeriod } from '@/utils/dateAndTime.utils';
import { Analytics } from '@/models/analytics/analytics.model';
import { computed, Ref, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { compareQueriesThenLoadData } from '@/utils/router.utils';
import { useQuery } from '@/hooks/useQuery.hook';
import { Microdistrict } from '@/models/patronage/Microdistrict.model';
import debounce from 'lodash.debounce';

import LayoutByUserRole from '@/components/layouts/LayoutByUserRole/index.vue';
import LayoutContentHeader from '@/components/layouts/assets/LayoutContentHeader/index.vue';
import AnalyticsPatronageTable from '@/components/analytics/tables/AnalyticsPatronageTable/index.vue';

const store = useStore();
const loading = ref(false);
const page = usePage();
const perPage = usePerPage();
const date = useDatePeriod(getMonthPeriod());
const name = useQuery({ field: 'name' });
const searchString = ref(name.value);

const debounceInput = debounce((value) => {
  name.value = value;
}, 600);

const total = computed(() => store.state.analyticsPatronage.total);
const items = computed(() => store.state.analyticsPatronage.data);
const query = computed(() => ({
  start_at: date.value[0],
  end_at: date.value[1],
  page: page.value,
  per_page: perPage.value,
  search: name.value,
  microdistrict_ids: microdistrictIds.value,
  count: defaultSort.value.prop === 'count' ? getConvertOrder() : null,
  count_success: defaultSort.value.prop === 'count_success' ? getConvertOrder() : null,
  count_cancel: defaultSort.value.prop === 'count_cancel' ? getConvertOrder() : null,
  percent_success: defaultSort.value.prop === 'percent_success' ? getConvertOrder() : null,
}));

const defaultSort: Ref<{ prop: string; order: string }> = ref({
  prop: 'percent_success',
  order: 'descending',
});

const onSortChange = ({ prop, order }: { prop: string; order: string }) => {
  defaultSort.value = { prop, order };
};

const getConvertOrder = () => {
  if (!!defaultSort.value.order) {
    return defaultSort.value.order === 'ascending' ? 'ASC' : 'DESC';
  } else {
    return null;
  }
};

const getPatronagesNurses = async () => {
  loading.value = true;
  const result = await Analytics.getPatronageNurses(query.value);
  if (result) {
    store.dispatch('analyticsPatronage/setData', {
      items: result.data,
      total: result.meta.total,
      overwriteDataState: true,
    });
  }
  loading.value = false;
};

const microdistrictIds = useQuery({ field: 'microdistrict_ids', valueIsNumberArray: true });

watch(
  query,
  (value, oldValue) => {
    compareQueriesThenLoadData({
      query: value,
      oldQuery: oldValue,
      getData: getPatronagesNurses,
      resetPage: page.reset,
      fieldsForResetPage: [
        'start_at',
        'end_at',
        'search',
        'microdistrict_ids',
        'count',
        'count_success',
        'count_cancel',
        'percent_success',
      ],
    });
  },
  { deep: true, immediate: true }
);
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/analytics.locales.json" />
<i18n src="@/locales/base.locales.json" />
