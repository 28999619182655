<template>
  <div class="add-idc-accounting-form">
    <FieldGroupWrapper>
      <FormSearchSelectField
        :label="$t('Patients.Diagnosis')"
        :placeholder="$t('Appointments.SelectIDC')"
        field-name="code"
        field-object-name="disease"
        value-key="code"
        :model-for-use="DiseaseCode"
        required />
      <FormDateField :label="$t('Patients.DateOfRegistration')" field-name="date" required />
    </FieldGroupWrapper>
    <FormActionsWrapper align="center">
      <ElButton
        type="primary"
        @click="submitForm"
        :loading="isSubmitting"
        :disabled="submitDisabled">
        {{ $t('Base.Add') }}
      </ElButton>
    </FormActionsWrapper>
  </div>
</template>

<script lang="ts">
export default {
  name: 'AddIDCAccountingForm',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { computed } from 'vue';
import { useForm } from 'vee-validate';
import { getCurrentDate } from '@/utils/dateAndTime.utils';
import { DiseaseCode } from '@/models/DiseaseCode.model';
import { object, string } from 'yup';
import { GlobalModalAction } from '@/models/client/ModalAndDrawer/GlobalModalAction';
import { I18nService } from '@/services/i18n.service';
import { AddIDCAccountingRequestDto } from '@/types/api';
import { Dispensarie } from '@/models/Dispensaries.model';

import FormActionsWrapper from '@/components/common/form/FormActionsWrapper/index.vue';
import FieldGroupWrapper from '@/components/common/form/FieldGroupWrapper/index.vue';
import FormDateField from '@/components/common/form/ui/FormDateField/index.vue';
import FormSearchSelectField from '@/components/common/form/ui/FormSearchSelectField/index.vue';

type AddIDCAccountingFormData = {
  userId: number;
  code?: string;
  date: string;
};

const validationSchema = object().shape({
  code: string().required(I18nService.t('Validation.RequiredField')),
  date: string().required(I18nService.t('Validation.RequiredField')),
});

const props = defineProps<{
  userId: number;
}>();

const emit = defineEmits(['action']);

const { isSubmitting, errors, handleSubmit } = useForm<AddIDCAccountingFormData>({
  initialValues: {
    userId: props.userId,
    date: getCurrentDate(),
  },
  validationSchema,
});

const submitDisabled = computed(() => Object.keys(errors.value).length > 0 || isSubmitting.value);

const submitForm = handleSubmit(
  async (values) => {
    const formData = values as Required<AddIDCAccountingFormData>;

    const requestData: AddIDCAccountingRequestDto = {
      user_id: formData.userId,
      disease_code: formData.code,
      registration_date: formData.date,
    };

    await Dispensarie.create(requestData);

    emit(
      'action',
      new GlobalModalAction({
        name: 'created',
      })
    );
  },
  (ctx) => {
    // eslint-disable-next-line no-console
    console.log('invalid submit', ctx.errors);
  }
);
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/patients.locales.json" />
<i18n src="@/locales/appointments.locales.json" />
<i18n src="@/locales/dateAndTime.locales.json" />
