import { onlyLoggedInMiddleware } from '@/middlewares/onlyLoggedIn.middleware';

export const TREATMENT_ROUTE = {
  name: 'DOCTORS_TREATMENT',
  path: '/treatment/:id',
  component: 'VTreatment',
  beforeEnter: [onlyLoggedInMiddleware],
  meta: {
    title: 'Base.Template',
  },
  props: true,
};

export const routes = [TREATMENT_ROUTE];
