// Не менять, в json переводах используем ru, uz, en
export const LOCALES = {
  RU: {
    id: 'ru',
    label: 'Русский',
  },
  UZ: {
    id: 'uz',
    label: 'O\'zbekcha',
  },
  EN: {
    id: 'en',
    label: 'English',
  },
};

export const DEFAULT_LOCALE = LOCALES.RU.id;
export const FALLBACK_LOCALE = LOCALES.RU.id;

export const LANG_AND_REGION = {
  [LOCALES.RU.id]: 'ru_RU',
  [LOCALES.UZ.id]: 'uz_UZ',
};
