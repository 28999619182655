export class ClientStorageService {
  /**
   * @param {string} key
   * @param {object|string} payload
   */
  static setItem(key, payload) {
    let value = payload;
    if (typeof payload === 'object' && payload !== null) {
      value = JSON.stringify(payload);
    }
    localStorage.setItem(key, value);
  }

  /**
   * @param {string} key
   * @return {object|string} value
   */
  static getItem(key) {
    let value;
    try {
      value = JSON.parse(localStorage.getItem(key));
    } catch {
      value = localStorage.getItem(key);
    }
    return value;
  }

  /**
   *  @param {string} key
   */
  static removeItem(key) {
    localStorage.removeItem(key);
  }
}
