<template>
  <LayoutByUserRole content-class="v-demographics-births" fix-height>
    <div class="v-demographics-births__header">
      <ElDatePicker
        class="v-demographics-births__date"
        v-model="date.value"
        :format="DATE_FORMAT"
        type="daterange"
        unlink-panels
        :value-format="DATE_FORMAT"
        :placeholder="$t('DateAndTime.Date')" />

      <a class="v-demographics-births__btn" :href="exportDataURL" download>
        <ElButton type="primary">
          {{ $t('Base.DownloadExcel') }}
        </ElButton>
      </a>
    </div>

    <DemographyBornsStatisticsBlock :query="statisticsQuery" />

    <DemographicsBirthsTable
      :loading="loading"
      :items="tableItems"
      :total="tableItemsTotal"
      v-model:page="page.value"
      v-model:per-page="perPage.value" />
  </LayoutByUserRole>
</template>
<script lang="ts">
export default {
  name: 'VDemographicsBirths',
  inheritAttrs: false,
  customOptions: {},
};
</script>
<script setup lang="ts">
import { DATE_FORMAT } from '@/config/dateAndTime.config';
import { useDatePeriod, usePage, usePerPage } from '@/hooks/query';
import { getMonthPeriod } from '@/utils/dateAndTime.utils';
import { computed, ref, watch } from 'vue';
import { Analytics } from '@/models/analytics/analytics.model';
import { compareQueriesThenLoadData } from '@/utils/router.utils';
import { mergeOrCreateQuery } from '@/utils/http.util';
import { deleteEmptyValueKeys } from '@/utils/object.util';
import { I18nService } from '@/services/i18n.service';
import { DeviceService } from '@/services/device.service';
import { TokenService } from '@/services/token.service';
import { BornDto } from '@/types/api';

import LayoutByUserRole from '@/components/layouts/LayoutByUserRole/index.vue';
import DemographicsBirthsTable from '@/components/analytics/demography/tables/DemographicsBirthsTable/index.vue';
import DemographyBornsStatisticsBlock from '@/components/analytics/demography/DemographyBornsStatisticsBlock/index.vue';

const date = useDatePeriod(getMonthPeriod());
const page = usePage();
const perPage = usePerPage();
const loading = ref(false);
const tableItems = ref<BornDto[]>([]);
const tableItemsTotal = ref(0);

const statisticsQuery = computed(() => ({
  start_at: date.value[0],
  end_at: date.value[1],
}));

const exportDataURL = computed(() => {
  return mergeOrCreateQuery({
    url: Analytics.exportBornDataURL,
    query: deleteEmptyValueKeys({
      ...query.value,
      lang: I18nService.getLocale(),
      token: TokenService.getAccessToken(),
      device_id: DeviceService.getDeviceId(),
    }),
  });
});

const query = computed(() => ({
  page: page.value,
  per_page: perPage.value,
  start_at: date.value[0],
  end_at: date.value[1],
}));

const getPatientsList = async () => {
  loading.value = true;

  const response = await Analytics.getBorn(query.value);

  tableItems.value = response?.data ?? [];
  tableItemsTotal.value = response?.meta.total ?? 0;

  loading.value = false;
};

watch(
  query,
  (value, oldValue) => {
    compareQueriesThenLoadData({
      query: value,
      oldQuery: oldValue,
      getData: getPatientsList,
      resetPage: page.reset,
      fieldsForResetPage: ['page', 'per_page', 'start_at', 'end_at'],
    });
  },
  { deep: true, immediate: true }
);
</script>

<style scoped lang="scss" src="./index.scss" />
