import { computed, reactive } from 'vue';
import { useRouter, useRoute } from 'vue-router';

/**
 * @typedef {Object} Params
 * @property {string} field
 * @property {(function|null)} [formatter]
 * @property {boolean} [valueIsNumber]
 * @property {boolean} [routerPush]
 * @property {boolean} [valueIsBoolean]
 * @property {boolean} [valueIsArray]
 * @property {boolean} [valueIsNumberArray]
 * @property {(number|string|null)} [defaultValue]
 */

/**
 * @param {Params} params - The {@link Params} to be played
 */
export function useQuery({
  field,
  defaultValue = null,
  formatter = null,
  valueIsNumber = false,
  valueIsBoolean = false,
  valueIsArray = false,
  valueIsNumberArray = false,
  routerPush = false, // use router.replace or router.push
}) {
  const router = useRouter();
  const route = useRoute();
  const updateRoute = routerPush ? router.push : router.replace;

  const reset = function () {
    const query = { ...route.query };
    delete query[field];
    updateRoute({ ...route, query });
  };

  const value = computed({
    get() {
      const value = (field ? route.query[field] : route.query) ?? defaultValue;

      if (valueIsNumber && value !== null && value !== undefined) {
        return +value;
      }
      if (valueIsBoolean && value !== null && value !== undefined && typeof value !== 'boolean') {
        return Boolean(+value);
      }
      if (valueIsArray && value !== null && value !== undefined && !(value instanceof Array)) {
        return value.split(',');
      }
      if (
        valueIsNumberArray &&
        value !== null &&
        value !== undefined &&
        !(value instanceof Array)
      ) {
        return value.split(',').map((item) => Number(item));
      }

      return value;
    },
    set(value) {
      if (field) {
        if (formatter) value = formatter(value);
        if (valueIsBoolean) value = Number(value);
        value = value?.toString();

        if (!value && !value?.length) return reset();

        updateRoute({
          ...route,
          query: { ...route.query, [field]: value },
        });
      }

      if (!field) {
        updateRoute({
          ...route,
          query: { ...value },
        });
      }
    },
  });

  return reactive({
    value: value,
    reset: reset,
  });
}
