﻿{
  "en": {
    "Statistic": {
      "Aborts": "Aborts",
      "AllPatients": "All patients",
      "AppointmentByTime": "Appointment by time",
      "AppointmentSources": "Clinic appointment sources",
      "AverageAppointmentTime": "Average appointment time",
      "Childbirth": "Childbirth",
      "ChildbirthWithComplications": "Childbirth with complications",
      "Completed": "Completed",
      "CompletedAppointments": "Completed appointments",
      "CompletedExamination": "Completed the examination",
      "CorrectlyCompletedAppointments": "Correctly completed appointments",
      "DidNotPass": "Did not pass",
      "DisabledFromGroupIIIToGroupII": "From Degree III to Degree II",
      "DisabledFromGroupIIToGroupI": "From Degree II to Degree I",
      "DisabledFromGroupIIToGroupIII": "From Degree II to Degree III",
      "DisabledFromGroupIToGroupII": "From Degree I to Degree II",
      "Hour": "h",
      "Income": "Income (mln. Uzbek som)",
      "Incomplete": "Incomplete",
      "LiveQueuing": "Live queuing",
      "Men": "Men",
      "Minute": "m",
      "Minutes": "Minutes",
      "MoneyAmount": "Money amount",
      "NotSuccessful": "Unsuccessful",
      "NumberOfAppointments": "Number of appointments",
      "Passed": "Passed",
      "PatientFeedback": "Patient feedback",
      "PatientWaitingTime": "Patient waiting time",
      "PersonalSite": "Personal site",
      "Phone": "Phone",
      "Pregnants": "Pregnant (ALL)",
      "ProvidedServices": "Provided services",
      "Quantity": "Quantity",
      "Remaining": "Remaining",
      "SuccessPregnants": "Successfully completed childbirth",
      "Successful": "Successful",
      "Time": "Time",
      "TimeSpentSeeingPatient": "Time spent seeing a patient",
      "TimeSpentWaitingForPatient": "Time spent waiting for a patient",
      "TopDoctorsByAmount": "Top doctors by amount",
      "TopDoctorsByCount": "Top doctors by appointments",
      "TopServicesByAmount": "Top services by amount",
      "TopServicesByCount": "Top services by receptions",
      "TotalNumberOfPatients": "Total number of patients",
      "TotalPatients": "Total patients",
      "TotalWomen": "Total women",
      "TotalPatientsInGroup": "Total patients in the group",
      "TransferDisabledFromGroupIIIToGroupII": "Transferred from Degree III to Degree II",
      "TransferDisabledFromGroupIIToGroupI": "Transferred from Degree II to Degree I",
      "TransferDisabledFromGroupIIToGroupIII": "Transferred from Degree II to Degree III",
      "TransferDisabledFromGroupIToGroupII": "Transferred from degree I to degree II",
      "TransferredFromDegreeI": "Transferred from Degree I",
      "TransferredFromDegreeII": "Transferred from Degree II",
      "TransferredFromDegreeIII": "Transferred from Degree III",
      "TransferredFromGroupIIIToGroupII": "Transferred from Group III to Group II",
      "TransferredFromGroupIIIToGroupIV": "Transferred from Group III to Group IV",
      "TransferredFromGroupIIToGroupI": "Transferred from Group II to Group I",
      "TransferredFromGroupIIToGroupIII": "Transferred from Group II to Group III",
      "TransferredFromGroupIToGroupII": "Transferred from Group I to Group II",
      "TransferredFromGroupIVToGroupIII": "Transferred from Group IV to Group III",
      "WithComplications": "With complications",
      "WithPathology": "With pathology",
      "WithoutComplications": "Without complications",
      "WithoutPathology": "Without pathology",
      "Women": "Women"
    }
  },
  "ru": {
    "Statistic": {
      "Aborts": "Аборты",
      "AllPatients": "Все пациенты",
      "AppointmentByTime": "Запись на время",
      "AppointmentSources": "Источники записи в клинику",
      "AverageAppointmentTime": "Среднее время приема",
      "Childbirth": "Роды",
      "ChildbirthWithComplications": "Роды с осложнениями",
      "Completed": "Завершенные",
      "CompletedAppointments": "Завершенные приемы",
      "CompletedExamination": "Прошли обследование",
      "CorrectlyCompletedAppointments": "Корректно завершенные приемы",
      "DidNotPass": "Не прошли",
      "DisabledFromGroupIIIToGroupII": "От степени III к степени II",
      "DisabledFromGroupIIToGroupI": "От степени II к степени I",
      "DisabledFromGroupIIToGroupIII": "От степени II к степени III",
      "DisabledFromGroupIToGroupII": "От степени I к степени II",
      "Hour": "ч",
      "Income": "Доход (млн. сумов)",
      "Incomplete": "Незавершенные",
      "LiveQueuing": "Живая очередь",
      "Men": "Мужчин",
      "Minute": "м",
      "Minutes": "Минуты",
      "MoneyAmount": "Сумма",
      "NotSuccessful": "Неуспешные",
      "NumberOfAppointments": "Количество приемов",
      "Passed": "Прошли",
      "PatientFeedback": "Отзывы пациентов",
      "PatientWaitingTime": "Время ожидания пациента",
      "PersonalSite": "Личный сайт",
      "Phone": "Телефон",
      "Pregnants": "Беременные (ВСЕ)",
      "ProvidedServices": "Оказанные услуги",
      "Quantity": "Количество",
      "Remaining": "Остальные",
      "SuccessPregnants": "Успешно завершенные роды",
      "Successful": "Успешные",
      "Time": "Время",
      "TimeSpentSeeingPatient": "Время, потраченное на прием пациента",
      "TimeSpentWaitingForPatient": "Время, потраченное на ожидание пациента",
      "TopDoctorsByAmount": "Топ врачи по сумме",
      "TopDoctorsByCount": "Топ врачи по приёмам",
      "TopServicesByAmount": "Топ услуг по сумме",
      "TopServicesByCount": "Топ услуг по приёмам",
      "TotalNumberOfPatients": "Общее количество пациентов",
      "TotalPatients": "Всего пациентов",
      "TotalWomen": "Всего женщин",
      "TotalPatientsInGroup": "Всего пациентов в группе",
      "TransferDisabledFromGroupIIIToGroupII": "Перешли от степени III к степени II",
      "TransferDisabledFromGroupIIToGroupI": "Перешли от степени II к степени I",
      "TransferDisabledFromGroupIIToGroupIII": "Перешли от степени II к степени III",
      "TransferDisabledFromGroupIToGroupII": "Перешли от степени I к степени II",
      "TransferredFromDegreeI": "Перешли в степень I",
      "TransferredFromDegreeII": "Перешли в степень II",
      "TransferredFromDegreeIII": "Перешли в степень III",
      "TransferredFromGroupIIIToGroupII": "Перешли от III группы ко II",
      "TransferredFromGroupIIIToGroupIV": "Перешли от III группы к IV",
      "TransferredFromGroupIIToGroupI": "Перешли от II группы к I",
      "TransferredFromGroupIIToGroupIII": "Перешли от II группы к III",
      "TransferredFromGroupIToGroupII": "Перешли от I группы ко II",
      "TransferredFromGroupIVToGroupIII": "Перешли от IV группы к III",
      "WithComplications": "С осложнениями",
      "WithPathology": "С патологией",
      "WithoutComplications": "Без осложнений",
      "WithoutPathology": "Без патологий",
      "Women": "Женщин"
    }
  },
  "uz": {
    "Statistic": {
      "Aborts": "Abortlar",
      "AllPatients": "Barcha bemorlar",
      "AppointmentByTime": "Vaqtga qabulga yozish",
      "AppointmentSources": "Klinikaga yozilish manbalari",
      "AverageAppointmentTime": "Qabulning o'rtacha vaqti",
      "Childbirth": "Tug’ruqlar",
      "ChildbirthWithComplications": "Murakkabliklar bilan tug'ilish",
      "Completed": "Bajarildi",
      "CompletedAppointments": "Yakunlangan qabullar",
      "CompletedExamination": "Tekshiruvdan o'tganlar",
      "CorrectlyCompletedAppointments": "To’g’ri yakunlangan qabullar",
      "DidNotPass": "O'tmadi",
      "DisabledFromGroupIIIToGroupII": "III-darajadan II-darajaga",
      "DisabledFromGroupIIToGroupI": "II-darajadan I-darajaga",
      "DisabledFromGroupIIToGroupIII": "II-darajadan III-darajaga",
      "DisabledFromGroupIToGroupII": "I-darajadan II-darajaga",
      "Hour": "soat",
      "Income": "Daromad (million so‘m)",
      "Incomplete": "Tugallanmagan",
      "LiveQueuing": "Jonli navbat",
      "Men": "Erkaklar",
      "Minute": "daqiqa",
      "Minutes": "Daqiqa",
      "MoneyAmount": "Summa",
      "NotSuccessful": "Muvaffaqiyatsiz",
      "NumberOfAppointments": "Qabullar soni",
      "Passed": "O'tdi",
      "PatientFeedback": "Bemorlarning sharhlari",
      "PatientWaitingTime": "Bemorning kutish vaqti",
      "PersonalSite": "Shaxsiy sayt",
      "Phone": "Telefon",
      "Pregnants": "Homiladorlar (HAMMA)",
      "ProvidedServices": "Ko'rsatilgan xizmatlar",
      "Quantity": "Miqdori",
      "Remaining": "Boshqalar",
      "SuccessPregnants": "Muvaffaqiyatli tugallangan tug’ruqlar",
      "Successful": "Muvaffaqiyatli",
      "Time": "Vaqt",
      "TimeSpentSeeingPatient": "Bemorni ko'rish uchun sarflangan vaqt",
      "TimeSpentWaitingForPatient": "Bemorni kutish uchun sarflangan vaqt",
      "TopDoctorsByAmount": "Top-shifokorlar narx bo‘yicha",
      "TopDoctorsByCount": "Top-shifokorlar qabul bo‘yicha ",
      "TopServicesByAmount": "Top-xizmatlar narx bo‘yicha",
      "TopServicesByCount": "Top-xizmatlar qabul bo‘yicha ",
      "TotalNumberOfPatients": "Bemorlarning umumiy soni",
      "TotalPatients": "Barcha bemorlar",
      "TotalWomen": "Barcha ayollar",
      "TotalPatientsInGroup": "Guruhdagi barcha bemorlar",
      "TransferDisabledFromGroupIIIToGroupII": "III-darajadan II-darajaga o’tgan",
      "TransferDisabledFromGroupIIToGroupI": "II-darajadan I-darajaga o’tgan",
      "TransferDisabledFromGroupIIToGroupIII": "II-darajadan III-darajaga o’tgan",
      "TransferDisabledFromGroupIToGroupII": "I-darajadan II-darajaga o’tgan",
      "TransferredFromDegreeI": "I-darajadan o’tgan",
      "TransferredFromDegreeII": "II-darajadan o'tgan",
      "TransferredFromDegreeIII": "III-darajadan o’tgan",
      "TransferredFromGroupIIIToGroupII": "III-darajadan II-darajaga o’tgan",
      "TransferredFromGroupIIIToGroupIV": "III-darajadan IV-darajaga o’tgan",
      "TransferredFromGroupIIToGroupI": "II-darajadan I-darajaga o’tgan",
      "TransferredFromGroupIIToGroupIII": "II-darajadan III-darajaga o’tgan",
      "TransferredFromGroupIToGroupII": "I-darajadan II-darajaga o’tgan",
      "TransferredFromGroupIVToGroupIII": "IV-darajadan III-darajaga o’tgan",
      "WithComplications": "Asoratlar bilan",
      "WithPathology": "Patalogiya bilan",
      "WithoutComplications": "Asoratlarsiz",
      "WithoutPathology": "Patalogiyasiz",
      "Women": "Ayollar"
    }
  }
}