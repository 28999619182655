import { I18nService } from '@/services/i18n.service';
import { array, object, string, boolean } from 'yup';

export const validationSchema = object().shape({
  payment_details: array().of(
    object().shape({
      deleted: boolean(),
      title: string().when(['deleted'], (fields, schema) => {
        if (fields[0] !== true) {
          return schema.required(I18nService.t('Validation.RequiredField'));
        }
        return schema;
      }),
      amount: string()
        .nullable()
        .transform((value) => (isNaN(value) ? null : value))
        .when(['deleted'], (fields, schema) => {
          if (fields[0] !== true) {
            return schema.required(I18nService.t('Validation.RequiredField'));
          }
          return schema;
        }),
    })
  ),
});
