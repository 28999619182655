<template>
  <div class="table-wrapper">
    <ElScrollbar class="table-wrapper__scrollbar">
      <ElTable
        :class="['table-wrapper__table', tableClass]"
        :data="data"
        :max-height="maxHeight"
        :empty-text="$t('Base.NoData')"
        :default-sort="defaultSort"
        v-bind="$attrs"
        v-loading="loading"
        ref="elTable"
        @row-click="$emit('row-click', $event)">
        <template name="empty">
          <slot name="empty"></slot>
        </template>
        <slot></slot>
      </ElTable>
    </ElScrollbar>

    <ElPagination
      v-show="!hidePagination"
      class="table-wrapper__pagination"
      :page-count="pageCount"
      :total="total"
      :current-page="page"
      :page-size="perPage"
      :page-sizes="PAGE_SIZES"
      background
      hide-on-single-page
      layout="prev, pager, next, sizes"
      @update:current-page="$emit('update:page', $event)"
      @update:page-size="$emit('update:perPage', $event)" />
  </div>
</template>

<script>
import { emitChangeFn } from 'element-plus';
import { PAGE_SIZES } from '@/config/ui.config';

export default {
  name: 'UiTableWithPagination',
  methods: { emitChangeFn },
  props: {
    loading: Boolean,
    hidePagination: Boolean,
    data: Array,
    page: Number,
    perPage: Number,
    total: Number,
    tableClass: String,
    maxHeight: Number,
    defaultSort: Object,
  },
  computed: {
    pageCount() {
      return Math.ceil(this.total / this.perPage);
    },
  },
  watch: {
    loading() {
      this.$refs.elTable.scrollTo({ top: 0, behavior: 'smooth' });
    },
  },
  setup: () => ({
    PAGE_SIZES: PAGE_SIZES,
  }),
};
</script>

<style lang="scss" src="./index.scss"></style>
