<template>
  <UiTableWithPagination
    :data="items"
    :loading="loading"
    :page="page"
    :per-page="perPage"
    :total="total"
    @update:page="$emit('update:page', $event)"
    @update:perPage="$emit('update:perPage', $event)"
    @row-click="goToPatient">
    <template #empty>
      <div class="patients-table__empty">
        <span>{{ $t('Base.NoData') }}</span>
        <ElButton v-if="!isDoctor" type="primary" @click="addPatient">
          {{ $t('Patients.AddPatient') }}
        </ElButton>
      </div>
    </template>

    <ElTableColumn
      v-if="columns?.name ?? true"
      prop="name"
      :label="$t('User.FullName')"
      column-key="name"
      width="300px">
      <template #default="{ row }">
        <UiUserAvatarInfo class="patients-table__user" :user="row" show-has-treatment />
      </template>
    </ElTableColumn>

    <ElTableColumn
      v-if="columns?.gender ?? true"
      prop="gender"
      :label="$t('User.Gender')"
      column-key="gender">
      <template #default="{ row }">
        {{ row.gender ? $t(`User.Genders.${row.gender}`) : '' }}
      </template>
    </ElTableColumn>

    <ElTableColumn
      v-if="columns?.birthdate ?? true"
      prop="birthdate"
      :label="$t('User.Birthdate')"
      column-key="birthdate" />

    <ElTableColumn
      v-if="columns?.phone ?? true"
      prop="phone"
      :label="$t('User.Phone')"
      column-key="phone" />

    <ElTableColumn
      v-if="columns?.children_count ?? true"
      prop="children_count"
      :label="$t('User.Children')"
      column-key="children_count">
      <template #default="{ row }">
        <template v-if="!row.parent_id">
          <span v-show="!!row.childrens_count"> {{ row.childrens_count }} </span>
          <ElButton
            v-show="!row.childrens_count"
            type="primary"
            plain
            @click.stop="addChildren(row)">
            <UiIcon :icon="icons.PLUS" />
          </ElButton>
        </template>
      </template>
    </ElTableColumn>

    <ElTableColumn
      v-if="columns?.actions ?? true"
      prop="actions"
      :label="$t('Base.Actions')"
      column-key="actions">
      <template #default="{ row }">
        <div class="patients-table-actions">
          <ElButton type="primary" @click.stop="makeAppointment(row)">
            {{ $t('Base.MakeAppointment') }}
          </ElButton>
        </div>
      </template>
    </ElTableColumn>
  </UiTableWithPagination>
</template>

<script>
import { mapState } from 'vuex';
import { PATIENT_ROUTE } from '@/router/patients.routes';
import * as icons from '@/enums/icons.enum.js';
import { PAGE_SIZES } from '@/config/ui.config';
import { Patient } from '@/models/Patient.model';
import { User } from '@/models/User.model';

import CreateOrEditAppointmentModal from '@/components/appointments/CreateOrEditAppointmentModal/index.vue';
import CreateOrEditPatientModal from '@/components/patients/CreateOrEditPatientModal/index.vue';
import UiTableWithPagination from '@/components/ui/UiTableWithPagination/index.vue';

export default {
  name: 'PatientsTable',
  components: { UiTableWithPagination },
  emits: ['update:perPage', 'update:page'],
  props: {
    loading: Boolean,
    /** @property { Array<Patient|object> } items */
    items: Array,
    page: Number,
    perPage: Number,
    total: Number,
    search: String,

    columns: Object,
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),

    hasItems() {
      return !!this.items.length;
    },
    pageCount() {
      return Math.ceil(this.total / this.perPage);
    },
    pageSizes() {
      return PAGE_SIZES;
    },
  },

  methods: {
    goToPatient(payload) {
      this.$router.push({
        name: PATIENT_ROUTE.name,
        params: {
          id: payload.id,
        },
      });
    },

    isDoctor() {
      return this.user.role === User.enum.roles.Doctor;
    },

    makeAppointment(payload) {
      this.$store.dispatch('modalAndDrawer/openModal', {
        component: CreateOrEditAppointmentModal,
        payload: {
          patient: payload,
        },
      });
    },
    addPatient() {
      this.$store.dispatch('modalAndDrawer/openModal', {
        component: CreateOrEditPatientModal,
        payload: {
          nameOrPhone: this.search,
        },
      });
    },

    addChildren(payload) {
      this.$store.dispatch('modalAndDrawer/openModal', {
        component: CreateOrEditPatientModal,
        payload: {
          data: new Patient({ parent: payload, parent_id: payload.id }),
        },
      });
    },
  },

  setup: () => ({ icons }),
};
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/user.locales.json" />
<i18n src="@/locales/patients.locales.json" />
