import axios from 'axios';
import { EmitterService } from '@/services/emitter.service';
import { API_LOGOUT_EMIT } from '@/enums/emits.enum';
import { TokenService } from './token.service';

const instance = axios.create({
  mode: 'no-cors',
  baseURL: import.meta.env.VITE_API_URL,
  headers: {
    'Content-Type': 'application/json;charset=utf-8',
  },
});

instance.getToken = () => instance.defaults.headers.common.Authorization?.split(' ')[1];
instance.setToken = (token) => {
  instance.defaults.headers.common.Authorization = `Bearer ${token}`;
};
instance.setDeviceId = (deviceId) => {
  instance.defaults.headers.common['Device-Id'] = deviceId;
};
instance.removeToken = () => {
  delete instance.defaults.headers.common.Authorization;
};

instance.setLocale = (locale) => {
  instance.defaults.headers.common.Lang = locale;
};
instance.removeLocale = () => {
  delete instance.defaults.headers.common.Lang;
};

instance.interceptors.request.use(
  async (config) => {
    // Удаляем лишние передние символы '/'
    const newConfig = {
      ...config,
      url: config.url.replace(/^\/*(.*)/gi, (str, path) => `/${path}`),
    };

    if (instance._isTokensUptating) {
      await instance._uptatingPromise;
      newConfig.headers.common.Authorization = `Bearer ${TokenService.getAccessToken()}`;
    } else if (TokenService.checkNeedRefreshTokens()) {
      instance._isTokensUptating = true;
      instance._uptatingPromise = new Promise((resolve) => {
        TokenService.refreshTokens().then(() => {
          instance._isTokensUptating = false;
          resolve();
        });
      });

      await instance._uptatingPromise;
      newConfig.headers.common.Authorization = `Bearer ${TokenService.getAccessToken()}`;
    }

    return newConfig;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  async (res) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return res;
  },
  async (err) => {
    if (err?.response?.status === 401) {
      // В проекте нету системы di, поэтому местами пользуемся глобальным емитером
      EmitterService.emit(API_LOGOUT_EMIT);
    }
    if (err?.response?.status === 403) {
      // store.dispatch("auth/logout");
    }

    return Promise.reject(err);
  }
);
instance._isTokensUptating = false;
instance._updatingPromise = null;

export { instance as ApiService };
