<template>
  <LayoutByUserRole content-class="v-outcome-content" fix-height>
    <LayoutContentHeader>
      <!-- Date -->
      <ElDatePicker
        class="v-doctors-content-header__date"
        v-model="date.value"
        type="daterange"
        unlink-panels
        :format="DATE_FORMAT"
        :value-format="DATE_FORMAT"
        :start-placeholder="$t('DateAndTime.StartDate')"
        :end-placeholder="$t('DateAndTime.EndDate')" />

      <!-- Type Expense -->
      <UiModelsAutocompleteSearch
        class="v-hospital-header__department"
        :model-value="expense.value.id"
        :model-for-use="Expense"
        method-name="getExpenseTypes"
        label="title"
        :default-item="expense.value"
        :placeholder="$t('Outcome.FlowType')"
        clearable
        @select="expense.value = $event" />

      <!-- Status -->
      <UiSelect
        :options="statusOptions"
        v-model="statusSelect.value"
        :placeholder="$t('Base.Status')" />

      <!-- Search recipient-->
      <ElInput
        class="v-hospital-header__department"
        v-model="recipient.value"
        :placeholder="$t('Outcome.Recipient')"
        clearable />

      <template #actions>
        <ElButton type="primary" @click="onCreateButtonClick">
          {{ `+ ${$t('Outcome.CreateOutcome')}` }}
        </ElButton>
      </template>
    </LayoutContentHeader>

    <AccountingOutcomesTable
      :loading="loading"
      :items="items"
      :total="total"
      @approve="onApproveBtnClick"
      @cancel="onCancelBtnClick"
      v-model:page="page.value"
      v-model:per-page="perPage.value" />
  </LayoutByUserRole>
</template>

<script lang="ts">
export default {
  name: 'VAccountingOutcome',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import axios from 'axios';
import { ref, computed, watch, reactive } from 'vue';
import { I18nService } from '@/services/i18n.service';
import { ElNotification } from 'element-plus';
import { useStore } from 'vuex';
import { useDatePeriod, usePerPage, usePage } from '@/hooks/query';
import { compareQueriesThenLoadData } from '@/utils/router.utils';
import { DATE_FORMAT } from '@/config/dateAndTime.config';
import { getMonthPeriod } from '@/utils/dateAndTime.utils';
import { Expense } from '@/models/accounting/Expense.model';
import { GlobalModalCloseAction } from '@/models/client/ModalAndDrawer/GlobalModalCloseAction';
import { useModel } from '@/hooks/useModel';
import { useQuery } from '@/hooks/useQuery.hook';
import { ExpenseStatus } from '@/types/api';

import LayoutByUserRole from '@/components/layouts/LayoutByUserRole/index.vue';
import LayoutContentHeader from '@/components/layouts/assets/LayoutContentHeader/index.vue';
import AccountingOutcomesTable from '@/components/accounting/tables/AccountingOutcomesTable/index.vue';
import UiModelsAutocompleteSearch from '@/components/ui/UiModelsAutocompleteSearch/index.vue';
import UiSelect from '@/components/ui/UiSelect/index.vue';
import ServiceOutcomeModal from '@/components/accounting/modals/ServiceOutcomeModal/Index.vue';

const statusOptions = computed<{ label: string; value: ExpenseStatus }[]>(() => [
  {
    label: I18nService.t('Income.Statuses.accepted'),
    value: 'accepted',
  },
  { label: I18nService.t('Income.Statuses.canceled'), value: 'canceled' },
  { label: I18nService.t('Income.Statuses.created'), value: 'created' },
]);

const statusSelect = reactive<{
  value: string;
}>({ value: '' });

const store = useStore();
const loading = ref(false);
const page = usePage();
const perPage = usePerPage();
const recipient = useQuery({ field: 'recipient' });

const date = useDatePeriod(getMonthPeriod());

const expense = useModel({
  fieldNames: {
    id: 'expense_id',
    name: 'expense_name',
  },
});

const total = computed(() => store.state.accountingOutcomesTable.total);
const items = computed(() => store.state.accountingOutcomesTable.data);
const query = computed(() => ({
  page: page.value,
  per_page: perPage.value,
  start_at: date.value[0],
  end_at: date.value[1],
  expense_type_id: expense.value.id,
  recipient: recipient.value,
  status: statusSelect.value,
}));

const getExpenses = async () => {
  loading.value = true;
  const result = await Expense.getItems(query.value);

  if (result) {
    store.dispatch('accountingOutcomesTable/setData', {
      items: result.data,
      total: result.response.data.meta.total,
      overwriteDataState: true,
    });
  }

  loading.value = false;
};

watch(
  query,
  (value, oldValue) => {
    compareQueriesThenLoadData({
      query: value,
      oldQuery: oldValue,
      getData: getExpenses,
      resetPage: page.reset,
    });
  },
  { deep: true, immediate: true }
);

const onCreateButtonClick = async () => {
  const action = await store.dispatch('modalAndDrawer/openModal', ServiceOutcomeModal);

  if (!(action instanceof GlobalModalCloseAction)) {
    getExpenses();
  }
};

const onApproveBtnClick = async (id: number) => {
  await store.dispatch('accountingOutcomesTable/setItemLoading', {
    id: id,
    loading: { accept: true },
  });
  try {
    const { data } = await Expense.acceptItem(id);
    store.dispatch('accountingOutcomesTable/editItem', data);
  } catch (err) {
    ElNotification({
      type: 'error',
      title: axios.isAxiosError(err) ? err.message : I18nService.t('Notifications.Error'),
    });
  } finally {
    await store.dispatch('accountingOutcomesTable/setItemLoading', {
      id: id,
      loading: { accept: false },
    });
  }
};

const onCancelBtnClick = async (id: number) => {
  await store.dispatch('accountingOutcomesTable/setItemLoading', {
    id: id,
    loading: { cancel: true },
  });
  try {
    const { data } = await Expense.cancelItem(id);
    store.dispatch('accountingOutcomesTable/editItem', data);
  } catch (err) {
    ElNotification({
      type: 'error',
      title: axios.isAxiosError(err) ? err.message : I18nService.t('Notifications.Error'),
    });
  } finally {
    await store.dispatch('accountingOutcomesTable/setItemLoading', {
      id: id,
      loading: { cancel: false },
    });
  }
};
</script>

<style lang="scss"></style>
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/warehouse.locales.json" />
<i18n src="@/locales/dateAndTime.locales.json" />
<i18n src="@/locales/accounting.locales.json" />
