<template>
  <LayoutByUserRole content-class="v-order" :loading="loading.order">
    <template v-if="order.id">
      <PatientCardRow
        class="v-order__patient-card-row"
        :patient="order?.user"
        :items="patientCardItems" />

      <LayoutContentHeader :title="$t('Laboratory.Results')">
        <template #actions>
          <ElButton
            v-show="actionsVisible.rePickReferencesGroup"
            type="primary"
            plain
            :loading="loading.rePickReferencesGroup"
            @click="rePickReferencesGroup">
            {{ $t('Laboratory.RePickReferencesGroup') }}
          </ElButton>

          <ElButton
            v-show="actionsVisible.addBiomaterial"
            type="primary"
            :loading="loading.biomaterial"
            @click="addBiomaterial">
            {{ $t('Laboratory.Biomaterial.AddBiomaterial') }}
          </ElButton>
        </template>
      </LayoutContentHeader>

      <OrderBiomaterialDataActionsCard
        v-for="biomaterial in order.biomaterials"
        :key="biomaterial.id"
        :biomaterial="biomaterial"
        :studys="biomaterial.studys"
        v-model:order="order"
        :editable="editable"
        v-loading="loading.study && loadingStudyBiomaterialId === biomaterial.id"
        @update:studys="localUpdateStudiesByBiomaterialId($event, biomaterial.id)"
        @study:create="localCreateStudyByBiomaterialId($event, biomaterial.id)"
        @study:update="localUpdateStudyByBiomaterialId($event, biomaterial.id)"
        @study:update:api="updateStudyByBiomaterialId($event, biomaterial.id)"
        @study:delete="localDeleteStudyByBiomaterialId($event, biomaterial.id)"
        @biomaterial:delete="localDeleteBiomaterial(biomaterial.id)"
        @biomaterial:update="localUpdateBiomaterial($event, biomaterial.id)"
        @editor:change="saveEditorData($event, biomaterial.id)"
        @editor:update="updateEditorData($event, biomaterial.id)" />

      <div class="v-order__actions v-order-actions">
        <ElButton text @click="print">
          <template #icon> <UiIcon :icon="icons.PRINTER" /> </template>
          {{ $t('Base.Print') }}
        </ElButton>
      </div>

      <OrderPrinterDocument :order="order" ref="orderPrinterDocument" />
    </template>
  </LayoutByUserRole>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';
import { Biomaterial } from '@/models/laboratory/Biomaterial.model';
import { Order } from '@/models/laboratory/Order.model';
import { Study } from '@/models/laboratory/Study.model';
import { User } from '@/models/User.model';
import { GlobalModalCloseAction } from '@/models/client/ModalAndDrawer/GlobalModalCloseAction';

import LayoutByUserRole from '@/components/layouts/LayoutByUserRole/index.vue';
import PatientCardRow from '@/components/patients/PatientCardRow/index.vue';
import OrderBiomaterialDataActionsCard from '@/components/laboratory/OrderBiomaterialDataActionsCard/index.vue';
import OrderPrinterDocument from '@/components/laboratory/OrderPrinterDocument/index.vue';
import LayoutContentHeader from '@/components/layouts/assets/LayoutContentHeader/index.vue';
import CreateBiomaterialModal from '@/components/laboratory/CreateBiomaterialModal/index.vue';
import * as icons from '@/enums/icons.enum';

export default {
  name: 'VOrder',
  components: {
    LayoutContentHeader,
    OrderPrinterDocument,
    OrderBiomaterialDataActionsCard,
    PatientCardRow,
    LayoutByUserRole,
  },
  props: {
    id: [Number, String],
  },
  data() {
    return {
      /** @type {Order|object} order */
      order: new Order(),
      loading: {
        order: false,
        study: false,
        rePickReferencesGroup: false,
      },

      loadingStudyBiomaterialId: null,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),

    patientCardItems() {
      return [
        {
          label: this.$t('User.Phone'),
          value: this.order?.user?.phone,
        },
        {
          label: this.$t('User.Birthdate'),
          value: this.order?.user?.birthdate,
        },
        {
          label: this.$t('User.Gender'),
          value: this.order?.user?.gender
            ? this.$t(`User.Genders.${this.order?.user?.gender}`)
            : null,
        },
        {
          label: this.$t('Base.Services'),
          value:
            this.order.appointment?.services?.map((service) => service.title).join(',\n') ?? '...',
        },
      ];
    },

    editable() {
      return this.user.role === User.enum.roles.Laboratory;
    },

    actionsVisible() {
      return {
        rePickReferencesGroup: this.editable && this.order?.status !== Order.enum.statuses.Canceled,
        addBiomaterial: this.editable && this.order.status !== Order.enum.statuses.Canceled,
      };
    },
  },
  watch: {
    id: {
      handler() {
        this.getOrder();
      },
      immediate: true,
    },
  },

  methods: {
    async getOrder() {
      this.loading.order = true;

      const { order } = await Order.findOneById(this.id);
      this.order = order;

      this.loading.order = false;
    },

    async saveEditorData(payload, id) {
      const index = this.order.biomaterials.findIndex((f) => f.id === id);
      try {
        const { data } = await Biomaterial.updateBiomaterialById(id, { basic_data: payload });
        this.order.biomaterials[index].basic_data = data.data;
      } catch (err) {
        this.$notify({
          type: 'error',
          title: axios.isAxiosError(err) ? err.message : String(err),
        });
      }
    },

    updateEditorData(payload, id) {
      const index = this.order.biomaterials.findIndex((f) => f.id === id);
      this.order.biomaterials[index].basic_data = payload;
    },

    async updateStudyByBiomaterialId(study, biomaterialId) {
      this.loading.study = true;
      this.loadingStudyBiomaterialId = biomaterialId;

      try {
        await Study.update({ id: study.id, payload: study });
        this.localUpdateStudyByBiomaterialId(study, biomaterialId);
      } catch (err) {
        this.$notify({
          type: 'error',
          title: axios.isAxiosError(err) ? err.message : String(err),
        });
      }

      this.loading.study = false;
      this.loadingStudyBiomaterialId = null;
    },

    localCreateStudyByBiomaterialId(study, biomaterialId) {
      const index = this._getBiomaterialIndexById(biomaterialId);

      this.order.biomaterials[index].study_ids.push(study.id);
      this.order.biomaterials[index].studys.push(study);
    },

    localUpdateStudiesByBiomaterialId(studies, biomaterialId) {
      const index = this._getBiomaterialIndexById(biomaterialId);
      this.order.biomaterials[index].studys = studies;
    },

    localUpdateStudyByBiomaterialId(study, biomaterialId) {
      const biomaterialIndex = this._getBiomaterialIndexById(biomaterialId);
      const studyIndex = this._getStudyIndexByIdAndBiomaterialIndex(study.id, biomaterialIndex);

      this.order.biomaterials[biomaterialIndex].studys[studyIndex] = study;
    },

    localDeleteStudyByBiomaterialId(study, biomaterialId) {
      const biomaterialIndex = this._getBiomaterialIndexById(biomaterialId);

      this.order.biomaterials[biomaterialIndex].study_ids = this.order.biomaterials[
        biomaterialIndex
      ].study_ids.filter((id) => id !== study.id);
      this.order.biomaterials[biomaterialIndex].studys = this.order.biomaterials[
        biomaterialIndex
      ].studys.filter((item) => item.id !== study.id);
    },

    async addBiomaterial() {
      const action = await this.$store.dispatch('modalAndDrawer/openModal', {
        component: CreateBiomaterialModal,
        payload: {
          orderId: this.order.id,
        },
      });

      if (!(action instanceof GlobalModalCloseAction)) {
        this.order.biomaterial_ids.push(action.data.biomaterial.id);
        this.order.biomaterials.push(action.data.biomaterial);
      }
    },

    localDeleteBiomaterial(id) {
      this.order.biomaterial_ids = this.order.biomaterial_ids.filter(
        (biomaterialId) => biomaterialId !== id
      );
      this.order.biomaterials = this.order.biomaterials.filter(
        (biomaterial) => biomaterial.id !== id
      );
    },

    localUpdateBiomaterial(biomaterial, biomaterialId) {
      const index = this._getBiomaterialIndexById(biomaterialId);
      this.order.biomaterials[index] = biomaterial;
    },

    async rePickReferencesGroup() {
      if (this.loading.rePickReferencesGroup) return;
      this.loading.rePickReferencesGroup = true;

      try {
        const { order } = await Order.rePickStudiesReferencesGroup(this.order.id);
        this.order = order;
        this.$notify({
          type: 'success',
          title: this.$t('Notifications.SuccessUpdated'),
        });
      } catch (err) {
        this.$notify({
          type: 'error',
          title: axios.isAxiosError(err) ? err.message : String(err),
        });
      }

      this.loading.rePickReferencesGroup = false;
    },

    print() {
      this.$refs.orderPrinterDocument.print();
    },

    _getBiomaterialIndexById(id) {
      return this.order.biomaterials.findIndex((biomaterial) => biomaterial.id === id);
    },
    _getStudyIndexByIdAndBiomaterialIndex(id, biomaterialIndex) {
      return this.order.biomaterials[biomaterialIndex].studys.findIndex((item) => item.id === id);
    },
  },

  setup: () => ({ icons }),
};
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/user.locales.json" />
<i18n src="@/locales/laboratory.locales.json" />
<i18n src="@/locales/notifications.locales.json" />
