import { onlyLoggedInMiddleware } from '@/middlewares/onlyLoggedIn.middleware';
import { byUserRolesMiddleware } from '@/middlewares/byUserRoles.middleware';
import { User } from '@/models/User.model';

export const SERVICES_ROUTE = {
  name: 'SERVICES',
  path: '/services',
  component: 'VServices',
  beforeEnter: [
    onlyLoggedInMiddleware,
    byUserRolesMiddleware([User.enum.roles.Director, User.enum.roles.Manager]),
  ],
  meta: {
    title: 'Views.Services.Title',
  },
};

export const routes = [SERVICES_ROUTE];
