<template>
  <ElDialog
    width="515px"
    class="add-pregnancy-record-modal"
    :model-value="modelValue"
    :title="$t('Patients.AddPregnancy')"
    v-bind="$attrs"
    @update:model-value="$emit('update:modelValue', $event)">
    <VeeForm class="add-pregnancy-record-__orm" as="ElForm"
      ><AddPregnancyForm :user-id="userId" @close:modal="$emit('update:modelValue')"
    /></VeeForm>
  </ElDialog>
</template>

<script lang="ts">
export default {
  name: 'AddPregnancyAccountingModal',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { Form as VeeForm } from 'vee-validate';

import AddPregnancyForm from '@/components/patients/modals/AddPregnancyAccountingModal/AddPregnancyForm/index.vue';

defineProps<{
  modelValue: boolean;
  userId: number;
}>();

defineEmits(['update:modelValue']);
</script>

<i18n src="@/locales/dateAndTime.locales.json" />
<i18n src="@/locales/patients.locales.json" />
