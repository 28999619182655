<template>
  <ElTable class="treatment-drugs-table" :data="filteredItems" v-bind="$attrs">
    <ElTableColumn prop="title" :label="$t('Base.Naming')" />

    <ElTableColumn prop="count" :label="$t('Base.Days')">
      <template #default="{ row }">
        <span v-if="!editable">{{ row.count }}</span>
        <ElInput
          v-if="editable"
          :model-value="row.count"
          type="number"
          min="1"
          @update:model-value="$emit('drug:update', { ...row, count: $event })"
          @change="$emit('drug:change', row)" />
      </template>
    </ElTableColumn>

    <ElTableColumn props="count_drink" :label="$t('Base.Used')">
      <template #default="{ row }">
        {{ row.count_drink }}
      </template>
    </ElTableColumn>
    <ElTableColumn prop="is_patient_product" :label="$t('Base.WhereFrom')">
      <template #default="{ row }">
        <ElCheckbox
          v-if="editable"
          :model-value="row.is_patient_product"
          @update:model-value="$emit('drug:update', { ...row, is_patient_product: $event })"
          @change="$emit('drug:change', row)">
          {{ $t('Base.FromWarehouse') }}
        </ElCheckbox>
        <template v-else>{{
          row.is_patient_product ? $t('Base.FromWarehouse') : $t('Treatments.PatientBring')
        }}</template>
      </template>
    </ElTableColumn>
    <ElTableColumn v-if="deletable" width="70px">
      <template #default="{ row }">
        <ElButton type="danger" text size="small" @click="$emit('drug:remove', row)">
          <template #icon>
            <UiIcon :icon="icons.CANCEL" />
          </template>
        </ElButton>
      </template>
    </ElTableColumn>
  </ElTable>
</template>

<script>
import * as icons from '@/enums/icons.enum.js';

export default {
  name: 'TreatmentDrugsTable',
  emits: ['drug:remove', 'drug:update', 'drug:change'],
  props: {
    /** @type {Array<TreatmentDrug|object>} */
    items: Array,
    editable: Boolean,
    deletable: Boolean,
    showIsDeleted: Boolean,
  },
  computed: {
    filteredItems() {
      return this.showIsDeleted ? this.items : this.items.filter((drug) => !drug.is_deleted);
    },
  },

  setup: () => ({ icons }),
};
</script>

<style lang="scss" src="./index.scss" />
