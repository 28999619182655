import { onlyLoggedInMiddleware } from '@/middlewares/onlyLoggedIn.middleware';
import { byUserRolesMiddleware } from '@/middlewares/byUserRoles.middleware';
import { User } from '@/models/User.model';

export const ACCOUNTING_PATH_PREFIX = '/accounting';

export const ACCOUNTING_ROUTE_OUTCOME = {
  name: 'ACCOUNTING_ROUTE_OUTCOME',
  path: `${ACCOUNTING_PATH_PREFIX}/outcome`,
  component: 'VAccountingOutcome',
  beforeEnter: [onlyLoggedInMiddleware],
  meta: {
    title: 'Outcome.Expenses',
  },
};

export const ACCOUNTING_ROUTE_SERVICES = {
  name: 'ACCOUNTING_ROUTE_SERVICES',
  path: `${ACCOUNTING_PATH_PREFIX}/services`,
  component: 'VServices',
  beforeEnter: [
    onlyLoggedInMiddleware,
    byUserRolesMiddleware([User.enum.roles.Director, User.enum.roles.Manager]),
  ],
  meta: {
    title: 'Views.Services.Title',
  },
};

export const routes = [ACCOUNTING_ROUTE_OUTCOME, ACCOUNTING_ROUTE_SERVICES];
