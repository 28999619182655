<template>
  <LayoutDefault v-bind="$attrs">
    <template #header>
      <DefaultHeader />
    </template>

    <template #default>
      <slot></slot>
    </template>
  </LayoutDefault>
</template>

<script lang="ts">
export default {
  name: 'LayoutNurse',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script setup lang="ts">
import LayoutDefault from '@/components/layouts/LayoutDefault/index.vue';
import DefaultHeader from '@/components/layouts/assets/DefaultHeader/index.vue';
</script>
