<template>
  <div class="calculate-best-value">
    <div class="calculate-best-value-content">
      {{ $t('Calculation.BestCost') }}
      <p>{{ $t('Calculation.CompetitorPrice') }}</p>
    </div>
    <ElButton
      :loading="props.loading"
      :disabled="props.requestDisabled"
      class="calculate-best-value-button"
      type="primary"
      @click="$emit('calculate-best-value')"
      >{{ $t('Calculation.CalculateBestValue') }}
    </ElButton>
  </div>
</template>

<script lang="ts">
export default {
  name: 'CalculateBestValue',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
const props = defineProps<{
  loading: boolean;
  requestDisabled: boolean;
}>();
</script>

<style lang="scss" scoped>
.calculate-best-value {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &-content {
    p {
      display: inline;
      color: var(--text-color-purple);
      font-size: 16px;
      font-weight: bold;
      margin-right: 8px;
    }
  }

  &-button {
  }
}
</style>
<i18n src="@/locales/accounting.locales.json" />
