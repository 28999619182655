<template>
  <UiTableWithPagination
    :data="items"
    :loading="loading"
    :page="page"
    :per-page="perPage"
    :total="total"
    @update:page="$emit('update:page', $event)"
    @update:per-page="$emit('update:perPage', $event)">
    <ElTableColumn prop="title" :label="$t('Supplier.NameSupplier')" />
    <ElTableColumn prop="phone" :label="$t('Supplier.PhoneNumber')" />
    <ElTableColumn prop="contact_person" :label="$t('Supplier.ContactPerson')" />
    <ElTableColumn prop="description" :label="$t('Nomenclature.Notes')" />
    <ElTableColumn :label="$t('Nomenclature.Actions')" width="150px">
      <template #default="{ row }">
        <ElButton size="small" type="primary" plain @click.stop="$emit('modal:open', row)"
          >{{ $t('Nomenclature.Edit') }}
        </ElButton>
      </template>
    </ElTableColumn>
  </UiTableWithPagination>
</template>
<script lang="ts">
export default {
  name: 'SuppliersTable',
  inheritAttrs: false,
  customOptions: {},
};
</script>
<script setup lang="ts">
import { SupplierDto } from '@/types/api';

import UiTableWithPagination from '@/components/ui/UiTableWithPagination/index.vue';

defineEmits(['update:page', 'update:perPage', 'modal:open']);

type Props = {
  loading: boolean;
  items: SupplierDto[];
  page: number;
  perPage: number;
  total: number;
};

defineProps<Props>();
</script>

<style lang="scss" scoped></style>
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/warehouse.locales.json" />
