<template>
  <UiTableWithPagination
    :data="items"
    :loading="loading"
    :page="props.page"
    :per-page="props.perPage"
    @update:page="$emit('update:page', $event)"
    @update:per-page="$emit('update:perPage', $event)"
    :default-sort="defaultSort"
    @sort-change="onSortChange">
    <ElTableColumn prop="title" :label="$t('Analytics.District')" />
    <ElTableColumn prop="count" :label="$t('Analytics.CountOfRounds')" sortable="custom" />
    <ElTableColumn prop="success" :label="$t('Analytics.Done')" sortable="custom" />
    <ElTableColumn prop="cancel" :label="$t('Analytics.Canceled')" sortable="custom" />

    <ElTableColumn
      prop="percent_success"
      :label="$t('Analytics.CompletedRounds')"
      width="250"
      sortable="custom">
      <template #default="{ row }">
        <div class="column">
          {{ row.percent_success ? row.percent_success : 0 }}%
          <div class="wrapper-bar">
            <div
              :style="{ width: `${row.percent_success ?? 0}%` }"
              :class="getBarClass(row.percent_success)"></div>
          </div>
        </div>
      </template>
    </ElTableColumn>
  </UiTableWithPagination>
</template>

<script lang="ts">
export default {
  name: 'AnalyticsMicrodistrictsTable',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { AnalyticsMicrodistricsShortDto } from '@/types/api';

import UiTableWithPagination from '@/components/ui/UiTableWithPagination/index.vue';
import { SortType } from '@/types/common';

const props = defineProps<{
  loading: boolean;
  items: AnalyticsMicrodistricsShortDto[];
  total: number;
  page: number;
  perPage: number;
  defaultSort: SortType;
}>();

const emit = defineEmits(['sort-change']);

const onSortChange = ({ prop, order }: SortType) => {
  emit('sort-change', { prop, order });
};

const getBarClass = (percent: number) => {
  if (percent > 89) {
    return 'bar green';
  } else if (percent > 20) {
    return 'bar yellow';
  } else {
    return 'bar red';
  }
};
</script>
