﻿{
  "en": {
    "Analytics": {
      "Aborts": "Aborts",
      "AgeFrom": "Age from",
      "Analysis": "Analysis",
      "Analytics": "Analytics",
      "Analyzes": "Analyzes",
      "DateOfTransfer": "Date of transfer",
      "Birthrate": "Birthrate",
      "Canceled": "Canceled",
      "ChildDeath": "Death of a child",
      "Children": "Children",
      "CompletedRounds": "Completed inspections",
      "Complications": "Complications",
      "CountOfRounds": "Count of inspections",
      "Demography": "Demography",
      "Deregistered": "Deregistered",
      "Deregistration": "Deregistration",
      "Diagnoses": "Diagnoses",
      "Diagnosis": "Diagnosis",
      "DiagnosisSearch": "Search for a diagnosis",
      "DisabledPeople": "Disabled people",
      "DispensariesAccounting": "On D-Count",
      "DispensariesAccountingNew": "On D-Count (new)",
      "DispensariesDeregistration": "Deregistered from the D-count",
      "Dispensarization": "Dispensarization",
      "District": "District",
      "Done": "Done",
      "Group": "Group",
      "HaveDied": "Have died",
      "Map": "Map",
      "Men": "Men",
      "Mictrodistrict": "Microdistrict",
      "Mortality": "Mortality",
      "MotherDeath": "Death of a mother",
      "New": "New",
      "NumberRounds": "Number of rounds",
      "PatientGroups": {
        "first_group": "1st group of patients",
        "fourth_group": "4th group of patients",
        "second_group": "2nd group of patients",
        "third_group": "3rd group of patients"
      },
      "PatientStatus": "Patient status",
      "Patronage": "Patronage",
      "People1stDegreeDisability": "1st degree disability",
      "People2ndDegreeDisability": "2nd degree disability",
      "People3rdDegreeDisability": "3rd degree disability",
      "Percent": "Percent",
      "Pregnants": "Pregnants",
      "Qty": "Quantity",
      "Quantity": "Quantity",
      "Registered": "Registered",
      "Retakes": "Retakes",
      "ScheduledVaccinations": "Scheduled vaccinations",
      "SuccessСhildbirth": "Successful births",
      "To": "to",
      "Vaccinated": "Vaccinated",
      "Vaccination": "Vaccination",
      "VaccinationTitle": "Vaccine name",
      "WasBorn": "Was born",
      "WereBorn": "Were born",
      "Women": "Women"
    }
  },
  "ru": {
    "Analytics": {
      "Aborts": "Аборты",
      "AgeFrom": "Возраст от",
      "DateOfTransfer": "Дата перехода",
      "Analysis": "Анализ",
      "Analytics": "Аналитика",
      "Analyzes": "Анализы",
      "Birthrate": "Рождаемость",
      "Canceled": "Отменено",
      "ChildDeath": "Смерть ребенка",
      "Children": "Дети",
      "CompletedRounds": "Завершенные обходы",
      "Complications": "Осложнения",
      "CountOfRounds": "Кол-во обходов",
      "Demography": "Демография",
      "Deregistered": "Сняты с учета",
      "Deregistration": "Сняты",
      "Diagnoses": "Диагнозы",
      "Diagnosis": "Диагноз",
      "DiagnosisSearch": "Поиск диагноза",
      "DisabledPeople": "Инвалиды",
      "DispensariesAccounting": "На Д-учете",
      "DispensariesAccountingNew": "На Д-учете (новые)",
      "DispensariesDeregistration": "Сняли с Д-учета",
      "Dispensarization": "Диспансеризация",
      "District": "Район",
      "Done": "Выполнено",
      "Group": "Группа",
      "HaveDied": "Умерло",
      "Map": "Карта",
      "Men": "Мужчины",
      "Mictrodistrict": "МФЙ",
      "Mortality": "Смертность",
      "MotherDeath": "Смерть матери",
      "New": "Новые",
      "NumberRounds": "Кол-во обходов",
      "PatientGroups": {
        "first_group": "I - Здоровые",
        "fourth_group": "IV - Высокий риск",
        "second_group": "II - Низкий риск",
        "third_group": "III - Средний риск"
      },
      "PatientStatus": "Статус пациента",
      "Patronage": "Патронаж",
      "People1stDegreeDisability": "Инвалиды I группа",
      "People2ndDegreeDisability": "Инвалиды II группа",
      "People3rdDegreeDisability": "Инвалиды III группа",
      "Percent": "Процент",
      "Pregnants": "Беременные",
      "Qty": "Кол-во",
      "Quantity": "Количество",
      "Registered": "Встали на учет",
      "Retakes": "Повторные сдачи",
      "ScheduledVaccinations": "Запланировано вакцинаций",
      "SuccessСhildbirth": "Успешные роды",
      "To": "до",
      "Vaccinated": "Вакцинировано",
      "Vaccination": "Вакцинация",
      "VaccinationTitle": "Название вакцины",
      "WasBorn": "Родилось",
      "WereBorn": "Родились",
      "Women": "Женщины"
    }
  },
  "uz": {
    "Analytics": {
      "Aborts": "Abortlar",
      "AgeFrom": "Yoshdan boshlab",
      "Analysis": "Tahlil",
      "Analytics": "Analitika",
      "DateOfTransfer": "O’tish sanasi",
      "Analyzes": "Tahlillar",
      "Birthrate": "Tug'ilish",
      "Canceled": "Bekor qilindi",
      "ChildDeath": "Bolaning o’limi",
      "Children": "Bolalar",
      "CompletedRounds": "Yakunlangan ko’riklar",
      "Complications": "Asoratlar",
      "CountOfRounds": "Ko’riklar soni",
      "Demography": "Demografiya",
      "Deregistered": "Ro’yxatdan olib tashlandi",
      "Deregistration": "Ro’yxatdan chiqarish",
      "Diagnoses": "Tashxislar",
      "Diagnosis": "Tashhis",
      "DiagnosisSearch": "Tashhis bo’yicha qidirish",
      "DisabledPeople": "Nogironlar",
      "DispensariesAccounting": "D-ro’yxatda",
      "DispensariesAccountingNew": "D-ro’yxatda (yangilar)",
      "DispensariesDeregistration": "D-ro’yxatdan chiqarilgan",
      "Dispensarization": "Dispanserizatsiya",
      "District": "Tuman",
      "Done": "Bajarilgan",
      "Group": "Guruh",
      "HaveDied": "Vafot etgan",
      "Map": "Karta",
      "Men": "Erkaklar",
      "Mictrodistrict": "Mahalla",
      "Mortality": "Vafot",
      "MotherDeath": "Onaning o’limi",
      "New": "Yangilar",
      "NumberRounds": "Ko’riklar soni",
      "PatientGroups": {
        "first_group": "I-guruh bemorlar",
        "fourth_group":"IV-guruh bemorlar",
        "second_group":"II-guruh bemorlar",
        "third_group":"III-guruh bemorlar"
      },
      "PatientStatus": "Bemor holati",
      "Patronage": "Patronaj",
      "People1stDegreeDisability": "I-darajadagi nogironlar",
      "People2ndDegreeDisability": "II-darajadagi nogironlar",
      "People3rdDegreeDisability": "III-darajadagi nogironlar",
      "Percent": "Foiz",
      "Pregnants": "Homiladorlar",
      "Qty": "Soni",
      "Quantity": "Miqdori",
      "Registered": "Ro’yxatga olingan",
      "Retakes": "Qayta topshirishlar",
      "ScheduledVaccinations": "Jadvalga olingan vaksinalar",
      "SuccessСhildbirth": "Muvaffaqiyatli tug’ruqlar",
      "To": "gacha",
      "Vaccinated": "Vaksinalangan",
      "Vaccination": "Vaksinatsiya",
      "VaccinationTitle": "Vaksina nomi",
      "WasBorn": "Tug’ilgan",
      "WereBorn": "Tug'ilgan",
      "Women": "Ayollar"
    }
  }
}
