<template>
  <LayoutByUserRole content-class="v-patient-content">
    <RouterView
      v-if="patient"
      v-model:patient="patient"
      :appointments="appointments"
      :treatments="treatments"
      :loading="loading"
      :invoices="invoices"
      :orders="orders"
      :hospital="hospital"
      :cards="cards"
      :dispensaries="dispensaries"
      :pregnancy="pregnancy"
      :vaccinations="vaccinations"
      :disability="disability"
      @disability:create="createDisability"
      @update:disability="updateDisability"
      @update:pregnancy="updatePregnancy"
      @pregnancy:create="createPregnancy"
      @dispensarie:create="createDispensarie"
      @invoice:create="createInvoice"
      @hospital:create="createHospital"
      @appointment:create="createAppointment"
      @treatment:create="createTreatment"
      @treatment:updated="updateTreatment"
      @patient:createChildren="createChildren"
      @update:dispensarie="getDispensaries"
      @vaccination:create="addVaccination"
      @update:die-info="updateDieInfo">
    </RouterView>
  </LayoutByUserRole>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { Patient } from '@/models/Patient.model';
import { Appointment } from '@/models/appointment/Appointment.model';
import { GlobalModalCloseAction } from '@/models/client/ModalAndDrawer/GlobalModalCloseAction';
import { Treatment } from '@/models/Treatment.model';
import { Invoice } from '@/models/Invoice.model';
import { Order } from '@/models/laboratory/Order.model';
import { User } from '@/models/User.model';
import { Hospital } from '@/models/hospital/Hospital.model';
import { Card } from '@/models/card/Card.model';
import { Dispensarie } from '@/models/Dispensaries.model';
import { Pregnancy } from '@/models/pregnancy/Pregnancy.model';
import { Disability } from '@/models/Disability.model';

import LayoutByUserRole from '@/components/layouts/LayoutByUserRole/index.vue';
import CreateOrEditPatientModal from '@/components/patients/CreateOrEditPatientModal/index.vue';
import CreateOrEditAppointmentModal from '@/components/appointments/CreateOrEditAppointmentModal/index.vue';
import CreateOrEditTreatmentModal from '@/components/treatments/CreateOrEditTreatmentModal/index.vue';
import CreateOrEditOrPayInvoiceModal from '@/components/invoices/CreateOrEditOrPayInvoiceModal/index.vue';
import CreateOrEditHospitalModal from '@/components/hospitals/CreateOrEditHospitalModal/index.vue';
import AddPregnancyAccountingModal from '@/components/patients/modals/AddPregnancyAccountingModal/index.vue';
import AddIDCAccountingModal from '@/components/patients/modals/AddIDCAccountingModal/index.vue';
import AddVaccinationModal from '@/components/patients/modals/AddVaccinationModal/index.vue';
import RegisterDieModal from '@/components/patients/modals/RegisterDieModal/index.vue';
import AddDisabilityAccountingModal from '@/components/patients/modals/AddDisabilityAccountingModal/index.vue';

export default {
  name: 'VPatient',
  components: { LayoutByUserRole },
  props: {
    id: [Number, String],
  },
  data() {
    return {
      /** @type Array<Appointment> */
      appointments: [],
      /** @type Patient */
      patient: null,
      /**
       * @typedef {object} VPatientLoading
       * @property {boolean} profile
       * @property {boolean} appointments
       * @property {boolean} treatments
       * @property {boolean} invoices
       * @property {boolean} orders
       */
      loading: {
        profile: false,
        appointments: false,
        treatments: false,
        invoices: false,
        orders: false,
        cards: false,
        dispensaries: false,
        pregnancy: false,
        vaccinations: false,
        disability: false,
      },
      invoices: [],
      orders: [],
      hospital: [],
      cards: [],
      dispensaries: [],
      pregnancy: [],
      vaccinations: [],
      disability: [],
      perPageLength: 5,
    };
  },
  computed: {
    ...mapState({
      treatments: (state) => state.treatments.data,
      user: (state) => state.auth.user,
    }),

    isChildren() {
      return !!this.patient.parent_id;
    },
  },
  watch: {
    id: {
      async handler() {
        await this.getUser();
        this.getCards();
        this.getAppointments();
        this.getDispensaries();
        this.getPatientHospital();
        this.getPregnancy();
        this.getVaccinations();
        this.getDisability();
        if (this.user.role === User.enum.roles.Doctor) this.getAmbulatoryCard();
        if (this.user.role !== User.enum.roles.Doctor) this.getPatientInvoices();

        if (this.user.role === User.enum.roles.Doctor) this.getTreatmentByUserId();
        if (this.user.role === User.enum.roles.Nurse) this.getTreatmentByUserId();
        if (this.user.role === User.enum.roles.Director) this.getTreatmentByUserId();
        if (this.user.role !== User.enum.roles.Doctor) this.getPatientInvoices();
        this.getPatientOrders();
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions({
      setPatient: 'patients/setPatient',
    }),
    async getUser() {
      this.loading.profile = true;

      const { data } = await Patient.findOneById(this.id);
      this.setPatient(data.data);
      this.patient = data.data;

      this.loading.profile = false;
    },

    async getAmbulatoryCard() {
      this.loading.profile = true;

      const { data } = await Patient.getAmbulatoryCardByPatientId(this.id);
      this.patient = {
        ...this.patient,
        ambulatory_card: data.data,
      };

      this.loading.profile = false;
    },

    async getAppointments() {
      this.loading.appointments = true;
      const { data } = await Appointment.find({
        query_type: 'EQUALS',
        page: 1,
        per_page: this.perPageLength,
        user_id: this.id,
      });
      this.appointments = data.data;
      this.loading.appointments = false;
    },

    async getPregnancy() {
      this.loading.pregnancy = true;
      const { data } = await Pregnancy.getItems({
        id: this.id,
      });
      this.pregnancy = data.data;
      this.loading.pregnancy = false;
    },

    async getDisability() {
      this.loading.disability = true;
      const { data } = await Disability.getItems({
        id: this.id,
      });
      this.disability = data.data;
      this.loading.disability = false;
    },

    async getDispensaries() {
      this.loading.dispensaries = true;
      const { data } = await Dispensarie.getItems({
        id: this.id,
      });
      this.dispensaries = data.data;
      this.loading.dispensaries = false;
    },

    async getPatientInvoices() {
      this.loading.invoices = true;
      const { data } = await Invoice.find({
        user_id: this.id,
        per_page: this.perPageLength,
      });

      this.invoices = data.data;
      this.loading.invoices = false;
    },

    async getVaccinations() {
      this.loading.vaccinations = true;
      const { data } = await Patient.getVaccinationById(this.patient.id);

      if (data) {
        this.vaccinations = data;
      }

      this.loading.vaccinations = false;
    },

    async getPatientHospital() {
      this.loading.hospital = true;
      const { data } = await Hospital.find({
        user_id: this.id,
      });

      this.hospital = data.data;
      this.loading.hospital = false;
    },

    async getPatientOrders() {
      this.loading.orders = true;
      const { data } = await Order.find({
        user_id: this.id,
        per_page: this.perPageLength,
      });

      this.orders = data.data;
      this.loading.orders = false;
    },
    async getTreatmentByUserId() {
      this.loading.treatments = true;

      const { data } = await Treatment.findByUserId(this.id);

      this.$store.dispatch('treatments/setData', { items: data.data, overwriteDataState: true });
      this.loading.treatments = false;
    },

    async getCards() {
      this.loading.cards = true;
      const { data } = await Card.getItems(this.id);
      this.cards = data.map((card) => {
        return {
          id: card.id,
          info: {
            title: card.title,
            created_at: card.created_at,
          },
        };
      });
      this.loading.cards = false;
    },

    createAppointment() {
      this.$store.dispatch('modalAndDrawer/openModal', {
        component: CreateOrEditAppointmentModal,
        payload: {
          patient: this.patient,
        },
      });
    },

    async createDispensarie() {
      const action = await this.$store.dispatch('modalAndDrawer/openModal', {
        component: AddIDCAccountingModal,
        payload: {
          userId: +this.id,
        },
      });

      if (action instanceof GlobalModalCloseAction) return;
      await this.getDispensaries();
    },

    createInvoice() {
      this.$store.dispatch('modalAndDrawer/openModal', {
        component: CreateOrEditOrPayInvoiceModal,
        payload: {},
      });
    },

    async addVaccination() {
      const action = await this.$store.dispatch('modalAndDrawer/openModal', {
        component: AddVaccinationModal,
        payload: {
          id: this.patient.id,
        },
      });

      if (action instanceof GlobalModalCloseAction) {
        this.getVaccinations();
      }
    },

    async createPregnancy() {
      await this.$store.dispatch('modalAndDrawer/openModal', {
        component: AddPregnancyAccountingModal,
        payload: {
          userId: this.id,
        },
      });

      await this.getPregnancy();
    },

    async createDisability() {
      await this.$store.dispatch('modalAndDrawer/openModal', {
        component: AddDisabilityAccountingModal,
        payload: {
          userId: +this.id,
        },
      });

      await this.getDisability();
    },

    async updatePregnancy() {
      await this.getPregnancy();
    },

    async updateDisability() {
      await this.getDisability();
    },

    async createHospital() {
      const action = await this.$store.dispatch('modalAndDrawer/openModal', {
        component: CreateOrEditHospitalModal,
        payload: {
          user: this.patient,
        },
      });
      if (!(action instanceof GlobalModalCloseAction)) {
        this.getPatientHospital();
      }
    },

    async createTreatment() {
      const action = await this.$store.dispatch('modalAndDrawer/openModal', {
        component: CreateOrEditTreatmentModal,
        payload: {
          user: this.patient,
        },
      });

      if (action instanceof GlobalModalCloseAction) return;
      this.$store.dispatch('treatments/createItem', action.data.treatment);
    },

    updateTreatment(treatment) {
      this.$store.dispatch('treatments/editItem', treatment);
    },

    async editPatient() {
      const action = await this.$store.dispatch('modalAndDrawer/openModal', {
        component: CreateOrEditPatientModal,
        payload: { data: this.patient },
      });

      if (action instanceof GlobalModalCloseAction) return;
      this.patient = action.data.patient;
    },

    createChildren() {
      this.$store.dispatch('modalAndDrawer/openModal', {
        component: CreateOrEditPatientModal,
        payload: {
          data: new Patient({ parent: this.patient, parent_id: this.patient.id }),
          disabledPhoneAndName: true,
        },
      });
    },

    async updateDieInfo() {
      if (!this.patient.died_at) {
        const action = await this.$store.dispatch('modalAndDrawer/openModal', {
          component: RegisterDieModal,
          payload: {
            id: this.patient.id,
          },
        });

        if (action instanceof GlobalModalCloseAction) {
          this.getUser();
        }
      } else {
        await Patient.cancelDie(this.patient.id);
        this.getUser();
      }
    },
  },
};
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/base.locales.json" />
<i18n src="./index.locales.json" />
