import { QueryParams } from '@/types/common';
import { ApiService } from '@/services/api.service';
import {
  MetaDto,
  IdcDto,
  AnalyticsAnalysisShortDto,
  PatronageNurseResponseData,
  AnalyticsMicrodistricsShortDto,
  PatientsMapInfoDto,
  VaccinationsDto,
  AnalyticsDeadDto,
  BornDto,
  GroupChangeDto,
} from '@/types/api';
import { mergeOrCreateQuery, getApiErrorMessage } from '@/utils/http.util';
import { deleteEmptyValueKeys } from '@/utils/object.util';
import { ElNotification } from 'element-plus';

export class Analytics {
  static tableName = 'analytics';

  static exportDataURL = import.meta.env.VITE_API_URL + `b2g/exports/groups/patients`;
  static exportDataURLPregnancies =
    import.meta.env.VITE_API_URL + `b2g/exports/pregnancies/patients`;
  static exportGroupsMicrodistrictsDataURL =
    import.meta.env.VITE_API_URL + `b2g/exports/groups/microdistricts`;
  static exportCodesDataURL = import.meta.env.VITE_API_URL + `b2g/exports/groups/codes`;
  static exportPregnancyMicrodistrictsDataURL =
    import.meta.env.VITE_API_URL + 'b2g/exports/pregnancies/microdistricts';
  static exportDispensarizationMicrodistrictsDataURL =
    import.meta.env.VITE_API_URL + `b2g/exports/dispensaries/microdistricts`;
  static exportDispensarizationCodesDataURL =
    import.meta.env.VITE_API_URL + `b2g/exports/dispensaries/codes`;
  static exportDispensarizationPatientsDataURL =
    import.meta.env.VITE_API_URL + `b2g/exports/dispensaries/patients`;
  static exportDisabledPeopleDataURL =
    import.meta.env.VITE_API_URL + `b2g/exports/disabilities/microdistricts`;
  static exportDisabledPeoplePatientsDataURL =
    import.meta.env.VITE_API_URL + `b2g/exports/disabilities/patients`;
  static exportVaccinesPatientsDataURL =
    import.meta.env.VITE_API_URL + `b2g/exports/vaccines/patients`;
  static exportVaccinesDataURL = `${import.meta.env.VITE_API_URL}b2g/exports/vaccines`;
  static exportVaccinesMicrodistrictsDataURL = `${
    import.meta.env.VITE_API_URL
  }b2g/exports/vaccines/microdistricts`;
  static exportDemographyDeathsDataURL = import.meta.env.VITE_API_URL + `b2g/exports/dead`;
  static exportBornDataURL = import.meta.env.VITE_API_URL + `b2g/exports/born`;
  static exportGroupChangesDataURL = import.meta.env.VITE_API_URL + `b2g/exports/group/changes`;

  static async getIdcList(query: QueryParams = {}) {
    try {
      const response = await ApiService.get<{ data: IdcDto[]; meta: MetaDto }>(
        mergeOrCreateQuery({
          url: `b2g/${this.tableName}/IDC`,
          query: deleteEmptyValueKeys(query),
        })
      );

      return {
        response: response,
        data: response.data.data,
        meta: response.data.meta,
      };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: getApiErrorMessage(err),
      });
    }
  }

  static async getAnalysis(query: QueryParams) {
    try {
      const response = await ApiService.get<{ data: AnalyticsAnalysisShortDto[]; meta: MetaDto }>(
        mergeOrCreateQuery({
          url: `b2g/${this.tableName}/analyses`,
          query: deleteEmptyValueKeys(query),
        })
      );

      return {
        response: response,
        data: response.data.data,
        meta: response.data.meta,
      };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: getApiErrorMessage(err),
      });
    }
  }

  static async getPatronageNurses(query: QueryParams) {
    try {
      const response = await ApiService.get<PatronageNurseResponseData>(
        mergeOrCreateQuery({
          url: `b2g/${this.tableName}/patronages/nurses`,
          query: deleteEmptyValueKeys(query),
        })
      );

      return {
        data: response.data.data,
        meta: response.data.meta,
      };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: getApiErrorMessage(err),
      });
    }
  }

  static async getPatronageMicrodistricts(query: QueryParams) {
    try {
      const response = await ApiService.get<{
        data: AnalyticsMicrodistricsShortDto[];
        meta: MetaDto;
      }>(
        mergeOrCreateQuery({
          url: `b2g/${this.tableName}/patronages`,
          query: deleteEmptyValueKeys(query),
        })
      );

      return {
        data: response.data.data,
        meta: response.data.meta,
      };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: getApiErrorMessage(err),
      });
    }
  }

  static async getAnalyticsMicrodistricts<T>(query: QueryParams) {
    try {
      const response = await ApiService.get<{
        data: T;
        meta: MetaDto;
      }>(
        mergeOrCreateQuery({
          url: `b2g/${this.tableName}/microdistricts`,
          query: deleteEmptyValueKeys(query),
        })
      );

      return {
        data: response.data.data,
        meta: response.data.meta,
      };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: getApiErrorMessage(err),
      });
    }
  }

  static async getAnalyticsDiagnoses<T>(query: QueryParams) {
    try {
      const response = await ApiService.get<{
        data: T;
        meta: MetaDto;
      }>(
        mergeOrCreateQuery({
          url: `b2g/${this.tableName}/codes`,
          query: deleteEmptyValueKeys(query),
        })
      );

      return {
        data: response.data.data,
        meta: response.data.meta,
      };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: getApiErrorMessage(err),
      });
    }
  }

  static async getAnalyticsPatients<T>(query: QueryParams) {
    try {
      const response = await ApiService.get<{
        data: T;
        meta: MetaDto;
      }>(
        mergeOrCreateQuery({
          url: `b2g/${this.tableName}/patients`,
          query: deleteEmptyValueKeys(query),
        })
      );

      return {
        data: response.data.data,
        meta: response.data.meta,
      };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: getApiErrorMessage(err),
      });
    }
  }

  static async getAnalyticsDeaths(query: QueryParams) {
    try {
      const response = await ApiService.get<{
        data: AnalyticsDeadDto[];
        meta: MetaDto;
      }>(
        mergeOrCreateQuery({
          url: `b2g/${this.tableName}/dead`,
          query: deleteEmptyValueKeys(query),
        })
      );

      return {
        data: response.data.data,
        meta: response.data.meta,
      };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: getApiErrorMessage(err),
      });
    }
  }

  static async getPatientsMapInfo(query: QueryParams) {
    try {
      const response = await ApiService.get<{
        data: PatientsMapInfoDto[];
        meta: MetaDto;
      }>(
        mergeOrCreateQuery({
          url: `b2g/${this.tableName}/maps/patients`,
          query: deleteEmptyValueKeys(query),
        })
      );

      return {
        data: response.data.data,
        meta: response.data.meta,
      };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: getApiErrorMessage(err),
      });
    }
  }

  static async getAnalyticVaccinations(query: QueryParams) {
    try {
      const response = await ApiService.get<{
        data: VaccinationsDto[];
        meta: MetaDto;
      }>(
        mergeOrCreateQuery({
          url: `b2g/${this.tableName}/vaccines`,
          query: deleteEmptyValueKeys(query),
        })
      );

      return {
        data: response.data.data,
        meta: response.data.meta,
      };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: getApiErrorMessage(err),
      });
    }
  }

  static async getBorn(query: QueryParams) {
    try {
      const response = await ApiService.get<{
        data: BornDto[];
        meta: MetaDto;
      }>(
        mergeOrCreateQuery({
          url: `b2g/${this.tableName}/born`,
          query: deleteEmptyValueKeys(query),
        })
      );

      return {
        data: response.data.data,
        meta: response.data.meta,
      };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: getApiErrorMessage(err),
      });
    }
  }

  static async getGroupChange(query: QueryParams) {
    try {
      const response = await ApiService.get<{
        data: GroupChangeDto[];
        meta: MetaDto;
      }>(
        mergeOrCreateQuery({
          url: `b2g/${this.tableName}/group/change`,
          query: deleteEmptyValueKeys(query),
        })
      );

      return {
        data: response.data.data,
        meta: response.data.meta,
      };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: getApiErrorMessage(err),
      });
    }
  }
}
