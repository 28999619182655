<template>
  <UiTableWithPagination
    :data="props.items"
    :loading="props.loading"
    :page="props.page"
    :per-page="props.perPage"
    :total="props.total"
    @row-click="$emit('row-click', $event)"
    @update:page="$emit('update:page', $event)"
    @update:per-page="$emit('update:perPage', $event)"
    :default-sort="defaultSort || {}"
    @sort-change="onSortChange">
    <ElTableColumn prop="title" :label="$t('Analytics.Mictrodistrict')" width="300px" />
    <ElTableColumn prop="doctor.name" :label="$t('Base.DoctorFullName2')" width="300px" />
    <ElTableColumn
      prop="count_dispensaries"
      :label="$t('Analytics.DispensariesAccounting')"
      sortable="custom" />
    <ElTableColumn prop="percent_dispensaries" :label="$t('Analytics.Percent')" />
    <ElTableColumn prop="count_dispensaries_man" :label="$t('Analytics.Men')" sortable="custom" />
    <ElTableColumn
      prop="count_dispensaries_woman"
      :label="$t('Analytics.Women')"
      sortable="custom" />
    <ElTableColumn prop="actions" :label="$t('Base.Actions')" width="250">
      <ElButton type="primary" text>
        <template #icon>
          <UiIcon :icon="icons.EYE" />
        </template>
        {{ $t('Base.Open') }}
      </ElButton>
    </ElTableColumn>
  </UiTableWithPagination>
</template>
<script lang="ts">
export default {
  name: 'DispensarizationTable',
  inheritAttrs: false,
  customOptions: {},
};
</script>
<script setup lang="ts">
import { DispensarizationMicrodistrictDto } from '@/types/api';
import { SortType } from '@/types/common';

import UiTableWithPagination from '@/components/ui/UiTableWithPagination/index.vue';
import * as icons from '@/enums/icons.enum';

const emit = defineEmits(['update:page', 'update:perPage', 'sort-change', 'row-click']);

const props = defineProps<{
  loading: boolean;
  items: DispensarizationMicrodistrictDto[];
  total: number;
  page: number;
  perPage: number;
  defaultSort: SortType | null;
}>();

const onSortChange = ({ prop, order }: SortType) => {
  emit('sort-change', { prop, order });
};
</script>

<style scoped lang="scss"></style>
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/user.locales.json" />
<i18n src="@/locales/analytics.locales.json" />
