<template>
  <ElDialog
    class="employee-modal"
    :model-value="modelValue"
    width="1158px"
    :title="$t('User.EmployeeClinic')"
    v-bind="$attrs"
    @update:model-value="$emit('update:modelValue', $event)">
    <div v-if="loading.getData" v-loading="true" />
    <VeeForm as="ElForm">
      <EmployeeForm v-if="!!formData.values" :form-data="formData.values" />
    </VeeForm>
  </ElDialog>
</template>

<script lang="ts">
export default {
  name: 'EmployeeModal',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script setup lang="ts">
import { Form as VeeForm } from 'vee-validate';
import EmployeeForm from '@/components/accounting/modals/EmployeeModal/EmployeeForm/index.vue';
import { EmployeeFormData, User } from '@/models/User.model';
import { reactive, watch } from 'vue';
import { EmployeeDto } from '@/types/api';

defineEmits(['update:modelValue']);

const props = withDefaults(defineProps<{ modelValue: boolean; userId: number | null }>(), {
  modelValue: false,
  userId: null,
});

const loading = reactive({
  getData: false,
});

const formData = reactive<{ values: null | EmployeeFormData }>({
  values: null,
});

const getUserById = async (id: number) => {
  loading.getData = true;
  const response = await User.findOneById<EmployeeDto>(id);
  formData.values = { ...new User(response.data.data) };
  loading.getData = false;
};

watch(
  () => props.userId,
  (value) => {
    if (value) {
      getUserById(value);
    } else {
      formData.values = { ...new User() };
    }
  },
  { immediate: true }
);
</script>

<style lang="scss" scoped></style>
