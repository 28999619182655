import axios from 'axios';
import { ApiService } from '@/services/api.service';
import { ElNotification } from 'element-plus';
import {
  UserStateDto,
  PatronageDto,
  PatronageShortDto,
  MetaDto,
  PatronagePlanningDto,
  FillCapacityPayload,
  PatronageUpdatePayload,
  PatronageUpdateResponse,
} from '@/types/api';
import { QueryParams } from '@/types/common';
import { deleteEmptyValueKeys } from '@/utils/object.util';
import { mergeOrCreateQuery } from '@/utils/http.util';

export type PatronageData = Omit<
  PatronageDto,
  'id' | 'latitude' | 'longitude' | 'nurse' | 'user' | 'feedback'
> & {
  id?: PatronageDto['id'] | null;
  latitude?: PatronageDto['latitude'] | null;
  longitude?: PatronageDto['longitude'] | null;
  nurse?: PatronageDto['nurse'] | null;
  user?: PatronageDto['user'] | null;
  feedback?: PatronageDto['feedback'] | null;
};

export class Patronage {
  static modelName = 'patronage';
  static tableName = 'patronages';

  id: PatronageData['id'];
  address: PatronageData['address'];
  causes: PatronageData['causes'];
  type: PatronageData['type'];
  status: PatronageData['status'];
  latitude: PatronageData['latitude'];
  longitude: PatronageData['longitude'];
  description: PatronageData['description'];
  nurse: PatronageData['nurse'];
  user_states: PatronageData['user_states'];
  details: PatronageData['details'];
  patronage_date: PatronageData['patronage_date'];
  user: PatronageData['user'];
  feedback: PatronageData['feedback'];

  constructor(payload?: PatronageData) {
    this.address = payload?.address ?? '';
    this.causes = payload?.causes ?? '';
    this.id = payload?.id ?? null;
    this.type = payload?.type ?? '';
    this.status = payload?.status ?? 'waiting';
    this.latitude = payload?.latitude ?? null;
    this.longitude = payload?.longitude ?? null;
    this.description = payload?.description ?? '';
    this.nurse = payload?.nurse ?? null;
    this.user_states = payload?.user_states ?? [];
    this.details = payload?.details ?? [];
    this.patronage_date = payload?.patronage_date ?? '';
    this.user = payload?.user ?? null;
    this.feedback = payload?.feedback ?? null;
  }

  // @ts-expect-error: Исправить.
  static exportDataURL = import.meta.env.VITE_API_URL + `b2g/exports/${this.tableName}`;

  static async getItemById(id: number) {
    try {
      const response = await ApiService.get<{ data: PatronageDto }>(`b2g/${this.tableName}/${id}`);

      return {
        response: response,
        data: response.data.data,
      };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: axios.isAxiosError(err) ? err.message : String(err),
      });
    }
  }

  static async getItems(query: QueryParams) {
    try {
      const response = await ApiService.get<{ data: PatronageShortDto[]; meta: MetaDto }>(
        mergeOrCreateQuery({
          url: `b2g/${this.tableName}`,
          query: deleteEmptyValueKeys(query),
        })
      );

      return {
        response: response,
        data: response.data.data,
        meta: response.data.meta,
      };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: axios.isAxiosError(err) ? err.message : String(err),
      });
    }
  }

  static async itemUpdate(id: PatronageDto['id'], payload: PatronageUpdatePayload) {
    try {
      const response = await ApiService.post<{ data: PatronageUpdateResponse }>(
        `b2g/${this.tableName}/${id}/update`,
        payload
      );

      return {
        response: response,
        data: response.data.data,
      };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: axios.isAxiosError(err) ? err.message : String(err),
      });
    }
  }

  static async fillCapacity(query: QueryParams, payload: FillCapacityPayload) {
    try {
      const response = await ApiService.post<{ data: PatronagePlanningDto; meta: MetaDto }>(
        mergeOrCreateQuery({
          url: `b2g/${this.tableName}/planning/select`,
          query: deleteEmptyValueKeys(query),
        }),
        payload
      );

      return {
        response: response,
        data: response.data.data,
        meta: response.data.meta,
      };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: axios.isAxiosError(err) ? err.message : String(err),
      });
    }
  }

  static async getPatronagePlanning(query: QueryParams) {
    try {
      const response = await ApiService.get<{ data: PatronagePlanningDto; meta: MetaDto }>(
        mergeOrCreateQuery({
          url: `b2g/${this.tableName}/planning`,
          query: deleteEmptyValueKeys(query),
        })
      );

      return {
        response: response,
        data: response.data.data,
        meta: response.data.meta,
      };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: axios.isAxiosError(err) ? err.message : String(err),
      });
    }
  }

  static async getStates(query: QueryParams = {}) {
    try {
      const response = await ApiService.get<{ data: UserStateDto[]; meta: MetaDto }>(
        mergeOrCreateQuery({
          url: `b2g/${this.tableName}/states`,
          query: deleteEmptyValueKeys(query),
        })
      );

      return {
        response: response,
        data: response.data.data,
        meta: response.data.meta,
      };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: axios.isAxiosError(err) ? err.message : String(err),
      });
    }
  }
}
