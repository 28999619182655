<template>
  <LayoutByUserRole content-class="v-template">
    <VeeForm
      class="v-template__form"
      as="ElForm"
      @submit="onSubmit"
      :initial-values="initialValues"
      :validation-schema="validationSchema">
      <FieldGroupWrapper class="v-template__header">
        <div class="v-template__header">
          <FormTextField field-name="title" required />
          <ElButton type="primary" native-type="submit">
            {{ $t('Base.Save') }}
          </ElButton>
        </div>
      </FieldGroupWrapper>
      <TinyEditor v-model:content="text" />
    </VeeForm>
  </LayoutByUserRole>
</template>

<script lang="ts">
export default {
  name: 'VTemplate',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { ref, reactive, watch } from 'vue';
import { Template } from '@/models/templates/Template.model';
import { Form as VeeForm, SubmissionHandler } from 'vee-validate';
import { object, string } from 'yup';
import { I18nService } from '@/services/i18n.service';

import FieldGroupWrapper from '@/components/common/form/FieldGroupWrapper/index.vue';
import FormTextField from '@/components/common/form/ui/FormTextField/index.vue';
import LayoutByUserRole from '@/components/layouts/LayoutByUserRole/index.vue';
import TinyEditor from '@/components/common/TinyEditor/Index.vue';

const props = defineProps<{
  id: string;
}>();

const validationSchema = object({
  title: string().required(I18nService.t('Validation.RequiredField')),
});
const initialValues = reactive({
  title: '',
});
const text = ref('');

const getTemplateById = async (id: number) => {
  const response = await Template.getById(id);

  if (response?.data) {
    initialValues.title = response.data.data.title ?? '';
    text.value = response.data.data.basic_data ?? '';
  }
};

const onSubmit: SubmissionHandler = async (values) => {
  if (!text.value) return;

  await Template.update(+props.id, {
    title: values.title as string,
    basic_data: text.value,
  });
};

watch(
  () => props.id,
  (val) => {
    if (val) {
      getTemplateById(+val);
    }
  },
  {
    immediate: true,
  }
);
</script>
<style lang="scss" src="./index.scss"></style>
<i18n src="@/locales/base.locales.json" />
