<template>
  <ElFormItem
    :class="[
      'ui-input-number-with-validation',
      {
        'ui-input-number-with-validation_in-column': inColumn,
      },
    ]"
    :error="errorMessage"
    :label="label"
    :required="required">
    <ElInputNumber v-model="value" v-bind="$attrs" :validate-event="false" :disabled="disabled" />
  </ElFormItem>
</template>
<script lang="ts">
export default {
  name: 'FormNumberField',
  inheritAttrs: false,
  customOptions: {},
};
</script>
<script setup lang="ts">
import { useField } from 'vee-validate';

type Props = {
  fieldName: string;
  inColumn?: boolean;
  disabled?: boolean;
  label?: string;
  required?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  inColumn: true,
  disabled: false,
  fieldName: '',
  label: '',
  required: false,
});

const { value, errorMessage, setValue } = useField<number | undefined>(props.fieldName);

defineExpose({
  setValue,
});
</script>

<style lang="scss" src="./index.scss" />
