<template>
  <LayoutByUserRole content-class="v-pregnants" fix-height>
    <div class="v-pregnants__header">
      <ElDatePicker
        class="v-pregnants__date"
        v-model="date.value"
        :format="DATE_FORMAT"
        type="daterange"
        unlink-panels
        :value-format="DATE_FORMAT"
        :placeholder="$t('DateAndTime.Date')" />

      <div class="v-pregnants__age-data">
        <span>{{ $t('Analytics.AgeFrom') }}</span>
        <UiSelect :options="ageOptions" v-model="ageFrom.value" class="v-pregnants__age" />
        <span>{{ $t('Analytics.To') }}</span>
        <UiSelect :options="ageOptions" v-model="ageTo.value" class="v-pregnants__age" />
      </div>

      <a class="v-pregnants__btn" :href="exportDataURL" download>
        <ElButton type="primary">
          {{ $t('Base.DownloadExcel') }}
        </ElButton>
      </a>
    </div>
    <PregnantsStatisticsBlock :query="statisticsQuery" />

    <PregnantTable
      :loading="loading"
      :items="tableItems"
      :total="tableItemsTotal"
      :default-sort="defaultSort"
      @sort-change="onSortChange"
      v-model:page="page.value"
      v-model:per-page="perPage.value"
      @row-click="goToPregnantMicrodistricts" />
  </LayoutByUserRole>
</template>

<script lang="ts">
export default {
  name: 'VPregnant',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { useRouter } from 'vue-router';
import { useDatePeriod, usePage, usePerPage } from '@/hooks/query';
import { computed, ref, watch } from 'vue';
import { getMonthPeriod } from '@/utils/dateAndTime.utils';
import { SortType } from '@/types/common';
import { useQuery } from '@/hooks/useQuery.hook';
import { DATE_FORMAT } from '@/config/dateAndTime.config';
import { PREGNANT_MICRODISTRICT_ROUTE } from '@/router/pregnant.routes';
import { Analytics } from '@/models/analytics/analytics.model';
import { compareQueriesThenLoadData } from '@/utils/router.utils';
import { mergeOrCreateQuery } from '@/utils/http.util';
import { deleteEmptyValueKeys } from '@/utils/object.util';
import { I18nService } from '@/services/i18n.service';
import { TokenService } from '@/services/token.service';
import { DeviceService } from '@/services/device.service';
import { PregnantMicrodistrictsDto } from '@/types/api';

import PregnantTable from '@/components/analytics/pregnants/tables/PregnantTable/index.vue';
import PregnantsStatisticsBlock from '@/components/analytics/pregnants/PregnantsStatisticsBlock/index.vue';
import LayoutByUserRole from '@/components/layouts/LayoutByUserRole/index.vue';

const router = useRouter();
const page = usePage();
const perPage = usePerPage();
const loading = ref(false);
const tableItems = ref<PregnantMicrodistrictsDto[]>([]);
const tableItemsTotal = ref(0);

const date = useDatePeriod(getMonthPeriod());
const defaultSort = ref<null | SortType>(null);
const ageFrom = useQuery({ field: 'age_from', defaultValue: 0 });
const ageTo = useQuery({ field: 'age_to', defaultValue: 120 });

const getConvertOrder = () => {
  if (!!defaultSort.value?.order) {
    return defaultSort.value.order === 'ascending' ? 'ASC' : 'DESC';
  } else {
    return null;
  }
};

const ageOptions = Array.from({ length: 120 }, (_, i) => {
  return {
    label: i.toString(),
    value: i,
  };
});

const statisticsQuery = computed(() => ({
  start_at: date.value[0],
  end_at: date.value[1],
  age_from: ageFrom.value,
  age_to: ageTo.value,
}));

const query = computed(() => ({
  is_pregnancy: 1,
  page: page.value,
  per_page: perPage.value,
  start_at: date.value[0],
  end_at: date.value[1],
  age_from: ageFrom.value,
  age_to: ageTo.value,
  count_pregnancies: defaultSort.value?.prop === 'count_pregnancies' ? getConvertOrder() : null,
  count_success_pregnancies:
    defaultSort.value?.prop === 'count_success_pregnancies' ? getConvertOrder() : null,
  count_aborts: defaultSort.value?.prop === 'count_aborts' ? getConvertOrder() : null,
  count_child_deathed: defaultSort.value?.prop === 'count_child_deathed' ? getConvertOrder() : null,
  count_mother_deathed:
    defaultSort.value?.prop === 'count_mother_deathed' ? getConvertOrder() : null,
  count_complications: defaultSort.value?.prop === 'count_complications' ? getConvertOrder() : null,
}));

const exportDataURL = computed(() => {
  return mergeOrCreateQuery({
    url: Analytics.exportPregnancyMicrodistrictsDataURL,
    query: deleteEmptyValueKeys({
      ...query.value,
      lang: I18nService.getLocale(),
      token: TokenService.getAccessToken(),
      device_id: DeviceService.getDeviceId(),
    }),
  });
});

const goToPregnantMicrodistricts = (row: PregnantMicrodistrictsDto) => {
  router.push({
    name: PREGNANT_MICRODISTRICT_ROUTE.name,
    query: {
      is_pregnancy: 1,
      date_period: date.value,
      age_from: ageFrom.value,
      age_to: ageTo.value,
    },
    params: {
      microdistrict_id: row.id,
    },
  });
};

const onSortChange = ({ prop, order }: SortType) => {
  defaultSort.value = { prop: prop, order: order };
};

const getPregnantMicrodistricts = async () => {
  loading.value = true;
  const response = await Analytics.getAnalyticsMicrodistricts<PregnantMicrodistrictsDto[]>(
    query.value
  );

  tableItems.value = response?.data ?? [];
  tableItemsTotal.value = response?.meta.total ?? 0;

  loading.value = false;
};

watch(
  query,
  (value, oldValue) => {
    compareQueriesThenLoadData({
      query: value,
      oldQuery: oldValue,
      getData: getPregnantMicrodistricts,
      resetPage: page.reset,
      fieldsForResetPage: [
        'start_at',
        'end_at',
        'count_pregnancies',
        'count_success_pregnancies',
        'count_aborts',
        'count_child_deathed',
      ],
    });
  },
  { deep: true, immediate: true }
);
</script>

<style lang="scss" src="./index.scss" />
