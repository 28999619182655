<template>
  <ElSelect
    :multiple="props.multiple"
    collapse-tags
    :clearable="props.clearable"
    :model-value="props.modelValue"
    :placeholder="props.placeholder"
    @change="$emit('change-select')"
    v-bind="$attrs"
    @update:model-value="$emit('update:modelValue', $event)">
    <ElOption
      v-for="option in props.options"
      :key="option[props.valueKey]"
      :label="option[props.labelKey]"
      :value="option[props.valueKey]!" />
  </ElSelect>
</template>

<script lang="ts">
export default {
  name: 'UiSelect',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
type Props = {
  modelValue?: string | number | (string | number)[] | undefined;
  options: Record<string, string | number>[];
  clearable?: boolean;
  multiple?: boolean;
  placeholder?: string;
  valueKey?: string;
  labelKey?: string;
};

const props = withDefaults(defineProps<Props>(), {
  clearable: true,
  multiple: false,
  placeholder: '',
  valueKey: 'value',
  labelKey: 'label',
});

defineEmits(['update:modelValue', 'change-select']);
</script>

<style lang="scss" src="./index.scss" />
