import { useModel } from '@/hooks/useModel';

/**
 * @param {useModelParams} [params]
 * @return {Object<{reset: void, value: WritableComputedRef<User|Patient|Doctor|Object>}>}
 */
export function useUser(params) {
  return useModel({
    defaultValue: params?.defaultValue,
    fieldNames: {
      id: params?.fieldNames?.id ?? enums.USER_ID,
      name: params?.fieldNames?.name ?? enums.USER_NAME,
    },
    modelKeys: {
      valueKey: 'id',
      labelKey: 'name',
    },
  });
}

const enums = {
  USER_ID: 'user_id',
  USER_NAME: 'user_name',
};
