﻿{
  "en": {
    "Patients": {
      "AddAccount": "Add under observation",
      "AddDiagnosis": "Add diagnosis",
      "AddPatient": "Add Patient",
      "AddPregnancy": "Add pregnancy",
      "AddVaccination": "Add vaccination",
      "AttendingDoctor": "Attending doctor",
      "CancelReception": "Cancel recording",
      "ChooseMicrodistrict": "Select a microdistrict",
      "ChooseReason": "Choose a reason",
      "ChooseVaccination": "Choose a vaccine",
      "ClinicPatients": "Clinic patients",
      "CountOfSuccessPregnancy": "How many successful pregnancies (if you have given birth)?",
      "CountPregnancy": "What pregnancy count is this?",
      "DNPMC": "SDLMC",
      "DateAndTimeVisit": "Time and date of appointment",
      "DateLastBirth": "Date of last birth",
      "DateNextInspection": "Date of the next inspection",
      "DateNextVisit": "Date of the next visit",
      "DateOfBirth": "Date of birth",
      "DateOfDeath": "Date of death",
      "DateOfPlanningBirth": "Planned date of birth",
      "DateOfRegistration": "Date of registration",
      "DateProduction": "Date of establishment",
      "DateVaccination": "Date of vaccination",
      "DeathInfo": "Death information",
      "DeathReason": "Cause of death",
      "DegreeDisability": "Degree of disability",
      "Degrees": {
        "first_group": "1st degree",
        "second_group": "2nd degree",
        "third_group": "3rd degree"
      },
      "Deregister": "Deregister",
      "DeregistrationDate": "Date of deregistration",
      "Diagnosis": "Diagnosis",
      "DisabilityAccounting": "Disability records",
      "EDB": "Estimated date of birth",
      "EditPatient": "Change patient data",
      "GroupOfPatients": "Group of patients",
      "Groups": {
        "first_group": "Group 1",
        "fourth_group": "Group 4",
        "second_group": "Group 2",
        "third_group": "Group 3"
      },
      "HasTreatment": "On treatment",
      "IDC": "IDC",
      "Id": "ID",
      "Microdistrict": "Microdistrict",
      "MyPatients": "My patients",
      "NamePatient": "Patient name",
      "NoPatient": "Patient not found",
      "PINFL": "PINFL",
      "ParentBirth": "Parent's date of birth",
      "PassportCode": "Code",
      "PassportNumber": "Passport number",
      "Pathology": "Pathology",
      "Patient": "Patient",
      "PatientAge": "Age",
      "PatientAlreadyExists": "The patient already exists",
      "PatientCard": "Patient card",
      "PatientCondition": "Patient condition",
      "PatientFIO": "Patient's full name",
      "PatientGroup": "Patient group",
      "PatientGroups": {
        "first_group": "Group I",
        "fourth_group": "Group IV",
        "second_group": "Group II",
        "third_group": "Group III"
      },
      "PatientRecord": "Patient record",
      "Pregnancy": "Pregnancy",
      "PregnancyAccounting": "Prenatal care",
      "PregnancyStatuses": {
        "active": "Under observation"
      },
      "Pregnants": "Pregnants",
      "PrintBracelet": "Print Bracelet",
      "ReasonOfDeregisterPregnancy": "Reason for deregistration of pregnancy",
      "RegistrationDate": "Date of registration",
      "ScheduledInspection": "Scheduled inspection",
      "SelectPatient": "Select a patient",
      "SeriesPassportNumber": "Series and passport number",
      "StartDFM": " Date of the last menstrual period",
      "StartDateOfMenstruation": "Start date of the last menstrual cycle ",
      "Statuses": {
        "abort": "Abortion",
        "child_deathed": "Death of a child",
        "created": "In the process",
        "false": "Pending",
        "gave_birth": "Gave birth",
        "misbirth": "Miscarriage",
        "mother_deathed": "Death of a mother",
        "true": "Received"
      },
      "Time": "Time",
      "VaccinationAccounting": "Vaccination tracking",
      "VaccinationDate": "Vaccination date",
      "VaccinationName": "Vaccine name",
      "VisitLog": "Visit log",
      "Week": "week",
      "Weeks": "Weeks",
      "WithdrawalDate": " Date of removal",
      "patientID": "Patient ID"
    }
  },
  "ru": {
    "Patients": {
      "AddAccount": "Добавить в учет",
      "AddDiagnosis": "Добавить диагноз",
      "AddPatient": "Добавить пациента",
      "AddPregnancy": "Добавить беременность",
      "AddVaccination": "Добавить вакцинацию",
      "AttendingDoctor": "Лечащий врач",
      "CancelReception": "Отменить запись",
      "ChooseMicrodistrict": "Выберите микрорайон",
      "ChooseReason": "Выберите причину",
      "ChooseVaccination": "Выберите вакцину",
      "ClinicPatients": "Пациенты клиники",
      "CountOfSuccessPregnancy": "Сколько удачных беременностей (если рожали)",
      "CountPregnancy": "Какая по счету беременность",
      "DNPMC": "ДНПМЦ",
      "DateAndTimeVisit": "Время и дата приема",
      "DateLastBirth": "Дата последних родов",
      "DateNextInspection": "Дата следующего осмотра",
      "DateNextVisit": "Дата следующего посещения",
      "DateOfBirth": "Дата родов",
      "DateOfDeath": "Дата смерти",
      "DateOfPlanningBirth": "ПДР",
      "DateOfRegistration": "Дата постановки на учет",
      "DateProduction": "Дата постановки",
      "DateVaccination": "Дата вакцинации",
      "DeathInfo": "Информация о смерти",
      "DeathReason": "Причина смерти",
      "DegreeDisability": "Степень инвалидности",
      "Degrees": {
        "first_group": "I группа",
        "second_group": "II группа",
        "third_group": "III группа"
      },
      "Deregister": "Снять с учета",
      "DeregistrationDate": "Дата снятия с учета",
      "Diagnosis": "Диагноз",
      "DisabilityAccounting": "Учет инвалидности",
      "EDB": "ПДР",
      "EditPatient": "Изменить данные пациента",
      "FiveWeek": "недель",
      "GroupOfPatients": "Группа пациентов",
      "Groups": {
        "first_group": "1 группа",
        "fourth_group": "4 группа",
        "second_group": "2 группа",
        "third_group": "3 группа"
      },
      "HasTreatment": "На лечении",
      "IDC": "МКБ",
      "Id": "ID",
      "Microdistrict": "МФЙ",
      "MyPatients": "Мои пациенты",
      "NamePatient": "Имя пациента",
      "NoPatient": "Пациен не найден",
      "OneWeek": "неделя",
      "PINFL": "ПИНФЛ",
      "ParentBirth": "Дата рождения родителя",
      "PassportCode": "Серия",
      "PassportNumber": "Номер паспорта",
      "Pathology": "Патология",
      "Patient": "Пациент",
      "PatientAge": "Возраст",
      "PatientAlreadyExists": "Пациент уже существует",
      "PatientCard": "Карта пациента",
      "PatientCondition": "Состояние пациента",
      "PatientFIO": "Ф.И.О. пациента",
      "PatientGroup": "Группа пациента",
      "PatientGroups": {
        "first_group": "I - Здоровые",
        "fourth_group": "IV - Высокий риск",
        "second_group": "II - Низкий риск",
        "third_group": "III - Средний риск"
      },
      "PatientId": "ID Пациента",
      "PatientRecord": "Учет пациента",
      "Pregnancy": "Беременность",
      "PregnancyAccounting": "Учет беременности",
      "PregnancyStatuses": {
        "active": "На учете"
      },
      "Pregnants": "Беременные",
      "PrintBracelet": "Распечатать браслет",
      "ReasonOfDeregisterPregnancy": "Причина снятия с учета беременности",
      "RegistrationDate": "Дата постановки на учет",
      "ScheduledInspection": "Плановый осмотр",
      "SelectPatient": "Выберите пациента",
      "SeriesPassportNumber": "Серия и номер паспорта",
      "StartDFM": "Дата НПМ",
      "StartDateOfMenstruation": "Дата начала последнего ментруального цикла",
      "Statuses": {
        "abort": "Аборт",
        "child_deathed": "Смерть ребенка",
        "created": "В процессе",
        "false": "В ожидании",
        "gave_birth": "Успешные роды",
        "misbirth": "Выкидыш",
        "mother_deathed": "Смерть матери",
        "true": "Получил"
      },
      "Time": "Срок",
      "TwoWeek": "недели",
      "VaccinationAccounting": "Учет вакцинации",
      "VaccinationDate": "Дата вакцинации",
      "VaccinationName": "Название вакцины",
      "VisitLog": "Журнал посещений",
      "Weeks": "Недель",
      "WithdrawalDate": "Дата снятия"
    }
  },
  "uz": {
    "Patients": {
      "AddAccount": "Ro’yxatga qo’shish",
      "AddDiagnosis": "Tashxisni qo'shish",
      "AddPatient": "Bemorni qo'shish",
      "AddPregnancy": "Homiladorlikni qo’shish",
      "AddVaccination": "Vaksinatsiyani qo'shish",
      "AttendingDoctor": "Davolashdagi shifokor",
      "CancelReception": "Qabulga yozuvni bekor qilish",
      "ChooseMicrodistrict": "Mahallani tanlang",
      "ChooseReason": "Sababni tanlang",
      "ChooseVaccination": "Vaksinani tanlang",
      "ClinicPatients": "Klinikadagi bemorlar",
      "CountOfSuccessPregnancy": "Muvaffaqiyatli homiladorlik soni (agar tug’ruq bo’lgan bo’lsa)",
      "CountPregnancy": "Nechinchi homiladorlik",
      "DNPMC": "OHBK",
      "DateAndTimeVisit": "Qabul sanasi va vaqti",
      "DateLastBirth": "Oxirgi tug'ilgan kun",
      "DateNextInspection": "Keyingi tekshiruv sanasi",
      "DateNextVisit": "Keyingi tashrif sanasi",
      "DateOfBirth": "Tug’ruq sanasi",
      "DateOfDeath": "O'lim sanasi",
      "DateOfPlanningBirth": "Taxminiy tug’ruq sanasi",
      "DateOfRegistration": "Ro'yxatga olish sanasi",
      "DateProduction": "Ro’yxatga olish sanasi",
      "DateVaccination": "Vaksinatsiya sanasi",
      "DeathInfo": "O'lim haqida ma'lumot",
      "DeathReason": "O'lim sababi",
      "DegreeDisability": "Nogironlik darajasi",
      "Degrees": {
        "first_group": "I daraja",
        "second_group": "II daraja",
        "third_group": "III daraja"
      },
      "Deregister": "Ro’yxatdan chiqarish",
      "DeregistrationDate": "Ro’yxatdan chiqarish sanasi",
      "Diagnosis": "Tashxis",
      "DisabilityAccounting": "Nogironlikni ro’yxatdan o’tkazish",
      "EDB": "Taxminiy tug’ruq sanasi",
      "EditPatient": "Bemorning ma'lumotlarini o'zgartirish",
      "FiveWeek": "hafta",
      "GroupOfPatients": "Bemorlar guruhi",
      "Groups": {
        "first_group": "1-guruh",
        "fourth_group": "4-guruh",
        "second_group": "2-guruh",
        "third_group": "3-guruh"
      },
      "HasTreatment": "Davolanmoqda",
      "IDC": "Kasalliklarning xalqaro tasnifi (KXT)",
      "Id": "ID",
      "Microdistrict": "Mahalla",
      "MyPatients": "Mening bemorlarim",
      "NamePatient": "Bemorning ismi",
      "NoPatient": "Bemor topilmadi",
      "OneWeek": "hafta",
      "PINFL": "PINFL",
      "ParentBirth": "Ota-onaning tug'ilgan kuni",
      "PassportCode": "Seriya",
      "PassportNumber": "Pasport raqami",
      "Pathology": "Patologiya",
      "Patient": "Bemor",
      "PatientAge": "Bemornig yoshi",
      "PatientAlreadyExists": "Bemor mavjud",
      "PatientCard": "Bemorning kartasi",
      "PatientCondition": "Bemorning holati",
      "PatientFIO": "Bemorning FISH",
      "PatientGroup": "Bemorlar guruhı",
      "PatientGroups": {
        "first_group": "I-guruh",
        "fourth_group": "IV-guruh",
        "second_group": "II-guruh",
        "third_group": "III-guruh"
      },
      "PatientId": "Bemorning ID raqami",
      "PatientRecord": "Bemorning yozuvi",
      "Pregnancy": "Homiladorlik",
      "PregnancyAccounting": "Homiladorlikni ro'yxatdan o'tkazish",
      "PregnancyStatuses": {
        "active": "Ro’yxatdan o’tgan"
      },
      "Pregnants": "Homiladorlar",
      "PrintBracelet": "Braslet chiqarish",
      "ReasonOfDeregisterPregnancy": "Homiladorlar ro’yxatidan chiqarilish sababi",
      "RegistrationDate": "Ro’yxatga olingan sana",
      "ScheduledInspection": "Jadvalga olingan tekshiruv",
      "SelectPatient": "Bemorni tanlang",
      "SeriesPassportNumber": "Pasport seriya va raqami",
      "StartDFM": "Oxirgi hayz boshlangan sana ",
      "StartDateOfMenstruation": "Oxirgi hayz boshlangan sana",
      "Statuses": {
        "abort": "Abort",
        "child_deathed": "Bolaning o’limi",
        "created": "Jarayonda",
        "false": "Kutilmoqda",
        "gave_birth": "Tug’gan",
        "misbirth": "Homila tushishi",
        "mother_deathed": "Onaning o’limi",
        "true": "Olindi"
      },
      "Time": "Vaqt",
      "TwoWeek": "hafta",
      "VaccinationAccounting": "Vaksinatsiya hisoboti",
      "VaccinationDate": "Vaksinatsiya sanasi",
      "VaccinationName": "Vaksina nomi",
      "VisitLog": "Tashriflar jurnali",
      "Weeks": "Hafta",
      "WithdrawalDate": "Ro’yxatdan chiqarish sanasi"
    }
  }
}
