<template>
  <div class="demography-borns-statistics-block">
    <StatisticsCard
      v-for="item in statisticsCardsData"
      :key="item.title"
      :title="item.title"
      :count="item.count"
      :total-count="item.totalCount"
      :subtitle="`${$t('Statistic.TotalPatients')} ${item.totalCount}`"
      :colors="item.colors"
      :labels="item.labels"
      :loading="item.loading" />
  </div>
</template>

<script lang="ts">
export default {
  name: 'DemographyBornsStatisticsBlock',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { Statistics } from '@/models/Statistics.model';
import { I18nService } from '@/services/i18n.service';
import { computed, ref, watch } from 'vue';

import StatisticsCard from '@/components/common/StatisticsCard/index.vue';

const initialData = { total_count: 0, count: 0 };

interface Props {
  query: {
    start_at: string;
    end_at: string;
  };
}

const props = defineProps<Props>();

const statisticsData = ref({
  men: {
    data: initialData,
    loading: false,
  },
  women: {
    data: initialData,
    loading: false,
  },
});

const statisticsCardsData = computed(() => [
  {
    title: I18nService.t('Analytics.Men'),
    count: statisticsData.value.men.data.count,
    totalCount: statisticsData.value.men.data.total_count,
    loading: statisticsData.value.men.loading,
    colors: ['#3879F8', '#C8DBFF'],
    labels: [I18nService.t('Analytics.WasBorn'), I18nService.t('Statistic.Remaining')],
  },
  {
    title: I18nService.t('Analytics.Women'),
    count: statisticsData.value.women.data.count,
    totalCount: statisticsData.value.women.data.total_count,
    loading: statisticsData.value.women.loading,
    colors: ['#3879F8', '#C8DBFF'],
    labels: [I18nService.t('Analytics.WasBorn'), I18nService.t('Statistic.Remaining')],
  },
]);

const getBornByMan = async (query: Props['query']) => {
  statisticsData.value.men.loading = true;
  const response = await Statistics.getBornByGender('man', query);
  statisticsData.value.men.data = response?.data ?? initialData;
  statisticsData.value.men.loading = false;
};

const getBornByWoman = async (query: Props['query']) => {
  statisticsData.value.women.loading = true;
  const response = await Statistics.getBornByGender('woman', query);
  statisticsData.value.women.data = response?.data ?? initialData;
  statisticsData.value.women.loading = false;
};

watch(
  () => props.query,
  async (value) => {
    getBornByMan(value);
    getBornByWoman(value);
  },
  { deep: true, immediate: true }
);
</script>
<style lang="scss" src="./index.scss" />
