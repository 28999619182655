export const LOGOUT = 'LOGOUT';
export const DASHBOARD = 'DASHBOARD';
export const USER_TAG = 'USER_TAG';
export const NOTE = 'NOTE';
export const LOCK = 'LOCK';
export const MESSAGE = 'MESSAGE';
export const BOARD = 'BOARD';
export const SEARCH = 'SEARCH';
export const BELL = 'BELL';
export const FILTER = 'FILTER';
export const CHEVRON_DOWN = 'CHEVRON_DOWN';
export const CHEVRON_LEFT = 'CHEVRON_LEFT';
export const CHEVRON_RIGHT = 'CHEVRON_RIGHT';
export const PLUS = 'PLUS';
export const CLOSE = 'CLOSE';
export const DOUBLE_CHECKER = 'DOUBLE_CHECKER';
export const PROVIDED = 'PROVIDED';
export const CANCELED_ROUND = 'CANCELED';
export const TRASH = 'TRASH';
export const RELOAD = 'RELOAD';
export const BACK_ARROW = 'BACK_ARROW';
export const DASHBOARD_NAV = 'DASHBOARD_NAV';
export const TIMETABLE_NAV = 'TIMETABLE_NAV';
export const CALENDAR_NAV = 'CALENDAR_NAV';
export const PATIENTS_NAV = 'PATIENTS_NAV';
export const LOGO_CIRCLE = 'LOGO_CIRCLE';
export const RUSSIAN = 'RUSSIAN';
export const ENGLISH = 'ENGLISH';
export const NOTIFICATION = 'NOTIFICATION';
export const UZBEKISTAN = 'UZBEKISTAN';
export const SCAN = 'SCAN';
export const PRINTER = 'PRINTER';
export const WALLET_OUTLINE = 'WALLET_OUTLINE';
export const MESSAGE_LOGIN = 'MESSAGE_LOGIN';
export const LOCK_PASSWORD = 'LOCK_PASSWORD';
export const EYE = 'EYE';
export const VECTOR_TOP = 'VECTOR_TOP';
export const VECTOR_BOT = 'VECTOR_BOT';
export const CANCEL = 'CANCEL';
export const MALE = 'MALE';
export const FEMALE = 'FEMALE';
export const DOWNLOAD = 'DOWNLOAD';
export const CASH_CHECK = 'CASH_CHECK';
export const CASH_REFUND = 'CASH_REFUND';
export const LIST_BOX_OUTLINE = 'LIST_BOX_OUTLINE';
export const HOSPITAL = 'HOSPITAL';
export const DATABASE = 'DATABASE';
export const MEDICAL = 'MEDICAL';
export const DECREASE_PERCENTAGE = 'DECREASE_PERCENTAGE';
export const INCREASE_PERCENTAGE = 'INCREASE_PERCENTAGE';
export const DOCTORS = 'DOCTORS';
export const NOMENCLATURE = 'NOMENCLATURE';
export const WAREHOUSES = 'WAREHOUSES';
export const SUPPLIERS = 'SUPPLIERS';
export const INCOME = 'INCOME';
export const OUTCOME = 'OUTCOME';
export const GOODS_STOCK = 'GOODS_STOCK';
export const TOOLTIP = 'TOOLTIP';
export const DOLLAR = 'DOLLAR';
export const D = 'D';
export const ARROW = 'ARROW';
export const V = 'V';
export const WARN = 'WARN';
export const DEVICE = 'DEVICE';
export const PATRONAGE = 'PATRONAGE';
export const HOME_VISIT = 'HOME_VISIT';
export const ANALYTICS = 'ANALYTICS';
export const CHAT = 'CHAT';
export const TELEGRAM = 'TELEGRAM';
export const PATIENT_GROUPS = 'PATIENT_GROUPS';
export const PREGNANT = 'PREGNANT';
export const DISPENSARIZATION = 'DISPENSARIZATION';
export const DISABLED_PEOPLE = 'DISABLED_PEOPLE';
export const MAP = 'MAP';
export const DEMOGRAPHY = 'DEMOGRAPHY';
export const VACCINATION = 'VACCINATION';
