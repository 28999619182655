<template>
  <form class="create-invoice-payment-subject" @submit.prevent="createSubject">
    <UiModelsAutocompleteSearch
      class="create-invoice-payment-subject__service"
      :model-value="subject.subject?.id"
      :model-for-use="ServiceGroup"
      :placeholder="$t('Appointments.SelectServices')"
      label="title"
      required
      collapse-tags
      :disabled="disabled"
      @select="subject.subject = $event" />

    <ElInput
      class="create-invoice-payment-subject__count"
      v-model="subject.count"
      :placeholder="$t('Base.Quantity')"
      type="number"
      required
      :disabled="disabled"
      min="1" />

    <ElButton type="primary" native-type="submit" :disabled="disabled">
      {{ $t('Base.Add') }}
    </ElButton>
  </form>
</template>

<script>
import cloneDeep from 'lodash.clonedeep';
import { Invoice } from '@/models/Invoice.model';
import { InvoicePaymentSubject } from '@/models/InvoicePaymentSubject.model';
import { ServiceGroup } from '@/models/ServiceGroup.model';

export default {
  name: 'CreateInvoicePaymentSubject',
  emits: ['subject:create'],
  props: {
    invoice: [Invoice, Object],
    disabled: Boolean,
  },
  data() {
    return {
      subject: new InvoicePaymentSubject({
        type: InvoicePaymentSubject.enum.types.GroupService,
      }),
    };
  },

  methods: {
    createSubject() {
      this.$emit('subject:create', cloneDeep(this.subject));
      this.reset();
    },
    reset() {
      this.subject = new InvoicePaymentSubject({
        type: InvoicePaymentSubject.enum.types.GroupService,
      });
    },
  },

  setup: () => ({
    ServiceGroup,
  }),
};
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/appointments.locales.json" />
