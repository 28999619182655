<template>
  <ElFormItem
    :class="[
      'ui-select-with-validation',
      {
        'ui-select-with-validation_in-column': inColumn,
        'ui-select-with-validation_in-line-space-between': !inColumn && !!label && spaceBetween,
      },
    ]"
    :error="errorMessage"
    :label="label"
    :required="required">
    <ElSelect
      class="ui-select-with-validation__select"
      v-model="value"
      :validate-event="false"
      :disabled="disabled"
      :placeholder="placeholder || $t('Base.PleaseSelect')"
      :clearable="clearable"
      @update:model-value="$emit('update:modelValue', $event)"
      @change="selectHandler">
      <ElOption
        class="ui-select-with-validation__option"
        v-for="item in items"
        :key="item[valueKey]"
        :label="item[labelKey]"
        :value="item[valueKey]" />
    </ElSelect>
  </ElFormItem>
</template>
<script lang="ts">
export default {
  name: 'FormSelectField',
  inheritAttrs: false,
  customOptions: {},
};
</script>
<script setup lang="ts">
import { useField } from 'vee-validate';
import cloneDeep from 'lodash.clonedeep';
import { AnyObject } from '@/types/common';

const emit = defineEmits(['select', 'update:modelValue']);

type Props = {
  items: AnyObject[];
  fieldName: string;
  fieldObjectName: string;
  inColumn?: boolean;
  spaceBetween?: boolean;
  clearable?: boolean;
  disabled?: boolean;
  required?: boolean;
  labelKey?: string;
  valueKey?: string;
  label?: string;
  placeholder?: string;
};

const props = withDefaults(defineProps<Props>(), {
  fieldName: '',
  fieldObjectName: '',
  inColumn: true,
  spaceBetween: false,
  clearable: false,
  disabled: false,
  required: false,
  labelKey: 'title',
  valueKey: 'id',
  label: '',
  placeholder: '',
});

const { value, setValue, errorMessage } = useField<string | number | boolean | undefined>(
  props.fieldName
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const { setValue: setObjectValue } = useField<Record<string, any>>(props.fieldObjectName);

const selectHandler = (eventValue: string | number | boolean) => {
  const result = props.items.find((f) => f[props.valueKey] === eventValue);
  const cloneResult = cloneDeep(result);

  setValue(eventValue);
  if (cloneResult) {
    setObjectValue(cloneResult);
  }
  emit('select', cloneResult);
};

defineExpose({
  setValue,
  setObjectValue,
});
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/base.locales.json" />
