<template>
  <UiTableWithPagination
    :data="items"
    :loading="loading"
    :page="page"
    :per-page="perPage"
    :total="total"
    @update:page="$emit('update:page', $event)"
    @update:per-page="$emit('update:perPage', $event)">
    <ElTableColumn prop="article" :label="$t('Nomenclature.VendorCode')" />
    <ElTableColumn prop="title" :label="$t('Nomenclature.NameOfProduct')" />
    <ElTableColumn prop="count" :label="$t('Nomenclature.Qty')" />
    <ElTableColumn prop="warehouse.title" :label="$t('Income.Warehouse')" />
    <ElTableColumn prop="next_income_date" :label="$t('Nomenclature.NextDeliveryDate')" />
  </UiTableWithPagination>
</template>
<script lang="ts">
export default {
  name: 'GoodsStockTable',
  inheritAttrs: false,
  customOptions: {},
};
</script>
<script setup lang="ts">
import { WarehouseDto } from '@/types/api';

import UiTableWithPagination from '@/components/ui/UiTableWithPagination/index.vue';

defineEmits(['update:page', 'update:perPage']);

type Props = {
  loading: boolean;
  items: WarehouseDto[];
  page: number;
  perPage: number;
  total: number;
};

defineProps<Props>();
</script>

<style lang="scss" scoped></style>
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/warehouse.locales.json" />
