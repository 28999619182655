<template>
  <LayoutByUserRole content-class="v-patronage-patient" fix-height>
    <ElBreadcrumb separator="/">
      <ElBreadcrumbItem :to="{ path: `/${PATRONAGE_PATH_PREFIX}` }"
        >{{ $t('Patronage.Patronage') }}
      </ElBreadcrumbItem>
      <ElBreadcrumbItem>{{ $t('Patronage.ExaminationPatient') }}</ElBreadcrumbItem>
    </ElBreadcrumb>
    <PersonCard
      v-if="patronageItems.length"
      :person="patronage"
      :items="patronageItems"
      :content-grid="false">
      <template #value>
        <StatusTag
          :title="patronage.status ? $t(`Patronage.Statuses.${patronage.status}`) : ''"
          :map-status="mapStatus"
          :value="patronage.status" />
      </template>
    </PersonCard>
    <template v-if="patronage">
      <ElCard class="v-patronage-patient__content" shadow="never">
        <template v-if="patronageContent.length">
          <div class="v-patronage-patient__column">
            <article
              class="v-patronage-patient__article"
              :class="{ 'v-patronage-patient__article-full': item.full }"
              v-for="(item, i) in patronageContent"
              :key="i">
              <div class="v-patronage-patient__label">{{ item.label }}</div>
              <p class="v-patronage-patient__value">{{ item.value ?? '...' }}</p>
            </article>
          </div>
          <div class="v-patronage-patient__map">
            <GeoMap :marker-properties="markerProperties" :locale="locale" />
          </div>
          <div v-if="patronage.feedback">
            <h3 class="v-patronage-patient__title">{{ $t('Patronage.FeedbackTitle') }}</h3>
            <Feedback :item="patronage.feedback" />
          </div>
        </template>
      </ElCard>
    </template>
  </LayoutByUserRole>
</template>

<script lang="ts">
export default {
  name: 'VPatronagePatient',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { ref, computed, watch } from 'vue';
import { StatusType, DetailDto, UserStateDto } from '@/types/api/patronage/patronage';
import { Patronage } from '@/models/patronage/Patronage.model';
import { I18nService } from '@/services/i18n.service';
import { MapStatusType } from '@/components/common/StatusTag/types';
import { PATRONAGE_PATH_PREFIX } from '@/router/patronage.routes';
import { PatronageDto } from '@/types/api';
import { ClientStorageService } from '@/services/clientStorage.service';

import GeoMap from '@/components/common/GeoMap/index.vue';
import LayoutByUserRole from '@/components/layouts/LayoutByUserRole/index.vue';
import PersonCard from '@/components/PersonCard/index.vue';
import StatusTag from '@/components/common/StatusTag/index.vue';
import Feedback from '@/components/common/Feedback/Index.vue';

const props = defineProps<{
  id: string;
}>();
const patronage = ref<PatronageDto | null>();

const mapStatus: Partial<MapStatusType<StatusType>> = {
  waiting: 'warning',
  bypassed: 'success',
  failed: 'danger',
  online_appointment: 'info',
};

const locale = ClientStorageService.getItem('LOCALE');

const markerProperties = computed(() => {
  if (patronage.value?.latitude && patronage.value?.longitude) {
    return [
      {
        coords: [+patronage.value?.latitude, +patronage.value?.longitude],
      },
    ];
  }

  return [];
});

const patronageItems = computed(() => {
  return patronage.value
    ? [
        {
          label: I18nService.t('Patronage.FIOPatient'),
          value: patronage.value.user.name,
        },
        {
          label: I18nService.t('Patronage.PhoneNumber'),
          value: patronage.value.user.phone,
        },
        {
          label: I18nService.t('Patronage.HealthStatus'),
          value: patronage.value.user.user_states
            .map((state: UserStateDto) => state.title)
            .join(', '),
        },
        {
          label: I18nService.t('Patronage.DateVisit'),
          value: patronage.value.patronage_date,
        },
        {
          label: I18nService.t('Patronage.StatusVisit'),
          value: patronage.value.status,
          custom: true,
        },
      ]
    : [];
});

const details = computed(() => {
  return patronage.value
    ? patronage.value.details.map((detail: DetailDto) => {
        return {
          label: I18nService.t(`Patronage.${detail.title}`),
          value: detail.value,
        };
      })
    : [];
});

const patronageContent = computed(() => {
  const arr = patronage.value
    ? [
        {
          label: I18nService.t('Patronage.Nurse'),
          value: patronage.value.nurse.name,
        },
        {
          label: I18nService.t('Patronage.TypeVisit'),
          value: I18nService.t(`Patronage.${patronage.value.type}`),
        },
        {
          label: I18nService.t('Patronage.ReasonVisit'),
          value: patronage.value.causes,
        },
        {
          label: I18nService.t('Patronage.HealthStatus'),
          value: patronage.value.user_states.map((state: UserStateDto) => state.title).join(','),
        },
        [...details.value],
        {
          label: I18nService.t('Patronage.Notes'),
          value: patronage.value.description,
          full: true,
        },
        {
          label: I18nService.t('Patronage.CoordsVisit'),
          value: `${patronage.value.latitude ?? ''} ${patronage.value.longitude ?? ''}`,
        },
        {
          label: I18nService.t('Patronage.Address'),
          value: patronage.value.address,
        },
      ]
    : [];

  return arr.flat();
});

const getPatronage = async () => {
  const response = await Patronage.getItemById(+props.id);

  if (response?.data) {
    patronage.value = new Patronage(response.data);
  } else {
    patronage.value = new Patronage();
  }
};

watch(
  () => props.id,
  () => {
    getPatronage();
  },
  { immediate: true }
);
</script>
<i18n src="@/locales/patronage.locales.json" />

<style lang="scss" src="./index.scss"></style>
