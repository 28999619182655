<template>
  <div class="deregister-pregnancy-form">
    <FieldGroupWrapper>
      <FormDateField
        :label="$t('Patients.DeregistrationDate')"
        field-name="deregistration_date"
        required
        :placeholder="$t('DateAndTime.Date')" />
    </FieldGroupWrapper>
    <FormActionsWrapper>
      <ElButton
        class="deregister-pregnancy-form__btn"
        type="primary"
        @click="submitForm"
        :loading="isSubmitting"
        :disabled="submitDisabled">
        {{ $t('Patients.Deregister') }}
      </ElButton>
    </FormActionsWrapper>
  </div>
</template>

<script lang="ts">
export default {
  name: 'DeregisterDisabilityForm',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { useForm } from 'vee-validate';
import { computed } from 'vue';
import { Disability } from '@/models/Disability.model';
import { DeregisterDisabilityFormData } from '@/types/api/disability';
import { validationSchema } from '@/components/patients/modals/DeregisterDisabilityModal/DeregisterDisabilityForm/validationSchema';

import FormDateField from '@/components/common/form/ui/FormDateField/index.vue';
import FormActionsWrapper from '@/components/common/form/FormActionsWrapper/index.vue';
import FieldGroupWrapper from '@/components/common/form/FieldGroupWrapper/index.vue';

const emit = defineEmits(['close:modal']);
const { isSubmitting, errors, handleSubmit } = useForm<DeregisterDisabilityFormData>({
  validationSchema,
});

const props = defineProps<{
  id: number;
}>();
const submitDisabled = computed(() => Object.keys(errors.value).length > 0 || isSubmitting.value);

const submitForm = handleSubmit(
  async (values) => {
    const requestData = {
      id: props.id,
      deregistration_date: values.deregistration_date,
    };

    await Disability.updateItem(requestData);

    emit('close:modal');
  },
  (ctx) => {
    // eslint-disable-next-line no-console
    console.log('invalid submit', ctx.errors);
  }
);
</script>

<style lang="scss" src="./index.scss"></style>
<i18n src="@/locales/dateAndTime.locales.json" />
<i18n src="@/locales/patients.locales.json" />
