import { CRUDModel } from '@/models/CRUD.model';
import { ApiService } from '@/services/api.service';
import { mergeOrCreateQuery } from '@/utils/http.util';
import { deleteEmptyValueKeys } from '@/utils/object.util';
import { QueryParams } from '@/types/common';
import { MetaDto, SupplierDto } from '@/types/api';

export type SupplierFormData = Omit<SupplierDto, 'id'> & {
  id?: number | null;
};

export class Supplier extends CRUDModel {
  static modelName = 'supplier';
  static tableName = 'suppliers';

  title: string;
  phone: string;
  contact_person: string;
  description: string;

  constructor(payload?: SupplierDto) {
    super(payload);

    this.title = payload?.title ?? '';
    this.phone = payload?.phone ?? '';
    this.contact_person = payload?.contact_person ?? '';
    this.description = payload?.description ?? '';
  }

  static async getItems(query: QueryParams) {
    const response = await ApiService.get<{ data: SupplierDto[]; meta: MetaDto }>(
      mergeOrCreateQuery({
        url: `${this.tableName}`,
        query: deleteEmptyValueKeys(query),
      })
    );

    return {
      response: response,
      data: response.data,
    };
  }

  static async getItemById(id: number) {
    const response = await ApiService.get<{ data: SupplierDto[] }>(`${this.tableName}/${id}`);

    return {
      response: response,
      data: response.data,
    };
  }

  static async createItem(payload: SupplierDto) {
    const response = await ApiService.post<{ data: SupplierDto[] }>(
      `${this.tableName}/create`,
      payload
    );

    return {
      response: response,
      data: response.data,
    };
  }

  static async updateItem(id: number, payload: SupplierDto) {
    const response = await ApiService.put<{ data: SupplierDto[] }>(
      `${this.tableName}/${id}/update`,
      payload
    );

    return {
      response: response,
      data: response.data,
    };
  }

  static async deleteItem(id: number) {
    const response = await ApiService.delete<{ data: SupplierDto[] }>(
      `${this.tableName}/${id}/delete`
    );

    return {
      response: response,
      data: response.data,
    };
  }
}
