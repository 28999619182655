import { ApiService } from '@/services/api.service';

export type ResponseExport = {
  title: string;
  type: string;
};

export class Export {
  static modelName = 'export';
  static tableName = 'exports';

  static async getExports() {
    const response = await ApiService.get<ResponseExport>(`b2g/${this.tableName}`);
    return {
      response: response,
      data: response.data,
    };
  }

  static exportDataURL = import.meta.env.VITE_API_URL + `b2g/${this.tableName}/export`;
}
