<template>
  <ElForm
    id="create-or-edit-patient"
    class="create-or-edit-patient"
    label-position="top"
    ref="formRef"
    :model="form"
    :rules="rules"
    @submit.prevent="submitHandler">
    <div class="create-or-edit-patient__fields-wrapper" v-show="hasPhoneNumber && !hasPatient">
      <div class="content__header">
        <ElFormItem v-show="!isChildrenSwitchIsDisabled">
          <ElSwitch size="small" :active-text="$t('User.IsChildren')" v-model="isChildren" />
        </ElFormItem>
      </div>

      <div class="create-or-edit-patient__left-field-group">
        <ElFormItem :label="$t(isChildren ? 'ParentPhone' : 'User.Phone')" prop="phone">
          <UiPhoneInput
            class="create-or-edit-patient__field"
            v-model="phone"
            :disabled="disabledPhoneAndName"
            ref="hasNotPatientPhoneInput" />
        </ElFormItem>

        <ElFormItem v-show="!isChildren" :label="$t('Patients.PINFL')" prop="pinfl">
          <ElInput
            v-model="patient.pinfl"
            v-maska
            maxlength="14"
            data-maska="##############"
            :disabled="isChildren" />
        </ElFormItem>

        <ElFormItem required v-if="isChildren" :label="$t('ParentFullName')" prop="parentName">
          <ElInput
            v-model="patient.parent.name"
            :disabled="!!patient.parent_id || disabledPhoneAndName" />
        </ElFormItem>

        <div class="create-or-edit-patient__gender-birthdate-fields">
          <ElFormItem class="birthdate" :label="$t('User.Birthdate')" prop="birthdate">
            <UiDateInput v-model="patient.birthdate" />
          </ElFormItem>
          <ElFormItem :label="$t('User.Gender')" prop="gender">
            <UiGenderSelect class="gender-field" v-model="patient.gender" />
          </ElFormItem>
        </div>

        <ElFormItem :label="$t('Patients.Microdistrict')" prop="microdistrict_id">
          <UiSelect
            v-model.number="patient.microdistrict_id"
            :options="microdistrictOptions"
            value-key="id"
            label-key="title" />
        </ElFormItem>
      </div>

      <div class="create-or-edit-patient__right-field-group">
        <ElFormItem
          prop="name"
          :label="$t('User.FullName') + ` (${$t('User.FullNameFormat').toLowerCase()})`">
          <ElInput class="create-or-edit-patient__field" v-model="patient.name" />
        </ElFormItem>

        <ElFormItem
          v-if="isChildren"
          prop="parentBirthdate"
          class="birthdate"
          :label="$t('Patients.ParentBirth')">
          <UiDateInput v-model="patient.parent.birthdate" />
        </ElFormItem>

        <div class="create-or-edit-patient__passport-fields">
          <ElFormItem
            v-show="!isChildren"
            :label="$t('Patients.PassportCode')"
            prop="passport_serial">
            <ElInput
              v-maska
              data-maska="@@"
              maxlength="2"
              v-model="patient.passport_serial"
              :disabled="isChildren" />
          </ElFormItem>
          <ElFormItem
            v-show="!isChildren"
            :label="$t('Patients.PassportNumber')"
            prop="passport_number">
            <ElInput
              v-maska
              data-maska="#######"
              maxlength="7"
              v-model="patient.passport_number"
              :disabled="isChildren" />
          </ElFormItem>
        </div>

        <ElFormItem :label="$t('Patients.PatientGroup')" prop="group">
          <UiSelect v-model="patient.group" :options="patientGroupOptions" />
        </ElFormItem>
        <ElFormItem :label="$t('User.PleaseInputAddress')">
          <ElInput v-model="patient.place_residence" />
        </ElFormItem>
      </div>
    </div>

    <div class="create-or-edit-patient__has-patient-wrapper" v-show="hasPatient">
      <ElFormItem v-if="isChildren" :label="$t('ParentFullName')" prop="parentName">
        <ElInput v-model="patient.parent.name" :disabled="!!patient.parent_id" />
      </ElFormItem>

      <ElFormItem :label="$t(isChildren ? 'ParentPhone' : 'User.Phone')" prop="phone">
        <UiPhoneInput
          class="create-or-edit-patient__field"
          v-model="phone"
          ref="hasPatientPhoneInput" />
      </ElFormItem>

      <ElFormItem>
        <div class="create-patient-modal-patient">
          <div class="create-patient-modal-patient__title">{{ $t('PatientIsInSystem') }}</div>

          <router-link
            v-show="!hasPatientFromOtherClinic"
            class="create-patient-modal-patient__name"
            :to="oldPatientPageUrl">
            {{ oldPatient?.name }}
          </router-link>
          <div v-show="hasPatientFromOtherClinic" class="create-patient-modal-patient__name">
            {{ oldPatient?.name }}
          </div>
        </div>
      </ElFormItem>
    </div>

    <div class="create-or-edit-patient__actions" ref="actions">
      <ElButton
        v-if="!hasPatient"
        type="primary"
        form="create-or-edit-patient"
        native-type="submit"
        :loading="loading.form"
        :disabled="hasPatient">
        {{ $t(data?.id ? 'Base.SaveChanges' : 'Patients.AddPatient') }}
      </ElButton>

      <router-link v-show="hasPatient && !hasPatientFromOtherClinic" :to="oldPatientPageUrl">
        <ElButton type="primary">{{ $t('GoToPatient') }}</ElButton>
      </router-link>

      <ElButton
        v-show="data?.id ? hasPatient : hasPatient && !hasPatientFromOtherClinic"
        type="primary"
        @click="isOpenPhoneConfirmModal = true">
        {{ $t(data ? 'RebindPhone' : 'CreateNewPatient') }}
      </ElButton>

      <ElButton
        v-show="hasPatientFromOtherClinic && !data"
        type="primary"
        :loading="loading.attach"
        :disabled="loading.attach"
        @click="attachPatient">
        {{ $t('Base.Attach') }}
      </ElButton>
    </div>

    <PhoneConfirmModal
      v-model="isOpenPhoneConfirmModal"
      :phone="this.patient.phone"
      append-to-body
      @action="checkPhoneForRebinding" />
  </ElForm>
</template>

<script>
import { insertRouteParams } from '@/utils/router.utils';
import { Patient } from '@/models/Patient.model';
import { GlobalModalAction } from '@/models/client/ModalAndDrawer/GlobalModalAction';
import { PATIENT_ROUTE } from '@/router/patients.routes';
import { PHONE_CONFIRM_MODAL_CONFIRMED_ACTION } from '@/components/PhoneConfirmModal/index.enum';
import { FULL_DATE_FORMAT } from '@/config/dateAndTime.config';
import { Appointment } from '@/models/appointment/Appointment.model';
import { Microdistrict } from '@/models/patronage/Microdistrict.model';
import { vMaska as maska } from 'maska';
import { I18nService } from '@/services/i18n.service';
import { getApiErrorMessage } from '@/utils/http.util';

import PhoneConfirmModal from '@/components/PhoneConfirmModal/index.vue';
import * as icons from '@/enums/icons.enum.js';

export default {
  name: 'CreateOrEditPatient',
  components: { PhoneConfirmModal },
  directives: { maska },
  emits: ['update:modelValue', 'action', 'reference:update'],
  props: {
    appointment: {
      type: [Appointment, Object],
    },
    modelValue: Boolean,
    nameOrPhone: String,
    data: [Patient, Object],
    disabledPhoneAndName: Boolean,

    disableDefaultAction: Boolean, // отключаем дефолтное поведение после создания
  },
  data() {
    return {
      loading: {
        form: false,
        attach: false,
      },
      isChildren: false,
      /** @type Patient */
      patient: null,
      /** @type Patient */
      oldPatient: null,
      hasPatient: false,
      hasPatientFromOtherClinic: false,

      isOpenPhoneConfirmModal: false,
      isRebinding: false, // если пользователь был найден и мы успешно подтвердили код - можем создать новый акк
      code: null, // для хранения кода подтверждения при rebinding или смене номера

      microdistrictOptions: [],
      rulesMapped: {
        required: {
          required: true,
          message: I18nService.t('Validation.RequiredField'),
          trigger: 'change',
        },
        minLen: {
          min: 10,
          trigger: 'change',
          message: `${I18nService.t('Validation.MinLength')} 10`,
        },
      },
    };
  },
  computed: {
    patientGroupOptions() {
      return [
        { value: Patient.enum.groups.FirstGroup, label: this.$t('Patients.Groups.first_group') },
        { value: Patient.enum.groups.SecondGroup, label: this.$t('Patients.Groups.second_group') },
        { value: Patient.enum.groups.ThirdGroup, label: this.$t('Patients.Groups.third_group') },
        { value: Patient.enum.groups.FourthGroup, label: this.$t('Patients.Groups.fourth_group') },
      ];
    },
    form() {
      return {
        microdistrict_id: this.patient?.microdistrict_id,
        name: this.patient?.name,
        phone: this.phone,
        birthdate: this.patient?.birthdate,
        parentBirthdate: this.patient?.parent?.birthdate,
        parentName: this.patient?.parent?.name,
        group: this.patient?.group,
        pinfl: this.patient?.pinfl,
        passport_serial: this.patient?.passport_serial,
        passport_number: this.patient?.passport_number,
        gender: this.patient?.gender,
      };
    },
    phoneValidation() {
      const rules = [
        {
          min: 13,
          trigger: 'change',
          message: `${this.$t('Validation.MinLength')} 13`,
        },
      ];
      if (!this.form.pinfl && !this.form.passport_serial && !this.form.passport_number) {
        rules.push(this.rulesMapped.required);
      }
      return rules;
    },
    birthdateValidation() {
      const rules = [
        this.rulesMapped.required,
        {
          validator: async (_rule, value, callback) => {
            const regExp = /^(0?[1-9]|[12][0-9]|3[01])\.(0?[1-9]|1[012])\.((19|20)\d\d)$/;

            if (!regExp.test(value)) {
              callback(new Error(this.$t('Validation.IncorrectDate')));
            } else {
              callback();
            }
          },
          trigger: 'change',
        },
      ];
      return rules;
    },
    pinflValidation() {
      const rules = [
        {
          validator: async (_rule, value, callback) => {
            if (value && String(value).length < 14) {
              callback(new Error(`${this.$t('Validation.MinLength')} 14`));
            } else if (value && String(value).length === 14) {
              const response = await Patient.checkPatientByPinfl({ pinfl: value });

              if (response?.patient && this.patient.id !== response?.patient.id) {
                callback(new Error(this.$t('Validation.PINFL')));
              }
            } else {
              callback();
            }
          },
          trigger: 'change',
        },
      ];
      if (!this.form.passport_serial && !this.form.passport_number && !this.form.phone) {
        rules.push(this.rulesMapped.required);
      }
      return rules;
    },
    passportSerialValidation() {
      const rules = [
        {
          validator: async (_rule, value, callback) => {
            if (value && String(value).length < 2) {
              callback(new Error(`${this.$t('Validation.MinLength')} 2`));
            } else {
              callback();
            }
          },
          trigger: 'change',
        },
      ];
      if ((!this.form.pinfl && !this.form.phone) || !!this.form.passport_number) {
        rules.push(this.rulesMapped.required);
      }
      return rules;
    },
    passportNumberValidation() {
      const rules = [
        {
          validator: async (_rule, value, callback) => {
            if (value && String(value).length < 7) {
              callback(new Error(`${this.$t('Validation.MinLength')} 7`));
            } else if (
              this.form.passport_serial &&
              String(this.form.passport_serial).length == 2 &&
              value &&
              String(value).length === 7
            ) {
              const { patient } = await Patient.checkPatientByPassport({
                passport_serial: this.form.passport_serial,
                passport_number: Number(value),
              });

              if (patient && this.patient.id !== patient.id) {
                callback(new Error(this.$t('Validation.Passport')));
              }
            } else {
              callback();
            }
          },
          trigger: 'change',
        },
      ];
      if ((!this.form.pinfl && !this.form.phone) || !!this.form.passport_serial) {
        rules.push(this.rulesMapped.required);
      }
      return rules;
    },
    rules() {
      return {
        microdistrict_id: [this.rulesMapped.required],
        name: [
          this.rulesMapped.required,
          {
            min: 3,
            trigger: 'change',
            message: `${this.$t('Validation.MinLength')} 3`,
          },
        ],
        phone: this.disabledPhoneAndName ? null : this.phoneValidation,
        parentName: [this.rulesMapped.required],
        gender: [this.rulesMapped.required],
        parentBirthdate: [this.rulesMapped.required, this.rulesMapped.minLen],
        birthdate: this.birthdateValidation,
        pinfl: this.disabledPhoneAndName ? null : this.pinflValidation,
        passport_serial: this.disabledPhoneAndName ? null : this.passportSerialValidation,
        passport_number: this.disabledPhoneAndName ? null : this.passportNumberValidation,
      };
    },
    phone: {
      get() {
        return this.isChildren ? this.patient.parent?.phone : this.patient.phone;
      },
      set(value) {
        if (this.isChildren) this.patient.parent.phone = value;
        else this.patient.phone = value;
      },
    },

    hasPhoneNumber() {
      return true;
    },

    oldPatientPageUrl() {
      return insertRouteParams({
        path: PATIENT_ROUTE.path,
        params: { id: this.oldPatient?.id },
      });
    },
    isChildrenSwitchIsDisabled() {
      return !!this.data?.id || !!this.data?.parent_id;
    },
  },

  watch: {
    modelValue: {
      handler() {
        this.patient = new Patient(this.data || {});
        if (this.data?.parent_id) this.isChildren = true;
      },
      immediate: true,
    },
    nameOrPhone: {
      handler() {
        this.nameOrPhoneWatcherHandler();
      },
      immediate: true,
    },
    hasPatient: {
      handler(value) {
        if (value) {
          this.$refs.hasPatientPhoneInput?.focus();
        } else {
          this.resetHasPatient();
          this.$refs.hasNotPatientPhoneInput?.focus();
        }
      },
    },
    isChildren: {
      handler(value) {
        this.resetHasPatient();

        if (value) {
          this.patient = new Patient({
            ...this.patient,
            parent: {
              ...new Patient(),
              ...this.patient.parent,
            },
          });
        }
      },
    },
    phone: {
      handler(value) {
        if (!value || (value && value.length < 13)) {
          this.hasPatient = false;
          return;
        }
        this.phoneWatcherHandler();
      },
    },
  },

  methods: {
    async submitHandler() {
      this.$refs.formRef.validate(async (valid) => {
        if (valid) {
          if (this.loading.form) return;
          this.loading.form = true;

          try {
            const patient = this.data?.id ? await this.editPatient() : await this.createPatient();

            this.$notify({
              type: 'success',
              title: this.$t(`Notifications.Success${this.patient.id ? 'Updated' : 'Created'}`),
            });
            this.$emit(
              'action',
              new GlobalModalAction({
                action: this.patient.id ? 'updated' : 'created',
                data: { patient },
              })
            );
          } catch (err) {
            this.$notify({
              type: 'error',
              title: getApiErrorMessage(err),
            });
          }

          this.loading.form = false;
        } else {
          return false;
        }
      });
    },

    customValidate() {
      const button = this.$refs.actions.querySelector("button[type='submit']");

      if (this.hasPatient) {
        this.$notify({ type: 'error', title: this.$t('Patients.PatientAlreadyExists') });
        return false;
      }

      const isPatientErrors =
        !this.patient.name ||
        (!this.isChildren &&
          !this.patient.phone &&
          (!this.patient.passport_serial || !this.patient.passport_number) &&
          !this.patient.pinfl);
      const isChildrenErrors =
        this.isChildren &&
        (this.patient.parent_id ? false : !this.patient.parent.phone || !this.patient.parent.name);

      if (isPatientErrors || isChildrenErrors) {
        button.click();
        this.$notify({ type: 'error', title: this.$t('Notifications.FillRequiredFields') });
        return false;
      }

      return true;
    },

    async createPatient() {
      const { patient } = this.isRebinding
        ? await Patient.rebinding({ patient: this.patient, code: this.code })
        : await Patient.create(this.patient, this.isChildren);

      if (!this.disableDefaultAction) setTimeout(() => this.goToPatient({ patientId: patient.id }));
      return patient;
    },

    async editPatient() {
      const phonesNonEmpty = !!(this.data.phone && this.patient.phone);

      if (phonesNonEmpty && this.data.phone !== this.patient.phone) {
        await this.openPhoneConfirmModal();
      }

      const { patient } = await Patient.update({
        id: this.patient.id,
        payload: this.isRebinding ? { ...this.patient, code: this.code } : this.patient,
      });
      return patient;
    },

    openPhoneConfirmModal() {
      this.isOpenPhoneConfirmModal = true;
      return new Promise((resolve) => {
        this.phoneConfirmPromise.resolve = resolve;
      });
    },

    // проверку телефона для создания нового пациента или смены текущего номера
    async checkPhoneForRebinding(action) {
      if (action.name !== PHONE_CONFIRM_MODAL_CONFIRMED_ACTION || !action.data.code) return;

      this.resetHasPatient();
      this.isOpenPhoneConfirmModal = false;
      this.isRebinding = true;
      this.code = action.data.code;
      this.patient = new Patient({ ...(this.data || {}), phone: this.patient.phone });

      this.phoneConfirmPromise.resolve();
    },

    // привязать к нашей клинике
    async attachPatient() {
      this.loading.attach = true;
      try {
        const { patient } = await Patient.attachPatient({ patient_id: this.oldPatient.id });

        this.$notify({ type: 'success', title: this.$t('Notifications.SuccessAttached') });
        this.$emit('action', new GlobalModalAction({ name: 'attached', data: { patient } }));
        if (!this.disableDefaultAction)
          this.goToPatient({ patient: patient, patientId: patient.id });
      } catch (err) {
        this.$notify({
          type: 'error',
          title: getApiErrorMessage(err),
        });
      }
      this.loading.attach = false;
    },

    async checkHasPatient() {
      if (!this.phone) return;

      if (this.isChildren) this.resetPatientParent();
      else this.resetHasPatient();

      const { patient, attach_clinic } = await Patient.checkPatient({ phone: this.phone });
      if (!patient) return;

      if (this.isChildren && patient) {
        this.patient.parent_id = patient.id;
        this.patient.parent = patient;
      }
      if (!this.isChildren) {
        this.oldPatient = patient;
        this.hasPatient = true;
        this.hasPatientFromOtherClinic = !attach_clinic;
      }
    },

    resetHasPatient() {
      this.isRebinding = false;
      this.oldPatient = null;
      this.hasPatient = false;
      this.hasPatientFromOtherClinic = false;
    },

    resetPatientParent() {
      if (this.patient?.parent_id) {
        this.patient.parent_id = null;
      }
    },

    goToPatient({ patientId }) {
      this.$router.push({
        name: PATIENT_ROUTE.name,
        params: {
          id: patientId || this.patient.id,
        },
      });
    },

    nameOrPhoneWatcherHandler() {
      if (!this.nameOrPhone) return;

      const isName = this.queryIsName(this.nameOrPhone);

      if (isName) {
        this.patient.name = this.nameOrPhone;
      } else {
        this.patient.phone = this.nameOrPhone;
      }
    },

    phoneWatcherHandler() {
      if (!this.hasPhoneNumber) return;
      this.checkHasPatient();
    },

    queryIsName(query) {
      return /[a-zA-Zа-яА-Я?\s]/gim.test(query);
    },
  },

  async created() {
    // Не запускаем при редактировании
    if (!this.data) this.phoneWatcherHandler();

    const result = await Microdistrict.getItems();
    if (result) {
      this.microdistrictOptions = result.data.data.map(({ id, title }) => ({ id, title }));
    }
  },

  setup: () => ({
    FULL_DATE_FORMAT: FULL_DATE_FORMAT,
    phoneConfirmPromise: { resolve: null },
    Patient,
    icons,
  }),
};
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/notifications.locales.json" />
<i18n src="@/locales/user.locales.json" />
<i18n src="@/locales/patients.locales.json" />
<i18n src="@/locales/validation.locales.json" />
<i18n src="./index.locales.json" />
