import { ApiService } from '@/services/api.service';
import { TemplateDto, MetaDto, TemplateCreateRequestDto } from '@/types/api';
import { ElNotification } from 'element-plus';

import axios from 'axios';
import { AnyObject, QueryParams } from '@/types/common';
import { mergeOrCreateQuery } from '@/utils/http.util';
import { deleteEmptyValueKeys } from '@/utils/object.util';

export class Template {
  static modelName = 'templates';

  static async getItems(query: QueryParams) {
    try {
      const response = await ApiService.get<{ data: TemplateDto[]; meta: MetaDto }>(
        mergeOrCreateQuery({
          url: `b2g/${this.modelName}`,
          query: deleteEmptyValueKeys(query),
        })
      );

      return {
        data: response.data,
        meta: response.data.meta,
      };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: axios.isAxiosError(err) ? err.message : String(err),
      });
    }
  }

  static async getById(id: number) {
    try {
      const response = await ApiService.get<{ data: TemplateDto }>(`b2g/${this.modelName}/${id}`);

      return {
        response,
        data: response.data,
      };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: axios.isAxiosError(err) ? err.message : String(err),
      });
    }
  }

  static async create(payload: TemplateCreateRequestDto) {
    try {
      const response = await ApiService.post<{ data: TemplateDto }>(
        `b2g/${this.modelName}/create`,
        payload
      );
      return {
        response,
        data: response.data,
      };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: axios.isAxiosError(err) ? err.message : String(err),
      });
    }
  }

  static async update(id: number, payload: TemplateCreateRequestDto) {
    try {
      const response = await ApiService.put<{ data: TemplateDto }>(
        `b2g/${this.modelName}/${id}/update`,
        payload
      );

      return {
        response,
        data: response.data,
      };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: axios.isAxiosError(err) ? err.message : String(err),
      });
    }
  }

  static async deleteById<T = AnyObject>(id: number) {
    try {
      const response = await ApiService.delete<T>(`b2g/${this.modelName}/${id}/delete`);

      return {
        response,
        data: response.data,
      };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: axios.isAxiosError(err) ? err.message : String(err),
      });
    }
  }
}
