<template>
  <component :is="component" v-bind="$attrs">
    <slot></slot>
  </component>
</template>

<script lang="ts">
export default {
  name: 'LayoutByUserRole',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script setup lang="ts">
import { useStore } from 'vuex';
import { User } from '@/models/User.model';
import { computed } from 'vue';

import LayoutDirector from '@/components/layouts/LayoutDirector/index.vue';
import LayoutRegistry from '@/components/layouts/LayoutRegistry/index.vue';
import LayoutDoctor from '@/components/layouts/LayoutDoctor/index.vue';
import LayoutDefault from '@/components/layouts/LayoutDefault/index.vue';
import LayoutLaboratory from '@/components/layouts/LayoutLaboratory/index.vue';
import LayoutNurse from '@/components/layouts/LayoutNurse/index.vue';

const store = useStore();
const user = computed(() => {
  return store.state.auth.user;
});

const component = computed(() => {
  switch (user.value.role) {
    case User.enum.roles.Director:
      return LayoutDirector;
    case User.enum.roles.Manager:
      return LayoutRegistry;
    case User.enum.roles.Doctor:
      return LayoutDoctor;
    case User.enum.roles.Laboratory:
      return LayoutLaboratory;
    case User.enum.roles.Nurse:
      return LayoutNurse;

    default:
      return LayoutDefault;
  }
});
</script>
