import { I18nService } from '@/services/i18n.service';
import {
  ANALYTICS_ANALYSIS_ROUTE,
  ANALYTICS_DIAGNOSES_ROUTE,
  ANALYTICS_MICRODISTRICTS_ROUTE,
  ANALYTICS_PATH_PREFIX,
  ANALYTICS_PATRONAGE_ROUTE,
} from '@/router/analytics.routes';
import {
  PATIENT_GROUPS_PATH_PREFIX,
  PATIENTS_FIRST_GROUP_ROUTE,
  PATIENTS_FOURTH_GROUP_ROUTE,
  PATIENTS_SECOND_GROUP_ROUTE,
  PATIENTS_THIRD_GROUP_ROUTE,
} from '@/router/patientGroups.routes';
import { PREGNANT_ROUTE } from '@/router/pregnant.routes';
import { VACCINATION_ROUTE } from '@/router/vaccination.routes';
import { DISPENSARIZATION_ROUTE } from '@/router/dispensarization.routes';
import { DISABLED_PEOPLE_ROUTE } from '@/router/disabledРeople.routes';
import {
  DEMOGRAPHY_BIRTHS_ROUTE,
  DEMOGRAPHY_DEATHS_ROUTE,
  DEMOGRAPHY_PATH_PREFIX,
} from '@/router/demography.routes';
import { STATISTIC_DASHBOARD_ROUTE } from '@/router/statistic.routes';
import { APPOINTMENTS_ROUTE, HOME_VISIT_ROUTE } from '@/router/appointments.routes';
import { PATIENTS_ROUTE } from '@/router/patients.routes';
import { HOSPITALS_ROUTE } from '@/router/hospital.routes';
import { USERS_PATH_PREFIX, USERS_ROUTE, USERS_VISIT_LOG_ROUTE } from '@/router/users.routes';
import { DOCTOR_ROUTE, DOCTORS_ROUTE, DOCTORS_TEMPLATES_ROUTE } from '@/router/doctors.routes';
import {
  LABORATORY_ANALYZES_ROUTE,
  LABORATORY_ORDERS_ROUTE,
  LABORATORY_REFERENCES_GROUPS_ROUTE,
} from '@/router/laboratory.routes';
import { ACCOUNTING_PATH_PREFIX, ACCOUNTING_ROUTE_OUTCOME } from '@/router/accounting.routes';
import {
  INCOME_TABLE_ROUTE,
  NOMENCLATURE_TABLE_ROUTE,
  OUTCOME_TABLE_ROUTE,
  SUPPLIERS_TABLE_ROUTE,
  WAREHOUSE_PATH_PREFIX,
  WAREHOUSE_TABLE_ROUTE,
  WAREHOUSES_TABLE_ROUTE,
} from '@/router/warehouse.routes';
import { USERS_MAP_ROUTE } from '@/router/usersMap.routes';
import { User } from '@/models/User.model';
import { DASHBOARD_ROUTE } from '@/router/dashboard.routes';
import { PATRONAGE_PLANNING_ROUTE, PATRONAGES_ROUTE } from '@/router/patronage.routes';
import { AnyObject } from '@/types/common';
import { SidebarMenuItem } from '@/types/app';

import * as icons from '@/enums/icons.enum';

export const getSidebarMenuItems = (params: AnyObject): SidebarMenuItem[] => {
  return [
    {
      roles: [User.enum.roles.Director],
      icon: icons.ANALYTICS,
      title: I18nService.t('Analytics.Analytics'),
      prefix: ANALYTICS_PATH_PREFIX,
      key: ANALYTICS_PATH_PREFIX,
      submenu: [
        {
          route: ANALYTICS_DIAGNOSES_ROUTE,
          icon: icons.ANALYTICS,
          title: I18nService.t('Analytics.Diagnoses'),
          key: ANALYTICS_DIAGNOSES_ROUTE.path,
        },
        {
          route: ANALYTICS_ANALYSIS_ROUTE,
          icon: icons.ANALYTICS,
          title: I18nService.t('Analytics.Analyzes'),
          key: ANALYTICS_ANALYSIS_ROUTE.path,
        },
        {
          route: ANALYTICS_MICRODISTRICTS_ROUTE,
          icon: icons.ANALYTICS,
          title: I18nService.t('Analytics.Mictrodistrict'),
          key: ANALYTICS_MICRODISTRICTS_ROUTE.path,
        },
        {
          route: ANALYTICS_PATRONAGE_ROUTE,
          icon: icons.ANALYTICS,
          title: I18nService.t('Analytics.Patronage'),
          key: ANALYTICS_PATRONAGE_ROUTE.path,
        },
      ],
    },
    {
      roles: [User.enum.roles.Director, User.enum.roles.Doctor],
      icon: icons.PATIENT_GROUPS,
      title: I18nService.t('Patients.GroupOfPatients'),
      prefix: PATIENT_GROUPS_PATH_PREFIX,
      key: PATIENT_GROUPS_PATH_PREFIX,
      submenu: [
        {
          route: PATIENTS_FIRST_GROUP_ROUTE,
          title: I18nService.t('Patients.PatientGroups.first_group'),
          key: PATIENTS_FIRST_GROUP_ROUTE.path,
        },
        {
          route: PATIENTS_SECOND_GROUP_ROUTE,
          title: I18nService.t('Patients.PatientGroups.second_group'),
          key: PATIENTS_SECOND_GROUP_ROUTE.path,
        },
        {
          route: PATIENTS_THIRD_GROUP_ROUTE,
          title: I18nService.t('Patients.PatientGroups.third_group'),
          key: PATIENTS_THIRD_GROUP_ROUTE.path,
        },
        {
          route: PATIENTS_FOURTH_GROUP_ROUTE,
          title: I18nService.t('Patients.PatientGroups.fourth_group'),
          key: PATIENTS_FOURTH_GROUP_ROUTE.path,
        },
      ],
    },
    {
      roles: [User.enum.roles.Director, User.enum.roles.Doctor],
      route: PREGNANT_ROUTE,
      icon: icons.PREGNANT,
      title: I18nService.t('Patients.Pregnants'),
      key: PREGNANT_ROUTE.path,
    },
    {
      roles: [User.enum.roles.Director, User.enum.roles.Doctor],
      route: VACCINATION_ROUTE,
      icon: icons.VACCINATION,
      title: I18nService.t('Analytics.Vaccination'),
      key: VACCINATION_ROUTE.path,
    },
    {
      roles: [User.enum.roles.Director, User.enum.roles.Doctor],
      route: DISPENSARIZATION_ROUTE,
      icon: icons.DISPENSARIZATION,
      title: I18nService.t('Analytics.Dispensarization'),
      key: DISPENSARIZATION_ROUTE.path,
    },
    {
      roles: [User.enum.roles.Director, User.enum.roles.Doctor],
      route: DISABLED_PEOPLE_ROUTE,
      icon: icons.DISABLED_PEOPLE,
      title: I18nService.t('Analytics.DisabledPeople'),
      key: DISABLED_PEOPLE_ROUTE.path,
    },
    {
      roles: [User.enum.roles.Director, User.enum.roles.Doctor],
      icon: icons.DEMOGRAPHY,
      title: I18nService.t('Analytics.Demography'),
      prefix: DEMOGRAPHY_PATH_PREFIX,
      key: DEMOGRAPHY_PATH_PREFIX,
      submenu: [
        {
          route: DEMOGRAPHY_DEATHS_ROUTE,
          title: I18nService.t('Analytics.Mortality'),
          key: DEMOGRAPHY_DEATHS_ROUTE.path,
        },
        {
          route: DEMOGRAPHY_BIRTHS_ROUTE,
          title: I18nService.t('Analytics.Birthrate'),
          key: DEMOGRAPHY_BIRTHS_ROUTE.path,
        },
      ],
    },
    {
      roles: [User.enum.roles.Director],
      route: STATISTIC_DASHBOARD_ROUTE,
      icon: icons.DASHBOARD,
      title: I18nService.t('Views.StatisticDashboard.Title'),
      key: STATISTIC_DASHBOARD_ROUTE.path,
    },
    {
      roles: [
        User.enum.roles.Director,
        User.enum.roles.Manager,
        User.enum.roles.Doctor,
        User.enum.roles.Nurse,
      ],
      route: HOME_VISIT_ROUTE,
      icon: icons.HOME_VISIT,
      title: I18nService.t('Appointments.HomeVisit'),
      key: HOME_VISIT_ROUTE.path,
    },
    {
      roles: [
        User.enum.roles.Director,
        User.enum.roles.Manager,
        User.enum.roles.Doctor,
        User.enum.roles.Nurse,
      ],
      route: PATIENTS_ROUTE,
      icon: icons.USER_TAG,
      title: I18nService.t('Base.Patients'),
      key: PATIENTS_ROUTE.path,
    },
    {
      roles: [
        User.enum.roles.Director,
        User.enum.roles.Manager,
        User.enum.roles.Doctor,
        User.enum.roles.Nurse,
      ],
      route: HOSPITALS_ROUTE,
      icon: icons.HOSPITAL,
      title: I18nService.t('Hospital.Hospital'),
      key: HOSPITALS_ROUTE.path,
    },
    {
      roles: [User.enum.roles.Director],
      icon: icons.MEDICAL,
      title: I18nService.t('User.Users'),
      prefix: USERS_PATH_PREFIX,
      key: USERS_PATH_PREFIX,
      submenu: [
        {
          route: USERS_ROUTE,
          icon: icons.HOSPITAL,
          title: I18nService.t('User.Employees'),
          key: USERS_ROUTE.path,
        },
        {
          route: DOCTORS_ROUTE,
          icon: icons.DOCTORS,
          title: I18nService.t('Base.Doctors'),
          key: DOCTORS_ROUTE.path,
        },
        {
          route: USERS_VISIT_LOG_ROUTE,
          icon: icons.DOCTORS,
          title: I18nService.t('Patients.VisitLog'),
          key: USERS_VISIT_LOG_ROUTE.path,
        },
      ],
    },
    {
      roles: [
        User.enum.roles.Director,
        User.enum.roles.Manager,
        User.enum.roles.Doctor,
        User.enum.roles.Nurse,
      ],
      route: APPOINTMENTS_ROUTE,
      icon: icons.NOTE,
      title: I18nService.t('Views.Appointments.Title'),
      key: APPOINTMENTS_ROUTE.path,
    },
    {
      roles: [User.enum.roles.Director, User.enum.roles.Manager, User.enum.roles.Laboratory],
      route: LABORATORY_ORDERS_ROUTE,
      icon: icons.NOTE,
      title: I18nService.t('Laboratory.Analysis.Analyzes'),
      key: LABORATORY_ORDERS_ROUTE.path,
    },
    {
      roles: [User.enum.roles.Director],
      icon: icons.WAREHOUSES,
      title: I18nService.t('Accounting.Accounting'),
      prefix: ACCOUNTING_PATH_PREFIX,
      key: ACCOUNTING_PATH_PREFIX,
      submenu: [
        {
          route: ACCOUNTING_ROUTE_OUTCOME,
          icon: icons.OUTCOME,
          title: I18nService.t('Outcome.Expenses'),
          key: ACCOUNTING_ROUTE_OUTCOME.path,
        },
      ],
    },
    {
      roles: [User.enum.roles.Director, User.enum.roles.Manager],
      icon: icons.WAREHOUSES,
      title: I18nService.t('Warehouse.Warehouse'),
      prefix: WAREHOUSE_PATH_PREFIX,
      key: WAREHOUSE_PATH_PREFIX,
      submenu: [
        {
          route: NOMENCLATURE_TABLE_ROUTE,
          icon: icons.NOMENCLATURE,
          title: I18nService.t('Nomenclature.Nomenclature'),
          key: NOMENCLATURE_TABLE_ROUTE.path,
        },
        {
          route: WAREHOUSES_TABLE_ROUTE,
          icon: icons.WAREHOUSES,
          title: I18nService.t('Warehouse.Warehouses'),
          key: WAREHOUSES_TABLE_ROUTE.path,
        },
        {
          route: SUPPLIERS_TABLE_ROUTE,
          icon: icons.SUPPLIERS,
          title: I18nService.t('Supplier.Suppliers'),
          key: SUPPLIERS_TABLE_ROUTE.path,
        },
        {
          route: INCOME_TABLE_ROUTE,
          icon: icons.INCOME,
          title: I18nService.t('Income.Incomes'),
          key: INCOME_TABLE_ROUTE.path,
        },
        {
          route: OUTCOME_TABLE_ROUTE,
          icon: icons.OUTCOME,
          title: I18nService.t('Outcome.Expenses'),
          key: OUTCOME_TABLE_ROUTE.path,
        },
        {
          route: WAREHOUSE_TABLE_ROUTE,
          icon: icons.GOODS_STOCK,
          title: I18nService.t('Product.GoodsStock'),
          key: WAREHOUSE_TABLE_ROUTE.path,
        },
      ],
    },
    // {
    //   roles: [User.enum.roles.Director],
    //   route: USERS_MAP_ROUTE,
    //   icon: icons.MAP,
    //   title: I18nService.t('Analytics.Map'),
    //   key: USERS_MAP_ROUTE.path,
    // },

    {
      roles: [User.enum.roles.Manager],
      route: DASHBOARD_ROUTE,
      icon: icons.DASHBOARD,
      title: I18nService.t('Views.Dashboard.Title'),
      key: DASHBOARD_ROUTE.path,
    },
    {
      roles: [User.enum.roles.Doctor],
      route: PATRONAGES_ROUTE,
      icon: icons.PATRONAGE,
      title: I18nService.t('Patronage.Title'),
      key: PATRONAGES_ROUTE.path,
    },
    {
      roles: [User.enum.roles.Doctor],
      route: PATRONAGE_PLANNING_ROUTE,
      icon: icons.PATRONAGE,
      title: I18nService.t('Patronage.PatronagePlanning'),
      key: PATRONAGE_PLANNING_ROUTE.path,
    },
    {
      roles: [User.enum.roles.Doctor],
      route: DASHBOARD_ROUTE,
      icon: icons.DASHBOARD_NAV,
      title: I18nService.t('Base.CalendarAppointments'),
      key: DASHBOARD_ROUTE.path,
    },
    {
      roles: [User.enum.roles.Doctor],
      route: DOCTORS_TEMPLATES_ROUTE,
      icon: icons.DATABASE,
      title: I18nService.t('Base.Templates'),
      key: DOCTORS_TEMPLATES_ROUTE.path,
    },
    {
      roles: [User.enum.roles.Doctor],
      route: {
        ...DOCTOR_ROUTE,
        params: {
          id: params.doctorId,
        },
      },
      icon: icons.DASHBOARD,
      title: I18nService.t('Base.Statistics'),
      key: DOCTOR_ROUTE.path,
    },
    {
      roles: [User.enum.roles.Laboratory],
      route: LABORATORY_ANALYZES_ROUTE,
      icon: icons.LIST_BOX_OUTLINE,
      title: I18nService.t('Laboratory.Analysis.Analyzes'),
      key: LABORATORY_ANALYZES_ROUTE.path,
    },
    {
      roles: [User.enum.roles.Laboratory],
      route: LABORATORY_REFERENCES_GROUPS_ROUTE,
      icon: icons.LIST_BOX_OUTLINE,
      title: I18nService.t('Laboratory.ReferencesGroups'),
      key: LABORATORY_REFERENCES_GROUPS_ROUTE.path,
    },

    {
      roles: [User.enum.roles.Nurse],
      route: PATRONAGES_ROUTE,
      icon: icons.PATRONAGE,
      title: I18nService.t('Patronage.Title'),
      key: PATRONAGES_ROUTE.path,
    },
    {
      roles: [User.enum.roles.Nurse],
      route: PATRONAGE_PLANNING_ROUTE,
      icon: icons.PATRONAGE,
      title: I18nService.t('Patronage.PatronagePlanning'),
      key: PATRONAGE_PLANNING_ROUTE.path,
    },
  ];
};
