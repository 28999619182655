import { computed, reactive } from 'vue';
import { useQuery } from '@/hooks/useQuery.hook';

/**
 * @typedef {object} useModelParams
 * @property {object} [defaultValue]
 * @property {object} [fieldNames]
 *   @property {string} [fieldNames.id]
 *   @property {string} [fieldNames.name]
 * @property {object} [modelKeys]
 *   @property {string} [modelKeys.valueKey = 'id']
 *   @property {string} [modelKeys.labelKey = 'title']
 */
/**
 * @param {useModelParams} [params]
 * @return {Object<{reset: void, value: WritableComputedRef<CRUDModel|Object>}>}
 */
export function useModel(params) {
  const id = useQuery({
    field: params.fieldNames.id,
    defaultValue: params?.defaultValue?.id ?? null,
    valueIsNumber: true,
  });
  const name = useQuery({
    field: params.fieldNames.name,
    defaultValue: params?.defaultValue?.name ?? null,
  });
  const reset = () => {
    name.reset();
    setTimeout(() => id.reset());
  };

  return reactive({
    value: computed({
      get() {
        return {
          [params?.modelKeys?.valueKey ?? enums.DEFAULT_VALUE_KEY]: id.value,
          [params?.modelKeys?.labelKey ?? enums.DEFAULT_LABEL_KEY]: name.value,
        };
      },

      /** @param {CRUDModel|object|null} value */
      set(value) {
        if (!value) return reset();
        name.value = value[params?.modelKeys?.labelKey ?? enums.DEFAULT_LABEL_KEY];
        setTimeout(() => (id.value = value.id));
      },
    }),

    reset: reset,
  });
}

const enums = {
  DEFAULT_VALUE_KEY: 'id',
  DEFAULT_LABEL_KEY: 'title',
};
