<template>
  <div class="v-patient-default">
    <div class="v-patient-default-main">
      <!--  Patient  -->
      <div class="v-patient-default__item v-patient-default-item">
        <div class="v-patient-default-item__header v-patient-default-item-header">
          <div class="v-patient-default__title">{{ $t('Title') }}</div>
          <div class="v-patient-default__actions">
            <ElButton type="primary" plain @click="editPatient">
              {{ $t('Patients.EditPatient') }}
            </ElButton>
          </div>
        </div>

        <div class="v-patient-default-item__body" v-loading="loading.profile">
          <PatientCardRow grid :patient="patient" :items="patientItems" shadow="never">
            <template #actions>
              <ElButton
                v-show="isManager"
                type="primary"
                :loading="braceletLoading"
                @click="printBracelet">
                {{ $t('Patients.PrintBracelet') }}
              </ElButton>
            </template>
          </PatientCardRow>
        </div>
      </div>

      <div class="v-patient-default__item v-patient-default-item">
        <div class="v-patient-default-item__header v-patient-default-item-header">
          <div class="v-patient-default__title">{{ $t('Patients.DeathInfo') }}</div>

          <ElButton type="primary" size="small" plain @click="this.$emit('update:die-info')">
            {{ textInfoDie }}
          </ElButton>
        </div>
        <div v-if="!!patient.died_at" class="v-patient-default-item__body">
          <PatientCardRow
            :is-show-avatar="false"
            grid
            :patient="patient"
            :items="dieInfoItems"
            shadow="never">
            <template #value>
              <ul>
                <li v-for="item in patient.disease_codes" :key="item.code">{{ item.title }}</li>
              </ul>
            </template>
          </PatientCardRow>
        </div>
      </div>

      <div class="v-patient-default-select-inputs">
        <FormLabel :label="$t('Patients.PatientCondition')">
          <UiModelsAutocompleteSearch
            class="v-patient-default-select-inputs__states-select"
            :model-value="currentUserStatesIds"
            :model-for-use="Patient"
            :models="currentStatesSelectItems"
            method-name="getStates"
            label="title"
            value="id"
            :placeholder="$t('Base.Choose')"
            multiple
            @update:modelValue="currentUserStatesIds = $event"
            @select="currentStatesSelectItems = $event" />
        </FormLabel>

        <FormLabel :label="$t('Patients.PatientGroup')">
          <UiSelect
            class="v-patient-default-select-inputs__group-select"
            v-model="patientGroup"
            :clearable="false"
            :options="patientGroupOptions" />
        </FormLabel>
      </div>
      <!-- Учет беременности -->
      <div v-if="isWoman" class="v-patient-default-item">
        <div class="v-patient-default-item__header v-patient-default-item-header">
          <div class="v-patient-default__title">{{ $t('Patients.PregnancyAccounting') }}</div>

          <ElButton size="small" type="primary" plain @click="$emit('pregnancy:create')">
            {{ $t('Patients.AddAccount') }}
          </ElButton>
        </div>

        <ElEmpty
          class="v-patient-default-item-empty"
          v-show="!pregnancy?.length && !loading.pregnancy"
          :description="$t('Base.NoData')" />

        <PregnanciesTable
          v-if="pregnancy.length"
          :items="pregnancy"
          :total="pregnancy?.length"
          :per-page="pregnancy?.length || 1"
          :page="1"
          :loading="loading.pregnancy"
          @approvePregnancies="onApprovePregnancies" />
      </div>
      <!-- Учет пациента -->
      <div class="v-patient-default-item">
        <div class="v-patient-default-item__header v-patient-default-item-header">
          <div class="v-patient-default__title">{{ $t('Patients.PatientRecord') }}</div>

          <ElButton size="small" type="primary" plain @click="$emit('dispensarie:create')">
            {{ $t('Patients.AddAccount') }}
          </ElButton>
        </div>

        <ElEmpty
          class="v-patient-default-item-empty"
          v-show="!dispensaries?.length && !loading.dispensaries"
          :description="$t('Base.NoData')" />

        <PatientGroupAccountingTable
          v-if="dispensaries?.length"
          :items="dispensaries"
          :total="dispensaries?.length"
          :per-page="dispensaries?.length || 1"
          :page="1"
          :loading="loading.dispensaries"
          @approve="onApproveBtnClick" />
      </div>

      <div class="v-patient-default-item">
        <div class="v-patient-default-item__header v-patient-default-item-header">
          <div class="v-patient-default__title">{{ $t('Patients.VaccinationAccounting') }}</div>

          <ElButton size="small" type="primary" plain @click="$emit('vaccination:create')">
            {{ $t('Patients.AddVaccination') }}
          </ElButton>
        </div>

        <ElEmpty
          class="v-patient-default-item-empty"
          v-show="!vaccinations?.length && !loading.vaccinations"
          :description="$t('Base.NoData')" />

        <VaccinationsTable
          v-if="vaccinations?.length"
          :items="vaccinations"
          :total="vaccinations?.length"
          :per-page="vaccinations?.length || 1"
          :page="1"
          :loading="loading.vaccinations" />
      </div>

      <!-- Учет инвалидности -->
      <div class="v-patient-default-item">
        <div class="v-patient-default-item__header v-patient-default-item-header">
          <div class="v-patient-default__title">{{ $t('Patients.DisabilityAccounting') }}</div>

          <ElButton size="small" type="primary" plain @click="$emit('disability:create')">
            {{ $t('Patients.AddAccount') }}
          </ElButton>
        </div>

        <ElEmpty
          class="v-patient-default-item-empty"
          v-show="!disability?.length && !loading.disability"
          :description="$t('Base.NoData')" />

        <DisabilityTable
          v-if="disability?.length"
          :items="disability"
          :total="disability?.length"
          :per-page="disability?.length || 1"
          :page="1"
          :loading="loading.disability"
          @approveDisability="onApproveDisability" />
      </div>

      <!--  Appointments  -->
      <div class="v-patient-default-item">
        <div class="v-patient-default-item__header v-patient-default-item-header">
          <div class="v-patient-default__title">{{ $t('Appointments.Appointments') }}</div>

          <ElButton size="small" type="primary" plain @click="$emit('appointment:create')">
            {{ $t('Appointments.CreateAppointment') }}
          </ElButton>
        </div>

        <ElEmpty
          class="v-patient-default-item-empty"
          v-show="!appointments?.length && !loading.appointments"
          :description="$t('Base.NoData')" />

        <AppointmentsTable
          v-show="appointments?.length || loading.appointments"
          :items="appointments"
          :total="appointments?.length"
          :per-page="appointments?.length || 1"
          :page="1"
          :loading="loading.appointments"
          :exclude-columns="excludeColumns"
          :max-height="500" />
        <div class="v-patient-default-item__action">
          <p @click="goToAppointmentsPatient">
            {{ $t('Appointments.AllAppointments') }}
          </p>
        </div>
      </div>

      <!--  Invoices  -->
      <div class="v-patient-default__item v-patient-default-item" v-show="false">
        <div class="v-patient-default-item__header v-patient-default-item-header">
          <div class="v-patient-default__title">{{ $t('Base.Invoice') }}</div>
          <!--          <ElButton type="primary" plain @click="$emit('appointment:create')">-->
          <ElButton size="small" type="primary" plain @click="$emit('invoice:create')">
            {{ $t('Invoices.Create') }}
          </ElButton>
        </div>
        <ElEmpty
          class="v-patient-default-item-empty"
          v-show="!invoices?.length && !loading.invoices"
          :description="$t('Base.NoData')" />
        <InvoicesTable
          v-show="invoices?.length || loading.invoices"
          :items="invoices"
          :total="invoices?.length"
          :per-page="invoices?.length || 1"
          :page="1"
          :loading="loading.invoices"
          :columns="{ id: false, user: false }" />
        <div class="v-patient-default-item__action">
          <p @click="goToCashPatient">
            {{ $t('Invoices.AllInvoices') }}
          </p>
        </div>
      </div>

      <!--  Orders  -->
      <div class="v-patient-default__item v-patient-default-item" v-show="false">
        <div class="v-patient-default-item__header v-patient-default-item-header">
          <div class="v-patient-default__title">{{ $t('Base.Orders') }}</div>
        </div>

        <ElEmpty
          class="v-patient-default-item-empty"
          v-show="!orders?.length && !loading.orders"
          :description="$t('Base.NoData')" />

        <OrdersTable
          v-show="orders?.length || loading.orders"
          :items="orders"
          :total="orders?.length"
          :per-page="orders?.length || 1"
          :page="1"
          :loading="loading.orders" />
      </div>

      <!--  Children  -->
      <div v-if="isParent" class="v-patient-default-item">
        <div class="v-patient-default-item__header v-patient-default-item-header">
          <div class="v-patient-default__title">{{ $t('User.Children') }}</div>
          <ElButton type="primary" plain @click="$emit('patient:createChildren')">
            {{ $t('User.AddChildren') }}
          </ElButton>
        </div>

        <ElEmpty
          class="v-patient-default-item-empty"
          v-show="!patient.childrens?.length"
          :description="$t('Base.NoData')" />

        <PatientsTable
          v-show="patient?.childrens?.length || loading.profile"
          :items="patient.childrens"
          :loaing="loading.profile"
          :total="patient?.childrens?.length"
          :per-page="patient?.childrens?.length || 1"
          :page="1"
          hide-on-single-page
          :loading="loading.profile"
          :max-height="500"
          layout="prev, pager, next, sizes" />
      </div>
    </div>

    <div class="v-patient-default-side">
      <!--  HospitalsTable-->
      <div class="v-patient-default__item v-patient-default-item">
        <div class="v-patient-default-item__header v-patient-default-item-header">
          <div class="v-patient-default__title">{{ $t('Hospital.Hospital') }}</div>
          <ElButton size="small" type="primary" plain @click="$emit('hospital:create')">
            {{ $t('Base.Create') }}
          </ElButton>
        </div>

        <ElEmpty
          class="v-patient-default-item-empty"
          v-show="!hospital?.length && !loading.hospital"
          :description="$t('Base.NoData')" />

        <HospitalsTable
          v-show="hospital?.length"
          :data="hospital"
          :total="hospital?.length"
          :loading="loading.hospital"
          :per-page="hospital?.length || 1"
          :page="1"
          :columns="{ user: false, department: false, status: false }" />
      </div>

      <div class="v-patient-default__item v-patient-default-item">
        <div class="v-patient-default-item__header v-patient-default-item-header">
          <div class="v-patient-default__title">{{ $t('Patronage.Documents') }}</div>

          <ElButton
            v-if="!isNurse && !isDirector && !isManager"
            size="small"
            plain
            type="primary"
            @click="openTemplatesModal">
            {{ $t('Patronage.AddDocument') }}
          </ElButton>
        </div>
        <div>
          <TemplatesTable :items="cards" :total="cards?.length" :loading="loading.cards" />
        </div>
      </div>
      <!--  Treatments  -->
      <div class="v-patient-default__item v-patient-default-item">
        <div class="v-patient-default-item__header v-patient-default-item-header">
          <div class="v-patient-default__title">{{ $t('Base.TableTreatment') }}</div>

          <ElButton
            v-show="!isManager && !isDirector"
            size="small"
            plain
            type="primary"
            @click="$emit('treatment:create')">
            {{ $t('Base.SetTreatment') }}
          </ElButton>
        </div>

        <ElEmpty
          class="v-patient-default-item-empty"
          v-show="!treatments?.length && !loading.treatments"
          :description="$t('Base.NoData')" />

        <TreatmentsTable
          v-show="treatments?.length || loading.treatments"
          :items="treatments"
          :total="treatments?.length"
          :per-page="treatments?.length || 1"
          :page="1"
          :loading="loading.treatments"
          :columns="{ doctor: false, created: false }"
          :max-height="500"
          @item:update="$emit('treatment:updated', $event)" />
      </div>

      <!--  AnalyzesStatusesTable  -->
      <div v-show="!isNurse" class="v-patient-default__item v-patient-default-item">
        <div class="v-patient-default-item__header v-patient-default-item-header">
          <div class="v-patient-default__title">{{ $t('Laboratory.Analysis.Analyzes') }}</div>
          <div class="v-patient-default-item__action right">
            <p @click="goToAnalysisPatient">
              {{ $t('Laboratory.Analysis.AllAnalyzes') }}
            </p>
          </div>
        </div>

        <ElEmpty
          class="v-patient-default-item-empty"
          v-show="!orders?.length && !loading.orders"
          :description="$t('Base.NoData')" />

        <AnalyzesStatusesTable
          v-show="orders?.length || loading.orders"
          :items="orders"
          :loading="loading.orders" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';
import { insertRouteParams } from '@/utils/router.utils';
import { PATIENT_ROUTE } from '@/router/patients.routes';
import { PrinterService } from '@/services/printer.service';
import { Patient } from '@/models/Patient.model';
import { User } from '@/models/User.model';
import { GlobalModalCloseAction } from '@/models/client/ModalAndDrawer/GlobalModalCloseAction';
import { ISOStringToDateAppFormat } from '@/utils/dateAndTime.utils';
import { Dispensarie } from '@/models/Dispensaries.model';

import AppointmentsTable from '@/components/appointments/AppointmentsTable/index.vue';
import FormLabel from '@/components/common/form/FormLabel/index.vue';
import AnalyzesStatusesTable from '@/components/patients/AnalyzesStatusesTable/index.vue';
import PatientsTable from '@/components/patients/PatientsTable/index.vue';
import HospitalsTable from '@/components/hospitals/HospitalsTable/index.vue';
import TreatmentsTable from '@/components/treatments/TreatmentsTable/index.vue';
import PatientCardRow from '@/components/patients/PatientCardRow/index.vue';
import CreateOrEditPatientModal from '@/components/patients/CreateOrEditPatientModal/index.vue';
import InvoicesTable from '@/components/invoices/InvoicesTable/index.vue';
import OrdersTable from '@/components/laboratory/OrdersTable/index.vue';
import TemplatesTable from '@/components/card/tables/TemplatesTable/Index.vue';
import TemplatesModal from '@/components/card/modals/TemplatesModal/Index.vue';
import UiModelsAutocompleteSearch from '@/components/ui/UiModelsAutocompleteSearch/index.vue';
import PatientGroupAccountingTable from '@/components/patients/tables/PatientGroupAccountingTable/index.vue';
import PregnanciesTable from '@/components/patients/tables/PregnanciesTable/index.vue';
import DeregisterPregnancyModal from '@/components/patients/modals/DeregisterPregnancyModal/index.vue';
import VaccinationsTable from '@/components/patients/tables/VaccinationsTable/index.vue';
import DisabilityTable from '@/components/patients/DisabilityTable/index.vue';
import DeregisterDisabilityModal from '@/components/patients/modals/DeregisterDisabilityModal/index.vue';

export default {
  name: 'VPatientDefault',
  emits: [
    'update:patient',
    'appointment:create',
    'patient:createChildren',
    'treatment:create',
    'treatment:updated',
    'hospital:create',
    'invoice:create',
    'update:dispensarie',
    'dispensarie:create',
    'pregnancy:create',
    'update:pregnancy',
    'vaccination:create',
    'update:die-info',
    'disability:create',
    'update:disability',
  ],
  components: {
    DisabilityTable,
    PregnanciesTable,
    PatientGroupAccountingTable,
    UiModelsAutocompleteSearch,
    AnalyzesStatusesTable,
    AppointmentsTable,
    PatientsTable,
    TreatmentsTable,
    PatientCardRow,
    InvoicesTable,
    OrdersTable,
    HospitalsTable,
    TemplatesTable,
    FormLabel,
    VaccinationsTable,
  },
  props: {
    patient: [Patient, Object],
    /**@param {Array<invoices>} */
    invoices: [Array],

    /**@param {Array<hospital>} */
    hospital: Array,

    /**@param {Array<orders>} */
    orders: [Array],

    /** @param {Array<Appointment>} appointments */
    appointments: Array,
    /** @param {Array<Treatment>} treatments */
    treatments: Array,
    /** @param {Array<Documents>} documents */
    cards: Array,

    /** @param {Array<Dispensarie>} documents */
    dispensaries: Array,

    /** @param {Array<Disability>} documents */
    disability: Array,

    /** @param {Array<Pregnancy>} documents */
    pregnancy: Array,

    /** @param {Array<Vaccination>} documents */
    vaccinations: Array,

    /** @type {VPatientLoading|object} loading */
    loading: Object,
  },
  data() {
    return {
      braceletLoading: false,
      userStatesSelectItems: [],
      currentUserStatesIds: [],
      currentUserStates: [],
    };
  },

  setup: () => ({
    Patient,
  }),

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),

    textInfoDie() {
      return this.patient.died_at ? this.$t('Patients.CancelReception') : this.$t('Base.Edit');
    },

    currentStatesSelectItems: {
      get() {
        return this.currentUserStates;
      },
      async set(value) {
        const ids = value.map((item) => item.id);
        await Patient.updateStatesById(this.patient.id, { user_state_ids: ids });
      },
    },
    patientGroupOptions() {
      return [
        { value: Patient.enum.groups.FirstGroup, label: this.$t('Patients.Groups.first_group') },
        { value: Patient.enum.groups.SecondGroup, label: this.$t('Patients.Groups.second_group') },
        { value: Patient.enum.groups.ThirdGroup, label: this.$t('Patients.Groups.third_group') },
        { value: Patient.enum.groups.FourthGroup, label: this.$t('Patients.Groups.fourth_group') },
      ];
    },

    patientGroup: {
      get() {
        return this.patient.group;
      },
      async set(value) {
        const { data } = await Patient.updateGroup(this.patient.id, { group: value });
        this.$emit('update:patient', { ...this.patient, group: data.group });
      },
    },

    isParent() {
      return this.patient.parent === null;
    },

    isDoctor() {
      return this.user.role === User.enum.roles.Doctor;
    },
    isManager() {
      return this.user.role === User.enum.roles.Manager;
    },
    isDirector() {
      return this.user.role === User.enum.roles.Director;
    },
    isNurse() {
      return this.user.role === User.enum.roles.Nurse;
    },
    isWoman() {
      return this.patient.gender === User.enum.genders.Woman;
    },
    dateAndTime() {
      return this.patient.died_at.split(' ');
    },
    dieInfoItems() {
      return [
        {
          label: this.$t('Patients.DateOfDeath'),
          value: this.dateAndTime[0],
        },
        {
          label: this.$t('Base.Time'),
          value: this.dateAndTime[1],
        },
        {
          label: this.$t('Patients.PatientAge'),
          value: this.patient.age,
        },
        {
          label: this.$t('Patients.DeathReason'),
          custom: true,
        },
      ];
    },
    patientItems() {
      if (this.isParent) {
        return [
          {
            label: this.$t('User.Phone'),
            value: this.patient.phone,
          },
          {
            label: this.$t('User.Birthdate'),
            value: this.patient.birthdate,
          },
          {
            label: this.$t('User.Gender'),
            value: this.patient.gender ? this.$t('User.Genders.' + this.patient.gender) : null,
          },
          {
            label: this.$t('User.PlaceResidence'),
            value: this.patient.place_residence,
          },
          {
            label: this.$t('User.Children'),
            value: this.patient.childrens?.length > 0 ? this.$t('Base.Yes') : this.$t('Base.No'),
          },
          {
            label: this.$t('User.PINFL'),
            value: this.patient.pinfl ?? '...',
          },
          {
            label: this.$t('User.PassportSerialAndNumber'),
            value: `${this.patient.passport_serial ?? '...'} ${
              this.patient.passport_number ?? '...'
            }`,
          },
        ];
      } else {
        return [
          {
            label: this.$t('User.ParentFullName'),
            value: this.patient.parent.name,
          },
          {
            label: this.$t('User.Phone'),
            value: this.patient.phone,
          },
          {
            label: this.$t('User.Birthdate'),
            value: this.patient.birthdate,
          },
          {
            label: this.$t('User.Gender'),
            value: this.patient.gender ? this.$t('User.Genders.' + this.patient.gender) : null,
          },
          {
            label: this.$t('User.PlaceResidence'),
            value: this.patient.place_residence,
          },
        ];
      }
    },
    patientAmbulatoryCardPageLink() {
      return insertRouteParams({
        path: PATIENT_ROUTE.childrenMap.PATIENT_ROUTE_AMBULATORY_CARD._fullPath,
        params: {
          id: this.patient.id,
        },
      });
    },
    excludeColumns() {
      return ['patient', 'start_at', 'end_at', 'patient.phone', 'status', 'actions', 'actions-eye'];
    },
  },

  methods: {
    async onUserStatesChange(values) {
      await Patient.updateStatesById(this.patient.id, { user_state_ids: values });
    },

    goToCashPatient() {
      this.$router.push(
        `/cash/?user_name=${this.patient.name}&user_id=${
          this.patient.id
        }&start_at=01.01.20&end_at=${ISOStringToDateAppFormat(new Date().toISOString())}`
      );
    },

    goToAppointmentsPatient() {
      this.$router.push(
        `/appointments/?user_name=${this.patient.name}&user_id=${
          this.patient.id
        }&start_at=01.01.20&end_at=${ISOStringToDateAppFormat(new Date().toISOString())}`
      );
    },

    goToAnalysisPatient() {
      this.$router.push(
        `/orders?user_name=${this.patient.name}&user_id=${
          this.patient.id
        }&date_period=01.01.20,${ISOStringToDateAppFormat(new Date().toISOString())}`
      );
    },

    async editPatient() {
      const action = await this.$store.dispatch('modalAndDrawer/openModal', {
        component: CreateOrEditPatientModal,
        payload: {
          data: this.patient,
        },
      });

      if (action instanceof GlobalModalCloseAction) return;
      this.$emit('update:patient', action.data.patient);
    },

    async printBracelet() {
      if (this.braceletLoading) return;
      this.braceletLoading = true;

      try {
        await PrinterService.printBraceletByPatientId(this.patient.id);
      } catch (err) {
        this.$notify({
          type: 'error',
          title: axios.isAxiosError(err) ? err.message : String(err),
        });
      }

      this.braceletLoading = false;
    },

    async openTemplatesModal() {
      await this.$store.dispatch('modalAndDrawer/openModal', {
        component: TemplatesModal,
        payload: {
          id: this.patient.id,
        },
      });
    },
    async onApproveBtnClick(id) {
      await Dispensarie.deregistration(id);
      this.$emit('update:dispensarie');
    },
    async onApprovePregnancies(id) {
      await this.$store.dispatch('modalAndDrawer/openModal', {
        component: DeregisterPregnancyModal,
        payload: {
          pregnancyId: id,
        },
      });

      this.$emit('update:pregnancy');
    },
    async onApproveDisability(id) {
      await this.$store.dispatch('modalAndDrawer/openModal', {
        component: DeregisterDisabilityModal,
        payload: {
          disabilityId: id,
        },
      });

      this.$emit('update:disability');
    },
  },
  watch: {
    'patient.id': {
      async handler(value) {
        const currentUserStatesResult = await Patient.getStatesById(value);
        if (currentUserStatesResult) {
          this.currentUserStatesIds = currentUserStatesResult.data.map((item) => item.id);
          this.currentUserStates = currentUserStatesResult.data;
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/patients.locales.json" />
<i18n src="@/locales/user.locales.json" />
<i18n src="@/locales/appointments.locales.json" />
<i18n src="@/locales/laboratory.locales.json" />
<i18n src="@/locales/hospital.locales.json" />
<i18n src="@/locales/invoices.locales.json" />
<i18n src="@/locales/patronage.locales.json" />
<i18n src="./index.locales.json" />
