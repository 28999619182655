<template>
  <DefaultHeader />
</template>

<script>
import DefaultHeader from '@/components/layouts/assets/DefaultHeader/index.vue';

export default {
  name: 'LaboratoryHeader',
  components: { DefaultHeader },
};
</script>

<style lang="scss" src="./index.scss" />
