<template>
  <VeeForm
    class="outcome-product-table-filling-form"
    as="ElForm"
    :validation-schema="validationSchema"
    v-slot="{ meta }"
    @submit="onSubmit">
    <div class="outcome-product-table-filling-form__field-group">
      <FormSearchSelectField
        field-name="product_id"
        field-object-name="product"
        :model-for-use="Product"
        method-name="getItemsWithWarehouses"
        :placeholder="$t('Product.ProductSearch')"
        @select="onProductSelect" />
      <FormTextField v-show="false" field-name="product.title" disabled />
      <FormTextField
        field-name="product.article"
        :placeholder="$t('Nomenclature.VendorCode')"
        disabled
        :in-column="false" />
      <FormSelectField
        :items="outcomeTypes"
        field-name="type"
        field-object-name="typeObject"
        :placeholder="$t('Outcome.OutcomeType')"
        value-key="value"
        label-key="label"
        ref="typeSelect" />
      <FormNumberField
        field-name="count"
        :placeholder="$t('Nomenclature.Qty')"
        :in-column="false"
        :min="1"
        :max="countMax.value"
        ref="countInput" />
      <FormTextField v-show="false" field-name="warehouse.title" disabled />
      <FormSelectField
        :items="warehouses.items"
        field-name="warehouse_id"
        field-object-name="warehouse"
        :placeholder="$t('Warehouse.Warehouse')"
        @select="onWarehouseSelect"
        ref="warehouseSelect" />
      <div />
    </div>
    <ElButton type="primary" native-type="submit" :disabled="!meta.dirty"
      >{{ `+ ${$t('Base.Add')}` }}
    </ElButton>
  </VeeForm>
</template>

<script lang="ts">
export default {
  name: 'OutcomeProductsTableFillingForm',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { Product } from '@/models/warehouse/Product.model';
import { ProductWarehouseDto, ProductWithWarehousesDto } from '@/types/api';
import { SubmissionHandler, Form as VeeForm } from 'vee-validate';
import { object, number, string } from 'yup';
import { I18nService } from '@/services/i18n.service';
import { reactive, ref } from 'vue';

import FormTextField from '@/components/common/form/ui/FormTextField/index.vue';
import FormNumberField from '@/components/common/form/ui/FormNumberField/index.vue';
import FormSearchSelectField from '@/components/common/form/ui/FormSearchSelectField/index.vue';
import FormSelectField from '@/components/common/form/ui/FormSelectField/index.vue';

const emit = defineEmits(['table:add']);
const warehouseSelect = ref<InstanceType<typeof FormSelectField> | null>(null);
const countInput = ref<InstanceType<typeof FormNumberField> | null>(null);
const typeSelect = ref<InstanceType<typeof FormSelectField> | null>(null);

const validationSchema = object({
  product_id: number().nullable().required(I18nService.t('Validation.RequiredField')),
  warehouse_id: number().nullable().required(I18nService.t('Validation.RequiredField')),
  count: number().nullable().required(I18nService.t('Validation.RequiredField')),
  type: string().nullable().required(I18nService.t('Validation.RequiredField')),
});

const outcomeTypes = [
  { value: 'sale', label: I18nService.t('Outcome.Sale') },
  { value: 'write_off', label: I18nService.t('Outcome.WriteOff') },
];

const warehouses = reactive<{ items: ProductWarehouseDto[] }>({
  items: [],
});

const countMax = reactive<{ value: number }>({ value: 1 });

const onSubmit: SubmissionHandler<Record<string, unknown>> = async (values, actions) => {
  actions.resetForm();
  emit('table:add', values);
};

const onProductSelect = (product: ProductWithWarehousesDto) => {
  warehouses.items = product.warehouses ?? [];
  countInput.value?.setValue(1);
  warehouseSelect.value?.setValue(warehouses.items[0]?.id);
  warehouseSelect.value?.setObjectValue(warehouses.items[0] ?? {});
  countMax.value = warehouses.items[0]?.count ?? 1;
  typeSelect.value?.setValue('write_off');
};

const onWarehouseSelect = (value: ProductWarehouseDto) => {
  const finded = warehouses.items.find((item) => item.id === value.id);
  countMax.value = finded?.count ?? 1;
};
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/validation.locales.json" />
<i18n src="@/locales/warehouse.locales.json" />
