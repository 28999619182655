<template>
  <div class="default-header">
    <div class="default-header-left-content">
      <PatientsSearch class="default-header__search" />
    </div>
    <!--    <NotificationsHeader></NotificationsHeader>-->

    <div class="default-header__actions">
      <slot name="actions"> </slot>
    </div>

    <LangSelect></LangSelect>
    <!-- <NotificationsDrawer /> -->
    <ElDivider direction="vertical" />
    <LayoutUserDropdown />
  </div>
</template>

<script>
import * as icons from '@/enums/icons.enum.js';

import PatientsSearch from '@/components/patients/PatientsSearch/index.vue';
import LayoutUserDropdown from '@/components/layouts/assets/LayoutUserDropdown/index.vue';
import LangSelect from '@/components/LangSelect/index.vue';
// import NotificationsDrawer from '@/components/NotificationsDrawer/index.vue';

export default {
  name: 'DefaultHeader',
  components: {
    LayoutUserDropdown,
    PatientsSearch,
    LangSelect,
    // NotificationsDrawer,
  },

  setup: () => ({
    icons: icons,
  }),
};
</script>

<style lang="scss" src="./index.scss" />
<i18n src="./index.locales.json" />
