<template>
  <ElCard
    class="patient-groups-statistics-card"
    v-loading="props.loading"
    :header="props.title"
    shadow="never"
    @click="handleClickCard">
    <div class="patient-groups-statistics-card__content">
      <div class="patient-groups-statistics-card__info patient-groups-statistics-card-info">
        <div class="patient-groups-statistics-card-info__value">{{ value }}</div>
        <div class="patient-groups-statistics-card-info__total-value">
          {{ subtitle }}
        </div>
      </div>

      <Vue3Apexcharts
        class="patient-groups-statistics-card__chart"
        type="pie"
        width="120"
        :options="chartOptions"
        :series="series"
        ref="chartRef" />
    </div>

    <div class="patient-groups-statistics-card__legend">
      <div class="patient-groups-statistics-card__legend-item">
        <div
          class="patient-groups-statistics-card__legend-icon"
          :style="{ background: props.colors[0] }" />
        <div class="patient-groups-statistics-card__legend-text">
          {{ props.labels[0] }}
        </div>
      </div>
      <div class="patient-groups-statistics-card__legend-item">
        <div
          class="patient-groups-statistics-card__legend-icon"
          :style="{ background: props.colors[1] }" />
        <div class="patient-groups-statistics-card__legend-text">
          {{ props.labels[1] }}
        </div>
      </div>
    </div>
  </ElCard>
</template>

<script lang="ts">
export default {
  name: 'StatisticsCard',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import { CardInfoType } from './types';

import Vue3Apexcharts from 'vue3-apexcharts';

interface Props {
  title: string;
  count: number;
  totalCount: number;
  loading: boolean;
  colors: string[];
  labels: string[];
  formatter?: (value: number) => string;
  subtitle: string;
}

const emit = defineEmits<{
  (e: 'click-card', data: CardInfoType): void;
}>();

const props = defineProps<Props>();

const value = computed(() =>
  props.formatter ? props.formatter(props.count) : String(props.count)
);
const series = computed(() => [props.count, props.totalCount - props.count]);
const chartOptions = computed(() => ({
  chart: {
    width: 120,
    type: 'pie',
  },
  plotOptions: {
    pie: {
      startAngle: 0,
      endAngle: 360,
    },
  },
  colors: props.colors,
  labels: props.labels,
  dataLabels: {
    enabled: false,
  },
  legend: {
    show: false,
  },
  fill: {
    type: 'gradient',
  },
}));

const chartRef = ref();

const handleClickCard = () => {
  emit('click-card', {
    labels: props.labels,
    title: props.title,
  });
};

watch(
  () => series.value,
  (value) => {
    chartRef.value?.chart?.updateSeries(value);
  },
  { deep: true }
);

watch(
  () => chartOptions.value,
  (value) => {
    chartRef.value?.chart?.updateOptions(value);
  },
  { deep: true }
);
</script>

<style lang="scss" src="./index.scss" />
