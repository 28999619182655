<template>
  <ElDialog
    class="queue-print-modal"
    width="400px"
    :title="$t('Recommendations.FavoriteServices')"
    :model-value="modelValue"
    @update:model-value="$emit('update:modelValue', $event)">
    <FieldGroupWrapper :title="$t('Appointments.AppointmentInformation')">
      <div class="queue-print-modal__fields">
        <ElFormItem :label="$t('User.FullName')">
          {{ props.appointment.patient?.name || '-' }}
        </ElFormItem>

        <ElFormItem :label="$t('User.QueueNumber')">
          {{ props.appointment.patient?.humId || '-' }}
        </ElFormItem>

        <ElFormItem :label="$t('DateAndTime.Time')">
          {{ props.appointment.start_at || '-' }}
        </ElFormItem>

        <ElFormItem :label="$t('Doctors.Doctor')">
          {{ props.appointment.doctor?.name || '-' }}
        </ElFormItem>

        <ElFormItem :label="$t('Base.Cabinet')">
          {{ props.appointment.doctor?.cabinet || '-' }}
        </ElFormItem>
      </div>
    </FieldGroupWrapper>

    <FormActionsWrapper>
      <ElButton type="primary" plain @click="callback">
        <template #icon>
          <UiIcon :icon="icons.PRINTER" />
        </template>
        {{ $t('Base.PrintTicket') }}
      </ElButton>
    </FormActionsWrapper>
  </ElDialog>
</template>

<script lang="ts">
export default {
  name: 'QueuePrintModal',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import * as icons from '@/enums/icons.enum';
import { AnyObject } from '@/types/common';

import FieldGroupWrapper from '@/components/common/form/FieldGroupWrapper/index.vue';
import FormActionsWrapper from '@/components/common/form/FormActionsWrapper/index.vue';

const props = defineProps<{
  modelValue: boolean;
  appointment: AnyObject;
  callback: () => void;
}>();

defineEmits(['update:modelValue', 'action']);
</script>

<style lang="scss" src="./index.scss"></style>
