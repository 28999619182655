<template>
  <UiTableWithPagination
    :data="props.items"
    :loading="props.loading"
    :page="props.page"
    :per-page="props.perPage"
    :total="props.total"
    @update:page="$emit('update:page', $event)"
    @update:per-page="$emit('update:perPage', $event)"
    @row-click="goToPatient">
    <ElTableColumn prop="user.name" :label="$t('Patients.Patient')" />
    <ElTableColumn prop="user.phone" :label="$t('Patronage.PhoneNumber')" />
    <ElTableColumn prop="user_states" :label="$t('Patronage.HealthStatus')" />
    <ElTableColumn prop="microdistrict.title" :label="$t('Patients.Microdistrict')" />
    <ElTableColumn prop="nurse.name" :label="$t('Patronage.Nurse')" />
    <ElTableColumn prop="status" :label="$t('Base.Status')">
      <template #default="{ row }">
        <StatusTag
          :map-status="mapStatus"
          :title="row.status ? $t(`Patronage.Statuses.${row.status}`) : ''"
          :value="row.status" />
      </template>
    </ElTableColumn>
    <ElTableColumn prop="patronage_date" :label="$t('Base.Time')" />
    <ElTableColumn prop="actions" :label="$t('Base.Actions')" width="160px">
      <template #default="{ row }">
        <div class="patronages-table__actions">
          <ElButton type="primary" size="small" plain @click.stop="goToMedicalCard(row)">
            {{ $t('Patronage.MedicalCard') }}
          </ElButton>
        </div>
      </template>
    </ElTableColumn>
  </UiTableWithPagination>
</template>

<script lang="ts">
export default {
  name: 'PatronagesTable',
  inheritAttrs: false,
  customOptions: {},
};
</script>
<script lang="ts" setup>
import { PatronageShortDto } from '@/types/api/patronage/patronage';
import { MapStatusType } from '@/components/common/StatusTag/types';
import { useRouter } from 'vue-router';
import { PATRONAGE_ROUTE } from '@/router/patronage.routes';

import UiTableWithPagination from '@/components/ui/UiTableWithPagination/index.vue';
import StatusTag from '@/components/common/StatusTag/index.vue';
import { PATIENT_ROUTE } from '@/router/patients.routes';

defineEmits(['update:page', 'update:perPage']);

const props = defineProps<{
  loading: boolean;
  items: PatronageShortDto[];
  total: number;
  page: number;
  perPage: number;
}>();

const mapStatus: MapStatusType = {
  bypassed: 'success',
  waiting: 'warning',
  failed: 'danger',
  in_progress: 'info',
  on_the_way: 'info',
};

const router = useRouter();

const goToPatient = ({ id }: { id: number }) => {
  router.push({
    name: PATRONAGE_ROUTE.name,
    params: { id },
  });
};

const goToMedicalCard = (row: PatronageShortDto) => {
  router.push({
    name: PATIENT_ROUTE.name,
    params: {
      id: row.user.id,
    },
  });
};
</script>

<style lang="scss" scoped>
.patronages-table__actions {
  display: flex;
  justify-content: start;
}
</style>
<i18n src="@/locales/patients.locales.json" />
<i18n src="@/locales/patronage.locales.json" />
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/warehouse.locales.json" />
