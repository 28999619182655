<!-- TODO: отрефакторить -->
<template>
  <LayoutByUserRole content-class="v-statistic-dashboard">
    <LayoutContentHeader>
      <ElDatePicker
        v-model="date.value"
        type="daterange"
        unlink-panels
        :format="DATE_FORMAT"
        :value-format="DATE_FORMAT"
        :start-placeholder="$t('DateAndTime.StartDate')"
        :end-placeholder="$t('DateAndTime.EndDate')" />

      <template #actions>
        <UiSelect
          v-model="currentReport"
          :options="reportOptions"
          value-key="type"
          label-key="title"
          clearable
          :placeholder="$t('Base.SelectReport')" />
        <a :href="exportDataURL" download>
          <ElButton plain text>
            <template #icon>
              <UiIcon :icon="icons.DOWNLOAD" />
            </template>
            {{ $t('Base.Report') }}
          </ElButton>
        </a>
      </template>
    </LayoutContentHeader>

    <div class="v-statistic-dashboard__content v-statistic-dashboard-content">
      <DashboardPatientGroupsStatistics
        class="v-statistic-dashboard__patient-groups"
        :query="patientGroupStatisticsQuery" />
      <PregnantsStatisticsBlock
        class="v-statistic-dashboard__pregnants"
        :query="pregnantStatisticsQuery" />
      <div class="v-statistic-dashboard__cards-wrapper">
        <StatisticSimpleCard
          v-for="card in simpleCards"
          :key="card.title"
          :title="card.title"
          :loading="card.loading"
          :value="card.value"
          :percentage="card.percentage"
          :chart="card.chart"
          :hide-chart="card.hideChart"
          :value-formatter="card.valueFormatter"
          :chart-color="card.chartColor" />
      </div>

      <div class="v-statistic-dashboard__cards-wrapper">
        <AppointmentsOnTimeStatisticCard
          class="v-statistic-dashboard__appointment-by-time-card"
          :loading="appointmentsOnTime.loading"
          :value="appointmentsOnTime.data.value"
          :percentage="appointmentsOnTime.data.percentage" />
        <PatientWaitingTimeStatisticCard
          class="v-statistic-dashboard__patient-waiting-time-card"
          :loading="patientAverageWaiting.loading"
          :time-waiting="patientAverageWaiting.data.timeWaiting"
          :time-appointment="patientAverageWaiting.data.timeAppointment" />
        <CompletedAppointmentsStatisticCard
          class="v-statistic-dashboard__completed-appointments-card"
          :loading="completedAppointments.loading"
          :value="completedAppointments.data.value"
          :percentage="completedAppointments.data.percentage" />
      </div>
      <ClinicWorkloadStatisticCard
        class="v-statistic-dashboard__clinic-workload-card"
        :period="date.value" />
      <ElCard
        class="v-statistic-dashboard__feedbacks"
        v-loading="feedback.loading"
        :header="$t('Statistic.PatientFeedback')"
        shadow="never">
        <div class="v-statistic-dashboard__feedback">
          <Feedback v-for="item in feedback.data" :item="item" :key="item.id" show-type-feedback />
        </div>
      </ElCard>
    </div>
  </LayoutByUserRole>
</template>

<script>
import { DATE_FORMAT } from '@/config/dateAndTime.config';
import { useDatePeriod } from '@/hooks/query';
import { getMonthPeriod } from '@/utils/dateAndTime.utils';
import { formatPrice } from '@/utils/price.util';
import { Invoice } from '@/models/Invoice.model';
import { Patient } from '@/models/Patient.model';
import { Appointment } from '@/models/appointment/Appointment.model';
import { Feedback as FeedbackModel } from '@/models/feedback/Feedback.model';
import { Export } from '@/models/Export.model';
import { mergeOrCreateQuery } from '@/utils/http.util';
import { deleteEmptyValueKeys } from '@/utils/object.util';
import { I18nService } from '@/services/i18n.service';
import { ApiService } from '@/services/api.service';
import { DeviceService } from '@/services/device.service';

import LayoutByUserRole from '@/components/layouts/LayoutByUserRole/index.vue';
import LayoutContentHeader from '@/components/layouts/assets/LayoutContentHeader/index.vue';
import StatisticSimpleCard from '@/components/statistic/StatisticSimpleCard/index.vue';
import ClinicWorkloadStatisticCard from '@/components/statistic/ClinicWorkloadStatisticCard/index.vue';
import AppointmentsOnTimeStatisticCard from '@/components/statistic/AppointmentsOnTimeStatisticCard/index.vue';
import CompletedAppointmentsStatisticCard from '@/components/statistic/CompletedAppointmentsStatisticCard/index.vue';
import PatientWaitingTimeStatisticCard from '@/components/statistic/PatientWaitingTimeStatisticCard/index.vue';
import DashboardPatientGroupsStatistics from '@/components/statistic/DashboardPatientGroupsStatistics/index.vue';
import PregnantsStatisticsBlock from '@/components/analytics/pregnants/PregnantsStatisticsBlock/index.vue';
import Feedback from '@/components/common/Feedback/Index.vue';
import UiSelect from '@/components/ui/UiSelect/index.vue';
import * as icons from '@/enums/icons.enum';

// TODO: отрефакторить, слишком много логики в одном месте и описать возвращаемые типы из запросов на статистику в моделях
export default {
  name: 'VStatisticDashboard',
  components: {
    UiSelect,
    LayoutContentHeader,
    LayoutByUserRole,
    StatisticSimpleCard,
    ClinicWorkloadStatisticCard,
    AppointmentsOnTimeStatisticCard,
    CompletedAppointmentsStatisticCard,
    PatientWaitingTimeStatisticCard,
    Feedback,
    DashboardPatientGroupsStatistics,
    PregnantsStatisticsBlock,
  },
  setup: () => ({
    date: useDatePeriod(getMonthPeriod()),
    DATE_FORMAT,
  }),

  data() {
    return {
      feedback: {
        data: [],
        loading: false,
      },
      invoicesIncome: {
        loading: false,
        data: {
          value: null,
          percent_change: null,
          chart: null,
        },
      },
      patientsCount: {
        loading: false,
        data: {
          value: null,
          percent_change: null,
          chart: null,
        },
      },
      appointmentsCount: {
        loading: false,
        data: {
          value: null,
          percent_change: null,
          chart: null,
        },
      },
      repeatPatients: {
        loading: false,
        data: {
          value: null,
          percent_change: null,
          chart: null,
        },
      },

      appointmentsOnTime: {
        loading: false,
        data: {
          value: null,
          percentage: null,
        },
      },
      completedAppointments: {
        loading: false,
        data: {
          value: null,
          percentage: null,
        },
      },
      patientAverageWaiting: {
        loading: false,
        data: {
          timeWaiting: null,
          timeAppointment: null,
        },
      },
      currentReport: null,
      reportOptions: [],
    };
  },

  computed: {
    patientGroupStatisticsQuery() {
      return {
        start_at: this.date.value[0],
        end_at: this.date.value[1],
      };
    },
    pregnantStatisticsQuery() {
      return {
        start_at: this.date.value[0],
        end_at: this.date.value[1],
        is_pregnancy: 1,
      };
    },
    icons() {
      return icons;
    },
    simpleCards() {
      return [
        {
          title: this.$t('Base.Patients'),
          loading: this.patientsCount.loading,
          value: this.patientsCount.data.value,
          percentage: this.patientsCount.data.percent_change,
          chart: this.patientsCount.data.chart,
          chartColor: '#3879F8',
          hideChart: true,
        },
        {
          title: this.$t('Statistic.NumberOfAppointments'),
          loading: this.appointmentsCount.loading,
          value: this.appointmentsCount.data.value,
          percentage: this.appointmentsCount.data.percent_change,
          chart: this.appointmentsCount.data.chart,
          chartColor: '#3879F8',
          hideChart: true,
        },
        {
          title: this.$t('Invoices.RepeatPatients'),
          loading: this.repeatPatients.loading,
          value: this.repeatPatients.data.value,
          percentage: this.repeatPatients.data.percent_change,
          chart: this.repeatPatients.data.chart,
          chartColor: '#3879F8',
          hideChart: true,
          valueFormatter: (value) => (value ? `${value}%` : '0%'),
        },
      ];
    },
    exportDataURL() {
      return mergeOrCreateQuery({
        url: Export.exportDataURL,
        query: deleteEmptyValueKeys({
          start_at: this.date.value[0],
          end_at: this.date.value[1],
          type: this.currentReport,
          lang: I18nService.getLocale(),
          token: ApiService.getToken(),
          device_id: DeviceService.getDeviceId(),
        }),
      });
    },
  },

  watch: {
    'date.value': {
      handler(value) {
        if (value) this.getData();
      },
      immediate: true,
    },
  },

  methods: {
    getData() {
      this.getInvoiceIncome();
      this.getPatientsCount();
      this.getAppointmentsCount();
      this.getRepeatPatients();

      this.getAppointmentsOnTime();
      this.getCompletedAppointments();
      this.getPatientAverageWaiting();
      this.getFeedbacks();
    },

    async getFeedbacks() {
      this.feedback.loading = true;
      const { data } = await FeedbackModel.getItems({
        start_at: this.date.value[0],
        end_at: this.date.value[1],
      });

      if (data?.length) {
        this.feedback.data = data;
      }

      this.feedback.loading = false;
    },

    async getAppointmentsOnTime() {
      this.appointmentsOnTime.loading = true;

      const { data } = await Appointment.getOnTimeStatistic({
        startAt: this.date.value[0],
        endAt: this.date.value[1],
      });

      this.appointmentsOnTime.data.value = data.value;
      this.appointmentsOnTime.data.percentage = data.percent_change;

      this.appointmentsOnTime.loading = false;
    },

    async getCompletedAppointments() {
      this.completedAppointments.loading = true;

      const { data } = await Appointment.getSuccessfulStatistic({
        startAt: this.date.value[0],
        endAt: this.date.value[1],
      });

      this.completedAppointments.data.value = data.value;
      this.completedAppointments.data.percentage = data.percent_change;

      this.completedAppointments.loading = false;
    },

    async getPatientAverageWaiting() {
      this.patientAverageWaiting.loading = true;

      const { data } = await Appointment.getAverageWaitingStatistic({
        startAt: this.date.value[0],
        endAt: this.date.value[1],
      });

      this.patientAverageWaiting.data.timeWaiting = data.time_waiting;
      this.patientAverageWaiting.data.timeAppointment = data.time_appointment;

      this.patientAverageWaiting.loading = false;
    },

    async getInvoiceIncome() {
      this.invoicesIncome.loading = true;

      const { data } = await Invoice.getIncomeByPeriod({
        startAt: this.date.value[0],
        endAt: this.date.value[1],
      });

      const income = Math.ceil(data.value / 1e6);
      this.invoicesIncome.data = {
        ...data,
        value: formatPrice({ price: income }),
      };

      this.invoicesIncome.loading = false;
    },

    async getPatientsCount() {
      this.patientsCount.loading = true;

      const { data } = await Patient.getCountStatisticByPeriod({
        startAt: this.date.value[0],
        endAt: this.date.value[1],
      });
      this.patientsCount.data = data;

      this.patientsCount.loading = false;
    },

    async getAppointmentsCount() {
      this.appointmentsCount.loading = true;

      const { data } = await Appointment.getCountStatistic({
        startAt: this.date.value[0],
        endAt: this.date.value[1],
      });
      this.appointmentsCount.data = data;

      this.appointmentsCount.loading = false;
    },

    async getRepeatPatients() {
      this.repeatPatients.loading = true;

      const { data } = await Patient.getRepeatStatisticByPeriod({
        startAt: this.date.value[0],
        endAt: this.date.value[1],
      });
      this.repeatPatients.data = data;

      this.repeatPatients.loading = false;
    },
    async getExportOptions() {
      const { data } = await Export.getExports();
      this.reportOptions = data.data;
    },
  },
  mounted() {
    this.getExportOptions();
  },
};
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/dateAndTime.locales.json" />
<i18n src="@/locales/appointments.locales.json" />
<i18n src="@/locales/invoices.locales.json" />
<i18n src="@/locales/statistic.locales.json" />
