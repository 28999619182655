import axios from 'axios';
import { CRUDModel } from '@/models/CRUD.model';
import { ApiService } from '@/services/api.service';
import { ElNotification } from 'element-plus';

/**
 * @class Hospital
 * @extends CRUDModel
 */
export class Hospital extends CRUDModel {
  static modelName = 'hospital';
  static tableName = 'hospitals';

  /**
   * @param [payload]
   * @param {number} payload.user_id
   * @param {Patient|object} payload.user
   * @param {number} payload.doctor_id
   * @param {Doctor|object} payload.doctor
   * @param {number} payload.chamber_id
   * @param {Chamber|object} payload.chamber
   * @param {Array<number>} payload.chamber_ids
   * @param {Array<Chamber|object>} payload.chambers
   * @param {number} payload.department_id
   * @param {Department|object} payload.department
   *
   * @param {string} payload.status
   * @param {number} payload.count_days
   * @param {string} payload.discharged_at DATE_FORMAT
   * @param {Array<number>} payload.invoice_ids
   * @param {Array<Invoice|object>} payload.invoices
   * @param {Array<number>} payload.treatment_ids
   * @param {Array<Treatment|object>} payload.treatments
   * @param {string} payload.created_at DATE_FORMAT
   */
  constructor(payload) {
    super(payload);

    this.user_id = payload?.user_id ?? null;
    this.user = payload?.user ?? null;
    this.doctor_id = payload?.doctor_id ?? null;
    this.doctor = payload?.doctor ?? null;
    this.chamber_id = payload?.chamber_id ?? null;
    this.chamber = payload?.chamber ?? null;
    this.chamber_ids = payload?.chamber_ids ?? [];
    this.chambers = payload?.chambers ?? [];
    this.department_id = payload?.department_id ?? null; // not in listing
    this.department = payload?.department ?? null;

    this.status = payload?.status ?? null;
    this.count_days = payload?.count_days ?? null;
    this.discharged_at = payload?.discharged_at ?? null;
    this.invoice_ids = payload?.invoice_ids ?? [];
    this.invoices = payload?.invoices ?? [];
    this.treatment_ids = payload?.treatment_ids ?? [];
    this.treatments = payload?.treatments ?? [];
    this.created_at = payload?.created_at ?? null;
  }

  static exportDataURL = import.meta.env.VITE_API_URL + `exports/${this.tableName}`;

  /**
   * @param {number} id
   * @returns {Promise<{data: response.data, response: Promise<AxiosResponse<any>>, invoice: Invoice|object}>}
   */
  static async getInvoiceById(id) {
    const response = await ApiService.post(`${this.tableName}/${id}/invoice`);
    return {
      response,
      data: response.data,
      invoice: response.data.data,
    };
  }

  /**
   * @param {number} id
   * @param {string} status
   * @returns {Promise<{data: response.data, response: Promise<AxiosResponse<any>>, hospital: Hospital|object}>}
   */
  static async updateStatusById(id, status) {
    const response = await ApiService.put(`${this.tableName}/${id}/${status}`);
    return {
      response,
      data: response.data,
      hospital: response.data.data,
    };
  }

  static async getStatisticByPatientAmount() {
    try {
      const response = await ApiService.get(`statistics/${this.tableName}/count`);
      return {
        response,
        data: response.data,
      };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: axios.isAxiosError(err) ? err.message : String(err),
      });
    }
  }

  static enum = {
    statuses: {
      IN_PROGRESS: 'in_progress',
      COMPLETED: 'completed',
      CANCELED: 'canceled',
    },
  };
}
