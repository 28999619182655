import { onlyLoggedInMiddleware } from '@/middlewares/onlyLoggedIn.middleware';
import { byUserRolesMiddleware } from '@/middlewares/byUserRoles.middleware';
import { User } from '@/models/User.model';

export const VACCINATION_PATH_PREFIX = '/vaccination';

export const VACCINATION_ROUTE = {
  name: 'VACCINATION',
  path: `${VACCINATION_PATH_PREFIX}`,
  component: 'VVaccinations',
  beforeEnter: [
    onlyLoggedInMiddleware,
    byUserRolesMiddleware([User.enum.roles.Doctor, User.enum.roles.Director]),
  ],
  props: true,
  meta: {
    title: 'Analytics.Vaccination',
  },
};

export const VACCINATION_MICRODISTRICTS_ROUTE = {
  name: 'VACCINATION_MICRODISTRICTS',
  path: `${VACCINATION_PATH_PREFIX}/:vaccine_id/:vaccine_title`,
  component: 'VVaccinationMicrodistricts',
  beforeEnter: [
    onlyLoggedInMiddleware,
    byUserRolesMiddleware([User.enum.roles.Doctor, User.enum.roles.Director]),
  ],
  props: true,
};

export const VACCINATION_GROUPS_ROUTE = {
  name: 'VACCINATION_GROUPS',
  path: `${VACCINATION_PATH_PREFIX}/:vaccine_id/:vaccine_title/:microdistrict_id`,
  component: 'VVaccinationGroups',
  beforeEnter: [
    onlyLoggedInMiddleware,
    byUserRolesMiddleware([User.enum.roles.Doctor, User.enum.roles.Director]),
  ],
  props: true,
};

export const routes = [
  VACCINATION_ROUTE,
  VACCINATION_MICRODISTRICTS_ROUTE,
  VACCINATION_GROUPS_ROUTE,
];
