import { I18nService } from '@/services/i18n.service';
import { object, string } from 'yup';
import { computed } from 'vue';

export const validationSchema = computed(() => {
  return object().shape({
    vaccine_id: string().required(I18nService.t('Validation.RequiredField')),
    vaccine_date: string().required(I18nService.t('Validation.RequiredField')),
  });
});
