<template>
  <ElTable
    class="documents-table"
    :data="items"
    :empty-text="$t('Base.NoData')"
    v-bind="$attrs"
    :show-header="false"
    :max-height="500"
    v-loading="loading">
    <ElTableColumn prop="info" column-key="info">
      <template #default="{ row }">
        <span class="templates-table__item templates-table__title">{{ row.info.title }}</span>
        <span class="templates-table__item templates-table__date">{{ row.info.created_at }} </span>
      </template>
    </ElTableColumn>

    <ElTableColumn prop="actions" width="200" :label="$t('Base.Actions')">
      <template #default="{ row }">
        <div class="templates-table__actions">
          <ElButton type="primary" text @click="goToCard(row.id)">
            <template #icon>
              <UiIcon :icon="icons.EYE" />
            </template>
            {{ $t('Base.Open') }}
          </ElButton>
        </div>
      </template>
    </ElTableColumn>
  </ElTable>
</template>

<script lang="ts">
export default {
  name: 'TemplatesTable',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script setup lang="ts">
import * as icons from '@/enums/icons.enum';
import { useRouter } from 'vue-router';
import { PATIENT_DOCUMENTS_ROUTE } from '@/router/patients.routes';

const router = useRouter();
defineProps<{
  loading: boolean;
  items: Array<{
    id: number;
    info: {
      created_at: string;
      title: string;
    };
  }>;
}>();

const goToCard = (id: number) => {
  router.push({
    name: PATIENT_DOCUMENTS_ROUTE.name,
    params: {
      id,
    },
  });
};
</script>

<i18n src="@/locales/base.locales.json" />

<style lang="scss" src="./index.scss"></style>
