<template>
  <UiTableWithPagination
    :data="items"
    :loading="loading"
    :page="page"
    :per-page="perPage"
    :total="total"
    @update:page="$emit('update:page', $event)"
    @update:per-page="$emit('update:perPage', $event)">
    <ElTableColumn prop="start_at" :label="$t('DateAndTime.StartingDate')" />
    <ElTableColumn prop="end_at" :label="$t('DateAndTime.CompletionDate')" />
    <ElTableColumn prop="user.name" :label="$t('Doctors.Doctor')" />
  </UiTableWithPagination>
</template>

<script lang="ts">
export default {
  name: 'VisitLogTable',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { WorkTrackingDto } from '@/types/api';

import UiTableWithPagination from '@/components/ui/UiTableWithPagination/index.vue';

defineEmits(['update:page', 'update:perPage', 'modal:open']);

defineProps<{
  loading: boolean;
  items: WorkTrackingDto[];
  total: number;
  page: number;
  perPage: number;
}>();
</script>

<style lang="scss" scoped></style>
