import { ApiService } from '@/services/api.service';
import { TokenService } from '@/services/token.service';
import { ClientStorageService } from '@/services/clientStorage.service';
import { Router } from '@/router';
import { LOGIN_ROUTE } from '@/router/auth.routes';
import { ActionContext, CommitOptions, DispatchOptions, Store as VuexStore } from 'vuex';
import { RootStateType } from '@/store';
import { UserFullDto, UserCredentialsDto, AuthorizationRequestDto } from '@/types/api';
import { AnyObject } from '@/types/common';

const enums = {
  DEVICE_ID_KEY: 'device_id',
  USER_KEY: 'user',
};

export type AuthState = {
  user: UserFullDto;
  userCredentials: UserCredentialsDto;
  isAuthorized: boolean;
};

type Context = ActionContext<AuthState, RootStateType>;

type Mutations = {
  ['auth/SET_USER'](state: AuthState, payload: UserFullDto): void;
  ['auth/SET_USER_CREDENTIALS'](state: AuthState, payload: UserCredentialsDto): void;
  ['auth/SET_IS_AUTHORIZED'](state: AuthState, payload: boolean): void;
  ['auth/LOGIN'](state: AuthState, { user }: { user: UserFullDto }): void;
  ['auth/LOGOUT'](state: AuthState): void;
};

type ActionAugments = Omit<ActionContext<AuthState, RootStateType>, 'commit'> & {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
};

export type Actions = {
  ['auth/logIn'](
    context: ActionAugments,
    { email, password, activationKey }: AuthorizationRequestDto
  ): Promise<void>;
  ['auth/setUserCredentials'](context: ActionAugments, userCredentials: UserCredentialsDto): void;
  ['auth/checkAndSetUserAndTokenFromClientStorage'](
    context: ActionAugments,
    item: AnyObject
  ): Promise<void>;
  ['auth/logout'](context: ActionAugments): Promise<void>;
};

export default {
  name: 'auth',
  namespaced: true,
  state: (): AuthState => ({
    user: {} as UserFullDto,
    userCredentials: {} as UserCredentialsDto,
    isAuthorized: false,
  }),
  mutations: {
    SET_USER(state: AuthState, payload: UserFullDto): void {
      state.user = payload;
    },
    SET_USER_CREDENTIALS(state: AuthState, payload: UserCredentialsDto): void {
      state.userCredentials = payload;
    },
    SET_IS_AUTHORIZED(state: AuthState, payload: boolean): void {
      state.isAuthorized = payload;
    },
    LOGIN(state: AuthState, { user }: { user: UserFullDto }): void {
      state.user = user;
      state.isAuthorized = true;
      state.userCredentials = {} as UserCredentialsDto;
    },
    LOGOUT(state: AuthState): void {
      state.user = {} as UserFullDto;
      state.isAuthorized = false;
    },
  },
  actions: {
    async logIn(
      ctx: Context,
      { email, password, activationKey }: AuthorizationRequestDto
    ): Promise<void> {
      const { data } = await ApiService.post('/auth/login', {
        email,
        password,
        key_activating: activationKey,
      });

      // TODO typed ApiService
      ApiService.setToken(data.access_token);
      TokenService.setTokens({
        accessToken: data.access_token,
        refreshToken: data.refresh_token,
      });

      ClientStorageService.setItem(enums.USER_KEY, data.user);
      ctx.commit('LOGIN', { user: data.user });
    },
    setUserCredentials({ commit }: Context, userCredentials: UserCredentialsDto): void {
      commit('SET_USER_CREDENTIALS', userCredentials);
    },
    async checkAndSetUserAndTokenFromClientStorage({ commit }: Context): Promise<void> {
      const { accessToken, refreshToken } = TokenService.getTokens();
      if (!accessToken || !refreshToken) return;

      if (TokenService.checkNeedRefreshTokens()) {
        TokenService.refreshTokens();
      }

      // TODO typed ApiService
      ApiService.setToken(accessToken);
      commit('SET_USER', ClientStorageService.getItem(enums.USER_KEY));
      commit('SET_IS_AUTHORIZED', true);
    },
    async logout({ commit, dispatch }: Context): Promise<void> {
      commit('LOGOUT');
      await Router.push(LOGIN_ROUTE.path);
      // TODO typed ApiService
      ApiService.removeToken();
      TokenService.removeTokens();
      ClientStorageService.removeItem(enums.USER_KEY);
      dispatch('resetGlobalDataFromModules', {}, { root: true });
    },
  },
};

// Типизация стора чтобы в файлах .vue работала типизация модуля
// TODO добавить такой же тип для StoreCRUDModule
export type AuthStore<S = AuthState> = Omit<VuexStore<S>, 'getters' | 'commit' | 'dispatch'> & {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
    key: K,
    payload: P,
    options?: CommitOptions
  ): ReturnType<Mutations[K]>;
} & {
  dispatch<K extends keyof Actions>(
    key: K,
    payload?: Parameters<Actions[K]>[1],
    options?: DispatchOptions
  ): ReturnType<Actions[K]>;
};
