import { onUnmounted, ref } from 'vue';

export function useIntersectionObserver(refElement, callback, options) {
  const { root = null, rootMargin = '0%', threshold = 1 } = options;
  const observer = ref();
  const node = refElement;

  if (!node) return;

  observer.value = new IntersectionObserver(callback, {
    root,
    rootMargin,
    threshold,
  });

  observer.value.observe(node);

  onUnmounted(() => observer.value.disconnect());
  return { observer };
}
