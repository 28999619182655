<template>
  <LayoutByUserRole content-class="v-patient-groups" fix-height>
    <ElBreadcrumb separator="/">
      <ElBreadcrumbItem
        :to="{
          path: `${PATIENT_GROUPS_PATH_PREFIX}/${pathGroup}`,
          query: {
            age_from: ageFrom.value,
            age_to: ageTo.value,
            group: pathGroup,
          },
        }"
        >{{ computeChangeGroup.titleGroupValue }}
      </ElBreadcrumbItem>
      <ElBreadcrumbItem>{{ computeChangeGroup.titleChangeGroupValue }}</ElBreadcrumbItem>
    </ElBreadcrumb>
    <div class="v-patient-groups__header">
      <ElDatePicker
        class="v-patient-groups__date"
        v-model="date.value"
        :format="DATE_FORMAT"
        type="daterange"
        unlink-panels
        :value-format="DATE_FORMAT"
        :placeholder="$t('DateAndTime.Date')" />

      <div class="v-patient-groups__age-data">
        <span>{{ $t('Analytics.AgeFrom') }}</span>
        <UiSelect :options="ageOptions" v-model="ageFrom.value" class="v-patient-groups__age" />
        <span>{{ $t('Analytics.To') }}</span>
        <UiSelect :options="ageOptions" v-model="ageTo.value" class="v-patient-groups__age" />
      </div>

      <a class="v-patient-groups__btn" :href="exportDataURL" download>
        <ElButton type="primary">
          {{ $t('Base.DownloadExcel') }}
        </ElButton>
      </a>
    </div>

    <GroupChangeTable
      :loading="loading"
      :items="tableItems"
      :total="tableItemsTotal"
      v-model:page="page.value"
      v-model:per-page="perPage.value" />
  </LayoutByUserRole>
</template>

<script lang="ts">
export default {
  name: 'VGroupChange',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { useRoute } from 'vue-router';
import { useDatePeriod, usePage, usePerPage } from '@/hooks/query';
import { computed, ref, watch } from 'vue';
import { getMonthPeriod } from '@/utils/dateAndTime.utils';
import { useQuery } from '@/hooks/useQuery.hook';
import { DATE_FORMAT } from '@/config/dateAndTime.config';
import { Analytics } from '@/models/analytics/analytics.model';
import { compareQueriesThenLoadData } from '@/utils/router.utils';
import { mergeOrCreateQuery } from '@/utils/http.util';
import { deleteEmptyValueKeys } from '@/utils/object.util';
import { I18nService } from '@/services/i18n.service';
import { TokenService } from '@/services/token.service';
import { DeviceService } from '@/services/device.service';
import { GroupChangeDto, PatientGroup } from '@/types/api';
import { PATIENT_GROUPS_PATH_PREFIX } from '@/router/patientGroups.routes';

import LayoutByUserRole from '@/components/layouts/LayoutByUserRole/index.vue';
import GroupChangeTable from '@/components/analytics/patientGroups/tables/GroupChangeTable/index.vue';

const route = useRoute();
const page = usePage();
const perPage = usePerPage();
const loading = ref(false);
const tableItems = ref<GroupChangeDto[]>([]);
const tableItemsTotal = ref(0);

const date = useDatePeriod(getMonthPeriod());
const ageFrom = useQuery({ field: 'age_from', defaultValue: 0 });
const ageTo = useQuery({ field: 'age_to', defaultValue: 120 });

const oldGroup = route.query.old_group as PatientGroup;
const newGroup = route.query.new_group as PatientGroup;

const ageOptions = Array.from({ length: 120 }, (_, i) => {
  return {
    label: i.toString(),
    value: i,
  };
});

const query = computed(() => ({
  old_group: oldGroup,
  new_group: newGroup,
  page: page.value,
  per_page: perPage.value,
  start_at: date.value[0],
  end_at: date.value[1],
  age_from: ageFrom.value,
  age_to: ageTo.value,
}));

const exportDataURL = computed(() => {
  return mergeOrCreateQuery({
    url: Analytics.exportGroupChangesDataURL,
    query: deleteEmptyValueKeys({
      ...query.value,
      lang: I18nService.getLocale(),
      token: TokenService.getAccessToken(),
      device_id: DeviceService.getDeviceId(),
    }),
  });
});

const getPatientsGroupChange = async () => {
  loading.value = true;
  const response = await Analytics.getGroupChange(query.value);

  tableItems.value = response?.data ?? [];
  tableItemsTotal.value = response?.meta.total ?? 0;

  loading.value = false;
};

const pathGroup = computed(() => {
  switch (true) {
    case oldGroup === 'first_group' || newGroup === 'first_group':
      return 'first_group';
    case oldGroup === 'second_group' || newGroup === 'second_group':
      return 'second_group';
    case oldGroup === 'third_group' || newGroup === 'third_group':
      return 'third_group';
    case oldGroup === 'fourth_group' || newGroup === 'fourth_group':
      return 'fourth_group';
    default:
      return 'first_group';
  }
});

const computeChangeGroup = computed(() => {
  let titleGroupValue = '';
  let titleChangeGroupValue = '';
  switch (true) {
    case oldGroup === 'first_group' && newGroup === 'second_group':
      titleChangeGroupValue = I18nService.t('Statistic.TransferredFromGroupIToGroupII');
      titleGroupValue = I18nService.t('Patients.Groups.first_group');
      break;
    case oldGroup === 'second_group' && newGroup === 'first_group':
      titleChangeGroupValue = I18nService.t('Statistic.TransferredFromGroupIIToGroupI');
      titleGroupValue = I18nService.t('Patients.Groups.first_group');
      break;
    case oldGroup === 'second_group' && newGroup === 'third_group':
      titleChangeGroupValue = I18nService.t('Statistic.TransferredFromGroupIIToGroupIII');
      titleGroupValue = I18nService.t('Patients.Groups.second_group');
      break;
    case oldGroup === 'third_group' && newGroup === 'second_group':
      titleChangeGroupValue = I18nService.t('Statistic.TransferredFromGroupIIIToGroupII');
      titleGroupValue = I18nService.t('Patients.Groups.second_group');
      break;
    case oldGroup === 'third_group' && newGroup === 'fourth_group':
      titleChangeGroupValue = I18nService.t('Statistic.TransferredFromGroupIIIToGroupIV');
      titleGroupValue = I18nService.t('Patients.Groups.third_group');
      break;
    case oldGroup === 'fourth_group' && newGroup === 'third_group':
      titleChangeGroupValue = I18nService.t('Statistic.TransferredFromGroupIVToGroupIII');
      titleGroupValue = I18nService.t('Patients.Groups.third_group');
      break;
  }
  return {
    titleChangeGroupValue,
    titleGroupValue,
  };
});

watch(
  query,
  (value, oldValue) => {
    compareQueriesThenLoadData({
      query: value,
      oldQuery: oldValue,
      getData: getPatientsGroupChange,
      resetPage: page.reset,
    });
  },
  { deep: true, immediate: true }
);
</script>

<style lang="scss" src="./index.scss" />

<i18n src="@/locales/patients.locales.json" />
