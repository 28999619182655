<template>
  <LayoutDefault v-bind="$attrs">
    <template #header>
      <RegistryHeader />
    </template>

    <template #default>
      <slot></slot>
    </template>
  </LayoutDefault>
</template>

<script lang="ts">
export default {
  name: 'LayoutRegistry',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script setup lang="ts">
import LayoutDefault from '@/components/layouts/LayoutDefault/index.vue';
import RegistryHeader from '@/components/doctors/DoctorsHeader/index.vue';
</script>

<style lang="scss" src="./index.scss" />
