import { useQuery } from '@/hooks/useQuery.hook';
import { ISOStringToDateAppFormat } from '@/utils/dateAndTime.utils';
import { DATE_PERIOD } from '@/enums/query.enum';

export function useDatePeriod(defaultValue) {
  return useQuery({
    field: DATE_PERIOD,
    defaultValue: defaultValue ?? [
      ISOStringToDateAppFormat(new Date().toISOString()),
      ISOStringToDateAppFormat(new Date().toISOString()),
    ],
    valueIsArray: true,
  });
}
