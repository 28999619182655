<template>
  <div class="product-form">
    <FieldGroupWrapper grid-columns="five-columns">
      <div class="product-form__left-field-group">
        <FormTextField
          field-name="title.ru"
          :label="$t('Base.NamingInRu')"
          maxlength="255"
          required />
        <FormTextField
          field-name="title.uz"
          :label="$t('Base.NamingInUz')"
          maxlength="255"
          required />
        <FormTextField
          field-name="title.en"
          :label="$t('Base.NamingInEn')"
          maxlength="255"
          required />
      </div>
      <div />
      <div class="product-form__center-field-group">
        <FormSearchSelectField
          field-name="category_id"
          field-object-name="category"
          :model-for-use="ServiceCategory"
          :label="$t('Services.CategoryOfService')"
          required />
        <FormSearchSelectField
          field-name="warehouse_id"
          field-object-name="warehouse"
          :model-for-use="Warehouse"
          :label="$t('Income.WriteOffWarehouse')"
          required />
        <FormTextField
          field-name="price"
          :label="$t('Base.Price')"
          mask-name="int-12"
          required
          v-show="isPriceFieldShow" />
      </div>
      <div />
      <div class="product-form__right-field-group">
        <FormTextField field-name="vat_percent" :label="$t('Services.VAT')" mask-name="float-2.2" />
        <FormTextField field-name="ikpu_code" :label="$t('Services.IKPUCode')" maxlength="255" />
        <FormTextField
          field-name="package_code"
          :label="$t('Services.PackageCode')"
          maxlength="255" />
      </div>
    </FieldGroupWrapper>
  </div>
</template>

<script lang="ts">
export default {
  name: 'ProductForm',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { Warehouse } from '@/models/warehouse/Warehouse.model';
import { ServiceCategory } from '@/models/ServiceCategory.model';
import { MarketCalculation } from '@/types/api';
import { computed } from 'vue';
import { useField } from 'vee-validate';

import FieldGroupWrapper from '@/components/common/form/FieldGroupWrapper/index.vue';
import FormTextField from '@/components/common/form/ui/FormTextField/index.vue';
import FormSearchSelectField from '@/components/common/form/ui/FormSearchSelectField/index.vue';

const { value: marketCalculation } = useField<MarketCalculation>('market_calculations');
const isPriceFieldShow = computed(
  () => marketCalculation.value?.margin === null || marketCalculation.value?.margin === undefined
);
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/accounting.locales.json" />
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/warehouse.locales.json" />
