<template>
  <UiTableWithPagination
    table-class="devices-table"
    :data="items"
    :loading="loading"
    :page="page"
    :per-page="perPage"
    :total="total"
    @update:page="$emit('update:page', $event)"
    @update:per-page="$emit('update:perPage', $event)">
    <ElTableColumn prop="user_agent" :label="$t('Devices.Device')" width="300px" />
    <ElTableColumn prop="ip" :label="$t('Devices.Ip')" />
    <ElTableColumn prop="created_at" :label="$t('Devices.CreatedAt')" />
    <ElTableColumn prop="location" :label="$t('Devices.Location')" />

    <ElTableColumn prop="actions" :label="$t('Base.Actions')" width="300px">
      <template #default="{ row }">
        <div class="devices-table-actions">
          <ElButton
            class="devices-table-actions__preview-button"
            type="primary"
            text
            v-if="
              [Device.enum.statuses.Activated, Device.enum.statuses.Blocked].includes(row.status)
            ">
            <template #icon> <UiIcon :icon="icons.EYE" /></template>
            {{ $t('Base.View') }}
          </ElButton>
          <ElButton
            link
            type="danger"
            size="small"
            v-if="row.status === Device.enum.statuses.Activated"
            @click.stop="
              $emit('device:update-status', { id: row.id, status: Device.enum.statuses.Blocked })
            "
            >{{ $t('Devices.EndSession') }}</ElButton
          >
          <ElButton
            class="devices-table-actions__apply-button"
            plain
            type="primary"
            size="small"
            text
            @click.stop="
              $emit('device:update-status', { id: row.id, status: Device.enum.statuses.Activated })
            "
            v-if="row.status === Device.enum.statuses.Created"
            >{{ $t('Base.Confirm') }}</ElButton
          >
          <ElButton
            link
            type="danger"
            size="small"
            text
            @click.stop="
              $emit('device:update-status', { id: row.id, status: Device.enum.statuses.Blocked })
            "
            v-if="row.status === Device.enum.statuses.Created"
            >{{ $t('Devices.Reject') }}</ElButton
          >
          <ElButton
            link
            type="danger"
            size="small"
            text
            @click.stop="
              $emit('device:update-status', { id: row.id, status: Device.enum.statuses.Activated })
            "
            v-if="row.status === Device.enum.statuses.Blocked"
            >{{ $t('Devices.Unlock') }}</ElButton
          >
        </div>
      </template>
    </ElTableColumn>
  </UiTableWithPagination>
</template>

<script>
import { Device } from '@/models/Device.model';
import * as icons from '@/enums/icons.enum.js';

export default {
  name: 'DevicesTable',
  emits: ['device:update-status', 'update:perPage', 'update:page'],
  props: {
    loading: Boolean,
    /**
     * @property { Array<Device|object> } items
     */
    items: Array,
    page: Number,
    perPage: Number,
    total: Number,
  },
  setup: () => ({
    Device,
    icons: icons,
  }),
};
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/devices.locales.json" />
