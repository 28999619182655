import { CRUDModel } from '@/models/CRUD.model';
import { ApiService } from '@/services/api.service';
import { I18nService } from '@/services/i18n.service';
import { Analysis } from '@/models/laboratory/Analysis.model';

export class Study extends CRUDModel {
  static modelName = 'study';
  static tableName = 'studys';

  /**
   * @param {object} [payload]
   * @param {string} payload.status
   * @param {number|string} payload.value
   * @param {string} payload.value_type virtual, identity Analysis.value_type
   * @param {number} payload.value_file_id
   * @param {File} payload.value_file File model
   * @param {number} payload.references_group_id
   * @param {ReferencesGroup|object} payload.references_group
   * @param {number} payload.references_value_id
   * @param {ReferencesValue} payload.references_value
   *
   * @param {number} payload.order_id
   * @param {Order|object} payload.order
   * @param {number} payload.biomaterial_id
   * @param {Biomaterial|object} payload.biomaterial
   * @param {number} payload.analysis_id
   * @param {Analysis|object} payload.analysis
   *
   * @param {string} payload.created_at DATE_FORMAT
   */
  constructor(payload) {
    super(payload);

    this.status = payload?.status ?? null;
    this.value = payload?.value ?? null;
    this.value_type = payload?.value_type ?? Analysis.enum.valueTypes.Default;
    this.value_file_id = payload?.value_file_id ?? null;
    this.value_file = payload?.value_file ?? null;
    this.references_group_id = payload?.references_group_id ?? null;
    this.references_group = payload?.references_group ?? null;
    this.references_value_id = payload?.references_value_id ?? null;
    this.references_value = payload?.references_value ?? null;

    this.order_id = payload?.order_id ?? null;
    this.order = payload?.order ?? null;
    this.biomaterial_id = payload?.biomaterial_id ?? null; // not for view, get
    this.biomaterial = payload?.biomaterial ?? null; // not for view, get
    this.analysis_id = payload?.analysis_id ?? null;
    this.analysis = payload?.analysis ?? null;

    this.created_at = payload?.created_at ?? null;
  }

  /**
   * @param {number} orderId
   * @param {options} [options]
   * @return {Promise<{data: response.data, response: AxiosResponse<any>}>}
   */
  static async updateStudiesReferencesGroupsByOrderId(orderId, options = {}) {
    const response = await ApiService.put(`${this.tableName}/${orderId}/references`, options);

    return { response, data: response.data };
  }

  /**
   * @param {Study|object} study
   * @returns {string|null}
   */
  static getReferencesValueString(study) {
    if (!study.references_value_id) return null;
    if (study.references_value?.norm_text) return study.references_value.norm_text;
    if (study.value_type === Analysis.enum.valueTypes.Boolean)
      return I18nService.t(`Laboratory.Study.ValueNegative`);

    return `${study.references_value.start_value ?? '...'} - ${
      study.references_value.end_value ?? '...'
    }`;
  }

  /**
   * @param {Study|object} study
   * @returns {string|null}
   */
  static getValueString(study) {
    if (study.value_type === Analysis.enum.valueTypes.File) return study.value_file?.filename;
    if (study.value_type === Analysis.enum.valueTypes.Boolean) {
      const answers = {
        1: I18nService.t(`Laboratory.Study.ValuePositive`),
        0: I18nService.t(`Laboratory.Study.ValueNegative`),
      };
      return answers[study.value];
    }

    return study.value;
  }
}
