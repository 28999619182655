<template>
  <ElDialog
    class="templates-modal"
    :model-value="modelValue"
    width="515px"
    :title="$t('Patronage.AddDocument')"
    v-bind="$attrs"
    @update:model-value="$emit('update:modelValue', $event)">
    <VeeForm as="ElForm" @submit="onSubmit" :initial-values="initialValues">
      <FieldGroupWrapper>
        <TemplateSelect field-name="title" @change-select="selectedTemplate = $event" />
      </FieldGroupWrapper>
      <FormActionsWrapper>
        <ElButton class="templates-modal__btn" type="primary" native-type="submit"
          >{{ $t('Patronage.FillDocument') }}
        </ElButton>
      </FormActionsWrapper>
    </VeeForm>
  </ElDialog>
</template>

<script lang="ts">
export default {
  name: 'TemplatesModal',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { Form as VeeForm, SubmissionHandler } from 'vee-validate';
import { useStore } from '@/store';
import { useRouter } from 'vue-router';
import { withDefaults, ref } from 'vue';
import { PATIENT_DOCUMENTS_ROUTE } from '@/router/patients.routes';
import { Card } from '@/models/card/Card.model';
import { TemplateDto } from '@/types/api';

import TemplateSelect from '@/components/documents/TemplateSelect/Index.vue';
import FieldGroupWrapper from '@/components/common/form/FieldGroupWrapper/index.vue';
import FormActionsWrapper from '@/components/common/form/FormActionsWrapper/index.vue';

type Props = {
  modelValue: boolean;
  id: number;
};

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
});

const store = useStore();
const router = useRouter();
const id = ref<null | number>(null);
defineEmits(['update:modelValue']);
const selectedTemplate = ref<TemplateDto | null>(null);

const initialValues = ref({
  title: '',
});

const createCard = async () => {
  const response = await Card.create({
    user_id: props.id,
    title: selectedTemplate.value?.title ?? '',
    basic_data: selectedTemplate.value?.basic_data ?? '',
    inspection_card_template_id: selectedTemplate.value?.id,
  });

  if (response?.data) {
    id.value = response.data.data.id;
    store.dispatch('card/setCardInfo', response.data.data);
  }
};

const onSubmit: SubmissionHandler = async () => {
  if (!selectedTemplate.value) return;
  await createCard();

  router.push({
    name: PATIENT_DOCUMENTS_ROUTE.name,
    params: {
      id: id.value,
      mode: 'create',
    },
  });
};
</script>

<style lang="scss" src="./index.scss"></style>
<i18n src="@/locales/patronage.locales.json" />
<i18n src="@/locales/validation.locales.json" />
