<template>
  <UiTableWithPagination
    :loading="loading"
    :data="items"
    :page="page"
    :per-page="perPage"
    :total="total"
    @update:page="$emit('update:page', $event)"
    @update:perPage="$emit('update:perPage', $event)">
    <ElTableColumn prop="type" :label="$t('Base.Type')">
      <template #default="{ row }">
        <!--  Иначе выскакивают предупреждения из-зa row.type === undefined, хотя такого быть не может  -->
        {{ row.type ? $t(`Transactions.Types.${row.type}`) : '' }}
      </template>
    </ElTableColumn>

    <ElTableColumn prop="amount" :label="$t('Base.SumCost')" />

    <ElTableColumn prop="payment_type" :label="$t('Invoices.PaymentMethod')">
      <template #default="{ row }">
        {{ row.payment_type ? $t(`Transactions.PaymentTypes.${row.payment_type}`) : '' }}
      </template>
    </ElTableColumn>

    <ElTableColumn prop="created_at" :label="$t('DateAndTime.CreatedAt')" />
  </UiTableWithPagination>
</template>

<script>
import { PAGE_SIZES } from '@/config/ui.config';
import UiTableWithPagination from '@/components/ui/UiTableWithPagination/index.vue';

export default {
  name: 'TransactionsTable',
  components: { UiTableWithPagination },
  emits: ['update:perPage', 'update:page'],
  props: {
    items: Array,
    loading: Boolean,
    page: Number,
    perPage: Number,
    total: Number,
  },
  computed: {
    pageCount() {
      return Math.ceil(this.total / this.perPage);
    },
  },

  setup: () => ({
    PAGE_SIZES,
  }),
};
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/dateAndTime.locales.json" />
<i18n src="@/locales/invoices.locales.json" />
<i18n src="@/locales/transactions.locales.json" />
