<template>
  <VeeForm
    class="income-product-table-filling-form"
    as="ElForm"
    :validation-schema="validationSchema"
    v-slot="{ meta }"
    @submit="onSubmit">
    <div class="income-product-table-filling-form__field-group">
      <FormSearchSelectField
        field-name="product_id"
        field-object-name="product"
        :model-for-use="Product"
        :placeholder="$t('Income.SearchGoods')"
        show-create-option
        @create="$emit('product:create', $event)" />
      <FormTextField v-show="false" field-name="product.title" disabled />
      <FormTextField
        field-name="product.article"
        :placeholder="$t('Nomenclature.VendorCode')"
        disabled
        :in-column="false" />
      <FormTextField
        field-name="price"
        :placeholder="$t('Income.Price')"
        :in-column="false"
        required
        mask-name="float-12.2" />
      <FormNumberField
        field-name="planed_count"
        :placeholder="$t('Nomenclature.Qty')"
        :in-column="false"
        :min="1" />
      <div />
    </div>
    <ElButton type="primary" native-type="submit" :disabled="!meta.dirty"
      >{{ `+ ${$t('Base.Add')}` }}
    </ElButton>
  </VeeForm>
</template>

<script lang="ts">
export default {
  name: 'IncomeProductsTableFillingForm',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { Product } from '@/models/warehouse/Product.model';
import { SubmissionHandler, Form as VeeForm } from 'vee-validate';
import { object, number, string } from 'yup';
import { I18nService } from '@/services/i18n.service';

import FormTextField from '@/components/common/form/ui/FormTextField/index.vue';
import FormNumberField from '@/components/common/form/ui/FormNumberField/index.vue';
import FormSearchSelectField from '@/components/common/form/ui/FormSearchSelectField/index.vue';

const emit = defineEmits(['table:add', 'product:create']);

const validationSchema = object({
  product_id: number().nullable().required(I18nService.t('Validation.RequiredField')),
  planed_count: number().nullable().required(I18nService.t('Validation.RequiredField')),
  price: string().nullable().required(I18nService.t('Validation.RequiredField')),
});

const onSubmit: SubmissionHandler<Record<string, unknown>> = async (values, actions) => {
  actions.resetForm();
  emit('table:add', values);
};
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/notifications.locales.json" />
<i18n src="@/locales/validation.locales.json" />
<i18n src="@/locales/warehouse.locales.json" />
<i18n src="@/locales/ui.locales.json" />
