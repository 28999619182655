<template>
  <UiTableWithPagination
    :data="props.items"
    :loading="props.loading"
    :page="props.page"
    :per-page="props.perPage"
    :total="props.total"
    @update:page="$emit('update:page', $event)"
    @update:per-page="$emit('update:perPage', $event)">
    <ElTableColumn prop="vaccine.title" :label="$t('Patients.VaccinationName')" />
    <ElTableColumn prop="vaccine_date" :label="$t('Patients.VaccinationDate')" />
  </UiTableWithPagination>
</template>

<script lang="ts">
export default {
  name: 'VaccinationsTable',
  inheritAttrs: false,
  customOptions: {},
};
</script>
<script setup lang="ts">
import { VaccinationTableDto } from '@/types/api';

import UiTableWithPagination from '@/components/ui/UiTableWithPagination/index.vue';

defineEmits(['update:page', 'update:perPage']);

const props = defineProps<{
  loading: boolean;
  items: VaccinationTableDto[];
  total: number;
  page: number;
  perPage: number;
}>();
</script>

<i18n src="@/locales/patients.locales.json" />
