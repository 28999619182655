import { onlyLoggedInMiddleware } from '@/middlewares/onlyLoggedIn.middleware';
import { byUserRolesMiddleware } from '@/middlewares/byUserRoles.middleware';
import { User } from '@/models/User.model';

export const PATIENT_GROUPS_PATH_PREFIX = '/patient_groups';

export const PATIENTS_FIRST_GROUP_ROUTE = {
  name: 'PATIENTS_FIRST_GROUP',
  path: `${PATIENT_GROUPS_PATH_PREFIX}/first_group`,
  component: 'VPatientGroups',
  beforeEnter: [
    onlyLoggedInMiddleware,
    byUserRolesMiddleware([User.enum.roles.Doctor, User.enum.roles.Director]),
  ],
  props: true,
  meta: {
    title: 'Patients.GroupOfPatients',
  },
};

export const PATIENTS_SECOND_GROUP_ROUTE = {
  name: 'PATIENTS_SECOND_GROUP',
  path: `${PATIENT_GROUPS_PATH_PREFIX}/second_group`,
  component: 'VPatientGroups',
  beforeEnter: [
    onlyLoggedInMiddleware,
    byUserRolesMiddleware([User.enum.roles.Doctor, User.enum.roles.Director]),
  ],
  props: true,
  meta: {
    title: 'Patients.GroupOfPatients',
  },
};

export const PATIENTS_THIRD_GROUP_ROUTE = {
  name: 'PATIENTS_THIRD_GROUP',
  path: `${PATIENT_GROUPS_PATH_PREFIX}/third_group`,
  component: 'VPatientGroups',
  beforeEnter: [
    onlyLoggedInMiddleware,
    byUserRolesMiddleware([User.enum.roles.Doctor, User.enum.roles.Director]),
  ],
  props: true,
  meta: {
    title: 'Patients.GroupOfPatients',
  },
};

export const PATIENTS_FOURTH_GROUP_ROUTE = {
  name: 'PATIENTS_FOURTH_GROUP',
  path: `${PATIENT_GROUPS_PATH_PREFIX}/fourth_group`,
  component: 'VPatientGroups',
  beforeEnter: [
    onlyLoggedInMiddleware,
    byUserRolesMiddleware([User.enum.roles.Doctor, User.enum.roles.Director]),
  ],
  props: true,
  meta: {
    title: 'Patients.GroupOfPatients',
  },
};

export const PATIENT_GROUP_CODES_ROUTE = {
  name: 'PATIENT_GROUP_CODES',
  path: `${PATIENT_GROUPS_PATH_PREFIX}/:group/:microdistrict_id`,
  component: 'VPatientGroupCodes',
  beforeEnter: [
    onlyLoggedInMiddleware,
    byUserRolesMiddleware([User.enum.roles.Doctor, User.enum.roles.Director]),
  ],
  props: true,
};

export const PATIENT_GROUP_PATIENTS_ROUTE = {
  name: 'PATIENT_GROUP_PATIENTS',
  path: `${PATIENT_GROUPS_PATH_PREFIX}/:group/:microdistrict_id/:disease_code`,
  component: 'VPatientGroupPatients',
  beforeEnter: [
    onlyLoggedInMiddleware,
    byUserRolesMiddleware([User.enum.roles.Doctor, User.enum.roles.Director]),
  ],
  props: true,
};

export const PATIENT_GROUP_CHANGE_ROUTE = {
  name: 'PATIENT_GROUP_CHANGE',
  path: `${PATIENT_GROUPS_PATH_PREFIX}/change`,
  component: 'VGroupChange',
  beforeEnter: [
    onlyLoggedInMiddleware,
    byUserRolesMiddleware([User.enum.roles.Doctor, User.enum.roles.Director]),
  ],
  props: true,
};

export const routes = [
  PATIENTS_FIRST_GROUP_ROUTE,
  PATIENTS_SECOND_GROUP_ROUTE,
  PATIENTS_THIRD_GROUP_ROUTE,
  PATIENTS_FOURTH_GROUP_ROUTE,
  PATIENT_GROUP_CODES_ROUTE,
  PATIENT_GROUP_PATIENTS_ROUTE,
  PATIENT_GROUP_CHANGE_ROUTE,
];
