<template>
  <UiTableWithPagination
    :data="props.items"
    :loading="props.loading"
    :page="props.page"
    :per-page="props.perPage"
    :total="props.total"
    @row-click="goToAppointment"
    @update:page="$emit('update:page', $event)"
    @update:per-page="$emit('update:perPage', $event)">
    <ElTableColumn prop="patient.name" :label="$t('Base.Patient')" min-width="250px">
      <template #default="{ row }">
        <UiUserAvatarInfo :user="row.patient" show-has-treatment :bold="false" />
      </template>
    </ElTableColumn>

    <ElTableColumn prop="patient_condition" :label="$t('Appointments.State')" min-width="120px">
      <template #default="{ row }">
        <StatusTag
          v-if="row.patient_condition"
          :title="$t(`User.Statuses.${row.patient_condition}`)"
          :value="row.patient_condition"
          :map-status="mapPatientStatus" />
      </template>
    </ElTableColumn>

    <ElTableColumn prop="address" :label="$t('User.Address')" min-width="150px" />
    <ElTableColumn prop="start_at" :label="$t('DateAndTime.CreatedAt')" min-width="180px">
      <template #default="{ row }">
        {{ getFormattedDate(row.start_at) }}
      </template>
    </ElTableColumn>
    <ElTableColumn prop="doctor.name" :label="$t('Appointments.Types.doctor')" min-width="150px" />

    <ElTableColumn prop="status" :label="$t('Appointments.Status')" min-width="150px">
      <template #default="{ row }">
        <StatusTag
          v-if="row.status"
          :title="$t(`Appointments.Statuses.${row.status}`)"
          :value="row.status"
          :map-status="mapAppointmentStatus" />
      </template>
    </ElTableColumn>

    <ElTableColumn prop="actions" :label="$t('Base.Actions')" width="400px">
      <template #default="{ row }">
        <div class="home-visits-table__actions">
          <ElButton
            type="primary"
            plain
            :disabled="getButtonProps(row.status).disabled"
            @click.stop="updateAppointmentStatus(row, getButtonProps(row.status).statusPayload)">
            {{ $t(getButtonProps(row.status).title) }}
          </ElButton>
          <ElButton
            type="danger"
            plain
            :disabled="row.status === Appointment.enum.statuses.Provided"
            @click.stop="updateAppointmentStatus(row, Appointment.enum.statuses.Canceled)">
            {{ $t('Appointments.CancelReception') }}
          </ElButton>
        </div>
      </template>
    </ElTableColumn>
  </UiTableWithPagination>
</template>

<script lang="ts">
export default {
  name: 'HomeVisitsTable',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { ExpenseDto } from '@/types/api';
import { getApiErrorMessage } from '@/utils/http.util';
import { ElNotification } from 'element-plus';
import { Appointment } from '@/models/appointment/Appointment.model';
import { useStore } from 'vuex';
import { AnyObject, AppointmentStatus } from '@/types/common';
import { MapStatusType } from '@/components/common/StatusTag/types';
import { I18nService } from '@/services/i18n.service';

import StatusTag from '@/components/common/StatusTag/index.vue';
import UiTableWithPagination from '@/components/ui/UiTableWithPagination/index.vue';
import { useRouter } from 'vue-router';
import { APPOINTMENT_ROUTE } from '@/router/appointments.routes';

defineEmits(['update:page', 'update:perPage', 'cancel', 'approve']);

const props = defineProps<{
  loading: boolean;
  items: ExpenseDto[];
  total: number;
  page: number;
  perPage: number;
}>();

const mapPatientStatus: MapStatusType = {
  average: 'warning',
  normal: 'success',
  serious: 'danger',
};

const mapAppointmentStatus: MapStatusType<AppointmentStatus> = {
  approved: 'warning',
  in_progress: '',
  provided: 'success',
  on_the_way: '',
  canceled: 'danger',
  waiting: 'info',
  created: 'info',
};

const getFormattedDate = (dateAndTime: string | null) => {
  if (!dateAndTime) {
    return '';
  }
  return dateAndTime.split(' ')[0];
};
const getButtonProps = (status: AppointmentStatus | null | undefined) => {
  switch (status) {
    case 'approved':
      return {
        title: I18nService.t('Appointments.TraveledToThePatient'),
        statusPayload: Appointment.enum.statuses.OnTheWay,
        disabled: false,
      };
    case 'on_the_way':
      return {
        title: I18nService.t('Appointments.StartInspection'),
        statusPayload: Appointment.enum.statuses.InProgress,
        disabled: false,
      };
    case 'in_progress':
      return {
        title: I18nService.t('Appointments.CompleteInspection'),
        statusPayload: Appointment.enum.statuses.Provided,
        disabled: false,
      };
    case 'provided':
      return {
        title: I18nService.t('Appointments.CompleteInspection'),
        statusPayload: Appointment.enum.statuses.Provided,
        disabled: true,
      };
    default:
      return {
        title: I18nService.t('Appointments.TraveledToThePatient'),
        statusPayload: Appointment.enum.statuses.OnTheWay,
        disabled: false,
      };
  }
};

const store = useStore();

const updateAppointmentStatus = async (row: AnyObject, status: string) => {
  try {
    await Appointment.updateStatus({
      id: row.id,
      status: status,
    });
    await store.dispatch('homeVisits/editItem', { ...row, status });
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

const router = useRouter();

const goToAppointment = (payload: AnyObject) => {
  router.push({
    name: APPOINTMENT_ROUTE.name,
    params: { id: payload.id },
  });
};
</script>

<style lang="scss" src="./index.scss" />
<!--<i18n src="@/locales/appointments.locales.json" />-->
<!--<i18n src="@/locales/base.locales.json" />-->
<!--<i18n src="@/locales/user.locales.json" />-->
<!--<i18n src="@/locales/dateAndTime.locales.json" />-->
