import { onlyLoggedInMiddleware } from '@/middlewares/onlyLoggedIn.middleware';
import { byUserRolesMiddleware } from '@/middlewares/byUserRoles.middleware';
import { User } from '@/models/User.model';

export const DISPENSARIZATION_PATH_PREFIX = '/dispensarization';

export const DISPENSARIZATION_ROUTE = {
  name: 'DISPENSARIZATION',
  path: `${DISPENSARIZATION_PATH_PREFIX}`,
  component: 'VDispensarization',
  beforeEnter: [
    onlyLoggedInMiddleware,
    byUserRolesMiddleware([User.enum.roles.Doctor, User.enum.roles.Director]),
  ],
  props: true,
  meta: {
    title: 'Analytics.Dispensarization',
  },
};

export const DISPENSARIZATION_CODES_ROUTE = {
  name: 'DISPENSARIZATION_CODES',
  path: `${DISPENSARIZATION_PATH_PREFIX}/:microdistrict_id`,
  component: 'VDispensarizationCodes',
  beforeEnter: [
    onlyLoggedInMiddleware,
    byUserRolesMiddleware([User.enum.roles.Doctor, User.enum.roles.Director]),
  ],
  props: true,
};

export const DISPENSARIZATION_PATIENTS_ROUTE = {
  name: 'DISPENSARIZATION_PATIENTS',
  path: `${DISPENSARIZATION_PATH_PREFIX}/:microdistrict_id/:disease_code`,
  component: 'VDispensarizationPatients',
  beforeEnter: [
    onlyLoggedInMiddleware,
    byUserRolesMiddleware([User.enum.roles.Doctor, User.enum.roles.Director]),
  ],
  props: true,
};

export const DISPENSARIZATION_NEW_PATIENTS_ROUTE = {
  name: 'DISPENSARIZATION_NEW_PATIENTS',
  path: `${DISPENSARIZATION_PATH_PREFIX}/register`,
  component: 'VDispensarizationNewPatients',
  beforeEnter: [
    onlyLoggedInMiddleware,
    byUserRolesMiddleware([User.enum.roles.Doctor, User.enum.roles.Director]),
  ],
  props: true,
};

export const DISPENSARIZATION_OLD_PATIENTS_ROUTE = {
  name: 'DISPENSARIZATION_OLD_PATIENTS',
  path: `${DISPENSARIZATION_PATH_PREFIX}/deregister`,
  component: 'VDispensarizationOldPatients',
  beforeEnter: [
    onlyLoggedInMiddleware,
    byUserRolesMiddleware([User.enum.roles.Doctor, User.enum.roles.Director]),
  ],
  props: true,
};

export const routes = [
  DISPENSARIZATION_ROUTE,
  DISPENSARIZATION_CODES_ROUTE,
  DISPENSARIZATION_PATIENTS_ROUTE,
  DISPENSARIZATION_NEW_PATIENTS_ROUTE,
  DISPENSARIZATION_OLD_PATIENTS_ROUTE,
];
