<template>
  <UiTableWithPagination
    :data="props.items"
    :loading="props.loading"
    :page="props.page"
    :per-page="props.perPage"
    :total="props.total"
    @update:page="$emit('update:page', $event)"
    @update:per-page="$emit('update:perPage', $event)">
    <ElTableColumn prop="expense_date" :label="$t('Outcome.Date')" />
    <ElTableColumn prop="recipient" :label="$t('Outcome.Recipient')" />
    <ElTableColumn prop="expense_type.title" :label="$t('Outcome.FlowType')" />
    <ElTableColumn prop="amount" :label="$t('Base.SumCost')" />
    <ElTableColumn prop="status" :label="$t('Income.Status')">
      <template #default="{ row }">
        <StatusTag
          :title="row.status ? $t(`Income.Statuses.${row.status}`) : ''"
          :value="row.status" />
      </template>
    </ElTableColumn>
    <ElTableColumn prop="actions" :label="$t('Base.Actions')" width="300px">
      <template #default="{ row }">
        <div class="table-actions">
          <ElPopconfirm
            :title="$t('Ui.PopConfirm.DefaultTitle')"
            :disabled="row.status === 'canceled'"
            @confirm="$emit('cancel', row.id)">
            <template #reference>
              <ElButton
                size="small"
                type="danger"
                plain
                :loading="row.loading?.cancel"
                :disabled="row.status === 'canceled'">
                {{ $t('Income.CancelIncome') }}
              </ElButton>
            </template>
          </ElPopconfirm>
          <ElPopconfirm
            :title="$t('Ui.PopConfirm.DefaultTitle')"
            :disabled="row.status === 'accepted'"
            @confirm="$emit('approve', row.id)">
            <template #reference>
              <ElButton
                size="small"
                type="primary"
                plain
                :loading="row.loading?.accept"
                :disabled="row.status === 'accepted'">
                {{ $t('Outcome.Conduct') }}
              </ElButton>
            </template>
          </ElPopconfirm>
        </div>
      </template>
    </ElTableColumn>
  </UiTableWithPagination>
</template>

<script lang="ts">
export default {
  name: 'AccountingOutcomesTable',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { ExpenseDto } from '@/types/api';

import StatusTag from '@/components/common/StatusTag/index.vue';
import UiTableWithPagination from '@/components/ui/UiTableWithPagination/index.vue';

defineEmits(['update:page', 'update:perPage', 'cancel', 'approve']);

const props = defineProps<{
  loading: boolean;
  items: ExpenseDto[];
  total: number;
  page: number;
  perPage: number;
}>();
</script>

<style lang="scss" scoped>
.table-actions {
  display: flex;
  gap: 20px;
  align-items: center;
}
</style>
<i18n src="@/locales/warehouse.locales.json" />
<i18n src="@/locales/notifications.locales.json" />
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/ui.locales.json" />
<i18n src="@/locales/accounting.locales.json" />
