<template>
  <UiTableWithPagination
    height="600px"
    table-class="outcome-products-table"
    hide-pagination
    :data="props.items">
    <ElTableColumn prop="product.title" :label="$t('Nomenclature.NameOfProduct')" />
    <ElTableColumn prop="product.article" :label="$t('Nomenclature.VendorCode')" width="150px" />
    <ElTableColumn prop="type" :label="$t('Outcome.OutcomeType')" width="150px">
      <template #default="{ row }">
        {{ row.type === 'sale' ? $t('Outcome.Sale') : $t('Outcome.WriteOff') }}
      </template></ElTableColumn
    >
    <ElTableColumn prop="count" :label="$t('Nomenclature.Qty')" width="200px" />
    <ElTableColumn prop="warehouse.title" :label="$t('Warehouse.Warehouse')" width="220px" />
    <ElTableColumn prop="actions">
      <template #default="{ row }">
        <div class="outcome-products-table-actions">
          <ElButton
            class="outcome-products-table-actions__delete-button"
            type="primary"
            text
            @click.stop="$emit('table:delete', row.product_id)">
            <template #icon> <UiIcon :icon="icons.CLOSE" /></template>
          </ElButton>
        </div>
      </template>
    </ElTableColumn>
  </UiTableWithPagination>
</template>

<script lang="ts">
export default {
  name: 'OutcomeProductsTable',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { OutcomeProductDto } from '@/types/api';

import * as icons from '@/enums/icons.enum.js';

defineEmits(['table:delete']);

const props = defineProps<{ items: OutcomeProductDto[] }>();
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/warehouse.locales.json" />
