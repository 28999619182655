<template>
  <LayoutByUserRole content-class="v-outcome-content" fix-height>
    <LayoutContentHeader>
      <!-- Date -->
      <ElDatePicker
        class="v-doctors-content-header__date"
        v-model="date.value"
        type="daterange"
        :disabled-date="disabledDate"
        unlink-panels
        :format="DATE_FORMAT"
        :value-format="DATE_FORMAT"
        :start-placeholder="$t('DateAndTime.StartDate')"
        :end-placeholder="$t('DateAndTime.EndDate')" />

      <!-- States -->
      <UiSelect
        :options="statesOptions"
        v-model="patientStates.value"
        multiple
        :placeholder="$t('Patronage.HealthStatus')" />

      <!-- Status -->
      <UiSelect
        :options="statusOptions"
        v-model="patronageStatus.value"
        :placeholder="$t('Base.Status')" />

      <!-- Microdistricts -->
      <UiSelect
        :options="microdistrictsOptions"
        v-model="microdistrictId.value"
        :placeholder="$t('Patients.Microdistrict')" />

      <template #actions>
        <a :href="exportDataURL" download>
          <ElButton plain text>
            <template #icon>
              <UiIcon :icon="icons.DOWNLOAD" />
            </template>
            {{ $t('Base.Report') }}
          </ElButton>
        </a>
      </template>
    </LayoutContentHeader>
    <PatronagesTable
      :loading="loading"
      :items="items"
      :total="total"
      v-model:page="page.value"
      v-model:per-page="perPage.value" />
  </LayoutByUserRole>
</template>

<script lang="ts">
export default {
  name: 'VPatronages',
  inheritAttrs: false,
  customOptions: {},
};
</script>
<script lang="ts" setup>
import * as icons from '@/enums/icons.enum';
import { useStore } from 'vuex';
import { computed, onMounted, ref, watch } from 'vue';
import { useDatePeriod, usePerPage, usePage } from '@/hooks/query';
import { I18nService } from '@/services/i18n.service';
import { compareQueriesThenLoadData } from '@/utils/router.utils';
import { Patronage } from '@/models/patronage/Patronage.model';
import { StatusType, UserStateDto } from '@/types/api/patronage/patronage';
import { DATE_FORMAT } from '@/config/dateAndTime.config';
import { getMonthPeriod } from '@/utils/dateAndTime.utils';
import { mergeOrCreateQuery } from '@/utils/http.util';
import { deleteEmptyValueKeys } from '@/utils/object.util';
import { ApiService } from '@/services/api.service';
import { DeviceService } from '@/services/device.service';
import { useQuery } from '@/hooks/useQuery.hook';
import { Microdistrict } from '@/models/patronage/Microdistrict.model';
import { MicrodistrictDto } from '@/types/api';
import { User } from '@/models/User.model';

import LayoutByUserRole from '@/components/layouts/LayoutByUserRole/index.vue';
import LayoutContentHeader from '@/components/layouts/assets/LayoutContentHeader/index.vue';
import PatronagesTable from '@/components/patronage/PatronagesTable/index.vue';
import UiSelect from '@/components/ui/UiSelect/index.vue';

const store = useStore();
const loading = ref(false);
const page = usePage();
const perPage = usePerPage();
const date = useDatePeriod(getMonthPeriod());

const disabledDate = computed(() => {
  return (time: Date) => {
    return time.getTime() > Date.now();
  };
});

const exportDataURL = computed(() => {
  return mergeOrCreateQuery({
    url: Patronage.exportDataURL,
    query: deleteEmptyValueKeys({
      ...query.value,
      lang: I18nService.getLocale(),
      // @ts-expect-error: Исправить.
      token: ApiService.getToken(),
      device_id: DeviceService.getDeviceId(),
    }),
  });
});

const total = computed(() => store.state.patronagesTable.total);
const items = computed(() => store.state.patronagesTable.data);
const query = computed(() => ({
  page: page.value,
  per_page: perPage.value,
  status: patronageStatus.value,
  search: patientStates.value,
  user_state_ids: patientStates.value,
  start_at: date.value[0],
  end_at: date.value[1],
  microdistrict_id: microdistrictId.value,
}));

const statusOptions = computed<{ label: string; value: StatusType }[]>(() => [
  {
    label: I18nService.t('Patronage.Statuses.bypassed'),
    value: 'bypassed',
  },
  { label: I18nService.t('Patronage.Statuses.waiting'), value: 'waiting' },
  { label: I18nService.t('Patronage.Statuses.failed'), value: 'failed' },
  { label: I18nService.t('Patronage.Statuses.in_progress'), value: 'in_progress' },
  { label: I18nService.t('Patronage.Statuses.on_the_way'), value: 'on_the_way' },
]);

const statesOptions = ref<{ label: string; value: string }[]>([]);

const microdistrictsOptions = ref<{ label: string; value: string }[]>([]);

const patronageStatus = useQuery({ field: 'status' });

const microdistrictId = useQuery({ field: 'microdistrict_id' });

const patientStates = useQuery({ field: 'user_state_ids', valueIsArray: true });

const getPatronages = async () => {
  loading.value = true;
  const result = await Patronage.getItems(query.value);
  if (result) {
    const data = result.data.map((item) => {
      return { ...item, user_states: item.user_states.map((state) => state.title).join(', ') };
    });

    store.dispatch('patronagesTable/setData', {
      items: data,
      total: result.response.data.meta.total,
      overwriteDataState: true,
    });
  }
  loading.value = false;
};

const getStates = async () => {
  const result = await Patronage.getStates(query.value);
  if (result) {
    statesOptions.value = result.data.map((item: UserStateDto) => {
      return { label: item.title, value: item.id.toString() };
    });
  }
};

const getMicrodistricts = async () => {
  const result = await Microdistrict.getItems(query.value);
  if (result) {
    microdistrictsOptions.value = result.data.data.map((item: MicrodistrictDto) => {
      return { label: item.title, value: item.id.toString() };
    });
  }
};

const getMicrodistrictId = () => {
  const user = store.state.auth.user;
  if (user.role === User.enum.roles.Nurse || user.role === User.enum.roles.Doctor) {
    microdistrictId.value = user.microdistrict.id;
  }
};

onMounted(() => {
  getStates();
  getMicrodistricts();
  getMicrodistrictId();
});

watch(
  query,
  (value, oldValue) => {
    compareQueriesThenLoadData({
      query: value,
      oldQuery: oldValue,
      getData: getPatronages,
      resetPage: page.reset,
    });
  },
  { deep: true, immediate: true }
);
</script>

<style lang="scss" scoped></style>
<i18n src="@/locales/warehouse.locales.json" />
<i18n src="@/locales/dateAndTime.locales.json" />
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/patronage.locales.json" />
<i18n src="@/locales/patients.locales.json" />
