<template>
  <ElForm class="create-appointment-subject">
    <ElSwitch
      class="create-appointment-subject-switch"
      size="large"
      :active-text="$t('Appointments.AddAnalysis')"
      :inactive-text="$t('Appointments.AddNote')"
      active-color="#13ce66"
      inactive-color="#13ce66"
      v-model="isAnalysis" />
    <UiSelect
      v-if="!isAnalysis"
      :options="statusOptions"
      v-model="patientCondition"
      :placeholder="$t('Appointments.SelectPatientCondition')" />
    <div v-if="isAnalysis" class="create-appointment-subject__part create-appointment-subject-part">
      <UiModelsAutocompleteSearch
        class="create-appointment-subject__doctor"
        :model-value="appointment?.abstract_biomaterial_ids"
        :models="abstractBiomaterials"
        clearable
        :model-for-use="AbstractBiomaterial"
        method-name="getBiomaterials"
        multiple
        label="title"
        value="id"
        @select="abstractBiomaterials = $event"
        :placeholder="$t('Appointments.SelectAnalysis')" />
    </div>

    <div
      v-if="!isAnalysis"
      class="create-appointment-subject__part create-appointment-subject-part">
      <UiModelsAutocompleteSearch
        class="create-appointment-subject__doctor"
        :model-value="doctor?.id"
        clearable
        :model-for-use="Doctor"
        :default-item="appointment?.doctor ?? user?.doctor"
        :placeholder="$t('Appointments.SelectDoctor')"
        @select="doctor = $event" />
    </div>

    <div class="create-appointment-subject__part create-appointment-subject-part">
      <ElRadioGroup class="create-appointment-subject__type" v-model="type">
        <ElRadio label="in_queue"> {{ $t('Appointments.LiveQueue') }}</ElRadio>
        <ElRadio label="in_time"> {{ $t('Appointments.RecordOnTime') }}</ElRadio>
        <ElRadio v-if="!isAnalysis" label="visit"> {{ $t('Appointments.HomeVisit') }}</ElRadio>
      </ElRadioGroup>

      <ScheduleSlotsSelect
        :show-slots="isAnalysis ? false : true"
        :label="$t('DateAndTime.SelectDate')"
        v-model:start-at="startAt"
        v-model:end-at="endAt"
        :disabled="type === 'in_queue'"
        :time-disabled="type === 'visit'"
        :search-query="slotsOptions.searchQuery"
        :dependencies="slotsOptions.dependencies" />
    </div>
  </ElForm>
</template>

<script>
import { mapState } from 'vuex';
import { Appointment } from '@/models/appointment/Appointment.model';
import { Doctor } from '@/models/Doctor.model';
import { Patient } from '@/models/Patient.model';
import { I18nService } from '@/services/i18n.service';
import { AbstractBiomaterial } from '@/models/appointment/AbstractBiomaterial.model';

import ScheduleSlotsSelect from '@/components/appointments/ScheduleSlotsSelect/index.vue';
import UiSelect from '@/components/ui/UiSelect/index.vue';

export default {
  name: 'CreateAppointmentSubjectOrServiceGroupWithCount',
  emits: ['update:appointment'],
  components: { UiSelect, ScheduleSlotsSelect },
  props: {
    appointment: [Appointment, Object], // use for edit
    patient: [Patient],
    setDefaultMyDoctor: Boolean,
    resetAfterCreate: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),

    statusOptions: {
      get() {
        return [
          { value: 'normal', label: I18nService.t('Appointments.Conditions.Normal') },
          { value: 'average', label: I18nService.t('Appointments.Conditions.Average') },
          { value: 'serious', label: I18nService.t('Appointments.Conditions.Serious') },
        ];
      },
    },

    doctor: {
      get() {
        return this.appointment.doctor;
      },
      set(value) {
        this.$emit('update:appointment', {
          ...this.appointment,
          doctor_id: value?.id || null,
          doctor: value,
        });
      },
    },

    type: {
      get() {
        return this.appointment.type;
      },
      set(value) {
        this.$emit('update:appointment', {
          ...this.appointment,
          type: value,
        });
      },
    },

    patientCondition: {
      get() {
        return this.appointment.patient_condition;
      },
      set(value) {
        this.$emit('update:appointment', {
          ...this.appointment,
          patient_condition: value,
        });
      },
    },

    abstractBiomaterials: {
      get() {
        return this.appointment.abstract_biomaterials;
      },
      set(value) {
        this.$emit('update:appointment', {
          ...this.appointment,
          abstract_biomaterials: value,
          abstract_biomaterial_ids: value.map((item) => item.id),
        });
      },
    },

    isAnalysis: {
      get() {
        return this.appointment.is_analysis;
      },
      set(value) {
        this.$emit('update:appointment', {
          ...this.appointment,
          is_analysis: value,
        });
      },
    },

    startAt: {
      get() {
        return this.appointment.start_at;
      },
      set(value) {
        this.$emit('update:appointment', { ...this.appointment, start_at: value });
      },
    },
    endAt: {
      get() {
        return this.appointment.end_at;
      },
      set(value) {
        this.$emit('update:appointment', { ...this.appointment, end_at: value });
      },
    },
    slotsOptions() {
      return {
        dependencies: [this.doctor?.id],
        searchQuery: {
          doctor_id: this.doctor?.id,
          appointment_id: this.appointment?.id,
        },
      };
    },
  },
  setup: () => ({
    Doctor,
    AbstractBiomaterial,
  }),
};
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/appointments.locales.json" />
<i18n src="@/locales/dateAndTime.locales.json" />
<i18n src="@/locales/recommendations.locales.json" />
<i18n src="@/locales/notifications.locales.json" />
