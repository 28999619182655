import { analyzes } from './analyzes.store';
import { orders } from './orders.store';
import { referencesGroups } from './referencesGroups.store';
import { referencesValues } from './referencesValues.store';

export default {
  analyzes,
  orders,
  referencesGroups,
  referencesValues,
};
