import { onlyLoggedInMiddleware } from '@/middlewares/onlyLoggedIn.middleware';
import { onlyLaboratoryMiddleware } from '@/middlewares/onlyLaboratory.middleware';
import { byUserRolesMiddleware } from '@/middlewares/byUserRoles.middleware';
import { User } from '@/models/User.model';

export const LABORATORY_ORDERS_ROUTE = {
  name: 'LABORATORY_ORDERS',
  path: '/orders',
  component: 'VOrders',
  beforeEnter: [
    onlyLoggedInMiddleware,
    byUserRolesMiddleware([
      User.enum.roles.Manager,
      User.enum.roles.Laboratory,
      User.enum.roles.Doctor,
      User.enum.roles.Director,
    ]),
  ],
  meta: {
    title: 'Laboratory.Analysis.Analyzes',
  },
};

export const LABORATORY_ORDER_ROUTE = {
  name: 'LABORATORY_ORDER',
  path: '/orders/:id',
  component: 'VOrder',
  beforeEnter: [onlyLoggedInMiddleware],
  props: true,
  meta: {
    title: 'Laboratory.Order.Order',
  },
};

export const LABORATORY_ANALYZES_ROUTE = {
  name: 'LABORATORY_ANALYZES',
  path: '/analyzes',
  component: 'VAnalyzes',
  beforeEnter: [onlyLoggedInMiddleware, onlyLaboratoryMiddleware],
  meta: {
    title: 'Laboratory.Analysis.Analyzes',
  },
};

export const LABORATORY_ANALYSIS_REFERENCES_VALUES_ROUTE = {
  name: 'LABORATORY_ANALYSIS_REFERENCES_VALUES',
  path: '/analyzes/:analysisId/references-values',
  component: 'VReferencesValues',
  beforeEnter: [onlyLoggedInMiddleware, onlyLaboratoryMiddleware],
  props: true,
  meta: {
    title: 'Laboratory.ReferencesValues',
  },
};

export const LABORATORY_REFERENCES_GROUPS_ROUTE = {
  name: 'LABORATORY_REFERENCES_GROUPS',
  path: '/references-groups',
  component: 'VReferencesGroups',
  beforeEnter: [onlyLoggedInMiddleware, onlyLaboratoryMiddleware],
  meta: {
    title: 'Laboratory.ReferencesGroups',
  },
};

export const LABORATORY_REFERENCES_GROUPS_VALUES_ROUTE = {
  name: 'LABORATORY_REFERENCES_VALUES',
  path: '/references-groups/:referencesGroupId/references-values',
  component: 'VReferencesValues',
  beforeEnter: [onlyLoggedInMiddleware, onlyLaboratoryMiddleware],
  props: true,
  meta: {
    title: 'Laboratory.ReferencesValues',
  },
};

export const routes = [
  LABORATORY_ORDERS_ROUTE,
  LABORATORY_ORDER_ROUTE,
  LABORATORY_ANALYZES_ROUTE,
  LABORATORY_ANALYSIS_REFERENCES_VALUES_ROUTE,
  LABORATORY_REFERENCES_GROUPS_ROUTE,
  LABORATORY_REFERENCES_GROUPS_VALUES_ROUTE,
];
