<template>
  <ElDialog
    class="create-or-edit-appointment-modal"
    :model-value="modelValue"
    :title="$t(`Title.${appointment?.id ? 'Edit' : 'Create'}`)"
    @update:model-value="$emit('update:modelValue', $event)">
    <ElForm
      id="create-or-edit-appointment"
      class="create-or-edit-appointment-modal-form"
      label-position="top"
      v-loading="loading.appointment"
      @submit.prevent="submitHandler">
      <div class="create-or-edit-appointment-modal-fields-grid">
        <FieldGroupWrapper :title="$t('Base.PatientInfo')">
          <PatientsSearchSelectDataBlock
            v-show="!patientPart.show"
            v-model="appointment.patient_id"
            :search-query="patientsOptions.searchQuery"
            :default-item="patient || patientPart.newPatient || appointment.patient"
            :disabled="!!appointment.id"
            :hide-select="!!appointment.id || !!patient?.id"
            :required="!patientPart.show && !patient?.id"
            :create-permission="permissions.createPatient"
            :appointment="appointment"
            ref="patient">
          </PatientsSearchSelectDataBlock>
        </FieldGroupWrapper>
        <FieldGroupWrapper :title="$t('Appointments.AppointmentInformation')">
          <CreateAppointmentSubjectOrServiceGroupWithCount
            v-model:appointment="appointment"
            :patient="patient"
            :set-default-my-doctor="setDefaultMyDoctor"
            @update:appointment="appointment = $event"
            reset-after-create />
        </FieldGroupWrapper>
      </div>
      <FormActionsWrapper align="right">
        <ElButton
          type="primary"
          native-type="submit"
          form="create-or-edit-appointment"
          :disabled="!!appointment?.id"
          :loading="loading.form">
          {{ $t(`Base.${appointment?.id ? 'Edit' : 'Create'}`) }}
        </ElButton>
      </FormActionsWrapper>
    </ElForm>
  </ElDialog>
</template>

<script>
import { markRaw } from 'vue';
import { mapState } from 'vuex';
import { APPOINTMENTS_ROUTE, HOME_VISIT_ROUTE } from '@/router/appointments.routes';
import { GlobalModalAction } from '@/models/client/ModalAndDrawer/GlobalModalAction';
import { Appointment } from '@/models/appointment/Appointment.model';
import { User } from '@/models/User.model';
import { Patient } from '@/models/Patient.model';
import { Doctor } from '@/models/Doctor.model';
import { Service } from '@/models/Service.model';
import { ServiceGroup } from '@/models/ServiceGroup.model';
import { getApiErrorMessage } from '@/utils/http.util';

import PatientsSearchSelectDataBlock from '@/components/patients/PatientsSearchSelectDataBlock/index.vue';
import CreateAppointmentSubjectOrServiceGroupWithCount from './CreateAppointmentSubjectOrServiceGroupWithCount/index.vue';
import FieldGroupWrapper from '@/components/common/form/FieldGroupWrapper/index.vue';
import FormActionsWrapper from '@/components/common/form/FormActionsWrapper/index.vue';
import * as icons from '@/enums/icons.enum.js';

export default {
  name: 'CreateOrEditAppointmentModal',
  components: {
    PatientsSearchSelectDataBlock,
    CreateAppointmentSubjectOrServiceGroupWithCount,
    FieldGroupWrapper,
    FormActionsWrapper,
  },
  emits: ['update:modelValue', 'action', 'update:appointment'],
  props: {
    modelValue: Boolean,
    id: [Number, String],
    patient: [Patient, User, Object],

    disableDefaultAction: Boolean, // отключаем дефолтное поведение после создания//
    setDefaultMyDoctor: {
      // для автоматического выбора врача, если мы с аккаунта врача
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      /** @type Appointment */
      appointment: new Appointment(),
      loading: {
        appointment: false,
        form: false,
      },

      patientPart: {
        show: false,
        nameOrPhone: null,
        newPatient: null,
      },
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),

    permissions() {
      return {
        createPatient: [User.enum.roles.Manager, User.enum.roles.Laboratory].includes(
          this.user.role
        ),
      };
    },
    patientsOptions() {
      return {
        searchQuery: {},
      };
    },
  },

  watch: {
    modelValue: {
      handler() {
        this.appointment = new Appointment({ patient_id: this.patient?.id || null });
      },
      immediate: true,
    },

    id: {
      handler(value) {
        if (value) this.getAppointment();
      },
      immediate: true,
    },
  },

  methods: {
    async getAppointment() {
      this.loading.appointment = true;
      const { appointment } = await Appointment.findOneById(this.id);
      this.appointment = new Appointment(appointment);
      this.loading.appointment = false;
    },

    async submitHandler() {
      if (!this.validate() || this.loading.form) return;
      this.loading.form = true;

      try {
        const { appointment } = this.appointment.id
          ? await this.editAppointment()
          : await this.createAppointment();

        this.$notify({
          type: 'success',
          title: this.$i18n.t(
            `Notifications.Success${this.appointment.id ? 'Updated' : 'Created'}`
          ),
        });
        this.$emit(
          'action',
          new GlobalModalAction({
            name: this.appointment.id ? 'updated' : 'created',
            data: { appointment },
          })
        );
      } catch (err) {
        this.$notify({
          type: 'error',
          title: getApiErrorMessage(err),
        });
      }

      this.loading.form = false;
    },

    async createAppointment() {
      if (this.$refs.patient.isNewPatientFlow()) {
        const patient = await this.$refs.patient.createPatient();
        this.appointment.patient_id = patient.id;
        this.appointment.patient = patient;
      }

      const formData = {
        patient_id: this.patient?.id ?? this.appointment.patient_id,
        start_at: this.appointment.start_at,
        end_at: this.appointment.end_at,
        doctor_id: this.appointment.doctor_id,
        patient_condition: this.appointment.patient_condition,
        abstract_biomaterial_ids: this.appointment.abstract_biomaterial_ids,
        type: this.appointment.type,
      };

      const { data } = await Appointment.create(formData);

      if (!this.disableDefaultAction) {
        if (this.appointment.type === Appointment.enum.types.Visit) {
          setTimeout(() => {
            this.$router.push({
              name: HOME_VISIT_ROUTE.name,
              params: { id: this.user.id },
            });
          });
        } else {
          setTimeout(() => {
            this.$router.push(APPOINTMENTS_ROUTE.path);
          });
        }
      }
      return { appointment: data.data };
    },

    async editAppointment() {
      const { data } = await Appointment.update({
        id: this.appointment.id,
        payload: this.appointment,
      });
      return { appointment: data.data };
    },

    validate() {
      const isValidatePatient = this.$refs.patient.isNewPatientFlow()
        ? this.$refs.patient.validate()
        : true;
      if (!isValidatePatient) return false;

      if (!this.appointment.is_analysis) {
        if (!this.appointment.doctor_id) {
          this.$notify({ type: 'error', title: this.$t('Appointments.SelectDoctor') });
          return false;
        }

        if (this.appointment.type === Appointment.enum.types.Visit) {
          if (!this.appointment.start_at) {
            this.$notify({ type: 'error', title: this.$t('DateAndTime.SelectDate') });
            return false;
          }
        }

        if (this.appointment.type === Appointment.enum.types.InTime) {
          if (!this.appointment.start_at) {
            this.$notify({ type: 'error', title: this.$t('DateAndTime.SelectDate') });
            return false;
          }
          if (!this.appointment.end_at) {
            this.$notify({ type: 'error', title: this.$t('DateAndTime.SelectTime') });
            return false;
          }
        }
      }

      if (this.appointment.is_analysis) {
        if (!this.appointment.abstract_biomaterial_ids.length) {
          this.$notify({ type: 'error', title: this.$t('Appointments.SelectAnalysis') });
          return false;
        }
        if (this.appointment.type === Appointment.enum.types.InTime) {
          if (!this.appointment.start_at) {
            this.$notify({ type: 'error', title: this.$t('DateAndTime.SelectDate') });
            return false;
          }
        }
      }

      return true;
    },
  },

  // other
  setup: () => ({
    Patient: markRaw(Patient),
    Doctor: markRaw(Doctor),
    Service: markRaw(Service),
    ServiceGroup: markRaw(ServiceGroup),
    icons,
  }),
};
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/notifications.locales.json" />
<i18n src="@/locales/user.locales.json" />
<i18n src="@/locales/appointments.locales.json" />
<i18n src="@/locales/dateAndTime.locales.json" />
<i18n src="./index.locales.json" />
