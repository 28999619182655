<template>
  <UiTableWithPagination
    class="disabled-patients-transferred-table"
    :data="props.items"
    :loading="props.loading"
    :page="props.page"
    :per-page="props.perPage"
    :total="props.total"
    @row-click="$emit('row-click', $event)"
    @update:page="$emit('update:page', $event)"
    @update:per-page="$emit('update:perPage', $event)">
    <ElTableColumn prop="name" :label="$t('Base.Patient')" width="200" />
    <ElTableColumn prop="birthdate" :label="$t('User.Birthdate')" />
    <ElTableColumn prop="phone" :label="$t('Base.PhoneNumber')" />
    <ElTableColumn prop="group" :label="$t('Analytics.Group')">
      <template #default="{ row }">
        <span
          :class="[
            `${row.group ? `disabled-patients-transferred-table__column-${row.group}` : ''}`,
          ]"
          >{{ row.group ? $t(`Patients.Groups.${row.group}`) : '' }}</span
        >
      </template>
    </ElTableColumn>
    <ElTableColumn prop="disease" :label="$t('Analytics.Diagnosis')" />
    <ElTableColumn
      prop="registration_date"
      :label="$t('Patients.DateOfRegistration')"
      width="150" />
    <ElTableColumn prop="deregistration_date" :label="$t('Analytics.DateOfTransfer')" />
    <ElTableColumn prop="actions" :label="$t('Base.Actions')" width="200">
      <ElButton type="primary" text>
        <template #icon>
          <UiIcon :icon="icons.EYE" />
        </template>
        {{ $t('Base.Open') }}
      </ElButton>
    </ElTableColumn>
  </UiTableWithPagination>
</template>
<script lang="ts">
export default {
  name: 'DisabledPatientsTransferredTable',
  inheritAttrs: false,
  customOptions: {},
};
</script>
<script setup lang="ts">
import { DisabledPeopleMicrodistrictDto } from '@/types/api';

import UiTableWithPagination from '@/components/ui/UiTableWithPagination/index.vue';
import * as icons from '@/enums/icons.enum';

const emit = defineEmits(['update:page', 'update:perPage', 'row-click']);

const props = defineProps<{
  loading: boolean;
  items: any[];
  total: number;
  page: number;
  perPage: number;
}>();
</script>

<style lang="scss" src="./index.scss"></style>
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/user.locales.json" />
<i18n src="@/locales/analytics.locales.json" />
<i18n src="@/locales/patients.locales.json" />

<script lang="ts" setup></script>
