import { CRUDModel } from '@/models/CRUD.model';
import { ApiService } from '@/services/api.service';
import { mergeOrCreateQuery } from '@/utils/http.util';
import { deleteEmptyValueKeys } from '@/utils/object.util';

/**
 * @class Doctors
 * @extends CRUDModel
 */
export class Doctor extends CRUDModel {
  static modelName = 'doctor';
  static tableName = 'doctors';

  /**
   * @typedef {object} DoctorConstructorPayload
   * @property {string} name
   * @property {string} avatar
   * @property {Array<Specialty>} specialties
   * @property {string} cabinet
   * @property {number} sign_id
   * @property {File|object} sign
   * @property {Date|string} published_at
   */
  /** @param {DoctorConstructorPayload|object} payload */
  constructor(payload) {
    super(payload);

    this.name = payload?.name || null;
    this.avatar = payload?.avatar || null;
    this.specialties = payload?.specialties || [];
    this.cabinet = payload?.cabinet ?? null;
    this.sign_id = payload?.sign_id || null;
    this.sign = payload?.sign || null;
    this.published_at = payload?.published_at || null;
  }

  /**
   * @param {number} id
   * @param {object} query
   * @return {Promise<{data: response.data, response: AxiosResponse<*>}>}
   */
  static async getPatients(id, query) {
    return super.find({ _url: `patients/doctor/${id}`, ...query });
  }

  /**
   * @param {Object} params
   * @param {string} params.startAt DATE_FORMAT
   * @param {string} params.endAt DATE_FORMAT
   * @return {Promise<{data: response.data, response: AxiosResponse<any>}>}
   */
  static async getTopByPeriod({ startAt, endAt }) {
    const response = await ApiService.get(
      mergeOrCreateQuery({
        url: `statistics/${this.tableName}/top`,
        query: { start_at: startAt, end_at: endAt },
      })
    );

    return {
      response: response,
      data: response.data,
    };
  }

  /**
   * @param {Object} params
   * @param {string} params.startAt DATE_FORMAT
   * @param {string} params.endAt DATE_FORMAT
   * @param {string|number} params.doctorId
   * @return {Promise<{data: response.data, response: AxiosResponse<any>}>}
   */
  static async getServicesCount({ startAt, endAt, doctorId }) {
    const response = await ApiService.get(
      mergeOrCreateQuery({
        url: `statistics/${this.tableName}/${doctorId}/services/count`,
        query: { start_at: startAt, end_at: endAt },
      })
    );

    return {
      response: response,
      data: response.data,
    };
  }

  /**
   * @param {Object} params
   * @param {string} params.startAt DATE_FORMAT
   * @param {string} params.endAt DATE_FORMAT
   * @param {string|number} params.doctorId
   * @return {Promise<{data: response.data, response: AxiosResponse<any>}>}
   */
  static async getServicesPrice({ startAt, endAt, doctorId }) {
    const response = await ApiService.get(
      mergeOrCreateQuery({
        url: `statistics/${this.tableName}/${doctorId}/services/price`,
        query: { start_at: startAt, end_at: endAt },
      })
    );

    return {
      response: response,
      data: response.data,
    };
  }

  /**
   * @param {Object} params
   * @param {string} params.startAt DATE_FORMAT
   * @param {string} params.endAt DATE_FORMAT
   * @param {string|number} params.doctorId
   * @return {Promise<{data: response.data, response: AxiosResponse<any>}>}
   */
  static async getAverageAppointmentTime({ startAt, endAt, doctorId }) {
    const response = await ApiService.get(
      mergeOrCreateQuery({
        url: `statistics/${this.tableName}/${doctorId}/appointments/average/time`,
        query: { start_at: startAt, end_at: endAt },
      })
    );

    return {
      response: response,
      data: response.data,
    };
  }

  /**
   * @param {Object} params
   * @param {string} params.startAt DATE_FORMAT
   * @param {string} params.endAt DATE_FORMAT
   * @param {string|number} params.doctorId
   * @return {Promise<{data: response.data, response: AxiosResponse<any>}>}
   */
  static async getAverageAppointmentDownTime({ startAt, endAt, doctorId }) {
    const response = await ApiService.get(
      mergeOrCreateQuery({
        url: `statistics/${this.tableName}/${doctorId}/appointments/average/downtime`,
        query: { start_at: startAt, end_at: endAt },
      })
    );

    return {
      response: response,
      data: response.data,
    };
  }

  /**
   * @param {Object} params
   * @param {string} params.startAt DATE_FORMAT
   * @param {string} params.endAt DATE_FORMAT
   * @param {string|number} params.doctorId
   * @return {Promise<{data: response.data, response: AxiosResponse<any>}>}
   */
  static async getDoctorsTop({ startAt, endAt, doctorId }) {
    const response = await ApiService.get(
      mergeOrCreateQuery({
        url: `statistics/${this.tableName}/${doctorId}/top`,
        query: { start_at: startAt, end_at: endAt },
      })
    );

    return {
      response: response,
      data: response.data,
    };
  }

  /**
   * @param {Object} query
   * @return {Promise<{data: response.data, response: AxiosResponse<any>}>}
   */
  static async getDoctors(query) {
    const response = await ApiService.get(
      mergeOrCreateQuery({
        url: `statistics/doctors`,
        query: deleteEmptyValueKeys(query),
      })
    );
    return { response: response, data: response.data };
  }

  /**
   * @return {Promise<{data: response.data, response: AxiosResponse<any>}>}
   */
  static async getFavoritesServices() {
    const response = await ApiService.get(`${this.tableName}/favorites/services`);
    return { response: response, data: response.data };
  }

  /**
   * @param {Array<number>} payload
   * @return {Promise<{data: response.data, response: AxiosResponse<any>}>}
   */
  static async updateFavoritesServices(payload) {
    const response = await ApiService.post(`${this.tableName}/favorites/services`, payload);
    return { response: response, data: response.data };
  }
}
