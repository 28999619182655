<template>
  <LayoutAuth content-class="v-waiting-confirm">
    <WaitingConfirm class="v-waiting-confirm__content" />
  </LayoutAuth>
</template>

<script>
import LayoutAuth from '@/components/layouts/LayoutAuth/index.vue';
import WaitingConfirm from '@/components/auth/WaitingConfirm/index.vue';

export default {
  name: 'VWaitingConfirm',
  components: { LayoutAuth, WaitingConfirm },
};
</script>

<style lang="scss" src="./index.scss" />
