{
  "en": {
    "Invoices": {
      "AllInvoices": "All invoices",
      "AverageInvoice": "Average receipt",
      "Bill": "Issue invoice",
      "Create": "Create an account",
      "Number": "Number",
      "PaymentMethod": "Payment Method",
      "RepeatPatients": "Repeat patients",
      "Statistic": {
        "amount": "Total amount",
        "amount_card": "Card (online)",
        "amount_cash": "Cash",
        "count": "Number of records",
        "amount_online": "Transfers",
        "online": "Transfers"
      },
      "Statuses": {
        "canceled": "Cancelled",
        "not_paid": "Not paid",
        "overpay": "Overpayment",
        "paid": "Paid",
        "partially_paid": "Part paid",
        "refund": "Refund",
        "created": "Created"
      },
      "SuccessPayed": "Successfully paid",
      "SuccessRefunded": "Successfully refunded",
      "Types": {
        "custom": "Other",
        "hospital": "Hospital",
        "services": "Services",
        "treatment": "Treatment"
      }
    }
  },
  "ru": {
    "Invoices": {
      "AllInvoices": "Все счета",
      "AverageInvoice": "Средний чек",
      "Bill": "Выставить счёт",
      "Create": "Создать счёт",
      "Number": "Номер",
      "PaymentMethod": "Способ оплаты",
      "RepeatPatients": "Повторные пациенты",
      "Statistic": {
        "amount": "Общая сумма",
        "amount_card": "Карта (онлайн)",
        "amount_cash": "Наличные",
        "count": "Количество записей",
        "amount_online": "Переводы",
        "online": "Переводы"
      },
      "Statuses": {
        "canceled": "Отменён",
        "not_paid": "Не оплачено",
        "overpay": "Переплата",
        "paid": "Оплачен",
        "partially_paid": "Часть оплачена",
        "refund": "Возврат",
        "created": "Создан"
      },
      "SuccessPayed": "Успешно оплачено",
      "SuccessRefunded": "Успешно возвращено",
      "Types": {
        "custom": "Прочее",
        "hospital": "Стационар",
        "services": "Услуги",
        "treatment": "Лечение"
      }
    }
  },
  "uz": {
    "Invoices": {
      "AllInvoices": "Barcha hisob-fakturalar",
      "AverageInvoice": "O'rtacha check",
      "Bill": "Hisobni to'lash",
      "Create": "Hisob yaratish",
      "Number": "Nomer",
      "PaymentMethod": "Tolov usuli",
      "RepeatPatients": "Takroriy bemorlar",
      "Statistic": {
        "amount": "Umumiy qiymat",
        "amount_card": "Karta onlayn",
        "amount_cash": "Naqd",
        "count": " Yozuvlar soni",
        "online": "Transferlar",
        "amount_online": "Transferlar"
      },
      "Statuses": {
        "canceled": "Bekor qilingan",
        "not_paid": "To'lanmagan",
        "overpay": "Ortiqcha",
        "paid": "To'langan",
        "partially_paid": "Qismi to'langan",
        "refund": "Qaytarilgan",
        "created": "Yaratilgan"
      },
      "SuccessPayed": "Muvaffaqiyatli to'landi",
      "SuccessRefunded": "Muvaffaqiyatli qaytarildi",
      "Types": {
        "custom": "Boshqa",
        "hospital": "Kasalxona",
        "services": "Xizmatlar",
        "treatment": "Davolash"
      }
    }
  }
}
