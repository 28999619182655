import { User } from '@/models/User.model';
import { ApiService } from '@/services/api.service';
import { I18nService } from '@/services/i18n.service';
import { cyrillicToEng } from '@/utils/translit.util';
import { mergeOrCreateQuery, getApiErrorMessage } from '@/utils/http.util';
import { ElNotification } from 'element-plus';
import { deleteEmptyValueKeys } from '@/utils/object.util';
import axios from 'axios';

/**
 * @class Patient
 * @extends User
 */
export class Patient extends User {
  static modelName = 'patient';
  static tableName = 'patients';

  constructor(payload) {
    super(payload);

    this.role = User.enum.roles.Patient;
    this.parent = payload?.parent ?? null;
    this.parent_id = payload?.parent?.id ?? null;
    this.ambulatory_card = payload?.ambulatory_card || null;
    this.place_residence = payload?.place_residence || null;
    this.has_treatment = payload?.has_treatment ?? true;
    this.is_foreign = payload?.is_foreign ?? false;
    this.passport_serial = payload?.passport_serial ?? '';
    this.passport_number = payload?.passport_number ?? '';
    this.pinfl = payload?.pinfl ?? '';
    this.microdistrict_id = payload?.microdistrict_id ?? payload?.microdistrict?.id ?? null;
    this.microdistrict = payload?.microdistrict ?? null;
    this.user_state_ids = payload?.user_state_ids ?? [];
    this.gender = payload?.gender ?? '';
    this.group = payload?.group ?? null;
    this.disease_codes = payload?.disease_codes ?? [];
    this.died_at = payload?.died_at ?? '';
    this.files_ids = payload?.files_ids ?? [];
    this.files = payload?.files ?? [];
  }

  static async getStates(query) {
    try {
      const response = await ApiService.get(
        mergeOrCreateQuery({
          url: `b2g/patronages/states`,
          query: deleteEmptyValueKeys(query),
        })
      );

      return {
        response: response,
        data: response.data,
        meta: response.data.meta,
      };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: axios.isAxiosError(err) ? err.message : String(err),
      });
    }
  }

  static async getStatesById(id) {
    try {
      const response = await ApiService.get(`b2g/${this.tableName}/${id}/states`);

      return {
        response: response,
        data: response.data.data,
      };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: axios.isAxiosError(err) ? err.message : String(err),
      });
    }
  }

  static async createVaccine(payload, id) {
    try {
      const response = super.create(payload, {
        url: `/b2g/${this.tableName}/${id}/vaccines/create`,
      });

      return {
        data: response.data,
      };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: getApiErrorMessage(err),
      });
    }
  }

  static async getVaccinationById(id) {
    try {
      const response = await ApiService.get(`b2g/${this.tableName}/${id}/vaccines`);

      return {
        response: response,
        data: response.data.data,
      };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: getApiErrorMessage(err),
      });
    }
  }

  static async updateStatesById(id, payload) {
    try {
      const response = await ApiService.put(`b2g/${this.tableName}/${id}/states/update`, payload);

      return {
        response: response,
        data: response.data.data,
      };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: axios.isAxiosError(err) ? err.message : String(err),
      });
    }
  }

  static async registerDie(id, payload) {
    try {
      const response = await super.update({
        id,
        payload,
        options: {
          url: `/b2g/${this.tableName}/${id}/die/`,
        },
      });

      return {
        data: response.data,
      };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: getApiErrorMessage(err),
      });
    }
  }

  static async cancelDie(id) {
    try {
      const response = await ApiService.put(`/b2g/${this.tableName}/${id}/die/cancel`);

      return {
        response,
      };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: getApiErrorMessage(err),
      });
    }
  }

  /**
   * @param {object} payload
   * @param {boolean} isChildren
   * @return {Promise<{data: response.data, response: AxiosResponse<*>}>}
   */
  static async create(payload, isChildren = false) {
    return super.create(payload, {
      url: isChildren || payload?.parent_id ? `${this.tableName}/child` : null,
    });
  }

  /**
   * Проверяет существование пациента
   * @param {string} phone
   * @return {Promise<{data: any, response: AxiosResponse<any>, patient: Patient, attach_clinic: boolean}>}
   */
  static async checkPatient({ phone }) {
    try {
      const response = await ApiService.post(`${this.tableName}/check`, { phone });
      return {
        response: response,
        data: response.data,
        patient: response.data.data.user,
        attach_clinic: response.data.data.attach_clinic,
      };
    } catch (err) {
      if (err.response?.status !== 404) throw new Error(err);

      return {
        response: null,
        data: null,
        patient: null,
        attach_clinic: false,
      };
    }
  }

  /**
   * @param {object} payload
   * @param {string} payload.pinfl
   * @return {Promise<{data: any, response: AxiosResponse<any>, patient: Patient, attach_clinic: boolean}>}
   */
  static async checkPatientByPinfl(payload) {
    try {
      const response = await ApiService.post(`b2g/${this.tableName}/check/pinfl`, payload);
      return {
        response: response,
        patient: response.data.data ?? null,
      };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: getApiErrorMessage(err),
      });
    }
  }

  /**
   * @param {object} payload
   * @param {string} payload.passport_serial
   * @param {string} payload.passport_number
   * @return {Promise<{data: any, response: AxiosResponse<any>, patient: Patient, attach_clinic: boolean}>}
   */
  static async checkPatientByPassport(payload) {
    try {
      const response = await ApiService.post(`b2g/${this.tableName}/check/passport`, payload);
      return {
        response: response,
        patient: response.data.data ?? null,
      };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: getApiErrorMessage(err),
      });
    }
  }

  /**
   * Привязка пациента к нашей клинике
   * @param {number|string} patient_id
   * @return {Promise<{data: any, response: AxiosResponse<any>, patient: Patient}>}
   */
  static async attachPatient({ patient_id }) {
    const response = await ApiService.post(`${this.tableName}/${patient_id}/attach`);
    return {
      response: response,
      data: response.data,
      patient: response.data.data,
    };
  }

  /**
   * @param {string} phone
   * @return {Promise<{response: AxiosResponse<any>, data: response.data}>}
   */
  static async sendCodeOnPhone({ phone }) {
    const response = await ApiService.post(`${this.tableName}/phone/code`, { phone: phone });
    return { response: response, data: response.data };
  }

  /**
   * @param {string} phone
   * @param {string} code
   * @return {Promise<{data: response.data, response: AxiosResponse<any>}>}
   */
  static async checkCodeFromPhone({ phone, code }) {
    const response = await ApiService.post(`${this.tableName}/phone/code/check`, { phone, code });
    return { response, data: response.data };
  }

  /**
   * @param {Patient} patient
   * @param {string} code
   * @return {Promise<{data: any, response: AxiosResponse<any>}>}
   */
  static async rebinding({ patient, code }) {
    const response = await ApiService.post(`${this.tableName}/phone/rebinding`, {
      code,
      ...patient,
    });
    return {
      response: response,
      data: response.data,
      patient: response.data.data,
    };
  }

  /**
   * Прикрепляем файл к пациенту
   * @param {string} payload.patient_id
   * @param {string} payload.file_id
   * @return {Promise<{data: any}>}
   */
  static async attachFile(payload) {
    const response = await ApiService.post(`${this.tableName}/${payload.patient_id}/files/attach`, {
      file_id: payload.file_id,
    });
    return {
      response: response,
      data: response.data,
      documents: response.data.data,
    };
  }

  /**
   * Находит пациента по данным из браслета, которые приходят после сканирования
   * @param {string} payload
   * @return {Promise<Patient|User|object>}
   */
  static async getByBraceletPayload(payload) {
    try {
      // Если включена руская раскладка, печатается русским алфавитом
      let text = payload;
      const textOnCyrillic = payload.match(/[а-яА-Я]/gim);
      if (textOnCyrillic) text = cyrillicToEng(text);

      const matches = Array.from(text.matchAll(/\?oneTimeToken=(?<token>[^&]*)/gim));
      const token = matches[0]?.groups?.token ?? null;
      if (!token) throw new Error(I18nService.t('Base.InvalidQrCode'));

      const { data } = await ApiService.get(
        `${this.tableName}/getByOneTimeToken?oneTimeToken=${token}`
      );
      return data.data;
    } catch (err) {
      throw new Error(I18nService.t('Base.InvalidQrCode'));
    }
  }

  /**
   * @param {number} userId
   * @return {Promise<{data: response.data, response: AxiosResponse<any>}>}
   */
  static async getQrCodeLinkByUserId(userId) {
    const response = await ApiService.get(`printer/${userId}/link`);
    return {
      response: response,
      data: response.data,
    };
  }

  /**
   * @param {Object} params
   * @param {string} params.startAt DATE_FORMAT
   * @param {string} params.endAt DATE_FORMAT
   * @return {Promise<{data: response.data, response: AxiosResponse<any>}>}
   */
  static async getCountStatisticByPeriod({ startAt, endAt }) {
    const response = await ApiService.get(
      mergeOrCreateQuery({
        url: `statistics/${this.tableName}/count`,
        query: { start_at: startAt, end_at: endAt },
      })
    );

    return {
      response: response,
      data: response.data,
    };
  }

  /**
   * @param {Object} params
   * @param {string} params.startAt DATE_FORMAT
   * @param {string} params.endAt DATE_FORMAT
   * @param {string|number} [params.doctorId]
   * @return {Promise<{data: response.data, response: AxiosResponse<any>}>}
   */
  static async getRepeatStatisticByPeriod({ startAt, endAt, doctorId }) {
    const query = { start_at: startAt, end_at: endAt };

    if (doctorId) {
      query.doctor_id = doctorId;
    }

    const response = await ApiService.get(
      mergeOrCreateQuery({
        url: `statistics/${this.tableName}/returned`,
        query,
      })
    );

    return {
      response: response,
      data: response.data,
    };
  }

  /**
   * @return {Promise<{data: response.data, response: AxiosResponse<any>}>}
   */
  static async getSeparationStatistic() {
    const response = await ApiService.get(`statistics/patients/total`);
    return { response, data: response.data };
  }

  /**
   * Получает амбулаторную карту пациента
   * @param {number} patientId
   * @return {Promise<{data: response.data, response: AxiosResponse<any>}>}
   */
  static async getAmbulatoryCardByPatientId(patientId) {
    const response = await ApiService.get(`/patients/${patientId}/card`);
    return { response, data: response.data };
  }

  /**
   * Получает назначения для пациента
   * @param {number} patientId
   * @return {Promise<{data: response.data, response: AxiosResponse<any>}>}
   */
  static async getRecommendations(patientId) {
    const response = await ApiService.get(`/recommendations/${patientId}/get`);
    return { response, data: response.data };
  }

  /**
   * @param {number} id
   * @param {object} payload
   * @param {string} payload.group
   * @return {Promise<{data: Patient, response: AxiosResponse<any>}>}
   */
  static async updateGroup(id, payload) {
    const response = await ApiService.put(`b2g/${this.tableName}/${id}/group`, payload);
    return {
      response: response,
      data: response.data.data,
    };
  }

  static enum = {
    groups: {
      FirstGroup: 'first_group',
      SecondGroup: 'second_group',
      ThirdGroup: 'third_group',
      FourthGroup: 'fourth_group',
    },
  };
}
