import { I18nService } from '@/services/i18n.service';
import { object, string } from 'yup';
import { computed } from 'vue';

export const validationSchema = computed(() => {
  return object().shape({
    first_day_menstruation: string().required(I18nService.t('Validation.RequiredField')),
    count_previous_births: string().required(I18nService.t('Validation.RequiredField')),
    count_successful_births: string().required(I18nService.t('Validation.RequiredField')),
    planned_date_birth: string().required(I18nService.t('Validation.RequiredField')),
  });
});
