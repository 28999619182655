import axios from 'axios';
import { ReferencesValue } from '@/models/laboratory/ReferencesValue.model';
import { GlobalModalAction } from '@/models/client/ModalAndDrawer/GlobalModalAction';
import { Analysis } from '@/models/laboratory/Analysis.model';

import SelectReferencesGroup from '@/components/laboratory/referencesGroups/SelectReferencesGroup/index.vue';

export default {
  name: 'CreateOrEditReferencesValueModal',
  emits: ['update:modelValue', 'action'],
  components: { SelectReferencesGroup },
  props: {
    modelValue: Boolean,
    data: [ReferencesValue, Object],
  },
  data() {
    return {
      /** @type {ReferencesValue|object} referencesValue */
      referencesValue: new ReferencesValue(),
      loading: false,
    };
  },
  computed: {
    formOptions() {
      return {
        start_value: {
          required: !this.referencesValue.norm_text,
        },
        end_value: {
          required: !this.referencesValue.norm_text,
        },
        norm_text: {
          required: !this.referencesValue.start_value && !this.referencesValue.end_value,
        },
        analysis: {
          required: true,
          disabled: !!this.data?.analysis_id,
        },
        references_group: {
          required: true,
          disabled: !!this.data?.references_group_id,
        },
      };
    },
  },

  watch: {
    modelValue: {
      handler() {
        this.referencesValue = new ReferencesValue(this.data || {});
      },
      immediate: true,
    },
  },

  methods: {
    async submitHandler() {
      if (this.loading) return;
      this.loading = true;

      try {
        const referencesValue = this.referencesValue.id ? await this.edit() : await this.create();

        this.$notify({
          type: 'success',
          title: this.$t(`Notifications.Success${this.referencesValue.id ? 'Updated' : 'Created'}`),
        });
        this.$emit(
          'action',
          new GlobalModalAction({
            name: this.referencesValue.id ? 'edited' : 'created',
            data: { referencesValue },
          })
        );
      } catch (err) {
        this.$notify({
          type: 'error',
          title: axios.isAxiosError(err) ? err.message : String(err),
        });
      }

      this.loading = false;
    },

    async create() {
      const { referencesValue } = await ReferencesValue.create(this.referencesValue);
      return referencesValue;
    },
    async edit() {
      const { referencesValue } = await ReferencesValue.update({
        id: this.referencesValue.id,
        payload: this.referencesValue,
      });
      return referencesValue;
    },
  },

  setup: () => ({ Analysis }),
};
