<template>
  <LayoutByUserRole content-class="v-treatment" fix-height :loading="loading.treatment">
    <LayoutContentHeader>
      <ElPageHeader class="v-patient-treatment-header" :title="$t('Base.Back')" @back="back">
        <template #content> {{ $t('Base.TreatmentJournal') }}</template>
      </ElPageHeader>
    </LayoutContentHeader>

    <template v-if="treatment">
      <TreatmentCard
        v-if="treatment"
        :data="treatment"
        :loading="loading.treatment"
        @treatment:update="treatment = $event" />

      <LayoutContentHeader :title="$t('Treatments.DrugsAndProcedures')">
        <template #actions>
          <ElButton v-if="!(isDirector || isClosed)" type="primary" plain @click="edit">
            {{ $t('Base.Edit') }}
          </ElButton>
        </template>
      </LayoutContentHeader>
      <TreatmentDrugsTable
        v-loading="loading.drugs"
        :items="treatment.drugs"
        :editable="!(isDirector || isClosed)"
        :deletable="!(isDirector || isClosed)"
        @drug:update="updateDrug"
        @drug:change="updateTreatment"
        @drug:remove="deleteDrug" />

      <LayoutContentHeader :title="$t('Appointments.Appointments')">
        <template #actions>
          <ElButton
            v-if="!(isDirector || isClosed)"
            type="primary"
            plain
            :loading="loading.appointment"
            @click="createAppointment">
            {{ $t('Appointments.CreateAppointment') }}
          </ElButton>
        </template>
      </LayoutContentHeader>
      <AppointmentsByTreatmentTable :data="treatment.appointments" :loading="loading.treatment" />

      <div class="v-treatment__actions">
        <ElButton
          v-if="!(isDirector || isClosed)"
          type="primary"
          plain
          :loading="loading.close"
          @click="closeTreatment">
          {{ $t('Treatments.CloseTreatment') }}
        </ElButton>
      </div>
    </template>
  </LayoutByUserRole>
</template>

<script>
import axios from 'axios';
import { Treatment } from '@/models/Treatment.model';
import { Appointment } from '@/models/appointment/Appointment.model';
import { insertRouteParams } from '@/utils/router.utils';
import { PATIENT_ROUTE } from '@/router/patients.routes';
import { APPOINTMENT_ROUTE } from '@/router/appointments.routes';
import { GlobalModalCloseAction } from '@/models/client/ModalAndDrawer/GlobalModalCloseAction';
import { mapState } from 'vuex';
import { User } from '@/models/User.model';

import TreatmentCard from '@/components/treatments/TreatmentCard/index.vue';
import AppointmentsByTreatmentTable from '@/components/treatments/AppointmentsByTreatmentTable/index.vue';
import LayoutContentHeader from '@/components/layouts/assets/LayoutContentHeader/index.vue';
import TreatmentDrugsTable from '@/components/treatments/TreatmentDrugsTable/index.vue';
import CreateOrEditTreatmentModal from '@/components/treatments/CreateOrEditTreatmentModal/index.vue';
import LayoutByUserRole from '@/components/layouts/LayoutByUserRole/index.vue';

export default {
  name: 'VTreatment',
  components: {
    LayoutByUserRole,
    TreatmentDrugsTable,
    LayoutContentHeader,
    TreatmentCard,
    AppointmentsByTreatmentTable,
  },
  props: {
    id: [Number, String],
  },
  data() {
    return {
      loading: {
        treatment: false,
        update: false,
        close: false,
        appointment: false,
        bill: false,
      },
      /** @type {Treatment} */
      treatment: null,
      /** @type {Array<Appointment>} */
      receptions: [],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),

    isClosed() {
      return this.treatment.status === Treatment.enum.statuses.Closed;
    },

    isDirector() {
      return this.user.role === User.enum.roles.Director;
    },

    patientPageLink() {
      return insertRouteParams({
        path: PATIENT_ROUTE.path,
        params: { id: this.treatment.user_id },
      });
    },
  },

  watch: {
    id: {
      async handler() {
        await this.getTreatment();
      },
      immediate: true,
    },
  },

  methods: {
    async getTreatment() {
      this.loading.treatment = true;

      const { data } = await Treatment.findById(this.id);

      this.treatment = data.data;
      this.loading.treatment = false;
    },

    async edit() {
      this.treatment.products = this.treatment.drugs.map((item) => ({
        ...item,
        count: +item.count,
      }));
      const action = await this.$store.dispatch('modalAndDrawer/openModal', {
        component: CreateOrEditTreatmentModal,
        payload: {
          treatment: this.treatment,
        },
      });

      if (!(action instanceof GlobalModalCloseAction)) this.treatment = action.data.treatment;
    },

    async updateTreatment() {
      this.loading.treatment = true;
      this.treatment.products = this.treatment.drugs.map((item) => ({
        ...item,
        count: +item.count,
      }));
      try {
        await Treatment.update({
          id: this.treatment.id,
          payload: this.treatment,
          options: {
            url: `b2g/treatments/${this.treatment.id}/update`,
          },
        });
        this.$notify({ type: 'success', title: this.$t('Notifications.SuccessUpdated') });
      } catch (err) {
        this.$notify({
          type: 'error',
          title: axios.isAxiosError(err) ? err.message : String(err),
        });
      }

      this.loading.treatment = false;
    },

    updateDrug(drug) {
      const index = this.treatment.drugs.findIndex((elem) => elem.id === drug.id);
      this.treatment.drugs[index] = drug;
    },

    async deleteDrug(drug) {
      this.loading.drugs = true;
      const index = this.treatment.drugs.filter((elem) => elem.id !== drug.id);
      try {
        this.treatment.drugs = index;
        this.treatment.products = index;

        await Treatment.update({
          id: this.treatment.id,
          payload: this.treatment,
          options: {
            url: `b2g/treatments/${this.treatment.id}/update`,
          },
        });

        this.$notify({ type: 'success', title: this.$t('Notifications.SuccessDeleted') });
      } catch (err) {
        this.$notify({
          type: 'error',
          title: axios.isAxiosError(err) ? err.message : String(err),
        });
      }

      this.loading.drugs = false;
    },

    async createAppointment() {
      this.loading.appointment = true;

      try {
        const { data } = await Appointment.createByTreatment(this.treatment.id);

        this.$router.push({
          name: APPOINTMENT_ROUTE.childrenMap.APPOINTMENT_ROUTE_INSPECTION_CARD.name,
          params: {
            id: data.data.appointments[0].id,
          },
        });
        this.$notify({ type: 'success', title: this.$t('Notifications.SuccessCreated') });
      } catch (err) {
        this.$notify({
          type: 'error',
          title: axios.isAxiosError(err) ? err.message : String(err),
        });
      }

      this.loading.appointment = false;
    },

    async closeTreatment() {
      this.loading.close = true;

      try {
        const { treatment } = await Treatment.closeB2g(this.treatment.id);
        this.treatment = treatment;

        this.$notify({ type: 'success', title: this.$t('Notifications.SuccessUpdated') });
      } catch (err) {
        this.$notify({
          type: 'error',
          title: axios.isAxiosError(err) ? err.message : String(err),
        });
      }

      this.loading.close = false;
    },

    back() {
      this.$router.go(-1);
    },
  },

  setup: () => ({ Treatment }),
};
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/appointments.locales.json" />
<i18n src="@/locales/invoices.locales.json" />
<i18n src="@/locales/treatments.locales.json" />
<i18n src="@/locales/notifications.locales.json" />
