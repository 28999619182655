<template>
  <ElCard class="appointment-card" shadow="never" v-bind="$attrs">
    <template #header>
      <router-link class="appointment-card__header appointment-card-header" :to="patientPageLink">
        <UiUserAvatarInfo
          class="appointment-card-header__patient"
          :user="appointment.patient"
          bold
          :secondary-field-key="isDoctor ? 'birthdate' : 'phone'" />

        <AppointmentStatusTag
          class="appointment-card-header__status"
          :status="appointment.status" />
      </router-link>
    </template>

    <div class="appointment-card-body">
      <div
        v-for="item in infoItems"
        :key="item.value"
        class="appointment-card-body__item appointment-card-body-item">
        <div class="appointment-card-body-item__title">{{ item.label }}:</div>
        <div class="appointment-card-body-item__value">{{ item.value }}</div>
      </div>
    </div>

    <ElDivider />

    <div class="appointment-card-actions">
      <ElButton
        v-if="actionsOptions.callToReception.isShow"
        type="primary"
        :loading="loading[Appointment.enum.statuses.Waiting]"
        @click="
          $emit(
            'status:update',
            isManager ? Appointment.enum.statuses.InProgress : Appointment.enum.statuses.Waiting
          )
        ">
        {{ $t('Base.CallToReception') }}
      </ElButton>
      <ElButton
        v-if="actionsOptions.onTheWay.isShow"
        type="primary"
        :loading="loading[Appointment.enum.statuses.Waiting]"
        @click="
          $emit(
            'status:update',
            isManager ? Appointment.enum.statuses.InProgress : Appointment.enum.statuses.Waiting
          )
        ">
        {{ $t('Appointments.TraveledToThePatient') }}
      </ElButton>
      <ElButton
        v-if="actionsOptions.start.isShow"
        type="primary"
        :loading="loading[Appointment.enum.statuses.InProgress]"
        @click="$emit('status:update', Appointment.enum.statuses.InProgress)">
        {{ $t('Appointments.StartAppointment') }}
      </ElButton>

      <ElButton
        v-if="actionsOptions.cancel.isShow"
        type="danger"
        plain
        :loading="loading[Appointment.enum.statuses.Canceled]"
        @click="$emit('status:update', Appointment.enum.statuses.Canceled)">
        {{ $t('Appointments.CancelReception') }}
      </ElButton>

      <ElButton
        v-if="actionsOptions.end.isShow"
        type="primary"
        :loading="loading[Appointment.enum.statuses.Provided]"
        @click="$emit('status:update', Appointment.enum.statuses.Provided)">
        {{ $t('Appointments.EndReception') }}
      </ElButton>

      <ElButton
        v-if="actionsOptions.goToInspectionCard.isShow"
        type="primary"
        @click="goToInspectionCard">
        {{ $t('GoToDefaultInspectionCard') }}
      </ElButton>

      <ElButton
        v-if="actionsOptions.reOpen.isShow"
        type="primary"
        plain
        :loading="loading[Appointment.enum.statuses.Approved]"
        @click="$emit('status:update', Appointment.enum.statuses.Approved)">
        {{ $t('Appointments.ReOpen') }}
      </ElButton>

      <ElButton
        v-if="actionsOptions.edit.isShow"
        type="primary"
        plain
        @click="$emit('appointment:edit')">
        {{ $t('Appointments.EditReception') }}
      </ElButton>
    </div>
  </ElCard>
</template>

<script>
import { mapState } from 'vuex';
import { insertRouteParams } from '@/utils/router.utils';
import { formatPrice } from '@/utils/price.util';
import { PATIENT_ROUTE } from '@/router/patients.routes';
import { APPOINTMENT_ROUTE } from '@/router/appointments.routes';
import { NOT_REDIRECT } from '@/enums/query.enum';
import { User } from '@/models/User.model';
import { Appointment } from '@/models/appointment/Appointment.model';

import AppointmentStatusTag from '@/components/appointments/AppointmentStatusTag/index.vue';

export default {
  name: 'VAppointmentDefaultCard',
  components: { AppointmentStatusTag },
  emits: ['update:appointment', 'status:update', 'appointment:edit'],
  props: {
    appointment: {
      type: [Appointment, Object],
      default: () => new Appointment(),
    },
    loading: Object, // object with appointment statuses keys
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),

    isManager() {
      return this.user.role === User.enum.roles.Manager;
    },
    isDoctor() {
      return this.user.role === User.enum.roles.Doctor;
    },
    isNurse() {
      return this.user.role === User.enum.roles.Nurse;
    },

    patientPageLink() {
      return insertRouteParams({
        path: PATIENT_ROUTE.path,
        params: { id: this.appointment?.patient?.id },
      });
    },

    infoItems() {
      return [
        {
          label: this.$t('Appointments.StartDate'),
          value: Appointment.getStartDate(this.appointment.start_at || ''),
        },
        {
          label: this.$t('Appointments.StartTime'),
          value: Appointment.getStartTime(this.appointment.start_at || ''),
        },
        {
          label: this.$t('Base.Doctor'),
          value: this.appointment.doctor?.name,
        },
      ];
    },

    groupServicesWithFormattedCost() {
      return this.appointment.group_services_with_count.map((group) => ({
        title: `${group.group_service.title} (${group.count})`,
        count: group.count,
        price: `${formatPrice({ price: group.group_service.price })} ${this.$t('Base.Sum')}`,
      }));
    },

    actionsOptions() {
      return {
        onTheWay: {
          isShow:
            this.appointment.status === Appointment.enum.statuses.Approved &&
            this.appointment.type === Appointment.enum.types.Visit &&
            (this.isManager || this.appointment.doctor_id === this.user.doctor_id),
        },
        callToReception: {
          isShow:
            this.appointment.status === Appointment.enum.statuses.Approved &&
            this.appointment.type !== Appointment.enum.types.Visit &&
            (this.isManager || this.appointment.doctor_id === this.user.doctor_id),
        },
        start: {
          isShow:
            this.appointment.status === Appointment.enum.statuses.Waiting &&
            (this.isManager || this.appointment.doctor_id === this.user.doctor_id),
        },
        cancel: {
          isShow:
            [Appointment.enum.statuses.Approved, Appointment.enum.statuses.Waiting].includes(
              this.appointment.status
            ) &&
            (this.isManager || this.appointment.doctor_id === this.user.doctor_id),
        },
        end: {
          isShow:
            this.appointment.status === Appointment.enum.statuses.InProgress &&
            (this.isManager || this.appointment.doctor_id === this.user.doctor_id),
        },
        reOpen: {
          isShow:
            this.appointment.status === Appointment.enum.statuses.Canceled &&
            (this.isManager || this.appointment.doctor_id === this.user.doctor_id),
        },

        edit: {
          isShow: ![
            Appointment.enum.statuses.Canceled,
            Appointment.enum.statuses.Provided,
          ].includes(this.appointment.status),
        },
        goToInspectionCard: {
          isShow:
            [User.enum.roles.Doctor, User.enum.roles.Laboratory] &&
            this.appointment.status === Appointment.enum.statuses.Provided,
        },
      };
    },
  },

  watch: {
    'appointment.id': {
      handler() {
        this.redirectToInspectionCardIfNeeded();
      },
      immediate: true,
    },
  },

  methods: {
    goToInspectionCard() {
      this.$router.replace(
        insertRouteParams({
          path: APPOINTMENT_ROUTE.childrenMap.APPOINTMENT_ROUTE_INSPECTION_CARD._fullPath,
          params: { id: this.appointment.id },
        })
      );
    },

    redirectToInspectionCardIfNeeded() {
      if (
        this.isDoctor &&
        this.user.doctor_id === this.appointment.doctor_id &&
        [Appointment.enum.statuses.InProgress, Appointment.enum.statuses.Provided].includes(
          this.appointment.status
        ) &&
        !this.$route.query[NOT_REDIRECT]
      ) {
        this.goToInspectionCard();
      } else if (this.isNurse) {
        this.goToInspectionCard();
      }
    },
  },

  setup: () => ({
    Appointment,
    User,
  }),
};
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/appointments.locales.json" />
<i18n src="@/locales/invoices.locales.json" />
<i18n src="./index.locales.json" />
