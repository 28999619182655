import { ApiService } from '@/services/api.service';
import { AddIDCAccountingRequestDto, DispensarieDto, MetaDto } from '@/types/api';
import { ElNotification } from 'element-plus';
import { QueryParams } from '@/types/common';
import { getApiErrorMessage, mergeOrCreateQuery } from '@/utils/http.util';
import { deleteEmptyValueKeys } from '@/utils/object.util';

export class Dispensarie {
  static modelName = 'dispensarie';
  static tableName = 'dispensaries';

  static async getItems(query: QueryParams) {
    try {
      const response = await ApiService.get<{ data: DispensarieDto[]; meta: MetaDto }>(
        mergeOrCreateQuery({
          url: `b2g/${this.tableName}/${query.id}`,
          query: deleteEmptyValueKeys(query),
        })
      );

      return { response, data: response.data };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: getApiErrorMessage(err),
      });
    }
  }

  static async create(payload: AddIDCAccountingRequestDto) {
    try {
      const response = await ApiService.post<{ data: DispensarieDto }>(
        `b2g/${this.tableName}/create`,
        payload
      );

      return { response, data: response.data.data };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: getApiErrorMessage(err),
      });
    }
  }

  static async deregistration(query: QueryParams) {
    try {
      const response = await ApiService.put<{ data: DispensarieDto }>(
        mergeOrCreateQuery({
          url: `b2g/${this.tableName}/${query}/deregistration`,
          query: deleteEmptyValueKeys(query),
        })
      );

      return { response, data: response.data };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: getApiErrorMessage(err),
      });
    }
  }
}
