<template>
  <div class="add-pregnancy-form">
    <FieldGroupWrapper>
      <FormDateField
        :label="$t('Patients.StartDateOfMenstruation')"
        field-name="first_day_menstruation"
        required
        :placeholder="$t('DateAndTime.Date')" />
      <FormTextField
        :label="$t('Patients.CountPregnancy')"
        field-name="count_previous_births"
        mask-name="int-12"
        required />
      <FormTextField
        :label="$t('Patients.CountOfSuccessPregnancy')"
        mask-name="int-12"
        field-name="count_successful_births"
        required />
      <FormDateField
        :label="$t('Patients.DateOfPlanningBirth')"
        field-name="planned_date_birth"
        required
        :placeholder="$t('DateAndTime.Date')" />
    </FieldGroupWrapper>
    <FormActionsWrapper>
      <ElButton
        class="add-pregnancy-form__btn"
        type="primary"
        @click="submitForm"
        :loading="isSubmitting"
        :disabled="submitDisabled">
        {{ $t('Patients.AddPregnancy') }}
      </ElButton>
    </FormActionsWrapper>
  </div>
</template>

<script lang="ts">
export default {
  name: 'AddPregnancyForm',
  inheritAttrs: false,
  customOptions: {},
};
</script>
<script lang="ts" setup>
import { computed, watch } from 'vue';
import { useForm, useField } from 'vee-validate';
import { AddPregnancyFormData, AddPregnancyRequestDto } from '@/types/api';
import { validationSchema } from './validationSchema';
import { dateAppFormatToISOString, ISOStringToDateAppFormat } from '@/utils/dateAndTime.utils';
import { Pregnancy } from '@/models/pregnancy/Pregnancy.model';

import FormTextField from '@/components/common/form/ui/FormTextField/index.vue';
import FormActionsWrapper from '@/components/common/form/FormActionsWrapper/index.vue';
import FieldGroupWrapper from '@/components/common/form/FieldGroupWrapper/index.vue';
import FormDateField from '@/components/common/form/ui/FormDateField/index.vue';

const props = defineProps<{
  userId: number;
}>();
const emit = defineEmits(['close:modal']);

const { isSubmitting, errors, handleSubmit } = useForm<AddPregnancyFormData>({
  validationSchema,
});

const { value: startDate } =
  useField<AddPregnancyFormData['first_day_menstruation']>('first_day_menstruation');
const { setValue } = useField<AddPregnancyFormData['planned_date_birth']>('planned_date_birth');
const submitDisabled = computed(() => Object.keys(errors.value).length > 0 || isSubmitting.value);

const convertEndDate = (dateStr: string) => {
  const dateIso = dateAppFormatToISOString(dateStr);
  const date = new Date(dateIso);
  date.setFullYear(date.getFullYear() + 1);
  date.setMonth(date.getMonth() - 3);
  date.setDate(date.getDate() + 7);
  return date;
};

const submitForm = handleSubmit(
  async (values) => {
    const requestData: AddPregnancyRequestDto = {
      ...values,
      count_previous_births: +values.count_previous_births,
      count_successful_births: +values.count_successful_births,
      user_id: props.userId,
    };

    await Pregnancy.createItem(requestData);

    emit('close:modal');
  },
  (ctx) => {
    // eslint-disable-next-line no-console
    console.log('invalid submit', ctx.errors);
  }
);

watch(startDate, (value) => {
  if (value) {
    const data = convertEndDate(value);
    setValue(ISOStringToDateAppFormat(data));
  }
});
</script>

<style lang="scss" src="./index.scss"></style>
<i18n src="@/locales/patients.locales.json" />
<i18n src="@/locales/dateAndTime.locales.json" />
