<template>
  <LayoutByUserRole content-class="v-patronage-planning" fix-height>
    <LayoutContentHeader>
      <ElCard class="v-patronage-planning-content-header" :shadow="'never'">
        <div class="v-patronage-planning-content-header__filters">
          <form @submit.prevent="throttleSearch">
            <ElInput
              v-model="search.value"
              :placeholder="$t('PatronageActions.PatientSearch')"
              clearable
              class="v-patronage-planning-content-header__user-search">
              <template #prefix>
                <UiIcon :icon="icons.SEARCH" />
              </template>
            </ElInput>
          </form>
          <ElDatePicker
            :clearable="false"
            class="v-doctors-content-header__date"
            v-model="date.value"
            :disabled-date="disabledDate"
            :format="DATE_FORMAT"
            :value-format="DATE_FORMAT"
            :placeholder="$t('DateAndTime.Date')" />
          <UiSelect
            v-model="microdistrictId.value"
            :options="microdistrictOptions"
            :clearable="false"
            value-key="id"
            label-key="title" />
          <UiSelect
            v-model="userStateIds.value"
            :options="userStateOptions"
            multiple
            :placeholder="$t('PatronageActions.FilterByPatientCondition')"
            value-key="id"
            label-key="title" />
          <ElCheckbox
            v-model="isPlaned.value"
            :label="$t('PatronageActions.OnlySelectedPatients')" />
        </div>
        <div class="v-patronage-planning-content-header__actions">
          <div class="v-patronage-planning-content-header__hint">
            {{ $t('Patronage.CapacityHint') }}
          </div>
          <div class="v-patronage-planning-content-header__capacity">
            {{ capacityData.selected }}/{{ capacityData.capacity }}
            {{ $t('PatronageActions.Patients') }}
          </div>
          <ElButton type="primary" @click="fillCapacity" :disabled="fillCapacityBtnDisabled">
            {{ $t('PatronageActions.SelectPatients') }}
          </ElButton>
        </div>
      </ElCard>
    </LayoutContentHeader>

    <PatronagePlanningTable
      :current-microdistrict="currentMicrodistrict"
      class="v-patronage-planning__table"
      :items="items"
      :loading="loading"
      :date="date.value"
      v-model:page="page.value"
      v-model:per-page="perPage.value"
      :total="total"
      :capacity-filled="capacityFilled"
      @capacity:update="updateCapacity"
      @patronage-item:update="updateTableItem" />
  </LayoutByUserRole>
</template>

<script lang="ts">
export default {
  name: 'VPatronagePlanning',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { usePerPage, usePage } from '@/hooks/query';
import { computed, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import throttle from 'lodash.throttle';
import { useQuery } from '@/hooks/useQuery.hook';
import { DATE_FORMAT } from '@/config/dateAndTime.config';
import { Patronage } from '@/models/patronage/Patronage.model';
import { compareQueriesThenLoadData } from '@/utils/router.utils';
import { getCurrentDate } from '@/utils/dateAndTime.utils';
import {
  MetaDto,
  MicrodistrictDto,
  PatronagePlanningDto,
  PatronageUpdateResponse,
  UserStateDto,
} from '@/types/api';
import { Microdistrict } from '@/models/patronage/Microdistrict.model';

import LayoutByUserRole from '@/components/layouts/LayoutByUserRole/index.vue';
import LayoutContentHeader from '@/components/layouts/assets/LayoutContentHeader/index.vue';
import PatronagePlanningTable from '@/components/patronage/PatronagePlanningTable/index.vue';
import UiSelect from '@/components/ui/UiSelect/index.vue';
import * as icons from '@/enums/icons.enum';

const store = useStore();
const loading = computed(() => store.state.userPatronages.loading);
const items = computed(() => store.state.userPatronages.data);
const total = computed(() => store.state.userPatronages.total);
const currentUser = computed(() => store.state.auth.user);

const search = useQuery({ field: 'user' });
const microdistrictId = useQuery({
  field: 'microdistrict_id',
  valueIsNumber: true,
});
const userStateIds = useQuery({ field: 'user_state_ids', valueIsNumberArray: true });
const date = useQuery({ field: 'date', defaultValue: getCurrentDate() });
const isPlaned = useQuery({ field: 'is_planed', valueIsBoolean: true });
const perPage = usePerPage();
const page = usePage();

const disabledDate = computed(() => {
  return (time: Date) => {
    return time.getTime() < Date.now();
  };
});

const query = computed(() => ({
  per_page: perPage.value,
  page: page.value,
  microdistrict_id: microdistrictId.value,
  user_state_ids: userStateIds.value,
  date: date.value,
  is_planed: isPlaned.value || null,
  search: search.value,
}));

const microdistricts = ref<MicrodistrictDto[]>([]);

const capacityData = ref({ capacity: 0, selected: 0 });

const capacityFilled = ref(false);

const fillCapacityBtnDisabled = computed(() => capacityFilled.value || loading.value);

const userStateOptions = ref<UserStateDto[]>([]);

const microdistrictOptions = computed(() =>
  microdistricts.value.map(({ id, title }) => ({ id, title }))
);

const currentMicrodistrict = computed(
  () =>
    microdistricts.value.find((f) => f.id === microdistrictId.value) ??
    (currentUser.value.microdistrict as MicrodistrictDto)
);

const setData = (data: { data: PatronagePlanningDto; meta: MetaDto }) => {
  store.dispatch('userPatronages/setData', {
    items: data.data.users.map((user) => ({
      ...user,
      nurse_id: user.nurse?.id,
    })),
    total: data.meta?.total ? +data.meta?.total : 0,
    overwriteDataState: true,
  });

  capacityData.value = { capacity: data.data.capacity, selected: data.data.selected };
};

const getUserPatronages = async () => {
  if (!microdistrictId.value) return;

  store.dispatch('userPatronages/setLoading', true);

  const result = await Patronage.getPatronagePlanning(query.value);
  if (result) {
    setData(result);
  }
  store.dispatch('userPatronages/setLoading', false);
};

const fillCapacity = async () => {
  page.value = 1;
  store.dispatch('userPatronages/setLoading', true);
  const result = await Patronage.fillCapacity(
    {
      per_page: perPage.value,
      page: page.value,
    },
    {
      date: date.value,
      microdistrict_id: currentMicrodistrict.value.id,
    }
  );

  if (result) {
    setData(result);
  }
  store.dispatch('userPatronages/setLoading', false);
};

const updateCapacity = (data: PatronageUpdateResponse) => {
  capacityData.value = { capacity: data.capacity, selected: data.selected };
};

const updateTableItem = (data: PatronageUpdateResponse['user']) => {
  store.dispatch('userPatronages/editItem', { ...data, nurse_id: data.nurse?.id });
};

const throttleSearch = throttle(getUserPatronages, 600);

watch(
  query,
  (value, oldValue) => {
    compareQueriesThenLoadData({
      query: value,
      oldQuery: oldValue,
      getData: throttleSearch,
      resetPage: page.reset,
      fieldsForResetPage: ['microdistrict_id', 'date', 'nurse_id'],
    });
  },
  {
    deep: true,
    immediate: true,
  }
);

onMounted(async () => {
  const microdistrictsResult = await Microdistrict.getItems();
  const userStatesResult = await Patronage.getStates();

  if (microdistrictsResult) {
    microdistricts.value = microdistrictsResult.data.data;
    microdistrictId.value = currentUser.value.microdistrict?.id ?? microdistricts.value[0]?.id;
  }
  if (userStatesResult) {
    userStateOptions.value = userStatesResult.data;
  }
});
</script>

<style lang="scss" src="./index.scss" />
