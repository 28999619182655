<template>
  <LayoutByUserRole content-class="v-patient-groups" fix-height>
    <div class="v-patient-groups__header">
      <ElDatePicker
        class="v-patient-groups__date"
        v-model="date.value"
        :format="DATE_FORMAT"
        type="daterange"
        unlink-panels
        :value-format="DATE_FORMAT"
        :placeholder="$t('DateAndTime.Date')" />

      <div class="v-patient-groups__age-data">
        <span>{{ $t('Analytics.AgeFrom') }}</span>
        <UiSelect :options="ageOptions" v-model="ageFrom.value" class="v-patient-groups__age" />
        <span>{{ $t('Analytics.To') }}</span>
        <UiSelect :options="ageOptions" v-model="ageTo.value" class="v-patient-groups__age" />
      </div>

      <a class="v-patient-groups__btn" :href="exportDataURL" download>
        <ElButton type="primary">
          {{ $t('Base.DownloadExcel') }}
        </ElButton>
      </a>
    </div>
    <PatientGroupsStatisticsBlock :query="statisticsQuery" />

    <PatientGroupMicrodistrictsTable
      :loading="loading"
      :items="tableItems"
      :total="tableItemsTotal"
      :default-sort="defaultSort"
      @sort-change="onSortChange"
      v-model:page="page.value"
      v-model:per-page="perPage.value"
      @row-click="goToPatientGroupCodesPage" />
  </LayoutByUserRole>
</template>

<script lang="ts">
export default {
  name: 'VPatientGroups',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { useRoute, useRouter } from 'vue-router';
import { useDatePeriod, usePage, usePerPage } from '@/hooks/query';
import { computed, ref, watch } from 'vue';
import { getMonthPeriod } from '@/utils/dateAndTime.utils';
import { SortType } from '@/types/common';
import { useQuery } from '@/hooks/useQuery.hook';
import { DATE_FORMAT } from '@/config/dateAndTime.config';
import { PatientGroup, PatientGroupMicrodistrictDto } from '@/types/api';
import { PATIENT_GROUP_CODES_ROUTE } from '@/router/patientGroups.routes';
import { Analytics } from '@/models/analytics/analytics.model';
import { compareQueriesThenLoadData } from '@/utils/router.utils';
import { mergeOrCreateQuery } from '@/utils/http.util';
import { deleteEmptyValueKeys } from '@/utils/object.util';
import { I18nService } from '@/services/i18n.service';
import { TokenService } from '@/services/token.service';
import { DeviceService } from '@/services/device.service';

import PatientGroupsStatisticsBlock from '@/components/analytics/patientGroups/PatientGroupsStatisticsBlock/index.vue';
import PatientGroupMicrodistrictsTable from '@/components/analytics/patientGroups/tables/PatientGroupMicrodistrictsTable/index.vue';
import LayoutByUserRole from '@/components/layouts/LayoutByUserRole/index.vue';

const route = useRoute();
const router = useRouter();
const page = usePage();
const perPage = usePerPage();
const loading = ref(false);
const tableItems = ref<PatientGroupMicrodistrictDto[]>([]);
const tableItemsTotal = ref(0);

const date = useDatePeriod(getMonthPeriod());
const defaultSort = ref<null | SortType>(null);
const ageFrom = useQuery({ field: 'age_from', defaultValue: 0 });
const ageTo = useQuery({ field: 'age_to', defaultValue: 120 });

const group = computed(() => route.path.split('/')[2] as PatientGroup);

const getConvertOrder = () => {
  if (!!defaultSort.value?.order) {
    return defaultSort.value.order === 'ascending' ? 'ASC' : 'DESC';
  } else {
    return null;
  }
};

const ageOptions = Array.from({ length: 120 }, (_, i) => {
  return {
    label: i.toString(),
    value: i,
  };
});

const statisticsQuery = computed(() => ({
  group: group.value,
  start_at: date.value[0],
  end_at: date.value[1],
  age_from: ageFrom.value,
  age_to: ageTo.value,
}));

const query = computed(() => ({
  group: group.value,
  page: page.value,
  per_page: perPage.value,
  start_at: date.value[0],
  end_at: date.value[1],
  age_from: ageFrom.value,
  age_to: ageTo.value,
  man: defaultSort.value?.prop === 'count_group_man' ? getConvertOrder() : null,
  woman: defaultSort.value?.prop === 'count_group_woman' ? getConvertOrder() : null,
  total_count: defaultSort.value?.prop === 'count_group' ? getConvertOrder() : null,
}));

const exportDataURL = computed(() => {
  return mergeOrCreateQuery({
    url: Analytics.exportGroupsMicrodistrictsDataURL,
    query: deleteEmptyValueKeys({
      ...query.value,
      lang: I18nService.getLocale(),
      token: TokenService.getAccessToken(),
      device_id: DeviceService.getDeviceId(),
    }),
  });
});

const goToPatientGroupCodesPage = (row: PatientGroupMicrodistrictDto) => {
  router.push({
    name: PATIENT_GROUP_CODES_ROUTE.name,
    query: {
      date_period: date.value,
      age_from: ageFrom.value,
      age_to: ageTo.value,
    },
    params: {
      microdistrict_id: row.id,
      group: group.value,
    },
  });
};

const onSortChange = ({ prop, order }: SortType) => {
  defaultSort.value = { prop: prop, order: order };
};

const getPatientGroupMicrodistricts = async () => {
  loading.value = true;
  const response = await Analytics.getAnalyticsMicrodistricts<PatientGroupMicrodistrictDto[]>(
    query.value
  );

  tableItems.value = response?.data ?? [];
  tableItemsTotal.value = response?.meta.total ?? 0;

  loading.value = false;
};

watch(
  query,
  (value, oldValue) => {
    compareQueriesThenLoadData({
      query: value,
      oldQuery: oldValue,
      getData: getPatientGroupMicrodistricts,
      resetPage: page.reset,
    });
  },
  { deep: true, immediate: true }
);
</script>

<style lang="scss" src="./index.scss" />

<i18n src="@/locales/patients.locales.json" />
<i18n src="@/locales/analytics.locales.json" />
