<template>
  <ElButton type="primary" plain :disabled="disabled" @click="startScan">
    <template #icon> <UiIcon :icon="icons.SCAN" /> </template>
    <div v-if="!onlyIcon">{{ text }}</div>

    <ScanModal
      :model-value="modalIsOpen"
      :text="isLoading ? $t('Base.Loading') : null"
      ref="scanModal"
      @scan:success="scanHandler"
      @update:model-value="endScan" />
  </ElButton>
</template>

<script>
import axios from 'axios';
import { Order } from '@/models/laboratory/Order.model';

import ScanModal from '@/components/scanner/ScanModal/index.vue';
import * as icons from '@/enums/icons.enum.js';

export default {
  name: 'ScanBiomaterialBarCode',
  components: { ScanModal },
  emits: ['scan:success'],
  props: {
    disabled: Boolean,
    onlyIcon: Boolean,
  },
  data() {
    return {
      isScanning: false,
      isLoading: false,
      modalIsOpen: false,
    };
  },
  computed: {
    text() {
      if (this.isLoading) return this.$t('Base.Loading');
      if (this.isScanning) return this.$t('Base.Scanning');

      return this.$t('Base.ScanBarCode');
    },
  },

  methods: {
    startScan() {
      if (this.isScanning) return;
      this.isScanning = true;
      this.modalIsOpen = true;
    },
    endScan() {
      this.isScanning = false;
      this.isLoading = false;
      this.modalIsOpen = false;
    },

    async scanHandler(data) {
      if (this.isLoading) return;
      this.isLoading = true;

      try {
        const order = await Order.getByBiomaterialBarCodePayload(data);
        this.$emit('scan:success', order);
        this.endScan();
      } catch (err) {
        this.$notify({
          type: 'error',
          title: axios.isAxiosError(err) ? err.message : String(err),
        });
        this.$refs.scanModal.reset();
      }

      this.isLoading = false;
    },
  },

  setup: () => ({ icons }),
};
</script>

<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/notifications.locales.json" />
