import { onlyLoggedInMiddleware } from '@/middlewares/onlyLoggedIn.middleware';
import { byUserRolesMiddleware } from '@/middlewares/byUserRoles.middleware';
import { User } from '@/models/User.model';

export const DISABLED_PEOPLE_PATH_PREFIX = '/disabled_people';

export const DISABLED_PEOPLE_ROUTE = {
  name: 'DISABLED_PEOPLE',
  path: `${DISABLED_PEOPLE_PATH_PREFIX}`,
  component: 'VDisabledPeople',
  beforeEnter: [
    onlyLoggedInMiddleware,
    byUserRolesMiddleware([User.enum.roles.Doctor, User.enum.roles.Director]),
  ],
  props: true,
  meta: {
    title: 'Analytics.DisabledPeople',
  },
};
export const DISABLED_PEOPLE_PATIENTS_ROUTE = {
  name: 'DISABLED_PEOPLE_PATIENTS',
  path: `${DISABLED_PEOPLE_PATH_PREFIX}/:microdistrict_id`,
  component: 'VDisabledPeoplePatients',
  beforeEnter: [
    onlyLoggedInMiddleware,
    byUserRolesMiddleware([User.enum.roles.Doctor, User.enum.roles.Director]),
  ],
  props: true,
};

export const DISABLED_PATIENTS_TRANSFERRED_ROUTE = {
  name: 'DISABLED_PATIENTS_TRANSFERRED',
  path: `${DISABLED_PEOPLE_PATH_PREFIX}/change`,
  component: 'VDisabledPatientsTransferred',
  beforeEnter: [
    onlyLoggedInMiddleware,
    byUserRolesMiddleware([User.enum.roles.Doctor, User.enum.roles.Director]),
  ],
  props: true,
};

export const routes = [
  DISABLED_PEOPLE_ROUTE,
  DISABLED_PEOPLE_PATIENTS_ROUTE,
  DISABLED_PATIENTS_TRANSFERRED_ROUTE,
];
