<template>
  <LayoutByUserRole content-class="v-pregnants-by-group" fix-height>
    <ElBreadcrumb separator="/">
      <ElBreadcrumbItem
        :to="{
          path: `${PREGNANT_PATH_PREFIX}`,
          query: {
            date_period: date.value,
            age_from: ageFrom.value,
            age_to: ageTo.value,
            is_pregnancy: 1,
          },
        }"
        >{{ $t('Analytics.Pregnants') }}
      </ElBreadcrumbItem>
      <ElBreadcrumbItem>{{ getTitleByPregnancyStatus }}</ElBreadcrumbItem>
    </ElBreadcrumb>
    <div class="v-pregnants-by-group__header">
      <ElDatePicker
        class="v-pregnants-by-group__date"
        v-model="date.value"
        :format="DATE_FORMAT"
        type="daterange"
        unlink-panels
        :value-format="DATE_FORMAT"
        :placeholder="$t('DateAndTime.Date')" />

      <div class="v-pregnants-by-group__age-data">
        <span>{{ $t('Analytics.AgeFrom') }}</span>
        <UiSelect :options="ageOptions" v-model="ageFrom.value" class="v-pregnants-by-group__age" />
        <span>{{ $t('Analytics.To') }}</span>
        <UiSelect :options="ageOptions" v-model="ageTo.value" class="v-pregnants-by-group__age" />
      </div>

      <a class="v-pregnants-by-group__btn" :href="exportDataURL" download>
        <ElButton type="primary">
          {{ $t('Base.DownloadExcel') }}
        </ElButton>
      </a>
    </div>
    <PregnantsByGroupTable
      :loading="loading"
      :items="tableItems"
      :total="tableItemsTotal"
      v-model:page="page.value"
      v-model:per-page="perPage.value" />
  </LayoutByUserRole>
</template>
<script lang="ts">
export default {
  name: 'VPregnantsByGroup',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { useDatePeriod, usePage, usePerPage } from '@/hooks/query';
import { DATE_FORMAT } from '@/config/dateAndTime.config';
import { getMonthPeriod } from '@/utils/dateAndTime.utils';
import { useQuery } from '@/hooks/useQuery.hook';
import { useRoute } from 'vue-router';
import { computed, ref, watch } from 'vue';
import { PREGNANT_PATH_PREFIX } from '@/router/pregnant.routes';
import { Analytics } from '@/models/analytics/analytics.model';
import { PregnancyType, PregnantsByGroupDto } from '@/types/api';
import { compareQueriesThenLoadData } from '@/utils/router.utils';
import { I18nService } from '@/services/i18n.service';
import { mergeOrCreateQuery } from '@/utils/http.util';
import { deleteEmptyValueKeys } from '@/utils/object.util';
import { TokenService } from '@/services/token.service';
import { DeviceService } from '@/services/device.service';

import LayoutByUserRole from '@/components/layouts/LayoutByUserRole/index.vue';
import PregnantsByGroupTable from '@/components/analytics/pregnants/tables/PregnantsByGroupTable/index.vue';

const route = useRoute();

const statusPregnancy = route.query.pregnancy_status as PregnancyType;
const microdistrictId = route.query.microdistrict_id as string;

const page = usePage();
const perPage = usePerPage();
const loading = ref(false);
const tableItems = ref<PregnantsByGroupDto[]>([]);
const tableItemsTotal = ref(0);

const date = useDatePeriod(getMonthPeriod());
const ageFrom = useQuery({ field: 'age_from', defaultValue: 0 });
const ageTo = useQuery({ field: 'age_to', defaultValue: 120 });

const ageOptions = Array.from({ length: 120 }, (_, i) => {
  return {
    label: i.toString(),
    value: i,
  };
});

const getTitleByPregnancyStatus = computed(() => {
  switch (statusPregnancy) {
    case 'gave_birth':
      return I18nService.t('Statistic.SuccessPregnants');
    case 'misbirth':
      return I18nService.t('Statistic.ChildbirthWithComplications');
    case 'abort':
      return I18nService.t('Statistic.Aborts');
    default:
      return I18nService.t('Statistic.SuccessPregnants');
  }
});

const query = computed(() => ({
  page: page.value,
  per_page: perPage.value,
  start_at: date.value[0],
  end_at: date.value[1],
  is_pregnancy: 1,
  age_from: ageFrom.value,
  age_to: ageTo.value,
  pregnancy_status: statusPregnancy,
  microdistrict_id: microdistrictId,
}));

const getPregnancyGroup = async () => {
  loading.value = true;

  const response = await Analytics.getAnalyticsPatients<PregnantsByGroupDto[]>(query.value);

  tableItems.value = response?.data ?? [];
  tableItemsTotal.value = response?.meta.total ?? 0;

  loading.value = false;
};

const exportDataURL = computed(() => {
  return mergeOrCreateQuery({
    url: Analytics.exportDataURLPregnancies,
    query: deleteEmptyValueKeys({
      ...query.value,
      lang: I18nService.getLocale(),
      token: TokenService.getAccessToken(),
      device_id: DeviceService.getDeviceId(),
    }),
  });
});

watch(
  query,
  (value, oldValue) => {
    compareQueriesThenLoadData({
      query: value,
      oldQuery: oldValue,
      getData: getPregnancyGroup,
      resetPage: page.reset,
      fieldsForResetPage: ['page', 'per_page', 'start_at', 'end_at', 'age_from', 'age_to'],
    });
  },
  { deep: true, immediate: true }
);
</script>

<style src="./index.scss"></style>
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/dateAndTime.locales.json" />
<i18n src="@/locales/patients.locales.json" />
<i18n src="@/locales/analytics.locales.json" />
