<template>
  <ElInput
    ref="elInputRef"
    class="ui-phone-input"
    v-maska="bindedObject"
    data-maska="+998 (##) ###-##-##"
    v-model="maskedValue"
    maxlength="19"
    v-bind="$attrs" />
</template>

<script lang="ts">
export default {
  name: 'UiPhoneInput',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { MaskaDetail, vMaska } from 'maska';
import { ref, watch } from 'vue';

const props = defineProps<{
  modelValue: string | null;
}>();

const emit = defineEmits(['update:modelValue']);

const UZ_PHONE_PREFIX = '+998';

const elInputRef = ref();
const maskedValue = ref<string | null>('');
const bindedObject = ref<MaskaDetail>({
  masked: '',
  unmasked: '',
  completed: false,
});

const focus = () => {
  elInputRef.value.focus();
};

watch(
  () => props.modelValue,
  (value) => {
    maskedValue.value = value;
  },
  { immediate: true }
);

watch(
  () => bindedObject.value,
  (value) => {
    maskedValue.value = value.masked;

    if (value.masked.length >= 4) {
      emit('update:modelValue', `${UZ_PHONE_PREFIX}${value.unmasked}`);
    } else {
      emit('update:modelValue', `${value.masked}`);
    }
  },
  { deep: true }
);

defineExpose({
  focus,
});
</script>

<style lang="scss" src="./index.scss" />
