<template>
  <LayoutByUserRole content-class="v-patient-group-patients" fix-height>
    <ElBreadcrumb separator="/">
      <ElBreadcrumbItem
        :to="{
          path: `${PATIENT_GROUPS_PATH_PREFIX}/${group}/`,
          query: { date_period: date.value, age_from: ageFrom.value, age_to: ageTo.value },
        }"
        >{{ $t(`Patients.PatientGroups.${group}`) }}
      </ElBreadcrumbItem>
      <ElBreadcrumbItem
        :to="{
          path: `${PATIENT_GROUPS_PATH_PREFIX}/${group}/${microdistrictId}`,
          query: { date_period: date.value, age_from: ageFrom.value, age_to: ageTo.value },
        }"
        >{{ $t('Analytics.Mictrodistrict') }} &#8470; {{ microdistrictId }}
      </ElBreadcrumbItem>
      <ElBreadcrumbItem> {{ diseaseCode }}</ElBreadcrumbItem>
    </ElBreadcrumb>

    <div class="v-patient-group-patients__header">
      <ElDatePicker
        class="v-patient-group-patients__date"
        v-model="date.value"
        :format="DATE_FORMAT"
        type="daterange"
        unlink-panels
        :value-format="DATE_FORMAT"
        :placeholder="$t('DateAndTime.Date')" />

      <div class="v-patient-group-patients__age-data">
        <span>{{ $t('Analytics.AgeFrom') }}</span>
        <UiSelect
          :options="ageOptions"
          v-model="ageFrom.value"
          class="v-patient-group-patients__age" />
        <span>{{ $t('Analytics.To') }}</span>
        <UiSelect
          :options="ageOptions"
          v-model="ageTo.value"
          class="v-patient-group-patients__age" />
      </div>

      <a class="v-patient-group-patients__btn" :href="exportDataURL" download>
        <ElButton type="primary">
          {{ $t('Base.DownloadExcel') }}
        </ElButton>
      </a>
    </div>
    <PatientGroupsStatisticsBlock :query="statisticsQuery" />

    <PatientsListTable
      :loading="loading"
      :items="items"
      :total="total"
      :default-sort="defaultSort"
      @sort-change="onSortChange"
      v-model:page="page.value"
      v-model:per-page="perPage.value" />
  </LayoutByUserRole>
</template>
<script lang="ts">
export default {
  name: 'VPatientGroupPatients',
  inheritAttrs: false,
  customOptions: {},
};
</script>
<script setup lang="ts">
import { DATE_FORMAT } from '@/config/dateAndTime.config';
import { useDatePeriod, usePage, usePerPage } from '@/hooks/query';
import { getMonthPeriod } from '@/utils/dateAndTime.utils';
import { computed, ref, watch } from 'vue';
import { Analytics } from '@/models/analytics/analytics.model';
import { useStore } from 'vuex';
import { compareQueriesThenLoadData } from '@/utils/router.utils';
import { SortType } from '@/types/common';
import { useQuery } from '@/hooks/useQuery.hook';
import { PATIENT_GROUPS_PATH_PREFIX } from '@/router/patientGroups.routes';
import { useRoute } from 'vue-router';
import { mergeOrCreateQuery } from '@/utils/http.util';
import { deleteEmptyValueKeys } from '@/utils/object.util';
import { I18nService } from '@/services/i18n.service';
import { DeviceService } from '@/services/device.service';
import { TokenService } from '@/services/token.service';
import { AnalyticsPatientsDto, PatientGroup } from '@/types/api';

import LayoutByUserRole from '@/components/layouts/LayoutByUserRole/index.vue';
import PatientsListTable from '@/components/analytics/patientGroups/tables/PatientsListTable/index.vue';
import UiSelect from '@/components/ui/UiSelect/index.vue';
import PatientGroupsStatisticsBlock from '@/components/analytics/patientGroups/PatientGroupsStatisticsBlock/index.vue';

const store = useStore();
const date = useDatePeriod(getMonthPeriod());
const page = usePage();
const perPage = usePerPage();
const loading = ref(false);
const ageFrom = useQuery({ field: 'age_from', defaultValue: 0 });
const ageTo = useQuery({ field: 'age_to', defaultValue: 120 });
const route = useRoute();

const microdistrictId = route.params['microdistrict_id'] as string;
const group = route.params.group as PatientGroup;
const diseaseCode = route.params['disease_code'] as string;

const exportDataURL = computed(() => {
  return mergeOrCreateQuery({
    url: Analytics.exportDataURL,
    query: deleteEmptyValueKeys({
      ...query.value,
      lang: I18nService.getLocale(),
      token: TokenService.getAccessToken(),
      device_id: DeviceService.getDeviceId(),
    }),
  });
});

const items = computed(() => store.state.patientsList.data);
const total = computed(() => store.state.patientsList.total);
const query = computed(() => ({
  page: page.value,
  per_page: perPage.value,
  start_at: date.value[0],
  end_at: date.value[1],
  birthdate: defaultSort.value?.prop === 'birthdate' ? getConvertOrder() : null,
  registration_date: defaultSort.value?.prop === 'registration_date' ? getConvertOrder() : null,
  age_from: ageFrom.value,
  age_to: ageTo.value,
  group: group,
  disease_code: diseaseCode,
  microdistrict_id: Number(microdistrictId),
}));

const statisticsQuery = computed(() => ({
  group,
  disease_code: diseaseCode,
  microdistrict_id: Number(microdistrictId),
  start_at: date.value[0],
  end_at: date.value[1],
  age_from: ageFrom.value,
  age_to: ageTo.value,
}));

const getPatientsList = async () => {
  loading.value = true;

  const result = await Analytics.getAnalyticsPatients<AnalyticsPatientsDto[]>(query.value);

  if (result) {
    store.dispatch('patientsList/setData', {
      items: result.data,
      total: result.meta.total,
      overwriteDataState: true,
    });
  }

  loading.value = false;
};

const defaultSort = ref<null | SortType>(null);

const getConvertOrder = () => {
  if (!!defaultSort.value?.order) {
    return defaultSort.value.order === 'ascending' ? 'ASC' : 'DESC';
  } else {
    return null;
  }
};

const onSortChange = ({ prop, order }: SortType) => {
  defaultSort.value = { prop: prop, order: order };
};

const ageOptions = computed(() => {
  const options = [];

  for (let i = 0; i <= 120; i++) {
    options.push({ label: i.toString(), value: i });
  }

  return options;
});

watch(
  query,
  (value, oldValue) => {
    compareQueriesThenLoadData({
      query: value,
      oldQuery: oldValue,
      getData: getPatientsList,
      resetPage: page.reset,
      fieldsForResetPage: [
        'page',
        'per_page',
        'start_at',
        'end_at',
        'birthdate',
        'registration_date',
        'age_from',
        'age_to',
      ],
    });
  },
  { deep: true, immediate: true }
);
</script>

<style scoped lang="scss" src="./index.scss" />
<i18n src="@/locales/patients.locales.json" />
