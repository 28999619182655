<template>
  <div class="map">
    <YandexMap
      :settings="settings"
      :coordinates="initialCenter"
      :zoom="zoom"
      :controls="['zoomControl', 'typeSelector', 'fullscreenControl']"
      @created="mapLoaded">
      <YandexClusterer :options="{ preset: 'islands#nightClusterIcons' }">
        <YandexMarker
          v-for="marker in markerProperties"
          :key="`${marker.coords.join('')}`"
          :properties="{
            hintContent: marker.properties?.hitContent ?? '',
          }"
          :coordinates="marker.coords"
          :marker-id="`${marker.coords.join('')}`"
          @click="onClickMarker(marker.id)" />
      </YandexClusterer>
    </YandexMap>
  </div>
</template>

<script lang="ts">
export default {
  name: 'GeoMap',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script setup lang="ts">
import { YandexMap, YandexMarker, YandexClusterer } from 'vue-yandex-maps';
import { Map } from 'yandex-maps';
import {
  computed,
  withDefaults,
  watch,
  getCurrentInstance,
  onUnmounted,
  nextTick,
  ComponentInternalInstance,
} from 'vue';
import { DEFAULT_LOCALE, LANG_AND_REGION } from '@/config/i18n.config';
import { MarkerPropertiesType } from './types';

type Props = {
  locale?: string;
  initialCenter?: [number, number];
  zoom?: number;
  markerProperties?: Array<MarkerPropertiesType>;
};

const props = withDefaults(defineProps<Props>(), {
  markerProperties: () => [],
  locale: DEFAULT_LOCALE,
  zoom: 11,
  initialCenter: () => [41.28, 69.15],
});
const currencyInstance = getCurrentInstance() as ComponentInternalInstance & { map: Map };
const emit = defineEmits(['marker-click']);

const lang = computed(() =>
  props.locale ? LANG_AND_REGION[props.locale] : LANG_AND_REGION[DEFAULT_LOCALE]
);

const settings = {
  apiKey: import.meta.env.VITE_YANDEX_MAP_KEY,
  lang: lang.value,
  coordorder: 'latlong',
  debug: false,
  version: '2.1',
};
const mapLoaded = (mapInstance: Map) => {
  currencyInstance.map = mapInstance;
  setBounds();
};

const bound = computed(() => props.markerProperties.map((marker) => marker.coords));

const setBounds = () => {
  nextTick().then(() => {
    if (bound.value.length > 1) {
      currencyInstance.map.setBounds(window.ymaps.util.bounds.fromPoints(bound.value));
    } else {
      currencyInstance.map.setZoom(props.zoom);
    }
  });
};

const onClickMarker = (id: number) => {
  emit('marker-click', id);
};

onUnmounted(() => {
  currencyInstance.map?.destroy();
});

watch(bound, () => {
  if (!currencyInstance.map) return;
  setBounds();
});
</script>

<style lang="scss" src="./index.scss"></style>
