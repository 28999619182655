﻿{
  "en": {
    "Appointments": {
      "ActiveAppointments": "Active",
      "AddAnalysis": "Add Analysis",
      "AddAppointment": "Add appointment",
      "AddNote": "Add appointment",
      "AllAppointments": "All appointments",
      "Appointment": "Appointments",
      "AppointmentInformation": "Appointment information",
      "Appointments": "Records",
      "AppointmentsTc": "{n} records | {n} record | {n} records",
      "CallToReception": "Call for an appointment",
      "CancelReception": "Cancel recording",
      "ChooseTemplate": "Select template",
      "ClosedAppointments": "Closed",
      "Conditions": {
        "Average": "Average",
        "Normal": "Normal",
        "Serious": "Serious"
      },
      "CreateAppointment": "Create Recording",
      "EditAppointment": "Edit appointment",
      "EditReception": "Edit Reception",
      "EndReception": "End reception",
      "HomeVisit": "Home visit",
      "InspectionCard": {
        "Anomnes": "Anamnesis",
        "AnomnesLife": "Anamnesis life",
        "GeneralState": "General condition",
        "LocalStatus": "Local status",
        "Operations": "Operations",
        "PlanObservation": "Survey Plan",
        "PreDiagnosis": "Preliminary diagnosis",
        "Recommendations": "Recommendations",
        "Сomplaints": "Complaints"
      },
      "InspectionTypes": {
        "full": "Full examination",
        "treatment": "Examination of the treatment diary"
      },
      "LiveQueue": "Live queue",
      "PatientCome": "Patient appeared",
      "PatientNotCome": "Patient did not appear",
      "PhonePatient": "Patient's phone",
      "ReOpen": "Reopen",
      "RecordOnTime": "On time",
      "RecordingSource": "Record Source",
      "SaveTemplate": "Save as template",
      "SelectAnalysis": "Select analysis",
      "SelectDiagnosis": "Select Diagnosis",
      "TraveledToThePatient": "Traveled to the patient",
      "StartInspection": "Start inspection",
      "CompleteInspection": "Complete inspection",
      "SelectDoctor": "Select Doctor",
      "SelectIDC": "Select IDC",
      "SelectPatientCondition": "Select patient condition",
      "SelectRecordingSource": "Select recording source",
      "SelectReferralDoctor": "Select a referral doctor",
      "SelectServices": "Select services",
      "SelectStatus": "Select status",
      "SetControlAppointment": "Assign control appointment",
      "SetExamination": "Assign examination",
      "StartAppointment": "Start appointment",
      "StartDate": "Date of appointment",
      "StartDateAndTime": "Time and date of reception",
      "StartTime": "Time of reception",
      "State": "State",
      "Status": "Status",
      "Statuses": {
        "Status": "Statuses",
        "approved": "In queue",
        "canceled": "Canceled",
        "created": "Created",
        "in_progress": "In progress",
        "on_the_way": "On the way",
        "provided": "Finished",
        "waiting": "Waiting"
      },
      "ToDiagnose": "Diagnose",
      "Types": {
        "doctor": "Doctor",
        "service": "Service"
      }
    }
  },
  "ru": {
    "Appointments": {
      "ActiveAppointments": "Активные",
      "AddAnalysis": "Добавить анализ",
      "AddAppointment": "Добавить назначения",
      "AddNote": "Добавить запись",
      "AllAppointments": "Все записи",
      "Appointment": "Назначения",
      "AppointmentInformation": "Информация о записи",
      "Appointments": "Записи",
      "AppointmentsTc": "{n} записей | {n} запись | {n} записей",
      "CallToReception": "Вызвать на прием",
      "CancelReception": "Отменить запись",
      "ChooseTemplate": "Выберите шаблон",
      "ClosedAppointments": "Закрытые",
      "Conditions": {
        "Average": "Среднее",
        "Normal": "Нормальное",
        "Serious": "Тяжелое"
      },
      "CreateAppointment": "Создать запись",
      "EditAppointment": "Редактриовать назначения",
      "EditReception": "Редактировать прием",
      "EndReception": "Завершить прием",
      "HomeVisit": "Выезд на дом",
      "InspectionCard": {
        "Anomnes": "Анамнез",
        "AnomnesLife": "Анамнез жизни",
        "GeneralState": "Общее состояние",
        "LocalStatus": "Локальный статус",
        "Operations": "Операции",
        "PlanObservation": "План обследования",
        "PreDiagnosis": "Предворительный диагноз",
        "Recommendations": "Рекомендации",
        "Сomplaints": "Жалобы"
      },
      "InspectionTypes": {
        "full": "Полный осмотр",
        "treatment": "Осмотр дневника лечения"
      },
      "LiveQueue": "Живая очередь",
      "PatientCome": "Пациент явился",
      "PatientNotCome": "Пациент не явился",
      "PhonePatient": "Телефон пациента",
      "ReOpen": "Переоткрыть",
      "RecordOnTime": "На время",
      "RecordingSource": "Источник записи",
      "SaveTemplate": "Сохранить как шаблон",
      "SelectAnalysis": "Выберите анализ",
      "SelectDiagnosis": "Выберите диагноз",
      "TraveledToThePatient": "Выехал к пациенту",
      "StartInspection": "Начать осмотр",
      "CompleteInspection": "Завершить осмотр",
      "SelectDoctor": "Выберите доктора",
      "SelectIDC": "Выбрать IDC",
      "SelectPatientCondition": "Выбрать состояние пациента",
      "SelectRecordingSource": "Выберите источник записи",
      "SelectReferralDoctor": "Выберите реферального доктора",
      "SelectServices": "Выберите услуги",
      "SelectStatus": "Выберите статус",
      "SetControlAppointment": "Назначить контрольный приём",
      "SetExamination": "Назначить обследование",
      "StartAppointment": "Начать приём",
      "StartDate": "Дата приёма",
      "StartDateAndTime": "Время и дата приёма",
      "StartTime": "Время приёма",
      "State": "Состояние",
      "Status": "Статус",
      "Statuses": {
        "Status": "Статусы",
        "approved": "В очереди",
        "canceled": "Отменён",
        "created": "Создан",
        "in_progress": "В процессе",
        "on_the_way": "Выехал",
        "provided": "Закончен",
        "waiting": "Ожидает"
      },
      "ToDiagnose": "Поставить диагноз",
      "Types": {
        "doctor": "Врач",
        "service": "Услуга"
      }
    }
  },
  "uz": {
    "Appointments": {
      "ActiveAppointments": "Aktiv",
      "AddAnalysis": "Tahlilni qo'shish",
      "AddAppointment": "Uchrashuvni qo'shish",
      "AddNote": "Yozuvni qo'shish",
      "AllAppointments": "Barcha uchrashuvlar",
      "Appointment": "Uchrashuvlar",
      "AppointmentInformation": "Yozuv haqida ma'lumot",
      "Appointments": "Qabulga yozuvlar",
      "AppointmentsTc": "{n} yozuvlar | {n} yozuv | {n} yozuvlar",
      "CallToReception": "Qabul qilish",
      "CancelReception": "Qabulga yozuvni bekor qilish",
      "ChooseTemplate": "Shablonni tanlang",
      "ClosedAppointments": "Yopiq",
      "Conditions": {
        "Average": "O'rta",
        "Normal": "Normal",
        "Serious": "Xavfli"
      },
      "CreateAppointment": "Qabulga yozuvni yaratish",
      "EditAppointment": "Uchrashuvlarni tahrirlash",
      "EditReception": "Qabulga yozuvni tahrirlash",
      "EndReception": "Qabulni yakunlash",
      "HomeVisit": "Uyga chaqiruv",
      "InspectionCard": {
        "Anomnes": "Anamnez",
        "AnomnesLife": "To'liq anamnez",
        "GeneralState": "Umumiy holat",
        "LocalStatus": "Mahalliy holat",
        "Operations": "Operatsiyalar",
        "PlanObservation": "So'rov rejasi",
        "PreDiagnosis": "Dastlabki tashxis",
        "Recommendations": "Tavsiyalar",
        "Сomplaints": "Shikoyatlar"
      },
      "InspectionTypes": {
        "full": "To'liq tekshirish",
        "treatment": "Davolash jurnalini ko'rib chiqish"
      },
      "LiveQueue": "Jonli navbat",
      "PatientCome": "Bemor tashrif buyurdi",
      "PatientNotCome": "Bemor tashrif buyurmadi",
      "PhonePatient": "Bemorning telefon raqami",
      "ReOpen": "Qayta ochish",
      "RecordOnTime": "Vaqtga",
      "RecordingSource": "Qabulga yozuv manbai",
      "SaveTemplate": "Shablon sifatida saqlash",
      "SelectAnalysis": "Tahlilni tanlang",
      "SelectDiagnosis": "Diagnozni tanlang",
      "TraveledToThePatient": "Bemorga sayohat qilindi",
      "StartInspection": "Tekshirishni boshlash",
      "CompleteInspection": "Tekshirishni yakunlash",
      "SelectDoctor": "Shifokorni tanlang",
      "SelectIDC": "Shablonni IDC",
      "SelectPatientCondition": "Mijoz holatini tanlang",
      "SelectRecordingSource": "Qabulga yozuv manbasini tanlang",
      "SelectReferralDoctor": "Yo'naltiruvchi shifokorni tanlang",
      "SelectServices": "Xizmatlarni tanlang",
      "SelectStatus": "Statusni tanlang",
      "SetControlAppointment": "Nazorat uchrashuvini o'rnatish",
      "SetExamination": "Tekshiruvni rejalashtirish",
      "StartAppointment": "Qabulni boshlash",
      "StartDate": "Qabul sanansi",
      "StartDateAndTime": "Qabul sanasi va vaqti",
      "StartTime": "Qabul vaqti",
      "State": "Holat",
      "Status": "Status",
      "Statuses": {
        "Status": "Statuslar",
        "approved": "Navbatda",
        "canceled": "Bekor qilindi",
        "created": "Yaratildi",
        "in_progress": "Jarayonda",
        "on_the_way": "Yo'lda",
        "provided": "Yakunlangan",
        "waiting": "Kutilmoqda"
      },
      "ToDiagnose": "Tashxis qo'yish",
      "Types": {
        "doctor": "Shifokor",
        "service": "Xizmat"
      }
    }
  }
}
