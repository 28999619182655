import { ApiService } from '@/services/api.service';
import { mergeOrCreateQuery } from '@/utils/http.util';

export class Clinic {
  static modelName = 'clinic';
  static tableName = 'clinics';

  /**
   * @param {object} payload
   * @param {number} payload.id
   * @param {string} payload.title
   * @param {string} payload.specialization
   * @param {string} payload.address
   * @param {number} payload.city_id
   * @param {City} payload.city
   * @param {number} payload.district_id
   * @param {District} payload.district
   * @param {number} payload.logo_id
   * @param {File} payload.logo
   * @param {string} payload.published_at
   */
  constructor(payload) {
    this.id = payload?.id ?? null;
    this.title = payload?.title ?? null;
    this.specialization = payload?.specialization ?? null;
    this.address = payload?.address ?? null;
    this.city_id = payload?.city_id ?? null;
    this.city = payload?.city ?? null;
    this.district_id = payload?.district_id ?? null;
    this.district = payload?.district ?? null;
    this.logo_id = payload?.logo_id ?? null;
    this.logo = payload?.logo ?? null;
    this.published_at = payload?.published_at ?? null;
  }

  /**
   * @param {string} startAt DATE_FORMAT
   * @param {string} endAt DATE_FORMAT
   * @return {Promise<{data: Array<number>, response: AxiosResponse<any>}>} data[0] is monday
   */
  static async getWeeklyWorkloadByPeriod({ startAt, endAt }) {
    const response = await ApiService.get(
      mergeOrCreateQuery({
        url: `statistics/clinic/workload/weekly`,
        query: { start_at: startAt, end_at: endAt },
      })
    );

    return { response, data: response.data };
  }

  /**
   * @param {string} startAt DATE_FORMAT
   * @param {string} endAt DATE_FORMAT
   * @return {Promise<{data: object, response: AxiosResponse<any>}>} data[key] = value, key = hh:mm
   */
  static async getDailyWorkloadByPeriod({ startAt, endAt }) {
    const response = await ApiService.get(
      mergeOrCreateQuery({
        url: `statistics/clinic/workload/daily`,
        query: { start_at: startAt, end_at: endAt },
      })
    );

    return { response, data: response.data };
  }
}
