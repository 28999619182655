import { CRUDModel } from '@/models/CRUD.model';
import { AppointmentServiceGroupWithCount } from '@/models/appointment/AppointmentServiceGroupWithCount.model';
import { ApiService } from '@/services/api.service';
import { mergeOrCreateQuery } from '@/utils/http.util';
import { ElNotification } from 'element-plus';
import { getApiErrorMessage } from '@/utils/http.util';
import { deleteEmptyValueKeys } from '@/utils/object.util';

/**
 * @class Appointment
 * @extends CRUDModel
 */
export class Appointment extends CRUDModel {
  static modelName = 'appointment';
  static tableName = 'appointments';

  constructor(payload) {
    super(payload);

    this.doctor_id = payload?.doctor?.id ?? null;
    this.user = payload?.user ?? null;
    this.user_id = payload?.user?.id ?? null;
    this.start_at = payload?.start_at ?? '';
    this.end_at = payload?.end_at ?? '';
    this.status = payload?.status ?? '';
    this.patient_id = payload?.patient?.id ?? payload?.user?.id ?? null;
    this.patient = payload?.patient ?? payload?.user ?? null;
    this.patient_condition = payload?.patient_condition ?? 'normal';
    this.abstract_biomaterials = payload?.abstract_biomaterials ?? [];
    this.abstract_biomaterial_ids = payload?.abstract_biomaterials
      ? payload.abstract_biomaterials.map((item) => item.id)
      : [];
    this.is_analysis = payload?.is_analysis ?? false;
    this.type = payload?.type ?? 'in_queue';

    this.doctor = payload?.doctor ?? null;
    this.specialty_id = payload?.specialty_id ?? null;
    this.specialty = payload?.specialty ?? null;
    this.service_ids = payload?.service_ids ?? [];
    this.services = payload?.services ?? [];
    this.group_service_ids = payload?.group_service_ids ?? [];
    this.group_services = payload?.group_services ?? [];
    this.group_services_with_count =
      payload?.group_services_with_count?.map(
        (elem) => new AppointmentServiceGroupWithCount(elem) // нужно для использования _id в качестве ключа
      ) ?? [];
    this.treatment_id = payload?.treatment_id ?? null;
    this.treatment = payload?.treatment ?? null;
    this.inspection_card_id = payload?.inspection_card_id ?? null;
    this.inspection_card = payload?.inspection_card ?? null;
    this.invoice_id = payload?.invoice_id ?? null;
    this.invoice = payload?.invoice ?? {};
    this.reference_id = payload?.reference_id ?? null;
    this.reference = payload?.reference ?? null;
    this.appointments = payload?.appointments ?? []; // only for create
    this.discount = payload?.discount ?? 0;
    this.cancel_reason = payload?.cancel_reason ?? null;
    this.cancel_description = payload?.cancel_description ?? null;
    this.permissions = {
      editable: payload?.permissions?.editable ?? false,
      deletable: payload?.permissions?.deletable ?? false,
    };
    this.recommendation_id = payload?.recommendation_id ?? null;
    this.recommendation = {
      id: payload?.recommendation?.id ?? null,
      group_services: payload?.recommendation?.group_services ?? [],
      group_service_ids: payload?.recommendation?.group_service_ids ?? [],
    };
    this.disease_codes_object = payload?.disease_codes ?? [];
    this.disease_ids = this.disease_codes_object.map((item) => item.code);
  }

  static exportDataURL = import.meta.env.VITE_API_URL + `b2g/exports/${this.tableName}`;
  static exportDataURLVisit = import.meta.env.VITE_API_URL + `b2g/exports/${this.tableName}/visit`;

  /**
   * @param {number} id
   * @param {object} query
   * @return {Promise<{data: response.data, response: AxiosResponse<*>}>}
   */
  static async find(query) {
    return super.find({ _url: `b2g/${Appointment.tableName}`, ...query });
  }

  static async findOneById(id) {
    const response = await ApiService.get(`b2g/${this.tableName}/${id}`);

    return {
      response: response,
      data: response.data,
      [this.modelName]: response.data.data,
    };
  }

  /**
   * @override
   * @param {Appointment|object} payload
   * @return {Promise<{data: response.data, response: AxiosResponse<*>, modelName?: Object}>}
   */
  static async create(payload) {
    return super.create(payload, { url: `b2g/${Appointment.tableName}/create` });
  }

  /**
   * @param {number} treatmentId
   * @return {Promise<{data: response.data, response: AxiosResponse<*>, modelName?: Object}>}
   */
  static async createByTreatment(treatmentId) {
    return super.create(
      {},
      {
        url: `${Appointment.tableName}/create/byTreatment/${treatmentId}`,
      }
    );
  }

  static async getStatistic({ startAt, endAt, doctorsId }) {
    const response = await ApiService.get(
      mergeOrCreateQuery({
        url: `/${this.tableName}/month`,
        query: deleteEmptyValueKeys({ start_at: startAt, end_at: endAt, doctors_id: doctorsId }),
      })
    );

    return { response, data: response.data };
  }

  static async getByVisit(query) {
    try {
      const response = await ApiService.get(
        mergeOrCreateQuery({
          url: `b2g/${this.tableName}/byVisit`,
          query: deleteEmptyValueKeys(query),
        })
      );

      return { response, data: response.data.data };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: getApiErrorMessage(err),
      });
    }
  }

  /**
   * @param {string,number} doctorId
   * @return {Promise<{data: response.data, response: AxiosResponse<*>}>}
   */
  static async findByDoctor({ doctorId }) {
    const response = await ApiService.get(`${this.tableName}/${doctorId}/queues`);
    return { response, data: response.data };
  }

  static async updateStatus({ id, status }) {
    const response = await ApiService.put(`${this.tableName}/${id}/${status}`);
    return {
      response: response,
      data: response.data,
    };
  }

  /**
   * @param {number} serviceCaseId
   * @param {number} appointmentId
   * @return {Promise<{data: response.data, response: AxiosResponse<any>}>}
   */
  static async attachServiceCase({ serviceCaseId, appointmentId }) {
    const response = await ApiService.put(`${this.tableName}/${appointmentId}/case`, {
      service_case_id: serviceCaseId,
    });
    return { response, data: response.data };
  }

  /**
   * @param {number} treatmentId
   * @param {number} appointmentId
   * @return {Promise<{data: response.data, response: AxiosResponse<any>}>}
   */
  static async attachTreatment({ treatmentId, appointmentId }) {
    const response = await ApiService.put(`${this.tableName}/${appointmentId}/treatment`, {
      treatment_id: treatmentId,
    });
    return { response: response, data: response.data };
  }

  /**
   * @param {string} date format - DD.MM.YY hh:mm
   * @return {Date|string}
   */
  static getStartDate(date) {
    return date.split(' ')[0];
  }

  /**
   * @param {string} date format - DD.MM.YY hh:mm
   * @return {Date|string}
   */
  static getStartTime(date) {
    return date.split(' ')[1];
  }

  /**
   * @param {Object} params
   * @param {string} params.startAt DATE_FORMAT
   * @param {string} params.endAt DATE_FORMAT
   * @param {string|number} [params.doctorId]
   * @return {Promise<{data: response.data, response: AxiosResponse<any>}>}
   */
  static async getCountStatistic({ startAt, endAt, doctorId }) {
    const query = { start_at: startAt, end_at: endAt };

    if (doctorId) {
      query.doctor_id = doctorId;
    }

    const response = await ApiService.get(
      mergeOrCreateQuery({
        url: `statistics/${this.tableName}/count`,
        query,
      })
    );

    return { response: response, data: response.data };
  }

  /**
   * @param {Object} params
   * @param {string} params.startAt DATE_FORMAT
   * @param {string} params.endAt DATE_FORMAT
   * @param {string|number} [params.doctorId]
   * @return {Promise<{data: response.data, response: AxiosResponse<any>}>}
   */
  static async getOnTimeStatistic({ startAt, endAt, doctorId }) {
    const query = { start_at: startAt, end_at: endAt };

    if (doctorId) {
      query.doctor_id = doctorId;
    }

    const response = await ApiService.get(
      mergeOrCreateQuery({
        url: `statistics/${this.tableName}/onTime`,
        query,
      })
    );

    return { response: response, data: response.data };
  }

  /**
   * @param {Object} params
   * @param {string} params.startAt DATE_FORMAT
   * @param {string} params.endAt DATE_FORMAT
   * @return {Promise<{data: response.data, response: AxiosResponse<any>}>}
   */
  static async getAverageWaitingStatistic({ startAt, endAt }) {
    const response = await ApiService.get(
      mergeOrCreateQuery({
        url: `statistics/${this.tableName}/average/waiting`,
        query: { start_at: startAt, end_at: endAt },
      })
    );

    return { response: response, data: response.data };
  }

  /**
   * @param {Object} params
   * @param {string} params.startAt DATE_FORMAT
   * @param {string} params.endAt DATE_FORMAT
   * @param {string|number} [params.doctorId]
   * @return {Promise<{data: response.data, response: AxiosResponse<any>}>}
   */
  static async getSuccessfulStatistic({ startAt, endAt, doctorId }) {
    const query = { start_at: startAt, end_at: endAt };

    if (doctorId) {
      query.doctor_id = doctorId;
    }

    const response = await ApiService.get(
      mergeOrCreateQuery({
        url: `statistics/${this.tableName}/successful`,
        query,
      })
    );

    return { response: response, data: response.data };
  }

  /**
   * @param {Object} payload
   * @param {number} payload.appointment_id
   * @param {number} payload.user_id
   * @param {Array<number>} payload.group_service_ids
   * @return {Promise<{data: response.data, response: AxiosResponse<any>}>}
   */
  static async recommendationCreate(payload) {
    const response = await ApiService.post(`recommendations/create`, payload);

    return {
      response: response,
      data: response.data,
    };
  }

  /**
   * @param {number} id
   * @param {Object} payload
   * @param {Array<number>} payload.group_service_ids
   * @return {Promise<{data: response.data, response: AxiosResponse<any>}>}
   */
  static async recommendationUpdate(id, payload) {
    const response = await ApiService.put(`recommendations/${id}/update`, payload);

    return {
      response: response,
      data: response.data,
    };
  }

  static enum = {
    statuses: {
      Created: 'created',
      Approved: 'approved',
      Waiting: 'waiting',
      InProgress: 'in_progress',
      Provided: 'provided',
      Canceled: 'canceled',
      OnTheWay: 'on_the_way',
    },
    // only for front
    inspectionTypes: {
      Full: 'full',
      Treatment: 'treatment',
    },
    types: {
      InQueue: 'in_queue',
      InTime: 'in_time',
      Visit: 'visit',
    },
  };

  static async updateAppointmentCodes(id, payload) {
    try {
      const response = await ApiService.put(`b2g/${this.tableName}/${id}/codes`, payload);

      return {
        response: response,
        data: response.data.data,
      };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: getApiErrorMessage(err),
      });
    }
  }
}
