<template>
  <ElCard class="clinic-workload-statistic-card" :shadow="shadow" v-loading="loading">
    <template #header>
      <div class="clinic-workload-statistic-card-header__title">{{ $t('Title') }}</div>

      <ElSelect v-model="type">
        <ElOption :label="$t('DateAndTime.Week')" :value="enums.Types.Weekly" />
        <ElOption :label="$t('DateAndTime.Day')" :value="enums.Types.Daily" />
      </ElSelect>
    </template>

    <Vue3Apexcharts
      type="bar"
      :series="CHART_DEFAULT_SERIES"
      :options="CHART_DEFAULT_OPTIONS"
      ref="chart" />
  </ElCard>
</template>

<script>
import Vue3Apexcharts from 'vue3-apexcharts';
import { Clinic } from '@/models/Clinic.model';
import { CHART_DEFAULT_OPTIONS, CHART_DEFAULT_SERIES } from './index.config';

export default {
  name: 'ClinicWorkloadStatisticCard',
  components: { Vue3Apexcharts },
  props: {
    /** @type {Array<string>} period - Array<DATE_FORMAT> */
    period: Array,
    shadow: {
      type: String,
      default: 'never',
    },
  },
  data() {
    return {
      loading: false,
      type: this.enums.Types.Weekly,
    };
  },
  computed: {
    watchersForFetch() {
      return {
        period: this.period,
        type: this.type,
      };
    },
  },

  watch: {
    watchersForFetch: {
      handler() {
        if (this.period) {
          if (this.type === this.enums.Types.Weekly) return this.getWeekly();
          if (this.type === this.enums.Types.Daily) return this.getDaily();
        }
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    async getWeekly() {
      this.loading = true;

      const { data } = await Clinic.getWeeklyWorkloadByPeriod({
        startAt: this.period[0],
        endAt: this.period[1],
      });

      this.$refs.chart.updateSeries([
        {
          name: this.$t('Appointments.Appointments'),
          data: data,
        },
      ]);
      this.$refs.chart.updateOptions({
        ...CHART_DEFAULT_OPTIONS,

        xaxis: {
          ...CHART_DEFAULT_OPTIONS.xaxis,
          categories: Array.from(new Array(7)).map((elem, index) =>
            this.$t(`DateAndTime.Days.${index}.Short`)
          ),
        },
      });

      this.loading = false;
    },

    async getDaily() {
      this.loading = true;

      const { data } = await Clinic.getDailyWorkloadByPeriod({
        startAt: this.period[0],
        endAt: this.period[1],
      });
      this.$refs.chart.updateSeries([
        {
          name: this.$t('Appointments.Appointments'),
          data: Object.keys(data).map((key) => data[key]),
        },
      ]);
      this.$refs.chart.updateOptions({
        ...CHART_DEFAULT_OPTIONS,

        xaxis: {
          ...CHART_DEFAULT_OPTIONS.xaxis,
          categories: Object.keys(data),
        },
      });

      this.loading = false;
    },
  },

  setup: () => ({
    CHART_DEFAULT_SERIES,
    CHART_DEFAULT_OPTIONS,

    enums: {
      Types: {
        Weekly: 'weekly',
        Daily: 'daily',
      },
    },
  }),
};
</script>

<style lang="scss" src="./index.scss" />
<i18n src="./index.locales.json" />
<i18n src="@/locales/appointments.locales.json" />
<i18n src="@/locales/dateAndTime.locales.json" />
