import axios from 'axios';
import { API_LOGOUT_EMIT } from '@/enums/emits.enum';
import { ApiService } from './api.service';
import { ClientStorageService } from '@/services/clientStorage.service';
import { DeviceService } from './device.service';
import { EmitterService } from '@/services/emitter.service';
import { parseJwtToken } from '@/utils/jwt.util';

const enums = {
  ACCESS_TOKEN_KEY: 'access_token',
  REFRESH_TOKEN_KEY: 'refresh_token',
};

export class TokenService {
  static setAccessToken(token) {
    ClientStorageService.setItem(enums.ACCESS_TOKEN_KEY, token);
  }

  static getAccessToken() {
    return localStorage.getItem(enums.ACCESS_TOKEN_KEY);
  }

  static removeAccessToken() {
    ClientStorageService.removeItem(enums.ACCESS_TOKEN_KEY);
  }

  static setRefreshToken(token) {
    ClientStorageService.setItem(enums.REFRESH_TOKEN_KEY, token);
  }

  static getRefreshToken() {
    return ClientStorageService.getItem(enums.REFRESH_TOKEN_KEY);
  }

  static removeRefreshToken() {
    ClientStorageService.removeItem(enums.REFRESH_TOKEN_KEY);
  }

  /**
   * @param {object} params
   * @param {string} params.accessToken
   * @param {string} params.refreshToken
   */
  static setTokens({ accessToken, refreshToken }) {
    this.setAccessToken(accessToken);
    this.setRefreshToken(refreshToken);
  }

  /**
   * @return {{accessToken: string, refreshToken: string}>}} tokens
   */
  static getTokens() {
    const accessToken = this.getAccessToken();
    const refreshToken = this.getRefreshToken();
    return {
      accessToken,
      refreshToken,
    };
  }

  static removeTokens() {
    this.removeAccessToken();
    this.removeRefreshToken();
  }

  /**
   * @param {string} token
   * @param {number} [delta] время в секундах, если нужно вычислить время жизни токена заблаговременно, до того как оно истекло
   * @return {boolean}
   */
  static checkIsTokenValid(token, delta = 0) {
    if (!token) return false;

    const payload = parseJwtToken(token);
    const now = Math.floor(new Date().getTime() / 1000);
    const isValid = payload.exp - now > delta;
    return isValid;
  }

  /**
   * @return {Promise<void>}
   */
  static async refreshTokens(refreshToken) {
    try {
      const payload = {
        refresh_token: refreshToken ?? this.getRefreshToken(),
      };
      const config = {
        headers: { 'Device-Id': DeviceService.getDeviceId() ?? '' },
      };
      const { data } = await axios.post(
        `${import.meta.env.VITE_API_URL}auth/refresh`,
        payload,
        config
      );

      ApiService.setToken(data.access_token);
      this.setTokens({
        accessToken: data.access_token,
        refreshToken: data.refresh_token,
      });
    } catch {
      // В проекте нету системы di, поэтому местами пользуемся глобальным емитером
      EmitterService.emit(API_LOGOUT_EMIT);
    }
  }

  static checkNeedRefreshTokens() {
    const { accessToken, refreshToken } = this.getTokens();
    return refreshToken && !this.checkIsTokenValid(accessToken, 60);
  }
}
