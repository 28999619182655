<template>
  <LayoutByUserRole content-class="v-disabled-people-transferred" fix-height>
    <ElBreadcrumb separator="/">
      <ElBreadcrumbItem
        :to="{
          path: `${DISABLED_PEOPLE_PATH_PREFIX}`,
          query: {
            date_period: date.value,
            age_from: ageFrom.value,
            age_to: ageTo.value,
            is_dispensary: 1,
          },
        }"
        >{{ $t('Analytics.Dispensarization') }}
      </ElBreadcrumbItem>
      <ElBreadcrumbItem>{{ getTitleByDisabledGroupStatus }} </ElBreadcrumbItem>
    </ElBreadcrumb>
    <div class="v-disabled-people-transferred__header">
      <ElDatePicker
        class="v-disabled-people-transferred__date"
        v-model="date.value"
        :format="DATE_FORMAT"
        type="daterange"
        unlink-panels
        :value-format="DATE_FORMAT"
        :placeholder="$t('DateAndTime.Date')" />

      <div class="v-disabled-people-transferred__age-data">
        <span>{{ $t('Analytics.AgeFrom') }}</span>
        <UiSelect
          :options="ageOptions"
          v-model="ageFrom.value"
          class="v-disabled-people-transferred__age" />
        <span>{{ $t('Analytics.To') }}</span>
        <UiSelect
          :options="ageOptions"
          v-model="ageTo.value"
          class="v-disabled-people-transferred__age" />
      </div>

      <UiSelect
        :options="statusOptions"
        v-model="disabilityGroup.value"
        :placeholder="$t('Patients.DegreeDisability')"
        class="v-disabled-people__status" />

      <a class="v-disabled-people-transferred__btn" :href="exportDataURL" download>
        <ElButton type="primary">
          {{ $t('Base.DownloadExcel') }}
        </ElButton>
      </a>
    </div>

    <DisabledPatientsTransferredTable
      :loading="loading"
      :items="tableItems"
      :total="tableItemsTotal"
      v-model:page="page.value"
      v-model:per-page="perPage.value"
      @row-click="goToPatient" />
  </LayoutByUserRole>
</template>

<script lang="ts">
export default {
  name: 'VDisabledPatientsTransferred',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { useRouter, useRoute } from 'vue-router';
import { useDatePeriod, usePage, usePerPage } from '@/hooks/query';
import { computed, ref, watch } from 'vue';
import { getMonthPeriod } from '@/utils/dateAndTime.utils';
import { useQuery } from '@/hooks/useQuery.hook';
import { DATE_FORMAT } from '@/config/dateAndTime.config';
import { DisabledPatientsTransferredDto } from '@/types/api';
import { Analytics } from '@/models/analytics/analytics.model';
import { compareQueriesThenLoadData } from '@/utils/router.utils';
import { mergeOrCreateQuery } from '@/utils/http.util';
import { deleteEmptyValueKeys } from '@/utils/object.util';
import { I18nService } from '@/services/i18n.service';
import { TokenService } from '@/services/token.service';
import { DeviceService } from '@/services/device.service';
import debounce from 'lodash.debounce';
import { PATIENT_ROUTE } from '@/router/patients.routes';
import { DISABLED_PEOPLE_PATH_PREFIX } from '@/router/disabledРeople.routes';
import { DisabilityGroup } from '@/types/api/disability';

import DisabledPatientsTransferredTable from '@/components/analytics/disabledPeople/tables/DisabledPatientsTransferredTable/index.vue';
import LayoutByUserRole from '@/components/layouts/LayoutByUserRole/index.vue';
import UiSelect from '@/components/ui/UiSelect/index.vue';

const router = useRouter();
const route = useRoute();
const page = usePage();
const perPage = usePerPage();
const loading = ref(false);
const tableItems = ref<DisabledPatientsTransferredDto[]>([]);
const tableItemsTotal = ref(0);
const oldGroupDisabilities = route.query.old_group_disabilities as string;
const newGroupDisabilities = route.query.new_group_disabilities as string;

const date = useDatePeriod(getMonthPeriod());
const ageFrom = useQuery({ field: 'age_from', defaultValue: 0 });
const ageTo = useQuery({ field: 'age_to', defaultValue: 120 });
const disabilityGroup = useQuery({ field: 'disability_group' });

const ageOptions = Array.from({ length: 120 }, (_, i) => {
  return {
    label: i.toString(),
    value: i,
  };
});

const query = computed(() => ({
  is_disability: 1,
  page: page.value,
  per_page: perPage.value,
  start_at: date.value[0],
  end_at: date.value[1],
  age_from: ageFrom.value,
  age_to: ageTo.value,
  old_group_disabilities: oldGroupDisabilities ?? null,
  new_group_disabilities: newGroupDisabilities ?? null,
  disability_group: disabilityGroup.value,
}));

const statusOptions = computed<{ label: string; value: DisabilityGroup }[]>(() => [
  {
    label: I18nService.t('Patients.Degrees.first_group'),
    value: 'first_group',
  },
  {
    label: I18nService.t('Patients.Degrees.second_group'),
    value: 'second_group',
  },
  {
    label: I18nService.t('Patients.Degrees.third_group'),
    value: 'third_group',
  },
]);

const getTitleByDisabledGroupStatus = computed(() => {
  switch (true) {
    case oldGroupDisabilities === 'first_group' && newGroupDisabilities === 'second_group':
      return I18nService.t('Statistic.TransferDisabledFromGroupIToGroupII');
    case newGroupDisabilities === 'first_group' && oldGroupDisabilities === 'second_group':
      return I18nService.t('Statistic.TransferDisabledFromGroupIIToGroupI');
    case newGroupDisabilities === 'second_group' && oldGroupDisabilities === 'third_group':
      return I18nService.t('Statistic.TransferDisabledFromGroupIIToGroupIII');
    case oldGroupDisabilities === 'second_group' && newGroupDisabilities === 'third_group':
      return I18nService.t('Statistic.TransferDisabledFromGroupIIToGroupIII');
    default:
      return I18nService.t('Statistic.TransferDisabledFromGroupIToGroupII');
  }
});

const exportDataURL = computed(() => {
  return mergeOrCreateQuery({
    url: Analytics.exportDisabledPeoplePatientsDataURL,
    query: deleteEmptyValueKeys({
      ...query.value,
      lang: I18nService.getLocale(),
      token: TokenService.getAccessToken(),
      device_id: DeviceService.getDeviceId(),
    }),
  });
});

const goToPatient = debounce((row: DisabledPatientsTransferredDto) => {
  window.open(
    router.resolve({
      name: PATIENT_ROUTE.name,
      params: {
        id: row.id,
      },
    }).href,
    '_blank'
  );
}, 300);

const getDisabledPeopleTransferred = async () => {
  loading.value = true;
  const response = await Analytics.getAnalyticsPatients<DisabledPatientsTransferredDto[]>(
    query.value
  );

  tableItems.value = response?.data ?? [];
  tableItemsTotal.value = response?.meta.total ?? 0;

  loading.value = false;
};

watch(
  query,
  (value, oldValue) => {
    compareQueriesThenLoadData({
      query: value,
      oldQuery: oldValue,
      getData: getDisabledPeopleTransferred,
      resetPage: page.reset,
    });
  },
  { deep: true, immediate: true }
);
</script>

<style lang="scss" src="./index.scss"></style>
