import * as icons from '@/enums/icons.enum';
import { HOSPITAL_ROUTE } from '@/router/hospital.routes';
import UiTableWithPagination from '@/components/ui/UiTableWithPagination/index.vue';
import HospitalStatusTag from '@/components/hospitals/HospitalStatusTag/index.vue';

export default {
  name: 'HospitalsTable',
  emits: ['update:page', 'update:perPage'],
  components: { UiTableWithPagination, HospitalStatusTag },
  props: {
    /** @type Array<Hospital> data */
    data: Array,
    total: Number,
    loading: Boolean,
    page: Number,
    perPage: Number,
    columns: Object,
  },

  methods: {
    goToHospital(hospital) {
      this.$router.push({
        name: HOSPITAL_ROUTE.name,
        params: { id: hospital.id },
      });
    },
  },

  setup: () => ({ icons }),
};
