<template>
  <ElDialog
    class="create-or-pay-invoice-modal"
    :model-value="modelValue"
    @update:model-value="$emit('update:modelValue', $event)">
    <template #header>
      <div v-if="!!invoice.id" class="create-or-pay-invoice-modal-header">
        <div>{{ $t('Base.Invoice') }}:</div>
        <div>#{{ invoice?.id }}</div>
        <InvoiceStatusTag
          class="create-or-pay-invoice-modal-header__status"
          :status="invoice.status" />
      </div>

      <div v-if="!invoice.id" class="create-or-pay-invoice-modal__header">
        {{ $t('Invoices.Create') }}
      </div>
    </template>

    <ElForm
      id="create-or-pay-invoice"
      class="create-or-pay-invoice-modal__content create-or-pay-invoice-modal-content"
      @submit.prevent="submitHandler">
      <UiDataModalGrid>
        <template #info>
          <ElCard
            v-loading="loading.invoice"
            shadow="never"
            :style="{ gridRow: invoice.id && invoice.transactions_ids.length ? '1/3' : 'auto' }">
            <template #header> {{ $t('Base.Info') }}</template>

            <PatientsSearchSelectDataBlock
              v-model="invoice.user_id"
              :disabled="!!invoice.id"
              :hide-select="!!invoice.id"
              :default-item="invoice.user"
              required
              create-permission
              ref="patient"
              @select="invoice.user = $event">
              <template #content-append>
                <!--  Notes  -->
                <ElFormItem class="create-or-pay-invoice-modal__notes" :label="$t('Base.Notes')">
                  <ElInput
                    v-model="invoice.description"
                    type="textarea"
                    :rows="3"
                    :disabled="!!invoice.id" />
                </ElFormItem>

                <!--  Doctors  -->
                <ElFormItem
                  v-if="invoice.appointments_ids.length"
                  :class="[
                    'create-or-pay-invoice-modal__doctors',
                    {
                      'create-or-pay-invoice-modal__doctors_multi':
                        invoice.appointments_ids.length > 1,
                    },
                  ]"
                  :label="$t('Base.Doctor')">
                  <template v-if="invoice.appointments_ids.length === 1">
                    {{ invoice.appointments[0].doctor.name }}
                  </template>

                  <ul v-if="invoice.appointments_ids.length > 1">
                    <li v-for="appointment in invoice.appointments" :key="appointment.id">
                      {{ appointment.doctor.name }}
                    </li>
                  </ul>
                </ElFormItem>

                <!--  ReferenceModel  -->
                <ElFormItem>
                  <SelectReference
                    v-model="invoice.reference_id"
                    :default-item="invoice.reference"
                    :disabled="!!invoice.id"
                    show-create-option
                    @select="invoice.reference = $event" />
                </ElFormItem>

                <!--  Created at  -->
                <ElFormItem v-if="!!invoice.id" :label="$t('DateAndTime.CreatedAt')">
                  {{ invoice.created_at }}
                </ElFormItem>
              </template>
            </PatientsSearchSelectDataBlock>
          </ElCard>
        </template>

        <template #control>
          <ElCard shadow="never" v-loading="loading.invoice">
            <CreateInvoicePaymentSubject
              :invoice="invoice"
              :disabled="!editable"
              @subject:create="attachPaymentSubject" />
          </ElCard>
        </template>

        <template #content>
          <ElCard shadow="never" v-loading="loading.invoice">
            <PaymentSubjectsTable
              class="create-or-pay-invoice-modal__services"
              height="200px"
              :invoice="invoice"
              :items="invoice.payment_subjects"
              :editable="editable"
              :deletable="editable"
              @item:update="updatePaymentSubject"
              @item:remove="deletePaymentSubject" />

            <ElFormItem
              class="create-or-pay-invoice-modal__discount-price-part"
              :label="$t('Base.Discount') + ' (%)'">
              <ElInput
                v-model="invoice.discount"
                type="number"
                placeholder="0%"
                :disabled="!editable"
                min="0"
                max="100" />
            </ElFormItem>

            <ElFormItem
              class="create-or-pay-invoice-modal__discount-price-part"
              :label="$t('Base.Total')">
              {{ totalPrice }}
            </ElFormItem>
          </ElCard>
        </template>

        <template #footer v-if="!!invoice.id">
          <TransactionsTable
            class="create-or-pay-invoice-modal__transactions-table"
            height="200px"
            :items="invoice.transactions"
            :loading="loading.invoice"
            :page="1"
            :per-page="invoice.transactions_ids.length || 1"
            :total="invoice.transactions_ids.length" />
        </template>
      </UiDataModalGrid>
    </ElForm>

    <template #footer>
      <div class="create-or-pay-invoice-modal-actions">
        <ElButton v-if="!!invoice.id" text :loading="loading.print" @click="print">
          <template #icon>
            <UiIcon :icon="icons.PRINTER" />
          </template>
          {{ $t('Base.Print') }}
        </ElButton>
        <ElButton
          v-show="!!invoice.user_id"
          type="primary"
          plain
          :loading="loading.printBracelet"
          @click="printBracelet">
          <template #icon>
            <UiIcon :icon="icons.PRINTER" />
          </template>
          {{ $t('Patients.PrintBracelet') }}
        </ElButton>

        <ElButton v-if="isShowRefundButton" type="danger" plain @click="refund">
          {{ $t('Base.Refund') }}
        </ElButton>
        <ElButton v-if="isShowPayButton" type="primary" @click="pay">
          {{ $t('Base.Pay') }}
        </ElButton>

        <ElButton
          v-show="editable"
          :type="invoice.id ? 'warning' : 'primary'"
          native-type="submit"
          :loading="loading.form"
          form="create-or-pay-invoice">
          {{ invoice.id ? $t('Base.Save') : $t('Base.Create') }}
        </ElButton>
      </div>
    </template>

    <InvoicePayOrRefundModal
      v-if="!!invoice.id"
      v-model="payModalIsOpen"
      :invoice="invoice"
      :type="payType"
      append-to-body
      @action="invoicePayModalActionHandler" />
    <InvoicePrinterCheck :invoice="invoice" :qr-payload="check.qrPayload" ref="invoiceCheck" />
  </ElDialog>
</template>

<script src="./index.js"></script>
<style lang="scss" src="./index.scss" />
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/user.locales.json" />
<i18n src="@/locales/patients.locales.json" />
<i18n src="@/locales/appointments.locales.json" />
<i18n src="@/locales/invoices.locales.json" />
<i18n src="@/locales/notifications.locales.json" />
<i18n src="@/locales/dateAndTime.locales.json" />
<i18n src="./index.locales.json" />
