﻿{
  "en": {
    "Validation": {
      "GreaterZero": "Amount must be greater than or equal to 1",
      "IncorrectDate": "Incorrect date",
      "MaxAmount": "The maximum possible amount is",
      "MaxLength": "Maximum string length - ",
      "MinLength": "Minimum string length - ",
      "OnlyZeroValues": "Value cannot contain only 0",
      "PINFL": "PINFL is occupied by another patient",
      "Passport": "Passport is occupied by another patient",
      "RequiredField": "This is a required field",
      "inputInCorrectFormat": "Enter data in a specified format"
    }
  },
  "ru": {
    "Validation": {
      "GreaterZero": "Значение должно быть больше или равно 1",
      "IncorrectDate": "Некорректная дата",
      "MaxAmount": "Максимально возможная сумма -",
      "MaxLength": "Максимальная длина строки - ",
      "MinLength": "Минимальная длина строки - ",
      "OnlyZeroValues": "Значение не может содержать только 0",
      "PINFL": "ПИНФЛ занят другим пациентом",
      "Passport": "Паспорт занят другим пациентом",
      "RequiredField": "Это обязательное поле",
      "inputInCorrectFormat": "Введите данные в указанном формате"
    }
  },
  "uz": {
    "Validation": {
      "GreaterZero": "Qiymat 1 ga teng yoki undan katta bo‘lishi kerak",
      "IncorrectDate": "Noto'g'ri sanasi",
      "MaxAmount": "Mumkin bo'lgan maksimal miqdor -",
      "MaxLength": "Qatorning maksimal uzunligi - ",
      "MinLength": "Qatorning minimal uzunligi - ",
      "OnlyZeroValues": "Qiymat faqat 0 dan iborat bo'lolmaydi",
      "PINFL": "PINFL boshqa bemor tomonidan band",
      "Passport": "Pasport boshqa bemor tomonidan band",
      "RequiredField": "Bu qatorni to'ldirish shart",
      "inputInCorrectFormat": "Ma'lumotlarni ko'rsatilgan formatda kiriting"
    }
  }
}