<template>
  <LayoutByUserRole content-class="v-doctors-content" fix-height>
    <LayoutContentHeader class="v-doctors-content-header">
      <!-- Date -->
      <ElDatePicker
        class="v-doctors-content-header__date"
        v-model="date.value"
        type="daterange"
        unlink-panels
        :format="DATE_FORMAT"
        :value-format="DATE_FORMAT"
        :start-placeholder="$t('DateAndTime.StartDate')"
        :end-placeholder="$t('DateAndTime.EndDate')" />

      <!-- Specialties -->
      <UiModelsAutocompleteSearch
        class="v-doctors-content-header__specialties"
        :model-value="specialty.value.id"
        :model-for-use="Specialty"
        label="title"
        :default-item="specialty.value.id ? specialty.value : null"
        :placeholder="$t('Doctors.ChooseSpecialty')"
        clearable
        @select="specialty.value = $event" />

      <!-- SearchDoctors -->
      <ElInput
        v-model="search.value"
        class="v-doctors-content-header__search"
        clearable
        :placeholder="$t('Doctors.FindingDoctor')">
        <template #prefix>
          <UiIcon :icon="icons.SEARCH" />
        </template>
        <!--        <template #append>-->
        <!--          <ElButton type="primary" plain native-type="submit">-->
        <!--            {{ $t('Base.Search') }}-->
        <!--          </ElButton>-->
        <!--        </template>-->
      </ElInput>

      <!-- AddDoctor -->
      <!-- //TODO -->
      <!-- <template #actions>
        <ElButton type="primary" @click="createDoctor">
          <template #icon>
            <UiIcon :icon="icons.PLUS" />
          </template>
          Добавить врача
        </ElButton>
      </template> -->
    </LayoutContentHeader>
    <DoctorsTable
      class="v-doctors-content__table"
      :data="items"
      :loading="loading"
      :total="total"
      :default-sort="defaultSort"
      @sort-change="onSortChange"
      v-model:page="page.value"
      v-model:per-page="perPage.value" />
  </LayoutByUserRole>
</template>

<script>
import axios from 'axios';
import { useDatePeriod, usePage, usePerPage } from '@/hooks/query';
import { mapActions, mapState } from 'vuex';
import { compareQueriesThenLoadData } from '@/utils/router.utils';
import { Doctor } from '@/models/Doctor.model';
import { useQuery } from '@/hooks/useQuery.hook';
import { getMonthPeriod, ISOStringToDateAppFormat } from '@/utils/dateAndTime.utils';
import { DATE_FORMAT } from '@/config/dateAndTime.config';
import { useModel } from '@/hooks/useModel';
import { Specialty } from '@/models/Specialty.model';

import LayoutByUserRole from '@/components/layouts/LayoutByUserRole/index.vue';
import LayoutContentHeader from '@/components/layouts/assets/LayoutContentHeader/index.vue';
import DoctorsTable from '@/components/doctors/DoctorsTable/index.vue';
import * as icons from '@/enums/icons.enum';

export default {
  name: 'VDoctors',
  components: { DoctorsTable, LayoutContentHeader, LayoutByUserRole },
  setup: () => ({
    perPage: usePerPage(),
    page: usePage(),
    search: useQuery({ field: 'user' }),
    startAt: useQuery({
      field: 'start_at',
      defaultValue: ISOStringToDateAppFormat(new Date().toISOString()),
    }),
    endAt: useQuery({
      field: 'end_at',
      defaultValue: ISOStringToDateAppFormat(new Date().toISOString()),
    }),
    icons,
    Specialty,
    date: useDatePeriod(getMonthPeriod()),
    DATE_FORMAT,
    specialty: useModel({
      fieldNames: {
        id: 'specialty_id',
        name: 'specialty_name',
      },
    }),
  }),

  data() {
    return {
      defaultSort: {},
    };
  },

  watch: {
    queryWatchers: {
      handler(value, oldValue) {
        compareQueriesThenLoadData({
          query: value,
          oldQuery: oldValue,
          getData: this.getDoctors,
          resetPage: this.page.reset,
        });
      },
      immediate: true,
      deep: true,
    },
  },

  computed: {
    ...mapState({
      loading: (state) => state.doctors.loading,
      items: (state) => state.doctors.data,
      total: (state) => state.doctors.total,
    }),
    queryWatchers() {
      return {
        page: this.page.value,
        per_page: this.perPage.value,
        start_at: this.date.value[0],
        end_at: this.date.value[1],
        specialty_ids: this.specialty.value.id ?? undefined,
        search: this.search.value,
        query_field: ['name'],
        query_type: 'ILIKE',
        appointment_count:
          this.defaultSort.prop === 'count_appointments' ? this.getConvertOrder() : null,
        percent_successful:
          this.defaultSort.prop === 'percent_successful' ? this.getConvertOrder() : null,
      };
    },
  },

  methods: {
    ...mapActions({
      setLoading: 'doctors/setLoading',
      setData: 'doctors/setData',
    }),

    async getDoctors() {
      this.setLoading(true);
      try {
        const { data } = await Doctor.getDoctors(this.queryWatchers);
        this.setData({
          items: data.data,
          total: +data.meta.total,
          overwriteDataState: true,
        });
      } catch (err) {
        this.$notify({
          type: 'error',
          title: axios.isAxiosError(err) ? err.message : String(err),
        });
      }
      this.setLoading(false);
    },
    onSortChange({ prop, order }) {
      this.defaultSort = { prop, order };
    },
    getConvertOrder() {
      if (!!this.defaultSort.order) {
        return this.defaultSort.order === 'ascending' ? 'ASC' : 'DESC';
      } else {
        return null;
      }
    },
  },
};
</script>

<style lang="scss" src="./index.scss"></style>
<i18n src="@/locales/doctors.locales.json" />
<i18n src="@/locales/dateAndTime.locales.json" />
