<template>
  <LayoutByUserRole content-class="v-demography-deaths" fix-height>
    <div class="v-demography-deaths__header">
      <ElDatePicker
        class="v-demography-deaths__date"
        v-model="date.value"
        :format="DATE_FORMAT"
        type="daterange"
        unlink-panels
        :value-format="DATE_FORMAT"
        :placeholder="$t('DateAndTime.Date')" />

      <a class="v-disabled-people__btn" :href="exportDataURL" download>
        <ElButton type="primary">
          {{ $t('Base.DownloadExcel') }}
        </ElButton>
      </a>
    </div>

    <DemographyDeathsStatisticsBlock :query="statisticsQuery" />

    <DemographyDeathsTable
      :loading="loading"
      :items="tableItems"
      :total="tableItemsTotal"
      v-model:page="page.value"
      v-model:per-page="perPage.value" />
  </LayoutByUserRole>
</template>

<script lang="ts">
export default {
  name: 'VDemographyDeaths',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { useDatePeriod, usePage, usePerPage } from '@/hooks/query';
import { getMonthPeriod } from '@/utils/dateAndTime.utils';
import { computed, ref, watch } from 'vue';
import { AnalyticsDeadDto } from '@/types/api';
import { DATE_FORMAT } from '@/config/dateAndTime.config';
import { mergeOrCreateQuery } from '@/utils/http.util';
import { deleteEmptyValueKeys } from '@/utils/object.util';
import { I18nService } from '@/services/i18n.service';
import { TokenService } from '@/services/token.service';
import { DeviceService } from '@/services/device.service';
import { Analytics } from '@/models/analytics/analytics.model';
import { compareQueriesThenLoadData } from '@/utils/router.utils';

import LayoutByUserRole from '@/components/layouts/LayoutByUserRole/index.vue';
import DemographyDeathsTable from '@/components/analytics/demography/tables/DemographyDeathsTable/index.vue';
import DemographyDeathsStatisticsBlock from '@/components/analytics/demography/DemographyDeathsStatisticsBlock/index.vue';

const date = useDatePeriod(getMonthPeriod());
const page = usePage();
const perPage = usePerPage();
const loading = ref(false);
const tableItems = ref<AnalyticsDeadDto[]>([]);
const tableItemsTotal = ref(0);

const statisticsQuery = computed(() => ({
  start_at: date.value[0],
  end_at: date.value[1],
}));

const query = computed(() => ({
  page: page.value,
  per_page: perPage.value,
  start_at: date.value[0],
  end_at: date.value[1],
}));

const exportDataURL = computed(() => {
  return mergeOrCreateQuery({
    url: Analytics.exportDemographyDeathsDataURL,
    query: deleteEmptyValueKeys({
      ...query.value,
      lang: I18nService.getLocale(),
      token: TokenService.getAccessToken(),
      device_id: DeviceService.getDeviceId(),
    }),
  });
});

const getAnalyticsDeaths = async () => {
  loading.value = true;
  const response = await Analytics.getAnalyticsDeaths(query.value);

  tableItems.value = response?.data ?? [];
  tableItemsTotal.value = response?.meta.total ?? 0;

  loading.value = false;
};

watch(
  query,
  (value, oldValue) => {
    compareQueriesThenLoadData({
      query: value,
      oldQuery: oldValue,
      getData: getAnalyticsDeaths,
      resetPage: page.reset,
    });
  },
  { deep: true, immediate: true }
);
</script>

<style lang="scss" src="./index.scss" />
