{
  "en": {
    "Notifications": {
      "Error": "Error",
      "FillRequiredFields": "Fill in all required fields",
      "NumberIsBusy": "This number is busy",
      "SuccessAttached": "Successfully linked",
      "SuccessCreated": "Successfully created",
      "SuccessDeleted": "Successfully deleted",
      "SuccessUpdated": "Successfully updated"
    }
  },
  "ru": {
    "Notifications": {
      "Error": "Ошибка",
      "FillRequiredFields": "Заполните все обязательные поля",
      "NumberIsBusy": "Этот номер занят",
      "SuccessAttached": "Успешно привязано",
      "SuccessCreated": "Успешно создано",
      "SuccessDeleted": "Успешно удалено",
      "SuccessUpdated": "Успешно обновлено"
    }
  },
  "uz": {
    "Error": "Xato",
    "FillRequiredFields": "Barcha kerakli maydonlarni to'ldiring",
    "NumberIsBusy": "Ushbu raqam band",
    "SuccessAttached": "Muvaffaqiyatli yaratildi",
    "SuccessCreated": "Muvaffaqiyatli ulandi",
    "SuccessDeleted": "Muvaffaqiyatli ochirildi",
    "SuccessUpdated": "Muvaffaqiyatli yangilandi"
  }
}
