<template>
  <div class="alert-content">
    <p>
      {{ $t('Calculation.AlwaysWorking') }}
    </p>
    <div class="alert-content-icon">
      <UiIcon :icon="icons.WARN" />
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: 'AlertContent',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import * as icons from '@/enums/icons.enum';
</script>

<style scoped lang="scss">
.alert-content {
  display: flex;
  justify-content: space-between;
  gap: 20px;

  p {
    font-size: 16px;
  }

  &-icon {
    display: flex;
    align-items: center;
  }
}
</style>
<i18n src="@/locales/accounting.locales.json" />
