<template>
  <div class="v-app-ins-card">
    <LayoutContentHeader>
      <ElPageHeader
        class="v-app-ins-card-header"
        :title="$t('Base.Back')"
        @back="goToAppointmentDefaultCard">
        <template #content>
          {{ $t('Base.InspectionCard') }}
        </template>
      </ElPageHeader>
    </LayoutContentHeader>

    <PatientCardRow grid :patient="appointment.patient" :items="patientCardItems">
      <template #actions>
        <UiModelsAutocompleteSearch
          class="v-app-ins-card__patient-states"
          :model-value="currentUserStatesIds"
          :model-for-use="Patient"
          :models="currentUserStates"
          method-name="getStates"
          label="title"
          :placeholder="$t('Appointments.SelectPatientCondition')"
          multiple
          @update:modelValue="currentUserStatesIds = $event"
          @select="currentStatesSelectItems = $event" />
      </template>
    </PatientCardRow>

    <InspectionCardComponent
      :appointment="appointment"
      :readonly="readonly"
      :is-provided="isProvided"
      @update:appointment="$emit('update:appointment', $event)"
      @appointment:provide="$emit('appointment:provide', $event)"
      @appointment:set:diagnosis="$emit('appointment:set:diagnosis')" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { APPOINTMENT_ROUTE } from '@/router/appointments.routes';
import { NOT_REDIRECT } from '@/enums/query.enum';
import { Appointment } from '@/models/appointment/Appointment.model';
import { Patient } from '@/models/Patient.model';

import LayoutContentHeader from '@/components/layouts/assets/LayoutContentHeader/index.vue';
import InspectionCardComponent from '@/components/appointments/InspectionCard/index.vue';
import PatientCardRow from '@/components/patients/PatientCardRow/index.vue';
import * as icons from '@/enums/icons.enum.js';
import { User } from '@/models/User.model';

export default {
  name: 'VAppointmentInspectionCard',
  emits: ['update:appointment', 'appointment:provide', 'appointment:set:diagnosis'],
  components: { InspectionCardComponent, LayoutContentHeader, PatientCardRow },
  props: {
    appointment: [Appointment, Object],
    patientCardItems: Array,
  },
  data() {
    return {
      currentUserStatesIds: [],
      currentUserStates: [],
      Patient,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),

    readonly() {
      return (
        (this.appointment.doctor_id !== this.user.doctor_id &&
          this.user.role !== User.enum.roles.Nurse) ||
        this.appointment.status === Appointment.enum.statuses.Canceled ||
        (this.appointment.status === Appointment.enum.statuses.Provided &&
          this.appointment.treatment !== null)
      );
    },
    isProvided() {
      return this.appointment.status === Appointment.enum.statuses.Provided;
    },
    currentStatesSelectItems: {
      get() {
        return this.currentUserStates;
      },
      async set(value) {
        const ids = value.map((item) => item.id);
        await Patient.updateStatesById(this.appointment.patient.id, { user_state_ids: ids });
      },
    },
  },
  watch: {
    'appointment.status': {
      handler() {
        this.redirectToAppointmentDefaultCardIfNeed();
      },
      immediate: true,
    },
  },

  methods: {
    goToAppointmentDefaultCard() {
      this.$router.push({
        name: APPOINTMENT_ROUTE.childrenMap.APPOINTMENT_ROUTE_DEFAULT_CARD.name,
        params: { id: this.appointment.id },
        query: { [NOT_REDIRECT]: 1 },
      });
    },

    redirectToAppointmentDefaultCardIfNeed() {
      if (
        this.appointment.status &&
        ![Appointment.enum.statuses.InProgress, Appointment.enum.statuses.Provided].includes(
          this.appointment.status
        )
      ) {
        this.goToAppointmentDefaultCard();
      }
    },
  },

  async created() {
    if (this.appointment.patient.id) {
      const currentUserStatesResult = await Patient.getStatesById(this.appointment.patient.id);

      if (currentUserStatesResult) {
        this.currentUserStatesIds = currentUserStatesResult.data.map((item) => item.id);
        this.currentUserStates = currentUserStatesResult.data;
      }
    }
  },

  setup: () => ({
    icons,
  }),
};
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/base.locales.json" />
<i18n src="./index.locales.json" />
<i18n src="@/locales/appointments.locales.json" />
