<template>
  <UiTableWithPagination
    :data="data"
    :loading="loading"
    :page="1"
    :per-page="data.length || 1"
    :total="data.length"
    @row-click="goToReception">
    <ElTableColumn prop="start_at" :label="$t('DateAndTime.StartDate')" />
    <ElTableColumn prop="end_at" :label="$t('DateAndTime.EndDate')" />
    <ElTableColumn :label="$t('Base.Status')">
      <template #default="{ row }">
        <AppointmentStatusTag :status="row.status" />
      </template>
    </ElTableColumn>

    <ElTableColumn prop="actions" width="200" :label="$t('Base.Actions')">
      <template #default>
        <div class="reception-table__actions">
          <ElButton type="primary" text>
            <template #icon>
              <UiIcon :icon="icons.EYE" />
            </template>
            {{ $t('Base.Open') }}
          </ElButton>
        </div>
      </template>
    </ElTableColumn>
  </UiTableWithPagination>
</template>

<script>
import * as icons from '@/enums/icons.enum.js';
import { APPOINTMENT_ROUTE } from '@/router/appointments.routes';
import AppointmentStatusTag from '@/components/appointments/AppointmentStatusTag/index.vue';
import UiTableWithPagination from '@/components/ui/UiTableWithPagination/index.vue';

export default {
  name: 'AppointmentsByTreatmentTable',
  components: { UiTableWithPagination, AppointmentStatusTag },
  props: {
    /** @type {Array<TreatmentAppointment>} data */
    data: Array,
    loading: Boolean,
  },
  computed: {},

  methods: {
    goToReception(row) {
      this.$router.push({
        name: APPOINTMENT_ROUTE.name,
        params: {
          id: row.id,
        },
      });
    },
  },

  setup: () => ({ icons }),
};
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/appointments.locales.json" />
<i18n src="@/locales/dateAndTime.locales.json" />
