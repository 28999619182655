<template>
  <UiTableWithPagination
    :data="props.items"
    :loading="props.loading"
    :page="props.page"
    :per-page="props.perPage"
    :total="props.total"
    @update:page="$emit('update:page', $event)"
    @update:per-page="$emit('update:perPage', $event)">
    <ElTableColumn prop="is_planed" width="46px">
      <template #default="{ row }">
        <ElCheckbox
          v-model="row.is_planed"
          :key="row.id"
          @change="onCheckboxClick(row)"
          :disabled="
            !row.nurse_id || (props.capacityFilled && row.is_planed === false) || !row.editable
          " />
      </template>
    </ElTableColumn>
    <ElTableColumn prop="name" :label="$t('Base.Patient')" />
    <ElTableColumn prop="phone" :label="$t('Patronage.PhoneNumber')" />
    <ElTableColumn prop="user_states" :label="$t('Patronage.HealthStatus')" width="250px">
      <template #default="{ row }">
        <UiModelsAutocompleteSearch
          class="patronage-planning-table__search-select"
          :model-value="row.user_state_ids"
          :model-for-use="Patient"
          :models="row.user_states"
          method-name="getStates"
          label="title"
          value="id"
          :placeholder="$t('Base.Choose')"
          multiple
          @update:modelValue="row.user_state_ids = $event"
          @select="handleChangeStates(row)" />
      </template>
    </ElTableColumn>
    <ElTableColumn prop="last_patronage_date" :label="$t('Patronage.DateLastVisit')" />
    <ElTableColumn prop="address" :label="$t('Patronage.Address')" />
    <ElTableColumn prop="nurse" :label="$t('Patronage.Nurse')">
      <template #default="{ row }">
        <UiSelect
          :disabled="(props.capacityFilled && row.is_planed === false) || !row.editable"
          :key="row.id"
          v-model="row.nurse_id"
          :options="props.currentMicrodistrict.nurses"
          value-key="id"
          label-key="name" />
      </template>
    </ElTableColumn>
  </UiTableWithPagination>
</template>

<script lang="ts">
export default {
  name: 'PatronagePlanningTable',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { MicrodistrictDto, UserPatronagePlanningData } from '@/types/api';
import { Patronage } from '@/models/patronage/Patronage.model';
import { Patient } from '@/models/Patient.model';
import { AnyObject } from '@/types/common';

import UiSelect from '@/components/ui/UiSelect/index.vue';
import UiTableWithPagination from '@/components/ui/UiTableWithPagination/index.vue';

const emit = defineEmits([
  'update:page',
  'update:perPage',
  'patronageItem:update',
  'capacity:update',
]);

const props = defineProps<{
  currentMicrodistrict: MicrodistrictDto;
  loading: boolean;
  items: UserPatronagePlanningData[];
  total: number;
  page: number;
  perPage: number;
  capacityFilled: boolean;
  date: string;
}>();

const onCheckboxClick = async (row: UserPatronagePlanningData) => {
  const result = await Patronage.itemUpdate(row.id, {
    microdistrict_id: props.currentMicrodistrict.id,
    date: props.date,
    nurse_id: row.nurse_id,
  });

  if (result) {
    emit('capacity:update', result.data);
    emit('patronageItem:update', result.data.user);
  }
};

const handleChangeStates = async (row: AnyObject) => {
  await Patient.updateStatesById(row.id, { user_state_ids: row.user_state_ids });
};
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/warehouse.locales.json" />
<i18n src="@/locales/notifications.locales.json" />
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/patronage.locales.json" />
<i18n src="@/locales/ui.locales.json" />
<i18n src="@/locales/accounting.locales.json" />
