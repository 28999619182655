import { onlyLoggedOutMiddleware } from '@/middlewares/onlyLoggedOut.middleware';

export const LOGIN_ROUTE = {
  name: 'AUTH_LOGIN',
  path: '/auth/login',
  component: 'VLogin',
  beforeEnter: [onlyLoggedOutMiddleware],
  meta: {
    title: 'Base.Authorization',
  },
};
export const WAITING_CONFIRM_ROUTE = {
  name: 'WAITING_CONFIRM',
  path: '/auth/waiting-confirm',
  component: 'VWaitingConfirm',
  beforeEnter: [onlyLoggedOutMiddleware],
  meta: {
    title: 'Base.WaitingConfirm',
  },
};
export const SIGNUP_ROUTE = {
  name: 'AUTH_SIGNUP',
  path: '/auth/sign-up',
  component: 'VSignUp',
  beforeEnter: [onlyLoggedOutMiddleware],
  meta: {
    title: 'Base.SignUp',
  },
};
export const FORGOT_PASSWORD_ROUTE = {
  name: 'FORGOT_PASSWORD',
  path: '/auth/forgot-password',
  component: 'VForgotPassword',
  beforeEnter: [onlyLoggedOutMiddleware],
  meta: {
    title: 'Base.ForgotPassword',
  },
};

export const routes = [LOGIN_ROUTE, WAITING_CONFIRM_ROUTE, SIGNUP_ROUTE, FORGOT_PASSWORD_ROUTE];
