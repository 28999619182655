<template>
  <div class="doctor-profile-statistic">
    <StatisticSimpleCard
      v-for="(card, index) in simpleCards"
      :key="card.title"
      :title="card.title"
      :loading="card.loading"
      :value="card.value"
      :percentage="card.percentage"
      :chart="card.chart"
      :chart-color="card.chartColor"
      :chart-type="card.chartType"
      :chart-labels="card.chartLabels"
      :value-formatter="card.valueFormatter"
      hide-chart
      :class-modificator="index.toString()" />

    <AppointmentsOnTimeStatisticCard
      class="doctor-profile-statistic__appointment-by-time-card"
      :loading="appointmentsOnTime.loading"
      :value="appointmentsOnTime.data.value"
      :percentage="appointmentsOnTime.data.percentage" />

    <PatientWaitingTimeStatisticCard
      class="doctor-profile-statistic__patient-waiting-time-card"
      :loading="patientAverageWaiting.loading"
      :time-waiting="patientAverageWaiting.data.timeWaiting"
      :time-appointment="patientAverageWaiting.data.timeAppointment" />

    <PatientFeedbackCard
      class="doctor-profile-statistic__patient-feedback-card"
      :loading="patientFeedback.loading"
      :data="patientFeedback.data" />
  </div>
</template>

<script>
import { Appointment } from '@/models/appointment/Appointment.model';
import { Doctor } from '@/models/Doctor.model';
import { Patient } from '@/models/Patient.model';
import { Feedback } from '@/models/feedback/Feedback.model';

import StatisticSimpleCard from '@/components/statistic/StatisticSimpleCard/index.vue';
import AppointmentsOnTimeStatisticCard from '@/components/statistic/AppointmentsOnTimeStatisticCard/index.vue';
import PatientFeedbackCard from '@/components/statistic/PatientFeedbackCard/index.vue';
import PatientWaitingTimeStatisticCard from '@/components/statistic/PatientWaitingTimeStatisticCard/index.vue';

export default {
  name: 'DoctorProfileStatistic',
  components: {
    StatisticSimpleCard,
    AppointmentsOnTimeStatisticCard,
    PatientFeedbackCard,
    PatientWaitingTimeStatisticCard,
  },
  props: {
    doctorId: [Number, String],
    /** @type {Array<string>} period - Array<DATE_FORMAT> */
    period: Array,
  },
  data() {
    return {
      /** @type {User} */
      doctor: {},
      loading: { doctor: false },

      appointmentsCount: {
        loading: false,
        data: {
          value: null,
          percent_change: null,
          chart: null,
        },
      },
      providedServices: {
        loading: false,
        data: {
          value: null,
          percent_change: null,
          chart: null,
        },
      },
      servicesPrice: {
        loading: false,
        data: {
          value: null,
          percent_change: null,
          chart: null,
        },
      },
      completedAppointments: {
        loading: false,
        data: {
          value: null,
          percent_change: null,
          chart: null,
        },
      },
      averageAppointmentTime: {
        loading: false,
        data: {
          value: null,
          percent_change: null,
          chart: null,
        },
      },
      repeatPatients: {
        loading: false,
        data: {
          value: null,
          percent_change: null,
          chart: null,
        },
      },

      appointmentsOnTime: {
        loading: false,
        data: {
          value: null,
          percentage: null,
        },
      },
      patientAverageWaiting: {
        loading: false,
        data: {
          timeWaiting: null,
          timeAppointment: null,
        },
      },
      doctorsTop: {
        loading: false,
        data: null,
      },
      patientFeedback: {
        loading: false,
        data: null,
      },
    };
  },
  computed: {
    simpleCards() {
      return [
        {
          title: this.$t('Statistic.NumberOfAppointments'),
          loading: this.appointmentsCount.loading,
          value: this.appointmentsCount.data.value,
          percentage: this.appointmentsCount.data.percentage,
          chart: this.appointmentsCount.data.chart,
          chartColor: '#3879F8',
        },
        {
          title: this.$t('Statistic.CompletedAppointments'),
          loading: this.completedAppointments.loading,
          value: this.completedAppointments.data.value,
          percentage: this.completedAppointments.data.percentage,
          chart: this.completedAppointments.data.chart,
          chartType: 'pie',
          chartColor: ['#3879F8', '#C8DBFF'],
          chartLabels: [this.$t(`Statistic.Completed`), this.$t(`Statistic.Incomplete`)],
          valueFormatter: (value) => (value ? `${value}%` : '0%'),
        },
        {
          title: this.$t('Statistic.AverageAppointmentTime'),
          loading: this.averageAppointmentTime.loading,
          value: this.averageAppointmentTime.data.value,
          percentage: this.averageAppointmentTime.data.percentage,
          chart: this.averageAppointmentTime.data.chart,
          chartColor: '#5090FF',
          valueFormatter: (value) => {
            if (typeof value === 'number') {
              const hours = Math.floor(value / 60);
              const minutes = Math.ceil(value % 60);
              return `${hours > 0 ? hours : ''}${
                hours > 0 ? this.$t(`Statistic.Hour`) : ''
              } ${minutes}${this.$t(`Statistic.Minute`)}`;
            }

            return `0${this.$t(`Statistic.Minute`)}`;
          },
        },
        {
          title: this.$t('Invoices.RepeatPatients'),
          loading: this.repeatPatients.loading,
          value: this.repeatPatients.data.value,
          percentage: this.repeatPatients.data.percentage,
          chart: this.repeatPatients.data.chart,
          chartColor: '#3879F8',
          valueFormatter: (value) => (value ? `${value}%` : '0%'),
        },
      ];
    },
  },
  watch: {
    period: {
      async handler() {
        this.getAppointmentsCount();
        this.getAppointmentsOnTime();
        this.getCompletedAppointments();
        this.getProvidedServices();
        this.getServicesPrice();
        this.getRepeatPatients();
        this.getAverageAppointmentTime();
        this.getPatientAverageWaiting();
        this.getDoctorsTop();
        this.getPatientFeedback();
      },
      immediate: true,
    },
  },
  methods: {
    async getAppointmentsCount() {
      this.appointmentsCount.loading = true;

      const { data } = await Appointment.getCountStatistic({
        startAt: this.period[0],
        endAt: this.period[1],
        doctorId: this.doctorId,
      });

      this.appointmentsCount.data.value = data.value;
      this.appointmentsCount.data.percentage = data.percent_change;
      this.appointmentsCount.data.chart = data.chart;

      this.appointmentsCount.loading = false;
    },

    async getAppointmentsOnTime() {
      this.appointmentsOnTime.loading = true;

      const { data } = await Appointment.getOnTimeStatistic({
        startAt: this.period[0],
        endAt: this.period[1],
        doctorId: this.doctorId,
      });

      this.appointmentsOnTime.data.value = data.value;
      this.appointmentsOnTime.data.percentage = data.percent_change;

      this.appointmentsOnTime.loading = false;
    },

    async getCompletedAppointments() {
      this.completedAppointments.loading = true;

      const { data } = await Appointment.getSuccessfulStatistic({
        startAt: this.period[0],
        endAt: this.period[1],
        doctorId: this.doctorId,
      });

      this.completedAppointments.data.value = data.value;
      this.completedAppointments.data.percentage = data.percent_change;

      this.completedAppointments.loading = false;
    },

    async getProvidedServices() {
      this.providedServices.loading = true;

      const { data } = await Doctor.getServicesCount({
        startAt: this.period[0],
        endAt: this.period[1],
        doctorId: this.doctorId,
      });

      this.providedServices.data.value = data.value;
      this.providedServices.data.percentage = data.percent_change;
      this.providedServices.data.chart = data.chart;

      this.providedServices.loading = false;
    },

    async getServicesPrice() {
      this.servicesPrice.loading = true;

      const { data } = await Doctor.getServicesPrice({
        startAt: this.period[0],
        endAt: this.period[1],
        doctorId: this.doctorId,
      });

      this.servicesPrice.data.value = data.value;
      this.servicesPrice.data.percentage = data.percent_change;

      this.servicesPrice.loading = false;
    },

    async getRepeatPatients() {
      this.repeatPatients.loading = true;

      const { data } = await Patient.getRepeatStatisticByPeriod({
        startAt: this.period[0],
        endAt: this.period[1],
        doctorId: this.doctorId,
      });
      this.repeatPatients.data.value = data.value;
      this.repeatPatients.data.percentage = data.percent_change;
      this.repeatPatients.data.chart = data.chart;

      this.repeatPatients.loading = false;
    },

    async getAverageAppointmentTime() {
      this.averageAppointmentTime.loading = true;

      const { data } = await Doctor.getAverageAppointmentTime({
        startAt: this.period[0],
        endAt: this.period[1],
        doctorId: this.doctorId,
      });
      this.averageAppointmentTime.data.value = data.value;
      this.averageAppointmentTime.data.percentage = data.percent_change;
      this.averageAppointmentTime.data.chart = data.chart;

      this.averageAppointmentTime.loading = false;
    },

    async getPatientAverageWaiting() {
      this.patientAverageWaiting.loading = true;

      const { data } = await Doctor.getAverageAppointmentDownTime({
        startAt: this.period[0],
        endAt: this.period[1],
        doctorId: this.doctorId,
      });
      this.patientAverageWaiting.data.timeWaiting = data.time_waiting;
      this.patientAverageWaiting.data.timeAppointment = data.time_appointment;

      this.patientAverageWaiting.loading = false;
    },

    async getDoctorsTop() {
      this.doctorsTop.loading = true;

      const { data } = await Doctor.getDoctorsTop({
        startAt: this.period[0],
        endAt: this.period[1],
        doctorId: this.doctorId,
      });
      this.doctorsTop.data = data;

      this.doctorsTop.loading = false;
    },

    async getPatientFeedback() {
      this.patientFeedback.loading = true;

      const { data } = await Feedback.getItems({
        startAt: this.period[0],
        endAt: this.period[1],
        doctor_id: this.doctorId,
      });

      if (data?.length) {
        this.patientFeedback.data = data;
      }

      this.patientFeedback.loading = false;
    },
  },
};
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/dateAndTime.locales.json" />
<i18n src="@/locales/appointments.locales.json" />
<i18n src="@/locales/invoices.locales.json" />
<i18n src="@/locales/statistic.locales.json" />
