<template>
  <div class="waiting-confirm-block">
    <img src="@/assets/images/logo.svg" />
    <div class="waiting-confirm-block__title">
      {{ isBlocked ? $t('BlockedTitle') : $t('WaitingConfirmTitle') }}
    </div>
    <div class="waiting-confirm-block__content">
      {{ isBlocked ? $t('Blocked') : $t('WaitingConfirm') }}
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapState, mapActions } from 'vuex';
import { CHECK_DEVICE_INTERVAL } from '@/config/ui.config';
import { Device } from '@/models/Device.model';

export default {
  name: 'WaitingConfirm',
  setup: () => ({
    interval: null,
  }),
  data() {
    return {
      isBlocked: false,
    };
  },
  computed: {
    ...mapState({
      userCredentials: (state) => state.auth.userCredentials,
    }),
  },
  methods: {
    ...mapActions({
      logIn: 'auth/logIn',
    }),

    async checkDeviceStatus() {
      try {
        const { data } = await Device.checkResult();

        if (data) {
          this.isBlocked = data.is_blocked;
        }
        if (data?.is_activated) {
          await this.logIn(this.userCredentials);
          this.$router.push('/');
        }
      } catch (err) {
        this.$notify({
          type: 'error',
          title: axios.isAxiosError(err) ? err.response.data.message : String(err),
        });
      }
    },
  },
  mounted() {
    this.checkDeviceStatus();
    this.interval = setInterval(() => {
      this.checkDeviceStatus();
    }, CHECK_DEVICE_INTERVAL);
  },
  unmounted() {
    clearInterval(this.interval);
  },
};
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/base.locales.json" />
<i18n src="./index.locales.json" />
