<template>
  <LayoutByUserRole>
    <LayoutContentHeader>
      <ElDatePicker
        class="v-services-content-header__date"
        v-model="date.value"
        type="daterange"
        unlink-panels
        :format="DATE_FORMAT"
        :value-format="DATE_FORMAT"
        :start-placeholder="$t('DateAndTime.StartDate')"
        :end-placeholder="$t('DateAndTime.EndDate')" />

      <UiModelsAutocompleteSearch
        class="v-services-content-header__category-select"
        :model-value="serviceCategory.value.id"
        :model-for-use="ServiceCategory"
        label="title"
        :default-item="serviceCategory.value"
        :placeholder="$t('Base.SelectServiceСategory')"
        clearable
        @select="serviceCategory.value = $event" />

      <form
        @submit.prevent="throttleSearch"
        class="v-services-content-header__search-by-service-name">
        <ElInput v-model="search.value" :placeholder="$t('Services.EnterServiceName')">
          <template #prefix>
            <UiIcon :icon="icons.SEARCH" />
          </template>
        </ElInput>
      </form>

      <template #actions>
        <ElButton type="primary" @click="createService">
          {{ $t('Base.Create') }}
        </ElButton>
      </template>
    </LayoutContentHeader>

    <ServicesTable
      :items="items"
      :loading="loading"
      :total="total"
      :default-sort="defaultSort"
      @sort-change="onSortChange"
      v-model:page="page.value"
      v-model:per-page="perPage.value"
      @item:update="editItem"
      @item:delete="deleteItem"
      @table:reload="getServices" />
  </LayoutByUserRole>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import * as icons from '@/enums/icons.enum.js';
import throttle from 'lodash.throttle';
import { useDatePeriod, usePage, usePerPage } from '@/hooks/query';
import { useGetDataByInterval } from '@/hooks/useGetDataByInterval.hook';
import { useModel } from '@/hooks/useModel';
import { useQuery } from '@/hooks/useQuery.hook';
import { compareQueriesThenLoadData } from '@/utils/router.utils';
import { ServiceCategory } from '@/models/ServiceCategory.model';
import { ServiceGroup } from '@/models/ServiceGroup.model';
import { GlobalModalCloseAction } from '@/models/client/ModalAndDrawer/GlobalModalCloseAction';
import noop from 'lodash.noop';
import { getMonthPeriod } from '@/utils/dateAndTime.utils';
import { DATE_FORMAT } from '@/config/dateAndTime.config';

import LayoutContentHeader from '@/components/layouts/assets/LayoutContentHeader/index.vue';
import ServicesTable from '@/components/services/ServicesTable/index.vue';
import LayoutByUserRole from '@/components/layouts/LayoutByUserRole/index.vue';
import ServiceCrudModal from '@/components/accounting/modals/ServiceCrudModal/index.vue';

export default {
  name: 'VServices',
  data() {
    return {
      defaultSort: {},
    };
  },
  components: { LayoutContentHeader, LayoutByUserRole, ServicesTable },
  setup: () => ({
    perPage: usePerPage(),
    page: usePage(),
    search: useQuery({ field: 'service' }),
    date: useDatePeriod(getMonthPeriod()),
    startAt: useQuery({ field: 'start_at' }),
    endAt: useQuery({ field: 'end_at' }),
    serviceCategory: useModel({
      fieldNames: {
        id: 'category_id',
        name: 'category_name',
      },
    }),
    DATE_FORMAT,
    throttleSearch: noop,
    throttleSearchByInterval: noop,
    icons,
    ServiceCategory,
  }),
  computed: {
    ...mapState({
      items: (state) => state.serviceGroups.data,
      total: (state) => state.serviceGroups.total,
      loading: (state) => state.serviceGroups.loading,
    }),

    queryWatchers() {
      return {
        page: this.page.value,
        per_page: this.perPage.value,
        search: this.search.value,
        query_field: ['title'],
        start_at: this.date.value[0],
        end_at: this.date.value[1],
        category_group_service_id: this.serviceCategory.value.id,
        sales_count_order: this.defaultSort.prop === 'sold_count' ? this.getConvertOrder() : null,
        sales_sum_order: this.defaultSort.prop === 'sold_amount' ? this.getConvertOrder() : null,
      };
    },
  },

  watch: {
    queryWatchers: {
      handler(value, oldValue) {
        compareQueriesThenLoadData({
          query: value,
          oldQuery: oldValue,
          resetPage: this.page.reset,
          getData: this.throttleSearchByInterval,
          fieldsForResetPage: 'service',
        });
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions({
      setLoading: 'serviceGroups/setLoading',
      setData: 'serviceGroups/setData',
      createItem: 'serviceGroups/createItem',
      editItem: 'serviceGroups/editItem',
      deleteItem: 'serviceGroups/deleteItem',
    }),
    onSortChange({ prop, order }) {
      this.defaultSort = { prop, order };
    },

    getConvertOrder() {
      if (!!this.defaultSort.order) {
        return this.defaultSort.order === 'ascending' ? 'ASC' : 'DESC';
      } else {
        return null;
      }
    },

    async getServices() {
      this.setLoading(true);

      const { data } = await ServiceGroup.find(this.queryWatchers);
      this.setData({
        items: data.data,
        total: data.meta.total,
        overwriteDataState: true,
      });

      this.setLoading(false);
    },

    async createService() {
      const action = await this.$store.dispatch('modalAndDrawer/openModal', ServiceCrudModal);

      if (!(action instanceof GlobalModalCloseAction)) this.getServices();
    },
  },

  mounted() {
    this.throttleSearch = throttle(this.getServices, 600);
    this.throttleSearchByInterval = useGetDataByInterval(this.throttleSearch);
    this.throttleSearchByInterval();
  },
};
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/accounting.locales.json" />
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/dateAndTime.locales.json" />
