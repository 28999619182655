import axios from 'axios';
import * as icons from '@/enums/icons.enum';
import { mapState } from 'vuex';
import { APPOINTMENT_ROUTE } from '@/router/appointments.routes';
import { PAGE_SIZES } from '@/config/ui.config';
import { Appointment } from '@/models/appointment/Appointment.model';
import { User } from '@/models/User.model';

import StatusTag from '@/components/common/StatusTag/index.vue';
import InvoiceStatusTag from '@/components/invoices/InvoiceStatusTag/index.vue';
import CreateOrEditAppointmentModal from '@/components/appointments/CreateOrEditAppointmentModal/index.vue';
import AppointmentStatusTag from '@/components/appointments/AppointmentStatusTag/index.vue';
import UiTableWithPagination from '@/components/ui/UiTableWithPagination/index.vue';

export default {
  name: 'AppointmentsTable',
  components: { StatusTag, UiTableWithPagination, AppointmentStatusTag, InvoiceStatusTag },
  emits: ['update:perPage', 'update:page', 'item:edit', 'queue:print'],
  props: {
    /** @type { Array<Appointment|object> } items */
    items: Array,
    loading: Boolean,
    page: Number,
    perPage: Number,
    total: Number,

    /** @typedef {"patient"|"patient.phone"|"doctor"|"start_at"|"end_at"|"payment"|"status"|"services"|"created_at"|"actions"} AppointmentsTableColumnKey */
    /** @type {Array<AppointmentsTableColumnKey>} excludeColumns */
    excludeColumns: Array,
  },

  data() {
    return {
      mapStatus: {
        average: 'warning',
        normal: 'success',
        serious: 'danger',
      },
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),

    isManager() {
      return this.user.role === User.enum.roles.Manager;
    },
    isDoctor() {
      return this.user.role === User.enum.roles.Doctor;
    },

    pageCount() {
      return Math.ceil(this.total / this.perPage);
    },
    pageSizes() {
      return PAGE_SIZES;
    },

    itemsWithPayload() {
      return this.items.map((elem) => ({
        ...elem,
        _start_at: elem.start_at?.split(' ')[1],
        _end_at: elem.end_at?.split(' ')[1],
      }));
    },

    columnsOptions() {
      return {
        'patient': {
          isShow: !this.excludeColumns?.includes('patient'),
        },
        'patient.phone': {
          isShow: !this.excludeColumns?.includes('patient.phone'),
        },
        'doctor': {
          isShow: !this.excludeColumns?.includes('doctor'),
        },
        'start_at': {
          isShow: !this.excludeColumns?.includes('start_at'),
        },
        'end_at': {
          isShow: !this.excludeColumns?.includes('end_at'),
        },
        'payment': {
          isShow: !this.excludeColumns?.includes('payment'),
        },
        'status': {
          isShow: !this.excludeColumns?.includes('status'),
        },
        'services': {
          isShow: !this.excludeColumns?.includes('services'),
        },
        'created_at': {
          isShow: !this.excludeColumns?.includes('created_at'),
        },
        'actions': {
          isShow: !this.excludeColumns?.includes('actions'),
        },
        'actions-eye': {
          isShow: !this.excludeColumns?.includes('actions-eye'),
        },
      };
    },
  },
  methods: {
    getTableRowClassName(row) {
      return `appointment_${row.row.status}`;
    },

    goToAppointment(payload) {
      this.$router.push({
        name: APPOINTMENT_ROUTE.name,
        params: { id: payload.id },
      });
    },
    addAppointment() {
      this.$store.dispatch('modalAndDrawer/openModal', CreateOrEditAppointmentModal);
    },

    async updateAppointmentStatus(id, status) {
      try {
        const { data } = await Appointment.updateStatus({
          id: id,
          status: status,
        });
        this.$emit('item:edit', data.data);

        if (this.isDoctor && status === Appointment.enum.statuses.Waiting)
          this.$router.push({
            name: APPOINTMENT_ROUTE.name,
            params: { id: id },
          });

        if (
          this.isDoctor &&
          [Appointment.enum.statuses.Provided, Appointment.enum.statuses.Canceled].includes(status)
        )
          this.$store.dispatch('user/closeActiveAppointment');
      } catch (err) {
        this.$notify({
          type: 'error',
          title: axios.isAxiosError(err) ? err.message : String(err),
        });
      }
    },
  },
  setup: () => ({
    Appointment,
    icons: icons,
  }),
};
