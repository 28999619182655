<template>
  <UiTableWithPagination
    :data="props.items"
    :loading="props.loading"
    :page="props.page"
    :per-page="props.perPage"
    :total="props.total"
    @update:page="$emit('update:page', $event)"
    @update:per-page="$emit('update:perPage', $event)">
    <ElTableColumn prop="pregnancy_number" :label="$t('Patients.Pregnancy')" />
    <ElTableColumn prop="first_day_menstruation" :label="$t('Patients.StartDFM')" />
    <ElTableColumn prop="count_weeks" :label="$t('Patients.Weeks')" />
    <ElTableColumn prop="planned_date_birth" :label="$t('Patients.EDB')" />
    <ElTableColumn prop="status" :label="$t('Income.Status')">
      <template #default="{ row }">
        <StatusTag
          :map-status="mapStatus"
          :title="row.status ? $t(`Patients.Statuses.${row.status}`) : ''"
          :value="row.status" />
      </template>
    </ElTableColumn>
    <ElTableColumn prop="actions" :label="$t('Base.Actions')" width="150px">
      <template #default="{ row }">
        <div class="table-actions">
          <ElPopconfirm
            :title="$t('Ui.PopConfirm.DefaultTitle')"
            @confirm="$emit('approvePregnancies', row.id)">
            <template #reference>
              <ElButton
                size="small"
                type="danger"
                plain
                :loading="row.loading?.cancel"
                :disabled="!!row.closed_at">
                {{ $t('Patients.Deregister') }}
              </ElButton>
            </template>
          </ElPopconfirm>
        </div>
      </template>
    </ElTableColumn>
  </UiTableWithPagination>
</template>
<script lang="ts">
export default {
  name: 'PregnanciesTable',
  inheritAttrs: false,
  customOptions: {},
};
</script>
<script setup lang="ts">
import { PregnancyDto } from '@/types/api';
import { MapStatusType } from '@/components/common/StatusTag/types';
import { PregnancyStatus } from '@/models/pregnancy/Pregnancy.model';

import UiTableWithPagination from '@/components/ui/UiTableWithPagination/index.vue';
import StatusTag from '@/components/common/StatusTag/index.vue';

defineEmits(['update:page', 'update:perPage', 'approvePregnancies']);

const props = defineProps<{
  loading: boolean;
  items: PregnancyDto[];
  total: number;
  page: number;
  perPage: number;
}>();

const mapStatus: MapStatusType<PregnancyStatus> = {
  created: 'warning',
  gave_birth: 'success',
  abort: 'danger',
};
</script>

<style scoped lang="scss">
.table-actions {
}
</style>
<i18n src="@/locales/patients.locales.json" />
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/warehouse.locales.json" />
<i18n src="@/locales/ui.locales.json" />
