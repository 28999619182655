<template>
  <UiTableWithPagination
    :max-height="400"
    table-class="service-products-table"
    hide-pagination
    :data="props.items">
    <ElTableColumn prop="title" :label="$t('Nomenclature.NameOfProduct')" />
    <ElTableColumn prop="price" :label="$t('Base.Price')" />
    <ElTableColumn prop="count" :label="$t('Base.Quantity')" />
    <ElTableColumn prop="total_amount" :label="$t('Base.SumCost')" />

    <ElTableColumn prop="actions">
      <template #default="{ row }">
        <div class="service-products-table-actions">
          <ElButton
            class="service-products-table-actions__delete-button"
            type="primary"
            text
            @click.stop="$emit('table:delete', row.id)">
            <template #icon>
              <UiIcon :icon="icons.CLOSE" />
            </template>
          </ElButton>
        </div>
      </template>
    </ElTableColumn>
  </UiTableWithPagination>
</template>

<script lang="ts">
export default {
  name: 'ServiceProductsTable',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { ExpenseProduct } from '@/types/api';

import * as icons from '@/enums/icons.enum.js';

defineEmits(['table:delete']);

const props = defineProps<{
  items: ExpenseProduct[];
}>();
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/warehouse.locales.json" />
