<template>
  <ElFormItem
    :class="[
      'form-label',
      {
        'form-label_in-column': props.inColumn,
      },
    ]"
    v-bind="$attrs"
    :label="props.label"
    :required="props.required">
    <slot />
  </ElFormItem>
</template>

<script lang="ts">
export default {
  name: 'FormLabel',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    inColumn?: boolean;
    required?: boolean;
    label: string;
  }>(),
  {
    inColumn: true,
    required: false,
  }
);
</script>

<style lang="scss" src="./index.scss" />
