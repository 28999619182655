import { markRaw } from 'vue';
import type { Component } from 'vue';
import { EmitterService } from '@/services/emitter.service';
import {
  GLOBAL_MODAL_ACTION,
  GLOBAL_MODAL_CLOSE,
  GLOBAL_DRAWER_ACTION,
  GLOBAL_DRAWER_CLOSE,
} from '@/enums/modalAndDrawer.enum';
import { GlobalModalCloseAction } from '@/models/client/ModalAndDrawer/GlobalModalCloseAction';
import { GlobalDrawerCloseAction } from '@/models/client/ModalAndDrawer/GlobalDrawerCloseAction';
import { ActionContext, Store as VuexStore, CommitOptions, DispatchOptions } from 'vuex';
import { RootStateType } from '@/store';
import { AnyObject } from '@/types/common';
import { noop } from 'lodash';
import { AuthState } from '@/store/auth/auth.store';

type PromiseCallback = {
  resolve: (value: unknown) => void | unknown;
  reject: (value: unknown) => void | unknown;
};

const promises: Record<ComponentType, PromiseCallback> = {
  modal: {
    resolve: noop,
    reject: noop,
  },
  drawer: {
    resolve: noop,
    reject: noop,
  },
};
setEventSubscribers();

type PayloadType = {
  component: Component;
  payload: AnyObject;
  isOpen?: boolean | null;
};

type ComponentType = 'modal' | 'drawer';

export type ModalAndDrawerState = Record<ComponentType, PayloadType>;

type ContextType = ActionContext<ModalAndDrawerState, RootStateType>;

type OptionsOrComponentType = Component & {
  component: Component;
};

type PayloadOpenComponentType = {
  type: ComponentType;
  optionsOrComponent: OptionsOrComponentType;
  payload?: Component;
};

type Mutations = {
  ['modalAndDrawer/OPEN'](
    state: ModalAndDrawerState,
    { type, component, payload }: PayloadType & { type: ComponentType }
  ): void;
  ['modalAndDrawer/CLOSE'](
    state: ModalAndDrawerState,
    { type }: PayloadType & { type: ComponentType }
  ): void;
};

type ActionAugments = Omit<ActionContext<ModalAndDrawerState, RootStateType>, 'commit'> & {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
};

export type Actions = {
  ['modalAndDrawer/openModal'](
    context: ActionAugments,
    optionsOrComponent: PayloadOpenComponentType
  ): Promise<void>;
  ['modalAndDrawer/openDrawer'](
    context: ActionAugments,
    optionsOrComponent: PayloadOpenComponentType
  ): void;
  ['modalAndDrawer/closeModal'](context: ActionAugments): void;
  ['modalAndDrawer/closeDrawer'](context: ActionAugments): void;
  ['modalAndDrawer/forceCloseModal'](context: ActionAugments): void;
  ['modalAndDrawer/forceCloseDrawer'](context: ActionAugments): void;
};

export default {
  name: 'modalAndDrawer',
  namespaced: true,
  state: (): ModalAndDrawerState => {
    return {
      modal: {
        component: {},
        payload: {},
        isOpen: null,
      },
      drawer: {
        component: {},
        payload: {},
        isOpen: null,
      },
    };
  },
  mutations: {
    OPEN(
      state: ModalAndDrawerState,
      { type, component, payload = {} }: PayloadType & { type: ComponentType }
    ) {
      state[type].payload = payload;
      state[type].component = markRaw(component);
      state[type].isOpen = true;
    },
    CLOSE(state: ModalAndDrawerState, { type }: PayloadType & { type: ComponentType }) {
      state[type].component = {};
      state[type].payload = {};
      state[type].isOpen = false;
    },
  },
  actions: {
    async _open({ commit }: ContextType, { type, optionsOrComponent }: PayloadOpenComponentType) {
      const payload = optionsOrComponent?.component
        ? optionsOrComponent
        : { component: optionsOrComponent };

      commit('OPEN', { type: type, ...payload });

      return new Promise((resolve, reject) => {
        promises[type].resolve = resolve;
        promises[type].reject = reject;
      });
    },
    async openModal(
      { dispatch }: ContextType,
      optionsOrComponent: PayloadOpenComponentType
    ): Promise<void> {
      return dispatch('_open', { type: 'modal', optionsOrComponent });
    },
    async openDrawer({ dispatch }: ContextType, optionsOrComponent: PayloadOpenComponentType) {
      return dispatch('_open', { type: 'drawer', optionsOrComponent });
    },
    closeModal({ commit }: ContextType) {
      commit('CLOSE', { type: 'modal' });
    },
    closeDrawer({ commit }: ContextType) {
      commit('CLOSE', { type: 'drawer' });
    },
    forceCloseModal({ state, dispatch }: ContextType) {
      if (!state.modal.isOpen) return;
      EmitterService.emit(GLOBAL_MODAL_CLOSE, new GlobalModalCloseAction());
      dispatch('closeModal');
    },
    forceCloseDrawer({ state, dispatch }: ContextType): void {
      if (!state.drawer.isOpen) return;
      EmitterService.emit(GLOBAL_DRAWER_CLOSE, new GlobalDrawerCloseAction());
      dispatch('closeDrawer');
    },
  },
};

function setEventSubscribers() {
  EmitterService.on(GLOBAL_MODAL_ACTION, (payload) => {
    if (promises) {
      return promises.modal.resolve(payload);
    }
  });
  EmitterService.on(GLOBAL_MODAL_CLOSE, (payload) => promises.modal.resolve(payload));
  EmitterService.on(GLOBAL_DRAWER_ACTION, (payload) => promises.drawer.resolve(payload));
  EmitterService.on(GLOBAL_DRAWER_CLOSE, (payload) => promises.drawer.resolve(payload));
}

// Типизация стора чтобы в файлах .vue работала типизация модуля
// TODO добавить такой же тип для StoreCRUDModule
export type ModalAndDrawerStore<S = AuthState> = Omit<
  VuexStore<S>,
  'getters' | 'commit' | 'dispatch'
> & {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
    key: K,
    payload: P,
    options?: CommitOptions
  ): ReturnType<Mutations[K]>;
} & {
  dispatch<K extends keyof Actions>(
    key: K,
    payload?: Parameters<Actions[K]>[1],
    options?: DispatchOptions
  ): ReturnType<Actions[K]>;
};
