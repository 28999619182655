<template>
  <UiTableWithPagination
    :loading="loading"
    :data="itemsWithPayload"
    :page="page"
    :per-page="perPage"
    :total="total"
    @update:page="$emit('update:page', $event)"
    @update:per-page="$emit('update:perPage', $event)"
    @row-click="goToOrder">
    <ElTableColumn prop="_id" label="ID" />

    <ElTableColumn prop="user" :label="$t('Base.Patient')" min-width="100px">
      <template #default="{ row }">
        <UiUserAvatarInfo :user="row.user" />
      </template>
    </ElTableColumn>

    <ElTableColumn
      prop="_biomaterialString"
      :label="$t('Laboratory.Biomaterial.Biomaterial')"
      min-width="150px" />

    <ElTableColumn prop="status" :label="$t('Base.Status')" min-width="120px">
      <template #default="{ row }">
        <OrderStatusTag :status="row.status" />
      </template>
    </ElTableColumn>

    <ElTableColumn prop="_createdDate" :label="$t('DateAndTime.CreatedAt')" />

    <ElTableColumn prop="actions" :label="$t('Base.Actions')" min-width="120px">
      <ElButton type="primary" text>
        <template #icon>
          <UiIcon :icon="icons.EYE" />
        </template>
        {{ $t('Base.Open') }}
      </ElButton>
    </ElTableColumn>
  </UiTableWithPagination>
</template>

<script>
import * as icons from '@/enums/icons.enum';
import { LABORATORY_ORDER_ROUTE } from '@/router/laboratory.routes';
import { excludeTime } from '@/utils/dateAndTime.utils';

import OrderStatusTag from '@/components/laboratory/OrderStatusTag/index.vue';
import UiTableWithPagination from '@/components/ui/UiTableWithPagination/index.vue';

export default {
  name: 'OrdersTable',
  components: { UiTableWithPagination, OrderStatusTag },
  props: {
    /** @type {Array<Invoice|object>} items */
    items: Array,
    loading: Boolean,
    page: Number,
    perPage: Number,
    total: Number,
  },
  computed: {
    itemsWithPayload() {
      return this.items.map((elem) => ({
        ...elem,

        _id: `#${elem.id}`,
        _biomaterialString: elem.biomaterials
          .map((biomaterial) => biomaterial.biomaterial_type_translate)
          .join(', '),
        _createdDate: excludeTime(elem.created_at),
      }));
    },
  },

  methods: {
    goToOrder(order) {
      this.$router.push({
        name: LABORATORY_ORDER_ROUTE.name,
        params: {
          id: order.id,
        },
      });
    },
  },

  setup: () => ({ icons }),
};
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/dateAndTime.locales.json" />
<i18n src="@/locales/laboratory.locales.json" />
