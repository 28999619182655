<template>
  <UiTableWithPagination
    :data="data"
    :total="total"
    :loading="loading"
    :page="page"
    :per-page="perPage"
    @update:page="$emit('update:page', $event)"
    @update:per-page="$emit('update:per-page', $event)"
    @row-click="goToHospital">
    <ElTableColumn
      v-if="columns?.user ?? true"
      column-key="user"
      prop="user"
      :label="$t('Patients.Patient')"
      min-width="200px">
      <template #default="{ row }">
        <UiUserAvatarInfo :user="row.user" />
      </template>
    </ElTableColumn>

    <ElTableColumn
      v-if="columns?.department ?? true"
      column-key="department"
      prop="chamber.department.title"
      :label="$t('Hospital.Department')" />
    <ElTableColumn prop="chamber.number" :label="$t('Hospital.Chamber')" />
    <ElTableColumn prop="created_at" :label="$t('Hospital.ArrivalDate')" />
    <ElTableColumn prop="discharged_at" :label="$t('Hospital.DischargedDate')" />

    <ElTableColumn
      v-if="columns?.status ?? true"
      column-key="status"
      prop="status"
      :label="$t('Base.Status')">
      <template #default="{ row }">
        <HospitalStatusTag :status="row.status" />
      </template>
    </ElTableColumn>

    <ElTableColumn :label="$t('Base.Open')">
      <ElButton type="primary" text>
        <template #icon>
          <UiIcon :icon="icons.EYE" />
        </template>
        {{ $t('Base.Open') }}
      </ElButton>
    </ElTableColumn>
  </UiTableWithPagination>
</template>

<script src="./index.js"></script>

<style lang="scss" src="./index.scss"></style>
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/patients.locales.json" />
<i18n src="@/locales/hospital.locales.json" />
