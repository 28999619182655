<template>
  <PrinterDocument
    class="queue-print"
    :content-blocks="contentBlocks"
    hide-title
    hide-clinic-name
    hide-address
    hide-watermark
    ref="document">
    <template #header>
      <div class="queue-print__header">
        <img class="queue-print__header-logo" :src="user?.clinic?.logo?.link" alt="clinic logo" />
        <div class="queue-print__header-inner">
          <div>{{ appointment.patient?.humId }}</div>
          <div>Ваш номер в очереди</div>
        </div>
      </div>
    </template>

    <template #append-content v-if="ticket.qrPayload">
      <QrCode :payload="{ data: ticket.qrPayload }" />
      <div class="queue-print__company-title">{{ $t('Base.DownloadApp') }}</div>
      <img
        class="queue-print__company-logo"
        src="@/assets/images/invoice-check-logo.svg"
        alt="logo" />
    </template>
  </PrinterDocument>
</template>

<script>
import { mapState } from 'vuex';
import { Appointment } from '@/models/appointment/Appointment.model';
import { Patient } from '@/models/Patient.model';
import { getApiErrorMessage } from '@/utils/http.util';

import PrinterDocument from '@/components/printer/PrinterDocument/index.vue';
import QrCode from '@/components/QrCode/index.vue';

export default {
  name: 'QueuePrint',
  components: { PrinterDocument, QrCode },
  data: () => ({
    appointment: { ...new Appointment() },
    ticket: {
      qrPayload: null,
    },
  }),
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),

    contentBlocks() {
      return [
        {
          label: this.$t('Base.Clinic'),
          value: this.user.clinic?.title,
        },
        {
          label: this.$t('Base.Patient'),
          value: this.appointment.patient?.name,
        },
        {
          label: this.$t('DateAndTime.Time'),
          value: this.appointment.start_at,
        },
        {
          label: this.$t('Doctors.Doctor'),
          value: this.appointment.doctor?.name,
        },
        {
          label: this.$t('Base.Cabinet'),
          value: this.appointment.doctor?.cabinet,
        },
      ];
    },
  },

  methods: {
    async print(appointment) {
      try {
        const { data } = await Patient.getQrCodeLinkByUserId(appointment.patient.id);
        this.ticket.qrPayload = data.link;
      } catch (err) {
        this.$notify({
          type: 'error',
          title: getApiErrorMessage(err),
        });
      }

      this.appointment = appointment;
      setTimeout(() => {
        this.$refs.document.print();
      }, 200);
    },
  },
};
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/user.locales.json" />
<i18n src="@/locales/patients.locales.json" />
<i18n src="@/locales/dateAndTime.locales.json" />
