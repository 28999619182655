import { ref, onUnmounted, computed } from 'vue';
import { DEFAULT_GET_DATA_INTERVAL } from '@/config/ui.config';
import { useStore } from 'vuex';
import noop from 'lodash.noop';

export function useGetDataByInterval(callback = noop, interval = DEFAULT_GET_DATA_INTERVAL) {
  const store = useStore();
  const isAuth = computed(() => {
    return store.state.auth.isAuthorized;
  });

  const getDataTimerId = ref(null);

  const clearTimer = () => clearTimeout(getDataTimerId.value);
  const getData = function () {
    if (!isAuth.value) {
      return;
    }

    callback();

    if (getDataTimerId.value) clearTimer();
    getDataTimerId.value = setInterval(callback, interval);
  };

  onUnmounted(() =>
    setTimeout(() => {
      clearTimer();
      getDataTimerId.value = null;
    })
  );

  return getData;
}
