{
  "en": {
    "ForgotPassword": "Forgot your password?",
    "LogIn": "Sign in"
  },
  "ru": {
    "ForgotPassword": "Забыли пароль?",
    "LogIn": "Войти"
  },
  "uz": {
    "ForgotPassword": "Parolni unutdingizmi?",
    "LogIn": "Kirish"
  }
}