{
  "en": {
    "Content": "Please contact your manager to solve this problem.",
    "Prev": "Return",
    "Title": "Forgot your password?"
  },
  "ru": {
    "Content": "Пожалуйста, свяжитесь с вашим менеджером чтобы решить эту проблему.",
    "Prev": "Вернуться",
    "Title": "Забыли пароль?"
  },
  "uz": {
    "Content": "Ushbu muammoni hal qilish uchun menejeringizga murojaat qiling.",
    "Prev": "Qaytish",
    "Title": "Parolni unutdingizmi?"
  }
}