import { CRUDModel } from '@/models/CRUD.model';
import { ApiService } from '@/services/api.service';

/**
 * @class Device
 * @extends CRUDModel
 */
export class Device extends CRUDModel {
  static modelName = 'device';
  static tableName = 'devices';

  /**
   * @param {DeviceConstructorPayload|object} payload
   * @param {string} payload.ip
   * @param {string} payload.status
   * @param {string} payload.device_id
   * @param {string} payload.user_agent
   * @param {string} payload.created_at
   */
  constructor(payload) {
    super(payload);

    this.ip = payload?.ip ?? null;
    this.status = payload?.status ?? null;
    this.device_id = payload?.device_id ?? null;
    this.user_agent = payload?.user_agent ?? null;
    this.created_at = payload?.created_at ?? null;
  }

  /**
   * @param {string} deviceid
   * @param {string} status
   * @return {Promise<{data: response.data, response: AxiosResponse<any>}>}
   */
  static async updateStatus(deviceid, status) {
    const response = await ApiService.post(`${this.tableName}/${deviceid}/${status}`);

    return {
      response: response,
      data: response.data,
    };
  }

  /**
   * @return {Promise<{data: response.data, response: AxiosResponse<any>}>}
   */
  static async checkResult() {
    const response = await ApiService.get(`${this.tableName}/check`);

    return {
      response: response,
      data: response.data,
    };
  }

  static enum = {
    statuses: {
      Activated: 'activated',
      Blocked: 'blocked',
      Created: 'created',
    },
  };
}
