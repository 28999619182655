import { mapState, mapActions } from 'vuex';
import { usePage, usePerPage } from '@/hooks/query';
import { useGetDataByInterval } from '@/hooks/useGetDataByInterval.hook';
import { compareQueriesThenLoadData } from '@/utils/router.utils';
import { ReferencesGroup } from '@/models/laboratory/ReferencesGroup.model';
import { ReferencesValue } from '@/models/laboratory/ReferencesValue.model';
import { Analysis } from '@/models/laboratory/Analysis.model';
import { GlobalModalCloseAction } from '@/models/client/ModalAndDrawer/GlobalModalCloseAction';
import {
  LABORATORY_ANALYZES_ROUTE,
  LABORATORY_REFERENCES_GROUPS_ROUTE,
} from '@/router/laboratory.routes';
import noop from 'lodash.noop';

import LayoutByUserRole from '@/components/layouts/LayoutByUserRole/index.vue';
import LayoutContentHeader from '@/components/layouts/assets/LayoutContentHeader/index.vue';
import ReferencesValuesTable from '@/components/laboratory/referencesValues/ReferencesValuesTable/index.vue';
import CreateOrEditReferencesValueModal from '@/components/laboratory/referencesValues/CreateOrEditReferencesValueModal/index.vue';

export default {
  name: 'VReferencesValues',
  components: { LayoutByUserRole, LayoutContentHeader, ReferencesValuesTable },
  props: {
    referencesGroupId: [String, Number],
    analysisId: [String, Number],
  },
  setup: () => ({
    page: usePage(),
    perPage: usePerPage(),

    getDataByInterval: noop,
  }),

  data() {
    return {
      /** @type {ReferencesGroup|object} referencesGroup */
      referencesGroup: null,
      referencesGroupLoading: false,

      /** @type {Analysis|object} */
      analysis: null,
      analysisLoading: false,
    };
  },
  computed: {
    ...mapState({
      items: (state) => state.referencesValues.data,
      total: (state) => state.referencesValues.total,
      loading: (state) => state.referencesValues.loading,
    }),

    title() {
      let title = '';
      const value = this.referencesGroup?.title || this.analysis?.title || '';
      if (this.referencesGroupId) title = this.$t('Laboratory.ReferencesGroup');
      if (this.analysisId) title = this.$t('Laboratory.Analysis.Analysis');

      return `${title}: ${value}`;
    },

    queryWatchers() {
      return {
        page: this.page.value,
        per_page: this.perPage.value,
        references_group_id: this.referencesGroupId,
        analysis_id: this.analysisId,
      };
    },
  },

  watch: {
    queryWatchers: {
      handler(value, oldValue) {
        compareQueriesThenLoadData({
          query: value,
          oldQuery: oldValue,
          getData: this.getDataByInterval,
          reset: this.page.reset,
          fieldsForResetPage: ['references_group_id'],
        });
      },
      deep: true,
    },

    referencesGroupId: {
      handler(value) {
        if (value) this.getReferencesGroup();
      },
      immediate: true,
    },
    analysisId: {
      handler(value) {
        if (value) this.getAnalysis();
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions({
      setData: 'referencesValues/setData',
      setLoading: 'referencesValues/setLoading',
      createItem: 'referencesValues/createItem',
      editItem: 'referencesValues/editItem',
      deleteItem: 'referencesValues/deleteItem',
    }),

    async getReferencesGroup() {
      this.referencesGroupLoading = true;
      const { referencesGroup } = await ReferencesGroup.findOneById(this.referencesGroupId);
      this.referencesGroup = referencesGroup;
      this.referencesGroupLoading = false;
    },

    async getAnalysis() {
      this.analysisLoading = true;
      const { analysis } = await Analysis.findOneById(this.analysisId);
      this.analysis = analysis;
      this.analysisLoading = false;
    },

    async getData() {
      this.setLoading(true);

      const { data } = await ReferencesValue.find(this.queryWatchers);
      this.setData({
        items: data.data,
        total: data.meta.total,
        overwriteDataState: true,
      });

      this.setLoading(false);
    },

    async create() {
      const action = await this.$store.dispatch('modalAndDrawer/openModal', {
        component: CreateOrEditReferencesValueModal,
        payload: {
          data: new ReferencesValue({
            references_group_id: this.referencesGroupId ? +this.referencesGroupId : null,
            references_group: this.referencesGroup,
            analysis_id: this.analysisId ? +this.analysisId : null,
            analysis: this.analysis,
          }),
        },
      });

      if (!(action instanceof GlobalModalCloseAction)) this.createItem(action.data.referencesValue);
    },

    backHandler() {
      if (this.referencesGroupId) this.$router.push(LABORATORY_REFERENCES_GROUPS_ROUTE.path);
      if (this.analysisId) this.$router.push(LABORATORY_ANALYZES_ROUTE.path);

      this.$router.back();
    },
  },

  mounted() {
    this.getDataByInterval = useGetDataByInterval(this.getData);
    this.getDataByInterval();
  },
};
