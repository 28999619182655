import { onlyLoggedInMiddleware } from '@/middlewares/onlyLoggedIn.middleware';
import { byUserRolesMiddleware } from '@/middlewares/byUserRoles.middleware';
import { User } from '@/models/User.model';

export const DEMOGRAPHY_PATH_PREFIX = '/demography';

export const DEMOGRAPHY_DEATHS_ROUTE = {
  name: 'DEMOGRAPHY_DEATHS',
  path: `${DEMOGRAPHY_PATH_PREFIX}/deaths`,
  component: 'VDemographyDeaths',
  beforeEnter: [
    onlyLoggedInMiddleware,
    byUserRolesMiddleware([User.enum.roles.Doctor, User.enum.roles.Director]),
  ],
  props: true,
  meta: {
    title: 'Analytics.Demography',
  },
};

export const DEMOGRAPHY_BIRTHS_ROUTE = {
  name: 'DEMOGRAPHY_BIRTHS',
  path: `${DEMOGRAPHY_PATH_PREFIX}/births`,
  component: 'VDemographicsBirths',
  beforeEnter: [
    onlyLoggedInMiddleware,
    byUserRolesMiddleware([User.enum.roles.Doctor, User.enum.roles.Director]),
  ],
  props: true,
  meta: {
    title: 'Analytics.Demography',
  },
};

export const routes = [DEMOGRAPHY_DEATHS_ROUTE, DEMOGRAPHY_BIRTHS_ROUTE];
