import { onlyLoggedInMiddleware } from '@/middlewares/onlyLoggedIn.middleware';
import { byUserRolesMiddleware } from '@/middlewares/byUserRoles.middleware';
import { User } from '@/models/User.model';

export const ANALYTICS_PATH_PREFIX = '/analytics';

export const ANALYTICS_DIAGNOSES_ROUTE = {
  name: 'ANALYTICS_DIAGNOSES',
  path: `${ANALYTICS_PATH_PREFIX}/diagnoses`,
  component: 'VAnalyticsDiagnoses',
  beforeEnter: [onlyLoggedInMiddleware, byUserRolesMiddleware([User.enum.roles.Director])],
  meta: {
    title: 'Analytics.Diagnoses',
  },
};

export const ANALYTICS_ANALYSIS_ROUTE = {
  name: 'ANALYTICS_ANALYSIS',
  path: `${ANALYTICS_PATH_PREFIX}/analysis`,
  component: 'VAnalyticsAnalysis',
  beforeEnter: [onlyLoggedInMiddleware, byUserRolesMiddleware([User.enum.roles.Director])],
  meta: {
    title: 'Analytics.Analyzes',
  },
};

export const ANALYTICS_PATRONAGE_ROUTE = {
  name: 'ANALYTICS_PATRONAGE',
  path: `${ANALYTICS_PATH_PREFIX}/patronage`,
  component: 'VAnalyticsPatronage',
  beforeEnter: [onlyLoggedInMiddleware, byUserRolesMiddleware([User.enum.roles.Director])],
  meta: {
    title: 'Analytics.Patronage',
  },
};

export const ANALYTICS_MICRODISTRICTS_ROUTE = {
  name: 'ANALYTICS_MICRODISTRICTS',
  path: `${ANALYTICS_PATH_PREFIX}/microdistricts`,
  component: 'VAnalyticsMicrodistricts',
  beforeEnter: [onlyLoggedInMiddleware, byUserRolesMiddleware([User.enum.roles.Director])],
  meta: {
    title: 'Analytics.Mictrodistrict',
  },
};

export const routes = [
  ANALYTICS_DIAGNOSES_ROUTE,
  ANALYTICS_PATRONAGE_ROUTE,
  ANALYTICS_ANALYSIS_ROUTE,
  ANALYTICS_MICRODISTRICTS_ROUTE,
];
