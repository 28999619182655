import { CRUDModel } from '@/models/CRUD.model';
import { ApiService } from '@/services/api.service';

export class Biomaterial extends CRUDModel {
  static modelName = 'biomaterial';
  static tableName = 'biomaterials';

  /**
   * @param {object} payload
   * @param {object} payload.basic_data
   * @param {string} payload.biomaterial_type
   * @param {string} payload.biomaterial_type_translate
   * @param {Array<number>} payload.study_ids
   * @param {Array<Study>} payload.studys
   * @param {string} payload.sampling_at DATE_FORMAT
   * @param {string} payload.created_at DATE_FORMAT
   */
  constructor(payload) {
    super(payload);

    this.basic_data = payload?.basic_data ?? null;
    this.biomaterial_type = payload?.biomaterial_type ?? null;
    this.biomaterial_type_translate = payload?.biomaterial_type_translate ?? null;
    this.study_ids = payload?.study_ids ?? [];
    this.studys = payload?.studys ?? [];
    this.sampling_at = payload?.sampling_at ?? null;
    this.created_at = payload?.created_at ?? null;
  }

  /**
   * @return {Promise<{
   *   data: response.data,
   *   biomaterial_types: Array<{title: string, biomaterial_type_translate: string}>,
   *   response: AxiosResponse<any>
   * }>}
   */
  static async getBiomaterialTypes() {
    const response = await ApiService.get(`${this.tableName}/types`);
    return {
      response,
      data: response.data,
      biomaterial_types: response.data.data,
    };
  }

  /**
   * @param {string} id
   * @param {object} payload
   * @param {object} payload.basic_data
   * @return {Promise<{data: response.data, response: AxiosResponse<any>}>}
   */
  static async updateBiomaterialById(id, payload) {
    const response = await ApiService.put(`${this.tableName}/${id}/update`, payload);
    return {
      response,
      data: response.data,
    };
  }

  /**
   * @param {Biomaterial|object} biomaterial
   * @returns {title}
   */
  static getBiomaterialTitleWithAnalysisCategory(biomaterial) {
    if (biomaterial.study_ids.length === 0) return biomaterial.biomaterial_type_translate;

    const studyWithAnalysisCategory = biomaterial.studys.find((study) => study.analysis.category);
    return studyWithAnalysisCategory
      ? `${biomaterial.biomaterial_type_translate} (${studyWithAnalysisCategory.analysis.category})`
      : biomaterial.biomaterial_type_translate;
  }

  /**
   * @param {Object} payload
   * @param {number} payload.id
   * @param {string} payload.status
   * @return {Promise<{data: response.data, response: AxiosResponse<any>}>}
   */
  static async updateStatusByBiomaterialId({ id, status }) {
    const response = await ApiService.put(`${this.tableName}/${id}/${status}`);

    return { response, data: response.data };
  }

  // Статусы для фронта
  static enum = {
    statuses: {
      NotAssembled: 'not_assembled',
      Assembled: 'assembled',
      NotConfirmed: 'not_confirmed',
      Confirmed: 'confirmed',
      Canceled: 'canceled',
    },
  };
}
