import axios from 'axios';
import { ApiService } from '@/services/api.service';
import { mergeOrCreateQuery } from '@/utils/http.util';
import { deleteEmptyValueKeys } from '@/utils/object.util';
import { ElNotification } from 'element-plus';
import { PaymentDetailsDto, SalaryDto, SalaryTableItemsResponseData } from '@/types/api';
import { QueryParams } from '@/types/common';

type PaymentDetailsFormData = {
  id?: PaymentDetailsDto['id'];
  title?: PaymentDetailsDto['title'];
  amount?: PaymentDetailsDto['amount'];
  type?: PaymentDetailsDto['type'];
  count?: PaymentDetailsDto['count'];
  can_deleted?: PaymentDetailsDto['can_deleted'];
  deleted?: boolean;
};

export type SalaryFormData = Pick<SalaryDto, 'status' | 'payed_at'> & {
  id?: SalaryDto['id'] | null;
  user?: (SalaryDto['user'] & { speciality?: string }) | null;
  user_id?: SalaryDto['user']['id'] | null;
  amount: SalaryDto['amount'] | null;
  salary: SalaryDto['salary'] | null;
  payment_details: PaymentDetailsFormData[];
};

export class Salary {
  static modelName = 'salary';
  static tableName = 'salaries';

  id?: SalaryFormData['id'];
  user: SalaryFormData['user'];
  user_id: SalaryFormData['user_id'];
  amount: SalaryFormData['amount'];
  salary: SalaryFormData['salary'];
  status: SalaryFormData['status'];
  payed_at: SalaryFormData['payed_at'];
  payment_details: SalaryFormData['payment_details'];

  constructor(payload?: SalaryFormData) {
    this.id = payload?.id ?? null;
    this.user = payload?.user ?? null;
    this.user_id = payload?.user?.id ?? null;
    this.amount = payload?.amount ?? null;
    this.salary = payload?.salary ?? null;
    this.status = payload?.status ?? 'waiting';
    this.payed_at = payload?.payed_at ?? '';
    this.payment_details = payload?.payment_details ?? [];
  }

  static async getItems(query: QueryParams) {
    try {
      const response = await ApiService.get<SalaryTableItemsResponseData>(
        mergeOrCreateQuery({
          url: `${this.tableName}`,
          query: deleteEmptyValueKeys(query),
        })
      );

      return {
        data: response.data.data,
        meta: response.data.meta,
      };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: axios.isAxiosError(err) ? err.message : String(err),
      });
    }
  }

  static async getItemById(id: number) {
    try {
      const response = await ApiService.get<{ data: SalaryDto }>(`${this.tableName}/${id}`);

      return {
        response: response,
        data: response.data.data,
      };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: axios.isAxiosError(err) ? err.message : String(err),
      });
    }
  }

  static async createItem(payload: SalaryFormData) {
    try {
      const response = await ApiService.post<{ data: SalaryDto }>(
        `${this.tableName}/create`,
        payload
      );

      return {
        response: response,
        data: response.data.data,
      };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: axios.isAxiosError(err) ? err.message : String(err),
      });
    }
  }

  static async updateStatus(payload: { id: SalaryDto['id']; status: SalaryDto['status'] }) {
    try {
      const response = await ApiService.put<{ data: SalaryDto }>(
        `${this.tableName}/${payload.id}/${payload.status}`
      );

      return {
        response: response,
        data: response.data.data,
      };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: axios.isAxiosError(err) ? err.message : String(err),
      });
    }
  }
}
