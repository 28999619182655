import { createApp } from 'vue';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';

import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';

// TODO: как появится переключении языка переделать
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import dayjs from 'dayjs';
// import 'dayjs/locale/ru'; // Date and time localization
// dayjs.locale('ru');

import App from './App.vue';
import { Router } from './router';
import { Store } from '@/store';
import { ApiPlugin } from '@/plugins/api.plugin.js';
import { I18nPlugin } from '@/plugins/i18n.plugin';

import '@/assets/styles/index.scss';

const app = createApp(App)
  .use(Router)
  .use(Store)
  .use(ApiPlugin)
  .use(I18nPlugin)
  // TODO: как появится переключении языка переделать
  .use(ElementPlus);

// init global ui components
const UiComponents = import.meta.glob('./components/ui/**/*.vue', { eager: true });
for (const key in UiComponents) {
  const component = UiComponents[key].default;
  app.component(component.name, component);
}

// init sentry
if (import.meta.env.PROD) {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(Router),
        tracingOrigins: [import.meta.env.VITE_CURRENT_MODE_SITE_URL, /^\//],
      }),
    ],
    tracesSampleRate: 1.0,
  });
}

app.mount('#app');
