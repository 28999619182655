<template>
  <ElDialog
    class="service-crud-modal"
    :model-value="modelValue"
    width="100%"
    :title="isNewService ? $t('Services.AddService') : $t('Services.EditService')"
    v-bind="$attrs"
    @update:model-value="$emit('update:modelValue', $event)">
    <div v-if="loading.getData" class="service-crud-modal__loading" v-loading="true" />
    <VeeForm v-else class="service-crud-modal__form" as="ElForm">
      <ServiceMainForm
        v-if="!!formData.values"
        :form-data="formData.values"
        @close:modal="$emit('update:modelValue')"
        @action="$emit('action', $event)" />
    </VeeForm>
  </ElDialog>
</template>

<script lang="ts">
export default {
  name: 'ServiceCrudModal',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import axios from 'axios';
import {
  ServiceGroupBase,
  ServiceGroupProcedure,
  ServiceGroupProduct,
  ServiceGroupConsultation,
  ServiceGroupConsultationFormData,
  ServiceGroupProcedureFormdata,
  ServiceGroupProductFormData,
} from '@/models/accounting/Service.model';
import { reactive, watch, computed } from 'vue';
import { Form as VeeForm } from 'vee-validate';
import { ElNotification } from 'element-plus';

import ServiceMainForm from './ServiceMainForm/index.vue';

const props = withDefaults(defineProps<{ modelValue: boolean; id?: number | null }>(), {
  modelValue: false,
  id: null,
});

defineEmits(['update:modelValue', 'action']);

const formData = reactive<{
  values:
    | ServiceGroupConsultationFormData
    | ServiceGroupProcedureFormdata
    | ServiceGroupProductFormData
    | null;
}>({ values: null });

const loading = reactive({
  getData: false,
});

const isNewService = computed(() => !props.id);

const getServiceGroupById = async (id: number) => {
  loading.getData = true;

  try {
    const response = await ServiceGroupBase.getItemById(id);

    switch (response?.data.type as ServiceGroupBase['type'] | undefined) {
      case 'analysis':
      case 'procedure':
        formData.values = { ...new ServiceGroupProcedure(response?.data) };
        break;
      case 'product':
        formData.values = { ...new ServiceGroupProduct(response?.data) };
        break;
      case 'consultation':
        formData.values = { ...new ServiceGroupConsultation(response?.data) };
        break;
      default:
        formData.values = { ...new ServiceGroupConsultation(response?.data) };
    }
  } catch (err) {
    ElNotification({
      type: 'error',
      title: axios.isAxiosError(err) ? err.message : String(err),
    });
  } finally {
    loading.getData = false;
  }
};

watch(
  () => props.id,
  (value) => {
    if (value) {
      getServiceGroupById(value);
    } else {
      formData.values = { ...new ServiceGroupConsultation() };
    }
  },
  { immediate: true }
);
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/accounting.locales.json" />
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/appointments.locales.json" />
<i18n src="@/locales/dateAndTime.locales.json" />
<i18n src="@/locales/notifications.locales.json" />
