<template>
  <div class="salary">
    <div>
      <ElCard shadow="never" class="salary__header"
        ><h2>{{ $t('Salary.CalculateSalary') }}</h2>
        <ElButton type="primary" @click="openCreateModal"
          >{{ $t('Salary.AddCalculating') }}
        </ElButton>
      </ElCard>
    </div>

    <UiTableWithPagination
      :data="items"
      :loading="loading"
      v-model:page="page.value"
      v-model:per-page="perPage.value"
      :total="total"
      @row-click="openEditModal">
      <ElTableColumn prop="amount" :label="$t('Salary.SumCost')" />
      <ElTableColumn prop="payed_at" :label="$t('Salary.Date')" />
      <ElTableColumn prop="status" :label="$t('User.Status')">
        <template #default="{ row }">
          <StatusTag
            :title="row.status ? $t(`Salary.Status.${row.status}`) : ''"
            :value="row.status"
            :map-status="mapStatus" />
        </template>
      </ElTableColumn>

      <ElTableColumn prop="actions" :label="$t('Base.Actions')" width="250">
        <ElButton type="primary" text>
          <template #icon>
            <UiIcon :icon="icons.EYE" />
          </template>
          {{ $t('Base.View') }}
        </ElButton>
      </ElTableColumn>
    </UiTableWithPagination>
  </div>
</template>

<script lang="ts">
export default {
  name: 'SalaryTable',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { MapStatusType } from '@/components/common/StatusTag/types';
import { usePage, usePerPage } from '@/hooks/query';
import { compareQueriesThenLoadData } from '@/utils/router.utils';
import { Salary } from '@/models/accounting/Salary.model';
import { GlobalModalCloseAction } from '@/models/client/ModalAndDrawer/GlobalModalCloseAction';
import { SalaryShortDto, SalaryStatus } from '@/types/api';

import UiTableWithPagination from '@/components/ui/UiTableWithPagination/index.vue';
import StatusTag from '@/components/common/StatusTag/index.vue';
import SalaryCrudModal from '@/components/accounting/modals/SalaryCrudModal/index.vue';
import * as icons from '@/enums/icons.enum';

const store = useStore();
const loading = ref(false);
const page = usePage();
const perPage = usePerPage();

const mapStatus: MapStatusType<SalaryStatus> = {
  waiting: 'warning',
  payed: 'success',
  canceled: 'danger',
};

const props = defineProps<{
  id: string;
}>();

const total = computed(() => store.state.salaries.total);
const items = computed(() => store.state.salaries.data);
const query = computed(() => ({
  page: page.value,
  per_page: perPage.value,
}));

const getSalaries = async () => {
  loading.value = true;
  const result = await Salary.getItems({
    user_id: Number(props.id),
    ...query.value,
  });

  if (result?.data) {
    store.dispatch('salaries/setData', {
      items: result.data.map((item) => ({ ...item, amount: `${item.amount} сум` })),
      total: result.meta.total,
      overwriteDataState: true,
    });
  }
  loading.value = false;
};

const openCreateModal = async () => {
  const action = await store.dispatch('modalAndDrawer/openModal', {
    component: SalaryCrudModal,
    payload: {
      userId: props.id,
    },
  });

  if (!(action instanceof GlobalModalCloseAction)) {
    getSalaries();
  }
};

const openEditModal = async (row: SalaryShortDto) => {
  const action = await store.dispatch('modalAndDrawer/openModal', {
    component: SalaryCrudModal,
    payload: {
      id: row.id,
    },
  });

  if (!(action instanceof GlobalModalCloseAction)) {
    getSalaries();
  }
};

watch(
  query,
  (value, oldValue) => {
    compareQueriesThenLoadData({
      query: value,
      oldQuery: oldValue,
      getData: getSalaries,
      resetPage: page.reset,
    });
  },
  { deep: true, immediate: true }
);
</script>

<style lang="scss" src="./index.scss" />

<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/accounting.locales.json" />
<i18n src="@/locales/user.locales.json" />
