<template>
  <UiTableWithPagination
    :data="data"
    :loading="loading"
    :page="page"
    :per-page="perPage"
    :total="total"
    :default-sort="defaultSort"
    @sort-change="onSortChange"
    @update:page="$emit('update:page', $event)"
    @update:perPage="$emit('update:perPage', $event)"
    @row-click="goToDoctor">
    <ElTableColumn prop="doctor.name" :label="$t('Doctors.Doctor')">
      <template #default="{ row }">
        <div class="doctors-avatars-wrapper">
          <UiUserAvatarInfo class="doctors-avatars-wrapper__avatar" :bold="false" :user="row" />
          <p class="doctors-avatars-wrapper__text">{{ row.doctor.name }}</p>
        </div>
      </template>
    </ElTableColumn>
    <ElTableColumn
      prop="count_appointments"
      :label="$t('Doctors.NumberReceptions')"
      width="250"
      sortable="custom">
      <template #default="{ row }">
        <div class="doctors-table-wrapper">
          <div class="doctors-table-wrapper-percent__text">{{ row.count_appointments }}</div>
          <div class="doctors-table-wrapper-percent__icon">
            <UiIcon :icon="row.percent_change > 0 || null ? icons.VECTOR_TOP : icons.VECTOR_BOT" />
          </div>
        </div>
      </template>
    </ElTableColumn>
    <ElTableColumn
      prop="appointment_on_time"
      :label="$t('Doctors.RecordingTime')"
      width="250"></ElTableColumn>
    <ElTableColumn
      prop="percent_successful"
      :label="$t('Doctors.CompletedAppointments')"
      width="250"
      sortable="custom">
      <template #default="{ row }">
        <div class="column">
          {{ row.percent_successful ? Math.floor(row.percent_successful) : 0 }}%
          <div class="wrapper-bar">
            <div
              :style="{ width: `${row.percent_successful ?? 0}%` }"
              :class="getBarClass(row.percent_successful)"></div>
          </div>
        </div>
      </template>
    </ElTableColumn>

    <ElTableColumn prop="actions" :label="$t('Base.Actions')" width="250">
      <ElButton class="doctors__btn" type="primary" text>
        <template #icon>
          <UiIcon :icon="icons.EYE" />
        </template>
        {{ $t('Base.View') }}
      </ElButton>
    </ElTableColumn>
  </UiTableWithPagination>
</template>

<script>
import UiTableWithPagination from '@/components/ui/UiTableWithPagination/index.vue';
import { DOCTOR_ROUTE } from '@/router/doctors.routes';
import * as icons from '@/enums/icons.enum';

export default {
  name: 'DoctorsTable',
  data() {
    return {};
  },
  components: { UiTableWithPagination },
  emits: ['update:perPage', 'update:page', 'sort-change'],
  props: {
    /**
     * @property { Array<Doctor|object> } items
     */
    data: Array,
    loading: Boolean,
    page: Number,
    perPage: Number,
    total: Number,
    defaultSort: Object,
  },
  methods: {
    goToDoctor(doctor) {
      this.$router.push({
        name: DOCTOR_ROUTE.name,
        params: { id: doctor.doctor_id },
      });
    },
    getBarClass(percent) {
      if (percent > 89) {
        return 'bar green';
      } else if (percent > 20) {
        return 'bar yellow';
      } else {
        return 'bar red';
      }
    },
    onSortChange({ prop, order }) {
      this.$emit('sort-change', { prop, order });
    },
  },
  computed: {
    icons() {
      return icons;
    },
  },
};
</script>

<style lang="scss" src="./index.scss" />
