<template>
  <LayoutByUserRole content-class="v-hospital" :loading="loading.hospital">
    <template v-if="!!hospital?.id">
      <!--  Patient  -->
      <PatientCardRow :patient="hospital?.user" :items="patientItems">
        <template #actions>
          <UiSelect
            v-model="currentUserStates"
            :options="userStatesOptions"
            value-key="id"
            label-key="title"
            multiple
            @change="onUserStatesChange" />
        </template>
      </PatientCardRow>

      <!--  Hospital Card  -->
      <LayoutContentHeader :title="$t('Hospital.HospitalTreatment')">
        <template #append> <HospitalStatusTag :status="hospital.status" /> </template>

        <template #actions>
          <ElButton
            v-show="actionsVisible.cancel"
            type="danger"
            plain
            :loading="loading.cancel"
            @click="cancel">
            {{ $t('Base.Cancel') }}
          </ElButton>

          <ElButton v-show="actionsVisible.edit" type="primary" plain @click="edit">
            {{ $t('Base.Edit') }}
          </ElButton>

          <ElButton
            v-show="actionsVisible.close"
            type="primary"
            :loading="loading.close"
            @click="close">
            {{ $t('Base.Close') }}
          </ElButton>
        </template>
      </LayoutContentHeader>
      <HospitalCard v-model:hospital="hospital" />

      <!--  Treatments  -->
      <LayoutContentHeader :title="$t('Base.TreatmentJournal')">
        <template #actions>
          <ElButton v-show="actionsVisible.treatment" type="primary" plain @click="addTreatment">
            {{ $t('Base.Add') }}
          </ElButton>
        </template>
      </LayoutContentHeader>
      <TreatmentsTable
        :items="hospital.treatments"
        :page="1"
        :per-page="hospital.treatment_ids.length || 1"
        :total="hospital.treatment_ids.length"
        @item:update="updateTreatment" />
    </template>
  </LayoutByUserRole>
</template>

<script>
import axios from 'axios';
import { PATIENT_ROUTE } from '@/router/patients.routes';
import { HOSPITALS_ROUTE } from '@/router/hospital.routes';
import { insertRouteParams } from '@/utils/router.utils';
import { Hospital } from '@/models/hospital/Hospital.model';
import { GlobalModalCloseAction } from '@/models/client/ModalAndDrawer/GlobalModalCloseAction';
import { Patient } from '@/models/Patient.model';
import { Patronage } from '@/models/patronage/Patronage.model';

import LayoutByUserRole from '@/components/layouts/LayoutByUserRole/index.vue';
import LayoutContentHeader from '@/components/layouts/assets/LayoutContentHeader/index.vue';
import PatientCardRow from '@/components/patients/PatientCardRow/index.vue';
import HospitalCard from '@/components/hospitals/HospitalCard/index.vue';
import TreatmentsTable from '@/components/treatments/TreatmentsTable/index.vue';
import HospitalStatusTag from '@/components/hospitals/HospitalStatusTag/index.vue';
import CreateOrEditHospitalModal from '@/components/hospitals/CreateOrEditHospitalModal/index.vue';
import CreateOrEditTreatmentModal from '@/components/treatments/CreateOrEditTreatmentModal/index.vue';

export default {
  name: 'VHospital',
  components: {
    LayoutByUserRole,
    LayoutContentHeader,
    PatientCardRow,
    HospitalCard,
    TreatmentsTable,
    HospitalStatusTag,
  },
  props: {
    id: [Number, String],
  },
  data() {
    return {
      loading: {
        hospital: false,
        close: false,
        cancel: false,
      },
      userStatesOptions: [],
      currentUserStates: [],
      /** @type {Hospital} hospital */
      hospital: new Hospital(),
    };
  },
  computed: {
    actionsVisible() {
      return {
        edit: this.hospital.status === Hospital.enum.statuses.IN_PROGRESS,
        close: this.hospital.status === Hospital.enum.statuses.IN_PROGRESS,
        cancel: this.hospital.status === Hospital.enum.statuses.IN_PROGRESS,
        treatment: this.hospital.status === Hospital.enum.statuses.IN_PROGRESS,
      };
    },

    patientItems() {
      return [
        {
          label: this.$t('User.Phone'),
          value: this.hospital?.user?.phone,
        },
        {
          label: this.$t('User.Birthdate'),
          value: this.hospital?.user?.birthdate,
        },
        {
          label: this.$t('User.Gender'),
          value: this.hospital?.user?.gender
            ? this.$t(`User.Genders.${this.hospital.user.gender}`)
            : '...',
        },
      ];
    },

    ambulatoryCardPageLink() {
      return insertRouteParams({
        path: PATIENT_ROUTE.childrenMap.PATIENT_ROUTE_AMBULATORY_CARD._fullPath,
        params: { id: this.hospital?.user?.id },
      });
    },
  },
  watch: {
    id: {
      async handler(value) {
        this.getHospital();
        if (value) {
          const userStatesOptionsResult = await Patronage.getStates();
          const currentUserStatesResult = await Patient.getStatesById(value);

          if (userStatesOptionsResult) {
            this.userStatesOptions = userStatesOptionsResult.data;
          }
          if (currentUserStatesResult) {
            this.currentUserStates = currentUserStatesResult.data.map((item) => item.id);
          }
        }
      },
      immediate: true,
    },
  },

  methods: {
    async onUserStatesChange(values) {
      await Patient.updateStatesById(this.id, { user_state_ids: values });
    },

    async getHospital() {
      this.loading.hospital = true;
      const { hospital } = await Hospital.findOneById(this.id);
      this.hospital = hospital;
      this.loading.hospital = false;
    },

    async edit() {
      const action = await this.$store.dispatch('modalAndDrawer/openModal', {
        component: CreateOrEditHospitalModal,
        payload: { id: this.id },
      });

      if (!(action instanceof GlobalModalCloseAction)) this.hospital = action.data.hospital;
    },

    async addTreatment() {
      const action = await this.$store.dispatch('modalAndDrawer/openModal', {
        component: CreateOrEditTreatmentModal,
        payload: {
          user: this.hospital.user,
        },
      });

      if (!(action instanceof GlobalModalCloseAction)) {
        this.hospital.treatment_ids.push(action.data.treatment.id);
        this.hospital.treatments.push(action.data.treatment);
      }
    },

    async updateTreatment(item) {
      const index = this.hospital.treatments.findIndex((elem) => elem.id == item.id);
      this.hospital.treatments = [
        ...this.hospital.treatments.slice(0, index),
        item,
        ...this.hospital.treatments.slice(index + 1),
      ];
    },

    /** @return {Promise<boolean>} false - error */
    async updateStatus(status) {
      try {
        await Hospital.updateStatusById(this.hospital.id, status);

        this.$notify({
          type: 'success',
          title: this.$t('Notifications.SuccessUpdated'),
        });
      } catch (err) {
        this.$notify({
          type: 'error',
          title: axios.isAxiosError(err) ? err.message : String(err),
        });

        return false;
      }

      return true;
    },

    async close() {
      if (this.loading.close) return;
      this.loading.close = true;

      const isUpdated = await this.updateStatus(Hospital.enum.statuses.COMPLETED);
      if (isUpdated) this.$router.push(HOSPITALS_ROUTE.path);

      this.loading.close = false;
    },

    async cancel() {
      if (this.loading.cancel) return;
      this.loading.cancel = true;

      const isUpdated = await this.updateStatus(Hospital.enum.statuses.CANCELED);
      if (isUpdated) this.$router.push(HOSPITALS_ROUTE.path);

      this.loading.cancel = false;
    },
  },
};
</script>

<style lang="scss" src="./index.scss"></style>
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/user.locales.json" />
<i18n src="@/locales/patients.locales.json" />
<i18n src="@/locales/hospital.locales.json" />
<i18n src="@/locales/invoices.locales.json" />
<i18n src="@/locales/notifications.locales.json" />
