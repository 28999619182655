<template>
  <div class="service-main-form">
    <UiTabs
      :title="$t('Services.ServiceInformation')"
      v-model="type"
      type="border-card"
      class="service-main-form__tabs">
      <template #title-append>
        <FormSwitchField :label="$t('Services.ShowInApp')" field-name="is_published" />
      </template>
      <ElTabPane
        :label="$t('Services.ServiceType.Consultation')"
        name="consultation"
        :disabled="!actionOptions.isNewService">
        <ConsultationForm v-if="type === 'consultation'" />
      </ElTabPane>
      <ElTabPane
        :label="$t('Services.ServiceType.Product')"
        name="product"
        :disabled="!actionOptions.isNewService">
        <ProductForm v-if="type === 'product'" />
      </ElTabPane>
      <ElTabPane
        :label="$t('Services.ServiceType.Procedure')"
        name="procedure"
        :disabled="!actionOptions.isNewService">
        <ProcedureForm v-if="type === 'procedure'" />
      </ElTabPane>
      <ElTabPane :label="$t('Services.ServiceType.Analysis')" name="analysis" disabled
        >Not implemented
      </ElTabPane>
    </UiTabs>
    <FieldGroupWrapper :title="$t('Services.ServiceCosts')">
      <ExpenseCategoriesForm />
      <FieldGroupDivider />
      <ServiceProductsTableFillingForm
        @table:add="addProduct"
        @product:create="onProductCreateButtonClick" />
      <ServiceProductsTable :items="productTableItems" @table:delete="deleteProduct" />
      <FormTotalAmount :total-amount="productTableTotalSum" />
    </FieldGroupWrapper>
    <ServiceCalculation
      :pre-validate="preValidate"
      :request-disabled="actionOptions.requestDisabled" />
    <FormActionsWrapper align="right">
      <ElPopconfirm
        :title="$t('Ui.PopConfirm.DefaultTitle')"
        @confirm="deleteService"
        v-if="!actionOptions.isNewService">
        <template #reference>
          <ElButton type="danger" :loading="isDeleting">
            {{ $t('Base.Delete') }}
          </ElButton>
        </template>
      </ElPopconfirm>
      <ElButton
        type="primary"
        :disabled="actionOptions.requestDisabled"
        :loading="isSubmitting"
        @click="submitForm"
        >{{ actionOptions.isNewService ? `+ ${$t('Base.Add')}` : $t('Base.Save') }}
      </ElButton>
    </FormActionsWrapper>
    <ProductCrudModal
      v-if="nestedModalData.product.visible"
      v-model="nestedModalData.product.visible"
      :form-data="nestedModalData.product.data"
      append-to-body />
  </div>
</template>

<script lang="ts">
export default {
  name: 'ServiceMainForm',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import { useField, useFieldArray, useForm } from 'vee-validate';
import { Product } from '@/models/warehouse/Product.model';
import {
  ServiceGroupConsultationFormData,
  ServiceGroupBase,
  ServiceGroupProcedureFormdata,
  ServiceGroupProductFormData,
} from '@/models/accounting/Service.model';
import { validationSchema } from '../validationSchema';
import { GlobalModalAction } from '@/models/client/ModalAndDrawer/GlobalModalAction';

import ExpenseCategoriesForm from '../ExpenseCategoriesForm/index.vue';
import ConsultationForm from '../ConsultationForm/index.vue';
import FieldGroupDivider from '@/components/common/form/FieldGroupDivider/index.vue';
import FieldGroupWrapper from '@/components/common/form/FieldGroupWrapper/index.vue';
import FormActionsWrapper from '@/components/common/form/FormActionsWrapper/index.vue';
import FormTotalAmount from '@/components/common/form/FormTotalAmount/index.vue';
import ProductCrudModal from '@/components/warehouse/modals/ProductCrudModal/index.vue';
import ProductForm from '../ProductForm/index.vue';
import ServiceProductsTable from '../ServiceProductsTable/index.vue';
import ServiceProductsTableFillingForm from '../ServiceProductsTableFillingForm/index.vue';
import FormSwitchField from '@/components/common/form/ui/FormSwitchField/index.vue';
import UiTabs from '@/components/ui/UiTabs/index.vue';
import ServiceCalculation from '@/components/accounting/modals/ServiceCrudModal/ServiceCalculation/index.vue';
import ProcedureForm from '@/components/accounting/modals/ServiceCrudModal/ProcedureForm/index.vue';
import { ExpenseProduct, ProductDto, ServiceGroupType } from '@/types/api';

const props = defineProps<{
  formData:
    | ServiceGroupConsultationFormData
    | ServiceGroupProcedureFormdata
    | ServiceGroupProductFormData;
}>();

const isDeleting = ref(false);

const emit = defineEmits(['submit', 'close:modal', 'action']);

const { isSubmitting, errors, handleSubmit, validate } = useForm<
  ServiceGroupConsultationFormData | ServiceGroupProcedureFormdata | ServiceGroupProductFormData
>({
  initialValues: props.formData,
  validationSchema,
  validateOnMount: false,
});

const preValidate = async () => {
  const result = await validate();
  return result;
};

const actionOptions = computed(() => ({
  isNewService: !props.formData.id,
  requestDisabled: Object.keys(errors.value).length > 0,
}));

const { value: type } = useField<ServiceGroupType>('type');

const nestedModalData = ref({
  product: { visible: false, data: { ...new Product() } },
});

const {
  fields: products,
  push: productPush,
  remove: productRemove,
} = useFieldArray<ExpenseProduct>('expenses_products');

const productTableItems = computed(() => products.value.map((field) => ({ ...field.value })));
const productTableTotalSum = computed(() =>
  products.value.reduce((acc, field) => acc + field.value.total_amount, 0)
);

const addProduct = (values: { product: ProductDto; count: number }) => {
  const { product, count } = values;
  productPush({
    price: product.price,
    count,
    title: product.title,
    id: product.id,
    total_amount: count * product.price,
  });
};

const deleteProduct = (id: number) => {
  const idx = products.value.findIndex((item) => item.value.id === id);
  productRemove(idx);
};

const onProductCreateButtonClick = async (value: string) => {
  nestedModalData.value.product.data.title = value;
  nestedModalData.value.product.visible = true;
};

const deleteService = async () => {
  if (props.formData.id) {
    isDeleting.value = true;
    await ServiceGroupBase.deleteItem(props.formData.id);
    isDeleting.value = false;
    emit(
      'action',
      new GlobalModalAction({
        name: 'deleted',
        data: { id: props.formData.id },
      })
    );
    emit('close:modal');
  }
};

const submitForm = handleSubmit(
  async (values) => {
    const formData = {
      ...values,
      expenses_categories: values.expenses_categories.filter((f) => !f.deleted),
    };

    let responseData: Record<string, unknown>;

    if (formData.id) {
      const response = await ServiceGroupBase.updateItem({
        id: formData.id,
        payload: formData,
      });
      responseData = response?.data ?? {};
    } else {
      const response = await ServiceGroupBase.createItem(formData);
      responseData = response?.data ?? {};
    }

    emit(
      'action',
      new GlobalModalAction({
        name: formData.id ? 'updated' : 'created',
        data: { ...responseData },
      })
    );
    emit('close:modal');
  },
  (ctx) => {
    // eslint-disable-next-line no-console
    console.log('invalid submit', ctx.errors);
  }
);
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/accounting.locales.json" />
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/doctors.locales.json" />
<i18n src="@/locales/notifications.locales.json" />
<i18n src="@/locales/validation.locales.json" />
<i18n src="@/locales/warehouse.locales.json" />
<i18n src="@/locales/ui.locales.json" />
