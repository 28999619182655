import { mapState, mapActions } from 'vuex';
import { useDatePeriod, usePage, usePerPage, useUser } from '@/hooks/query';
import { useQuery } from '@/hooks/useQuery.hook';
import { useModel } from '@/hooks/useModel';
import { useGetDataByInterval } from '@/hooks/useGetDataByInterval.hook';
import { compareQueriesThenLoadData } from '@/utils/router.utils';
import { Hospital } from '@/models/hospital/Hospital.model';
import { Department } from '@/models/hospital/Department.model';
import { GlobalModalCloseAction } from '@/models/client/ModalAndDrawer/GlobalModalCloseAction';
import { DATE_FORMAT } from '@/config/dateAndTime.config';
import { mergeOrCreateQuery } from '@/utils/http.util';
import { deleteEmptyValueKeys } from '@/utils/object.util';
import { I18nService } from '@/services/i18n.service';
import { ApiService } from '@/services/api.service';
import { DeviceService } from '@/services/device.service';
import noop from 'lodash.noop';

import LayoutByUserRole from '@/components/layouts/LayoutByUserRole/index.vue';
import LayoutContentHeader from '@/components/layouts/assets/LayoutContentHeader/index.vue';
import SelectHospitalStatus from '@/components/hospitals/SelectHospitalStatus/index.vue';
import HospitalsTable from '@/components/hospitals/HospitalsTable/index.vue';
import CreateOrEditHospitalModal from '@/components/hospitals/CreateOrEditHospitalModal/index.vue';
import PatientsSearchSelect from '@/components/patients/PatientsSearchSelect/index.vue';
import * as icons from '@/enums/icons.enum.js';

export default {
  name: 'VHospitals',
  components: {
    HospitalsTable,
    LayoutContentHeader,
    SelectHospitalStatus,
    LayoutByUserRole,
    PatientsSearchSelect,
  },
  data() {
    return {
      statistic: {
        loading: false,
        data: { patientAmount: 0 },
      },
    };
  },
  setup: () => ({
    page: usePage(),
    perPage: usePerPage(),
    period: useDatePeriod(),
    status: useQuery({
      field: 'status',
    }),
    department: useModel({
      fieldNames: {
        id: 'department_id',
        name: 'department_name',
      },
    }),
    patient: useUser(),
    icons: icons,

    Department,
    DATE_FORMAT,

    getDataByInterval: noop,
  }),
  computed: {
    ...mapState({
      items: (state) => state.hospital.data,
      total: (state) => state.hospital.total,
      loading: (state) => state.hospital.loading,
    }),

    queryWatchers() {
      return {
        page: this.page.value,
        per_page: this.perPage.value,
        start_at: this.period.value[0],
        end_at: this.period.value[1],
        status: this.status.value,
        user_id: this.patient.value.id,
        department_id: this.department.value.id,
      };
    },

    statisticCard() {
      return {
        label: this.$t('Hospital.Statistic.PatientAmount'),
        value: this.statistic.data.patientAmount,
        icon: icons.BOARD,
      };
    },

    exportDataURL() {
      return mergeOrCreateQuery({
        url: Hospital.exportDataURL,
        query: deleteEmptyValueKeys({
          ...this.queryWatchers,
          lang: I18nService.getLocale(),
          token: ApiService.getToken(),
          device_id: DeviceService.getDeviceId(),
        }), // per_page, page будут игнорироваться на бэке
      });
    },
  },
  watch: {
    queryWatchers: {
      handler(value, oldValue) {
        compareQueriesThenLoadData({
          query: value,
          oldQuery: oldValue,
          fieldsForResetPage: ['start_at', 'status', 'department_id'],
          getData: this.getDataByInterval,
          resetPage: this.page.reset,
        });
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions({
      setData: 'hospital/setData',
      setLoading: 'hospital/setLoading',
      createItem: 'hospital/createItem',
      editItem: 'hospital/editItem',
      deleteItem: 'hospital/deleteItem',
    }),

    async getData() {
      this.setLoading(true);

      const { data } = await Hospital.find(this.queryWatchers);
      this.setData({
        items: data.data,
        total: data.meta.total,
        overwriteDataState: true,
      });

      this.setLoading(false);
    },

    async getStatistic() {
      this.statistic.loading = true;

      const response = await Hospital.getStatisticByPatientAmount();
      this.statistic.data.patientAmount = response?.data.value ?? 0;

      this.statistic.loading = false;
    },

    async getDataAndStatistic() {
      this.getData();
      this.getStatistic();
    },

    async create() {
      const action = await this.$store.dispatch(
        'modalAndDrawer/openModal',
        CreateOrEditHospitalModal
      );
      if (!(action instanceof GlobalModalCloseAction)) this.createItem(action.data.hospital);
    },
  },

  mounted() {
    this.getDataByInterval = useGetDataByInterval(this.getDataAndStatistic);
    this.getDataByInterval();
  },
};
