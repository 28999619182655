import { ClientStorageService } from '@/services/clientStorage.service';

const enums = {
  DEVICE_ID_KEY: 'device_id',
};

export class DeviceService {
  /**
   * @param {string} payload
   */
  static setDeviceId(payload) {
    ClientStorageService.setItem(enums.DEVICE_ID_KEY, payload);
  }

  /**
   * @return {string} deviceId
   */
  static getDeviceId() {
    return ClientStorageService.getItem(enums.DEVICE_ID_KEY);
  }

  static removeDeviceId() {
    ClientStorageService.removeItem(enums.DEVICE_ID_KEY);
  }

  static checkAndSetDeviceId() {
    let deviceId = this.getDeviceId();
    if (!deviceId) {
      deviceId = self.crypto.randomUUID();
      this.setDeviceId(deviceId);
    }
  }
}
