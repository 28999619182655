/**
 * @class PrinterDocumentContentBlock
 */
export class PrinterDocumentContentBlock {
  /**
   * @param {object} payload
   * @param {number|string} payload.uuid
   * @param {string} payload.type - this.enum.types
   * @param {string} payload.label
   * @param {number, string, Array<number|string|File|object>} payload.value  принимает массив, если isList = true || isTable = true
   * @param {boolean} payload.inline - if inline = false block fill full width(100%)
   * @param {Array<{prop: string, label: string}>} payload.headers
   * @param {string} payload.class - css class
   * @param {object} payload.style - inline styles for block
   * @param {boolean} payload.withDivider - block with border-bottom
   */
  constructor(payload) {
    this.uuid = payload?.uuid ?? Math.random();
    this.type = payload?.type ?? null;
    this.label = payload?.label ?? null;
    this.value = payload?.value ?? null;
    this.inline = payload?.inline ?? false;
    this.headers = payload?.headers ?? [];
    this.class = payload?.class ?? null;
    this.style = payload?.style ?? {};
    this.withDivider = payload?.withDivider ?? false;
  }

  static enum = {
    types: {
      InlineValue: 'inlineValue',
      List: 'list',
      Table: 'table',
      Title: 'title',
      Editor: 'editor',
    },
  };
}
