<template>
  <DevicesTable
    :items="devices"
    :loading="loading.devices"
    v-model:page="page.value"
    v-model:per-page="perPage.value"
    :total="total"
    @device:update-status="updateDeviceStatus" />
</template>

<script lang="ts">
export default {
  name: 'EmployeeDevicesTable',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { computed, reactive, watch } from 'vue';
import { useStore } from 'vuex';
import { usePage, usePerPage } from '@/hooks/query';
import { Device } from '@/models/Device.model';
import { ElNotification } from 'element-plus';
import axios from 'axios';
import { I18nService } from '@/services/i18n.service';
import { User } from '@/models/User.model';
import { useGetDataByInterval } from '@/hooks/useGetDataByInterval.hook';
import { compareQueriesThenLoadData } from '@/utils/router.utils';

import DevicesTable from '@/components/devices/DevicesTable/index.vue';

const store = useStore();
const props = defineProps<{
  id: string;
}>();
const loading = reactive({
  user: false,
  devices: false,
});
const page = usePage();
const perPage = usePerPage();
const devices = computed(() => store.state.devices.data);
const total = computed(() => store.state.devices.total);

const query = computed(() => ({
  page: page.value,
  per_page: perPage.value,
}));

const updateDeviceStatus = async (payload) => {
  try {
    const { data } = await Device.updateStatus(payload.id, payload.status);
    store.dispatch('devices/editItem', data.data);
  } catch (err) {
    ElNotification({
      type: 'error',
      title: axios.isAxiosError(err) ? err.message : I18nService.t('Notifications.Error'),
    });
  }
};

const getDevices = async () => {
  loading.devices = true;
  try {
    const { data } = await User.getDevices(props.id, query.value);
    store.dispatch('devices/setData', {
      items: data.data,
      total: +data.meta.total,
      overwriteDataState: true,
    });
  } catch (err) {
    ElNotification({
      type: 'error',
      title: axios.isAxiosError(err) ? err.message : I18nService.t('Notifications.Error'),
    });
  }
  loading.devices = false;
};

const getDevicesByInterval = useGetDataByInterval(getDevices);

watch(
  query,
  (value, oldValue) => {
    compareQueriesThenLoadData({
      query: value,
      oldQuery: oldValue,
      getData: getDevicesByInterval,
      resetPage: page.reset,
    });
  },
  { deep: true, immediate: true }
);
</script>

<style scoped></style>
