<template>
  <LayoutByUserRole content-class="v-disabled-people-patients" fix-height>
    <ElBreadcrumb separator="/">
      <ElBreadcrumbItem
        :to="{
          path: `${DISABLED_PEOPLE_PATH_PREFIX}`,
          query: {
            date_period: date.value,
            age_from: ageFrom.value,
            age_to: ageTo.value,
            is_disability: 1,
          },
        }"
        >{{ $t('Analytics.DisabledPeople') }}
      </ElBreadcrumbItem>
      <ElBreadcrumbItem
        >{{ $t('Analytics.Mictrodistrict') }} &#8470; {{ microdistrictId }}
      </ElBreadcrumbItem>
    </ElBreadcrumb>
    <div class="v-disabled-people-patients__header">
      <ElDatePicker
        class="v-disabled-people-patients__date"
        v-model="date.value"
        :format="DATE_FORMAT"
        type="daterange"
        unlink-panels
        :value-format="DATE_FORMAT"
        :placeholder="$t('DateAndTime.Date')" />

      <div class="v-disabled-people-patients__age-data">
        <span>{{ $t('Analytics.AgeFrom') }}</span>
        <UiSelect
          :options="ageOptions"
          v-model="ageFrom.value"
          class="v-disabled-people-patients__age" />
        <span>{{ $t('Analytics.To') }}</span>
        <UiSelect
          :options="ageOptions"
          v-model="ageTo.value"
          class="v-disabled-people-patients__age" />
        <UiSelect
          :options="statusOptions"
          v-model="disabilityGroup.value"
          :placeholder="$t('Patients.DegreeDisability')"
          class="v-disabled-people-patients__status" />
      </div>

      <a class="v-disabled-people-patients__btn" :href="exportDataURL" download>
        <ElButton type="primary">
          {{ $t('Base.DownloadExcel') }}
        </ElButton>
      </a>
    </div>

    <DisabledPeopleStatisticsBlock :query="statisticsQuery" />

    <DisabledPeoplePatientsTable
      :loading="loading"
      :items="tableItems"
      :total="tableItemsTotal"
      v-model:page="page.value"
      v-model:per-page="perPage.value" />
  </LayoutByUserRole>
</template>
<script lang="ts">
export default {
  name: 'VDisabledPeoplePatients',
  inheritAttrs: false,
  customOptions: {},
};
</script>
<script setup lang="ts">
import { DATE_FORMAT } from '@/config/dateAndTime.config';
import { useDatePeriod, usePage, usePerPage } from '@/hooks/query';
import { getMonthPeriod } from '@/utils/dateAndTime.utils';
import { computed, ref, watch } from 'vue';
import { Analytics } from '@/models/analytics/analytics.model';
import { compareQueriesThenLoadData } from '@/utils/router.utils';
import { mergeOrCreateQuery } from '@/utils/http.util';
import { deleteEmptyValueKeys } from '@/utils/object.util';
import { I18nService } from '@/services/i18n.service';
import { DeviceService } from '@/services/device.service';
import { TokenService } from '@/services/token.service';
import { useRoute } from 'vue-router';
import { useQuery } from '@/hooks/useQuery.hook';
import { DisabledPeoplePatientsDto } from '@/types/api';
import { DISABLED_PEOPLE_PATH_PREFIX } from '@/router/disabledРeople.routes';
import { DisabilityGroup } from '@/types/api/disability';

import LayoutByUserRole from '@/components/layouts/LayoutByUserRole/index.vue';
import UiSelect from '@/components/ui/UiSelect/index.vue';
import DisabledPeopleStatisticsBlock from '@/components/analytics/disabledPeople/DisabledPeopleStatisticsBlock/index.vue';
import DisabledPeoplePatientsTable from '@/components/analytics/disabledPeople/tables/DisabledPeoplePatientsTable/index.vue';

const date = useDatePeriod(getMonthPeriod());
const page = usePage();
const perPage = usePerPage();
const loading = ref(false);
const route = useRoute();
const ageFrom = useQuery({ field: 'age_from', defaultValue: 0 });
const ageTo = useQuery({ field: 'age_to', defaultValue: 120 });
const disabilityGroup = useQuery({ field: 'disability_group' });
const tableItems = ref<DisabledPeoplePatientsDto[]>([]);
const tableItemsTotal = ref(0);

const microdistrictId = route.params['microdistrict_id'] as string;

const statisticsQuery = computed(() => ({
  microdistrict_id: Number(microdistrictId) as number | null,
  start_at: date.value[0],
  end_at: date.value[1],
  age_from: ageFrom.value,
  age_to: ageTo.value,
}));

const exportDataURL = computed(() => {
  return mergeOrCreateQuery({
    url: Analytics.exportDisabledPeoplePatientsDataURL,
    query: deleteEmptyValueKeys({
      ...query.value,
      lang: I18nService.getLocale(),
      token: TokenService.getAccessToken(),
      device_id: DeviceService.getDeviceId(),
    }),
  });
});

const query = computed(() => ({
  page: page.value,
  per_page: perPage.value,
  start_at: date.value[0],
  end_at: date.value[1],
  age_from: ageFrom.value,
  age_to: ageTo.value,
  is_disability: 1,
  microdistrict_id: microdistrictId,
  disability_group: disabilityGroup.value,
}));

const statusOptions = computed<{ label: string; value: DisabilityGroup }[]>(() => [
  {
    label: I18nService.t('Patients.Degrees.first_group'),
    value: 'first_group',
  },
  {
    label: I18nService.t('Patients.Degrees.second_group'),
    value: 'second_group',
  },
  {
    label: I18nService.t('Patients.Degrees.third_group'),
    value: 'third_group',
  },
]);

const getPatientsList = async () => {
  loading.value = true;

  const response = await Analytics.getAnalyticsPatients<DisabledPeoplePatientsDto[]>(query.value);

  tableItems.value = response?.data ?? [];
  tableItemsTotal.value = response?.meta.total ?? 0;

  loading.value = false;
};

const ageOptions = computed(() => {
  const options = [];

  for (let i = 0; i <= 120; i++) {
    options.push({ label: i.toString(), value: i });
  }

  return options;
});

watch(
  query,
  (value, oldValue) => {
    compareQueriesThenLoadData({
      query: value,
      oldQuery: oldValue,
      getData: getPatientsList,
      resetPage: page.reset,
      fieldsForResetPage: ['page', 'per_page', 'start_at', 'end_at', 'age_from', 'age_to'],
    });
  },
  { deep: true, immediate: true }
);
</script>

<style scoped lang="scss" src="./index.scss" />
