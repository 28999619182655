<template>
  <LayoutByUserRole content-class="v-patient-diagnoses" fix-height>
    <ElBreadcrumb separator="/">
      <ElBreadcrumbItem
        :to="{
          path: `${PATIENT_GROUPS_PATH_PREFIX}/${group}/`,
          query: { date_period: date.value, age_from: ageFrom.value, age_to: ageTo.value },
        }"
        >{{ $t(`Patients.PatientGroups.${group}`) }}
      </ElBreadcrumbItem>
      <ElBreadcrumbItem
        >{{ $t('Analytics.Mictrodistrict') }} &#8470; {{ microdistrictId }}</ElBreadcrumbItem
      >
    </ElBreadcrumb>

    <div class="v-patient-diagnoses__header">
      <ElDatePicker
        class="v-patient-diagnoses__date"
        v-model="date.value"
        :format="DATE_FORMAT"
        type="daterange"
        unlink-panels
        :value-format="DATE_FORMAT"
        :placeholder="$t('DateAndTime.Date')" />

      <div class="v-patient-diagnoses__age-data">
        <span>{{ $t('Analytics.AgeFrom') }}</span>
        <UiSelect :options="ageOptions" v-model="ageFrom.value" class="v-patient-diagnoses__age" />
        <span>{{ $t('Analytics.To') }}</span>
        <UiSelect :options="ageOptions" v-model="ageTo.value" class="v-patient-diagnoses__age" />
      </div>

      <a class="v-patient-diagnoses__btn" :href="exportDataURL" download>
        <ElButton type="primary">
          {{ $t('Base.DownloadExcel') }}
        </ElButton>
      </a>
    </div>

    <PatientGroupsStatisticsBlock :query="statisticsQuery" />

    <PatientGroupsDiagnosesTable
      :loading="loading"
      :items="items"
      :total="total"
      :default-sort="defaultSort"
      @sort-change="onSortChange"
      v-model:page="page.value"
      v-model:per-page="perPage.value"
      @row-click="goToGroupPatient" />
  </LayoutByUserRole>
</template>

<script lang="ts">
export default {
  name: 'VPatientGroupCodes',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { usePage, usePerPage, useDatePeriod } from '@/hooks/query';
import { computed, ref, watch } from 'vue';
import { getMonthPeriod } from '@/utils/dateAndTime.utils';
import { SortType } from '@/types/common';
import { compareQueriesThenLoadData } from '@/utils/router.utils';
import { Analytics } from '@/models/analytics/analytics.model';
import {
  PATIENT_GROUP_PATIENTS_ROUTE,
  PATIENT_GROUPS_PATH_PREFIX,
} from '@/router/patientGroups.routes';
import { DATE_FORMAT } from '@/config/dateAndTime.config';
import { useQuery } from '@/hooks/useQuery.hook';
import { AnalyticsCodesDto, PatientGroup } from '@/types/api';
import { mergeOrCreateQuery } from '@/utils/http.util';
import { deleteEmptyValueKeys } from '@/utils/object.util';
import { I18nService } from '@/services/i18n.service';
import { DeviceService } from '@/services/device.service';
import { TokenService } from '@/services/token.service';

import UiSelect from '@/components/ui/UiSelect/index.vue';
import LayoutByUserRole from '@/components/layouts/LayoutByUserRole/index.vue';
import PatientGroupsDiagnosesTable from '@/components/analytics/patientGroups/tables/PatientGroupsDiagnosesTable/index.vue';
import PatientGroupsStatisticsBlock from '@/components/analytics/patientGroups/PatientGroupsStatisticsBlock/index.vue';

// TODO  добавить фильтр для возраста
const store = useStore();
const route = useRoute();
const router = useRouter();
const page = usePage();
const perPage = usePerPage();
const loading = ref(false);
const total = computed(() => store.state.patientGroupsDiagnoses.total);
const items = computed(() => store.state.patientGroupsDiagnoses.data);
const date = useDatePeriod(getMonthPeriod());
const defaultSort = ref<null | SortType>(null);
const ageFrom = useQuery({ field: 'age_from', defaultValue: 0 });
const ageTo = useQuery({ field: 'age_to', defaultValue: 120 });

const onSortChange = ({ prop, order }: SortType) => {
  defaultSort.value = { prop: prop, order: order };
};

const group = route.params.group as PatientGroup;
const microdistrictId = route.params['microdistrict_id'] as string;

const query = computed(() => ({
  group,
  microdistrict_id: microdistrictId,
  page: page.value,
  per_page: perPage.value,
  start_at: date.value[0],
  end_at: date.value[1],
  age_from: ageFrom.value,
  age_to: ageTo.value,
  count: defaultSort.value?.prop === 'count' ? getConvertOrder() : null,
  count_man: defaultSort.value?.prop === 'count_man' ? getConvertOrder() : null,
  count_woman: defaultSort.value?.prop === 'count_woman' ? getConvertOrder() : null,
}));

const statisticsQuery = computed(() => ({
  group,
  microdistrict_id: Number(microdistrictId),
  start_at: date.value[0],
  end_at: date.value[1],
  age_from: ageFrom.value,
  age_to: ageTo.value,
}));

const ageOptions = Array.from({ length: 120 }, (_, i) => {
  return {
    label: i.toString(),
    value: i,
  };
});

const exportDataURL = computed(() => {
  return mergeOrCreateQuery({
    url: Analytics.exportCodesDataURL,
    query: deleteEmptyValueKeys({
      ...query.value,
      lang: I18nService.getLocale(),
      token: TokenService.getAccessToken(),
      device_id: DeviceService.getDeviceId(),
    }),
  });
});

const getConvertOrder = () => {
  if (!!defaultSort.value?.order) {
    return defaultSort.value.order === 'ascending' ? 'ASC' : 'DESC';
  } else {
    return null;
  }
};

const getPatientGroupsDiagnoses = async () => {
  loading.value = true;
  const results = await Analytics.getAnalyticsDiagnoses<AnalyticsCodesDto[]>(query.value);

  if (results?.data) {
    store.dispatch('patientGroupsDiagnoses/setData', {
      items: results.data,
      total: results.meta.total,
      overwriteDataState: true,
    });
  }

  loading.value = false;
};

watch(
  query,
  (value, oldValue) => {
    compareQueriesThenLoadData({
      query: value,
      oldQuery: oldValue,
      getData: getPatientGroupsDiagnoses,
      resetPage: page.reset,
      fieldsForResetPage: ['start_at', 'end_at', 'count', 'count_man', 'count_woman'],
    });
  },
  { deep: true, immediate: true }
);

const goToGroupPatient = (row: AnalyticsCodesDto) => {
  router.push({
    name: PATIENT_GROUP_PATIENTS_ROUTE.name,
    query: {
      date_period: date.value,
      age_from: ageFrom.value,
      age_to: ageTo.value,
    },
    params: {
      disease_code: row.code,
      group,
      microdistrict_id: microdistrictId,
    },
  });
};
</script>

<style lang="scss" src="./index.scss" />

<i18n src="@/locales/analytics.locales.json" />
<i18n src="@/locales/dateAndTime.locales.json" />
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/patients.locales.json" />
