<template>
  <ElCard class="inspection-card" shadow="never" v-loading.fullscreen="loading.template">
    <ElForm
      class="inspection-card-form"
      label-position="top"
      id="inspection-card"
      ref="form"
      @submit.prevent="submitHandler">
      <div class="inspection-card-form__content">
        <UiModelsAutocompleteSearch
          class="inspection-card-form__codes"
          :model-value="appointment.disease_ids"
          :model-for-use="DiseaseCode"
          :models="currentCodesObjects"
          :placeholder="$t('Appointments.SelectIDC')"
          label="title"
          value="code"
          multiple
          :disabled="isDirectorOrManager"
          @select="currentCodesObjects = $event" />

        <template v-if="isThreatment">
          <TemplateResult
            v-if="blocksOptions.oldTemplate.isShow"
            v-model="inspectionCard.basic_data"
            :readonly="blocksOptions.oldTemplate.readonly"
            @block:update="updateInspectionCardBlock">
          </TemplateResult>
        </template>

        <template v-else>
          <ElFormItem v-if="!isDirectorOrManager" :label="$t('Templates.SelectDocument')">
            <TemplateSelect
              @change-select="setTemplate"
              :template="appointment?.inspection_card?.template" />
          </ElFormItem>

          <TinyEditor
            v-model:content="tinyData"
            :disabled="!isSelectedTemplate || isDirectorOrManager" />

          <ElButton
            v-if="!actionsOptions.save.isShow && !isDirectorOrManager"
            class="inspection-card__save-edit"
            type="primary"
            @click="save"
            :disabled="!isSelectedTemplate">
            {{ $t('Base.Save') }}
          </ElButton>
        </template>
      </div>

      <!--  Actions  -->
      <ElFormItem class="inspection-card-form__actions">
        <div class="inspection-card-form-actions">
          <ElButton
            v-show="actionsOptions.provide.isShow"
            type="primary"
            native-type="submit"
            :loading="loading.provide">
            {{ $t('Appointments.EndReception') }}
          </ElButton>

          <ElButton
            v-show="actionsOptions.save.isShow"
            type="primary"
            :loading="loading.save"
            @click="save">
            {{ $t('Base.SaveChanges') }}
          </ElButton>

          <ElButton
            v-show="actionsOptions.setTreatment.isShow"
            type="warning"
            plain
            @click="setTreatment">
            {{ $t('Base.SetTreatment') }}
          </ElButton>

          <ElButton
            v-show="actionsOptions.closeTreatment.isShow"
            type="warning"
            plain
            @click="closeTreatment">
            {{ $t('Treatments.CloseTreatment') }}
          </ElButton>

          <ElDivider direction="vertical" class="inspection-card-form-actions__divider" />

          <ElButton
            v-show="actionsOptions.setControlAppointment.isShow"
            type="primary"
            plain
            @click="setControlAppointment">
            {{ $t('Appointments.SetControlAppointment') }}
          </ElButton>
          <ElButton
            v-show="actionsOptions.setExamination.isShow"
            type="primary"
            plain
            @click="setExamination">
            {{ $t('Appointments.SetExamination') }}
          </ElButton>
        </div>
      </ElFormItem>
    </ElForm>
  </ElCard>
</template>

<script>
// TODO: декомпозировать и отрефакторить, разросся
import { mapState } from 'vuex';
import { Appointment } from '@/models/appointment/Appointment.model';
import { DiseaseCode } from '@/models/DiseaseCode.model';
import { InspectionCard } from '@/models/InspectionCard.model';
import { Treatment } from '@/models/Treatment.model';
import { Card } from '@/models/card/Card.model';
import { User } from '@/models/User.model';
import { getApiErrorMessage } from '@/utils/http.util';

import TemplateResult from '@/components/templates/TemplateResult/index.vue';
import CreateOrEditTreatmentModal from '@/components/treatments/CreateOrEditTreatmentModal/index.vue';
import CreateOrEditAppointmentModal from '@/components/appointments/CreateOrEditAppointmentModal/index.vue';
import TemplateSelect from '@/components/documents/TemplateSelect/Index.vue';
import TinyEditor from '@/components/common/TinyEditor/Index.vue';
import * as icons from '@/enums/icons.enum.js';

export default {
  name: 'InspectionCard',
  components: {
    TinyEditor,
    TemplateSelect,
    TemplateResult,
  },
  emits: ['update:appointment', 'appointment:provide'],
  props: {
    appointment: [Appointment, Object],
    readonly: Boolean,
    isProvided: Boolean,
  },
  data() {
    return {
      card_id: null,
      inspectionCard: this.appointment?.inspection_card ?? null,
      loading: {
        template: false, // получения шаблона
        provide: false,
        closeTreatment: false,
        save: false,
      },
      isSelectedTemplate: false,
      tinyData: '',
      selectedTemplate: null,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),

    currentCodesObjects: {
      get() {
        return this.appointment?.disease_codes_object;
      },
      async set(value) {
        await this.$emit('update:appointment', {
          ...this.appointment,
          disease_codes_object: value,
          disease_ids: value.map((item) => item.code),
        });

        this.updateCodes();
      },
    },

    isThreatment() {
      return this.appointment.treatment !== null;
    },

    blocksOptions() {
      return {
        oldTemplate: {
          isShow: this.inspectionCard.basic_data.length,
          readonly: this.readonly,
        },
      };
    },

    isNewInspectionCard() {
      return !this.appointment.inspection_card;
    },

    actionsOptions() {
      return {
        provide: {
          isShow: !this.isProvided && !this.readonly,
        },
        save: {
          isShow: this.isProvided && !this.readonly && !this.isThreatment,
        },
        setTreatment: {
          isShow: !this.isProvided && !this.readonly && !this.isThreatment,
        },
        closeTreatment: {
          isShow:
            !this.isProvided &&
            !this.readonly &&
            this.appointment.treatment_id &&
            this.appointment.treatment.status === Treatment.enum.statuses.Created &&
            !this.isThreatment,
        },
        setControlAppointment: {
          isShow: !this.isProvided && !this.readonly && !this.isThreatment,
        },
        setExamination: {
          isShow: !this.isProvided && !this.readonly && !this.isThreatment,
        },
      };
    },
    isDirectorOrManager() {
      return (
        this.user.role === User.enum.roles.Director || this.user.role === User.enum.roles.Manager
      );
    },
  },

  methods: {
    async submitHandler() {
      if (this.isThreatment) {
        await this.saveOrCreateInspectionCard();
      }
      this.$emit('appointment:provide', this.isThreatment ? 'treatment' : null);
    },

    async saveOrCreateInspectionCard() {
      this.loading.save = true;

      try {
        const { data } = await InspectionCard.update({
          id: this.inspectionCard.id,
          payload: this.inspectionCard,
          options: {
            url: `b2g/cards/${this.inspectionCard.id}/update/byTreatment`,
          },
        });

        this.$emit('update:appointment', {
          ...this.appointment,
          inspection_card_id: data.id,
          inspection_card: data,
        });
      } catch (err) {
        this.$notify({
          type: 'error',
          title: getApiErrorMessage(err),
        });
      }

      this.loading.save = false;
    },

    updateInspectionCardBlock(block) {
      const index = this.inspectionCard.basic_data.findIndex(
        (elem) => elem.template_id === block.template_id && elem.id === block.id
      );

      this.inspectionCard.basic_data[index] = block;
    },

    setTemplate(data) {
      this.selectedTemplate = data;
      this.isSelectedTemplate = !!data.title;

      if (this.isNewInspectionCard) {
        this.createCard(data);
      } else {
        this.updateCard(data);
      }
    },

    async setTreatment() {
      this.$store.dispatch('modalAndDrawer/openModal', {
        component: CreateOrEditTreatmentModal,
        payload: {
          user: this.appointment.patient,
          appointment: this.appointment,
        },
      });
    },
    async closeTreatment() {
      if (this.loading.closeTreatment) return;
      this.loading.closeTreatment = true;

      try {
        const { data } = await Treatment.close(this.appointment.treatment_id);
        this.$emit('update:appointment', {
          ...this.appointment,
          treatment: data.data,
        });
      } catch (err) {
        this.$notify({
          type: 'error',
          title: getApiErrorMessage(err),
        });
      }

      this.loading.closeTreatment = false;
    },

    async setControlAppointment() {
      this.$store.dispatch('modalAndDrawer/openModal', {
        component: CreateOrEditAppointmentModal,
        payload: {
          disableDefaultAction: true,
          patient: this.appointment.patient,
        },
      });
    },

    async updateCard(data) {
      const response = await Card.update(this.card_id, {
        title: data.title,
        basic_data: data.basic_data,
        inspection_card_template_id: data.id,
      });

      if (response.data) {
        this.title = response.data.data.title;
        this.tinyData = response.data.data.basic_data;
      }
    },

    async setExamination() {
      this.$store.dispatch('modalAndDrawer/openModal', {
        component: CreateOrEditAppointmentModal,
        payload: {
          disableDefaultAction: true,
          patient: this.appointment.patient,
          setDefaultMyDoctor: false,
        },
      });
    },

    async createCard(data) {
      const response = await Card.create({
        user_id: this.appointment.patient.id,
        title: data.title,
        basic_data: data.basic_data,
        appointment_id: this.appointment.id,
        inspection_card_template_id: data.id,
      });

      if (response?.data) {
        this.card_id = response.data.data.id;
        this.tinyData = response.data.data.basic_data;
      }
    },
    async updateCodes() {
      await Appointment.updateAppointmentCodes(this.appointment.id, {
        disease_code_codes: this.appointment.disease_ids,
      });
    },
    async save() {
      if (this.isNewInspectionCard && !this.selectedTemplate?.title) return;

      await Card.update(this.card_id, {
        title: this.selectedTemplate?.title ?? this.appointment?.inspection_card?.title,
        basic_data: this.tinyData,
        inspection_card_template_id:
          this.selectedTemplate?.id ?? this.appointment?.inspection_card?.template.id,
      });
    },
  },
  mounted() {
    if (!this.isNewInspectionCard) {
      this.isSelectedTemplate = true;
      this.tinyData = this.appointment?.inspection_card?.basic_data;
      this.card_id = this.appointment?.inspection_card?.id;
    }
  },

  setup: () => {
    return {
      InspectionCard,
      DiseaseCode,
      icons,
    };
  },
};
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/appointments.locales.json" />
<i18n src="@/locales/notifications.locales.json" />
<i18n src="@/locales/templates.locales.json" />
<i18n src="@/locales/treatments.locales.json" />
<i18n src="@/locales/dateAndTime.locales.json" />
<i18n src="./index.locales.json" />
