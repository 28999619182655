import {
  ActionContext,
  CommitOptions,
  DispatchOptions,
  GetterTree,
  Store as VuexStore,
} from 'vuex';
import { RootStateType } from '@/store';
import { ClientStorageService } from '@/services/clientStorage.service';
import { Appointment } from '@/models/appointment/Appointment.model';

export type UserState = {
  userPayload: {
    active_appointment: Appointment | null;
    has_active_appointment: boolean;
  };
};

type ContextType = ActionContext<UserState, RootStateType>;

type Mutations = {
  ['user/SET_ACTIVE_APPOINTMENT'](state: UserState, payload: Appointment): void;
  ['user/CLOSE_ACTIVE_APPOINTMENT'](state: UserState): void;
};

type ActionAugments = Omit<ActionContext<UserState, RootStateType>, 'commit'> & {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
};

export type Actions = {
  ['user/setActiveAppointment'](context: ActionAugments, payload: Appointment): void;
  ['user/closeActiveAppointment'](context: ActionAugments): void;
  ['user/getDataFromStorage'](context: ActionAugments): void;
};

export default {
  name: 'user',
  namespaced: true,
  state: () => {
    return {
      userPayload: {
        active_appointment: null,
        has_active_appointment: false,
      },
    };
  },
  getters: {
    user(state: UserState, _: GetterTree<UserState, RootStateType>, rootState: RootStateType) {
      return { ...rootState.auth.user, ...state.userPayload };
    },
  },
  mutations: {
    SET_ACTIVE_APPOINTMENT(state: UserState, payload: Appointment) {
      state.userPayload.active_appointment = payload;
      state.userPayload.has_active_appointment = true;

      ClientStorageService.setItem('ACTIVE_APPOINTMENT', payload);
      ClientStorageService.setItem('HAS_ACTIVE_APPOINTMENT', '1');
    },
    CLOSE_ACTIVE_APPOINTMENT(state: UserState) {
      state.userPayload.active_appointment = null;
      state.userPayload.has_active_appointment = false;

      ClientStorageService.removeItem('ACTIVE_APPOINTMENT');
      ClientStorageService.removeItem('HAS_ACTIVE_APPOINTMENT');
    },
  },
  actions: {
    setActiveAppointment({ commit }: ContextType, payload: Appointment) {
      commit('SET_ACTIVE_APPOINTMENT', payload);
    },
    closeActiveAppointment({ commit }: ContextType) {
      commit('CLOSE_ACTIVE_APPOINTMENT');
    },
    getDataFromStorage({ dispatch }: ContextType) {
      const hasActiveAppointment = ClientStorageService.getItem('HAS_ACTIVE_APPOINTMENT');
      if (hasActiveAppointment) {
        dispatch('setActiveAppointment', ClientStorageService.getItem('ACTIVE_APPOINTMENT'));
      }
    },
  },
};

// Типизация стора чтобы в файлах .vue работала типизация модуля
export type UserStore<S = UserState> = Omit<VuexStore<S>, 'getters' | 'commit' | 'dispatch'> & {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
    key: K,
    payload: P,
    options?: CommitOptions
  ): ReturnType<Mutations[K]>;
} & {
  dispatch<K extends keyof Actions>(
    key: K,
    payload?: Parameters<Actions[K]>[1],
    options?: DispatchOptions
  ): ReturnType<Actions[K]>;
};
