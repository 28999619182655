<template>
  <UiTableWithPagination
    :data="items"
    :loading="loading"
    :page="props.page"
    :per-page="props.perPage"
    :total="total"
    @update:page="$emit('update:page', $event)"
    @update:per-page="$emit('update:perPage', $event)"
    :default-sort="defaultSort || {}"
    @sort-change="onSortChange">
    <ElTableColumn prop="title" :label="$t('Analytics.Analysis')" />
    <ElTableColumn prop="count" :label="$t('Analytics.Quantity')" sortable="custom" />
    <ElTableColumn prop="percent" :label="$t('Analytics.Percent')" sortable="custom">
      <template #default="{ row }">
        {{ row.percent ? `${row.percent}%` : '' }}
      </template>
    </ElTableColumn>
    <ElTableColumn
      prop="percent_returned"
      :label="$t('Analytics.Retakes')"
      min-width="100px"
      sortable="custom">
      <template #default="{ row }">
        {{ row.percent_returned ? `${row.percent_returned}%` : '' }}
      </template>
    </ElTableColumn>
    <ElTableColumn prop="man" :label="$t('Analytics.Men')" sortable="custom" />
    <ElTableColumn prop="woman" :label="$t('Analytics.Women')" sortable="custom" />
    <ElTableColumn prop="child" :label="$t('Analytics.Children')" sortable="custom" />
  </UiTableWithPagination>
</template>

<script lang="ts">
export default {
  name: 'AnalyticsAnalysisTable',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { SortType } from '@/types/common';
import { AnalyticsAnalysisShortDto } from '@/types/api';

import UiTableWithPagination from '@/components/ui/UiTableWithPagination/index.vue';

const props = defineProps<{
  loading: boolean;
  items: AnalyticsAnalysisShortDto[];
  total: number;
  page: number;
  perPage: number;
  defaultSort: SortType | null;
}>();

const emit = defineEmits(['update:perPage', 'update:page', 'sort-change']);

const onSortChange = ({ prop, order }: SortType) => {
  emit('sort-change', { prop, order });
};
</script>
