import { onlyLoggedInMiddleware } from '@/middlewares/onlyLoggedIn.middleware';
import { byUserRolesMiddleware } from '@/middlewares/byUserRoles.middleware';
import { User } from '@/models/User.model';

export const CASH_ROUTE = {
  name: 'CASH',
  path: '/cash',
  component: 'VCash',
  beforeEnter: [
    onlyLoggedInMiddleware,
    byUserRolesMiddleware([
      User.enum.roles.Manager,
      User.enum.roles.Director,
      User.enum.roles.Doctor,
    ]),
  ],
  meta: {
    title: 'Base.CashRegister',
  },
};

export const routes = [CASH_ROUTE];
