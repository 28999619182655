import { I18nService } from '@/services/i18n.service';
import { array, object, string } from 'yup';
import { computed } from 'vue';

export const validationSchema = computed(() => {
  return object().shape({
    disease_codes: array().min(1, I18nService.t('Validation.RequiredField')),
    died_at: string().required(I18nService.t('Validation.RequiredField')),
  });
});
