import { ApiService } from '@/services/api.service';
import { ElNotification } from 'element-plus';
import { QueryParams } from '@/types/common';
import { getApiErrorMessage, mergeOrCreateQuery } from '@/utils/http.util';
import { deleteEmptyValueKeys } from '@/utils/object.util';
import { DisabilityGroup, StatisticsCommonData } from '@/types/api';

type ResponseDataDto = {
  data: StatisticsCommonData;
};

export class Statistics {
  static tableName = 'statistics';

  static async getGroupCount(query: QueryParams) {
    try {
      const response = await ApiService.get<ResponseDataDto>(
        mergeOrCreateQuery({
          url: `b2g/${this.tableName}/group/count`,
          query: deleteEmptyValueKeys(query),
        })
      );

      return { response, data: response.data.data };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: getApiErrorMessage(err),
      });
    }
  }

  static async getGroupAppointment(query: QueryParams) {
    try {
      const response = await ApiService.get<ResponseDataDto>(
        mergeOrCreateQuery({
          url: `b2g/${this.tableName}/group/appointment`,
          query: deleteEmptyValueKeys(query),
        })
      );

      return { response, data: response.data.data };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: getApiErrorMessage(err),
      });
    }
  }

  static async getGroupIncome(query: QueryParams) {
    try {
      const response = await ApiService.get<ResponseDataDto>(
        mergeOrCreateQuery({
          url: `b2g/${this.tableName}/group/income`,
          query: deleteEmptyValueKeys(query),
        })
      );

      return { response, data: response.data.data };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: getApiErrorMessage(err),
      });
    }
  }

  static async getGroupOutcome(query: QueryParams) {
    try {
      const response = await ApiService.get<ResponseDataDto>(
        mergeOrCreateQuery({
          url: `b2g/${this.tableName}/group/outcome`,
          query: deleteEmptyValueKeys(query),
        })
      );

      return { response, data: response.data.data };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: getApiErrorMessage(err),
      });
    }
  }

  static async getPregnancyCount(query: QueryParams) {
    try {
      const response = await ApiService.get<ResponseDataDto>(
        mergeOrCreateQuery({
          url: `b2g/${this.tableName}/pregnancy/count`,
          query: deleteEmptyValueKeys(query),
        })
      );

      return { response, data: response.data.data };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: getApiErrorMessage(err),
      });
    }
  }

  static async getPregnancySuccessCount(query: QueryParams) {
    try {
      const response = await ApiService.get<ResponseDataDto>(
        mergeOrCreateQuery({
          url: `b2g/${this.tableName}/pregnancy/success`,
          query: deleteEmptyValueKeys(query),
        })
      );

      return { response, data: response.data.data };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: getApiErrorMessage(err),
      });
    }
  }

  static async getAbortsCount(query: QueryParams) {
    try {
      const response = await ApiService.get<ResponseDataDto>(
        mergeOrCreateQuery({
          url: `b2g/${this.tableName}/pregnancy/aborts`,
          query: deleteEmptyValueKeys(query),
        })
      );

      return { response, data: response.data.data };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: getApiErrorMessage(err),
      });
    }
  }

  static async getMisbirthsCount(query: QueryParams) {
    try {
      const response = await ApiService.get<ResponseDataDto>(
        mergeOrCreateQuery({
          url: `b2g/${this.tableName}/pregnancy/misbirths`,
          query: deleteEmptyValueKeys(query),
        })
      );

      return { response, data: response.data.data };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: getApiErrorMessage(err),
      });
    }
  }

  static async getDispensaryCount(query: QueryParams) {
    try {
      const response = await ApiService.get<ResponseDataDto>(
        mergeOrCreateQuery({
          url: `b2g/${this.tableName}/dispensary/count`,
          query: deleteEmptyValueKeys(query),
        })
      );

      return { response, data: response.data.data };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: getApiErrorMessage(err),
      });
    }
  }

  static async getDispensaryRegistration(query: QueryParams) {
    try {
      const response = await ApiService.get<ResponseDataDto>(
        mergeOrCreateQuery({
          url: `b2g/${this.tableName}/dispensary/registration`,
          query: deleteEmptyValueKeys(query),
        })
      );

      return { response, data: response.data.data };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: getApiErrorMessage(err),
      });
    }
  }

  static async getDispensaryDeregistration(query: QueryParams) {
    try {
      const response = await ApiService.get<ResponseDataDto>(
        mergeOrCreateQuery({
          url: `b2g/${this.tableName}/dispensary/deregistration`,
          query: deleteEmptyValueKeys(query),
        })
      );

      return { response, data: response.data.data };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: getApiErrorMessage(err),
      });
    }
  }

  static async getDisabilitiesByGroup(group: DisabilityGroup, query: QueryParams) {
    try {
      const response = await ApiService.get<ResponseDataDto>(
        mergeOrCreateQuery({
          url: `b2g/${this.tableName}/disabilities/${group}`,
          query: deleteEmptyValueKeys(query),
        })
      );

      return { response, data: response.data.data };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: getApiErrorMessage(err),
      });
    }
  }

  static async getDisabilitiesChange(query: QueryParams) {
    try {
      const response = await ApiService.get<ResponseDataDto>(
        mergeOrCreateQuery({
          url: `b2g/${this.tableName}/disabilities/group/change`,
          query: deleteEmptyValueKeys(query),
        })
      );

      return { response, data: response.data.data };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: getApiErrorMessage(err),
      });
    }
  }

  static async getDeadByGender(gender: 'man' | 'woman', query: QueryParams) {
    try {
      const response = await ApiService.get<ResponseDataDto>(
        mergeOrCreateQuery({
          url: `b2g/${this.tableName}/dead/${gender}`,
          query: deleteEmptyValueKeys(query),
        })
      );

      return { response, data: response.data.data };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: getApiErrorMessage(err),
      });
    }
  }

  static async getBornByGender(gender: 'man' | 'woman', query: QueryParams) {
    try {
      const response = await ApiService.get<ResponseDataDto>(
        mergeOrCreateQuery({
          url: `b2g/${this.tableName}/born/${gender}`,
          query: deleteEmptyValueKeys(query),
        })
      );

      return { response, data: response.data.data };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: getApiErrorMessage(err),
      });
    }
  }

  static async getVaccinated(query: QueryParams) {
    try {
      const response = await ApiService.get<ResponseDataDto>(
        mergeOrCreateQuery({
          url: `b2g/${this.tableName}/vaccines/exists`,
          query: deleteEmptyValueKeys(query),
        })
      );

      return { response, data: response.data.data };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: getApiErrorMessage(err),
      });
    }
  }

  static async getNotVaccinated(query: QueryParams) {
    try {
      const response = await ApiService.get<ResponseDataDto>(
        mergeOrCreateQuery({
          url: `b2g/${this.tableName}/vaccines/doesnt_exists`,
          query: deleteEmptyValueKeys(query),
        })
      );

      return { response, data: response.data.data };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: getApiErrorMessage(err),
      });
    }
  }
}
