﻿{
  "en": {
    "Patronage": {
      "AddDocument": "Add document",
      "Address": "Address",
      "Anamnesis": "Anamnesis",
      "CapacityHint": "This is the maximum number of patients that your medical team can examine.",
      "CoordsVisit": "Visiting coordinates",
      "DateLastVisit": "Last visit date",
      "DateVisit": "Appointment date",
      "DocumentPlaceholder": "Choose the form you want to fill out",
      "Documents": "Documents",
      "ExaminationPatient": "Patient examination",
      "FIOPatient": "Patient's full name",
      "FeedbackTitle": "Patient feedback about the nurse",
      "FillDocument": "Fill",
      "HealthStatus": "State",
      "Height": "Heigh",
      "MedicalCard": "MedicalCard",
      "Notes": "Notes",
      "Nurse": "Nurse",
      "Patronage": "Patronage",
      "PatronagePlanning": "Patronage planning",
      "PhoneNumber": "Phone number",
      "Pulse": "Pulse",
      "ReasonVisit": "Reason for patient visit",
      "SelectDocument": "Select document",
      "StatusVisit": "Appointment status",
      "Statuses": {
        "bypassed": "Examined",
        "canceled": "Canceled",
        "failed": "Canceled",
        "in_progress": "In progress",
        "on_the_way": "On the way",
        "online_appointment": "Online",
        "payed": "Paid",
        "waiting": "Pending"
      },
      "Temperature": "Temperature",
      "TimeVisit": "Appointment time",
      "Title": "Patronage",
      "TypeVisit": "Appointment type",
      "Weight": "Weight",
      "offline": "Offline",
      "online": "Online"
    },
    "PatronageActions": {
      "FilterByPatientCondition": "Filter by patient condition",
      "OnlySelectedPatients": "Only selected patients",
      "PatientSearch": "Patient search",
      "Patients": "Patients",
      "SelectPatients": "Select patients"
    }
  },
  "ru": {
    "Patronage": {
      "AddDocument": "Добавить документ",
      "Address": "Адрес",
      "Anamnesis": "Анамнез",
      "CapacityHint": "Это максимальное кол-во пациентов, которое ваша бригада может осмотреть",
      "CoordsVisit": "Координаты посещения",
      "DateLastVisit": "Дата п. посещ.",
      "DateVisit": "Дата приема",
      "DocumentPlaceholder": "Выберите форму которую хотите заполнить",
      "Documents": "Документы",
      "ExaminationPatient": "Осмотр пациента",
      "FIOPatient": "Ф.И.О. пациента",
      "FeedbackTitle": "Отзывы пациента о медсестре",
      "FillDocument": "Заполнить",
      "HealthStatus": "Состояние",
      "Height": "Рост",
      "MedicalCard": "Мед карта",
      "Notes": "Заметки",
      "Nurse": "Медсестра",
      "Patronage": "Патронаж",
      "PatronagePlanning": "Планирование патронажа",
      "PhoneNumber": "Номер телефона",
      "Pulse": "Пульс",
      "ReasonVisit": "Причина посещения пациента",
      "SelectDocument": "Выберите документ",
      "StatusVisit": "Статус приема",
      "Statuses": {
        "bypassed": "Обошли",
        "failed": "Отменен",
        "in_progress": "В процессе",
        "on_the_way": "В пути",
        "online_appointment": "Онлайн",
        "waiting": "В ожидании"
      },
      "Temperature": "Температура",
      "TimeVisit": "Время приема",
      "Title": "Патронаж",
      "TypeVisit": "Тип приема",
      "Weight": "Вес",
      "offline": "Офлайн",
      "online": "Онлайн"
    },
    "PatronageActions": {
      "FilterByPatientCondition": "Фильтр по состоянию пациента",
      "OnlySelectedPatients": "Только выбранные пациенты",
      "PatientSearch": "Поиск пациента",
      "Patients": "пациентов",
      "SelectPatients": "Выбрать пациентов"
    }
  },
  "uz": {
    "Patronage": {
      "AddDocument": "Hujjatni qo'shish",
      "Address": "Manzil",
      "Anamnesis": "Anamnez",
      "CapacityHint": "Bu sizning brigadangiz ko'rishi mumkin bo'lgan maksimal bemorlar soni",
      "CoordsVisit": "Tashrif koordinatlari",
      "DateLastVisit": "Oxirgi tashrif sanasi",
      "DateVisit": "Qabul sanasi",
      "DocumentPlaceholder": "To'ldirishingiz kerak bo'lgan  formani tanlang",
      "Documents": "Bemor",
      "ExaminationPatient": "Bemorni ko'rikdan o'tkazish",
      "FIOPatient": "Bemorning IFSH",
      "FeedbackTitle": "Hamshira haqida bemorning fikri",
      "FillDocument": "To'ldirish",
      "HealthStatus": "Holati",
      "Height": "Bo'yi",
      "MedicalCard": "Tibbiy karta",
      "Notes": "Eslatmalar",
      "Nurse": "Hamshira",
      "Patronage": "Patronaj",
      "PatronagePlanning": "Patronajni rejalashtirish",
      "PhoneNumber": "Telefon raqami",
      "Pulse": "Pulsi",
      "ReasonVisit": "Tashrif sababi",
      "SelectDocument": "Hujjatni tanlang",
      "StatusVisit": "Qabul statusi",
      "Statuses": {
        "bypassed": "Ko'rib chiqildi",
        "failed": "Bekor qilindi",
        "in_progress": "Jarayonda",
        "on_the_way": "Yo'lda",
        "online_appointment": "Onlayn",
        "waiting": "Kutilmoqda"
      },
      "Temperature": "Temperatura",
      "TimeVisit": "Qabul vaqti",
      "Title": "Patronaj",
      "TypeVisit": "Qabul turi",
      "Weight": "Vazni",
      "offline": "Oflayn",
      "online": "Onlayn"
    },
    "PatronageActions": {
      "FilterByPatientCondition": "Bemorning ahvoliga qarab filtr",
      "OnlySelectedPatients": "Faqat tanlangan bemorlar",
      "PatientSearch": "Bemorni qidirish",
      "Patients": "Bemorlar",
      "SelectPatients": "Bemorlarni tanlash"
    }
  }
}
