<template>
  <div class="employee-form">
    <FieldGroupWrapper :title="$t('User.EmployeeInfo')">
      <div class="employee-form-info">
        <FormTextField
          :label="$t('User.FIOEmployee')"
          field-name="name"
          :placeholder="$t('User.FIOEmployee')"
          maxlength="255" />
        <FormSelectField
          :label="$t('User.Role')"
          :items="userRoles"
          field-name="role"
          field-object-name="roleObject"
          :placeholder="$t('Outcome.OutcomeType')"
          value-key="value"
          label-key="label" />
      </div>
      <FormSearchSelectField
        :label="$t('User.Speciality')"
        :placeholder="$t('User.Speciality')"
        multiple
        field-name="doctor.speciality_ids"
        field-object-name="doctor.specialties"
        :model-for-use="Specialty" />
      <div class="employee-form-info">
        <FormTextField
          :label="$t('User.Birthdate')"
          field-name="birthdate"
          :placeholder="$t('User.FormatDate')"
          mask-name="int-12" />
        <FormTextField
          :label="$t('User.PhoneNumber')"
          field-name="phone"
          placeholder="+ 998 ХХ ХХХ - ХХ - ХХ"
          mask-name="int-12" />
        <FormTextField
          :label="$t('User.Address')"
          field-name="address"
          :placeholder="$t('User.Address')"
          maxlength="255" />
      </div>
      <div class="employee-form-info">
        <FormTextField
          :label="$t('User.EmployeeSalary')"
          field-name="salary"
          :placeholder="$t('User.EmployeeSalary')"
          mask-name="int-12" />
        <FormTextField
          :label="$t('User.ConsultationFee')"
          field-name="doctor_recommendation_commission"
          placeholder="%"
          mask-name="int-12" />
        <FormTextField
          :label="$t('User.AppointmentFee')"
          field-name="appointment_commission"
          placeholder="%"
          mask-name="int-12" />
      </div>
      <!--      <FormTextField-->
      <!--        :label="$t('User.WorkingHours')"-->
      <!--        field-name="short_working_time"-->
      <!--        :placeholder="$t('User.WorkingHours')"-->
      <!--        maxlength="255" />-->
      <div class="employee-form-footer">
        <div class="employee-form-footer-title">{{ $t('User.StatusActivityEmployee') }}</div>
        <div class="employee-form-footer-actions">
          <ElButton size="small" type="primary" plain>{{ $t('User.Statuses.active') }}</ElButton>
          <ElButton size="small" type="danger" plain>
            {{ $t('User.Statuses.not_active') }}</ElButton
          >
        </div>
        <div class="employee-form-footer-text">
          {{ $t('User.EmployeeActivityInfo') }}
        </div>
      </div>
    </FieldGroupWrapper>
    <FormActionsWrapper>
      <ElButton type="primary" @click="submitForm">{{ `+ ${$t('User.AddUser')}` }}</ElButton>
    </FormActionsWrapper>
  </div>
</template>

<script lang="ts">
export default {
  name: 'EmployeeForm',
  inheritAttrs: false,
  customOptions: {},
};
</script>
<script setup lang="ts">
import { EmployeeFormData } from '@/models/User.model';
import { useForm } from 'vee-validate';
import { Specialty } from '@/models/Specialty.model';
import { I18nService } from '@/services/i18n.service';

import FieldGroupWrapper from '@/components/common/form/FieldGroupWrapper/index.vue';
import FormActionsWrapper from '@/components/common/form/FormActionsWrapper/index.vue';
import FormSearchSelectField from '@/components/common/form/ui/FormSearchSelectField/index.vue';
import FormTextField from '@/components/common/form/ui/FormTextField/index.vue';
import FormSelectField from '@/components/common/form/ui/FormSelectField/index.vue';

const props = defineProps<{
  formData: EmployeeFormData;
}>();

const { handleSubmit } = useForm({
  initialValues: props.formData,
  // validationSchema,
  validateOnMount: false,
});

const userRoles: { value: EmployeeFormData['role']; label: string }[] = [
  { value: 'accountant', label: I18nService.t('User.Roles.accountant') },
  { value: 'doctor', label: I18nService.t('User.Roles.doctor') },
  { value: 'manager', label: I18nService.t('User.Roles.manager') },
  { value: 'director', label: I18nService.t('User.Roles.director') },
  { value: 'laboratory', label: I18nService.t('User.Roles.laboratory') },
  { value: 'warehouse', label: I18nService.t('User.Roles.warehouse') },
  { value: 'nurse', label: I18nService.t('User.Roles.nurse') },
];

const submitForm = () => {
  handleSubmit((values) => {
    // not implemented
    // eslint-disable-next-line no-console
    console.log('values handleSubmit', values);
  })();
};
</script>

<style lang="scss" src="./index.scss" scoped />
<i18n src="@/locales/user.locales.json" />
