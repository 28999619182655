import { byUserRolesMiddleware } from '@/middlewares/byUserRoles.middleware';
import { onlyLoggedInMiddleware } from '@/middlewares/onlyLoggedIn.middleware';
import { User } from '@/models/User.model';

export const PREGNANT_PATH_PREFIX = '/pregnant';

export const PREGNANT_ROUTE = {
  name: 'PREGNANT',
  path: PREGNANT_PATH_PREFIX,
  component: 'VPregnant',
  beforeEnter: [
    onlyLoggedInMiddleware,
    byUserRolesMiddleware([User.enum.roles.Doctor, User.enum.roles.Director]),
  ],
  props: true,
  meta: {
    title: 'Patients.Pregnants',
  },
};

export const PREGNANT_MICRODISTRICT_ROUTE = {
  name: 'PREGNANT_MICRODISTRICT',
  path: `${PREGNANT_PATH_PREFIX}/:microdistrict_id`,
  component: 'VPregnantMicrodistrict',
  beforeEnter: [
    onlyLoggedInMiddleware,
    byUserRolesMiddleware([User.enum.roles.Doctor, User.enum.roles.Director]),
  ],
  props: true,
  meta: {
    title: 'Patients.Pregnants',
  },
};

export const PREGNANT_BY_GROUP_ROUTE = {
  name: 'VPregnantsByGroup',
  path: `${PREGNANT_PATH_PREFIX}/group`,
  component: 'VPregnantsByGroup',
  beforeEnter: [
    onlyLoggedInMiddleware,
    byUserRolesMiddleware([User.enum.roles.Doctor, User.enum.roles.Director]),
  ],
  props: true,
  meta: {
    title: 'Patients.Pregnants',
  },
};

export const routes = [PREGNANT_ROUTE, PREGNANT_MICRODISTRICT_ROUTE, PREGNANT_BY_GROUP_ROUTE];
