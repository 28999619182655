<template>
  <LayoutByUserRole content-class="v-analyzes">
    <LayoutContentHeader>
      <form @submit.prevent="getThrottleDataByInterval">
        <ElInput v-model="search.value">
          <template #append>
            <ElButton native-type="submit">
              {{ $t('Base.Search') }}
            </ElButton>
          </template>
        </ElInput>
      </form>

      <template #actions>
        <ElButton type="primary" @click="createAnalysis">
          {{ $t('Laboratory.Analysis.CreateAnalysis') }}
        </ElButton>
      </template>
    </LayoutContentHeader>

    <AnalyzesTable
      :items="items"
      :loading="loading"
      :total="total"
      v-model:page="page.value"
      v-model:per-page="perPage.value"
      @item:update="editItem"
      @item:delete="deleteItem" />
  </LayoutByUserRole>
</template>

<script>
import axios from 'axios';
import { mapActions, mapState } from 'vuex';
import { usePage, usePerPage } from '@/hooks/query';
import { useQuery } from '@/hooks/useQuery.hook';
import { useGetDataByInterval } from '@/hooks/useGetDataByInterval.hook';
import throttle from 'lodash.throttle';
import { Analysis } from '@/models/laboratory/Analysis.model';
import { GlobalModalCloseAction } from '@/models/client/ModalAndDrawer/GlobalModalCloseAction';
import noop from 'lodash.noop';

import LayoutByUserRole from '@/components/layouts/LayoutByUserRole/index.vue';
import LayoutContentHeader from '@/components/layouts/assets/LayoutContentHeader/index.vue';
import AnalyzesTable from '@/components/laboratory/analysis/AnalyzesTable/index.vue';
import CreateOrEditAnalysisModal from '@/components/laboratory/analysis/CreateOrEditAnalysisModal/index.vue';

export default {
  name: 'VAnalyzes',
  components: { AnalyzesTable, LayoutContentHeader, LayoutByUserRole },
  setup: () => ({
    page: usePage(),
    perPage: usePerPage(),
    search: useQuery({
      field: 'title',
    }),

    getThrottleDataByInterval: noop,
  }),
  computed: {
    ...mapState({
      items: (state) => state.analyzes.data,
      total: (state) => state.analyzes.total,
      loading: (state) => state.analyzes.loading,
    }),

    queryWatchers() {
      return {
        page: this.page.value,
        per_page: this.perPage.value,
        query_type: 'ILIKE',
        query_field: ['title'],
        query_operator: 'OR',
        search: this.search.value,
      };
    },
  },
  watch: {
    queryWatchers: {
      handler() {
        this.getThrottleDataByInterval();
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    ...mapActions({
      setLoading: 'analyzes/setLoading',
      setData: 'analyzes/setData',
      createItem: 'analyzes/createItem',
      editItem: 'analyzes/editItem',
      deleteItem: 'analyzes/deleteItem',
    }),

    async getAnalyzes() {
      this.setLoading(true);

      try {
        const { data } = await Analysis.find(this.queryWatchers);
        this.setData({
          items: data.data,
          total: data.meta.total,
          overwriteDataState: true,
        });
      } catch (err) {
        this.$notify({
          type: 'error',
          title: axios.isAxiosError(err) ? err.message : String(err),
        });
      }

      this.setLoading(false);
    },

    async createAnalysis() {
      const action = await this.$store.dispatch(
        'modalAndDrawer/openModal',
        CreateOrEditAnalysisModal
      );
      if (!(action instanceof GlobalModalCloseAction)) this.createItem(action.data.analysis);
    },
  },

  mounted() {
    this.getThrottleDataByInterval = throttle(useGetDataByInterval(this.getAnalyzes), 300);
    this.getThrottleDataByInterval();
  },
  beforeUnmount() {
    this.getThrottleDataByInterval.cancel();
  },
};
</script>

<style lang="scss" src="./index.scss"></style>
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/notifications.locales.json" />
<i18n src="@/locales/laboratory.locales.json" />
