<template>
  <div class="main-costs">
    <div class="main-costs-wrap">
      <div>{{ $t('Calculation.OptimalCostService') }}</div>
      <Tooltip :description="$t('Services.Tooltip.FieldsChanged')" />
    </div>
    <div class="main-costs-inputs">
      <FormInfoField
        field-name="market_calculations.average_market_price"
        :label="$t('Calculation.AverageMarketPrice')"
        format="price"
        :description="$t('Calculation.AmountSold2')" />
      <FormInfoField
        field-name="market_calculations.market_demand"
        :label="$t('Calculation.MarketDemand')"
        :description="$t('Calculation.BuyCertainPrice')" />
      <FormInfoField
        field-name="market_calculations.optimal_margin"
        :label="$t('Calculation.OptimalMarkup')"
        :description="$t('Calculation.MarkupIncome')" />
      <FormInfoField
        field-name="market_calculations.optimal_price"
        :label="$t('Calculation.OptimalPrice')"
        format="price"
        :description="$t('Calculation.MaximumProfit')" />
    </div>
    <div class="main-costs-content">
      <div class="main-costs-content-wrap">
        <div class="main-costs-content-text">
          {{ $t('Calculation.SimilarServicesClinics') }}
          <div class="main-costs-content-number">
            {{ formatPrice({ price: props.calculationData?.market_price_range_from }) }}
            {{ $t('Base.Sum') }}
          </div>
          -
          <div class="main-costs-content-number">
            {{ formatPrice({ price: props.calculationData?.market_price_range_to }) }}
            {{ $t('Base.Sum') }}
          </div>
        </div>
      </div>
      <div class="main-costs-content-info">
        <div class="main-costs-content-info-content">
          <div class="main-costs-content-info-content-wrapper">
            <UiIcon class="main-costs-content-info-content-wrapper-icon" :icon="icons.DOLLAR" />
          </div>
          <div class="main-costs-content-info-text">{{ $t('Calculation.AverageMarketPrice') }}</div>
          - {{ $t('Calculation.AmountSold') }}
        </div>
      </div>
      <div class="main-costs-content-info">
        <div class="main-costs-content-info-content">
          <div class="main-costs-content-info-content-wrapper">
            <UiIcon class="main-costs-content-info-content-wrapper-icon" :icon="icons.D" />
          </div>
          <div class="main-costs-content-info-text">{{ $t('Calculation.MarketDemand') }}</div>
          - {{ $t('Calculation.BuyCertainPrice') }}
        </div>
      </div>
      <div class="main-costs-content-info">
        <div class="main-costs-content-info-content">
          <div class="main-costs-content-info-content-wrapper">
            <UiIcon class="main-costs-content-info-content-wrapper-icon" :icon="icons.ARROW" />
          </div>
          <div class="main-costs-content-info-text">{{ $t('Calculation.OptimalMarkup') }}</div>
          - {{ $t('Calculation.MarkupIncome') }}
        </div>
      </div>
      <div class="main-costs-content-info">
        <div class="main-costs-content-info-content">
          <div class="main-costs-content-info-content-wrapper">
            <UiIcon class="main-costs-content-info-content-wrapper-icon" :icon="icons.V" />
          </div>
          <div class="main-costs-content-info-text">{{ $t('Calculation.OptimalPrice') }}</div>
          - {{ $t('Calculation.MaximumProfit') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: 'CalculationOptimalCost',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import Tooltip from '@/components/common/Tooltip/index.vue';
import { MarketCalculation } from '@/types/api';
import { formatPrice } from '@/utils/price.util';

import FormInfoField from '@/components/common/form/ui/FormInfoField/index.vue';
import * as icons from '@/enums/icons.enum';

const props = defineProps<{
  calculationData?: MarketCalculation | null;
}>();
</script>

<style lang="scss" src="./index.scss" />

<i18n src="@/locales/accounting.locales.json" />
<i18n src="@/locales/base.locales.json" />
