<template>
  <UiTableWithPagination
    :data="items"
    :loading="loading"
    :page="page"
    :per-page="perPage"
    :total="total"
    @row-click="goToWarehouse"
    @update:page="$emit('update:page', $event)"
    @update:per-page="$emit('update:perPage', $event)">
    <ElTableColumn prop="title" :label="$t('Warehouse.Warehouse')" />
    <ElTableColumn prop="address" :label="$t('Warehouse.Address')" />
    <ElTableColumn prop="description" :label="$t('Nomenclature.Notes')" />
    <ElTableColumn :label="$t('Nomenclature.Actions')" width="150px">
      <template #default="{ row }">
        <ElButton size="small" type="primary" plain @click.stop="$emit('modal:open', row)"
          >{{ $t('Nomenclature.Edit') }}
        </ElButton>
      </template>
    </ElTableColumn>
    <ElTableColumn width="150px">
      <ElButton type="primary" text>
        <template #icon>
          <UiIcon :icon="icons.EYE" />
        </template>
        {{ $t('Base.View') }}
      </ElButton>
    </ElTableColumn>
  </UiTableWithPagination>
</template>
<script lang="ts">
export default {
  name: 'WarehousesTable',
  inheritAttrs: false,
  customOptions: {},
};
</script>
<script setup lang="ts">
import { useRouter } from 'vue-router';
import { WarehouseDto } from '@/types/api';

import * as icons from '@/enums/icons.enum.js';

import UiTableWithPagination from '@/components/ui/UiTableWithPagination/index.vue';

defineEmits(['update:page', 'update:perPage', 'modal:open']);

type Props = {
  loading: boolean;
  items: WarehouseDto[];
  page: number;
  perPage: number;
  total: number;
};

defineProps<Props>();

const router = useRouter();
const goToWarehouse = (warehouse: WarehouseDto) => {
  router.push(`/warehouse/?warehouse_name=${warehouse.title}&warehouse_id=${warehouse.id}`);
};
</script>

<style lang="scss" scoped></style>
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/warehouse.locales.json" />
