<template>
  <LayoutByUserRole content-class="v-user-content" fix-height>
    <ElBreadcrumb separator="/">
      <ElBreadcrumbItem :to="{ path: `${USERS_PATH_PREFIX}/employees` }"
        >{{ $t('User.Employees') }}
      </ElBreadcrumbItem>
      <ElBreadcrumbItem>{{ user.name ?? '' }}</ElBreadcrumbItem>
    </ElBreadcrumb>

    <!--    <PatientCardRow :patient="user" :items="userItems" content-grid />-->

    <PersonCard :person="user" :items="userItems" content-grid>
      <template #actions>
        <ElButton type="primary" @click="onClickPersonModal"
          >{{ $t('Accounting.DetailedInformation') }}
        </ElButton>
      </template>
    </PersonCard>

    <div class="v-user-content__btns">
      <router-link :to="deviceLink">
        <ElButton type="primary" plain>
          <template #icon>
            <UiIcon :icon="icons.DEVICE" />
          </template>
          {{ $t('User.Devices') }}
        </ElButton>
      </router-link>

      <router-link :to="salaryLink">
        <ElButton type="primary">{{ $t('User.Accounting') }}</ElButton>
      </router-link>
    </div>
    <RouterView />
  </LayoutByUserRole>
</template>

<script>
import axios from 'axios';
import { mapState, useStore } from 'vuex';
import { User } from '@/models/User.model';
import { insertRouteParams } from '@/utils/router.utils';
import { USERS_PATH_PREFIX, USER_ROUTE } from '@/router/users.routes';
import { usePerPage, usePage } from '@/hooks/query';
import noop from 'lodash.noop';

import LayoutByUserRole from '@/components/layouts/LayoutByUserRole/index.vue';
import * as icons from '@/enums/icons.enum';
import PersonCard from '@/components/PersonCard/index.vue';
import EmployeeModal from '@/components/accounting/modals/EmployeeModal/index.vue';

export default {
  name: 'VUser',
  components: { PersonCard, LayoutByUserRole },
  props: {
    id: [Number, String],
  },
  setup: () => ({
    getDevicesByInterval: noop,
    perPage: usePerPage(),
    page: usePage(),
    store: useStore(),
  }),
  data() {
    return {
      /** @type {User} */
      USERS_PATH_PREFIX,
      user: {},
      loading: { user: false, devices: false },
      USER_ROUTE,
    };
  },
  computed: {
    icons() {
      return icons;
    },
    deviceLink() {
      return insertRouteParams({
        path: USER_ROUTE.childrenMap.DEVICES._fullPath,
        params: { id: this.id },
      });
    },
    salaryLink() {
      return insertRouteParams({
        path: USER_ROUTE.childrenMap.SALARY._fullPath,
        params: { id: this.id },
      });
    },
    ...mapState({
      devices: (state) => state.devices.data,
      total: (state) => state.devices.total,
    }),
    userItems() {
      return [
        {
          label: this.$t('Base.DoctorFullName2'),
          value: this.user.name,
        },
        {
          label: this.$t('User.Birthdate'),
          value: this.user.birthdate,
        },
        {
          label: this.$t('User.Address'),
          value: this.user.address,
        },
        {
          label: this.$t('User.SalaryAmount'),
          value: this.user.salary,
        },
        {
          label: this.$t('User.Speciality'),
          value: this.user.doctor?.specialties
            ? `${this.user.doctor.specialties.map((item) => item.title).join(', ')}`
            : null,
        },
        {
          label: this.$t('User.PhoneNumber'),
          value: this.user.phone,
        },
        {
          label: this.$t('User.Schedule'),
          value: this.user.short_working_time,
        },
      ];
    },
  },
  watch: {
    id: {
      async handler() {
        await this.getUser();
      },
      immediate: true,
    },
  },
  methods: {
    async getUser() {
      this.loading.user = true;
      try {
        const { data } = await User.findOneById(this.id);
        this.user = new User(data.data);
      } catch (err) {
        this.$notify({
          type: 'error',
          title: axios.isAxiosError(err) ? err.message : String(err),
        });
      }
      this.loading.user = false;
    },

    onClickPersonModal() {
      this.store.dispatch('modalAndDrawer/openModal', {
        component: EmployeeModal,
        payload: {
          userId: this.user.id,
        },
      });
    },
  },
  mounted() {
    this.getDevicesByInterval();
  },
};
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/user.locales.json" />
<i18n src="@/locales/accounting.locales.json" />
