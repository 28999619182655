<template>
  <LayoutByUserRole content-class="v-warehouses-content" fix-height>
    <LayoutContentHeader>
      <template #actions>
        <ElButton type="primary" @click="onCreateButtonClick">
          {{ `+ ${$t('Warehouse.AddWarehouse')}` }}
        </ElButton>
      </template>
    </LayoutContentHeader>
    <WarehousesTable
      :loading="loading"
      v-model:page="page.value"
      v-model:per-page="perPage.value"
      :items="items"
      :total="total"
      @modal:open="onEditButtonClick" />
  </LayoutByUserRole>
</template>

<script lang="ts">
export default {
  name: 'VWarehouses',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import axios from 'axios';
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { usePerPage, usePage } from '@/hooks/query';
import { ElNotification } from 'element-plus/es';
import { I18nService } from '@/services/i18n.service';
import { Warehouse } from '@/models/warehouse/Warehouse.model';
import { compareQueriesThenLoadData } from '@/utils/router.utils';
import { WarehouseDto } from '@/types/api';

import LayoutByUserRole from '@/components/layouts/LayoutByUserRole/index.vue';
import WarehouseCrudModal from '@/components/warehouse/modals/WarehouseCrudModal/index.vue';
import LayoutContentHeader from '@/components/layouts/assets/LayoutContentHeader/index.vue';
import WarehousesTable from '@/components/warehouse/tables/WarehousesTable/index.vue';

const store = useStore();
const loading = ref(false);
const perPage = usePerPage();
const page = usePage(); // TODO типизировать usePage.js

const total = computed(() => store.state.warehouses.total); // TODO типизировать store
const items = computed(() => store.state.warehouses.data); // TODO типизировать store
const query = computed(() => ({
  per_page: perPage.value,
  page: page.value,
}));

const getWarehouses = async () => {
  loading.value = true;
  try {
    const { data } = await Warehouse.getItems(query.value);
    store.dispatch('warehouses/setData', {
      items: data.data,
      total: +data.meta.total,
      overwriteDataState: true,
    });
  } catch (err) {
    ElNotification({
      type: 'error',
      title: axios.isAxiosError(err) ? err.message : I18nService.t('Notifications.Error'),
    });
  } finally {
    loading.value = false;
  }
};

watch(
  query,
  (value, oldValue) => {
    compareQueriesThenLoadData({
      // TODO типизировать compareQueriesThenLoadData
      query: value,
      oldQuery: oldValue,
      getData: getWarehouses,
      resetPage: page.reset,
    });
  },
  { deep: true, immediate: true }
);

const onCreateButtonClick = async () => {
  await store.dispatch('modalAndDrawer/openModal', WarehouseCrudModal);
};

const onEditButtonClick = async (payload: WarehouseDto) => {
  await store.dispatch('modalAndDrawer/openModal', {
    component: WarehouseCrudModal,
    payload: {
      formData: payload,
    },
  });
};
</script>

<style lang="scss"></style>
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/warehouse.locales.json" />
