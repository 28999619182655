<template>
  <LayoutByUserRole content-class="v-doctor-content" fix-height>
    <ElBreadcrumb separator="/">
      <ElBreadcrumbItem :to="{ path: '/doctors' }">{{ $t('Base.Doctors') }}</ElBreadcrumbItem>
      <ElBreadcrumbItem>{{ doctor.name ?? '' }}</ElBreadcrumbItem>
    </ElBreadcrumb>

    <PersonCard :person="doctor" :items="userItems" />
    <div>
      <ElDatePicker
        v-model="date.value"
        type="daterange"
        unlink-panels
        :format="DATE_FORMAT"
        :value-format="DATE_FORMAT"
        :start-placeholder="$t('DateAndTime.StartDate')"
        :end-placeholder="$t('DateAndTime.EndDate')" />
    </div>

    <DoctorProfileStatistic :doctor-id="id" :period="date.value" />
  </LayoutByUserRole>
</template>

<script>
import { Doctor } from '@/models/Doctor.model';
import { DATE_FORMAT } from '@/config/dateAndTime.config';
import { useDatePeriod } from '@/hooks/query';
import { getMonthPeriod } from '@/utils/dateAndTime.utils';

import LayoutByUserRole from '@/components/layouts/LayoutByUserRole/index.vue';
import DoctorProfileStatistic from '@/components/doctors/DoctorProfileStatistic/index.vue';
import PersonCard from '@/components/PersonCard/index.vue';

export default {
  name: 'VDoctor',
  components: {
    LayoutByUserRole,
    PersonCard,
    DoctorProfileStatistic,
  },
  props: {
    id: [Number, String],
  },
  setup: () => ({
    date: useDatePeriod(getMonthPeriod()),
    DATE_FORMAT,
  }),
  data() {
    return {
      /** @type {User} */
      doctor: {},
      loading: { doctor: false },
    };
  },
  computed: {
    userItems() {
      return [
        {
          label: this.$t('User.Speciality'),
          value: this.doctor.specialties
            ? `${this.doctor.specialties.map((item) => item.title).join(', ')}`
            : null,
        },
        {
          label: this.$t('User.WorkExperience'),
          value: this.doctor.work_experience,
        },
        {
          label: this.$t('User.PhoneNumber'),
          value: this.doctor.phone,
        },
      ];
    },
  },
  watch: {
    id: {
      async handler() {
        await this.getDoctor();
      },
      immediate: true,
    },
  },
  methods: {
    async getDoctor() {
      this.loading.doctor = true;
      try {
        const { data } = await Doctor.findOneById(this.id);
        this.doctor = new Doctor(data.data);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        this.$notify({
          type: 'error',
          title: err?.response?.data?.message || this.$t('Notifications.Error'),
        });
      }
      this.loading.doctor = false;
    },
  },
};
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/user.locales.json" />
<i18n src="@/locales/dateAndTime.locales.json" />
