<template>
  <ElDialog
    width="515px"
    class="daregister-pregnancy-modal"
    :model-value="modelValue"
    :title="$t('Patients.Deregister')"
    v-bind="$attrs"
    @update:model-value="$emit('update:modelValue', $event)">
    <VeeForm class="daregister-pregnancy-modal__form" as="ElForm">
      <DeregisterDisabilityForm :id="disabilityId" @close:modal="$emit('update:modelValue')" />
    </VeeForm>
  </ElDialog>
</template>

<script lang="ts">
export default {
  name: 'DeregisterDisabilityModal',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { Form as VeeForm } from 'vee-validate';
import DeregisterDisabilityForm from '@/components/patients/modals/DeregisterDisabilityModal/DeregisterDisabilityForm/index.vue';

defineProps<{
  modelValue: boolean;
  disabilityId: number;
}>();

defineEmits(['update:modelValue']);
</script>

<i18n src="@/locales/patients.locales.json" />
