<template>
  <ElCard class="person-card" :shadow="shadow">
    <UiUserAvatarInfo class="person-card__user" :user="person" />

    <div :class="['person-card__content', { 'person-card__content_grid': contentGrid }]">
      <div class="person-card__info person-card-info" v-for="item in items" :key="item.value">
        <div class="person-card-info__label">
          <slot name="label" :item="item">{{ item.label }}</slot>
        </div>
        <div class="person-card-info__value">
          <slot v-if="item.custom" name="value" :item="item"></slot>
          <template v-else>{{ item.value ?? '...' }}</template>
        </div>
      </div>
    </div>

    <div class="person-card__actions person-card-actions">
      <slot name="actions"></slot>
    </div>
  </ElCard>
</template>

<script>
import { User } from '@/models/User.model';

export default {
  name: 'PersonCard',
  props: {
    person: [User, Object],
    /** @type {Array<{label: string, value: string|number}>} items */
    items: Array,
    shadow: {
      default: 'never',
      type: String,
    },
    contentGrid: Boolean,
  },
};
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/patients.locales.json" />
<i18n src="@/locales/user.locales.json" />
