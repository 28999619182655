import { warehouses } from './warehouses.store';
import { goodsStock } from './goodsStock.store';
import { nomenclature } from './nomenclature.store';
import { outcomes } from './outcomes.store';
import { suppliers } from './suppliers.store';
import { incomes } from './incomes.store';

export default {
  warehouses,
  goodsStock,
  nomenclature,
  outcomes,
  suppliers,
  incomes,
};
