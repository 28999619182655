<template>
  <LayoutByUserRole content-class="v-analytics-microdistricts" fix-height>
    <div class="v-analytics-microdistricts__header">
      <ElInput
        class="v-analytics-microdistricts__department"
        v-model="searchString"
        :placeholder="$t('Base.Search')"
        @input="debounceInput"
        clearable />
      <ElDatePicker
        class="v-analytics-micro districts__date"
        v-model="date.value"
        :format="DATE_FORMAT"
        type="daterange"
        unlink-panels
        :value-format="DATE_FORMAT"
        :placeholder="$t('DateAndTime.Date')" />
    </div>
    <AnalyticsMicrodistrictsTable
      :loading="loading"
      :items="items"
      :total="total"
      :default-sort="defaultSort"
      @sort-change="onSortChange"
      v-model:page="page.value"
      v-model:per-page="perPage.value" />
  </LayoutByUserRole>
</template>

<script lang="ts">
export default {
  name: 'VAnalyticsMicrodistricts',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { useStore } from 'vuex';
import { computed, ref, watch } from 'vue';
import { usePage, usePerPage, useDatePeriod } from '@/hooks/query';
import { useQuery } from '@/hooks/useQuery.hook';
import { getMonthPeriod } from '@/utils/dateAndTime.utils';
import { compareQueriesThenLoadData } from '@/utils/router.utils';
import { DATE_FORMAT } from '@/config/dateAndTime.config';
import { Analytics } from '@/models/analytics/analytics.model';
import { SortType } from '@/types/common';
import debounce from 'lodash.debounce';

import LayoutByUserRole from '@/components/layouts/LayoutByUserRole/index.vue';
import AnalyticsMicrodistrictsTable from '@/components/analytics/tables/AnalyticsMicrodistrictsTable/Index.vue';

const store = useStore();
const search = useQuery({ field: 'microdistricts' });
const page = usePage();
const perPage = usePerPage();
const loading = ref(false);
const total = computed(() => store.state.analyticsMicrodistricts.total);
const items = computed(() => store.state.analyticsMicrodistricts.data);
const date = useDatePeriod(getMonthPeriod());

const defaultSort = ref<SortType>({
  prop: 'percent_success',
  order: 'descending',
});

const searchString = ref(search.value);

const debounceInput = debounce((value) => {
  search.value = value;
}, 600);

const getConvertOrder = () => {
  if (!!defaultSort.value?.order) {
    return defaultSort.value.order === 'ascending' ? 'ASC' : 'DESC';
  } else {
    return null;
  }
};

const onSortChange = ({ prop, order }: SortType) => {
  defaultSort.value = { prop: prop, order: order };
};

const query = computed(() => ({
  page: page.value,
  per_page: perPage.value,
  start_at: date.value[0],
  end_at: date.value[1],
  search: search.value,
  count: defaultSort.value?.prop === 'count' ? getConvertOrder() : null,
  success: defaultSort.value?.prop === 'success' ? getConvertOrder() : null,
  cancel: defaultSort.value?.prop === 'cancel' ? getConvertOrder() : null,
  percent_success: defaultSort.value?.prop === 'percent_success' ? getConvertOrder() : null,
}));

const getAnalyticsMicrodistricts = async () => {
  loading.value = true;
  const results = await Analytics.getPatronageMicrodistricts(query.value);

  if (results?.data) {
    store.dispatch('analyticsMicrodistricts/setData', {
      items: results.data,
      total: results.meta.total,
      overwriteDataState: true,
    });
  }

  loading.value = false;
};

watch(
  query,
  (value, oldValue) => {
    compareQueriesThenLoadData({
      query: value,
      oldQuery: oldValue,
      getData: getAnalyticsMicrodistricts,
      resetPage: page.reset,
      fieldsForResetPage: ['start_at', 'end_at', 'count', 'success', 'cancel', 'percent_success'],
    });
  },
  { deep: true, immediate: true }
);
</script>

<style lang="scss" src="./index.scss"></style>

<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/dateAndTime.locales.json" />
<i18n src="@/locales/analytics.locales.json" />
