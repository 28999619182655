﻿{
  "en": {
    "Templates": {
      "AddTemplate": "Add Template",
      "Create": "Create a template",
      "Edit": "Edit template",
      "Name": "Template name",
      "OtherAnswer": "Your answer",
      "SelectDocument": "Select document",
      "SelectTemplate": "Select template",
      "Template": "Template",
      "Templates": "Templates"
    }
  },
  "ru": {
    "Templates": {
      "AddTemplate": "Добавить шаблон",
      "Create": "Создать шаблон",
      "Edit": "Редактировать шаблон",
      "Name": "Название шаблона",
      "OtherAnswer": "Свой овтет",
      "SelectDocument": "Выбрать документ",
      "SelectTemplate": "Выбрать шаблон",
      "Template": "Шаблон",
      "Templates": "Шаблоны"
    }
  },
  "uz": {
    "Templates": {
      "AddTemplate": "Shablon qo'shish",
      "Create": "Shablon yaratish",
      "Edit": "Shablonni tahrirlash",
      "Name": "Shablon nomi",
      "OtherAnswer": "Boshqa javob",
      "SelectDocument": "Hujjatni tanlash",
      "SelectTemplate": "Shablonni tanlang",
      "Template": "Shablon",
      "Templates": "Shablonlar"
    }
  }
}
