<template>
  <div class="app">
    <ElConfigProvider size="large" :locale="lang">
      <RouterView />
      <GlobalModalsAndDrawers />
    </ElConfigProvider>

    <SVG />
  </div>
</template>

<script lang="ts">
export default {
  name: 'App',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { computed, ref, watch, onBeforeMount } from 'vue';
import { I18nService } from '@/services/i18n.service';
import { ApiService } from '@/services/api.service.js';
import { DeviceService } from '@/services/device.service';
import { User } from '@/models/User.model';
import { useStore } from '@/store';

import { RouterView } from 'vue-router';
import { ElConfigProvider } from 'element-plus';
import { EmitterService } from '@/services/emitter.service';
import { API_LOGOUT_EMIT } from '@/enums/emits.enum';
import ru from 'element-plus/lib/locale/lang/ru';
import en from 'element-plus/lib/locale/lang/en';
import uz from 'element-plus/lib/locale/lang/uz-uz';

import SVG from '@/components/SVG.vue';
import GlobalModalsAndDrawers from '@/components/GlobalModalsAndDrawers/index.vue';

const store = useStore();

const user = ref(store.state.auth.user);
const langByLocale = {
  ru,
  en,
  uz,
};

const lang = computed(() => {
  const locale = I18nService.getLocale() as keyof typeof langByLocale;
  return langByLocale[locale];
});

const addGlobalEventListeners = () => {
  EmitterService.on(API_LOGOUT_EMIT, () => store.dispatch('auth/logout'));
};

const getMe = async () => {
  const { user } = await User.me();
  store.commit('auth/SET_USER', user);
};

watch(
  () => user.value.id,
  () => {
    if (user.value.role === User.enum.roles.Doctor) {
      store.dispatch('user/getDataFromStorage');
    }
  },
  { immediate: true }
);

onBeforeMount(() => {
  // Проверяем наличие deviceId в localStorage, записываем в headers, генерируем новый если отсутствует, сохраняем в localStorage
  DeviceService.checkAndSetDeviceId();
  ApiService.setDeviceId(DeviceService.getDeviceId());

  I18nService.setLocaleFromStorage();
  store.dispatch('auth/checkAndSetUserAndTokenFromClientStorage');

  // TODO: добавить проверку на существование сохранённого пользователя
  getMe();

  addGlobalEventListeners();
});
</script>

<style src="./App.scss" lang="scss"></style>
