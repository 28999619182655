<template>
  <div class="expense-categories-form">
    <div class="expense-categories-form__fields">
      <FormTextField
        v-for="(expense, idx) in expenses"
        v-show="!expense.deleted"
        :key="expense.id"
        :label="expense.title"
        :description="expense.description"
        :mask-name="expense.type === 'absolute' ? 'int-12' : 'float-3.2'"
        :field-name="`expenses_categories[${idx}].value`"
        required>
        <template #append>
          <FormCheckboxBtnField
            :field-name="`expenses_categories[${idx}].type`"
            true-label="percent"
            false-label="absolute"
            >{{ expense.type === 'absolute' ? `so'm` : '%' }}
          </FormCheckboxBtnField>
        </template>
        <template v-if="expense.can_deleted" #delete-action>
          <ElPopconfirm
            :title="$t('Ui.PopConfirm.DefaultTitle')"
            @confirm="onRemoveExpenseBtnClick(expense)">
            <template #reference>
              <ElButton class="expense-categories-form__delete-button" size="small" type="danger">
                &#10006;
              </ElButton>
            </template>
          </ElPopconfirm>
        </template>
      </FormTextField>
    </div>
    <div class="expense-categories-form__actions">
      <ElButton type="primary" text link @click="openExpenseCrudModal">
        {{ `+ ${$t('Services.AddExpenseType')}` }}
      </ElButton>
    </div>
    <ExpenseCrudModal
      v-if="nestedModalData.expense.visible"
      v-model="nestedModalData.expense.visible"
      append-to-body
      @expenses:add="onAddExpenseBtnClick" />
  </div>
</template>

<script lang="ts">
export default {
  name: 'ExpenseCategoriesForm',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { ref, watch } from 'vue';
import { useField } from 'vee-validate';
import {
  ExpenseCategoryFormData,
  ServiceGroupBase,
  ServiceGroupBaseFormData,
} from '@/models/accounting/Service.model';

import ExpenseCrudModal from '../../ExpenseCrudModal/index.vue';
import FormTextField from '@/components/common/form/ui/FormTextField/index.vue';
import FormCheckboxBtnField from '@/components/common/form/ui/FormCheckboxBtnField/index.vue';

const nestedModalData = ref({
  expense: { visible: false },
});

const { value: expenses, setValue: setExpenses } =
  useField<ExpenseCategoryFormData[]>('expenses_categories');

const openExpenseCrudModal = () => {
  nestedModalData.value.expense.visible = true;
};

const onAddExpenseBtnClick = (values: ExpenseCategoryFormData) => {
  setExpenses([...expenses.value, { ...values, can_deleted: true }]);
};

const onRemoveExpenseBtnClick = (expense: ExpenseCategoryFormData) => {
  const idx = expenses.value.findIndex((item) => item.id === expense.id);
  setExpenses([
    ...expenses.value.slice(0, idx),
    { ...expense, deleted: true },
    ...expenses.value.slice(idx + 1),
  ]);
};

const { value: formId } = useField<ServiceGroupBaseFormData['id']>('id');
const { value: formType } = useField<ServiceGroupBaseFormData['type']>('type');

watch(
  () => ({ id: formId.value, type: formType.value }),
  async (newValue) => {
    if (!newValue.id) {
      const response = await ServiceGroupBase.getExpenseCategories({ type: newValue.type });
      if (response) {
        setExpenses(response.data);
      }
    }
  },
  { deep: true, immediate: true }
);
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/accounting.locales.json" />
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/ui.locales.json" />
