<template>
  <ElDialog
    width="515px"
    class="add-vaccination-modal"
    :model-value="modelValue"
    :title="$t('Patients.AddVaccination')"
    v-bind="$attrs"
    @update:model-value="$emit('update:modelValue', $event)">
    <VeeForm class="add-vaccination__form" as="ElForm"
      ><AddVaccinationForm :id="id" @close:modal="$emit('update:modelValue')"
    /></VeeForm>
  </ElDialog>
</template>

<script lang="ts">
export default {
  name: 'AddVaccinationModal',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { Form as VeeForm } from 'vee-validate';

import AddVaccinationForm from './AddVaccinationForm/index.vue';

defineProps<{
  modelValue: boolean;
  id: number;
}>();

defineEmits(['update:modelValue']);
</script>

<i18n src="@/locales/dateAndTime.locales.json" />
<i18n src="@/locales/patients.locales.json" />
