<template>
  <LayoutByUserRole content-class="v-analytics-diagnoses" fix-height>
    <div class="v-analytics-diagnoses__filters">
      <ElInput
        :clearable="false"
        v-model="searchString"
        :placeholder="$t('Analytics.DiagnosisSearch')"
        @input="debounceInput" />
      <ElDatePicker
        v-model="date.value"
        type="daterange"
        unlink-panels
        :format="DATE_FORMAT"
        :value-format="DATE_FORMAT"
        :start-placeholder="$t('DateAndTime.StartDate')"
        :end-placeholder="$t('DateAndTime.EndDate')" />

      <UiModelsAutocompleteSearch
        :model-value="userStatesIds.value"
        :model-for-use="Patient"
        method-name="getStates"
        label="title"
        value="id"
        :placeholder="$t('Analytics.PatientStatus')"
        multiple
        clearable
        @update:modelValue="userStatesIds.value = $event" />

      <UiModelsAutocompleteSearch
        :model-value="microdistrictIds.value"
        :model-for-use="Microdistrict"
        method-name="getItems"
        label="title"
        value="id"
        :placeholder="$t('Analytics.Mictrodistrict')"
        multiple
        clearable
        @update:modelValue="microdistrictIds.value = $event" />
    </div>

    <AnalyticsDiagnosesTable
      :loading="loading"
      :items="items"
      :total="total"
      :default-sort="defaultSort"
      @sort-change="onSortChange"
      v-model:page="page.value"
      v-model:per-page="perPage.value" />
  </LayoutByUserRole>
</template>

<script lang="ts">
export default {
  name: 'VAnalyticsDiagnoses',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { usePage } from '@/hooks/query/usePage';
import { usePerPage } from '@/hooks/query/usePerPage';
import { computed, ref, watch } from 'vue';
import { useDatePeriod } from '@/hooks/query';
import { useStore } from 'vuex';
import { getMonthPeriod } from '@/utils/dateAndTime.utils';
import { compareQueriesThenLoadData } from '@/utils/router.utils';
import { Analytics } from '@/models/analytics/analytics.model';
import { DATE_FORMAT } from '@/config/dateAndTime.config';
import { Patient } from '@/models/Patient.model';
import { Microdistrict } from '@/models/patronage/Microdistrict.model';
import debounce from 'lodash.debounce';

import { useQuery } from '@/hooks/useQuery.hook';
import LayoutByUserRole from '@/components/layouts/LayoutByUserRole/index.vue';
import AnalyticsDiagnosesTable from '@/components/analytics/tables/AnalyticsDiagnosesTable/index.vue';
import { SortType } from '@/types/common';

const page = usePage();
const perPage = usePerPage();
const date = useDatePeriod(getMonthPeriod());
const diagnosis = useQuery({ field: 'diagnosis' });
const userStatesIds = useQuery({ field: 'user_state_ids', valueIsNumberArray: true });
const microdistrictIds = useQuery({ field: 'microdistrict_ids', valueIsNumberArray: true });

const store = useStore();
const loading = computed(() => store.state.analyticsDiagnoses.loading);
const total = computed(() => store.state.analyticsDiagnoses.total);
const items = computed(() => store.state.analyticsDiagnoses.data);

const searchString = ref(diagnosis.value);

const debounceInput = debounce((value) => {
  diagnosis.value = value;
}, 600);

const defaultSort = ref<null | SortType>({
  prop: '',
  order: '',
});

const getConvertedOrder = () => {
  if (!!defaultSort.value?.order) {
    return defaultSort.value.order === 'ascending' ? 'ASC' : 'DESC';
  } else {
    return null;
  }
};

const onSortChange = ({ prop, order }: SortType) => {
  defaultSort.value = { prop: prop, order: order };
};

const query = computed(() => ({
  page: page.value,
  per_page: perPage.value,
  start_at: date.value[0],
  end_at: date.value[1],
  search: diagnosis.value,
  user_states: userStatesIds.value,
  microdistrict_ids: microdistrictIds.value,
  count: defaultSort.value?.prop === 'count' ? getConvertedOrder() : null,
  man: defaultSort.value?.prop === 'man' ? getConvertedOrder() : null,
  woman: defaultSort.value?.prop === 'woman' ? getConvertedOrder() : null,
  child: defaultSort.value?.prop === 'child' ? getConvertedOrder() : null,
}));

const getIdcList = async () => {
  store.dispatch('analyticsDiagnoses/setLoading', true);
  const result = await Analytics.getIdcList(query.value);

  if (result) {
    store.dispatch('analyticsDiagnoses/setData', {
      items: result.data,
      total: result.meta.total,
      overwriteDataState: true,
    });
  }

  store.dispatch('analyticsDiagnoses/setLoading', false);
};

watch(
  query,
  (value, oldValue) => {
    compareQueriesThenLoadData({
      query: value,
      oldQuery: oldValue,
      getData: getIdcList,
      resetPage: page.reset,
      fieldsForResetPage: ['start_at', 'end_at', 'count', 'man', 'woman', 'child'],
    });
  },
  { deep: true, immediate: true }
);
</script>

<style lang="scss" src="./index.scss" />
