<template>
  <PrinterDocument
    class="order-printer-document"
    :meta-blocks="metaBlocks"
    :content-blocks="contentBlocks"
    full-page
    hide-watermark
    ref="printerDocument">
    <template #meta-sign-value>
      <img
        v-if="user.doctor?.sign?.link"
        class="order-printer-document__sign"
        :src="user.doctor?.sign?.link"
        alt="sign" />
    </template>

    <template #append-content>
      {{ $t('Info') }}
    </template>
  </PrinterDocument>
</template>

<script>
import { mapState } from 'vuex';
import { Order } from '@/models/laboratory/Order.model';
import { Study } from '@/models/laboratory/Study.model';
import { Biomaterial } from '@/models/laboratory/Biomaterial.model';
import { ISOStringToDateAppFormat } from '@/utils/dateAndTime.utils';
import { PrinterDocumentContentBlock as ContentBlock } from '@/models/client/PrinterDocumentContentBlock.model';

import PrinterDocument from '@/components/printer/PrinterDocument/index.vue';

export default {
  name: 'OrderPrinterDocument',
  components: { PrinterDocument },
  props: {
    order: [Order, Object],
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),

    metaBlocks() {
      return [
        [
          {
            label: this.$t('Patients.Patient'),
            value: this.order.user.name,
          },
          {
            label: this.$t('User.Birthdate'),
            value: this.order.user.birthdate,
          },
          {
            label: this.$t('User.Gender'),
            value: this.$t(`User.Genders.${this.order.user.gender}`),
          },
        ],
        [
          {
            label: this.$t('Laboratory.SamplingAt'),
            value: this.order.biomaterial_ids.length ? this.order.biomaterials[0].sampling_at : '',
          },
          {
            label: this.$t('DateAndTime.DateOfIssues'),
            value: ISOStringToDateAppFormat(new Date().toISOString()),
          },
          {
            label: this.$t('Doctors.Doctor'),
            value: this.user.doctor?.name ?? this.order.invoice?.doctor?.name,
          },
          {
            uuid: 'sign',
          },
        ],
      ];
    },

    contentBlocks() {
      return [
        {
          label: this.$t('Laboratory.StudiesResults'),
          type: ContentBlock.enum.types.Title,
        },

        ...this.order.biomaterials
          .filter((biomaterial) => biomaterial.sampling_at && biomaterial.confirmed_at)
          .map((biomaterial) =>
            biomaterial.basic_data
              ? {
                  label: Biomaterial.getBiomaterialTitleWithAnalysisCategory(biomaterial),
                  type: ContentBlock.enum.types.Editor,
                  basicData: biomaterial.basic_data,
                }
              : {
                  label: Biomaterial.getBiomaterialTitleWithAnalysisCategory(biomaterial),
                  type: ContentBlock.enum.types.Table,
                  class: 'order-printer-document__table',
                  headers: [
                    {
                      label: this.$t('Base.Naming'),
                      prop: 'title',
                    },
                    {
                      label: this.$t('Laboratory.Results'),
                      prop: 'value',
                    },
                    {
                      label: this.$t('Base.Measure'),
                      prop: 'measure',
                    },
                    {
                      label: this.$t('Laboratory.ReferencesInterval'),
                      prop: 'references_value',
                    },
                  ],
                  value: biomaterial.studys
                    .filter((study) => !!Study.getValueString(study))
                    .map((study) => ({
                      title: study.analysis.title,
                      category: study.analysis.category,
                      value: Study.getValueString(study),
                      measure: study.analysis.measure,
                      references_value: Study.getReferencesValueString(study) ?? '',
                    })),
                }
          ),
      ];
    },
  },

  methods: {
    print() {
      this.$refs.printerDocument.print();
    },
  },
};
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/user.locales.json" />
<i18n src="@/locales/patients.locales.json" />
<i18n src="@/locales/laboratory.locales.json" />
<i18n src="@/locales/dateAndTime.locales.json" />
<i18n src="./index.locales.json" />
