<template>
  <ElDialog
    width="480px"
    class="expense-crud-modal"
    :title="isNewExpense ? $t('Services.AddExpenseType') : $t('Services.EditExpenseType')"
    v-bind="$attrs"
    @update:model-value="$emit('update:modelValue', $event)">
    <VeeForm
      class="expense-crud-form"
      as="ElForm"
      :validation-schema="validationSchema"
      :initial-values="initialValues"
      v-slot="{ meta }"
      @submit="onSubmit">
      <FieldGroupWrapper>
        <FormTextField
          :label="$t('Services.ExpenseName')"
          field-name="title"
          required
          maxlength="255" />
        <FormSelectField
          :items="unitsMeasurement"
          field-name="type"
          field-object-name="typeObject"
          :placeholder="$t('Services.UnitOfMeasurement')"
          value-key="value"
          label-key="label"
          ref="typeSelect" />
        <FormTextField
          :label="$t('Base.Description')"
          field-name="description"
          type="textarea"
          :rows="4"
          maxlength="255" />
      </FieldGroupWrapper>
      <FormActionsWrapper>
        <ElButton
          type="primary"
          native-type="submit"
          :disabled="!meta.dirty"
          :loading="loading.save"
          >{{ isNewExpense ? `+ ${$t('Base.Add')}` : $t('Base.Save') }}
        </ElButton>
        <ElPopconfirm
          v-if="!isNewExpense"
          :title="$t('Ui.PopConfirm.DefaultTitle')"
          @confirm="onDeleteButtonClick">
          <template #reference>
            <ElButton type="danger" plain :loading="loading.delete">
              {{ $t('Base.Delete') }}
            </ElButton>
          </template>
        </ElPopconfirm>
      </FormActionsWrapper>
    </VeeForm>
  </ElDialog>
</template>

<script lang="ts">
export default {
  name: 'ExpenseCrudModal',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script setup lang="ts">
import { SubmissionHandler, Form as VeeForm } from 'vee-validate';
import { object, string } from 'yup';
import { computed, reactive, ref } from 'vue';
import { I18nService } from '@/services/i18n.service';
import { ExpenseCategory } from '@/types/api';
import noop from 'lodash.noop';

import FormTextField from '@/components/common/form/ui/FormTextField/index.vue';
import FormSelectField from '@/components/common/form/ui/FormSelectField/index.vue';
import FieldGroupWrapper from '@/components/common/form/FieldGroupWrapper/index.vue';
import FormActionsWrapper from '@/components/common/form/FormActionsWrapper/index.vue';

const emit = defineEmits(['update:modelValue', 'action', 'expenses:add']);

type Props = {
  formData: ExpenseCategory | null;
};

const props = withDefaults(defineProps<Props>(), {
  formData: null,
});

const initialValues = ref({ ...props.formData });
const isNewExpense = computed(() => !initialValues.value.id);
const loading = reactive({
  save: false,
  delete: false,
});

const unitsMeasurement: { value: ExpenseCategory['type']; label: string }[] = [
  { value: 'absolute', label: I18nService.t('Base.Sum') },
  { value: 'percent', label: I18nService.t('Base.Percent') },
];

const validationSchema = object({
  title: string().required(I18nService.t('Validation.RequiredField')),
  type: string().required(I18nService.t('Validation.RequiredField')),
  description: string().nullable(),
});

const closeModal = () => {
  emit('update:modelValue');
};

const onSubmit: SubmissionHandler = (values) => {
  emit('expenses:add', { id: self.crypto.randomUUID(), ...values });
  closeModal();
};

const onDeleteButtonClick = () => {
  noop();
};
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/accounting.locales.json" />
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/validation.locales.json" />
<i18n src="@/locales/ui.locales.json" />
