import { ApiService } from '@/services/api.service';
import { ElNotification } from 'element-plus';
import {
  AddPregnancyRequestDto,
  MetaDto,
  PregnancyDto,
  PregnancyUpdateRequestDto,
} from '@/types/api';
import { getApiErrorMessage, mergeOrCreateQuery } from '@/utils/http.util';
import { QueryParams } from '@/types/common';
import { deleteEmptyValueKeys } from '@/utils/object.util';

export type PregnancyStatus = 'created' | 'gave_birth' | 'abort';

export class Pregnancy {
  static modelName = 'pregnancy';
  static tableName = 'pregnancies';

  static async getItems(query: QueryParams) {
    try {
      const response = await ApiService.get<{ data: PregnancyDto[]; meta: MetaDto }>(
        mergeOrCreateQuery({
          url: `b2g/${this.tableName}/${query.id}`,
          query: deleteEmptyValueKeys(query),
        })
      );

      return { response, data: response.data };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: getApiErrorMessage(err),
      });
    }
  }

  static async createItem(payload: AddPregnancyRequestDto) {
    try {
      const response = await ApiService.post<{ data: PregnancyDto }>(
        `/b2g/${this.tableName}/create`,
        payload
      );

      return {
        response: response,
        data: response.data.data,
      };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: getApiErrorMessage(err),
      });
    }
  }

  static async updateItem(id: PregnancyDto['id'], payload: PregnancyUpdateRequestDto) {
    try {
      const response = await ApiService.put<{ data: PregnancyDto[] }>(
        `/b2g/${this.tableName}/${id}/close`,
        payload
      );

      return {
        response: response,
        data: response.data.data,
      };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: getApiErrorMessage(err),
      });
    }
  }
}
