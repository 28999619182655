<template>
  <form class="login-form" @submit.prevent="submitHandler">
    <div class="login-form-content">
      <div class="login-form-info">
        <p class="login-form-info__title">{{ $t('Base.Authorization') }}!</p>
        <p class="login-form-info__text">{{ $t('Base.AuthSubtitle') }}</p>
      </div>
      <div>
        <p class="login-form__label">{{ $t('Base.Login') }}</p>
        <ElInput
          class="login-form-content__input"
          v-model="email"
          name="email"
          type="email"
          required>
          <template #prefix>
            <UiIcon :icon="icons.MESSAGE" />
          </template>
        </ElInput>
      </div>

      <div>
        <p class="login-form__label">{{ $t('Base.Password') }}</p>
        <ElInput v-model="password" name="password" type="password" required show-password>
          <template #prefix>
            <UiIcon :icon="icons.LOCK" />
          </template>
        </ElInput>
        <router-link :to="FORGOT_PASSWORD_ROUTE.path">
          <ElButton class="forgot" type="primary" text link>
            {{ $t('Base.ForgotPassword') }}
          </ElButton>
        </router-link>
      </div>

      <ElCheckbox v-model="checked" :label="$t('Base.LoginСonfirmed')" size="large" />

      <div v-if="checked" class="login-form-content__otp-wrapper">
        <VOtpInput
          input-classes="login-form-content__otp-input"
          separator=""
          :num-inputs="VOTP_INPUT_LENGTH"
          should-auto-focus
          is-input-num
          :conditional-class="['one', 'two', 'three', 'four', 'five', 'six']"
          :placeholder="['*', '*', '*', '*', '*', '*']"
          @on-change="otpInputOnChange"
          @on-complete="otpInputOnChange" />
      </div>

      <div class="login-form__actions">
        <ElButton
          :disabled="isSubmitDisabled"
          type="primary"
          class="login-form__actions-submit"
          :loading="loading"
          native-type="submit">
          {{ $t('LogIn') }}
        </ElButton>
      </div>
    </div>
  </form>
</template>

<script>
import axios from 'axios';
import { FORGOT_PASSWORD_ROUTE, WAITING_CONFIRM_ROUTE } from '@/router/auth.routes';
import { VOTP_INPUT_LENGTH } from '@/config/ui.config';

import VOtpInput from 'vue3-otp-input';
import * as icons from '@/enums/icons.enum.js';

export default {
  name: 'LoginForm',
  components: { VOtpInput },
  data() {
    return {
      checked: false,
      loading: false,
      email: null,
      password: null,
      activationKey: '',
    };
  },
  computed: {
    FORGOT_PASSWORD_ROUTE: () => FORGOT_PASSWORD_ROUTE,
    VOTP_INPUT_LENGTH: () => VOTP_INPUT_LENGTH,
    isSubmitDisabled() {
      return this.checked && this.activationKey.length < VOTP_INPUT_LENGTH;
    },
  },
  methods: {
    async submitHandler() {
      if (this.loading) return;
      this.loading = true;

      try {
        await this.$store.dispatch('auth/logIn', {
          email: this.email,
          password: this.password,
          activationKey: this.activationKey,
        });
        this.$router.push('/');
      } catch (err) {
        if (err.response?.status === 419) {
          this.$store.dispatch('auth/setUserCredentials', {
            email: this.email,
            password: this.password,
          });
          this.$router.push(WAITING_CONFIRM_ROUTE.path);
        } else {
          this.$notify({
            type: 'error',
            title: axios.isAxiosError(err) ? err.response.data.message : String(err),
          });
        }
      }

      this.loading = false;
    },

    otpInputOnChange(value) {
      this.activationKey = String(value);
    },
  },
  setup: () => ({
    icons,
  }),
};
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/base.locales.json" />
<i18n src="./index.locales.json" />
