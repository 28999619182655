<template>
  <div class="pregnants-statistics-block" v-bind="$attrs">
    <StatisticsCard
      v-for="item in statisticsCardsData"
      :key="item.title"
      :title="item.title"
      :count="item.count"
      :total-count="item.totalCount"
      :subtitle="`${$t('Statistic.TotalWomen')} ${item.totalCount}`"
      :colors="item.colors"
      :labels="item.labels"
      :loading="item.loading"
      @click-card="handleClickCard" />
  </div>
</template>

<script lang="ts">
export default {
  name: 'PregnantsStatisticsBlock',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { Statistics } from '@/models/Statistics.model';
import { I18nService } from '@/services/i18n.service';
import { computed, ref, watch } from 'vue';
import { CardInfoType } from '@/components/common/StatisticsCard/types';
import { useRouter } from 'vue-router';

import StatisticsCard from '@/components/common/StatisticsCard/index.vue';
import { PREGNANT_BY_GROUP_ROUTE } from '@/router/pregnant.routes';

const initialData = { total_count: 0, count: 0 };
const router = useRouter();

interface Props {
  query: {
    start_at: string;
    end_at: string;
    age_from?: number | null;
    age_to?: number | null;
    microdistrict_id?: number | null;
    is_pregnancy?: number | null;
  };
}

const props = defineProps<Props>();

const statisticsData = ref({
  count: {
    data: initialData,
    loading: false,
  },
  appointment: {
    data: initialData,
    loading: false,
  },
  income: {
    data: initialData,
    loading: false,
  },
  outcome: {
    data: initialData,
    loading: false,
  },
});

const statisticsCardsData = computed(() => [
  {
    title: I18nService.t('Statistic.Pregnants'),
    count: statisticsData.value.count.data.count,
    totalCount: statisticsData.value.count.data.total_count,
    loading: statisticsData.value.count.loading,
    colors: ['#3879F8', '#C8DBFF'],
    labels: [I18nService.t('Analytics.Pregnants'), I18nService.t('Statistic.TotalWomen')],
  },
  {
    title: I18nService.t('Statistic.SuccessPregnants'),
    count: statisticsData.value.appointment.data.count,
    totalCount: statisticsData.value.appointment.data.total_count,
    loading: statisticsData.value.appointment.loading,
    colors: ['#3879F8', '#C8DBFF'],
    labels: [I18nService.t('Statistic.Successful'), I18nService.t('Statistic.NotSuccessful')],
  },
  {
    title: I18nService.t('Statistic.ChildbirthWithComplications'),
    count: statisticsData.value.income.data.count,
    totalCount: statisticsData.value.income.data.total_count,
    loading: statisticsData.value.income.loading,
    colors: ['#3879F8', '#C8DBFF'],
    labels: [
      I18nService.t('Statistic.WithComplications'),
      I18nService.t('Statistic.WithoutComplications'),
    ],
  },
  {
    title: I18nService.t('Statistic.Aborts'),
    count: statisticsData.value.outcome.data.count,
    totalCount: statisticsData.value.outcome.data.total_count,
    loading: statisticsData.value.outcome.loading,
    colors: ['#3879F8', '#C8DBFF'],
    labels: [I18nService.t('Statistic.Aborts'), I18nService.t('Statistic.Childbirth')],
  },
]);

const getPregnancyCount = async (query: Props['query']) => {
  statisticsData.value.count.loading = true;
  const response = await Statistics.getPregnancyCount(query);
  statisticsData.value.count.data = response?.data ?? initialData;
  statisticsData.value.count.loading = false;
};

const getPregnancySuccessCount = async (query: Props['query']) => {
  statisticsData.value.appointment.loading = true;
  const response = await Statistics.getPregnancySuccessCount(query);
  statisticsData.value.appointment.data = response?.data ?? initialData;
  statisticsData.value.appointment.loading = false;
};

const getAbortsCount = async (query: Props['query']) => {
  statisticsData.value.income.loading = true;
  const response = await Statistics.getAbortsCount(query);
  statisticsData.value.income.data = response?.data ?? initialData;
  statisticsData.value.income.loading = false;
};

const getMisbirthsCount = async (query: Props['query']) => {
  statisticsData.value.outcome.loading = true;
  const response = await Statistics.getMisbirthsCount(query);
  statisticsData.value.outcome.data = response?.data ?? initialData;
  statisticsData.value.outcome.loading = false;
};

const getStatusPregnancy = (title: string) => {
  switch (title) {
    case I18nService.t('Statistic.SuccessPregnants'):
      return 'gave_birth';
    case I18nService.t('Statistic.ChildbirthWithComplications'):
      return 'misbirth';
    case I18nService.t('Statistic.Aborts'):
      return 'abort';
    default:
      return 'gave_birth';
  }
};

const handleClickCard = (data: CardInfoType) => {
  if (data.title === I18nService.t('Statistic.Pregnants')) return;

  router.push({
    name: PREGNANT_BY_GROUP_ROUTE.name,
    query: {
      ...props.query,
      pregnancy_status: getStatusPregnancy(data.title),
    },
  });
};

watch(
  () => props.query,
  async (value) => {
    getPregnancyCount(value);
    getPregnancySuccessCount(value);
    getAbortsCount(value);
    getMisbirthsCount(value);
  },
  { deep: true, immediate: true }
);
</script>
<style lang="scss" src="./index.scss" />
