import { AxiosResponse } from 'axios';
import { CRUDModel } from '@/models/CRUD.model';
import { ApiService } from '@/services/api.service';
import { mergeOrCreateQuery } from '@/utils/http.util';
import { deleteEmptyValueKeys } from '@/utils/object.util';
import { QueryParams } from '@/types/common';
import {
  IncomeDto,
  IncomePayloadDto,
  IncomeProductItem,
  IncomeWithProductsDto,
  MetaDto,
} from '@/types/api';

export type IncomeFormData = Pick<IncomeDto, 'income_date' | 'status' | 'planed_income_date'> & {
  id?: IncomeDto['id'] | null;
  count_products?: IncomeDto['count_products'] | null;
  amount_products?: IncomeDto['amount_products'] | null;
  supplier_id?: IncomeDto['supplier_id'] | null;
  supplier?: IncomeDto['supplier'] | null;
  warehouse_id?: IncomeDto['warehouse_id'] | null;
  warehouse?: IncomeDto['warehouse'] | null;
  products: IncomeProductItem[];
};

export class Income extends CRUDModel {
  static modelName = 'income';
  static tableName = 'incomes';

  static exportIncomeDataURL = import.meta.env.VITE_API_URL + `incomes/export`;

  count_products: IncomeFormData['count_products'];
  amount_products: IncomeFormData['amount_products'];
  status: IncomeFormData['status'];
  supplier_id: IncomeFormData['supplier_id'];
  supplier: IncomeFormData['supplier'];
  warehouse_id: IncomeFormData['warehouse_id'];
  warehouse: IncomeFormData['warehouse'];
  planed_income_date: IncomeFormData['planed_income_date'];
  income_date?: IncomeFormData['income_date'];
  products: IncomeFormData['products'];

  constructor(payload?: IncomeFormData) {
    super(payload);

    this.count_products = payload?.count_products ?? null;
    this.amount_products = payload?.amount_products ?? null;
    this.status = payload?.status ?? 'created';
    this.supplier_id = payload?.supplier_id ?? null;
    this.supplier = payload?.supplier ?? null;
    this.warehouse_id = payload?.warehouse_id ?? null;
    this.warehouse = payload?.warehouse ?? null;
    this.income_date = payload?.income_date ?? '';
    this.planed_income_date = payload?.planed_income_date ?? '';
    this.products = payload?.products ?? [];
  }

  static async getItems(query: QueryParams) {
    const response = await ApiService.get<{ data: IncomeDto[]; meta: MetaDto }>(
      mergeOrCreateQuery({
        url: `${this.tableName}`,
        query: deleteEmptyValueKeys(query),
      })
    );

    return {
      response: response,
      data: response.data,
    };
  }

  static async getItemById(id: number) {
    const response = await ApiService.get<{ data: IncomeWithProductsDto }>(
      `${this.tableName}/${id}`
    );

    return {
      response: response,
      data: response.data,
    };
  }

  static async createItem(payload: IncomePayloadDto) {
    const response = await ApiService.post<{ data: IncomeWithProductsDto }>(
      `${this.tableName}/create`,
      payload
    );

    return {
      response: response,
      data: response.data,
    };
  }

  static async updateItem(id: number, payload: IncomePayloadDto) {
    const response = await ApiService.put<{ data: IncomeWithProductsDto }>(
      `${this.tableName}/${id}/update`,
      payload
    );

    return {
      response: response,
      data: response.data,
    };
  }

  static async acceptedItem(id: number, payload: IncomePayloadDto) {
    const response = await ApiService.put<{ data: IncomeWithProductsDto }>(
      `${this.tableName}/${id}/accepted`,
      payload
    );

    return {
      response: response,
      data: response.data,
    };
  }

  static async canceledItem(id: number): Promise<{
    response: AxiosResponse<{ data: IncomeWithProductsDto }>;
    data: { data: IncomeWithProductsDto };
  }> {
    const response = await ApiService.put(`${this.tableName}/${id}/canceled`);

    return {
      response: response,
      data: response.data,
    };
  }

  static enum = {
    statuses: {
      CREATED: 'created',
      ACCEPTED: 'accepted',
      CANCELED: 'canceled',
    },
  };
}
