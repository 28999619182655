import { Store } from '@/store';

/**
 * @param {string|Array<string>} roleOrRoles
 * @return { (function(*, *, *))|* } middleware function
 */
export function byUserRolesMiddleware(roleOrRoles) {
  const roles = roleOrRoles instanceof Array ? roleOrRoles : [roleOrRoles];

  return function (to, from, next) {
    const hasAccessRole = roles.includes(Store.state.auth.user.role);
    return next(hasAccessRole ? null : '/');
  };
}
