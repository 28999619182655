<template>
  <ElTable
    class="analyzes-statuses-table"
    :data="items"
    :empty-text="$t('Base.NoData')"
    v-bind="$attrs"
    v-loading="loading"
    :show-header="true"
    ref="elTable"
    @row-click="rowClickHandler">
    <ElTableColumn prop="created_at" column-key="created_at" :label="$t('DateAndTime.CreatedAt')" />

    <ElTableColumn prop="status" column-key="status" :label="$t('Base.Status')">
      <template #default="{ row }">
        <OrderStatusTag :status="row.status" />
      </template>
    </ElTableColumn>
  </ElTable>
</template>

<script>
import { LABORATORY_ORDER_ROUTE } from '@/router/laboratory.routes';
import OrderStatusTag from '@/components/laboratory/OrderStatusTag/index.vue';

export default {
  name: 'AnalyzesStatusesTable',
  components: { OrderStatusTag },
  props: {
    loading: Boolean,
    /** @property { Array<Patient|object> } items */
    items: Array,
  },
  methods: {
    rowClickHandler(payload) {
      this.$router.push({
        name: LABORATORY_ORDER_ROUTE.name,
        params: {
          id: payload.id,
        },
      });
    },
  },
};
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/user.locales.json" />
<i18n src="@/locales/patients.locales.json" />
<i18n src="@/locales/laboratory.locales.json" />
<i18n src="@/locales/dateAndTime.locales.json" />
