<template>
  <ElDialog
    class="template-create-modal"
    :model-value="modelValue"
    width="515px"
    :title="$t('Templates.Create')"
    v-bind="$attrs"
    @update:model-value="$emit('update:modelValue', $event)">
    <VeeForm
      as="ElForm"
      @submit="onSubmit"
      :validation-schema="validationSchema"
      v-slot="{ meta }"
      :initial-values="initialValues">
      <FieldGroupWrapper>
        <FormTextField :label="$t('Templates.Name')" field-name="title" required maxlength="255" />
      </FieldGroupWrapper>
      <FormActionsWrapper>
        <ElButton
          class="templates-modal__btn"
          type="primary"
          native-type="submit"
          :disabled="!meta.dirty"
          >{{ $t('Base.Create') }}
        </ElButton>
      </FormActionsWrapper>
    </VeeForm>
  </ElDialog>
</template>

<script lang="ts">
export default {
  name: 'CreateTemplateModal',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { Form as VeeForm, SubmissionHandler } from 'vee-validate';
import { object, string } from 'yup';
import { I18nService } from '@/services/i18n.service';
import { ref } from 'vue';
import { Template } from '@/models/templates/Template.model';
import { GlobalModalAction } from '@/models/client/ModalAndDrawer/GlobalModalAction';

import FormTextField from '@/components/common/form/ui/FormTextField/index.vue';
import FieldGroupWrapper from '@/components/common/form/FieldGroupWrapper/index.vue';
import FormActionsWrapper from '@/components/common/form/FormActionsWrapper/index.vue';

defineProps<{
  modelValue: boolean;
}>();
const emit = defineEmits(['action']);

const validationSchema = object({
  title: string().required(I18nService.t('Validation.RequiredField')),
});

const initialValues = ref<{
  title: string;
}>({
  title: '',
});

const onSubmit: SubmissionHandler = async (values) => {
  const data = await Template.create({
    title: values.title as string,
    basic_data: null,
  });

  emit(
    'action',
    new GlobalModalAction({
      data: data?.data,
    })
  );
};
</script>
