<template>
  <UiTableWithPagination
    :max-height="400"
    table-class="income-products-table"
    hide-pagination
    :data="props.items">
    <ElTableColumn prop="product.title" :label="$t('Nomenclature.NameOfProduct')" />
    <ElTableColumn prop="product.article" :label="$t('Nomenclature.VendorCode')" width="150px" />
    <ElTableColumn prop="price" :label="$t('Income.Price')" width="150px" />
    <ElTableColumn prop="planed_count" :label="$t('Nomenclature.ExpectedQty')" width="200px" />
    <ElTableColumn prop="count" :label="$t('Nomenclature.QuantityReceived')" width="220px">
      <template #default="{ row }">
        <ElInputNumber
          v-model="row.count"
          :min="1"
          @change="$emit('table:update', row)"></ElInputNumber>
      </template>
    </ElTableColumn>

    <ElTableColumn prop="sum" :label="$t('Nomenclature.AmountSum')" width="150px">
      <template #default="{ row }">
        {{ row.price * row.count }}
      </template>
    </ElTableColumn>

    <ElTableColumn prop="actions">
      <template #default="{ row }">
        <div class="income-products-table-actions">
          <ElButton
            class="income-products-table-actions__delete-button"
            type="primary"
            text
            @click.stop="$emit('table:delete', row.product_id)">
            <template #icon>
              <UiIcon :icon="icons.CLOSE" />
            </template>
          </ElButton>
        </div>
      </template>
    </ElTableColumn>
  </UiTableWithPagination>
</template>

<script lang="ts">
export default {
  name: 'IncomeProductsTable',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { IncomeProductItem } from '@/types/api';

import * as icons from '@/enums/icons.enum.js';

defineEmits(['table:delete', 'table:update']);

const props = defineProps<{ items: IncomeProductItem[] }>();
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/devices.locales.json" />
<i18n src="@/locales/warehouse.locales.json" />
