<template>
  <ElCard
    :class="['order-biomaterial-dac', { 'order-biomaterial-dac_editor-card': isCardWithBasicData }]"
    :shadow="shadow">
    <template #header>
      <!--  Biomaterial  -->
      <div class="order-biomaterial-dac-header-item">
        <div class="order-biomaterial-dac-header-item__title">
          {{ $t('Laboratory.Biomaterial.Biomaterial') }}:
        </div>
        <div class="order-biomaterial-dac-header-item__value">
          {{ biomaterialTitle }}
        </div>
      </div>

      <!--  Sampling At  -->
      <div class="order-biomaterial-dac-header-item">
        <div class="order-biomaterial-dac-header-item__title">
          {{ $t('Laboratory.SamplingAt') }}:
        </div>
        <div class="order-biomaterial-dac-header-item__value">
          {{ biomaterial.sampling_at ?? '...' }}
        </div>
      </div>

      <!--  Status  -->
      <div class="order-biomaterial-dac-header-item">
        <div class="order-biomaterial-dac-header-item__value">
          <BiomaterialStatusTag :status="biomaterialStatus" />
        </div>
      </div>

      <!--  Actions -->
      <div class="order-biomaterial-dac-header-item order-biomaterial-dac-header__actions">
        <ElPopconfirm
          v-if="actionsOptions.cancelConfirm.isShow"
          :title="$t('Ui.PopConfirm.DefaultTitle')"
          @confirm="cancelConfirm">
          <template #reference>
            <ElButton type="danger" plain :loading="loading.cancelConfirm">
              {{ $t('Base.CancelConfirmation') }}
            </ElButton>
          </template>
        </ElPopconfirm>

        <ElButton
          v-if="actionsOptions.sampling.isShow"
          type="primary"
          :plain="actionsOptions.sampling.plain"
          @click="sampleBiomaterial">
          {{ actionsOptions.sampling.text }}
        </ElButton>

        <ElPopconfirm
          v-if="actionsOptions.confirm.isShow"
          :title="$t('Ui.PopConfirm.DefaultTitle')"
          @confirm="confirm">
          <template #reference>
            <ElButton type="primary" :loading="loading.confirm">
              {{ $t('Base.Confirm') }}
            </ElButton>
          </template>
        </ElPopconfirm>

        <ElButton v-if="actionsOptions.createStudy.isShow" plain @click="createStudy">
          {{ $t('Laboratory.Study.AddStudy') }}
        </ElButton>

        <ElPopconfirm
          v-if="actionsOptions.remove.isShow"
          :title="$t('Ui.PopConfirm.DeleteTitle')"
          @confirm="deleteBiomaterial">
          <template #reference>
            <ElButton type="danger" text :loading="loading.delete">
              <template #icon> <UiIcon :icon="icons.TRASH" /> </template>
            </ElButton>
          </template>
        </ElPopconfirm>
      </div>
    </template>

    <StudiesTable
      v-if="isCardWithStudiesTable"
      :items="studys"
      :editable="studiesIsEditable"
      @item:update="$emit('study:update', $event)"
      @item:change="$emit('study:update:api', $event)"
      @item:delete="$emit('study:delete', $event)" />
    <EditorCard
      v-if="isCardWithBasicData"
      :data="biomaterial.basic_data"
      :readonly="!studiesIsEditable"
      @update:data="$emit('editor:update', $event)"
      @change="$emit('editor:change', $event)" />
  </ElCard>
</template>

<script src="./index.js"></script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/notifications.locales.json" />
<i18n src="@/locales/laboratory.locales.json" />
<i18n src="@/locales/ui.locales.json" />
