<template>
  <UiTableWithPagination
    :loading="loading"
    :data="itemsWithPayload"
    :page="page"
    :per-page="perPage"
    :total="total"
    @update:page="$emit('update:page', $event)"
    @update:per-page="$emit('update:perPage', $event)"
    @row-click="openInvoice">
    <ElTableColumn prop="id" :label="$t('Invoices.Number')" />
    <ElTableColumn prop="_user_short_name" :label="$t('Base.Patient')" min-width="140px" />
    <ElTableColumn prop="_payment_subjects" :label="$t('Base.Services')" min-width="260px" />
    <ElTableColumn prop="_doctor_short_name" :label="$t('Doctors.Doctor')" min-width="140px" />
    <ElTableColumn
      prop="_cost"
      :label="`${$t('Base.Cost')} (${$t('Base.Sum')})`"
      min-width="140px" />

    <ElTableColumn prop="status" :label="$t('Base.Status')" min-width="120px">
      <template #default="{ row }">
        <InvoiceStatusTag :status="row.status" />
      </template>
    </ElTableColumn>

    <ElTableColumn prop="_created_date" :label="$t('DateAndTime.CreatedAt')" min-width="100px" />

    <ElTableColumn prop="actions" :label="$t('Base.Actions')" min-width="120px">
      <ElButton type="primary" text>
        <template #icon>
          <UiIcon :icon="icons.EYE" />
        </template>
        {{ $t('Base.View') }}
      </ElButton>
    </ElTableColumn>
  </UiTableWithPagination>
</template>

<script>
import * as icons from '@/enums/icons.enum.js';
import { formatPrice } from '@/utils/price.util';
import { GlobalModalCloseAction } from '@/models/client/ModalAndDrawer/GlobalModalCloseAction';

import InvoiceStatusTag from '@/components/invoices/InvoiceStatusTag/index.vue';
import CreateOrEditOrPayInvoiceModal from '@/components/invoices/CreateOrEditOrPayInvoiceModal/index.vue';
import UiTableWithPagination from '@/components/ui/UiTableWithPagination/index.vue';

export default {
  name: 'InvoicesTable',
  components: { UiTableWithPagination, InvoiceStatusTag },
  emits: ['update:perPage', 'update:page', 'item:edit'],
  props: {
    /** @property {Array<Invoice|object>} items */
    items: Array,
    loading: Boolean,
    page: Number,
    perPage: Number,
    total: Number,
    hiddenProp: Boolean,
    columns: Object,
  },
  computed: {
    itemsWithPayload() {
      return this.items.map((elem) => ({
        ...elem,
        _user_short_name: elem.user
          ? elem.user.name
              .split(' ')
              .map((item, index) => (index > 0 ? `${item.slice(0, 1)}.` : `${item} `))
              .join('')
          : '',
        _doctor_short_name: elem.doctor
          ? elem.doctor.name
              .split(' ')
              .map((item, index) => (index > 0 ? `${item.slice(0, 1)}.` : `${item} `))
              .join('')
          : '',
        _payment_subjects: elem.payment_subjects
          ? elem.payment_subjects.map((item) => item.subject.title).join('\n')
          : '',
        _cost: formatPrice({ price: elem.discounted_amount ?? elem.total_amount }),
        _created_date: elem.created_at.split(' ')[0],
      }));
    },
  },

  methods: {
    async openInvoice(row) {
      const action = await this.$store.dispatch('modalAndDrawer/openModal', {
        component: CreateOrEditOrPayInvoiceModal,
        payload: { id: row.id },
      });

      if (action instanceof GlobalModalCloseAction) return;
      this.$emit('item:edit', action.data.invoice);
    },
  },
  setup: () => ({
    icons: icons,
  }),
};
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/doctors.locales.json" />
<i18n src="@/locales/dateAndTime.locales.json" />
<i18n src="@/locales/invoices.locales.json" />
<i18n src="./index.locales.json" />
