<template>
  <FieldGroupWrapper :title="$t('Salary.SalaryDetails')">
    <div class="salary-details-wrapper">
      <div
        class="salary-details-field-group"
        v-for="(paymentDetail, idx) in paymentDetails"
        v-show="!paymentDetail.deleted"
        :key="paymentDetail.id">
        <FormTextField
          :field-name="`payment_details[${idx}].title`"
          maxlength="255"
          :in-column="false"
          :disabled="props.disabled" />
        <FormTextField
          :field-name="`payment_details[${idx}].amount`"
          :mask-name="'int-12'"
          :in-column="false"
          :disabled="props.disabled" />
        <FormSwitchField
          :field-name="`payment_details[${idx}].type`"
          active-value="plus"
          inactive-value="minus"
          active-color="#13ce66"
          inactive-color="#ff4949"
          active-text="Добавить"
          inactive-text="Вычесть"
          :disabled="props.disabled" />
        <FormNumberField
          :field-name="`payment_details[${idx}].count`"
          :in-column="false"
          :min="1"
          :disabled="props.disabled" />
        <div class="salary-details-field-group__total">
          <span
            :class="[
              'salary-details-field-group__total-prefix',
              {
                'salary-details-field-group__total-prefix_plus': paymentDetail.type === 'plus',
                'salary-details-field-group__total-prefix_minus': paymentDetail.type === 'minus',
              },
            ]"
            >{{ paymentDetail.type === 'plus' ? '+' : '-' }}</span
          >
          <span>{{ (paymentDetail.amount ?? 0) * (paymentDetail.count ?? 0) }}</span>
        </div>
        <ElPopconfirm
          :title="$t('Ui.PopConfirm.DefaultTitle')"
          :disabled="!paymentDetail.can_deleted || props.disabled"
          @confirm="onRemovePaymentDetailsBtnClick(paymentDetail)">
          <template #reference>
            <ElButton
              class="salary-details-field-group__delete-button"
              size="large"
              type="danger"
              text
              :disabled="!paymentDetail.can_deleted || props.disabled">
              &#10006;
            </ElButton>
          </template>
        </ElPopconfirm>
      </div>
    </div>
    <div class="salary-details-actions">
      <ElButton
        type="primary"
        text
        link
        @click="onAddPaymentDetailsBtnClick"
        :disabled="props.disabled">
        {{ `+ ${$t('Services.AddExpenseType')}` }}
      </ElButton>
    </div>
    <FieldGroupDivider />
    <FormTotalAmount :total-amount="paymentDetailsTotalSum" />
  </FieldGroupWrapper>
</template>

<script lang="ts">
export default {
  name: 'SalaryDetails',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { computed } from 'vue';
import { useField } from 'vee-validate';
import { SalaryFormData } from '@/models/accounting/Salary.model';

import FormTextField from '@/components/common/form/ui/FormTextField/index.vue';
import FormNumberField from '@/components/common/form/ui/FormNumberField/index.vue';
import FormSwitchField from '@/components/common/form/ui/FormSwitchField/index.vue';
import FieldGroupDivider from '@/components/common/form/FieldGroupDivider/index.vue';
import FormTotalAmount from '@/components/common/form/FormTotalAmount/index.vue';
import FieldGroupWrapper from '@/components/common/form/FieldGroupWrapper/index.vue';

const props = defineProps<{
  disabled: boolean;
}>();

const { value: paymentDetails, setValue: setPaymentDetails } =
  useField<SalaryFormData['payment_details']>('payment_details');

const paymentDetailsTotalSum = computed(
  () =>
    paymentDetails.value?.reduce((acc, curr) => {
      const amount = curr.amount ?? 0;
      const count = curr.count ?? 0;

      return curr.type === 'plus' ? acc + amount * count : acc - amount * count;
    }, 0) ?? 0
);

const onAddPaymentDetailsBtnClick = () => {
  setPaymentDetails([
    ...paymentDetails.value,
    { id: self.crypto.randomUUID(), type: 'plus', can_deleted: true, count: 1 },
  ]);
};

const onRemovePaymentDetailsBtnClick = (values: SalaryFormData['payment_details'][0]) => {
  const idx = paymentDetails.value.findIndex((item) => item.id === values.id);
  setPaymentDetails([
    ...paymentDetails.value.slice(0, idx),
    { ...values, deleted: true },
    ...paymentDetails.value.slice(idx + 1),
  ]);
};
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/accounting.locales.json" />
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/ui.locales.json" />
