import axios from 'axios';
import { CRUDModel } from '@/models/CRUD.model';
import { ApiService } from '@/services/api.service';
import { Device } from '@/models/Device.model';
import { ElNotification } from 'element-plus';
import { EmployeeDto, MetaDto, UserFullDto, WorkTrackingDto } from '@/types/api';
import { QueryParams } from '@/types/common';
import { mergeOrCreateQuery } from '@/utils/http.util';
import { deleteEmptyValueKeys } from '@/utils/object.util';

export type EmployeeFormData = Omit<
  EmployeeDto,
  'role' | 'id' | 'salary' | 'amount_last_salary' | 'doctor'
> & {
  id?: EmployeeDto['id'] | null;
  role?: EmployeeDto['role'] | null;
  salary?: EmployeeDto['salary'] | null;
  amount_last_salary?: EmployeeDto['amount_last_salary'] | null;
  doctor?: (EmployeeDto['doctor'] & { speciality_ids?: number[] }) | null;
};

export class User extends CRUDModel {
  static modelName = 'user';
  static tableName = 'users';

  role: EmployeeFormData['role'];
  name: EmployeeFormData['name'];
  is_working: EmployeeFormData['is_working'];
  phone: EmployeeFormData['phone'];
  short_working_time: EmployeeFormData['short_working_time'];
  salary: EmployeeFormData['salary'];
  address: EmployeeFormData['address'];
  appointment_commission: EmployeeFormData['appointment_commission'];
  birthdate: EmployeeFormData['birthdate'];
  doctor_recommendation_commission: EmployeeFormData['doctor_recommendation_commission'];
  doctor?: EmployeeFormData['doctor'];
  working_time: EmployeeFormData['working_time'];
  date_last_salary: EmployeeFormData['date_last_salary'];
  amount_last_salary: EmployeeFormData['amount_last_salary'];

  constructor(payload?: EmployeeFormData) {
    super(payload);

    this.role = payload?.role ?? '';
    this.name = payload?.name ?? '';
    this.is_working = payload?.is_working ?? true;
    this.phone = payload?.phone ?? '';
    this.short_working_time = payload?.short_working_time ?? '';
    this.salary = payload?.salary ?? null;
    this.address = payload?.address ?? '';
    this.appointment_commission = payload?.appointment_commission ?? '';
    this.birthdate = payload?.birthdate ?? '';
    this.doctor_recommendation_commission = payload?.doctor_recommendation_commission ?? '';
    this.doctor = payload?.doctor
      ? {
          ...payload.doctor,
          speciality_ids: payload.doctor.specialties.map((item) => item.id),
        }
      : null;
    this.working_time = payload?.working_time ?? null;
    this.date_last_salary = payload?.date_last_salary ?? '';
    this.amount_last_salary = payload?.amount_last_salary ?? null;
  }

  static async me() {
    const response = await ApiService.get<{ data: UserFullDto }>(`${this.tableName}/me`);
    return {
      response: response,
      data: response.data,
      user: response.data.data,
    };
  }

  static async getDevices(deviceid: string, query: QueryParams) {
    return super.find({ _url: `${this.tableName}/${deviceid}/${Device.tableName}`, ...query });
  }

  static async getKey(userId: EmployeeDto['id']) {
    try {
      const response = await ApiService.post(`${this.tableName}/${userId}/key`);

      return {
        response: response,
        data: response.data,
      };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: axios.isAxiosError(err) ? err.message : String(err),
      });
    }
  }

  static async getVisitLog(query: QueryParams = {}) {
    try {
      const response = await ApiService.get<{ data: WorkTrackingDto; meta: MetaDto }>(
        mergeOrCreateQuery({
          url: `b2g/${this.tableName}/tracking`,
          query: deleteEmptyValueKeys(query),
        })
      );

      return {
        response: response,
        data: response.data,
      };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: axios.isAxiosError(err) ? err.message : String(err),
      });
    }
  }

  static enum = {
    genders: {
      MAN: 'man',
      Woman: 'woman',
    },
    roles: {
      Admin: 'admin',
      Director: 'director',
      Patient: 'patient',
      Manager: 'manager',
      Doctor: 'doctor',
      Laboratory: 'laboratory',
      Nurse: 'nurse',
      Warehouse: 'warehouse',
      Accountant: 'accountant',
    },
  };
}
