import { onlyLoggedInMiddleware } from '@/middlewares/onlyLoggedIn.middleware';
import { onlyDirectorMiddleware } from '@/middlewares/onlyDirectorMiddleware';

export const STATISTIC_DASHBOARD_ROUTE = {
  name: 'STATISTIC_DASHBOARD',
  path: '/statistic/dashboard',
  component: 'VStatisticDashboard',
  beforeEnter: [onlyLoggedInMiddleware, onlyDirectorMiddleware],
  meta: {
    title: 'Views.StatisticDashboard.Title',
  },
};

export const routes = [STATISTIC_DASHBOARD_ROUTE];
