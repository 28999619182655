<template>
  <LayoutByUserRole content-class="v-patients-map" fix-height>
    <div class="v-patients-map__header">
      <ElDatePicker
        class="v-patients-map__date"
        v-model="date.value"
        :format="DATE_FORMAT"
        type="daterange"
        unlink-panels
        :value-format="DATE_FORMAT"
        :placeholder="$t('DateAndTime.Date')"
        :disabled="isLoading" />

      <div class="v-patients-map__age-data">
        <span>{{ $t('Analytics.AgeFrom') }}</span>
        <UiSelect
          :options="ageOptions"
          v-model="ageFrom.value"
          class="v-patients-map__age"
          :disabled="isLoading" />
        <span>{{ $t('Analytics.To') }}</span>
        <UiSelect
          :options="ageOptions"
          v-model="ageTo.value"
          class="v-patients-map__age"
          :disabled="isLoading" />
      </div>
      <UiSelect
        class="v-patient-default-select-inputs__group-select"
        v-model="patientGroup.value"
        :placeholder="$t('Analytics.Group')"
        :options="patientGroupOptions"
        :disabled="isLoading" />
      <UiModelsAutocompleteSearch
        v-model="disease.value"
        :model-for-use="DiseaseCode"
        :placeholder="$t('Analytics.Diagnosis')"
        :clearable="true"
        label="title"
        value="code"
        :disabled="isLoading" />
    </div>
    <div class="v-patients-map__map">
      <GeoMap
        :key="currentKey"
        :locale="locale"
        :initial-center="[39.662577, 66.933263]"
        :marker-properties="patients"
        :zoom="13"
        @marker-click="goToPatient" />
    </div>
  </LayoutByUserRole>
</template>
<script lang="ts">
export default {
  name: 'VPatientsMap',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { useRouter } from 'vue-router';
import { DATE_FORMAT } from '@/config/dateAndTime.config';
import { I18nService } from '@/services/i18n.service';
import { getMonthPeriod } from '@/utils/dateAndTime.utils';
import { useDatePeriod } from '@/hooks/query';
import { useQuery } from '@/hooks/useQuery.hook';
import { Analytics } from '@/models/analytics/analytics.model';
import { computed, ref, watch } from 'vue';
import { PatientsMapInfoDto } from '@/types/api';
import { compareQueriesThenLoadData } from '@/utils/router.utils';
import { Patient } from '@/models/Patient.model';
import { ClientStorageService } from '@/services/clientStorage.service';
import { DiseaseCode } from '@/models/DiseaseCode.model';
import { PATIENT_ROUTE } from '@/router/patients.routes';
import { MarkerPropertiesType } from '@/components/common/GeoMap/types';

import LayoutByUserRole from '@/components/layouts/LayoutByUserRole/index.vue';
import GeoMap from '@/components/common/GeoMap/index.vue';

const router = useRouter();
const date = useDatePeriod(getMonthPeriod());
const ageFrom = useQuery({ field: 'age_from', defaultValue: 0 });
const ageTo = useQuery({ field: 'age_to', defaultValue: 120 });
const patientGroup = useQuery({ field: 'group', defaultValue: '' });
const disease = useQuery({ field: 'disease_code', defaultValue: '' });
const currentPage = ref(1);
const ageOptions = Array.from({ length: 120 }, (_, i) => {
  return {
    label: i.toString(),
    value: i,
  };
});
const isLoading = ref(false);
const currentKey = ref(0);
const locale = ClientStorageService.getItem('LOCALE');
const patients = ref<MarkerPropertiesType[]>([]);
const patientGroupOptions = [
  { value: Patient.enum.groups.FirstGroup, label: I18nService.t('Patients.Groups.first_group') },
  { value: Patient.enum.groups.SecondGroup, label: I18nService.t('Patients.Groups.second_group') },
  { value: Patient.enum.groups.ThirdGroup, label: I18nService.t('Patients.Groups.third_group') },
  { value: Patient.enum.groups.FourthGroup, label: I18nService.t('Patients.Groups.fourth_group') },
];

const query = computed(() => {
  return {
    start_at: date.value[0],
    end_at: date.value[1],
    age_from: ageFrom.value,
    age_to: ageTo.value,
    group: patientGroup.value,
    disease_code: disease.value,
  };
});

const getPatientsMapInfo = async () => {
  isLoading.value = true;
  const response = await Analytics.getPatientsMapInfo(query.value);

  if (response?.data?.length) {
    patients.value.push(...processDataMap(response.data));
  }
  currentPage.value++;

  if (response && response.meta?.last_page > currentPage.value) {
    await getPatientsMapInfo();
  }
  isLoading.value = false;
};

const processDataMap = (patientsInfo: PatientsMapInfoDto[]): MarkerPropertiesType[] => {
  const processedData: MarkerPropertiesType[] = [];

  patientsInfo.forEach((patient) => {
    processedData.push({
      id: patient.id,
      properties: {
        hitContent: `${patient.name} ${patient.birthdate}`,
      },
      coords: [patient.latitude, patient.longitude],
    });
  });

  return processedData;
};

const goToPatient = (id: number) => {
  window.open(
    router.resolve({
      name: PATIENT_ROUTE.name,
      params: {
        id: id,
      },
    }).href,
    '_blank'
  );
};

watch(
  query,
  (value, oldValue) => {
    currentPage.value = 1;
    patients.value = [];
    currentKey.value++;

    compareQueriesThenLoadData({
      query: value,
      oldQuery: oldValue,
      getData: getPatientsMapInfo,
    });
  },
  { deep: true, immediate: true }
);
</script>

<style lang="scss" src="./index.scss" />
