<template>
  <UiTableWithPagination
    class="references-values-table"
    :data="dataWithPayload"
    :total="total"
    :loading="loading"
    :page="page"
    :per-page="perPage"
    @update:page="$emit('update:page', $event)"
    @update:per-page="$emit('update:perPage', $event)"
    @row-click="preview">
    <ElTableColumn prop="_value" :label="$t('Base.Value')" />
    <ElTableColumn prop="norm_text" :label="$t('Base.Text')" />
    <ElTableColumn prop="analysis.title" :label="$t('Laboratory.Analysis.Analysis')" />
    <ElTableColumn prop="created_at" :label="$t('DateAndTime.CreatedAt')" />

    <ElTableColumn :label="$t('Base.Actions')" width="290px">
      <template #default="{ row }">
        <div class="references-values-table__actions">
          <ElButton type="primary" plain @click.stop="edit(row)">
            {{ $t('Base.Edit') }}
          </ElButton>

          <ElButton
            type="danger"
            plain
            :loading="deleteLoading && deletingItemId === row.id"
            @click.stop="remove(row)">
            {{ $t('Base.Delete') }}
          </ElButton>
        </div>
      </template>
    </ElTableColumn>
  </UiTableWithPagination>
</template>

<script>
import axios from 'axios';
import { ReferencesValue } from '@/models/laboratory/ReferencesValue.model';
import { GlobalModalCloseAction } from '@/models/client/ModalAndDrawer/GlobalModalCloseAction';

import UiTableWithPagination from '@/components/ui/UiTableWithPagination/index.vue';
import CreateOrEditReferencesValueModal from '@/components/laboratory/referencesValues/CreateOrEditReferencesValueModal/index.vue';

export default {
  name: 'ReferencesValuesTable',
  emits: ['update:page', 'update:perPage', 'item:update', 'item:delete'],
  components: { UiTableWithPagination },
  props: {
    /** @type {Array<ReferencesValue>} data */
    data: Array,
    total: Number,
    loading: Boolean,
    page: Number,
    perPage: Number,
  },
  data() {
    return {
      deleteLoading: false,
      deletingItemId: null,
    };
  },
  computed: {
    dataWithPayload() {
      return this.data.map((referencesValue) => ({
        ...referencesValue,
        _value: this.getRefValue(referencesValue),
      }));
    },
  },

  methods: {
    getRefValue(referencesValue) {
      if (referencesValue.start_value || referencesValue.end_value) {
        return `${referencesValue.start_value ?? '...'} - ${referencesValue.end_value ?? '...'}`;
      }
      return '...';
    },

    preview(referencesValue) {
      this.edit(referencesValue);
    },
    async edit(referencesValue) {
      const action = await this.$store.dispatch('modalAndDrawer/openModal', {
        component: CreateOrEditReferencesValueModal,
        payload: {
          data: referencesValue,
        },
      });

      if (!(action instanceof GlobalModalCloseAction))
        this.$emit('item:update', action.data.referencesValue);
    },

    async remove(referencesValue) {
      if (this.deleteLoading) return;
      this.deleteLoading = true;
      this.deletingItemId = referencesValue.id;

      try {
        await ReferencesValue.delete({ id: referencesValue.id });
        this.$emit('item:delete', referencesValue);
        this.$notify({
          type: 'success',
          title: this.$t('Notifications.SuccessDeleted'),
        });
      } catch (err) {
        this.$notify({
          type: 'error',
          title: axios.isAxiosError(err) ? err.message : String(err),
        });
      }

      this.deleteLoading = false;
      this.deletingItemId = null;
    },
  },
};
</script>

<style lang="scss" src="./index.scss"></style>
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/notifications.locales.json" />
<i18n src="@/locales/laboratory.locales.json" />
<i18n src="@/locales/dateAndTime.locales.json" />
