<template>
  <LayoutByUserRole content-class="v-home-visit-content" fix-height>
    <LayoutContentHeader class="v-home-visit-content-header">
      <ElDatePicker
        class="v-home-visit-content-header__date-picker"
        v-model="date.value"
        type="daterange"
        unlink-panels
        :format="DATE_FORMAT"
        :value-format="DATE_FORMAT"
        :start-placeholder="$t('DateAndTime.StartDate')"
        :end-placeholder="$t('DateAndTime.EndDate')" />

      <UiSelect
        :options="statusOptions"
        v-model="userStatuses.value"
        multiple
        :placeholder="$t('Base.Status')" />

      <UiModelsAutocompleteSearch
        class="v-home-visit-content-header__doctor-select"
        v-model="doctor.value.id"
        :model-for-use="Doctor"
        :default-item="doctor.value"
        :placeholder="$t('Appointments.SelectDoctor')"
        clearable
        @select="doctor.value = $event" />

      <template #actions>
        <a :href="exportDataURL" download class="v-home-visit-content__report">
          <ElButton plain text>
            <template #icon>
              <UiIcon :icon="icons.DOWNLOAD" />
            </template>
            {{ $t('Base.DownloadExcel') }}
          </ElButton>
        </a>
        <ElButton
          class="v-home-visit-content-header__create-button"
          type="primary"
          @click="createAppointment">
          {{ $t('Appointments.CreateAppointment') }}
        </ElButton>
      </template>
    </LayoutContentHeader>

    <HomeVisitsTable
      :loading="loading"
      :items="tableItems"
      :total="tableItemsTotal"
      v-model:page="page.value"
      v-model:per-page="perPage.value" />
  </LayoutByUserRole>
</template>

<script lang="ts">
export default {
  name: 'VHomeVisit',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { ref, computed, watch, onMounted } from 'vue';
import { I18nService } from '@/services/i18n.service';
import { useStore } from 'vuex';
import { useDatePeriod, usePerPage, usePage, useUser } from '@/hooks/query';
import { compareQueriesThenLoadData } from '@/utils/router.utils';
import { DATE_FORMAT } from '@/config/dateAndTime.config';
import { getCurrentDate, getMonthPeriod } from '@/utils/dateAndTime.utils';
import { Doctor } from '@/models/Doctor.model';
import { Appointment } from '@/models/appointment/Appointment.model';
import { useQuery } from '@/hooks/useQuery.hook';
import { GlobalModalCloseAction } from '@/models/client/ModalAndDrawer/GlobalModalCloseAction';
import { useRoute } from 'vue-router';
import { mergeOrCreateQuery } from '@/utils/http.util';
import { deleteEmptyValueKeys } from '@/utils/object.util';
import { ApiService } from '@/services/api.service';
import { DeviceService } from '@/services/device.service';
import * as icons from '@/enums/icons.enum';
import debounce from 'lodash.debounce';

import LayoutByUserRole from '@/components/layouts/LayoutByUserRole/index.vue';
import LayoutContentHeader from '@/components/layouts/assets/LayoutContentHeader/index.vue';
import HomeVisitsTable from '@/components/appointments/HomeVisitsTable/index.vue';
import UiSelect from '@/components/ui/UiSelect/index.vue';
import CreateOrEditAppointmentModal from '@/components/appointments/CreateOrEditAppointmentModal/index.vue';
import UiModelsAutocompleteSearch from '@/components/ui/UiModelsAutocompleteSearch/index.vue';

const filteredStatuses = computed(() => {
  return Object.values(Appointment.enum.statuses).filter(
    (value) =>
      value !== Appointment.enum.statuses.Created && value !== Appointment.enum.statuses.Waiting
  );
});

const statusOptions = computed(() =>
  Object.values(filteredStatuses.value).map((value) => ({
    value,
    label: I18nService.t(`Appointments.Statuses.${value}`),
  }))
);

const userStatuses = useQuery({ field: 'user_state_ids', valueIsArray: true });

const store = useStore();
const loading = ref(false);
const page = usePage();
const perPage = usePerPage();
const route = useRoute();

const date = useDatePeriod(getMonthPeriod());

const doctor = useUser({
  fieldNames: {
    id: 'doctor_id',
    name: 'doctor_name',
  },
});
const tableItems = ref([]);
const tableItemsTotal = ref(0);

const paramsId = computed(() => route.params.id);

const query = computed(() => ({
  page: page.value,
  per_page: perPage.value,
  start_at: date.value[0],
  end_at: date.value[1],
  doctor_id: doctor.value.id,
  statuses: userStatuses.value,
}));

const getByVisit = async () => {
  loading.value = true;
  const result = await Appointment.getByVisit(query.value);

  if (result) {
    tableItems.value = result.data;
    tableItemsTotal.value = result.response.data.meta.total;
  }

  loading.value = false;
};

const currentDoctor = computed(() => {
  const user = store.state.auth.user;

  if (user && user.role === 'doctor') {
    return { id: user.doctor.id, name: user.name };
  } else {
    return undefined;
  }
});

const createAppointment = async () => {
  const action = await store.dispatch('modalAndDrawer/openModal', CreateOrEditAppointmentModal);

  if (action instanceof GlobalModalCloseAction) return;
  const currentDate = getCurrentDate();
  if (
    (!action.data.appointment.start_at ||
      action.data.appointment.start_at.split(' ')[0] === currentDate) &&
    (!doctor.value || action.data.appointment.doctor?.id === doctor.value.id) &&
    action.data.appointment.type === Appointment.enum.types.Visit
  ) {
    await store.dispatch('homeVisits/addItem', action.data.appointment);
  }
};

watch(
  query,
  (value, oldValue) => {
    compareQueriesThenLoadData({
      query: value,
      oldQuery: oldValue,
      getData: debounce(getByVisit, 100),
      resetPage: page.reset,
    });
  },
  { deep: true, immediate: true }
);

watch(
  paramsId,
  () => {
    if (currentDoctor.value) {
      doctor.value = currentDoctor.value;
    }
  },
  { immediate: true }
);

onMounted(() => {
  if (currentDoctor.value) {
    doctor.value = currentDoctor.value;
  }
});

const exportDataURL = computed(() => {
  return mergeOrCreateQuery({
    url: Appointment.exportDataURLVisit,
    query: deleteEmptyValueKeys({
      ...query.value,
      lang: I18nService.getLocale(),
      // @ts-expect-error: Исправить.
      token: ApiService.getToken(),
      device_id: DeviceService.getDeviceId(),
    }),
  });
});
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/warehouse.locales.json" />
<i18n src="@/locales/dateAndTime.locales.json" />
<i18n src="@/locales/accounting.locales.json" />
<i18n src="@/locales/appointments.locales.json" />
