import { StoreCRUDModule } from '@/store/assets/StoreCRUDModule';
import { ActionContext, Store as VuexStore, CommitOptions, DispatchOptions } from 'vuex';
import { RootStateType } from '@/store';
import { PatientFullDto } from '@/types/api';

export type PatientsState = {
  patientInfo: PatientFullDto | null;
};

type ContextType = ActionContext<PatientsState, RootStateType>;

type Mutations = {
  ['patients/SET_PATIENT'](state: PatientsState, payload: PatientFullDto): void;
};

type ActionAugments = Omit<ActionContext<PatientsState, RootStateType>, 'commit'> & {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
};

export type Actions = {
  ['patients/setPatient'](context: ActionAugments, payload: PatientFullDto): void;
};

export default new StoreCRUDModule({
  name: 'patients',
  namespaced: true,
  state: (): PatientsState => {
    return {
      patientInfo: null,
    };
  },
  mutations: {
    SET_PATIENT(state: PatientsState, payload: PatientFullDto) {
      state.patientInfo = payload;
    },
  },
  actions: {
    setPatient({ commit }: ContextType, payload: PatientFullDto) {
      commit('SET_PATIENT', payload);
    },
  },
});

// Типизация стора чтобы в файлах .vue работала типизация модуля
// TODO добавить такой же тип для StoreCRUDModule
export type PatientsStore<S = PatientsState> = Omit<
  VuexStore<S>,
  'getters' | 'commit' | 'dispatch'
> & {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
    key: K,
    payload: P,
    options?: CommitOptions
  ): ReturnType<Mutations[K]>;
} & {
  dispatch<K extends keyof Actions>(
    key: K,
    payload?: Parameters<Actions[K]>[1],
    options?: DispatchOptions
  ): ReturnType<Actions[K]>;
};
