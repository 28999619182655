<template>
  <ElFormItem
    :class="[
      'ui-input-with-validation',
      {
        'ui-input-with-validation_in-column': props.inColumn,
        'ui-input-with-validation_only-value': props.onlyValue && props.disabled,
      },
    ]"
    :error="errorMessage"
    :label="props.label"
    :required="props.required">
    <template v-if="isLabelShow" #label>
      <slot name="label">
        <div class="ui-input-with-validation__label-text">{{ label }}</div>
        <Tooltip v-if="!!props.description" :description="props.description" />
      </slot>
    </template>
    <div v-if="props.onlyValue && props.disabled">
      <slot>{{ value }}</slot>
    </div>
    <ElInput
      v-else
      v-model="value"
      v-bind="$attrs"
      :validate-event="false"
      v-maska:[maskOptions]
      :disabled="props.disabled"
      :data-maska="dataMmaska"
      :placeholder="props.placeholder || $t('Base.PleaseInput')">
      <template v-if="isAppendShow" #append>
        <slot name="append" />
      </template>
    </ElInput>
    <div v-if="isDeleteActionShow" class="ui-input-with-validation__delete-action">
      <slot name="delete-action" />
    </div>
  </ElFormItem>
</template>

<script lang="ts">
export default {
  name: 'FormTextField',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { useField } from 'vee-validate';
import { vMaska, MaskOptions } from 'maska';
import { computed, useSlots } from 'vue';

import Tooltip from '@/components/common/Tooltip/index.vue';

const props = withDefaults(
  defineProps<{
    inColumn?: boolean;
    onlyValue?: boolean;
    disabled?: boolean;
    required?: boolean;
    fieldName: string;
    label?: string;
    maskName?:
      | 'float-2.2'
      | 'float-3.2'
      | 'float-12.2'
      | 'int-12'
      | 'int-12-with-minus'
      | 'float-12.2-with-minus'
      | '';
    placeholder?: string;
    description?: string | null;
  }>(),
  {
    inColumn: true,
    onlyValue: false,
    disabled: false,
    required: false,
    maskName: '',
    label: '',
    placeholder: '',
    description: '',
  }
);

const { value, errorMessage, setValue } = useField<string | number | undefined>(props.fieldName);

const slots = useSlots();
const isAppendShow = computed(() => !!slots.append);
const isLabelShow = computed(() => !!props.label);
const isDeleteActionShow = computed(() => !!slots['delete-action'] && props.inColumn);

const maskOptions: MaskOptions = {
  tokens: {
    '0': { pattern: /\d/, multiple: true },
    '9': { pattern: /\d/, optional: true },
    '-': { pattern: /-/, optional: true },
  },
};

const dataMmaska = computed(() => {
  switch (props.maskName) {
    case 'float-2.2':
      return '#9.99';
    case 'float-3.2':
      return '#99.99';
    case 'float-12.2':
      return '#99999999999.99';
    case 'float-12.2-with-minus':
      return '-#99999999999.99';
    case 'int-12':
      return '############';
    case 'int-12-with-minus':
      return '-############';
    default:
      return '';
  }
});

defineExpose({
  setValue,
});
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/base.locales.json" />
