import { ApiService } from '@/services/api.service';
import { VaccinationItemDto } from '@/types/api';
import { getApiErrorMessage, mergeOrCreateQuery } from '@/utils/http.util';
import { ElNotification } from 'element-plus';
import { QueryParams } from '@/types/common';
import { deleteEmptyValueKeys } from '@/utils/object.util';

export class Vaccination {
  static modelName = 'vaccine';
  static tableName = 'vaccines';

  static async getItems(query: QueryParams) {
    try {
      const response = await ApiService.get<{ data: VaccinationItemDto[] }>(
        mergeOrCreateQuery({
          url: `b2g/${this.tableName}`,
          query: deleteEmptyValueKeys(query),
        })
      );

      return {
        data: response.data,
      };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: getApiErrorMessage(err),
      });
    }
  }
}
