import { CRUDModel } from '@/models/CRUD.model';
import { MetaDto } from '@/types/api';
import { QueryParams } from '@/types/common';
import { ApiService } from '@/services/api.service';
import { mergeOrCreateQuery } from '@/utils/http.util';
import { deleteEmptyValueKeys } from '@/utils/object.util';

export type AbstractBiomaterialDto = {
  id: number;
  title: string;
};

export type AbstractBiomaterialFormData = {
  id: AbstractBiomaterialDto['id'] | null;
  title: AbstractBiomaterialDto['title'] | '';
};

export class AbstractBiomaterial extends CRUDModel {
  static modelName = 'biomaterial';
  static tableName = 'biomaterials';

  id: AbstractBiomaterialDto['id'] | null;
  title: AbstractBiomaterialDto['title'] | '';

  constructor(payload?: AbstractBiomaterialFormData) {
    super();
    this.id = payload?.id ?? null;
    this.title = payload?.title ?? '';
  }

  static async getBiomaterials(query: QueryParams) {
    const response = await ApiService.get<{ data: AbstractBiomaterialDto[]; meta: MetaDto }>(
      mergeOrCreateQuery({
        url: `b2g/${this.tableName}/abstracts`,
        query: deleteEmptyValueKeys(query),
      })
    );

    return {
      response: response,
      data: response.data,
      meta: response.data.meta,
    };
  }
}
