import { onlyLoggedInMiddleware } from '@/middlewares/onlyLoggedIn.middleware';
import { byUserRolesMiddleware } from '@/middlewares/byUserRoles.middleware';
import { User } from '@/models/User.model';

export const PATRONAGE_PATH_PREFIX = 'patronages';
export const PATRONAGES_ROUTE = {
  name: 'PATRONAGES',
  path: '/patronages',
  component: 'VPatronages',
  beforeEnter: [onlyLoggedInMiddleware],
};

export const PATRONAGE_PLANNING_ROUTE = {
  name: 'PATRONAGE_PLANNING',
  path: '/patronage-planning',
  component: 'VPatronagePlanning',
  beforeEnter: [onlyLoggedInMiddleware],
};

export const PATRONAGE_ROUTE = {
  name: 'PATRONAGE',
  path: `/patronage/:id`,
  component: 'VPatronagePatient',
  props: true,
  beforeEnter: [
    onlyLoggedInMiddleware,
    byUserRolesMiddleware([
      User.enum.roles.Doctor,
      User.enum.roles.Director,
      User.enum.roles.Nurse,
    ]),
  ],
  meta: {
    title: 'Base.Doctors',
  },
};

export const routes = [PATRONAGES_ROUTE, PATRONAGE_PLANNING_ROUTE, PATRONAGE_ROUTE];
