<template>
  <ElFormItem
    :class="[
      'ui-input-with-validation',
      {
        'ui-input-with-validation_in-column': props.inColumn,
        'ui-input-with-validation_only-value': props.onlyValue && props.disabled,
      },
    ]"
    :error="errorMessage"
    :label="props.label"
    :required="props.required">
    <template v-if="isLabelShow" #label>
      <slot name="label">
        <div class="ui-input-with-validation__label-text">{{ props.label }}</div>
        <Tooltip v-if="!!props.description" :description="props.description" />
        <div v-if="isLabelActionsShow" class="ui-input-with-validation__label-actions">
          <slot name="label-actions" />
        </div>
      </slot>
    </template>
    <div class="ui-input-with-validation-info">
      <slot>{{ formattedValue }}</slot>
    </div>
  </ElFormItem>
</template>

<script lang="ts">
export default {
  name: 'FormInfoField',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { useField } from 'vee-validate';
import { computed, useSlots } from 'vue';
import { formatPrice } from '@/utils/price.util';

import Tooltip from '@/components/common/Tooltip/index.vue';
import { I18nService } from '@/services/i18n.service';

const props = withDefaults(
  defineProps<{
    inColumn?: boolean;
    onlyValue?: boolean;
    disabled?: boolean;
    fieldName: string;
    label?: string;
    required?: boolean;
    pattern?: string;
    placeholder?: string;
    description?: string;
    format?: 'price' | null;
  }>(),
  {
    inColumn: true,
    onlyValue: false,
    disabled: false,
    label: '',
    required: false,
    pattern: '',
    placeholder: '',
    description: '',
    format: null,
  }
);

const { value, errorMessage } = useField<string | number | undefined>(props.fieldName);

const formattedValue = computed(() => {
  if (props.format === 'price') {
    return `${formatPrice({ price: value.value })} ${I18nService.t('Base.Sum')}`;
  }
  return value.value;
});

const slots = useSlots();
const isLabelShow = computed(() => !!props.label);
const isLabelActionsShow = computed(() => !!slots['label-actions'] && props.inColumn);
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/base.locales.json" />
