export type AddPregnancyFormData = {
  first_day_menstruation: string;
  count_previous_births: number;
  count_successful_births: number;
  planned_date_birth: string;
};

export type DeregisterPregnancyFormData = {
  status: string;
  closed_at: string;
};

export type AddPregnancyRequestDto = {
  user_id: number;
  first_day_menstruation: string;
  planned_date_birth: string;
  count_previous_births: number;
  count_successful_births: number;
};

export type PregnancyDto = {
  id: number;
  status: string;
  count_previous_births: number;
  count_successful_births: number;
  first_day_menstruation: string;
  planned_date_birth: string;
  date_birth: string;
  closed_at: string;
  created_at: string;
  count_weeks: number;
  pregnancy_number: number;
};

export type PregnancyUpdateRequestDto = {
  status: string;
  closed_at: string;
};

export enum PregnancyStatuses {
  'gave_birth' = 'gave_birth',
  'abort' = 'abort',
  'misbirth' = 'misbirth',
  'child_deathed' = 'child_deathed',
  'mother_deathed' = 'mother_deathed',
}

export type PregnancyType = 'gave_birth' | 'misbirth' | 'abort';

export type PregnantsByGroupDto = {
  name: string;
  date_prev_pregnancy: string | null;
  date_survey_date: string;
  first_day_menstruation: string;
  group: string | null;
  id: number;
  pathology: string | null;
  planned_date_birth: string;
  pregnancy_week: number;
  closed_at: string;
};
