{
  "en": {
    "GoToAppointment": "Back to reception"
  },
  "ru": {
    "GoToAppointment": "Вернуться к приёму"
  },
  "uz": {
    "GoToAppointment": "Qabulga qaytish"
  }
}