<template>
  <UiTableWithPagination
    table-class="templates-table"
    :data="data"
    :loading="loading"
    :page="page"
    :per-page="perPage"
    :total="total"
    @update:page="$emit('update:page', $event)"
    @update:per-page="$emit('update:perPage', $event)"
    @row-click="rowClickHandler">
    >
    <ElTableColumn prop="title" :label="$t('Templates.Templates')"></ElTableColumn>

    <ElTableColumn prop="actions" :label="$t('Base.Actions')" width="160px">
      <template #default="{ row }">
        <div class="templates-table__actions">
          <ElButton
            type="danger"
            :loading="deleteLoading && deleteItemId === row.id"
            plain
            @click.stop="deleteTemplate(row.id)">
            {{ $t('Base.Delete') }}
          </ElButton>
        </div>
      </template>
    </ElTableColumn>
  </UiTableWithPagination>
</template>

<script>
import { DOCTORS_TEMPLATE_ROUTE } from '@/router/doctors.routes';
import { Template } from '@/models/templates/Template.model';

import UiTableWithPagination from '@/components/ui/UiTableWithPagination/index.vue';
import * as icons from '@/enums/icons.enum.js';

export default {
  name: 'TemplatesTable',
  components: { UiTableWithPagination },
  props: {
    data: Array,
    loading: Boolean,
    page: Number,
    perPage: Number,
    total: Number,
  },
  data() {
    return {
      deleteLoading: false,
      deleteItemId: null,
    };
  },

  methods: {
    rowClickHandler(row) {
      this.goToTemplate(row);
    },

    goToTemplate(template) {
      this.$router.push({
        name: DOCTORS_TEMPLATE_ROUTE.name,
        params: { id: template.id },
      });
    },

    async deleteTemplate(id) {
      this.deleteLoading = true;
      this.deleteItemId = id;

      await Template.deleteById(id);
      this.$emit('update:tableItems', id);

      this.deleteLoading = false;
      this.deleteItemId = null;
    },
  },

  setup: () => ({
    icons,
  }),
};
</script>

<style src="./index.scss" />
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/templates.locales.json" />
