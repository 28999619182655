<template>
  <UiModelsAutocompleteSearch
    class="inspection-card-form__codes"
    v-model="templateId"
    :default-item="template"
    method-name="getItems"
    :model-for-use="Template"
    value="id"
    label="title"
    :search-query="{ basic_data_not_null: 'true' }"
    :placeholder="$t('Patronage.DocumentPlaceholder')"
    @select="$emit('change-select', $event)" />
</template>

<script lang="ts">
export default {
  name: 'TemplateSelect',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { ref } from 'vue';
import { TemplateDto } from '@/types/api';
import { Template } from '@/models/templates/Template.model';

type Props = {
  template?: TemplateDto | null;
};
const props = withDefaults(defineProps<Props>(), {
  template: null,
});
defineEmits(['change-select']);
const templateId = ref(props.template ? props.template.id : null);
</script>
