<template>
  <div class="add-pregnancy-form">
    <FieldGroupWrapper>
      <FormSelectField
        class="deregister-pregnancy-form__select"
        field-name="group"
        field-object-name="typeObject"
        :items="statusOptions"
        :label="$t('Patients.DegreeDisability')"
        value-key="value"
        label-key="label"
        required />
      <FormSearchSelectField
        :label="$t('Patients.Diagnosis')"
        :placeholder="$t('Appointments.SelectIDC')"
        field-name="disease_code"
        field-object-name="disease"
        value-key="code"
        :model-for-use="DiseaseCode"
        required />
      <FormDateField
        :label="$t('Patients.DateOfRegistration')"
        field-name="registration_date"
        required
        :placeholder="$t('DateAndTime.Date')" />
    </FieldGroupWrapper>
    <FormActionsWrapper>
      <ElButton
        class="add-pregnancy-form__btn"
        type="primary"
        @click="submitForm"
        :loading="isSubmitting"
        :disabled="submitDisabled">
        {{ $t('Patients.AddAccount') }}
      </ElButton>
    </FormActionsWrapper>
  </div>
</template>

<script lang="ts">
export default {
  name: 'AddDisabilityForm',
  inheritAttrs: false,
  customOptions: {},
};
</script>
<script lang="ts" setup>
import { computed } from 'vue';
import { useForm } from 'vee-validate';
import { validationSchema } from './validationSchema';
import { DiseaseCode } from '@/models/DiseaseCode.model';
import {
  AddDisabilityFormData,
  DisabilityGroup,
  RequestDisabilityDto,
} from '@/types/api/disability';
import { Disability } from '@/models/Disability.model';
import { I18nService } from '@/services/i18n.service';

import FormActionsWrapper from '@/components/common/form/FormActionsWrapper/index.vue';
import FieldGroupWrapper from '@/components/common/form/FieldGroupWrapper/index.vue';
import FormDateField from '@/components/common/form/ui/FormDateField/index.vue';
import FormSearchSelectField from '@/components/common/form/ui/FormSearchSelectField/index.vue';
import FormSelectField from '@/components/common/form/ui/FormSelectField/index.vue';

const props = defineProps<{
  userId: number;
}>();
const emit = defineEmits(['close:modal']);

const statusOptions = computed<{ label: string; value: DisabilityGroup }[]>(() => [
  {
    label: I18nService.t('Patients.Degrees.first_group'),
    value: 'first_group',
  },
  {
    label: I18nService.t('Patients.Degrees.second_group'),
    value: 'second_group',
  },
  {
    label: I18nService.t('Patients.Degrees.third_group'),
    value: 'third_group',
  },
]);

const { isSubmitting, errors, handleSubmit } = useForm<AddDisabilityFormData>({
  validationSchema,
});

const submitDisabled = computed(() => Object.keys(errors.value).length > 0 || isSubmitting.value);

const submitForm = handleSubmit(
  async (values) => {
    const requestData: RequestDisabilityDto = {
      group: values.group,
      user_id: props.userId,
      registration_date: values.registration_date,
      disease_code: values.disease_code,
    };

    await Disability.createItem(requestData);

    emit('close:modal');
  },
  (ctx) => {
    // eslint-disable-next-line no-console
    console.log('invalid submit', ctx.errors);
  }
);
</script>

<style lang="scss" src="./index.scss"></style>
<i18n src="@/locales/patients.locales.json" />
<i18n src="@/locales/dateAndTime.locales.json" />
