<template>
  <ElCheckboxButton
    v-model="value"
    class="ui-checkbox-btn"
    v-bind="$attrs"
    :true-label="props.trueLabel"
    :false-label="props.falseLabel"
    :validate-event="false">
    <slot />
  </ElCheckboxButton>
</template>

<script lang="ts">
export default {
  name: 'FormCheckboxBtnField',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { useField } from 'vee-validate';
import { ElCheckboxButton } from 'element-plus';

const props = defineProps<{
  fieldName: string;
  trueLabel?: string | number;
  falseLabel?: string | number;
}>();

const { value } = useField<boolean | string | number | undefined>(props.fieldName);
</script>

<style lang="scss" src="./index.scss" />
