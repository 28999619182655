<template>
  <ElDialog
    class="create-study-modal"
    :model-value="modelValue"
    :title="$t('Laboratory.Study.AddStudy')"
    @update:model-value="$emit('update:modelValue', $event)">
    <ElForm id="create-study-modal-form" label-position="top" @submit.prevent="submitHandler">
      <ElFormItem :label="$t('Base.Naming')">
        <UiModelsAutocompleteSearch
          v-model="study.analysis_id"
          :model-for-use="Analysis"
          :search-query="{ biomaterial_type: biomaterialType }"
          label="title"
          required
          @select="study.analysis = $event" />
      </ElFormItem>
    </ElForm>

    <template #footer>
      <ElButton
        type="primary"
        native-type="submit"
        form="create-study-modal-form"
        :loading="loading">
        {{ $t('Base.Create') }}
      </ElButton>
    </template>
  </ElDialog>
</template>

<script>
import axios from 'axios';
import { Study } from '@/models/laboratory/Study.model';
import { Analysis } from '@/models/laboratory/Analysis.model';
import { GlobalModalAction } from '@/models/client/ModalAndDrawer/GlobalModalAction';

export default {
  name: 'CreateStudyModal',
  emits: ['update:modelValue', 'action'],
  props: {
    modelValue: Boolean,
    data: [Study, Object],
    biomaterialType: String,
  },
  data() {
    return {
      study: null,
      loading: false,
    };
  },
  watch: {
    modelValue: {
      handler() {
        this.study = new Study(this.data ?? {});
      },
      immediate: true,
    },
  },

  methods: {
    async submitHandler() {
      if (this.loading) return;
      this.loading = true;

      try {
        const { study } = await Study.create(this.study);

        this.$notify({
          type: 'success',
          title: this.$t('Notifications.SuccessCreated'),
        });
        this.$emit(
          'action',
          new GlobalModalAction({
            name: 'created',
            data: { study },
          })
        );
      } catch (err) {
        this.$notify({
          type: 'error',
          title: axios.isAxiosError(err) ? err.message : String(err),
        });
      }

      this.loading = false;
    },
  },

  setup: () => ({ Analysis }),
};
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/laboratory.locales.json" />
<i18n src="@/locales/notifications.locales.json" />
