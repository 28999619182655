import { ActionContext, CommitOptions, DispatchOptions, Store as VuexStore } from 'vuex';
import { RootStateType } from '@/store';
import { AnyObject } from '@/types/common';

export type RecommendationsState = {
  appointedServices: AnyObject[];
  favorites: {
    groupServices: [];
    groupServiceIds: [];
  };
};

type Context = ActionContext<RecommendationsState, RootStateType>;

type Mutations = {
  ['recommendations/SET_APPOINTED_SERVICES'](
    state: RecommendationsState,
    payload: AnyObject[]
  ): void;
  ['recommendations/UPDATE_APPOINTED_SERVICES'](
    state: RecommendationsState,
    payload: AnyObject[]
  ): void;
  ['recommendations/SET_FAVORITES'](
    state: RecommendationsState,
    payload: { group_services: []; group_service_ids: [] }
  ): void;
};

type ActionAugments = Omit<ActionContext<RecommendationsState, RootStateType>, 'commit'> & {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
};

export type Actions = {
  ['recommendations/setAppointedServices'](context: ActionAugments, payload: AnyObject[]): void;
  ['recommendations/updateAppointedServices'](context: ActionAugments, payload: AnyObject[]): void;
  ['recommendations/setFavorites'](
    context: ActionAugments,
    payload: { group_services: []; group_service_ids: [] }
  ): void;
};

export default {
  name: 'recommendations',
  namespaced: true,
  state: () => {
    return {
      appointedServices: [],
      favorites: {
        groupServices: [],
        groupServiceIds: [],
      },
    };
  },
  mutations: {
    SET_APPOINTED_SERVICES: (state: RecommendationsState, payload: AnyObject[]) => {
      state.appointedServices = payload.map((item) => ({
        ...item,
        checked: item.checked ?? true,
      }));
    },
    UPDATE_APPOINTED_SERVICES: (state: RecommendationsState, payload: AnyObject[]) => {
      const ids = payload.map((item) => item.id);
      const filteredAppointServices = state.appointedServices.filter(
        (item) => !ids.includes(item.id)
      );
      state.appointedServices = filteredAppointServices.concat(
        payload
          .map((item) => ({
            ...item,
            checked: item.checked ?? true,
          }))
          .filter((item) => item.checked !== false)
      );
    },
    SET_FAVORITES: (
      state: RecommendationsState,
      payload: { group_services: []; group_service_ids: [] }
    ) => {
      state.favorites = {
        groupServices: payload.group_services,
        groupServiceIds: payload.group_service_ids,
      };
    },
  },
  actions: {
    setAppointedServices({ commit }: Context, payload: AnyObject[]) {
      commit('SET_APPOINTED_SERVICES', payload);
    },
    updateAppointedServices({ commit }: Context, payload: AnyObject[]) {
      commit('UPDATE_APPOINTED_SERVICES', payload);
    },
    setFavorites({ commit }: Context, payload: { group_services: []; group_service_ids: [] }) {
      commit('SET_FAVORITES', payload);
    },
  },
};

// Типизация стора чтобы в файлах .vue работала типизация модуля
// TODO добавить такой же тип для StoreCRUDModule
export type RecommendationsStore<S = RecommendationsState> = Omit<
  VuexStore<S>,
  'getters' | 'commit' | 'dispatch'
> & {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
    key: K,
    payload: P,
    options?: CommitOptions
  ): ReturnType<Mutations[K]>;
} & {
  dispatch<K extends keyof Actions>(
    key: K,
    payload?: Parameters<Actions[K]>[1],
    options?: DispatchOptions
  ): ReturnType<Actions[K]>;
};
