<template>
  <UiTableWithPagination
    :loading="loading"
    :data="itemsWithPayload"
    :page="page"
    :per-page="perPage"
    :total="total"
    @update:page="$emit('update:page', $event)"
    @update:per-page="$emit('update:perPage', $event)"
    @row-click="rowClickHandler">
    <ElTableColumn prop="title" :label="$t('Base.Naming')" />
    <ElTableColumn
      prop="biomaterial_type_translate"
      :label="$t('Laboratory.Biomaterial.Biomaterial')" />
    <ElTableColumn
      v-if="columns?.category ?? true"
      column-key="category"
      prop="category"
      :label="$t('Laboratory.Analysis.Category')" />
    <ElTableColumn
      v-if="columns?.translate ?? true"
      column-key="translate"
      prop="_value_type_translate"
      :label="$t('Laboratory.Analysis.ValueType')" />
    <ElTableColumn
      v-if="columns?.measure ?? true"
      column-key="measure"
      prop="measure"
      :label="$t('Base.Measure')" />
    <ElTableColumn
      v-if="columns?.services ?? true"
      column-key="services"
      prop="_group_services_count"
      :label="$t('Base.Services')"
      width="80px" />

    <ElTableColumn
      v-if="columns?.actions ?? true"
      column-key="actions"
      :label="$t('Base.Actions')"
      width="280px">
      <template #default="{ row }">
        <div class="analyzes-table__actions">
          <ElButton type="primary" plain @click.stop="editAnalysis(row)">
            {{ $t('Base.Edit') }}
          </ElButton>

          <ElButton
            type="danger"
            plain
            :loading="deleteLoading && deletingItemId === row.id"
            @click.stop="deleteAnalysis(row)">
            {{ $t('Base.Delete') }}
          </ElButton>
        </div>
      </template>
    </ElTableColumn>
  </UiTableWithPagination>
</template>

<script>
import axios from 'axios';
import { Analysis } from '@/models/laboratory/Analysis.model';
import { GlobalModalCloseAction } from '@/models/client/ModalAndDrawer/GlobalModalCloseAction';
import { LABORATORY_ANALYSIS_REFERENCES_VALUES_ROUTE } from '@/router/laboratory.routes';

import CreateOrEditAnalysisModal from '@/components/laboratory/analysis/CreateOrEditAnalysisModal/index.vue';
import UiTableWithPagination from '@/components/ui/UiTableWithPagination/index.vue';

export default {
  name: 'AnalyzesTable',
  components: { UiTableWithPagination },
  emits: ['update:page', 'update:perPage', 'item:update', 'item:delete'],
  props: {
    loading: Boolean,
    /** @type {Array<Analysis>} items  */
    items: Array,
    page: Number,
    perPage: Number,
    total: Number,
    columns: Object,
  },
  data() {
    return {
      deleteLoading: false,
      deletingItemId: null,
    };
  },
  computed: {
    itemsWithPayload() {
      return this.items.map((item) => ({
        ...item,
        _value_type_translate: this.$t(`Laboratory.Analysis.ValueTypes.${item.value_type}`),
        _group_services_count: item.group_service_ids.length,
      }));
    },
  },

  methods: {
    rowClickHandler(analysis) {
      this.$router.push({
        name: LABORATORY_ANALYSIS_REFERENCES_VALUES_ROUTE.name,
        params: {
          analysisId: analysis.id,
        },
      });
    },

    async editAnalysis(analysis) {
      const action = await this.$store.dispatch('modalAndDrawer/openModal', {
        component: CreateOrEditAnalysisModal,
        payload: {
          data: analysis,
        },
      });

      if (!(action instanceof GlobalModalCloseAction))
        this.$emit('item:update', action.data.analysis);
    },

    async deleteAnalysis(analysis) {
      this.deleteLoading = true;
      this.deletingItemId = analysis.id;

      try {
        await Analysis.delete({ id: analysis.id });
        this.$notify({ type: 'success', title: this.$t('Notifications.SuccessDeleted') });
        this.$emit('item:delete', analysis);
      } catch (err) {
        this.$notify({
          type: 'error',
          title: axios.isAxiosError(err) ? err.message : String(err),
        });
      }

      this.deleteLoading = false;
      this.deletingItemId = null;
    },
  },
};
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/notifications.locales.json" />
<i18n src="@/locales/laboratory.locales.json" />
