<template>
  <ElSelect
    :model-value="modelValue"
    :placeholder="$t('Base.Status')"
    v-bind="$attrs"
    @update:model-value="$emit('update:modelValue', $event)">
    <ElOption
      v-for="option in options"
      :key="option.value"
      :label="option.label"
      :value="option.value" />
  </ElSelect>
</template>

<script>
import { Hospital } from '@/models/hospital/Hospital.model';

export default {
  name: 'SelectHospitalStatus',
  emits: ['update:modelValue'],
  props: {
    modelValue: String,
    // + все пропсы для ElSelect
  },
  computed: {
    options() {
      return Object.keys(Hospital.enum.statuses).map((key) => ({
        label: this.$t(`Hospital.Statuses.${Hospital.enum.statuses[key]}`),
        value: Hospital.enum.statuses[key],
      }));
    },
  },
};
</script>

<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/hospital.locales.json" />
