<template>
  <article class="feedback-card">
    <div class="feedback-card__item-user">
      <div class="feedback-card__header">
        <UiAvatar
          class="feedback-card__item-user-avatar"
          :image="item.user?.avatar"
          size="super-large"
          :gender="item.user?.gender" />
        <div class="feedback-card__info">
          <div class="feedback-card__info-header">
            <h3 class="feedback-card__item-user-name">
              {{ `${$t('User.User')} ${item.user.name}` }}
            </h3>
            <StatusTag
              v-if="props.item.has_appointment"
              :title="$t('User.WasReception')"
              value="success"
              :map-status="{ success: 'success' }" />
          </div>
          <div v-if="showTypeFeedback && typeFeedback" class="feedback-card__type">
            <template v-if="props.item.clinic">{{ `${$t('User.FeedbackByClinic')}` }}</template>
            <template v-else
              ><router-link
                :to="{
                  name: DOCTOR_ROUTE.name,
                  params: { id: props.item.doctor?.id },
                }">
                {{ `${$t('User.FeedbackByDoctor')}:` }}
                <span class="feedback-card__doctor-name">{{ props.item.doctor.name }}</span>
              </router-link></template
            >
          </div>
          <div v-if="states" class="feedback-card__states">
            <span class="feedback-card__secondary">
              {{ $t('Patronage.HealthStatus') }}: {{ states }}</span
            >
          </div>
          <div class="feedback-card__grade-date">
            <div class="feedback-card__item-user-grade">
              <ElRate :model-value="+item.grade" disabled text-color="#ff9900" />
            </div>
            <div class="feedback-card__item-user-date">
              {{ item.created_at }}
            </div>
          </div>
        </div>
      </div>

      <div class="feedback-card__item-comment">
        {{ item.comment }}
      </div>

      <div class="feedback-card__answer">
        <template v-if="!isAnswerPosted">
          <span
            v-if="!isEdit"
            class="feedback-card__answer-title feedback-card__left-answer"
            @click="toggleEdit"
            ><UiIcon class="feedback-card__chat-icon" :icon="icons.CHAT" />{{
              $t(`User.AnswerToComment`)
            }}</span
          >
          <template v-else-if="isEdit">
            <span class="feedback-card__answer-title">{{ $t('User.AnswerOnComment') }}</span>
            <div class="feedback-card__input-container">
              <ElInput
                ref="inputRef"
                class="feedback-card__input"
                v-model.trim="modelAnswer"
                clearable />
              <ElButton text @click="postAnswer" :disabled="!modelAnswer">
                <UiIcon :icon="icons.TELEGRAM"
              /></ElButton>
            </div>
          </template>
        </template>
        <template v-else>
          <span class="feedback-card__answer-title">{{ $t('User.Answer') }}:</span>
          <ul class="feedback-card__answer-list">
            <li class="feedback-card__answer-item">{{ modelAnswer }}</li>
          </ul>
        </template>
      </div>
    </div>
  </article>
</template>

<script lang="ts">
export default {
  name: 'Feedback',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script setup lang="ts">
import { withDefaults, computed, ref, nextTick } from 'vue';
import { FeedbackType } from './types';
import { DOCTOR_ROUTE } from '@/router/doctors.routes';
import { Feedback } from '@/models/feedback/Feedback.model';
import * as icons from '@/enums/icons.enum.js';

import StatusTag from '@/components/common/StatusTag/index.vue';

type Props = {
  item: FeedbackType;
  showTypeFeedback?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  item: () => ({
    id: 0,
    user: {
      id: 0,
      phone: '',
      avatar: '',
      gender: '',
      name: '',
    },
    grade: '',
    created_at: '',
    comment: '',
  }),
  showTypeFeedback: false,
});

const states = computed(() => {
  return props.item.user?.user_states?.length
    ? props.item.user.user_states
        .map((state) => {
          return state.title;
        })
        .join(',')
    : '';
});

const inputRef = ref<null | HTMLElement>(null);
const isEdit = ref(false);
const isAnswerPosted = ref(!!props.item?.response?.comment);
const modelAnswer = ref(props.item?.response?.comment ?? '');

const typeFeedback = computed(() => !!props.item.clinic || !!props.item.doctor);

const toggleEdit = () => {
  isEdit.value = !isEdit.value;
  setFocus();
};

const setFocus = () => {
  nextTick().then(() => {
    if (!inputRef.value) return;

    inputRef.value.focus();
  });
};

const postAnswer = async () => {
  const response = await Feedback.createAnswer(props.item.id, {
    response: modelAnswer.value,
  });

  if (response) {
    isAnswerPosted.value = true;
    isEdit.value = false;
  }
};
</script>

<i18n src="@/locales/user.locales.json" />
<i18n src="@/locales/patronage.locales.json" />

<style lang="scss" src="./index.scss"></style>
