<template>
  <div class="dispensarization-statistics-block">
    <StatisticsCard
      v-for="item in statisticsCardsData"
      :key="item.title"
      :title="item.title"
      :count="item.count"
      :total-count="item.totalCount"
      :subtitle="`${$t('Statistic.TotalPatients')} ${item.totalCount}`"
      :colors="item.colors"
      :labels="item.labels"
      :loading="item.loading"
      @click-card="handleClickCard" />
  </div>
</template>

<script lang="ts">
export default {
  name: 'DispensarizationStatisticsBlock',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { Statistics } from '@/models/Statistics.model';
import { I18nService } from '@/services/i18n.service';
import { computed, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { CardInfoType } from '@/components/common/StatisticsCard/types';
import {
  DISPENSARIZATION_NEW_PATIENTS_ROUTE,
  DISPENSARIZATION_OLD_PATIENTS_ROUTE,
} from '@/router/dispensarization.routes';

import StatisticsCard from '@/components/common/StatisticsCard/index.vue';

const router = useRouter();
const initialData = { total_count: 0, count: 0 };

interface Props {
  query: {
    start_at: string;
    end_at: string;
    is_dispensary: number;
    age_from?: number | null;
    age_to?: number | null;
    microdistrict_id?: number | null;
    disease_code?: string | null;
  };
}

const props = defineProps<Props>();

const statisticsData = ref({
  firstGroup: {
    data: initialData,
    loading: false,
  },
  secondGroup: {
    data: initialData,
    loading: false,
  },
  thirdGroup: {
    data: initialData,
    loading: false,
  },
});

const statisticsCardsData = computed(() => [
  {
    title: I18nService.t('Analytics.DispensariesAccounting'),
    count: statisticsData.value.firstGroup.data.count,
    totalCount: statisticsData.value.firstGroup.data.total_count,
    loading: statisticsData.value.firstGroup.loading,
    colors: ['#3879F8', '#C8DBFF'],
    labels: [
      I18nService.t('Analytics.DispensariesAccounting'),
      I18nService.t('Statistic.Remaining'),
    ],
  },
  {
    title: I18nService.t('Analytics.DispensariesAccountingNew'),
    count: statisticsData.value.secondGroup.data.count,
    totalCount: statisticsData.value.secondGroup.data.total_count,
    loading: statisticsData.value.secondGroup.loading,
    colors: ['#3879F8', '#C8DBFF'],
    labels: [I18nService.t('Analytics.New'), I18nService.t('Statistic.Remaining')],
  },
  {
    title: I18nService.t('Analytics.DispensariesDeregistration'),
    count: statisticsData.value.thirdGroup.data.count,
    totalCount: statisticsData.value.thirdGroup.data.total_count,
    loading: statisticsData.value.thirdGroup.loading,
    colors: ['#3879F8', '#C8DBFF'],
    labels: [I18nService.t('Analytics.Deregistration'), I18nService.t('Statistic.Remaining')],
  },
]);

const getDispensaryCount = async (query: Props['query']) => {
  statisticsData.value.firstGroup.loading = true;
  const response = await Statistics.getDispensaryCount(query);
  statisticsData.value.firstGroup.data = response?.data ?? initialData;
  statisticsData.value.firstGroup.loading = false;
};

const getDispensaryRegistration = async (query: Props['query']) => {
  statisticsData.value.secondGroup.loading = true;
  const response = await Statistics.getDispensaryRegistration(query);
  statisticsData.value.secondGroup.data = response?.data ?? initialData;
  statisticsData.value.secondGroup.loading = false;
};

const getDispensaryDeregistration = async (query: Props['query']) => {
  statisticsData.value.thirdGroup.loading = true;
  const response = await Statistics.getDispensaryDeregistration(query);
  statisticsData.value.thirdGroup.data = response?.data ?? initialData;
  statisticsData.value.thirdGroup.loading = false;
};

const getStatusDispensary = (title: string) => {
  switch (title) {
    case I18nService.t('Analytics.DispensariesAccountingNew'):
      return 'registration';
    case I18nService.t('Analytics.DispensariesDeregistration'):
      return 'deregistration';
    default:
      return 'registration';
  }
};

const handleClickCard = (data: CardInfoType) => {
  if (data.title === I18nService.t('Analytics.DispensariesAccounting')) return;

  const pathName =
    data.title === I18nService.t('Analytics.DispensariesAccountingNew')
      ? DISPENSARIZATION_NEW_PATIENTS_ROUTE.name
      : DISPENSARIZATION_OLD_PATIENTS_ROUTE.name;

  router.push({
    name: pathName,
    query: {
      ...props.query,
      dispensary_status: getStatusDispensary(data.title),
    },
  });
};

watch(
  () => props.query,
  async (value) => {
    getDispensaryCount(value);
    getDispensaryRegistration(value);
    getDispensaryDeregistration(value);
  },
  { deep: true, immediate: true }
);
</script>
<style lang="scss" src="./index.scss" />
