<template>
  <LayoutByUserRole content-class="v-dispensarization" fix-height>
    <div class="v-dispensarization__header">
      <ElDatePicker
        class="v-dispensarization__date"
        v-model="date.value"
        :format="DATE_FORMAT"
        type="daterange"
        unlink-panels
        :value-format="DATE_FORMAT"
        :placeholder="$t('DateAndTime.Date')" />

      <div class="v-dispensarization__age-data">
        <span>{{ $t('Analytics.AgeFrom') }}</span>
        <UiSelect :options="ageOptions" v-model="ageFrom.value" class="v-dispensarization__age" />
        <span>{{ $t('Analytics.To') }}</span>
        <UiSelect :options="ageOptions" v-model="ageTo.value" class="v-dispensarization__age" />
      </div>

      <a class="v-dispensarization__btn" :href="exportDataURL" download>
        <ElButton type="primary">
          {{ $t('Base.DownloadExcel') }}
        </ElButton>
      </a>
    </div>
    <DispensarizationStatisticsBlock :query="statisticsQuery" />

    <DispensarizationTable
      :loading="loading"
      :items="tableItems"
      :total="tableItemsTotal"
      :default-sort="defaultSort"
      @sort-change="onSortChange"
      v-model:page="page.value"
      v-model:per-page="perPage.value"
      @row-click="goToDispensarizationGroupCodesPage" />
  </LayoutByUserRole>
</template>

<script lang="ts">
export default {
  name: 'VDispensarization',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { useRouter } from 'vue-router';
import { useDatePeriod, usePage, usePerPage } from '@/hooks/query';
import { computed, ref, watch } from 'vue';
import { getMonthPeriod } from '@/utils/dateAndTime.utils';
import { SortType } from '@/types/common';
import { useQuery } from '@/hooks/useQuery.hook';
import { DATE_FORMAT } from '@/config/dateAndTime.config';
import { DispensarizationMicrodistrictDto } from '@/types/api';
import { Analytics } from '@/models/analytics/analytics.model';
import { compareQueriesThenLoadData } from '@/utils/router.utils';
import { mergeOrCreateQuery } from '@/utils/http.util';
import { deleteEmptyValueKeys } from '@/utils/object.util';
import { I18nService } from '@/services/i18n.service';
import { TokenService } from '@/services/token.service';
import { DeviceService } from '@/services/device.service';

import DispensarizationStatisticsBlock from '@/components/analytics/dispensarization/DispensarizationStatisticsBlock/index.vue';
import DispensarizationTable from '@/components/analytics/dispensarization/tables/DispensarizationTable/index.vue';
import LayoutByUserRole from '@/components/layouts/LayoutByUserRole/index.vue';
import { DISPENSARIZATION_CODES_ROUTE } from '@/router/dispensarization.routes';

const router = useRouter();
const page = usePage();
const perPage = usePerPage();
const loading = ref(false);
const tableItems = ref<DispensarizationMicrodistrictDto[]>([]);
const tableItemsTotal = ref(0);

const date = useDatePeriod(getMonthPeriod());
const defaultSort = ref<null | SortType>(null);
const ageFrom = useQuery({ field: 'age_from', defaultValue: 0 });
const ageTo = useQuery({ field: 'age_to', defaultValue: 120 });

const getConvertOrder = () => {
  if (!!defaultSort.value?.order) {
    return defaultSort.value.order === 'ascending' ? 'ASC' : 'DESC';
  } else {
    return null;
  }
};

const ageOptions = Array.from({ length: 120 }, (_, i) => {
  return {
    label: i.toString(),
    value: i,
  };
});

const statisticsQuery = computed(() => ({
  is_dispensary: 1,
  start_at: date.value[0],
  end_at: date.value[1],
  age_from: ageFrom.value,
  age_to: ageTo.value,
}));

const query = computed(() => ({
  is_dispensary: 1,
  page: page.value,
  per_page: perPage.value,
  start_at: date.value[0],
  end_at: date.value[1],
  age_from: ageFrom.value,
  age_to: ageTo.value,
  count_dispensaries: defaultSort.value?.prop === 'count_dispensaries' ? getConvertOrder() : null,
  count_dispensaries_man:
    defaultSort.value?.prop === 'count_dispensaries_man' ? getConvertOrder() : null,
  count_dispensaries_woman:
    defaultSort.value?.prop === 'count_dispensaries_woman' ? getConvertOrder() : null,
}));

const exportDataURL = computed(() => {
  return mergeOrCreateQuery({
    url: Analytics.exportDispensarizationMicrodistrictsDataURL,
    query: deleteEmptyValueKeys({
      ...query.value,
      lang: I18nService.getLocale(),
      token: TokenService.getAccessToken(),
      device_id: DeviceService.getDeviceId(),
    }),
  });
});

const goToDispensarizationGroupCodesPage = (row: DispensarizationMicrodistrictDto) => {
  router.push({
    name: DISPENSARIZATION_CODES_ROUTE.name,
    query: {
      date_period: date.value,
      age_from: ageFrom.value,
      age_to: ageTo.value,
      is_dispensary: 1,
    },
    params: {
      microdistrict_id: row.id,
    },
  });
};

const onSortChange = ({ prop, order }: SortType) => {
  defaultSort.value = { prop: prop, order: order };
};

const getDispensarizationMicrodistricts = async () => {
  loading.value = true;
  const response = await Analytics.getAnalyticsMicrodistricts<DispensarizationMicrodistrictDto[]>(
    query.value
  );

  tableItems.value = response?.data ?? [];
  tableItemsTotal.value = response?.meta.total ?? 0;

  loading.value = false;
};

watch(
  query,
  (value, oldValue) => {
    compareQueriesThenLoadData({
      query: value,
      oldQuery: oldValue,
      getData: getDispensarizationMicrodistricts,
      resetPage: page.reset,
      fieldsForResetPage: [
        'start_at',
        'end_at',
        'count_dispensaries',
        'count_dispensaries_man',
        'count_dispensaries_woman',
      ],
    });
  },
  { deep: true, immediate: true }
);
</script>

<style lang="scss" src="./index.scss" />

<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/dateAndTime.locales.json" />
<i18n src="@/locales/analytics.locales.json" />
