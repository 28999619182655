<template>
  <div class="patients-search-select-data-block">
    <PatientsSearchSelect
      v-show="!patientPart.show && !hideSelect"
      class="patients-search-select-data-block__search"
      :placeholder="$t('User.PleaseInputNameOrPhone')"
      :model-value="modelValue"
      :search-query="query"
      :required="!patientPart.show && required"
      :disabled="disabled"
      :default-item="defaultItem"
      :show-create-option="createPermission"
      ref="select"
      @update:model-value="$emit('update:modelValue', $event)"
      @select="selectHandler"
      @create="startCreatePatientFlow" />

    <div v-show="!patientPart.show" class="patients-search-select-data-block__content">
      <ElFormItem :label="$t('User.FullName')">
        {{ patient?.name || '-' }}
      </ElFormItem>

      <ElFormItem :label="$t('User.Birthdate')">
        {{ patient?.birthdate || '-' }}
      </ElFormItem>

      <ElFormItem :label="$t('User.Phone')">
        {{ patient?.phone || '-' }}
      </ElFormItem>

      <ElFormItem :label="$t('User.Gender')">
        {{ patient?.id && patient?.gender ? $t(`User.Genders.${patient?.gender}`) : '-' }}
      </ElFormItem>

      <ElFormItem :label="$t('User.PlaceResidence')">
        {{ patient?.place_residence || '-' }}
      </ElFormItem>

      <slot name="content-append"></slot>
    </div>

    <CreateOrEditPatient
      v-if="patientPart.show"
      class="create-or-edit-appointment-modal__create-patient"
      :appointment="appointment"
      @reference:update="$emit('reference:update', $event)"
      :name-or-phone="patientPart.nameOrPhone"
      disable-default-action
      ref="create_or_edit_patient"
      @action="insertPatient" />
  </div>
</template>

<script>
import { Patient } from '@/models/Patient.model';
import PatientsSearchSelect from '@/components/patients/PatientsSearchSelect/index.vue';
import CreateOrEditPatient from '@/components/patients/CreateOrEditPatientModal/CreateOrEditPatient/index.vue';
import { Appointment } from '@/models/appointment/Appointment.model';

/**
 * Компонент для поиска, отображения и создания пациента.
 * Логика создания пациента в компоненте CreateOrEditPatient
 * Для создания - снаружи нужно вызывать методы `validate`, `isNewPatientFlow`, 'createPatient`
 */
export default {
  name: 'PatientsSearchSelectDataBlock',
  components: { PatientsSearchSelect, CreateOrEditPatient },
  emits: ['update:modelValue', 'select', 'reference:update'],
  props: {
    modelValue: Number,
    defaultItem: [Patient, Object],
    required: Boolean,
    disabled: Boolean,
    searchQuery: Object,
    createPermission: Boolean,
    hideSelect: Boolean,
    appointment: {
      type: [Appointment, Object],
    },
  },
  data() {
    return {
      patient: null,

      patientPart: {
        show: false,
        nameOrPhone: null,
        newPatient: null,
      },
    };
  },
  computed: {
    query() {
      return {
        query_field: ['name', 'phone'],
        ...(this.searchQuery ?? {}),
      };
    },
  },

  watch: {
    defaultItem: {
      handler(value) {
        this.patient = value ?? new Patient();
      },
      immediate: true,
    },
  },

  methods: {
    selectHandler(data) {
      this.patient = data;
      this.$emit('select', data);
    },

    validate() {
      return this.$refs.create_or_edit_patient.customValidate();
    },
    isNewPatientFlow() {
      return this.patientPart.show;
    },
    async createPatient() {
      return this.$refs.create_or_edit_patient.createPatient();
    },
    insertPatient(action) {
      this.$emit('update:modelValue', action.data.patient.id);
      this.patientPart.show = false;
      this.selectHandler(action.data.patient);
    },

    startCreatePatientFlow(query) {
      this.blur();
      this.patientPart.show = true;
      this.$nextTick(() => (this.patientPart.nameOrPhone = query));
    },

    focus() {
      setTimeout(() => this.$refs.select.focus());
    },
    blur() {
      setTimeout(() => this.$refs.select.blur());
    },
  },
};
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/user.locales.json" />
