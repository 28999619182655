<template>
  <ElCard
    class="patient-feedback-card"
    v-loading="loading"
    :header="$t('Statistic.PatientFeedback')"
    :shadow="props.shadow">
    <div class="patient-feedback-card__items">
      <Feedback v-for="item in data" :item="item" :key="item.id" />
    </div>
  </ElCard>
</template>

<script lang="ts">
export default {
  name: 'PatientFeedbackCard',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { CardProps } from 'element-plus/es/components/card';
import { FeedbackType } from '@/components/common/Feedback/types';

import Feedback from '@/components/common/Feedback/Index.vue';

type Props = {
  data: Array<FeedbackType> | null;
  loading: boolean;
  shadow?: CardProps['shadow'];
};

const props = withDefaults(defineProps<Props>(), {
  data: () => [],
  loading: false,
  shadow: 'never',
});
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/statistic.locales.json" />
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/user.locales.json" />
