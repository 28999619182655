import { onlyDoctorMiddleware } from '@/middlewares/onlyDoctor.middleware';
import { onlyLoggedInMiddleware } from '@/middlewares/onlyLoggedIn.middleware';
import { byUserRolesMiddleware } from '@/middlewares/byUserRoles.middleware';
import { User } from '@/models/User.model';

export const USERS_PATH_PREFIX = '/users';

export const DOCTORS_TEMPLATES_ROUTE = {
  name: 'DOCTORS_TEMPLATES',
  path: '/templates',
  component: 'VTemplates',
  beforeEnter: [onlyLoggedInMiddleware, onlyDoctorMiddleware],
  meta: {
    title: 'Base.Templates',
  },
};

export const DOCTORS_TEMPLATE_ROUTE = {
  name: 'DOCTORS_TEMPLATE',
  path: '/templates/:id',
  component: 'VTemplate',
  beforeEnter: [onlyLoggedInMiddleware, onlyDoctorMiddleware],
  meta: {
    title: 'Base.Template',
  },
  props: true,
};

export const DOCTORS_ROUTE = {
  name: 'DOCTORS',
  path: `${USERS_PATH_PREFIX}/doctors`,
  component: 'VDoctors',
  beforeEnter: [
    onlyLoggedInMiddleware,
    byUserRolesMiddleware([User.enum.roles.Manager, User.enum.roles.Director]),
  ],
  meta: {
    title: 'Base.Doctors',
  },
};

export const DOCTOR_ROUTE = {
  name: 'DOCTOR',
  path: '/doctors/:id',
  component: 'VDoctor',
  beforeEnter: [
    onlyLoggedInMiddleware,
    byUserRolesMiddleware([
      User.enum.roles.Manager,
      User.enum.roles.Director,
      User.enum.roles.Doctor,
    ]),
  ],
  meta: {
    title: 'Base.Doctor',
  },
  props: true,
  childrenMap: {
    DEVICES: {
      name: 'DOCTOR_DEVICES',
      path: 'devices',
      _fullPath: `/doctor/:id/devices`,
      component: 'VDoctorDevices',
      meta: {
        title: 'User.Devices',
      },
      props: true,
    },
    SALARY: {
      name: 'DOCTOR_SALARY',
      path: 'salary',
      _fullPath: `/doctor/:id/salary`,
      component: 'VDoctorSalary',
      meta: {
        title: 'User.Accounting',
      },
      props: true,
    },
  },
};

export const DOCTORS_SETTINGS_ROUTE = {
  name: 'DOCTORS_SETTINGS',
  path: '/profile/settings',
  component: 'VSettings',
  beforeEnter: [onlyLoggedInMiddleware, onlyDoctorMiddleware],
  meta: {
    title: 'Base.Settings',
  },
};

export const routes = [
  DOCTORS_ROUTE,
  DOCTOR_ROUTE,
  DOCTORS_TEMPLATES_ROUTE,
  DOCTORS_TEMPLATE_ROUTE,
  DOCTORS_SETTINGS_ROUTE,
];
