<template>
  <div class="patient-groups-statistics-block">
    <StatisticsCard
      v-for="item in statisticsCardsData"
      :key="item.title"
      :title="item.title"
      :count="item.count"
      :total-count="item.totalCount"
      :subtitle="`${$t('Statistic.TotalPatients')} ${item.totalCount}`"
      :colors="item.colors"
      :labels="item.labels"
      :loading="item.loading"
      @click-card="handleClickCard" />
  </div>
</template>

<script lang="ts">
export default {
  name: 'PatientGroupsStatisticsBlock',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { Statistics } from '@/models/Statistics.model';
import { I18nService } from '@/services/i18n.service';
import { PatientGroup } from '@/types/api';
import { computed, ref, watch } from 'vue';
import { PATIENT_GROUP_CHANGE_ROUTE } from '@/router/patientGroups.routes';
import { useRouter } from 'vue-router';

import StatisticsCard from '@/components/common/StatisticsCard/index.vue';

const router = useRouter();
const initialData = { total_count: 0, count: 0 };

interface Props {
  query: {
    group: PatientGroup;
    start_at: string;
    end_at: string;
    age_from?: number | null;
    age_to?: number | null;
    microdistrict_id?: number | null;
    disease_code?: string | null;
  };
}

const props = defineProps<Props>();

const statisticsData = ref({
  count: {
    data: initialData,
    loading: false,
  },
  appointment: {
    data: initialData,
    loading: false,
  },
  income: {
    data: initialData,
    loading: false,
  },
  outcome: {
    data: initialData,
    loading: false,
  },
});

const firstCardByGroupTextData = computed(() => {
  switch (props.query.group) {
    case 'first_group':
      return {
        title: I18nService.t('Statistic.TransferredFromGroupIToGroupII'),
        labels: [
          I18nService.t('Patients.PatientGroups.first_group'),
          I18nService.t('Patients.PatientGroups.second_group'),
        ],
      };
    case 'second_group':
      return {
        title: I18nService.t('Statistic.TransferredFromGroupIIToGroupIII'),
        labels: [
          I18nService.t('Patients.PatientGroups.second_group'),
          I18nService.t('Patients.PatientGroups.third_group'),
        ],
      };
    case 'third_group':
      return {
        title: I18nService.t('Statistic.TransferredFromGroupIIIToGroupIV'),
        labels: [
          I18nService.t('Patients.PatientGroups.third_group'),
          I18nService.t('Patients.PatientGroups.fourth_group'),
        ],
      };
    case 'fourth_group':
      return {
        title: I18nService.t('Statistic.TransferredFromGroupIVToGroupIII'),
        labels: [
          I18nService.t('Patients.PatientGroups.fourth_group'),
          I18nService.t('Patients.PatientGroups.third_group'),
        ],
      };
    default:
      return {
        title: I18nService.t('Statistic.TransferredFromGroupIToGroupII'),
        labels: [
          I18nService.t('Patients.PatientGroups.first_group'),
          I18nService.t('Patients.PatientGroups.second_group'),
        ],
      };
  }
});
const secondCardByGroupTextData = computed(() => {
  switch (props.query.group) {
    case 'first_group':
      return {
        title: I18nService.t('Statistic.TransferredFromGroupIIToGroupI'),
        labels: [
          I18nService.t('Patients.PatientGroups.second_group'),
          I18nService.t('Patients.PatientGroups.first_group'),
        ],
      };
    case 'second_group':
      return {
        title: I18nService.t('Statistic.TransferredFromGroupIIIToGroupII'),
        labels: [
          I18nService.t('Patients.PatientGroups.third_group'),
          I18nService.t('Patients.PatientGroups.second_group'),
        ],
      };
    case 'third_group':
      return {
        title: I18nService.t('Statistic.TransferredFromGroupIVToGroupIII'),
        labels: [
          I18nService.t('Patients.PatientGroups.fourth_group'),
          I18nService.t('Patients.PatientGroups.third_group'),
        ],
      };
    case 'fourth_group':
      return {
        title: I18nService.t('Statistic.TransferredFromGroupIIIToGroupIV'),
        labels: [
          I18nService.t('Patients.PatientGroups.third_group'),
          I18nService.t('Patients.PatientGroups.fourth_group'),
        ],
      };
    default:
      return {
        title: I18nService.t('Statistic.TransferredFromGroupIIToGroupI'),
        labels: [
          I18nService.t('Patients.PatientGroups.second_group'),
          I18nService.t('Patients.PatientGroups.first_group'),
        ],
      };
  }
});

const statisticsCardsData = computed(() => [
  {
    title: I18nService.t('Statistic.TotalPatientsInGroup'),
    count: statisticsData.value.count.data.count,
    totalCount: statisticsData.value.count.data.total_count,
    loading: statisticsData.value.count.loading,
    colors: ['#3879F8', '#C8DBFF'],
    labels: [I18nService.t('Statistic.Women'), I18nService.t('Statistic.Men')],
  },
  {
    title: I18nService.t('Statistic.CompletedExamination'),
    count: statisticsData.value.appointment.data.count,
    totalCount: statisticsData.value.appointment.data.total_count,
    loading: statisticsData.value.appointment.loading,
    colors: ['#3879F8', '#C8DBFF'],
    labels: [I18nService.t('Statistic.Passed'), I18nService.t('Statistic.DidNotPass')],
  },
  {
    count: statisticsData.value.income.data.count,
    totalCount: statisticsData.value.income.data.total_count,
    loading: statisticsData.value.income.loading,
    colors: ['#3879F8', '#C8DBFF'],
    ...firstCardByGroupTextData.value,
  },
  {
    count: statisticsData.value.outcome.data.count,
    totalCount: statisticsData.value.outcome.data.total_count,
    loading: statisticsData.value.outcome.loading,
    colors: ['#3879F8', '#C8DBFF'],
    ...secondCardByGroupTextData.value,
  },
]);

const getGroupCount = async (query: Props['query']) => {
  statisticsData.value.count.loading = true;
  const response = await Statistics.getGroupCount(query);
  statisticsData.value.count.data = response?.data ?? initialData;
  statisticsData.value.count.loading = false;
};

const getGroupAppointment = async (query: Props['query']) => {
  statisticsData.value.appointment.loading = true;
  const response = await Statistics.getGroupAppointment(query);
  statisticsData.value.appointment.data = response?.data ?? initialData;
  statisticsData.value.appointment.loading = false;
};

const getGroupIncome = async (query: Props['query']) => {
  statisticsData.value.income.loading = true;
  const response = await Statistics.getGroupIncome(query);
  statisticsData.value.income.data = response?.data ?? initialData;
  statisticsData.value.income.loading = false;
};

const getGroupOutcome = async (query: Props['query']) => {
  statisticsData.value.outcome.loading = true;
  const response = await Statistics.getGroupOutcome(query);
  statisticsData.value.outcome.data = response?.data ?? initialData;
  statisticsData.value.outcome.loading = false;
};

interface Data {
  labels: string[];
}

const handleClickCard = (data: Data) => {
  const oldGroupLabel = computed(() => {
    switch (data.labels[0]) {
      case I18nService.t('Patients.PatientGroups.first_group'):
        return 'first_group';
      case I18nService.t('Patients.PatientGroups.second_group'):
        return 'second_group';
      case I18nService.t('Patients.PatientGroups.third_group'):
        return 'third_group';
      case I18nService.t('Patients.PatientGroups.fourth_group'):
        return 'fourth_group';
      default:
        return 'second_group';
    }
  });

  const newGroupLabel = computed(() => {
    switch (data.labels[1]) {
      case I18nService.t('Patients.PatientGroups.first_group'):
        return 'first_group';
      case I18nService.t('Patients.PatientGroups.second_group'):
        return 'second_group';
      case I18nService.t('Patients.PatientGroups.third_group'):
        return 'third_group';
      case I18nService.t('Patients.PatientGroups.fourth_group'):
        return 'fourth_group';
      default:
        return 'first_group';
    }
  });

  router.push({
    name: PATIENT_GROUP_CHANGE_ROUTE.name,
    query: {
      old_group: oldGroupLabel.value,
      new_group: newGroupLabel.value,
    },
  });
};

watch(
  () => props.query,
  async (value) => {
    getGroupCount(value);
    getGroupAppointment(value);
    getGroupIncome(value);
    getGroupOutcome(value);
  },
  { deep: true, immediate: true }
);
</script>
<style lang="scss" src="./index.scss" />
