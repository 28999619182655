import { ApiService } from '@/services/api.service';
import { MetaDto } from '@/types/api';
import { ElNotification } from 'element-plus';
import { QueryParams } from '@/types/common';
import { getApiErrorMessage, mergeOrCreateQuery } from '@/utils/http.util';
import { deleteEmptyValueKeys } from '@/utils/object.util';
import { DisabilityDto, RequestDisabilityDto } from '@/types/api/disability';

export class Disability {
  static modelName = 'disability';
  static tableName = 'disabilities';

  static async getItems(query: QueryParams) {
    try {
      const response = await ApiService.get<{ data: DisabilityDto[]; meta: MetaDto }>(
        mergeOrCreateQuery({
          url: `b2g/${this.tableName}/${query.id}`,
          query: deleteEmptyValueKeys(query),
        })
      );

      return { response, data: response.data };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: getApiErrorMessage(err),
      });
    }
  }

  static async createItem(payload: RequestDisabilityDto) {
    try {
      const response = await ApiService.post<{ data: DisabilityDto }>(
        `b2g/${this.tableName}/create`,
        payload
      );

      return { response, data: response.data.data };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: getApiErrorMessage(err),
      });
    }
  }

  static async updateItem(query: QueryParams) {
    try {
      const response = await ApiService.put<{ data: DisabilityDto }>(
        mergeOrCreateQuery({
          url: `b2g/${this.tableName}/${query.id}/deregistration`,
          query: deleteEmptyValueKeys(query),
        })
      );

      return { response, data: response.data };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: getApiErrorMessage(err),
      });
    }
  }
}
