<template>
  <ElTag :type="typeByStatus[status]" effect="dark" round>
    {{ $t(`Appointments.Statuses.${status}`) }}
  </ElTag>
</template>

<script>
import { Appointment } from '@/models/appointment/Appointment.model';

export default {
  name: 'AppointmentStatusTag',
  props: {
    status: String,
  },
  computed: {
    typeByStatus() {
      return {
        [Appointment.enum.statuses.Created]: 'info',
        [Appointment.enum.statuses.Approved]: '',
        [Appointment.enum.statuses.Waiting]: 'warning',
        [Appointment.enum.statuses.InProgress]: '',
        [Appointment.enum.statuses.Provided]: 'success',
        [Appointment.enum.statuses.Canceled]: 'danger',
      };
    },
  },
};
</script>

<i18n src="@/locales/appointments.locales.json" />
