<template>
  <div class="deregister-pregnancy-form">
    <FieldGroupWrapper>
      <FormSearchSelectField
        :label="$t('Patients.DeathReason')"
        :placeholder="$t('Patients.ChooseReason')"
        field-name="disease_codes"
        field-object-name="disease"
        multiple
        value-key="code"
        :model-for-use="DiseaseCode"
        required />
      <FormDateField
        :label="$t('Patients.DateOfDeath')"
        field-name="died_at"
        value-format="DD.MM.YYYY hh:mm"
        format="DD.MM.YYYY hh:mm"
        with-time
        required
        :placeholder="$t('DateAndTime.Date')" />
    </FieldGroupWrapper>
    <FormActionsWrapper>
      <ElButton
        class="register-die-form__btn"
        type="primary"
        @click="submitForm"
        :loading="isSubmitting"
        :disabled="submitDisabled">
        {{ $t('Patients.AddAccount') }}
      </ElButton>
    </FormActionsWrapper>
  </div>
</template>

<script lang="ts">
export default {
  name: 'RegisterDieForm',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { useForm } from 'vee-validate';
import { computed } from 'vue';
import { validationSchema } from './validationSchema';
import { RegisterDieFormData } from '@/types/api';
import { Patient } from '@/models/Patient.model';
import { DiseaseCode } from '@/models/DiseaseCode.model';

import FormSearchSelectField from '@/components/common/form/ui/FormSearchSelectField/index.vue';
import FormDateField from '@/components/common/form/ui/FormDateField/index.vue';
import FormActionsWrapper from '@/components/common/form/FormActionsWrapper/index.vue';
import FieldGroupWrapper from '@/components/common/form/FieldGroupWrapper/index.vue';

const emit = defineEmits(['close:modal']);
const { isSubmitting, errors, handleSubmit } = useForm<RegisterDieFormData>({
  validationSchema,
  validateOnMount: false,
});

const props = defineProps<{
  id: number;
}>();
const submitDisabled = computed(() => Object.keys(errors.value).length > 0 || isSubmitting.value);

const submitForm = handleSubmit(
  async (values) => {
    const requestDara: RegisterDieFormData = {
      disease_codes: values.disease_codes,
      died_at: values.died_at,
    };

    await Patient.registerDie(props.id, requestDara);

    emit('close:modal');
  },
  (ctx) => {
    // eslint-disable-next-line no-console
    console.log('invalid submit', ctx.errors);
  }
);
</script>

<style lang="scss" src="./index.scss"></style>
<i18n src="@/locales/dateAndTime.locales.json" />
<i18n src="@/locales/patients.locales.json" />
