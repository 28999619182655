<template>
  <ElCard class="ambulatory-card printer__block printer__doc" shadow="never">
    <ElForm label-position="top">
      <ElFormItem v-for="item in items" :key="item.keyForModel" :label="item.label">
        <ElInput v-model="localAmbulatoryCard[item.keyForModel]" />
      </ElFormItem>

      <ElFormItem :label="$t('Base.PatientDocument')">
        <UiUpload
          class="template-result-block__file-upload"
          :files="patient.files || []"
          @file:add="addFileHandler"
          @file:delete="deleteFileHandler" />
      </ElFormItem>
    </ElForm>

    <ElDivider />
    <div class="ambulatory-card__actions ambulatory-card-actions">
      <ElButton type="primary" :loading="loading" @click="saveChanges">
        {{ $t('Base.SaveChanges') }}
      </ElButton>

      <ElButton text @click="print">
        <template #icon>
          <UiIcon :icon="icons.PRINTER" />
        </template>
        {{ $t('Base.Print') }}
      </ElButton>
    </div>

    <PrinterDocument
      class="ambulatory-card__printer-document"
      :content-blocks="contentBlocksForPrinter"
      hide-decree
      hide-meta
      hide-watermark
      full-page
      ref="printerDocument">
      <template #title> {{ $t('Base.AmbulatoryCard') }} </template>
    </PrinterDocument>
  </ElCard>
</template>

<script>
import axios from 'axios';
import { AmbulatoryCard } from '@/models/AmbulatoryCard.model';
import { Patient } from '@/models/Patient.model';
import { User } from '@/models/User.model';

import PrinterDocument from '@/components/printer/PrinterDocument/index.vue';
import * as icons from '@/enums/icons.enum.js';

export default {
  name: 'AmbulatoryCard',
  components: { PrinterDocument },
  emits: ['update:patient', 'update:patient:redirect'],
  props: {
    patient: [User, Patient, Object],
  },
  data() {
    return {
      /** @type {Patient} */
      localPatient: null,
      /** @type {AmbulatoryCard} */
      localAmbulatoryCard: null,

      loading: false,
    };
  },
  computed: {
    items() {
      return [
        {
          label: this.$t('User.PlaceResidence'),
          keyForModel: 'place_residence',
        },
        {
          label: this.$t('User.StudyWork'),
          keyForModel: 'study_work',
        },
        {
          label: this.$t('Base.ClinicalExamination'),
          keyForModel: 'clinical_examination',
        },
        {
          label: this.$t('User.PlaceTreatment'),
          keyForModel: 'place_treatment',
        },
        {
          label: this.$t('User.BloodTypeRhFactor'),
          keyForModel: 'blood_type_rh_factor',
        },
        {
          label: this.$t('Base.Allergy'),
          keyForModel: 'allergy',
        },
        {
          label: this.$t('Base.Reaction'),
          keyForModel: 'reaction',
        },
        {
          label: this.$t('Base.BloodTransfusion'),
          keyForModel: 'blood_transfusion',
        },
        {
          label: this.$t('Base.SurgicalIntervention'),
          keyForModel: 'surgical_intervention',
        },
        {
          label: this.$t('Base.Diabetes'),
          keyForModel: 'diabetes',
        },
        {
          label: this.$t('Base.InfectiousDiseases'),
          keyForModel: 'infectious_diseases',
        },
        {
          label: this.$t('User.Height'),
          keyForModel: 'height',
        },
        {
          label: this.$t('User.Weight'),
          keyForModel: 'weight',
        },
        {
          label: this.$t('Base.Fluorography'),
          keyForModel: 'fluorography',
        },
      ];
    },
    contentBlocksForPrinter() {
      return [
        {
          label: this.$t('Base.Patient'),
          value: this.patient.name,
        },
        ...this.items.map((item) => ({
          label: item.label,
          value: this.localAmbulatoryCard[item.keyForModel],
        })),
      ];
    },
  },

  watch: {
    'patient': {
      handler(val) {
        this.localPatient = val;
      },
      immediate: true,
      deep: true,
    },
    'patient.ambulatory_card': {
      handler(val) {
        this.localAmbulatoryCard = val || new AmbulatoryCard(val || {});
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    async saveChanges() {
      if (this.loading) return;
      this.loading = true;

      try {
        await Patient.update({
          id: this.patient.id,
          payload: this.localPatient,
        });

        const payloadFromCard = await AmbulatoryCard.save(
          this.patient.id,
          this.localAmbulatoryCard
        );
        this.$emit('update:patient:redirect', payloadFromCard.data.data);
      } catch (err) {
        this.$notify({
          type: 'error',
          title: axios.isAxiosError(err) ? err.message : String(err),
        });
      }

      this.loading = false;
    },

    async addFileHandler(file) {
      const { data } = await Patient.attachFile({ patient_id: this.patient.id, file_id: file.id });
      this.$emit('update:patient', data.data);
    },
    async deleteFileHandler(fileId) {
      this.$emit('update:patient', {
        ...this.patient,
        files_ids: this.patient.files_ids?.filter((id) => id !== fileId),
        files: this.patient.files?.filter((elem) => elem.id !== fileId),
      });
    },

    print() {
      this.$refs.printerDocument.print();
    },
  },
  setup: () => ({
    icons,
  }),
};
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/notifications.locales.json" />
<i18n src="@/locales/user.locales.json" />
<i18n src="./index.locales.json" />
