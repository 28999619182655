import { ActionContext, CommitOptions, DispatchOptions, Store as VuexStore } from 'vuex';
import { RootStateType } from '@/store';
import { CardFullDto } from '@/types/api';
import { AuthState } from '@/store/auth/auth.store';

export type CardState = {
  cardInfo: CardFullDto | null;
};

type ContextType = ActionContext<CardState, RootStateType>;

type Mutations = {
  ['card/SET_CARD_INFO'](state: CardState, payload: CardFullDto): void;
};

type ActionAugments = Omit<ActionContext<AuthState, RootStateType>, 'commit'> & {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
};

export type Actions = {
  ['card/setCardInfo'](context: ActionAugments, payload: CardFullDto): void;
};
export default {
  name: 'card',
  namespaced: true,
  state: (): CardState => {
    return {
      cardInfo: null,
    };
  },
  mutations: {
    SET_CARD_INFO(state: CardState, payload: CardFullDto) {
      state.cardInfo = payload;
    },
  },
  actions: {
    setCardInfo({ commit }: ContextType, payload: CardFullDto) {
      commit('SET_CARD_INFO', payload);
    },
  },
};

// Типизация стора чтобы в файлах .vue работала типизация модуля
// TODO добавить такой же тип для StoreCRUDModule
export type CardStore<S = AuthState> = Omit<VuexStore<S>, 'getters' | 'commit' | 'dispatch'> & {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
    key: K,
    payload: P,
    options?: CommitOptions
  ): ReturnType<Mutations[K]>;
} & {
  dispatch<K extends keyof Actions>(
    key: K,
    payload?: Parameters<Actions[K]>[1],
    options?: DispatchOptions
  ): ReturnType<Actions[K]>;
};
