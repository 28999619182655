{
  "en": {
    "Transactions": {
      "PaymentTypes": {
        "card": "Card",
        "cash": "Cash",
        "discount": "Discount",
        "online": "Transfer"
      },
      "Types": {
        "discount": "Discount",
        "pay_in": "Top-up",
        "pay_out": "Refund"
      }
    }
  },
  "ru": {
    "Transactions": {
      "PaymentTypes": {
        "card": "Карта",
        "cash": "Наличные",
        "discount": "Скидка",
        "online": "Перевод"
      },
      "Types": {
        "discount": "Скидка",
        "pay_in": "Пополнение",
        "pay_out": "Возврат"
      }
    }
  },
  "uz": {
    "Transactions": {
      "PaymentTypes": {
        "card": "Karta",
        "cash": "Naqd",
        "discount": "Chegirma",
        "online": "Otkazma"
      },
      "Types": {
        "discount": "Chegirma",
        "pay_in": "To‘ldirish",
        "pay_out": "Qaytarish"
      }
    }
  }
}