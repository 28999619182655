﻿{
  "en": {
    "Hospital": {
      "ArrivalDate": "Arrival Date",
      "Chamber": "Ward",
      "Department": "Department",
      "DischargedDate": "Departure Date",
      "Hospital": "Hospital",
      "HospitalTreatment": "Hospital treatment",
      "StartDate": "The date of the beginning",
      "Statistic": {
        "PatientAmount": "Number of patients in the inpatient"
      },
      "Statuses": {
        "canceled": "Canceled",
        "completed": "Completed",
        "in_progress": "In progress"
      }
    }
  },
  "ru": {
    "Hospital": {
      "ArrivalDate": "Дата заезда",
      "Chamber": "Палата",
      "Department": "Отделение",
      "DischargedDate": "Дата выезда",
      "Hospital": "Стационар",
      "HospitalTreatment": "Стационарное лечение",
      "StartDate": "Дата начала",
      "Statistic": {
        "PatientAmount": "Количество пациентов в стационаре"
      },
      "Statuses": {
        "canceled": "Отменен",
        "completed": "Завершен",
        "in_progress": "В процессе"
      }
    }
  },
  "uz": {
    "Hospital": {
      "ArrivalDate": "Kirish sanasi",
      "Chamber": "Xona",
      "Department": "Bo'lim",
      "DischargedDate": "Chiqish sanasi",
      "Hospital": "Kasalxona",
      "HospitalTreatment": "Statsionar davolanish",
      "StartDate": "Boshlanish sanasi",
      "Statistic": {
        "PatientAmount": "Statsionarda bemorlar soni"
      },
      "Statuses": {
        "canceled": "Bekor qilingan",
        "completed": "Yakullangan",
        "in_progress": "Jarayonda"
      }
    }
  }
}
