<template>
  <LayoutByUserRole content-class="v-visit-log" fix-height>
    <LayoutContentHeader>
      <ElDatePicker
        class="v-visit-log__date-picker"
        v-model="date.value"
        type="daterange"
        unlink-panels
        :clearable="false"
        :format="DATE_FORMAT"
        :value-format="DATE_FORMAT"
        :start-placeholder="$t('DateAndTime.StartDate')"
        :end-placeholder="$t('DateAndTime.EndDate')" />

      <ElInput
        class="v-visit-log__search-input"
        :clearable="false"
        v-model="searchString"
        :placeholder="$t('PatronageActions.PatientSearch')"
        @input="debounceInput" />
    </LayoutContentHeader>
    <VisitLogTable
      :loading="loading"
      v-model:page="page.value"
      v-model:per-page="perPage.value"
      :items="items"
      :total="total" />
  </LayoutByUserRole>
</template>

<script lang="ts">
export default {
  name: 'VVisitLog',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { ElNotification } from 'element-plus/es';
import { User } from '@/models/User.model';
import { usePerPage, usePage, useDatePeriod } from '@/hooks/query';
import { useQuery } from '@/hooks/useQuery.hook';
import { compareQueriesThenLoadData } from '@/utils/router.utils';
import { getApiErrorMessage } from '@/utils/http.util';
import { getMonthPeriod } from '@/utils/dateAndTime.utils';
import { DATE_FORMAT } from '@/config/dateAndTime.config';
import debounce from 'lodash.debounce';

import LayoutByUserRole from '@/components/layouts/LayoutByUserRole/index.vue';
import LayoutContentHeader from '@/components/layouts/assets/LayoutContentHeader/index.vue';
import VisitLogTable from '@/components/users/VisitLogTable/index.vue';

const store = useStore();
const loading = ref(false);
const perPage = usePerPage();
const page = usePage();
const date = useDatePeriod(getMonthPeriod());
const patientName = useQuery({ field: 'name' });

const total = computed(() => store.state.visitLog.total);
const items = computed(() => store.state.visitLog.data);

const searchString = ref(patientName.value);

const query = computed(() => ({
  per_page: perPage.value,
  page: page.value,
  search: patientName.value,
  start_at: date.value[0],
  end_at: date.value[1],
}));

const debounceInput = debounce((value) => {
  patientName.value = value;
}, 600);

const getVisitLog = async () => {
  loading.value = true;
  try {
    const responce = await User.getVisitLog(query.value);
    if (responce) {
      store.dispatch('visitLog/setData', {
        items: responce.data.data,
        total: +responce.data.meta.total,
        overwriteDataState: true,
      });
    }
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  } finally {
    loading.value = false;
  }
};

watch(
  query,
  (value, oldValue) => {
    compareQueriesThenLoadData({
      query: value,
      oldQuery: oldValue,
      getData: getVisitLog,
      resetPage: page.reset,
    });
  },
  { deep: true, immediate: true }
);
</script>

<style lang="scss" src="./index.scss" />
