<template>
  <UiTableWithPagination
    :data="props.items"
    :loading="props.loading"
    :page="props.page"
    :per-page="props.perPage"
    :total="props.total"
    :default-sort="defaultSort || {}"
    @update:page="$emit('update:page', $event)"
    @update:per-page="$emit('update:perPage', $event)"
    @sort-change="onSortChange">
    <ElTableColumn prop="title" :label="$t('Analytics.Diagnosis')">
      <template #default="{ row }">
        {{ `${row.code} ${row.title}` }}
      </template>
    </ElTableColumn>
    <ElTableColumn prop="count" :label="$t('Analytics.Quantity')" sortable="custom" />
    <ElTableColumn prop="percent" :label="$t('Analytics.Percent')">
      <template #default="{ row }">
        {{ `${row.percent}%` }}
      </template>
    </ElTableColumn>
    <ElTableColumn prop="man" :label="$t('Analytics.Men')" sortable="custom" />
    <ElTableColumn prop="woman" :label="$t('Analytics.Women')" sortable="custom" />
    <ElTableColumn prop="child" :label="$t('Analytics.Children')" sortable="custom" />
  </UiTableWithPagination>
</template>

<script lang="ts">
export default {
  name: 'AnalyticsDiagnosesTable',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { IdcDto } from '@/types/api';
import { SortType } from '@/types/common';

import UiTableWithPagination from '@/components/ui/UiTableWithPagination/index.vue';

const emit = defineEmits(['update:page', 'update:perPage', 'sort-change']);

const props = defineProps<{
  loading: boolean;
  items: IdcDto[];
  total: number;
  page: number;
  perPage: number;
  defaultSort: SortType | null;
}>();

const onSortChange = ({ prop, order }: SortType) => {
  emit('sort-change', { prop, order });
};
</script>
