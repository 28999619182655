<template>
  <UiTableWithPagination
    :data="items"
    :loading="loading"
    :page="page"
    :per-page="perPage"
    :total="total"
    @update:page="$emit('update:page', $event)"
    @update:per-page="$emit('update:perPage', $event)">
    <ElTableColumn prop="supplier.title" :label="$t('Income.Supplier')" />
    <ElTableColumn prop="count_products" :label="$t('Income.Goods')" />
    <ElTableColumn prop="amount_products" :label="$t('Income.Price')" />
    <ElTableColumn prop="warehouse.title" :label="$t('Income.Warehouse')" />
    <ElTableColumn prop="planed_income_date" :label="$t('Income.ArrivalDate')" />
    <ElTableColumn prop="status" :label="$t('Income.Status')">
      <template #default="{ row }">
        <StatusTag
          :title="row.status ? $t(`Income.Statuses.${row.status}`) : ''"
          :value="row.status" />
      </template>
    </ElTableColumn>
    <ElTableColumn prop="actions" :label="$t('Base.Actions')" width="300px">
      <template #default="{ row }">
        <div class="table-actions">
          <ElButton
            size="small"
            type="primary"
            plain
            :loading="row.loading?.accept"
            @click.stop="$emit('modal:open', row)"
            >{{ row.status !== 'accepted' ? $t('Income.AcceptIncome') : $t('Base.View') }}
          </ElButton>
          <ElPopconfirm
            :title="$t('Ui.PopConfirm.DefaultTitle')"
            :disabled="row.status === 'canceled'"
            @confirm="$emit('modal:cancel', row)">
            <template #reference>
              <ElButton
                size="small"
                type="danger"
                plain
                :loading="row.loading?.cancel"
                :disabled="row.status === 'canceled'">
                {{ $t('Income.CancelIncome') }}
              </ElButton>
            </template>
          </ElPopconfirm>
        </div>
      </template>
    </ElTableColumn>
  </UiTableWithPagination>
</template>

<script lang="ts">
import { PropType } from 'vue';
import { IncomeDto } from '@/types/api';

import UiTableWithPagination from '@/components/ui/UiTableWithPagination/index.vue';
import StatusTag from '@/components/common/StatusTag/index.vue';

export default {
  name: 'IncomesTable',
  components: {
    StatusTag,
    UiTableWithPagination,
  },
  emits: ['update:page', 'update:perPage', 'modal:open', 'modal:cancel'],
  props: {
    loading: { type: Boolean, required: true },
    items: { type: Array as PropType<IncomeDto[]>, required: true },
    page: { type: Number, required: true },
    perPage: { type: Number, required: true },
    total: { type: Number, required: true },
  },
};
</script>

<style lang="scss" scoped>
.table-actions {
  display: flex;
  gap: 20px;
}
</style>
<i18n src="@/locales/warehouse.locales.json" />
<i18n src="@/locales/notifications.locales.json" />
<i18n src="@/locales/ui.locales.json" />
<i18n src="@/locales/base.locales.json" />
