import { onlyLoggedInMiddleware } from '@/middlewares/onlyLoggedIn.middleware';
import { byUserRolesMiddleware } from '@/middlewares/byUserRoles.middleware';
import { User } from '@/models/User.model';

export const USERS_PATH_PREFIX = '/users';

export const USERS_ROUTE = {
  name: 'USERS',
  path: `${USERS_PATH_PREFIX}/employees`,
  component: 'VUsers',
  beforeEnter: [
    onlyLoggedInMiddleware,
    byUserRolesMiddleware([User.enum.roles.Manager, User.enum.roles.Director]),
  ],
  meta: {
    title: 'User.Users',
  },
};

export const USERS_VISIT_LOG_ROUTE = {
  name: 'USERS_VISIT_LOG',
  path: `${USERS_PATH_PREFIX}/visit-log`,
  component: 'VVisitLog',
  beforeEnter: [onlyLoggedInMiddleware, byUserRolesMiddleware([User.enum.roles.Director])],
  meta: {
    title: 'Patients.VisitLog',
  },
};

export const USER_ROUTE = {
  name: 'USER',
  path: '/user/:id',
  component: 'VUser',
  beforeEnter: [
    onlyLoggedInMiddleware,
    byUserRolesMiddleware([User.enum.roles.Manager, User.enum.roles.Director]),
  ],
  meta: {
    title: 'User.User',
  },
  props: true,
  childrenMap: {
    DEVICES: {
      name: 'DEVICES',
      path: 'devices',
      _fullPath: `/user/:id/devices`,
      component: 'VUserDevices',
      beforeEnter: [
        onlyLoggedInMiddleware,
        byUserRolesMiddleware([User.enum.roles.Manager, User.enum.roles.Director]),
      ],
      meta: {
        title: 'User.Devices',
      },
      props: true,
    },
    SALARY: {
      name: 'SALARY',
      path: 'salary',
      _fullPath: `/user/:id/salary`,
      component: 'VUserSalary',
      beforeEnter: [
        onlyLoggedInMiddleware,
        byUserRolesMiddleware([User.enum.roles.Manager, User.enum.roles.Director]),
      ],
      meta: {
        title: 'User.Accounting',
      },
      props: true,
    },
  },
};

export const routes = [USERS_ROUTE, USER_ROUTE, USERS_VISIT_LOG_ROUTE];
