import { onlyLoggedInMiddleware } from '@/middlewares/onlyLoggedIn.middleware';

export const HOSPITALS_ROUTE = {
  name: 'HOSPITALS',
  path: '/hospitals',
  component: 'VHospitals',
  beforeEnter: [onlyLoggedInMiddleware],
  meta: {
    title: 'Hospital.Hospital',
  },
};

export const HOSPITAL_ROUTE = {
  name: 'HOSPITAL',
  path: '/hospitals/:id',
  component: 'VHospital',
  props: true,
  beforeEnter: [onlyLoggedInMiddleware],
  meta: {
    title: 'Hospital.Hospital',
  },
};

export const routes = [HOSPITALS_ROUTE, HOSPITAL_ROUTE];
