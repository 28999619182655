<template>
  <div class="default-menu">
    <div class="default-menu-top">
      <img class="default-menu__logo" src="@/assets/images/logo.svg" alt="logo" />
    </div>
    <nav class="default-menu__nav default-menu-nav">
      <ElMenu
        :default-active="route.path"
        class="default-menu__container"
        router
        :default-openeds="defaultOpeneds">
        <template v-for="item in filteredMenuByRole">
          <template v-if="item.submenu?.length">
            <ElSubMenu :index="item.key" :key="item.key">
              <template #title>
                <UiIcon v-if="item.icon" :icon="item.icon" />
                <span> {{ item.title }}</span>
              </template>
              <ElMenuItem
                v-for="subItem in item.submenu"
                class="default-menu-nav__item"
                :index="subItem.key"
                :route="subItem.route"
                :key="subItem.key">
                <template #title>
                  <UiIcon v-if="subItem.icon" :icon="subItem.icon" />
                  <span> {{ subItem.title }}</span></template
                >
              </ElMenuItem>
            </ElSubMenu>
          </template>

          <template v-else>
            <ElMenuItem
              :index="item.key"
              :route="item.route"
              :key="item.key"
              class="default-menu-nav__item">
              <template #title>
                <UiIcon v-if="item.icon" :icon="item.icon" />
                <span> {{ item.title }}</span></template
              >
            </ElMenuItem>
          </template>
        </template>
      </ElMenu>
    </nav>

    <div class="default-menu__actions default-menu-actions">
      <slot name="actions"></slot>

      <ElButton class="default-menu-actions__btn" link @click="logout">
        <template #icon>
          <UiIcon :icon="icons.LOGOUT" />
        </template>

        {{ $t('Base.Logout') }}
      </ElButton>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: 'SidebarMenu',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { computed } from 'vue';
import { getSidebarMenuItems } from './getSidebarMenuItems';

import * as icons from '@/enums/icons.enum.js';

const store = useStore();
const route = useRoute();

const user = computed(() => store.state.auth.user);
const doctorId = computed(() => user.value?.doctor_id);

const filteredMenuByRole = computed(() => {
  const menu = getSidebarMenuItems({ doctorId: doctorId.value });
  return menu.filter((item) => item.roles?.includes(user.value.role));
});

const defaultOpeneds = computed(() => {
  return filteredMenuByRole.value
    .map((item) => item.prefix)
    .filter((prefix) => {
      if (!prefix) {
        return false;
      } else {
        return route.path.startsWith(prefix);
      }
    }) as string[];
});

const logout = () => {
  store.dispatch('auth/logout');
};
</script>

<style lang="scss" src="./index.scss"></style>
