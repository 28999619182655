{
  "en": {
    "WaitingConfirmTitle": "Wait for system administrator confirmation",
    "BlockedTitle": "Device locked",
    "WaitingConfirm": "After confirmation, you will be automatically logged in",
    "Blocked": "Contact your system administrator"
  },
  "ru": {
    "WaitingConfirmTitle": "Ожидайте подтверждение администратора системы",
    "BlockedTitle": "Устройство заблокировано",
    "WaitingConfirm": "После подтверждения вы автоматически войдете в систему",
    "Blocked": "Обратитесь к администратору системы"
  },
  "uz": {
    "WaitingConfirmTitle": "Tizim administratori tasdiqlanishini kuting",
    "BlockedTitle": "Qurilish zablokirovkasi",
    "WaitingConfirm": "Tasdiqlangandan so'ng siz avtomatik ravishda tizimga kirasiz",
    "Blocked": "Tizim administratoringizga murojaat qiling"
  }
}
