﻿{
  "en": {
    "Accounting": {
      "Accounting": "Accounting",
      "Active": "Active",
      "DetailedInformation": "Detailed information",
      "Inactive": "Inactive"
    },
    "Calculation": {
      "AlwaysWorking": "We are always working to improve our service. We cannot calculate the optimal cost at the moment due to lack of data, so we provide you with the opportunity to calculate it yourself.",
      "AmountSold": "The amount for which it is most likely to be sold on the market under competitive conditions ",
      "AmountSold2": "The amount for which it is most likely to be marketed competitively. Below are the prices for this service at popular clinics in the city",
      "AverageMarketPrice": "Average market price",
      "BestCost": "Calculate the best price for the service per click",
      "BuyCertainPrice": "This is an economic category that reflects the desire and ability of consumers to buy a certain amount of goods at a certain price. Measured in %.",
      "CalculateBestValue": " Calculate the best price ",
      "CompetitorPrice": "Competitor's price, demand and markup",
      "CostPrice": "Cost Price",
      "DoctorWantsAdd": "If the doctor wants to add his/her price, he/she can enter the total price manually",
      "MarketDemand": "Demand in the market",
      "Markup": "Markup",
      "MarkupIncome": "A markup on the cost of goods or services, which constitutes the income of the organization",
      "MaximumProfit": "A unit price at which it can be sold at maximum profit under given market conditions ",
      "OptimalCostService": "The optimal cost of the service, calculated by us",
      "OptimalMarkup": " Optimal Markup ",
      "OptimalPrice": " Optimal Price",
      "ServiceCostCalculation": "Calculation of service cost ",
      "SimilarServicesClinics": "Similar services in other clinics",
      "TotalCostService": "Total cost of the service",
      "TotalPrice": "Total price"
    },
    "Outcome": {
      "Add": "Add expense",
      "AddType": "Add expense type",
      "Auto": "Post automatically",
      "Conduct": "Conduct",
      "Date": "Expenditure date",
      "Name": "Expense name",
      "NamePlaceholder": "Enter the name of the expense",
      "Recipient": "Recipient",
      "RecipientPlaceholder": "Enter the full name or name of the recipient",
      "SettingSum": "Specify the amount of the expense",
      "Status": "Expense status",
      "Summary": "Amount of expense",
      "Type": "Flow type"
    },
    "Salary": {
      "AddCalculating": "Add payroll",
      "CalculateSalary": "Calculate employee salary",
      "Date": "Date",
      "DateOfLastPayment": "Date of last payment",
      "EditCalculating": "Edit payroll",
      "PayOut": "Pay out",
      "PreviousPaymentAmount": "Previous payment amount",
      "SalaryDetails": "Salary details",
      "Status": {
        "canceled": "Canceled",
        "payed": "Paid",
        "waiting": "Pending"
      },
      "SumCost": "Sum"
    },
    "Services": {
      "AddExpenseType": "Add expense type",
      "AddService": "Add service",
      "CategoryOfService": "Category of service",
      "DoctorFee": "Doctor's fee",
      "EditExpenseType": "Edit expense type",
      "EditService": "Edit service",
      "EnterServiceName": "Enter service name",
      "ExpendableMaterials": "Expendable materials",
      "ExpenseName": "Name of expenditures",
      "IKPUCode": "IKPU code",
      "PackageCode": "Package code",
      "ServiceCosts": "Service costs",
      "ServiceInformation": "Service information",
      "ServiceType": {
        "Analysis": "Analysis",
        "Consultation": "Consultation",
        "Procedure": "Procedure",
        "Product": "Product"
      },
      "ShowInApp": "Show in app",
      "Tooltip": {
        "FieldsChanged": "These fields cannot be changed"
      },
      "TypeOfService": "Type of service",
      "UnitOfMeasurement": "Unit of measurement",
      "VAT": "VAT"
    },
    "User": {
      "SalaryAmount": "Salary amount",
      "Schedule": "Schedule"
    }
  },
  "ru": {
    "Accounting": {
      "Accounting": "Бухгалтерия",
      "Active": "Активный",
      "DetailedInformation": "Подробная информация",
      "Inactive": "Неактивный"
    },
    "Calculation": {
      "AlwaysWorking": "Мы всегда работаем над улучшением нашего сервиса. Мы не можем рассчитать оптимальную стоимость в данный момент из-за недостатка данных, мы предоставляем вам возможность рассчитать ее самостоятельно.",
      "AmountSold": "Сумма, за которую с наибольшей вероятностью будет реализован на рынке в конкурентных условиях.",
      "AmountSold2": "Сумма, за которую с наибольшей вероятностью будет реализован на рынке в конкурентных условиях. Ниже предоставлены цены на подобную услугу в популярных клиниках города'",
      "AverageMarketPrice": "Средняя рыночная цена",
      "BestCost": "Рассчитайте лучшую стоимость услуги за 1 клик, учитывая",
      "BuyCertainPrice": "Это экономическая категория, отражающая желание и возможность потребителей купить какое-либо количество товара по определенной цене. Измеряетеся в %.",
      "CalculateBestValue": "Рассчитать лучшую стоимость",
      "CompetitorPrice": "цену у конкурентов, спрос и наценку",
      "CostPrice": "Себестоимость",
      "DoctorWantsAdd": "Если врач хочет добавить свою цену, то он может ввести итоговую цену вручную",
      "MarketDemand": "Спрос на рынке",
      "Markup": "Наценка",
      "MarkupIncome": "Надбавка к себестоимости товара или услуги, которая составляет доход организации",
      "MaximumProfit": "Цена единицы продукции, по которой она может быть продана с максимальной прибылью в данных рыночных условиях.",
      "OptimalCostService": "Оптимальная стоимость услуги, рассчитанная нами",
      "OptimalMarkup": "Оптимальная наценка",
      "OptimalPrice": "Оптимальная цена",
      "ServiceCostCalculation": "Расчет стоимости услуги",
      "SimilarServicesClinics": "Похожие услуги в других клиниках",
      "TotalCostService": "Итоговая стоимость услуги",
      "TotalPrice": "Итоговая цена"
    },
    "Outcome": {
      "Add": "Добавить расход",
      "AddType": "Добавить тип расхода",
      "Auto": "Провести автоматически",
      "Conduct": "Провести",
      "Date": "Дата расхода",
      "Name": "Наименование расхода",
      "NamePlaceholder": "Введите наименование расхода",
      "Recipient": "Получатель",
      "RecipientPlaceholder": "Введите ФИО или название получателя",
      "SettingSum": "Укажите сумму расхода",
      "Status": "Статус расхода",
      "Summary": "Сумма расхода",
      "Type": "Тип расхода"
    },
    "Salary": {
      "AddCalculating": "Добавить расчет зарплаты",
      "CalculateSalary": "Рассчитайте зарплату сотрудника",
      "Date": "Дата",
      "DateOfLastPayment": "Дата предыдущей выплаты",
      "EditCalculating": "Редактировать расчет зарплаты",
      "PayOut": "Выплатить",
      "PreviousPaymentAmount": "Сумма предыдущей выплаты",
      "SalaryDetails": "Детализация зарплаты",
      "Status": {
        "canceled": "Отменено",
        "payed": "Выплачено",
        "waiting": "В ожидании"
      },
      "SumCost": "Сумма"
    },
    "Services": {
      "AddExpenseType": "Добавить тип расхода",
      "AddService": "Добавить услугу",
      "CategoryOfService": "Категория услуги",
      "DoctorFee": "Комиссия врача",
      "EditExpenseType": "Редактировать тип расхода",
      "EditService": "Редактировать услугу",
      "EnterServiceName": "Введите наименование услуги",
      "ExpendableMaterials": "Расходные материалы",
      "ExpenseName": "Наименование расхода",
      "IKPUCode": "Код ИКПУ",
      "PackageCode": "Код упаковки",
      "ServiceCosts": "Расходы на услугу",
      "ServiceInformation": "Информация об услуге",
      "ServiceType": {
        "Analysis": "Анализ",
        "Consultation": "Консультация",
        "Procedure": "Процедура",
        "Product": "Товар"
      },
      "ShowInApp": "Показывать в приложении",
      "Tooltip": {
        "FieldsChanged": "Эти поля нельзя изменить"
      },
      "TypeOfService": "Тип услуги",
      "UnitOfMeasurement": "Единица измерения",
      "VAT": "НДС"
    },
    "User": {
      "SalaryAmount": "Сумма оклада",
      "Schedule": "График работы"
    }
  },
  "uz": {
    "Accounting": {
      "Accounting": "Buxgalteriya",
      "Active": "Faol",
      "DetailedInformation": "Batafsil ma'lumot",
      "Inactive": "Faol emas"
    },
    "Calculation": {
      "AlwaysWorking": "Biz doimo xizmatimizni yaxshilash ustida ishlaymiz. Hozir ma'lumotlar kam bo‘lgani sababli optimal narxni hisoblay olmaymiz, hisoblash imkoniyatini sizga taklif etamiz",
      "AmountSold": "Raqobat sharoitida bozorda sotilishi ehtimoli yuqori bo'lgan narx.",
      "AmountSold2": "Raqobat sharoitida bozorda sotilishi ehtimoli yuqori bo'lgan narx. Quyida shahardagi mashhur klinikalarda shunga o'xshash xizmat narxlari keltirilgan.",
      "AverageMarketPrice": "O'rtacha bozor narxi",
      "BestCost": "1 klik bilan xizmatning eng yaxshi narxini hisoblang",
      "BuyCertainPrice": "Bu iste'molchilarning qanchadir miqdordagi tovarlarni ma'lum bir narxda sotib olish istagi va qobiliyatini aks ettiruvchi iqtisodiy kategoriyadir. % da o'lchanadi",
      "CalculateBestValue": "Eng yaxshi narxni hisoblash",
      "CompetitorPrice": "raqobatchilar narxi, talab va ustama narxni hisobga olgan holda",
      "CostPrice": "Tannarx",
      "DoctorWantsAdd": "Agar shifokor o'z narxini qo'shishni xohlasa, yakuniy narxni qo'lda kiritishi mumkin",
      "MarketDemand": "Bozor talabi",
      "Markup": "Ustama narx",
      "MarkupIncome": "Tashkilot daromadini tashkil etuvchi mahsulot yoki xizmat tannarxiga ustama narx",
      "MaximumProfit": "Ma'lum bozor sharoitida uni maksimal foyda bilan sotish mumkin bo'lgan mahsulot birligi narxi.",
      "OptimalCostService": "Xizmatning biz hisoblagan optimal narxi",
      "OptimalMarkup": "Optimal ustama narx",
      "OptimalPrice": "Optimal narx",
      "ServiceCostCalculation": "Xizmat narxini hisoblash",
      "SimilarServicesClinics": "Boshqa klinikalarda o‘xshash xizmatlar",
      "TotalCostService": "Xizmatning yakuniy narxi",
      "TotalPrice": "Umumiy narx"
    },
    "Outcome": {
      "Add": "Harajatni qo'shish",
      "AddType": "Harajat turini qo'shish",
      "Auto": "Avtomatik tarzda bajarish",
      "Conduct": "Bajarish",
      "Date": "Harajat sanasi",
      "Name": "Harajat nomi",
      "NamePlaceholder": "Harajat nomini kiriting",
      "Recipient": "Qabul qiluvchi",
      "RecipientPlaceholder": "Qabil qiluvchining IFSH kiriting",
      "SettingSum": "Harajat miqdorini kiriting",
      "Status": "Harajat statusi",
      "Summary": "Harajat summasi",
      "Type": "Harajat turi"
    },
    "Salary": {
      "AddCalculating": "Oylik maoshini hisoblashni qo'shish",
      "CalculateSalary": "Xodimning oylik maoshini hisoblang",
      "Date": "Sana",
      "DateOfLastPayment": "Oxirgi to'lov sanasi",
      "EditCalculating": "Oylik maoshini tahrirlash",
      "PayOut": "To'lash",
      "PreviousPaymentAmount": "Oxirgi to'lov summasi",
      "SalaryDetails": "Oylik maoshi tafsilotlari",
      "Status": {
        "canceled": "Bekor qilingan",
        "payed": "To'langan",
        "waiting": "Kutilmoqda"
      },
      "SumCost": "Summa"
    },
    "Services": {
      "AddExpenseType": "Xarajat turini qo‘shish",
      "AddService": "Xizmatni qo‘shish",
      "CategoryOfService": "Xizmat toifasi",
      "DoctorFee": "Shifokor komissiyasi",
      "EditExpenseType": "Xarajat turini tahrirlash",
      "EditService": "Xizmatni tahrirlash ",
      "EnterServiceName": "Xizmat nomini kiriting",
      "ExpendableMaterials": "Sarflanadigan materiallar",
      "ExpenseName": "Xarajat nomi",
      "IKPUCode": "IKPU Kodi",
      "PackageCode": "Qadoq kodi",
      "ServiceCosts": "Xizmat xarajatlari",
      "ServiceInformation": "Xizmat haqida ma'lumot",
      "ServiceType": {
        "Analysis": "Tahlil",
        "Consultation": "Konsultatsiya",
        "Procedure": "Protsedura",
        "Product": "Tovar"
      },
      "ShowInApp": "Ilovada ko‘rsatish",
      "Tooltip": {
        "FieldsChanged": "Bu qatorlarni o‘zgartirish mumkin emas "
      },
      "TypeOfService": "Xizmat turi",
      "UnitOfMeasurement": "O‘lchov birligi",
      "VAT": "QQS"
    },
    "User": {
      "SalaryAmount": "Ish haqi summasi",
      "Schedule": "Ish vaqti"
    }
  }
}
