<template>
  <ElDialog
    class="treatment-modal"
    :model-value="modelValue"
    :title="$t('Base.SetTreatment')"
    @update:modelValue="$emit('update:modelValue')">
    <ElForm
      id="treatment-modal-form"
      class="treatment-modal-form"
      label-position="top"
      @submit.prevent="submitHandler">
      <div class="treatment-modal-grid">
        <ElCard class="treatment-modal-grid__info-card" shadow="never">
          <template #header> {{ $t('Base.Info') }}</template>

          <ElFormItem :label="$t('Base.Naming')">
            <ElInput v-model="localTreatment.title" :placeholder="$t('Base.Naming')" required />
          </ElFormItem>

          <ElFormItem :label="$t('Base.Notes')">
            <ElInput v-model="localTreatment.description" type="textarea" :rows="3" />
          </ElFormItem>

          <ElFormItem class="treatment-modal__inline-form-item" :label="$t('Base.Patient')">
            {{ localTreatment.user?.name }}
          </ElFormItem>
        </ElCard>

        <ElCard class="treatment-modal-grid__create-drug" shadow="never">
          <CreateTreatmentDrug @drug:create="createDrug" />
        </ElCard>

        <TreatmentDrugsTable
          class="treatment-modal-grid__treatment-drugs-table"
          :items="localTreatment.drugs"
          editable
          deletable
          height="300px"
          max-height="300px"
          @drug:update="updateDrug"
          @drug:remove="removeDrug" />
      </div>
    </ElForm>

    <template #footer>
      <ElButton type="primary" native-type="submit" form="treatment-modal-form" :loading="loading">
        {{ localTreatment.id ? $t('Base.Save') : $t('Base.Create') }}
      </ElButton>
    </template>
  </ElDialog>
</template>

<script>
import axios from 'axios';
import cloneDeep from 'lodash.clonedeep';
import { Treatment } from '@/models/Treatment.model';
import { GlobalModalAction } from '@/models/client/ModalAndDrawer/GlobalModalAction';
import { Appointment } from '@/models/appointment/Appointment.model';
import { User } from '@/models/User.model';

import CreateTreatmentDrug from '@/components/treatments/CreateOrEditTreatmentModal/CreateTreatmentDrug/index.vue';
import TreatmentDrugsTable from '@/components/treatments/TreatmentDrugsTable/index.vue';

export default {
  name: 'CreateOrEditTreatmentModal',
  components: { TreatmentDrugsTable, CreateTreatmentDrug },
  emits: ['update:modelValue', 'action'],
  props: {
    modelValue: Boolean,
    treatment: [Treatment, Object],
    appointment: [Appointment, Object],
    user: [User, Object],
  },
  data() {
    return {
      /** @type {Treatment} */
      localTreatment: null,
      loading: false,
    };
  },
  watch: {
    modelValue: {
      handler() {
        this.localTreatment = new Treatment(
          this.treatment
            ? cloneDeep(this.treatment)
            : {
                user_id: this.appointment?.patient_id ?? this.user?.id ?? null,
                user: this.appointment?.patient ?? this.user ?? null,
                appointment_id: this.appointment?.id ?? null,
                appointment: this.appointment ?? null,
                doctor_id: this.$store.state.auth.user.doctor_id ?? null,
                doctor: this.$store.state.auth.user.doctor ?? null,
              }
        );
      },
      immediate: true,
    },
  },

  methods: {
    async submitHandler() {
      if (this.loading) return;
      this.loading = true;

      try {
        const treatment = this.localTreatment.id
          ? await this.editTreatment()
          : await this.createTreatment();

        this.$notify({
          type: 'success',
          title: this.$t(`Notifications.Success${this.localTreatment.id ? 'Updated' : 'Created'}`),
        });
        this.$emit(
          'action',
          new GlobalModalAction({
            name: this.localTreatment.id ? 'updated' : 'created',
            data: { treatment },
          })
        );
      } catch (err) {
        this.$notify({
          type: 'error',
          title: axios.isAxiosError(err) ? err.message : String(err),
        });
      }

      this.loading = false;
    },

    async createTreatment() {
      const { treatment } = await Treatment.create(this.localTreatment, {
        url: 'b2g/treatments/create',
      });
      return treatment;
    },

    async editTreatment() {
      const { treatment } = await Treatment.update({
        id: this.localTreatment.id,
        payload: this.localTreatment,
        options: {
          url: `b2g/treatments/${this.localTreatment.id}/update`,
        },
      });
      return treatment;
    },

    createDrug(drug) {
      const newDrug = {
        ...drug,
        price: 0,
        uuid: self.crypto.randomUUID(),
      };

      if (this.localTreatment.drugs.find((elem) => elem.id === drug.id)) return;

      this.localTreatment.drugs = [newDrug, ...new Set(this.localTreatment.drugs)];

      this.localTreatment.products.unshift({
        ...drug,
        price: 0,
      });
    },
    updateDrug(drug) {
      if (this.localTreatment) {
        const index = this.localTreatment.drugs.findIndex((elem) => elem.id === drug.id);
        this.localTreatment.drugs[index] = drug;
        this.localTreatment.products[index] = drug;
      }
      const index = this.treatment?.drugs.findIndex((elem) => elem.id === drug.id);
      this.localTreatment.drugs[index] = drug;
      this.localTreatment.products[index] = drug;
    },

    removeDrug(drug) {
      if (this.localTreatment) {
        const index = this.localTreatment.drugs.filter((elem) => elem.id !== drug.id);
        this.localTreatment.drugs = index;
        this.localTreatment.products = index;
        return;
      }
      const index = this.treatment?.drugs.filter((elem) => elem.id !== drug.id);
      this.localTreatment.drugs = index;
      this.localTreatment.products = index;
    },
  },
};
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/patients.locales.json" />
<i18n src="@/locales/notifications.locales.json" />
