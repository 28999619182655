import { createRouter, createWebHistory } from 'vue-router';
import { setComponentInRoutesByViewsFolder } from '@/utils/router.utils';
import { APPOINTMENTS_ROUTE } from '@/router/appointments.routes';
import { STATISTIC_DASHBOARD_ROUTE } from '@/router/statistic.routes';
import { LABORATORY_ORDERS_ROUTE } from '@/router/laboratory.routes';
import { I18nService } from '@/services/i18n.service';

import { onlyLoggedInMiddleware } from '@/middlewares/onlyLoggedIn.middleware';
import { Store } from '@/store';
import { User } from '@/models/User.model';
import NProgress from 'nprogress';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      beforeEnter: [onlyLoggedInMiddleware, _redirectCurrentPageByUserRole],
    },

    ...setComponentInRoutesByViewsFolder({
      routes: [
        ..._getRoutes(),

        {
          path: '/404',
          name: '404',
          component: 'VNotFound',
          meta: { title: '404' },
        },
        {
          path: '/:pathMatch(.*)*',
          redirect: '/404',
        },
      ],
    }),
  ],
  scrollBehavior() {
    return { top: 0, behavior: 'smooth' };
  },
});

router.beforeEach((to, _from, next) => {
  if (to.meta.title) {
    document.title = I18nService.t(to.meta.title);
  } else {
    document.title = 'zordoc';
  }
  next();
});
router.beforeResolve((to, _from, next) => {
  if (to.path) {
    NProgress.start();
  }
  next();
});
router.afterEach(() => {
  NProgress.done();
});
export { router as Router };

// Assets

function _redirectCurrentPageByUserRole(to, from, next) {
  // TODO: переделать на объект
  switch (Store.state.auth.user?.role) {
    case User.enum.roles.Manager:
    case User.enum.roles.Doctor:
    case User.enum.roles.Nurse:
      return next(APPOINTMENTS_ROUTE.path);
    case User.enum.roles.Director:
      return next(STATISTIC_DASHBOARD_ROUTE.path);
    case User.enum.roles.Laboratory:
      return next(LABORATORY_ORDERS_ROUTE.path);
  }
}

function _getRoutes() {
  const routesModules = import.meta.glob('@/router/**/*.routes.js', { eager: true });
  return Object.keys(routesModules).reduce(
    (acc, key) => [...acc, ...routesModules[key].routes],
    []
  );
}
