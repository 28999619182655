<template>
  <UiTableWithPagination
    :data="props.items"
    :loading="props.loading"
    :page="props.page"
    :per-page="props.perPage"
    :total="props.total"
    :default-sort="defaultSort || {}"
    @update:page="$emit('update:page', $event)"
    @update:per-page="$emit('update:perPage', $event)"
    @sort-change="onSortChange"
    @row-click="$emit('row-click', $event)">
    <ElTableColumn prop="title" :label="$t('Analytics.VaccinationTitle')" width="250px" />
    <ElTableColumn prop="count_vaccines" :label="$t('Analytics.Vaccinated')" sortable="custom" />
    <ElTableColumn prop="percent_vaccines" :label="$t('Analytics.Percent')">
      <template #default="{ row }">
        {{ `${row.percent_vaccines}%` }}
      </template>
    </ElTableColumn>
    <ElTableColumn prop="count_vaccines_man" :label="$t('Analytics.Men')" sortable="custom" />
    <ElTableColumn prop="count_vaccines_woman" :label="$t('Analytics.Women')" sortable="custom" />
    <ElTableColumn prop="actions" :label="$t('Base.Actions')" min-width="120px">
      <ElButton type="primary" text>
        <template #icon>
          <UiIcon :icon="icons.EYE" />
        </template>
        {{ $t('Base.Open') }}
      </ElButton>
    </ElTableColumn>
  </UiTableWithPagination>
</template>

<script lang="ts">
export default {
  name: 'VaccinationMicrodistrictsTable',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { VaccinationMicrodistrictsDto } from '@/types/api';
import { SortType } from '@/types/common';
import * as icons from '@/enums/icons.enum.js';

const emit = defineEmits(['update:page', 'update:perPage', 'sort-change', 'row-click']);

const props = defineProps<{
  loading: boolean;
  items: VaccinationMicrodistrictsDto[];
  total: number;
  page: number;
  perPage: number;
  defaultSort: SortType | null;
}>();

const onSortChange = ({ prop, order }: SortType) => {
  emit('sort-change', { prop, order });
};
</script>
