<template>
  <LayoutByUserRole content-class="v-analytics-analysis" fix-height>
    <div class="v-analytics-analysis__header">
      <ElInput
        class="v-analytics-analysis__department"
        v-model="searchString"
        @input="debounceInput"
        :placeholder="$t('Base.Search')"
        clearable />
      <ElDatePicker
        class="v-analytics-analysis__date"
        v-model="date.value"
        :format="DATE_FORMAT"
        type="daterange"
        unlink-panels
        :value-format="DATE_FORMAT"
        :placeholder="$t('DateAndTime.Date')" />
      <UiModelsAutocompleteSearch
        :model-value="microdistrictIds.value"
        :model-for-use="Microdistrict"
        method-name="getItems"
        label="title"
        value="id"
        :placeholder="$t('Analytics.Mictrodistrict')"
        multiple
        clearable
        @update:modelValue="microdistrictIds.value = $event" />
    </div>
    <AnalyticsAnalysisTable
      :loading="loading"
      :items="items"
      :total="total"
      :default-sort="defaultSort"
      @sort-change="onSortChange"
      v-model:page="page.value"
      v-model:per-page="perPage.value" />
  </LayoutByUserRole>
</template>

<script lang="ts">
export default {
  name: 'VAnalyticsAnalysis',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { useStore } from 'vuex';
import { computed, ref, watch } from 'vue';
import { usePage, usePerPage, useDatePeriod } from '@/hooks/query';
import { useQuery } from '@/hooks/useQuery.hook';
import { getMonthPeriod } from '@/utils/dateAndTime.utils';
import { compareQueriesThenLoadData } from '@/utils/router.utils';
import { DATE_FORMAT } from '@/config/dateAndTime.config';
import { SortType } from '@/types/common';
import { Microdistrict } from '@/models/patronage/Microdistrict.model';
import { Analytics } from '@/models/analytics/analytics.model';
import debounce from 'lodash.debounce';

import LayoutByUserRole from '@/components/layouts/LayoutByUserRole/index.vue';
import AnalyticsAnalysisTable from '@/components/analytics/tables/AnalyticsAnalysisTable/Index.vue';

const store = useStore();
const search = useQuery({ field: 'searchAnalysis' });
const page = usePage();
const perPage = usePerPage();
const loading = ref(false);
const total = computed(() => store.state.analyticsAnalysis.total);
const items = computed(() => store.state.analyticsAnalysis.data);
const date = useDatePeriod(getMonthPeriod());

const searchString = ref(search.value);

const debounceInput = debounce((value) => {
  search.value = value;
}, 600);

const microdistrictIds = useQuery({ field: 'microdistrict_ids', valueIsNumberArray: true });

const defaultSort = ref<null | SortType>({
  prop: '',
  order: '',
});

const getConvertOrder = () => {
  if (!!defaultSort.value?.order) {
    return defaultSort.value.order === 'ascending' ? 'ASC' : 'DESC';
  } else {
    return null;
  }
};

const onSortChange = ({ prop, order }: SortType) => {
  defaultSort.value = { prop: prop, order: order };
};

const query = computed(() => ({
  page: page.value,
  per_page: perPage.value,
  start_at: date.value[0],
  end_at: date.value[1],
  search: search.value,
  microdistrict_ids: microdistrictIds.value,
  count: defaultSort.value?.prop === 'count' ? getConvertOrder() : null,
  percent: defaultSort.value?.prop === 'percent' ? getConvertOrder() : null,
  percent_returned: defaultSort.value?.prop === 'percent_returned' ? getConvertOrder() : null,
  man: defaultSort.value?.prop === 'man' ? getConvertOrder() : null,
  woman: defaultSort.value?.prop === 'woman' ? getConvertOrder() : null,
  child: defaultSort.value?.prop === 'child' ? getConvertOrder() : null,
}));

const getAnalyticsAnalysis = async () => {
  loading.value = true;
  const results = await Analytics.getAnalysis(query.value);

  if (results?.data) {
    store.dispatch('analyticsAnalysis/setData', {
      items: results.data,
      total: results.meta.total,
      overwriteDataState: true,
    });
  }

  loading.value = false;
};

watch(
  query,
  (value, oldValue) => {
    compareQueriesThenLoadData({
      query: value,
      oldQuery: oldValue,
      getData: getAnalyticsAnalysis,
      resetPage: page.reset,
      fieldsForResetPage: [
        'start_at',
        'end_at',
        'count',
        'percent',
        'percent_returned',
        'man',
        'woman',
        'child',
      ],
    });
  },
  { deep: true, immediate: true }
);
</script>

<style lang="scss" src="./index.scss"></style>

<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/dateAndTime.locales.json" />
<i18n src="@/locales/analytics.locales.json" />
