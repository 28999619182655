import { onlyLoggedInMiddleware } from '@/middlewares/onlyLoggedIn.middleware';
import { byUserRolesMiddleware } from '@/middlewares/byUserRoles.middleware';
import { User } from '@/models/User.model';

export const USERS_MAP_ROUTE = {
  name: 'USERSMAP',
  path: `/users_map`,
  component: 'VPatientsMap',
  beforeEnter: [
    onlyLoggedInMiddleware,
    byUserRolesMiddleware([User.enum.roles.Director, User.enum.roles.Doctor]),
  ],
  meta: {
    title: 'Analytics.Map',
  },
};

export const routes = [USERS_MAP_ROUTE];
