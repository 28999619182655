import { analyticsDiagnoses } from './analyticsDiagnoses.store';
import { analyticsAnalysis } from './analyticsAnalysis.store';
import { analyticsMicrodistricts } from './analyticsMicrodistricts.store';
import { analyticsPatronage } from './analyticsPatronage.store';

export default {
  analyticsAnalysis,
  analyticsPatronage,
  analyticsDiagnoses,
  analyticsMicrodistricts,
};
