{
  "en": {
    "Home": "Home",
    "Title": "Page not found"
  },
  "ru": {
    "Home": "На главную",
    "Title": "Страница не найдена"
  },
  "uz": {
    "Home": "Asosiy sahifaga",
    "Title": "Sahifa topilmadi"
  }
}