{
  "en": {
    "DateAndTime": {
      "CompletionDate": "Completion date",
      "CreatedAt": "Created",
      "Date": "Date",
      "DateOfIssues": "Issue Date",
      "Day": "Day",
      "Days": {
        "0": {
          "Full": "Sunday",
          "Short": "Sun"
        },
        "1": {
          "Full": "Monday",
          "Short": "Mon"
        },
        "2": {
          "Full": "Tuesday",
          "Short": "Tue"
        },
        "3": {
          "Full": "Wednesday",
          "Short": "Wed"
        },
        "4": {
          "Full": "Thursday",
          "Short": "Thu"
        },
        "5": {
          "Full": "Friday",
          "Short": "Fri"
        },
        "6": {
          "Full": "Saturday",
          "Short": "Sat"
        }
      },
      "DaysCount": "Number of days",
      "EndAt": "End in",
      "EndDate": "End",
      "Minutes": "Minutes",
      "Months": {
        "0": {
          "Full": "January",
          "Short": "Jan"
        },
        "1": {
          "Full": "February",
          "Short": "Feb"
        },
        "2": {
          "Full": "March",
          "Short": "March"
        },
        "3": {
          "Full": "April",
          "Short": "Apr"
        },
        "4": {
          "Full": "May",
          "Short": "May"
        },
        "5": {
          "Full": "June",
          "Short": "June"
        },
        "6": {
          "Full": "July",
          "Short": "July"
        },
        "7": {
          "Full": "August",
          "Short": "Aug"
        },
        "8": {
          "Full": "September",
          "Short": "Sep"
        },
        "9": {
          "Full": "October",
          "Short": "Oct"
        },
        "10": {
          "Full": "November",
          "Short": "Nov"
        },
        "11": {
          "Full": "December",
          "Short": "Dec"
        }
      },
      "SelectDate": "Select Date",
      "SelectTime": "Select Time",
      "StartAt": "Start at",
      "StartDate": "Start",
      "StartingDate": "Starting date",
      "Time": "Time",
      "Week": "Week"
    }
  },
  "ru": {
    "DateAndTime": {
      "CompletionDate": "Дата завершения",
      "CreatedAt": "Создана",
      "Date": "Дата",
      "DateOfIssues": "Дата выдачи",
      "Day": "День",
      "Days": {
        "0": {
          "Full": "Воскресенье",
          "Short": "Вс"
        },
        "1": {
          "Full": "Понедельник",
          "Short": "Пн"
        },
        "2": {
          "Full": "Вторник",
          "Short": "Вт"
        },
        "3": {
          "Full": "Среда",
          "Short": "Ср"
        },
        "4": {
          "Full": "Четверг",
          "Short": "Чт"
        },
        "5": {
          "Full": "Пятница",
          "Short": "Пт"
        },
        "6": {
          "Full": "Суббота",
          "Short": "Сб"
        }
      },
      "DaysCount": "Количество дней",
      "EndAt": "Конец в",
      "EndDate": "Конец",
      "Minutes": "Минуты",
      "Months": {
        "0": {
          "Full": "Январь",
          "Short": "Янв"
        },
        "1": {
          "Full": "Февраль",
          "Short": "Фев"
        },
        "2": {
          "Full": "Март",
          "Short": "Март"
        },
        "3": {
          "Full": "Апрель",
          "Short": "Апр"
        },
        "4": {
          "Full": "Май",
          "Short": "Май"
        },
        "5": {
          "Full": "Июнь",
          "Short": "Июнь"
        },
        "6": {
          "Full": "Июль",
          "Short": "Июль"
        },
        "7": {
          "Full": "Август",
          "Short": "Авг"
        },
        "8": {
          "Full": "Сентябрь",
          "Short": "Сен"
        },
        "9": {
          "Full": "Октябрь",
          "Short": "Окт"
        },
        "10": {
          "Full": "Ноябрь",
          "Short": "Нояб"
        },
        "11": {
          "Full": "Декабрь",
          "Short": "Дек"
        }
      },
      "SelectDate": "Выберите дату",
      "SelectTime": "Выберите время",
      "StartAt": "Начало в",
      "StartDate": "Начало",
      "StartingDate": "Дата начала",
      "Time": "Время",
      "Week": "Неделя"
    }
  },
  "uz": {
    "DateAndTime": {
      "CompletionDate": "Bajarilgan sana",
      "CreatedAt": "Yaratilgan",
      "Date": "Sana",
      "DateOfIssues": "Berilgan sana",
      "Day": "Kun",
      "Days": {
        "0": {
          "Full": "Yakshanba",
          "Short": "Ya"
        },
        "1": {
          "Full": "Dushanba",
          "Short": "Du"
        },
        "2": {
          "Full": "Seshanba",
          "Short": "Se"
        },
        "3": {
          "Full": "Chorshanba",
          "Short": "Ch"
        },
        "4": {
          "Full": "Payshanba",
          "Short": "Psh"
        },
        "5": {
          "Full": "Juma",
          "Short": "Juma"
        },
        "6": {
          "Full": "Shanba",
          "Short": "Sh"
        }
      },
      "DaysCount": "Kunlar soni",
      "EndAt": "Tugashi",
      "EndDate": "Tugash muddati",
      "Minutes": "Daqiqalar",
      "Months": {
        "0": {
          "Full": "Yanvar",
          "Short": "Yan"
        },
        "1": {
          "Full": "Fevral",
          "Short": "Fev"
        },
        "2": {
          "Full": "Mart",
          "Short": "Mart"
        },
        "3": {
          "Full": "Aprel",
          "Short": "Apr"
        },
        "4": {
          "Full": "May",
          "Short": "May"
        },
        "5": {
          "Full": "Iyun",
          "Short": "Iyun"
        },
        "6": {
          "Full": "Iyul",
          "Short": "Iyul"
        },
        "7": {
          "Full": "Avgust",
          "Short": "Avg"
        },
        "8": {
          "Full": "Sentabr",
          "Short": "Sen"
        },
        "9": {
          "Full": "Oktyabr",
          "Short": "Okt"
        },
        "10": {
          "Full": "Noyabr",
          "Short": "Noy"
        },
        "11": {
          "Full": "Dekabr",
          "Short": "Dek"
        }
      },
      "SelectDate": "Sanani tanlang",
      "SelectTime": "Vaqtni tanlang",
      "StartAt": "Boshlanishi",
      "StartDate": "Boshlanish sanasi",
      "StartingDate": "Boshlanish sanasi",
      "Time": "Vaqt",
      "Week": "Hafta"
    }
  }
}