import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import List from '@editorjs/list';
import ImageTool from '@editorjs/image';
import Checklist from '@editorjs/checklist';
import Quote from '@editorjs/quote';
import Marker from '@editorjs/marker';
import Delimiter from '@editorjs/delimiter';
import Embed from '@editorjs/embed';
import Table from '@editorjs/table';
import TextVariantTune from '@editorjs/text-variant-tune';
import Columns from '@calumk/editorjs-columns';

import { File as FileModel } from '@/models/File.model';
import { LOCALES } from '@/config/i18n.config';
import noop from 'lodash.noop';

export default {
  name: 'EditorCard',
  emits: ['ready', 'update:data', 'change'],
  props: {
    data: Object, // for @editorjs/editorjs
    readonly: Boolean,
    minHeight: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    locales() {
      if (this.$i18n.locale === LOCALES.EN.id) return {};
      return {
        messages: {
          ui: {
            blockTunes: {
              toggler: {
                'Click to tune': this.$t('Editor.ui.blockTunes.toggler.Click to tune'),
                'or drag to move': this.$t('Editor.ui.blockTunes.toggler.or drag to move'),
              },
            },
            inlineToolbar: {
              converter: {
                'Convert to': this.$t('Editor.ui.inlineToolbar.converter.Convert to'),
              },
            },
            toolbar: {
              toolbox: {
                Add: this.$t('Editor.ui.toolbar.toolbox.Add'),
              },
            },
          },
          toolNames: {
            'Text': this.$t('Editor.toolNames.Text'),
            'Heading': this.$t('Editor.toolNames.Heading'),
            'List': this.$t('Editor.toolNames.List'),
            'Warning': this.$t('Editor.toolNames.Warning'),
            'Checklist': this.$t('Editor.toolNames.Checklist'),
            'Quote': this.$t('Editor.toolNames.Quote'),
            'Code': this.$t('Editor.toolNames.Code'),
            'Delimiter': this.$t('Editor.toolNames.Delimiter'),
            'Raw HTML': this.$t('Editor.toolNames.Raw HTML'),
            'Table': this.$t('Editor.toolNames.Table'),
            'Link': this.$t('Editor.toolNames.Link'),
            'Marker': this.$t('Editor.toolNames.Marker'),
            'Bold': this.$t('Editor.toolNames.Bold'),
            'Image': this.$t('Editor.toolNames.Image'),
            'Italic': this.$t('Editor.toolNames.Italic'),
            'Inline Code': this.$t('Editor.toolNames.InlineCode'),
            'Columns': this.$t('Editor.toolNames.Columns'),
          },
          blockTunes: {
            delete: {
              Delete: this.$t('Editor.blockTunes.delete.Delete'),
            },
            moveUp: {
              'Move up': this.$t('Editor.blockTunes.moveUp.Move up'),
            },
            moveDown: {
              'Move down': this.$t('Editor.blockTunes.moveDown.Move down'),
            },
          },
        },
      };
    },
  },
  watch: {
    data: {
      // TODO: реализовать нормальное сравнение, MIS-519
      handler(value, oldValue) {
        if (!value || !oldValue) this.rerender();
      },
    },

    readonly: {
      handler() {
        this.editor.readOnly?.toggle();
      },
    },
  },

  methods: {
    rerender() {
      this.editor?.render && this.editor.render(this.data);
    },
  },

  async mounted() {
    const columnTools = {
      header: Header,
      delimiter: Delimiter,
    };

    const mainTools = {
      header: Header,
      checklist: Checklist,
      list: List,
      table: Table,
      delimiter: Delimiter,
      quote: Quote,
      marker: Marker,
      embed: Embed,
      textVariant: TextVariantTune,
      paragraph: {
        tunes: ['textVariant'],
      },
      image: {
        class: ImageTool,
        config: {
          uploader: {
            async uploadByFile(file) {
              const { data } = await FileModel.create(file);
              return {
                success: 1,
                file: {
                  ...data.data[0],
                  url: data.data[0].link,
                },
              };
            },
            uploadByUrl() {
              // not implemented
              noop();
            },
          },
        },
      },
      columns: {
        class: Columns,
        config: {
          tools: columnTools,
        },
      },
    };

    this.editor = new EditorJS({
      data: this.data || this.defaultData,
      holder: this.$refs.container.$el,
      readOnly: this.readonly,
      placeholder: 'Let`s write an awesome story!',
      minHeight: this.minHeight,

      tools: mainTools,
      i18n: this.locales,

      onReady: () => {
        this.loading = false;
        this.$emit('ready');
      },
      onChange: async (event) => {
        const blocks = await event.saver.save();
        this.$emit('update:data', blocks);
        setTimeout(() => this.$emit('change', blocks));
      },
    });
  },

  setup: () => ({
    editor: null,
    defaultData: {
      template_id: 1123,
      blocks: [],
    },
  }),
};
