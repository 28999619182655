<template>
  <UiTableWithPagination
    :data="data"
    :loading="loading"
    :page="page"
    :per-page="perPage"
    :total="total"
    :default-sort="defaultSort"
    @sort-change="onSortChange"
    @update:page="$emit('update:page', $event)"
    @update:perPage="$emit('update:perPage', $event)"
    @row-click="goToNurse">
    <ElTableColumn prop="name" :label="$t('Patronage.Nurse')"></ElTableColumn>
    <ElTableColumn
      prop="count"
      :label="$t('Analytics.NumberRounds')"
      sortable="custom"></ElTableColumn>
    <ElTableColumn
      prop="count_success"
      :label="$t('Analytics.Done')"
      sortable="custom"></ElTableColumn>
    <ElTableColumn
      prop="count_cancel"
      :label="$t('Analytics.Canceled')"
      sortable="custom"></ElTableColumn>
    <ElTableColumn
      prop="percent_success"
      :label="$t('Analytics.CompletedRounds')"
      width="250"
      sortable="custom">
      <template #default="{ row }">
        <div class="column">
          {{ row.percent_success ? row.percent_success : 0 }}%
          <div class="wrapper-bar">
            <div
              :style="{ width: `${row.percent_success ?? 0}%` }"
              :class="getBarClass(row.percent_success)"></div>
          </div>
        </div>
      </template>
    </ElTableColumn>
    <ElTableColumn :label="$t('Nomenclature.Actions')">
      <ElButton type="primary" text>
        <template #icon>
          <UiIcon :icon="icons.EYE" />
        </template>
        {{ $t('Base.View') }}
      </ElButton>
    </ElTableColumn>
  </UiTableWithPagination>
</template>

<script lang="ts">
export default {
  name: 'AnalyticsPatronageTable',
  inheritAttrs: false,
  customOptions: {},
};
</script>
<script setup lang="ts">
import { USER_ROUTE } from '@/router/users.routes';
import { useRouter } from 'vue-router';
import { PatronageNurseDto } from '@/types/api';

import UiTableWithPagination from '@/components/ui/UiTableWithPagination/index.vue';
import * as icons from '@/enums/icons.enum';

const emit = defineEmits(['update:perPage', 'update:page', 'sort-change']);

interface TableProps {
  data: Array<PatronageNurseDto>;
  loading: boolean;
  page: number;
  perPage: number;
  total: number;
  defaultSort: { prop: string; order: string };
}

defineProps<TableProps>();

const getBarClass = (percent: number) => {
  if (percent > 89) {
    return 'bar green';
  } else if (percent > 20) {
    return 'bar yellow';
  } else {
    return 'bar red';
  }
};

const onSortChange = ({ prop, order }: { prop: string; order: string }) => {
  emit('sort-change', { prop, order });
};

const router = useRouter();
const goToNurse = (row: PatronageNurseDto) => {
  router.push({
    name: USER_ROUTE.name,
    params: { id: row.id },
  });
};
</script>

<style lang="scss"></style>
<i18n src="@/locales/analytics.locales.json" />
<i18n src="@/locales/patronage.locales.json" />
<i18n src="@/locales/warehouse.locales.json" />
<i18n src="@/locales/base.locales.json" />
