<template>
  <LayoutByUserRole content-class="v-appointments" fix-height>
    <LayoutContentHeader>
      <ElDatePicker
        v-model="date"
        type="daterange"
        unlink-panels
        :format="DATE_FORMAT"
        :value-format="DATE_FORMAT"
        :start-placeholder="$t('DateAndTime.StartDate')"
        :end-placeholder="$t('DateAndTime.EndDate')" />

      <PatientsSearchSelect
        class="v-cash-content-header__user"
        :model-value="patient.value.id"
        :default-item="patientFromRoute"
        :placeholder="$t('Patients.SelectPatient')"
        clearable
        only-icon
        @select="patient.value = $event" />

      <!-- !user.doctor_id - типа не валидно теперь, из-за добавления user: useUser() -->
      <UiModelsAutocompleteSearch
        v-show="!user.doctor_id"
        :model-value="doctor.value.id"
        :model-for-use="Doctor"
        :default-item="doctor.value.id ? doctor.value : null"
        clearable
        :placeholder="$t('Appointments.SelectDoctor')"
        @select="doctor.value = $event" />

      <template #actions>
        <a :href="exportDataURL" download>
          <ElButton plain text>
            <template #icon>
              <UiIcon :icon="icons.DOWNLOAD" />
            </template>
            {{ $t('Base.Report') }}
          </ElButton>
        </a>

        <ElButton type="primary" @click="createAppointment">
          {{ $t('Appointments.CreateAppointment') }}
        </ElButton>
      </template>
    </LayoutContentHeader>

    <ElButtonGroup>
      <ElButton :type="!isClosed.value ? 'primary' : ''" @click="isClosed.value = 0">
        {{ $t('Appointments.ActiveAppointments') }}
      </ElButton>
      <ElButton :type="isClosed.value ? 'primary' : ''" @click="isClosed.value = 1">
        {{ $t('Appointments.ClosedAppointments') }}
      </ElButton>
    </ElButtonGroup>

    <AppointmentsTable
      :items="items"
      :loading="loading"
      v-model:page="page.value"
      v-model:per-page="perPage.value"
      :total="total"
      :exclude-columns="excludeColumns"
      @queue:print="queuePrint"
      @item:edit="editItem" />

    <QueuePrint ref="queuePrint" />
  </LayoutByUserRole>
</template>

<script>
import axios from 'axios';
import { mapState, mapActions } from 'vuex';
import { useQuery } from '@/hooks/useQuery.hook';
import { usePage, usePerPage, useUser } from '@/hooks/query';
import { useGetDataByInterval } from '@/hooks/useGetDataByInterval.hook';
import { compareQueriesThenLoadData } from '@/utils/router.utils';
import { getCurrentDate, ISOStringToDateAppFormat } from '@/utils/dateAndTime.utils';
import { Appointment } from '@/models/appointment/Appointment.model.js';
import { User } from '@/models/User.model';
import { Doctor } from '@/models/Doctor.model';
import { GlobalModalCloseAction } from '@/models/client/ModalAndDrawer/GlobalModalCloseAction';
import { DATE_FORMAT } from '@/config/dateAndTime.config';
import noop from 'lodash.noop';
import { mergeOrCreateQuery } from '@/utils/http.util';
import { deleteEmptyValueKeys } from '@/utils/object.util';
import { I18nService } from '@/services/i18n.service';
import { ApiService } from '@/services/api.service';
import { DeviceService } from '@/services/device.service';

import AppointmentsTable from '@/components/appointments/AppointmentsTable/index.vue';
import QueuePrint from '@/components/appointments/QueuePrint/index.vue';
import QueuePrintModal from '@/components/appointments/QueuePrintModal/index.vue';
import CreateOrEditAppointmentModal from '@/components/appointments/CreateOrEditAppointmentModal/index.vue';
import LayoutByUserRole from '@/components/layouts/LayoutByUserRole/index.vue';
import LayoutContentHeader from '@/components/layouts/assets/LayoutContentHeader/index.vue';
import PatientsSearchSelect from '@/components/patients/PatientsSearchSelect/index.vue';
import * as icons from '@/enums/icons.enum';

export default {
  name: 'VAppointments',
  components: {
    PatientsSearchSelect,
    LayoutContentHeader,
    LayoutByUserRole,
    AppointmentsTable,
    QueuePrint,
  },
  setup: () => ({
    getDataByInterval: noop,

    perPage: usePerPage(),
    page: usePage(),
    startAt: useQuery({
      field: 'start_at',
      defaultValue: ISOStringToDateAppFormat(new Date().toISOString()),
    }),
    endAt: useQuery({
      field: 'end_at',
      defaultValue: ISOStringToDateAppFormat(new Date().toISOString()),
    }),
    isClosed: useQuery({
      field: 'is_closed',
      defaultValue: false,
      valueIsBoolean: true,
    }),
    patient: useUser(),
    doctor: useUser({
      fieldNames: {
        id: 'doctor_id',
        name: 'doctor_name',
      },
    }),

    icons: icons,
    Doctor,
    DATE_FORMAT,
  }),

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      loading: (state) => state.appointments.loading,
      items: (state) => state.appointments.data,
      total: (state) => state.appointments.total,
    }),

    patientFromRoute() {
      return this.patient.value.id && this.patient.value.name ? { ...this.patient.value } : null;
    },

    queryWatchers() {
      return {
        per_page: this.perPage.value,
        page: this.page.value,
        start_at: this.startAt.value,
        end_at: this.endAt.value,
        query_field: 'doctor_id',
        user_id: this.patient.value.id,
        search: this.user?.doctor_id ?? this.doctor.value.id ?? null,
        statuses: this.statusesQuery,
      };
    },
    statusesQuery() {
      return this.isClosed.value
        ? [Appointment.enum.statuses.Provided, Appointment.enum.statuses.Canceled]
        : [
            Appointment.enum.statuses.Created,
            Appointment.enum.statuses.Approved,
            Appointment.enum.statuses.Waiting,
            Appointment.enum.statuses.InProgress,
          ];
    },

    date: {
      get() {
        return [this.startAt.value, this.endAt.value];
      },
      set(value) {
        this.startAt.value = value ? value[0] : null;
        setTimeout(() => (this.endAt.value = value ? value[1] : null));
      },
    },

    excludeColumns() {
      return this.user.role === User.enum.roles.Doctor
        ? ['doctor', 'end_at', 'patient.phone']
        : ['services'];
    },

    exportDataURL() {
      return mergeOrCreateQuery({
        url: Appointment.exportDataURL,
        query: deleteEmptyValueKeys({
          ...this.queryWatchers,
          lang: I18nService.getLocale(),
          token: ApiService.getToken(),
          device_id: DeviceService.getDeviceId(),
        }), // per_page, page будут игнорироваться на бэке
      });
    },

    isDoctor() {
      return this.user.role === User.enum.roles.Doctor;
    },
  },

  watch: {
    queryWatchers: {
      handler(value, oldValue) {
        compareQueriesThenLoadData({
          query: value,
          oldQuery: oldValue,
          resetPage: this.page.reset,
          getData: this.getDataByInterval,
          fieldsForResetPage: ['start_at', 'end_at', 'statuses'],
        });
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions({
      setLoading: 'appointments/setLoading',
      setData: 'appointments/setData',
      createItem: 'appointments/createItem',
      editItem: 'appointments/editItem',
    }),

    queuePrint(appointment) {
      this.$refs.queuePrint.print(appointment);
    },

    async getAppointments() {
      this.setLoading(true);

      try {
        const { data } = await Appointment.find(this.queryWatchers);
        this.setData({
          items: data.data,
          total: +data.meta.total,
          overwriteDataState: true,
        });
      } catch (err) {
        this.$notify({
          type: 'error',
          title: axios.isAxiosError(err) ? err.message : String(err),
        });
      }

      this.setLoading(false);
    },

    async createAppointment() {
      const action = await this.$store.dispatch(
        'modalAndDrawer/openModal',
        CreateOrEditAppointmentModal
      );

      if (action instanceof GlobalModalCloseAction) return;

      const currentDate = getCurrentDate();
      if (
        (!action.data.appointment.start_at ||
          action.data.appointment.start_at.split(' ')[0] === currentDate) &&
        (!this.isDoctor || action.data.appointment.doctor?.id === this.user?.doctor_id)
      ) {
        this.createItem(action.data.appointment);
      }

      if (action.data.appointment.type === Appointment.enum.types.Visit) return;

      await this.$store.dispatch('modalAndDrawer/openModal', {
        component: QueuePrintModal,
        payload: {
          appointment: action.data.appointment,
          callback: () => this.queuePrint(action.data.appointment),
        },
      });
    },
  },

  mounted() {
    this.getDataByInterval = useGetDataByInterval(this.getAppointments);
    this.getDataByInterval();
  },
};
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/notifications.locales.json" />
<i18n src="@/locales/patients.locales.json" />
<i18n src="@/locales/appointments.locales.json" />
<i18n src="@/locales/dateAndTime.locales.json" />
<i18n src="@/locales/base.locales.json" />
