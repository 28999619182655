<template>
  <UiTableWithPagination
    :data="props.items"
    :loading="props.loading"
    :page="props.page"
    :per-page="props.perPage"
    :total="props.total"
    @row-click="goToPatient"
    @update:page="$emit('update:page', $event)"
    @update:per-page="$emit('update:perPage', $event)">
    <ElTableColumn prop="group" :label="$t('Analytics.Group')">
      <template #default="{ row }">
        <span> {{ row.group ? $t(`Patients.Groups.${row.group}`) : $t('Base.No') }}</span>
      </template>
    </ElTableColumn>
    <ElTableColumn prop="name" :label="$t('Base.Patient')" />
    <ElTableColumn prop="first_day_menstruation" :label="$t('Patients.DNPMC')" />
    <ElTableColumn prop="planned_date_birth" :label="$t('Patients.DateOfPlanningBirth')" />
    <ElTableColumn prop="pregnancy_week" :label="$t('Patients.Time')">
      <template #default="{ row }">
        {{
          row.pregnancy_week
            ? `${row.pregnancy_week} ${getPregnancyWeekWithDeclension(row.pregnancy_week)}`
            : ''
        }}
      </template>
    </ElTableColumn>
    <ElTableColumn prop="pathology" :label="$t('Patients.Pathology')">
      <template #default="{ row }">
        {{ row.pathology ? row.pathology : $t('Base.No') }}
      </template>
    </ElTableColumn>
    <ElTableColumn prop="date_prev_pregnancy" :label="$t('Patients.DateLastBirth')" />
    <ElTableColumn prop="date_survey_date" :label="$t('Patients.ScheduledInspection')" />
    <ElTableColumn prop="actions" :label="$t('Base.Actions')" width="250">
      <ElButton type="primary" text>
        <template #icon>
          <UiIcon :icon="icons.EYE" />
        </template>
        {{ $t('Patronage.MedicalCard') }}
      </ElButton>
    </ElTableColumn>
  </UiTableWithPagination>
</template>
<script lang="ts">
export default {
  name: 'PregnantMicrodistrictTable',
  inheritAttrs: false,
  customOptions: {},
};
</script>
<script setup lang="ts">
import { AnalyticsPatientsDto } from '@/types/api';
import { PATIENT_ROUTE } from '@/router/patients.routes';
import { useRouter } from 'vue-router';
import debounce from 'lodash.debounce';
import { computed } from 'vue';
import { I18nService } from '@/services/i18n.service';
import { getDeclensionWord } from '@/utils/strings.util';

import UiTableWithPagination from '@/components/ui/UiTableWithPagination/index.vue';
import * as icons from '@/enums/icons.enum';

defineEmits(['update:page', 'update:perPage']);
const router = useRouter();

const props = defineProps<{
  loading: boolean;
  items: AnalyticsPatientsDto[];
  total: number;
  page: number;
  perPage: number;
}>();

type DeclensionWordByLangType = {
  ru: [string, string, string];
  uz: [string, string, string];
  en: [string, string, string];
};
const locale = computed<keyof DeclensionWordByLangType>(() => I18nService.getLocale());
const getPregnancyWeekWithDeclension = (value: number) => {
  const wordsDeclensionByLang: DeclensionWordByLangType = {
    ru: [
      I18nService.t('Patients.OneWeek'),
      I18nService.t('Patients.TwoWeek'),
      I18nService.t('Patients.FiveWeek'),
    ],
    uz: [
      I18nService.t('Patients.OneWeek'),
      I18nService.t('Patients.TwoWeek'),
      I18nService.t('Patients.FiveWeek'),
    ],
    en: [
      I18nService.t('Patients.Week'),
      I18nService.t('Patients.Weeks'),
      I18nService.t('Patients.Weeks'),
    ],
  };

  return getDeclensionWord(value, wordsDeclensionByLang[locale.value]);
};

const goToPatient = debounce((row: AnalyticsPatientsDto) => {
  window.open(
    router.resolve({
      name: PATIENT_ROUTE.name,
      params: {
        id: row.id,
      },
    }).href,
    '_blank'
  );
}, 300);
</script>

<style scoped lang="scss"></style>
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/user.locales.json" />
<i18n src="@/locales/patients.locales.json" />
<i18n src="@/locales/patronage.locales.json" />
