<template>
  <LayoutByUserRole content-class="v-vaccinations-microdistricts" fix-height>
    <ElBreadcrumb separator="/">
      <ElBreadcrumbItem
        :to="{
          path: `${VACCINATION_PATH_PREFIX}`,
          query: { date_period: date.value, age_from: ageFrom.value, age_to: ageTo.value },
        }"
        >{{ $t(`Analytics.Vaccination`) }}
      </ElBreadcrumbItem>
      <ElBreadcrumbItem>{{ vaccineTitle }} </ElBreadcrumbItem>
    </ElBreadcrumb>
    <div class="v-vaccinations-microdistricts__header">
      <ElDatePicker
        class="v-vaccinations-microdistricts__date"
        v-model="date.value"
        :format="DATE_FORMAT"
        type="daterange"
        unlink-panels
        :value-format="DATE_FORMAT"
        :placeholder="$t('DateAndTime.Date')" />

      <div class="v-vaccinations-microdistricts__age-data">
        <span>{{ $t('Analytics.AgeFrom') }}</span>
        <UiSelect
          :options="ageOptions"
          v-model="ageFrom.value"
          class="v-vaccinations-microdistricts__age" />
        <span>{{ $t('Analytics.To') }}</span>
        <UiSelect
          :options="ageOptions"
          v-model="ageTo.value"
          class="v-vaccinations-microdistricts__age" />
      </div>
      <UiSelect
        class="v-vaccinations-microdistricts__group-select"
        v-model="patientGroup.value"
        :placeholder="$t('Analytics.Group')"
        :options="patientGroupOptions" />

      <a class="v-vaccinations-microdistricts__btn" :href="exportDataURL" download>
        <ElButton type="primary">
          {{ $t('Base.DownloadExcel') }}
        </ElButton>
      </a>
    </div>
    <VaccinationsStatisticsBlock :query="statisticsQuery" />
    <VaccinationMicrodistrictsTable
      :loading="loading"
      :items="tableItems"
      :total="tableItemsTotal"
      :default-sort="defaultSort"
      @sort-change="onSortChange"
      v-model:page="page.value"
      v-model:per-page="perPage.value"
      @row-click="goToVaccinationGroups" />
  </LayoutByUserRole>
</template>

<script lang="ts">
export default {
  name: 'VVaccinationMicrodistricts',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { useDatePeriod, usePage, usePerPage } from '@/hooks/query';
import { I18nService } from '@/services/i18n.service';
import { DATE_FORMAT } from '@/config/dateAndTime.config';
import { useRouter, useRoute } from 'vue-router';
import { computed, ref, watch } from 'vue';
import { VaccinationMicrodistrictsDto } from '@/types/api';
import { getMonthPeriod } from '@/utils/dateAndTime.utils';
import { SortType } from '@/types/common';
import { useQuery } from '@/hooks/useQuery.hook';
import { Analytics } from '@/models/analytics/analytics.model';
import { compareQueriesThenLoadData } from '@/utils/router.utils';
import { VACCINATION_GROUPS_ROUTE, VACCINATION_PATH_PREFIX } from '@/router/vaccination.routes';
import { Patient } from '@/models/Patient.model';
import { mergeOrCreateQuery } from '@/utils/http.util';
import { deleteEmptyValueKeys } from '@/utils/object.util';
import { TokenService } from '@/services/token.service';
import { DeviceService } from '@/services/device.service';

import LayoutByUserRole from '@/components/layouts/LayoutByUserRole/index.vue';
import VaccinationMicrodistrictsTable from '@/components/analytics/vaccinations/tables/VaccinationMicrodistrictsTable/index.vue';
import VaccinationsStatisticsBlock from '@/components/analytics/vaccinations/VaccinationsStatisticsBlock/index.vue';

const router = useRouter();
const route = useRoute();
const vaccineId = route.params.vaccine_id as string;
const vaccineTitle = route.params.vaccine_title as string;

const page = usePage();
const perPage = usePerPage();
const loading = ref(false);
const tableItems = ref<VaccinationMicrodistrictsDto[]>([]);
const tableItemsTotal = ref(0);

const patientGroup = useQuery({ field: 'group', defaultValue: '' });
const date = useDatePeriod(getMonthPeriod());
const defaultSort = ref<null | SortType>(null);
const ageFrom = useQuery({ field: 'age_from', defaultValue: 0 });
const ageTo = useQuery({ field: 'age_to', defaultValue: 120 });

const getConvertOrder = () => {
  if (!!defaultSort.value?.order) {
    return defaultSort.value.order === 'ascending' ? 'ASC' : 'DESC';
  } else {
    return null;
  }
};

const ageOptions = Array.from({ length: 120 }, (_, i) => {
  return {
    label: i.toString(),
    value: i,
  };
});

const query = computed(() => ({
  vaccine_id: vaccineId,
  group: patientGroup.value,
  page: page.value,
  per_page: perPage.value,
  start_at: date.value[0],
  end_at: date.value[1],
  age_from: ageFrom.value,
  age_to: ageTo.value,
  count_vaccines: defaultSort.value?.prop === 'count_vaccines' ? getConvertOrder() : null,
  count_vaccines_man: defaultSort.value?.prop === 'count_vaccines_man' ? getConvertOrder() : null,
  count_vaccines_woman:
    defaultSort.value?.prop === 'count_vaccines_woman' ? getConvertOrder() : null,
}));

const statisticsQuery = computed(() => ({
  vaccine_id: vaccineId,
  start_at: date.value[0],
  end_at: date.value[1],
  age_from: ageFrom.value,
  age_to: ageTo.value,
  group: patientGroup.value,
}));

const onSortChange = ({ prop, order }: SortType) => {
  defaultSort.value = { prop: prop, order: order };
};

const getAnalyticVMicrodistricts = async () => {
  loading.value = true;
  const response = await Analytics.getAnalyticsMicrodistricts<[]>(query.value);

  tableItems.value = response?.data ?? [];
  tableItemsTotal.value = response?.meta.total ?? 0;

  loading.value = false;
};

const patientGroupOptions = [
  { value: Patient.enum.groups.FirstGroup, label: I18nService.t('Patients.Groups.first_group') },
  { value: Patient.enum.groups.SecondGroup, label: I18nService.t('Patients.Groups.second_group') },
  { value: Patient.enum.groups.ThirdGroup, label: I18nService.t('Patients.Groups.third_group') },
  { value: Patient.enum.groups.FourthGroup, label: I18nService.t('Patients.Groups.fourth_group') },
];

const goToVaccinationGroups = (row: VaccinationMicrodistrictsDto) => {
  router.push({
    name: VACCINATION_GROUPS_ROUTE.name,
    query: {
      date_period: date.value,
      age_from: ageFrom.value,
      age_to: ageTo.value,
      group: patientGroup.value,
    },
    params: {
      vaccine_id: vaccineId,
      microdistrict_id: row.id,
      vaccine_title: vaccineTitle,
    },
  });
};

watch(
  query,
  (value, oldValue) => {
    compareQueriesThenLoadData({
      query: value,
      oldQuery: oldValue,
      getData: getAnalyticVMicrodistricts,
      resetPage: page.reset,
      fieldsForResetPage: [
        'start_at',
        'end_at',
        'age_from',
        'age_to',
        'group',
        'count_vaccines',
        'count_vaccines_man',
        'count_vaccines_woman',
      ],
    });
  },
  { deep: true, immediate: true }
);

const exportDataURL = computed(() => {
  return mergeOrCreateQuery({
    url: Analytics.exportVaccinesMicrodistrictsDataURL,
    query: deleteEmptyValueKeys({
      ...query.value,
      lang: I18nService.getLocale(),
      token: TokenService.getAccessToken(),
      device_id: DeviceService.getDeviceId(),
    }),
  });
});
</script>

<style lang="scss" src="./index.scss" />
