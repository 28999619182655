<template>
  <ElDialog
    class="service-outcome-modal"
    :model-value="modelValue"
    width="586px"
    :title="$t('Outcome.Add')"
    v-bind="$attrs"
    @update:model-value="$emit('update:modelValue', $event)">
    <div v-if="loading.getData" v-loading="true" />
    <VeeForm v-else as="ElForm">
      <OutcomeForm
        v-if="!!formData.values"
        :form-data="formData.values"
        @close:modal="$emit('update:modelValue')"
        @action="$emit('action', $event)" />
    </VeeForm>
  </ElDialog>
</template>

<script lang="ts">
export default {
  name: 'ServiceOutcomeModal',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { reactive, watch } from 'vue';
import { Form as VeeForm } from 'vee-validate';
import { Expense, ExpenseFormData } from '@/models/accounting/Expense.model';

import OutcomeForm from '@/components/accounting/modals/ServiceOutcomeModal/OutcomeForm/Index.vue';

const props = withDefaults(defineProps<{ modelValue: boolean; id?: number | null }>(), {
  modelValue: false,
  id: null,
});

defineEmits(['update:modelValue', 'action']);

const formData = reactive<{ values: null | ExpenseFormData }>({
  values: null,
});

const loading = reactive({
  getData: false,
});

const getOutcomeById = async (id: number) => {
  loading.getData = true;

  const response = await Expense.getItemById(id);
  formData.values = { ...new Expense(response?.data) };
};

watch(
  () => props.id,
  (value) => {
    if (value) {
      getOutcomeById(value);
    } else {
      formData.values = { ...new Expense() };
    }
  },
  { immediate: true }
);
</script>

<i18n src="@/locales/accounting.locales.json" />
