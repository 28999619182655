import { Module, ActionContext, Store as VuexStore, CommitOptions, DispatchOptions } from 'vuex';
import { RootStateType } from '@/store';
import { AnyObject } from '@/types/common';

type StoreCRUDModulePayloadType<T> = {
  name: string;
  namespaced?: boolean;
  state?: Module<T, RootStateType>['state'];
  getters?: Module<T, RootStateType>['getters'];
  mutations?: Module<T, RootStateType>['mutations'];
  actions?: Module<T, RootStateType>['actions'];
};

export type StoreCRUDModuleState = {
  data: AnyObject[];
  loading: boolean;
  total: number;
};

type Mutations = {
  SET_TOTAL(state: StoreCRUDModuleState, count: number): void;
  SET_DATA(state: StoreCRUDModuleState, items: []): void;
  SET_LOADING(state: StoreCRUDModuleState, value: boolean): void;
  ADD_ITEM(state: StoreCRUDModuleState, item: AnyObject): void;
  EDIT_ITEM(state: StoreCRUDModuleState, item: AnyObject): void;
  SET_ITEM_LOADING(
    state: StoreCRUDModuleState,
    { id, loading }: { id: number; loading: boolean }
  ): void;
  DELETE_ITEM(state: StoreCRUDModuleState, item: AnyObject): void;
};

type ActionAugments = Omit<ActionContext<StoreCRUDModuleState, RootStateType>, 'commit'> & {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
};

export type Actions = {
  setData(context: ActionAugments, payload: AnyObject): void;
  createItem(context: ActionAugments, item: AnyObject): void;
  editItem(context: ActionAugments, item: AnyObject): void;
  deleteItem(context: ActionAugments, item: AnyObject): void;
  clearAllData(context: ActionAugments): void;
  setLoading(context: ActionAugments, value: boolean): void;
  setItemLoading(context: ActionAugments, payload: { id: number; loading: boolean }): void;
};

export class StoreCRUDModule<T> {
  name: string;
  isCRUDModule: boolean;
  namespaced: boolean;
  state: () => StoreCRUDModuleState;
  mutations: Module<StoreCRUDModuleState, RootStateType>['mutations'] & Mutations;
  actions: Module<StoreCRUDModuleState, RootStateType>['actions'] & Actions;
  constructor({ name, state: payloadState, mutations, actions }: StoreCRUDModulePayloadType<T>) {
    this.name = name;
    this.isCRUDModule = true;
    this.namespaced = true;
    this.state = () => {
      return {
        data: [],
        loading: false,
        total: 0,

        ...payloadState,
      };
    };
    this.mutations = {
      SET_TOTAL(state, count: number) {
        state.total = count;
      },
      SET_DATA(state, items = []) {
        // убираем дупликаты
        state.data = items.filter(
          (item: AnyObject, index: number) =>
            !items
              .slice(index + 1)
              .find((elem: AnyObject) => elem.id && item.id && elem.id == item.id)
        );
      },
      SET_LOADING(state, value) {
        state.loading = value;
      },
      ADD_ITEM(state, item) {
        state.data = [item, ...state.data];
        state.total = state.total + 1;
      },
      EDIT_ITEM(state, item) {
        const index = state.data.findIndex((elem) => elem.id == item.id);
        state.data = [...state.data.slice(0, index), item, ...state.data.slice(index + 1)];
      },
      SET_ITEM_LOADING(state, { id, loading }) {
        const index = state.data.findIndex((elem) => elem.id == id);
        if (index >= 0) {
          const item = { ...state.data[index] };
          if (loading) {
            item.loading = loading;
          } else {
            delete item.loading;
          }
          state.data = [...state.data.slice(0, index), { ...item }, ...state.data.slice(index + 1)];
        }
      },
      DELETE_ITEM(state, item) {
        const index = state.data.findIndex((elem) => elem.id == item.id);

        state.data = [...state.data.slice(0, index), ...state.data.slice(index + 1)];
        state.total = state.total - 1;
      },
      ...mutations,
    };
    this.actions = {
      setData({ state, commit }, payload) {
        commit(
          'SET_DATA',
          payload.overwriteDataState ?? true ? payload.items : [...state.data, ...payload.items]
        );
        commit('SET_TOTAL', payload.total ?? payload.items.length);
      },
      async createItem({ commit }, item) {
        commit('ADD_ITEM', { ...item });
        return { ...item };
      },
      async editItem({ commit }, item) {
        commit('EDIT_ITEM', item);
      },
      async deleteItem({ commit }, item) {
        commit('DELETE_ITEM', item);
      },
      clearAllData({ commit }) {
        commit('SET_DATA', []);
        commit('SET_TOTAL', 0);
        commit('SET_LOADING', false);
      },

      setLoading({ commit }, value) {
        commit('SET_LOADING', value);
      },
      setItemLoading({ commit }, payload) {
        commit('SET_ITEM_LOADING', payload);
      },
      ...actions,
    };
  }
}

export type CRUDModuleStore<S = StoreCRUDModuleState> = Omit<
  VuexStore<S>,
  'getters' | 'commit' | 'dispatch'
> & {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
    key: K,
    payload: P,
    options?: CommitOptions
  ): ReturnType<Mutations[K]>;
} & {
  dispatch<K extends keyof Actions>(
    key: K,
    payload?: Parameters<Actions[K]>[1],
    options?: DispatchOptions
  ): ReturnType<Actions[K]>;
};
