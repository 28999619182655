<template>
  <UiTableWithPagination
    :data="props.items"
    :loading="props.loading"
    :page="props.page"
    :per-page="props.perPage"
    :total="props.total"
    @update:page="$emit('update:page', $event)"
    @update:per-page="$emit('update:perPage', $event)"
    @row-click="goToPatient">
    <ElTableColumn prop="name" :label="$t('Patients.Patient')" />
    <ElTableColumn prop="birthdate" :label="$t('User.Birthdate')" />
    <ElTableColumn prop="registration_date" :label="$t('Patients.DateOfRegistration')" />
    <ElTableColumn prop="doctor" :label="$t('Patients.AttendingDoctor')">
      <template #default="{ row }">
        {{ row.doctor ? row.doctor.name : '' }}
      </template>
    </ElTableColumn>
    <ElTableColumn prop="date_survey_date" :label="$t('Patients.DateNextInspection')" />
    <ElTableColumn prop="actions" :label="$t('Base.Actions')" width="200px">
      <ElButton type="primary" text>
        <template #icon>
          <UiIcon :icon="icons.EYE" />
        </template>
        {{ $t('Base.Open') }}
      </ElButton>
    </ElTableColumn>
  </UiTableWithPagination>
</template>

<script lang="ts">
export default {
  name: 'GroupChangeTable',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import * as icons from '@/enums/icons.enum.js';
import { PATIENT_ROUTE } from '@/router/patients.routes';
import { useRouter } from 'vue-router';
import debounce from 'lodash.debounce';
import { GroupChangeDto } from '@/types/api';

import UiTableWithPagination from '@/components/ui/UiTableWithPagination/index.vue';

defineEmits(['update:page', 'update:perPage']);

const props = defineProps<{
  loading: boolean;
  items: GroupChangeDto[];
  total: number;
  page: number;
  perPage: number;
}>();

const router = useRouter();

const goToPatient = debounce((row: GroupChangeDto) => {
  window.open(
    router.resolve({
      name: PATIENT_ROUTE.name,
      params: {
        id: row.id,
      },
    }).href,
    '_blank'
  );
}, 300);
</script>
