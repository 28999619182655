<template>
  <LayoutByUserRole content-class="v-hospital">
    <LayoutContentHeader class="v-hospital-header">
      <ElDatePicker
        class="v-hospital-header__date-picker"
        v-model="period.value"
        type="daterange"
        unlink-panels
        :format="DATE_FORMAT"
        :value-format="DATE_FORMAT"
        :start-placeholder="$t('DateAndTime.StartDate')"
        :end-placeholder="$t('DateAndTime.EndDate')" />

      <UiModelsAutocompleteSearch
        class="v-hospital-header__department"
        :model-value="department.value.id"
        :model-for-use="Department"
        label="title"
        :default-item="department.value.id ? department.value : null"
        :placeholder="$t('Hospital.Department')"
        clearable
        @select="department.value = $event" />

      <SelectHospitalStatus class="v-hospital-header__status" clearable v-model="status.value" />

      <PatientsSearchSelect
        class="v-hospital-header__patient"
        v-model="patient.value.id"
        :default-item="patient.value.id ? patient.value : null"
        clearable
        @select="patient.value = $event" />

      <template #actions>
        <a :href="exportDataURL" download>
          <ElButton plain text>
            <template #icon>
              <UiIcon :icon="icons.DOWNLOAD" />
            </template>
            {{ $t('Base.Report') }}
          </ElButton>
        </a>
        <ElButton type="primary" @click="create">{{ $t('Base.Create') }}</ElButton>
      </template>
    </LayoutContentHeader>

    <div class="v-hospital__statistic">
      <UiSimpleStatisticCard
        v-loading="statistic.loading"
        :label="statisticCard.label"
        :value="statisticCard.value"
        :icon="statisticCard.icon" />
    </div>

    <HospitalsTable
      :data="items"
      :total="total"
      :loading="loading"
      v-model:page="page.value"
      v-model:per-page="perPage.value" />
  </LayoutByUserRole>
</template>

<script src="./index.js"></script>
<style lang="scss" src="./index.scss"></style>
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/dateAndTime.locales.json" />
<i18n src="@/locales/hospital.locales.json" />
