/**
 * @class TreatmentDrug
 */
export class TreatmentDrug {
  /**
   * @param {object} [payload]
   * @param {string|number} payload.uuid
   * @param {string} payload.title
   * @param {number} payload.price
   * @param {number} payload.count
   * @param {number} payload.count_drink
   * @param {boolean} payload.is_patient_product
   * @param {boolean} payload.is_deleted
   * @param {number} payload.id
   */
  constructor(payload) {
    this._id = Math.random(); // only for client
    this.uuid = payload?.uuid ?? null;
    this.title = payload?.title ?? null;
    this.price = payload?.price ?? null;
    this.count = payload?.count ?? 1;
    this.count_drink = payload?.count_drink ?? 0;
    this.is_patient_product = payload?.is_patient_product ?? false;
    this.is_deleted = payload?.is_deleted ?? false;
    this.id = payload?.id ?? null;
  }
}
