<template>
  <div class="disabled-people-statistics-block">
    <div
      class="disabled-people-statistics-block__container disabled-people-statistics-block__container-3">
      <StatisticsCard
        v-for="item in statisticsCardsData.slice(0, 3)"
        :key="item.title"
        :title="item.title"
        :count="item.count"
        :total-count="item.totalCount"
        :subtitle="`${$t('Statistic.TotalPatients')} ${item.totalCount}`"
        :colors="item.colors"
        :labels="item.labels"
        :loading="item.loading" />
    </div>
    <div
      class="disabled-people-statistics-block__container disabled-people-statistics-block__container-4">
      <StatisticsCard
        v-for="item in statisticsCardsData.slice(3)"
        :key="item.title"
        :title="item.title"
        :count="item.count"
        :total-count="item.totalCount"
        :subtitle="`${$t('Statistic.TotalPatients')} ${item.totalCount}`"
        :colors="item.colors"
        :labels="item.labels"
        :loading="item.loading"
        @click-card="handleClickCard" />
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: 'DisabledPeopleStatisticsBlock',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { useRouter } from 'vue-router';
import { Statistics } from '@/models/Statistics.model';
import { I18nService } from '@/services/i18n.service';
import { computed, ref, watch } from 'vue';
import { CardInfoType } from '@/components/common/StatisticsCard/types';
import { DISABLED_PATIENTS_TRANSFERRED_ROUTE } from '@/router/disabledРeople.routes';

import StatisticsCard from '@/components/common/StatisticsCard/index.vue';

const initialData = { total_count: 0, count: 0 };
const router = useRouter();

interface Props {
  query: {
    start_at: string;
    end_at: string;
    age_from?: number | null;
    age_to?: number | null;
    microdistrict_id?: number | null;
  };
}

const props = defineProps<Props>();

const statisticsData = ref({
  firstGroup: {
    data: initialData,
    loading: false,
  },
  secondGroup: {
    data: initialData,
    loading: false,
  },
  thirdGroup: {
    data: initialData,
    loading: false,
  },
  disabilityFromItoII: {
    data: initialData,
    loading: false,
  },
  disabilityFromIItoI: {
    data: initialData,
    loading: false,
  },
  disabilityFromIItoIII: {
    data: initialData,
    loading: false,
  },
  disabilityFromIIItoII: {
    data: initialData,
    loading: false,
  },
});

const statisticsCardsData = computed(() => [
  {
    title: I18nService.t('Analytics.People1stDegreeDisability'),
    count: statisticsData.value.firstGroup.data.count,
    totalCount: statisticsData.value.firstGroup.data.total_count,
    loading: statisticsData.value.firstGroup.loading,
    colors: ['#3879F8', '#C8DBFF'],
    labels: [I18nService.t('Patients.Degrees.first_group'), I18nService.t('Statistic.Remaining')],
  },
  {
    title: I18nService.t('Analytics.People2ndDegreeDisability'),
    count: statisticsData.value.secondGroup.data.count,
    totalCount: statisticsData.value.secondGroup.data.total_count,
    loading: statisticsData.value.secondGroup.loading,
    colors: ['#3879F8', '#C8DBFF'],
    labels: [I18nService.t('Patients.Degrees.second_group'), I18nService.t('Statistic.Remaining')],
  },
  {
    title: I18nService.t('Analytics.People3rdDegreeDisability'),
    count: statisticsData.value.thirdGroup.data.count,
    totalCount: statisticsData.value.thirdGroup.data.total_count,
    loading: statisticsData.value.thirdGroup.loading,
    colors: ['#3879F8', '#C8DBFF'],
    labels: [I18nService.t('Patients.Degrees.third_group'), I18nService.t('Statistic.Remaining')],
  },
  {
    title: I18nService.t('Statistic.DisabledFromGroupIToGroupII'),
    count: statisticsData.value.disabilityFromItoII.data.count,
    totalCount: statisticsData.value.disabilityFromItoII.data.total_count,
    loading: statisticsData.value.disabilityFromItoII.loading,
    colors: ['#3879F8', '#C8DBFF'],
    labels: [
      I18nService.t('Statistic.TransferredFromDegreeII'),
      I18nService.t('Statistic.TotalPatients'),
    ],
  },
  {
    title: I18nService.t('Statistic.DisabledFromGroupIIToGroupI'),
    count: statisticsData.value.disabilityFromIItoI.data.count,
    totalCount: statisticsData.value.disabilityFromIItoI.data.total_count,
    loading: statisticsData.value.disabilityFromIItoI.loading,
    colors: ['#3879F8', '#C8DBFF'],
    labels: [
      I18nService.t('Statistic.TransferredFromDegreeI'),
      I18nService.t('Statistic.TotalPatients'),
    ],
  },
  {
    title: I18nService.t('Statistic.DisabledFromGroupIIToGroupIII'),
    count: statisticsData.value.disabilityFromIItoIII.data.count,
    totalCount: statisticsData.value.disabilityFromIItoIII.data.total_count,
    loading: statisticsData.value.disabilityFromIItoIII.loading,
    colors: ['#3879F8', '#C8DBFF'],
    labels: [
      I18nService.t('Statistic.TransferredFromDegreeIII'),
      I18nService.t('Statistic.TotalPatients'),
    ],
  },
  {
    title: I18nService.t('Statistic.DisabledFromGroupIIIToGroupII'),
    count: statisticsData.value.disabilityFromIIItoII.data.count,
    totalCount: statisticsData.value.disabilityFromIIItoII.data.total_count,
    loading: statisticsData.value.disabilityFromIIItoII.loading,
    colors: ['#3879F8', '#C8DBFF'],
    labels: [
      I18nService.t('Statistic.TransferredFromDegreeII'),
      I18nService.t('Statistic.TotalPatients'),
    ],
  },
]);

const getTransferredGroup = (title: string) => {
  switch (title) {
    case I18nService.t('Statistic.DisabledFromGroupIToGroupII'):
      return {
        old_group_disabilities: 'first_group',
        new_group_disabilities: 'second_group',
      };
    case I18nService.t('Statistic.DisabledFromGroupIIToGroupI'):
      return {
        old_group_disabilities: 'second_group',
        new_group_disabilities: 'first_group',
      };
    case I18nService.t('Statistic.DisabledFromGroupIIToGroupIII'):
      return {
        old_group_disabilities: 'second_group',
        new_group_disabilities: 'third_group',
      };
    case I18nService.t('Statistic.DisabledFromGroupIIIToGroupII'):
      return {
        old_group_disabilities: 'third_group',
        new_group_disabilities: 'second_group',
      };
    default:
      return {
        old_group_disabilities: 'first_group',
        new_group_disabilities: 'second_group',
      };
  }
};

const handleClickCard = (data: CardInfoType) => {
  router.push({
    name: DISABLED_PATIENTS_TRANSFERRED_ROUTE.name,
    query: {
      ...props.query,
      ...getTransferredGroup(data.title),
    },
  });
};

const getDispensaryByFirstGroup = async (query: Props['query']) => {
  statisticsData.value.firstGroup.loading = true;
  const response = await Statistics.getDisabilitiesByGroup('first', query);
  statisticsData.value.firstGroup.data = response?.data ?? initialData;
  statisticsData.value.firstGroup.loading = false;
};

const getDispensaryBySecondGroup = async (query: Props['query']) => {
  statisticsData.value.secondGroup.loading = true;
  const response = await Statistics.getDisabilitiesByGroup('second', query);
  statisticsData.value.secondGroup.data = response?.data ?? initialData;
  statisticsData.value.secondGroup.loading = false;
};

const getDispensaryByThirdGroup = async (query: Props['query']) => {
  statisticsData.value.thirdGroup.loading = true;
  const response = await Statistics.getDisabilitiesByGroup('third', query);
  statisticsData.value.thirdGroup.data = response?.data ?? initialData;
  statisticsData.value.thirdGroup.loading = false;
};

const getDisabilityFromItoII = async (query: Props['query']) => {
  statisticsData.value.disabilityFromItoII.loading = true;
  const response = await Statistics.getDisabilitiesChange({
    ...query,
    old_group: 'first_group',
    new_group: 'second_group',
  });
  statisticsData.value.disabilityFromItoII.data = response?.data ?? initialData;
  statisticsData.value.disabilityFromItoII.loading = false;
};

const getDisabilityFromIItoI = async (query: Props['query']) => {
  statisticsData.value.disabilityFromIItoI.loading = true;
  const response = await Statistics.getDisabilitiesChange({
    ...query,
    old_group: 'second_group',
    new_group: 'first_group',
  });
  statisticsData.value.disabilityFromIItoI.data = response?.data ?? initialData;
  statisticsData.value.disabilityFromIItoI.loading = false;
};

const getDisabilityFromIItoIII = async (query: Props['query']) => {
  statisticsData.value.disabilityFromIItoIII.loading = true;
  const response = await Statistics.getDisabilitiesChange({
    ...query,
    old_group: 'second_group',
    new_group: 'third_group',
  });
  statisticsData.value.disabilityFromIItoIII.data = response?.data ?? initialData;
  statisticsData.value.disabilityFromIItoIII.loading = false;
};

const getDisabilityFromIIItoII = async (query: Props['query']) => {
  statisticsData.value.disabilityFromIIItoII.loading = true;
  const response = await Statistics.getDisabilitiesChange({
    ...query,
    old_group: 'third_group',
    new_group: 'second_group',
  });
  statisticsData.value.disabilityFromIIItoII.data = response?.data ?? initialData;
  statisticsData.value.disabilityFromIIItoII.loading = false;
};

watch(
  () => props.query,
  async (value) => {
    getDispensaryByFirstGroup(value);
    getDispensaryBySecondGroup(value);
    getDispensaryByThirdGroup(value);
    getDisabilityFromItoII(value);
    getDisabilityFromIItoI(value);
    getDisabilityFromIItoIII(value);
    getDisabilityFromIIItoII(value);
  },
  { deep: true, immediate: true }
);
</script>
<style lang="scss" src="./index.scss" />
