﻿{
  "en": {
    "Base": {
      "Absinthe": "Missing",
      "Actions": "Actions",
      "Add": "Add",
      "Allergy": "Allergy",
      "AmbulatoryCard": "Outpatient card",
      "Amount": "sum",
      "Assign": "Assign",
      "Attach": "Bind",
      "AuthSubtitle": "Please log in to your personal account and register patients for further appointments",
      "Authorization": "Authorization",
      "Back": "Back",
      "BloodTransfusion": "Blood transfusion",
      "Cabinet": "Cabinet",
      "CalendarAppointments": "Calendar Receptions",
      "Call": "Call",
      "CallToReception": "Call for an appointment",
      "Cancel": "Cancel",
      "CancelConfirmation": "Cancel confirmation",
      "Capacity": "Capacity",
      "CashRegister": "Cash Register",
      "Category": "Category",
      "ChangeLang": "Change the language",
      "Choose": "Choose",
      "Class": "Class",
      "Clinic": "Clinic",
      "ClinicalExamination": "Medical examination",
      "Close": "Close",
      "Code": "Code",
      "ColDays": "Number of days",
      "Confirm": "Confirm",
      "Cost": "Cost",
      "Create": "Create",
      "CreateNew": "Create new",
      "Creator": "Creator",
      "Dashboard": "Dashboard",
      "DateAppoint": "Appointment date",
      "Days": "Вays",
      "Delete": "Delete",
      "Description": "Description",
      "Diabetes": "Diabetes mellitus",
      "Discount": "Discount",
      "Doctor": "Doctor",
      "DoctorFullName": "Doctor's full name",
      "DoctorFullName2": "Doctor's full name",
      "Doctors": "Doctors",
      "DoctorsSchedule": "Doctors' schedule",
      "Documents": "Documents",
      "Download": "Download",
      "DownloadApp": "Download the app",
      "DownloadExcel": "Download Excel file",
      "DrugFromWarehouse": "Drug from warehouse",
      "Duration": "Duration",
      "Edit": "Edit",
      "EndTreatment": "Finish treatment",
      "Exit": "Exit",
      "Fluorography": "Fluorography",
      "ForgotPassword": "Forgot password",
      "FromWarehouse": "From warehouse",
      "Full name": "FULL NAME",
      "Income": "Income",
      "Infectious diseases": "Infectious diseases",
      "Info": "Information",
      "Information": "Information",
      "Inspection": "Inspection",
      "InspectionCard": "Inspection card",
      "InvalidBarCode": "Invalid barcode",
      "InvalidQrCode": "Invalid QR code",
      "InviteByNumber": "Invite by number",
      "Invoice": "Invoice",
      "Invoices": "Invoices",
      "Loading": "Loading",
      "Location": "Location",
      "Login": "Login",
      "LoginСonfirmed": "I have a verification code",
      "Logout": "Logout",
      "MakeAppointment": "Make an appointment",
      "Measure": "Unit of measurement",
      "Naming": "Name",
      "NamingInEn": "Name in English",
      "NamingInRu": "Name in Russian",
      "NamingInUz": "Name in Uzbek",
      "NamingShort": "Abbreviated. name",
      "No": "No",
      "NoData": "No data",
      "Notes": "Notes",
      "Open": "Open",
      "OpenCard": "Open",
      "Orders": "Requests",
      "Paid": "Pay",
      "Password": "Password",
      "Patient": "Patient",
      "PatientDocument": "Patient documents",
      "PatientInfo": "Patient Information",
      "PatientRecord": "Patient record",
      "Patients": "Patients",
      "PatientsRecords": "Patient records",
      "Pay": "Pay",
      "Payment": "Payment",
      "Percent": "Percent",
      "PhoneNumber": "Phone number",
      "PleaseInput": "Please enter",
      "PleaseSelect": "Please choose",
      "Price": "Price",
      "Print": "Print",
      "PrintTicket": "Print ticket",
      "Quantity": "Quantity",
      "QuantityDays": "Number of days",
      "Queue": "Queue",
      "QueueForToday": "Queue for today",
      "Reaction": "Reaction",
      "ReasonService": "Service case",
      "Refund": "Refund",
      "Register": "Register",
      "Report": "Report",
      "Save": "Save",
      "SaveChanges": "Save Changes",
      "SaveChanges Method": "Save Changes",
      "Scan": "Scan ...",
      "ScanBarCode": "Scan barcode",
      "ScanBracelet": "Scan",
      "Scanning": "Scanning ...",
      "Search": "Search",
      "SelectCategory": "Select a category",
      "SelectDate": "Select a date",
      "SelectReport": "Select report",
      "SelectServiceСategory": "Select service category",
      "SelectStatus": "Select status",
      "Service": "Service",
      "Services": "Services",
      "SetTreatment": "Prescribe treatment",
      "Settings": "Settings",
      "Skip": "Skip",
      "StatePrice": "Cost",
      "Statistics": "Statistics",
      "Status": "Status",
      "Sum": "Uzbek sum",
      "SumCost": "Sum",
      "SurgicalIntervention": "Surgical interventions",
      "TableTreatment": "Treatment diaries",
      "Template": "Template",
      "Templates": "Templates",
      "Time": "Time",
      "Total": "Total",
      "TreatmentJournal": "Treatment Diary",
      "Type": "Type",
      "Upload": "Upload",
      "Used": "Used",
      "View": "View",
      "WhereFrom": "Where from",
      "Workload": "Workload",
      "WriteText": "Write the text..."
    }
  },
  "ru": {
    "Base": {
      "Absent": "Отсутствует",
      "Actions": "Действия",
      "Add": "Добавить",
      "Allergy": "Аллергия",
      "AmbulatoryCard": "Амбулаторная карта",
      "Appoint": "Назначить",
      "Appointment": "Запись",
      "Attach": "Привязать",
      "AuthSubtitle": "Войдите в личный кабинет, для записи пациентов на прием",
      "Authorization": "Авторизация",
      "Back": "Назад",
      "BloodTransfusion": "Переливание крови",
      "Cabinet": "Кабинет",
      "CalendarAppointments": "Календарь приемов",
      "Call": "Позвонить",
      "CallToReception": "Вызвать на прием",
      "Cancel": "Отменить",
      "CancelConfirmation": "Отменить подтверждение",
      "Capacity": "Вместимость",
      "CashRegister": "Касса",
      "Category": "Категория",
      "ChangeLang": "Сменить язык",
      "Choose": "Выбрать",
      "Class": "Класс",
      "Clinic": "Клиника",
      "ClinicalExamination": "Диспансеризация",
      "Close": "Закрыть",
      "Code": "Код",
      "ColDays": "Кол. дней",
      "Confirm": "Подтвердить",
      "Cost": "Стоимость",
      "Create": "Создать",
      "CreateNew": "Создать новый",
      "Creator": "Создатель",
      "Custom": "Пользовательский",
      "Dashboard": "Дашборд",
      "DateAppoint": "Дата назначение",
      "Days": "Дней",
      "Default": "По умолчанию",
      "Delete": "Удалить",
      "Description": "Описание",
      "Diabetes": "Сахарный диабет",
      "Discount": "Скидка",
      "Doctor": "Доктор",
      "DoctorFullName": "Врач ФИО",
      "DoctorFullName2": "Ф.И.О врача",
      "Doctors": "Врачи",
      "DoctorsSchedule": "Расписание врачей",
      "Documents": "Документы",
      "Download": "Загрузить",
      "DownloadApp": "Скачайте приложение",
      "DownloadExcel": "Скачать Excel-файл",
      "DrugFromWarehouse": "Препарат со склада",
      "Duration": "Продолжительность",
      "Edit": "Редактировать",
      "EndTreatment": "Закончить лечение",
      "Fluorography": "Флюорография",
      "ForgotPassword": "Забыл пароль",
      "FromWarehouse": "Cо склада",
      "FullName": "ФИО",
      "Income": "Доход",
      "InfectiousDiseases": "Инфекционные заболевания",
      "Info": "Информация",
      "Inspection": "Осмотр",
      "InspectionCard": "Карта осмотра",
      "InvalidBarCode": "Невалидный штрих-код",
      "InvalidQrCode": "Невалидный qr код",
      "InviteByNumber": "Пригласить по номеру",
      "Invoice": "Счёт",
      "Invoices": "Счета",
      "Loading": "Загрузка...",
      "Location": "Локация",
      "Login": "Логин",
      "LoginСonfirmed": "У меня есть код подтверждения",
      "Logout": "Выйти",
      "MakeAppointment": "Записать на приём",
      "Measure": "Единица измерения",
      "Naming": "Название",
      "NamingInEn": "Название на английском",
      "NamingInRu": "Название на русском",
      "NamingInUz": "Название на узбекском",
      "NamingShort": "Сокращ. название",
      "No": "Нет",
      "NoData": "Нет данных",
      "Notes": "Заметки",
      "Open": "Открыть",
      "OpenCard": "Открыть",
      "Orders": "Заявки",
      "Password": "Пароль",
      "Patient": "Пациент",
      "PatientDocument": "Документы пациента",
      "PatientInfo": "Информация о пациенте",
      "PatientRecord": "Запись пациента",
      "Patients": "Пациенты",
      "PatientsRecords": "Записи пациентов",
      "Pay": "Оплатить",
      "Payment": "Оплата",
      "Percent": "Процент",
      "PhoneNumber": "Номер телефона",
      "PleaseInput": "Пожалуйста, введите",
      "PleaseSelect": "Пожалуйста выберите",
      "Price": "Цена",
      "Print": "Распечатать",
      "PrintTicket": "Распечатать талон",
      "Quantity": "Количество",
      "QuantityDays": "Количество дней",
      "Queue": "Очередь",
      "QueueForToday": "Очередь на сегодня",
      "Reaction": "Реакция",
      "ReasonService": "Случай обслуживания",
      "Refund": "Возврат",
      "Replace": "Заменить",
      "Report": "Отчет",
      "Save": "Сохранить",
      "SaveChanges": "Сохранить изменения",
      "ScanBarCode": "Сканировать штрих-код",
      "ScanBracelet": "Cканировать",
      "Scanning": "Сканирование ...",
      "Search": "Поиск",
      "SelectCategory": "Выберите категорию",
      "SelectDate": "Выберите дату",
      "SelectReport": "Выберите отчет",
      "SelectServiceСategory": "Выберите категорию услуг",
      "SelectStatus": "Выберите статус",
      "Service": "Услуга",
      "Services": "Услуги",
      "SetTreatment": "Назначить лечение",
      "Settings": "Настройки",
      "SignUp": "Регистрация",
      "Skip": "Пропустить",
      "StatePrice": "Стоимость",
      "Statistics": "Статистика",
      "Status": "Статус",
      "Sum": "Cум",
      "SumCost": "Сумма",
      "SurgicalIntervention": "Хирургические вмешательста",
      "TableTreatment": "Дневники лечений",
      "Template": "Шаблон",
      "Templates": "Шаблоны",
      "Text": "Текст",
      "Time": "Время",
      "Total": "Итого",
      "TreatmentJournal": "Дневник лечения",
      "Type": "Тип",
      "Used": "Использовано",
      "Value": "Значение",
      "VerificationCode": "Код подтверждения",
      "View": "Посмотреть",
      "WaitingConfirm": "Ожидание подтверждения",
      "WhereFrom": "Откуда берется",
      "Workload": "Загруженность",
      "WriteText": "Напишите текст...",
      "Yes": "Да"
    }
  },
  "uz": {
    "Base": {
      "Absent": "Mavjud emas",
      "Actions": "Harakatlar",
      "Add": "Qo'shish",
      "Allergy": "Allergiya",
      "AmbulatoryCard": "Ambulatoriya kartasi",
      "Appoint": "Tayinlash",
      "Appointment": "Yozuv",
      "Attach": "Bog'lash",
      "AuthSubtitle": "Bemorlarni ro'yxatdan o'tkazish uchun shaxsiy kabinetga kiring",
      "Authorization": "Kirish",
      "Back": "Orqaga",
      "BloodTransfusion": "Qon quyish",
      "Cabinet": "Kabinet",
      "CalendarAppointments": "Qabullar taqvimi",
      "Call": "Qongiroq qilish",
      "CallToReception": "Qabul qilish",
      "Cancel": "Qaytish",
      "CancelConfirmation": "Tasdiqni bekor qiling",
      "Capacity": "Sig'im",
      "CashRegister": "Kassa",
      "Category": "Kategoriya",
      "ChangeLang": "Tilni o'zgartirish",
      "Choose": "Tanlang",
      "Class": "Sinf",
      "Clinic": "Klinika",
      "ClinicalExamination": "Dispanser ko'rigi",
      "Close": "Yopish",
      "Code": "Kod",
      "ColDays": "Kunlar Miqdori",
      "Confirm": "Tasdiqlash",
      "Cost": "Narxi",
      "Create": "Yaratish",
      "CreateNew": "Yangi yaratish",
      "Creator": "Yaratuvchi",
      "Custom": "Maxsus",
      "Dashboard": "Boshqaruv paneli",
      "DateAppoint": "Tayinlash sanasi",
      "Days": "Kunlar",
      "Default": "Standart",
      "Delete": "Oʻchirish",
      "Description": "Tavsif",
      "Diabetes": "Qandli diabet",
      "Discount": "Chegirma",
      "Doctor": "Shifokor",
      "DoctorFullName": "Shifokor FIO",
      "DoctorFullName2": "Shifokor FIO",
      "Doctors": "Shifokorlar",
      "DoctorsSchedule": "Shifokorlar jadvali",
      "Documents": "Bemor",
      "Download": "Yuklash",
      "DownloadApp": "Ilovani yuklab oling",
      "DownloadExcel": "Excel faylini yuklash",
      "DrugFromWarehouse": "Dori omboridan",
      "Duration": "Davomiyligi",
      "Edit": "Tahrirlash",
      "EndTreatment": "Davolanishni tugating",
      "Fluorography": "Flyurografiya",
      "ForgotPassword": "Parolni unutdingizmi",
      "FromWarehouse": "Ombordan",
      "FullName": "F.I.Sh",
      "Income": "Daromad",
      "InfectiousDiseases": "Yuqumli kasalliklar",
      "Info": "Ma'lumot",
      "Inspection": "Tekshirish",
      "InspectionCard": "Tekshirish kartasi",
      "InvalidBarCode": "Noto'g'ri shtrix kod",
      "InvalidQrCode": "Noto'g'ri QR kod",
      "InviteByNumber": "Telefondan ulash",
      "Invoice": "Hisob",
      "Invoices": "Hisoblar",
      "Loading": "Yuklanmoqda...",
      "Location": "Lokatsiya",
      "Login": "Login",
      "LoginСonfirmed": "Tasdiqlash kodim bor",
      "Logout": "Chiqish",
      "MakeAppointment": "Qabulga yozdirish",
      "Measure": "O‘lchov birligi",
      "Naming": "Nomi",
      "NamingInEn": "Nomi ingliz tilida",
      "NamingInRu": "Nomi rus tilida",
      "NamingInUz": "Nomi o'zbek tilida",
      "NamingShort": "Qisqartma",
      "No": "Yo'q",
      "NoData": "Malumot topilmadi",
      "Notes": "Eslatmalar",
      "Open": "Ochish",
      "OpenCard": " Ochish",
      "Orders": "Ilovalar",
      "Password": "Parol",
      "Patient": "Bemor",
      "PatientDocument": "Bemor hujjatlari",
      "PatientInfo": "Bemor haqida ma'lumot",
      "PatientRecord": "Bemor yozuvi",
      "Patients": "Bemorlar",
      "PatientsRecords": "Bemorlar yozuvlari",
      "Pay": "Tolash",
      "Payment": "To'lov",
      "Percent": "Foiz",
      "PhoneNumber": "Telefon raqami",
      "PleaseInput": "Iltimos, kiriting",
      "PleaseSelect": "Iltimos, tanlang",
      "Price": "Narxi",
      "Print": "Chop etish",
      "PrintTicket": "Chekni chiqazish",
      "Quantity": "Miqdori",
      "QuantityDays": "Kunlar miqdori",
      "Queue": "Navbat",
      "QueueForToday": "Bugungi navbat",
      "Reaction": "Reaksiya",
      "ReasonService": "Xizmat ishi",
      "Refund": "Qaytarish",
      "Replace": "O'zgartirish",
      "Report": "Hisobot",
      "Save": "Saqlash",
      "SaveChanges": "O'zgarishlarni saqlash",
      "ScanBarCode": "Shtrix kodni skanerlash",
      "ScanBracelet": "Skanerlash",
      "Scanning": "Skanerlanyapti ...",
      "Search": "Topish",
      "SelectCategory": "Kategoriyani tanlang",
      "SelectDate": "Sanani tanlang",
      "SelectReport": "Hisobotni tanlang",
      "SelectServiceСategory": "Xizmat kategoriyasini tanlang",
      "SelectStatus": "Statusni tanlang",
      "Service": "Xizmat",
      "Services": "Xizmatlar",
      "SetTreatment": "Davolashni tayinlash",
      "Settings": "Sozlamalar",
      "SignUp": "Ro'yxatdan o'tish",
      "Skip": "O'tkazib yuborish",
      "StatePrice": "Narxi",
      "Statistics": "Statistika",
      "Status": "Holat",
      "Sum": "So'm",
      "SumCost": "Narxi",
      "SurgicalIntervention": "Jarrohlik aralashuvlar",
      "TableTreatment": "Davolash jurnali",
      "Template": "Na’muna",
      "Templates": "Na'munalar",
      "Text": "Matn",
      "Time": "Vaqt",
      "Total": "Jami",
      "TreatmentJournal": "Davolash jurnali",
      "Type": "Turi",
      "Used": "Foydalanilgan",
      "Value": "Qiymati",
      "VerificationCode": "Tasdiqlash kodi",
      "View": "Ko'rish",
      "WaitingConfirm": "Tasdiqlash kutilmoqda",
      "WhereFrom": "Qayerdan",
      "Workload": "Yuklangalik holati",
      "WriteText": "Matnni yozing..."
    }
  }
}
