<template>
  <div class="deregister-pregnancy-form">
    <FieldGroupWrapper>
      <FormSelectField
        class="deregister-pregnancy-form__select"
        field-name="status"
        field-object-name="typeObject"
        :items="pregnacyOptions"
        :label="$t('Patients.ReasonOfDeregisterPregnancy')"
        value-key="value"
        label-key="label"
        required />
      <FormDateField
        :label="$t('Patients.DateOfBirth')"
        field-name="closed_at"
        required
        :placeholder="$t('DateAndTime.Date')" />
    </FieldGroupWrapper>
    <FormActionsWrapper>
      <ElButton
        class="deregister-pregnancy-form__btn"
        type="primary"
        @click="submitForm"
        :loading="isSubmitting"
        :disabled="submitDisabled">
        {{ $t('Patients.Deregister') }}
      </ElButton>
    </FormActionsWrapper>
  </div>
</template>

<script lang="ts">
export default {
  name: 'DeregisterPregnancyForm',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { useForm } from 'vee-validate';
import { computed } from 'vue';
import { DeregisterPregnancyFormData, PregnancyStatuses } from '@/types/api';
import { validationSchema } from './validationSchema';
import { I18nService } from '@/services/i18n.service';
import { Pregnancy } from '@/models/pregnancy/Pregnancy.model';

import FormSelectField from '@/components/common/form/ui/FormSelectField/index.vue';
import FormDateField from '@/components/common/form/ui/FormDateField/index.vue';
import FormActionsWrapper from '@/components/common/form/FormActionsWrapper/index.vue';
import FieldGroupWrapper from '@/components/common/form/FieldGroupWrapper/index.vue';

const emit = defineEmits(['close:modal']);
const { isSubmitting, errors, handleSubmit } = useForm<DeregisterPregnancyFormData>({
  validationSchema,
});

const props = defineProps<{
  id: number;
}>();
const submitDisabled = computed(() => Object.keys(errors.value).length > 0 || isSubmitting.value);

const pregnacyOptions = [
  { value: PregnancyStatuses.gave_birth, label: I18nService.t('Patients.Statuses.gave_birth') },
  { value: PregnancyStatuses.abort, label: I18nService.t('Patients.Statuses.abort') },
  { value: PregnancyStatuses.misbirth, label: I18nService.t('Patients.Statuses.misbirth') },
  {
    value: PregnancyStatuses.child_deathed,
    label: I18nService.t('Patients.Statuses.child_deathed'),
  },
  {
    value: PregnancyStatuses.mother_deathed,
    label: I18nService.t('Patients.Statuses.mother_deathed'),
  },
];

const submitForm = handleSubmit(
  async (values) => {
    const requestDara = {
      closed_at: values.closed_at,
      status: values.status,
    };

    await Pregnancy.updateItem(props.id, requestDara);

    emit('close:modal');
  },
  (ctx) => {
    // eslint-disable-next-line no-console
    console.log('invalid submit', ctx.errors);
  }
);
</script>

<style lang="scss" src="./index.scss"></style>
<i18n src="@/locales/dateAndTime.locales.json" />
<i18n src="@/locales/patients.locales.json" />
