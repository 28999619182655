import axios from 'axios';
import { CRUDModel } from '@/models/CRUD.model';
import { ApiService } from '@/services/api.service';
import { mergeOrCreateQuery } from '@/utils/http.util';
import { deleteEmptyValueKeys } from '@/utils/object.util';
import { ElNotification } from 'element-plus';
import {
  ExpenseCategory,
  ExpenseProduct,
  ServiceGroupBaseDto,
  MarketCalculation,
  ServiceGroupType,
  DoctorShort,
  WarehouseDto,
} from '@/types/api';

export type ExpenseCategoryFormData = Omit<ExpenseCategory, 'value'> & {
  value?: number | null;
  deleted?: boolean;
};

export type ServiceGroupBaseFormData = Pick<
  ServiceGroupBaseDto,
  'type' | 'ikpu_code' | 'title' | 'is_published' | 'package_code'
> & {
  id?: number | null;
  category?: ServiceGroupBaseDto['category'] | null;
  category_id?: number | null;
  vat_percent?: number | null;
  price?: number | null;
  market_calculations?: ServiceGroupBaseDto['market_calculations'] | null;
};

export type CalculatePriceRequestData = {
  change: null | 'price' | 'margin';
  price: number;
  expenses_categories: ExpenseCategoryFormData[];
  expenses_products: ExpenseProduct[];
  market_calculations: MarketCalculation;
};

export class ServiceGroupBase extends CRUDModel {
  static modelName = 'service';
  static tableName = 'services';

  type: ServiceGroupBaseFormData['type'];
  title: ServiceGroupBaseFormData['title'];
  category: ServiceGroupBaseFormData['category'];
  category_id: ServiceGroupBaseFormData['category_id'];
  package_code: ServiceGroupBaseFormData['package_code'];
  vat_percent: ServiceGroupBaseFormData['vat_percent'];
  ikpu_code: ServiceGroupBaseFormData['ikpu_code'];
  price: ServiceGroupBaseFormData['price'];
  market_calculations: ServiceGroupBaseFormData['market_calculations'];
  is_published: ServiceGroupBaseFormData['is_published'];

  constructor(payload?: ServiceGroupBaseFormData) {
    super(payload);

    this.price = payload?.price ?? null;
    this.title = payload?.title ?? { ru: '', en: '', uz: '' };
    this.type = payload?.type ?? 'consultation';
    this.category = payload?.category ?? null;
    this.category_id = payload?.category?.id ?? null;
    this.package_code = payload?.package_code ?? '';
    this.vat_percent = payload?.vat_percent ?? null;
    this.ikpu_code = payload?.ikpu_code ?? '';
    this.market_calculations = payload?.market_calculations ?? null;
    this.is_published = payload?.is_published ?? false;
  }

  static async getExpenseCategories(query: { type: ServiceGroupType }) {
    try {
      const response = await ApiService.get<{ data: ExpenseCategoryFormData[] }>(
        mergeOrCreateQuery({
          url: `${this.tableName}/group/expenses/categories`,
          query: deleteEmptyValueKeys(query),
        })
      );

      return {
        response: response,
        data: response.data.data,
      };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: axios.isAxiosError(err) ? err.message : String(err),
      });
    }
  }

  static async getItemById(id: number) {
    try {
      const response = await ApiService.get(`${this.tableName}/group/${id}`);

      return {
        response: response,
        data: response.data.data,
      };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: axios.isAxiosError(err) ? err.message : String(err),
      });
    }
  }

  static async createItem(payload: Record<string, unknown>) {
    try {
      const response = await ApiService.post<{
        data: Record<string, unknown>;
      }>(`${this.tableName}/group/create`, payload);

      return {
        response: response,
        data: response.data.data,
      };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: axios.isAxiosError(err) ? err.message : String(err),
      });
    }
  }

  static async updateItem({ id, payload }: { id: number; payload: Record<string, unknown> }) {
    try {
      const response = await ApiService.put<{
        data: Record<string, unknown>;
      }>(`${this.tableName}/group/${id}/update`, payload);

      return {
        response: response,
        data: response.data.data,
      };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: axios.isAxiosError(err) ? err.message : String(err),
      });
    }
  }

  static async deleteItem(id: number) {
    try {
      await ApiService.delete(`${this.tableName}/group/${id}/delete`);
    } catch (err) {
      ElNotification({
        type: 'error',
        title: axios.isAxiosError(err) ? err.message : String(err),
      });
    }
  }

  static async calculatePrice(payload: CalculatePriceRequestData) {
    try {
      const response = await ApiService.post(`${this.tableName}/group/calculatePrice`, payload);

      return {
        response: response,
        data: response.data.data,
      };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: axios.isAxiosError(err) ? err.message : String(err),
      });
      if (axios.isAxiosError(err)) {
        return err;
      }
    }
  }
}

export type ServiceGroupConsultationFormData = ServiceGroupBaseFormData & {
  slot_duration?: number | null;
  doctors: DoctorShort[];
  expenses_categories: ExpenseCategoryFormData[];
  expenses_products: ExpenseProduct[];
};

export class ServiceGroupConsultation extends ServiceGroupBase {
  slot_duration: ServiceGroupConsultationFormData['slot_duration'];
  doctors: ServiceGroupConsultationFormData['doctors'];
  doctor_ids: number[];
  expenses_categories: ServiceGroupConsultationFormData['expenses_categories'];
  expenses_products: ServiceGroupConsultationFormData['expenses_products'];

  constructor(payload?: ServiceGroupConsultationFormData) {
    super(payload);

    this.slot_duration = payload?.slot_duration ?? null;
    this.doctors = payload?.doctors ?? [];
    this.doctor_ids = payload?.doctors ? payload?.doctors.map((item) => item.id) : [];
    this.expenses_categories = payload?.expenses_categories ?? [];
    this.expenses_products = payload?.expenses_products ?? [];
  }
}

export type ServiceGroupProductFormData = ServiceGroupBaseFormData & {
  warehouse_id: WarehouseDto['id'] | null;
  warehouse: WarehouseDto | null;
  expenses_categories: ExpenseCategoryFormData[];
  expenses_products: ExpenseProduct[];
};

export class ServiceGroupProduct extends ServiceGroupBase {
  warehouse_id: ServiceGroupProductFormData['warehouse_id'];
  warehouse: ServiceGroupProductFormData['warehouse'];
  expenses_categories: ServiceGroupProductFormData['expenses_categories'];
  expenses_products: ServiceGroupProductFormData['expenses_products'];

  constructor(payload?: ServiceGroupProductFormData) {
    super(payload);

    this.type = payload?.type ?? 'product';
    this.warehouse_id = payload?.warehouse?.id ?? null;
    this.warehouse = payload?.warehouse ?? null;
    this.expenses_categories = payload?.expenses_categories ?? [];
    this.expenses_products = payload?.expenses_products ?? [];
  }
}

export type ServiceGroupProcedureFormdata = ServiceGroupBaseFormData & {
  slot_duration: number | null;
  doctors: DoctorShort[];
  doctor_ids: number[];
  expenses_categories: ExpenseCategoryFormData[];
  expenses_products: ExpenseProduct[];
};

export class ServiceGroupProcedure extends ServiceGroupBase {
  slot_duration: ServiceGroupProcedureFormdata['slot_duration'];
  doctors: ServiceGroupProcedureFormdata['doctors'];
  doctor_ids: number[];
  expenses_categories: ServiceGroupProcedureFormdata['expenses_categories'];
  expenses_products: ServiceGroupProcedureFormdata['expenses_products'];

  constructor(payload?: ServiceGroupProcedureFormdata) {
    super(payload);

    this.type = payload?.type ?? 'procedure';
    this.slot_duration = payload?.slot_duration ?? null;
    this.doctors = payload?.doctors ?? [];
    this.doctor_ids = payload?.doctors ? payload?.doctors.map((item) => item.id) : [];
    this.expenses_categories = payload?.expenses_categories ?? [];
    this.expenses_products = payload?.expenses_products ?? [];
  }
}
