import { onlyLoggedInMiddleware } from '@/middlewares/onlyLoggedIn.middleware';

export const DASHBOARD_ROUTE = {
  name: 'REGISTRY_DASHBOARD',
  path: '/dashboard',
  component: 'VDashboard',
  beforeEnter: [onlyLoggedInMiddleware],
  meta: {
    title: 'Base.Dashboard',
  },
};

export const routes = [DASHBOARD_ROUTE];
