﻿{
  "en": {
    "User": {
      "Baby": "Baby",
      "DateBirthChild": "Date of birth of the child",
      "Method": "Method",
      "NaturalChildbirth": "Natural childbirth",
      "Accounting": "Accounting",
      "ActivationKey": "Activation key",
      "AddChildren": "Add a child",
      "AddUser": "Add user",
      "Address": "Address",
      "Age": "Age",
      "Answer": "Answer",
      "AnswerOnComment": "Response to the comment",
      "AnswerToComment": "Respond to the comment",
      "AppointmentFee": "Appointment commission",
      "Attendancy": "Attendancy",
      "Birthdate": "Date of birth",
      "BloodTypeRhFactor": "Blood type and Rh factor",
      "Came": "Came",
      "Children": "Children",
      "ConsultationFee": "Consultancy commission",
      "Devices": "Devices",
      "DidntComeOut": "No",
      "Email": "Mail",
      "EmployeeActivityInfo": "The activity status is displayed on the employee profile page to determine his current accessibility status",
      "EmployeeClinic": "Clinical staff member",
      "EmployeeInfo": "Employee information",
      "EmployeeInformation": "Information about the employee",
      "EmployeeLoginAndPassword": "Employee login and password",
      "EmployeeSalary": "Employee salary",
      "Employees": "Employees",
      "FIOEmployee": "Full name of the employee",
      "FeedbackByClinic": "Feedback on the clinic",
      "FeedbackByDoctor": "Feedback on the doctor",
      "FormatDate": "DD/MM/YYYY",
      "FullName": "Full name",
      "FullNameFormat": "Latin letters",
      "FullNameOrPhoneNumber": "Full name or phone number",
      "Gender": "Gender",
      "Genders": {
        "man": "Male",
        "null": "",
        "woman": "Female"
      },
      "GetStarted": "Beginning of work",
      "Gone": "Left",
      "Height": "Height",
      "IsChildren": "Child",
      "Is_foreginer": "Foreigner",
      "NumberOfPatients": "Number of patients",
      "PINFL": "PINFL",
      "Parent": "Parent",
      "ParentFullName": "Full Name of the parent",
      "PassportSerialAndNumber": "Passport series and number",
      "Password": "Password",
      "Phone": "Phone",
      "PhoneNumber": "Phone number",
      "PlaceResidence": "Place of residence",
      "PlaceTreatment": "Place of treatment",
      "PleaseInputAddress": "Enter the patient's address",
      "PleaseInputNameOrPhone": "Enter the number or full name",
      "QueueNumber": "Ticket number",
      "Role": "Role",
      "Roles": {
        "accountant": "Accountant",
        "admin": "Administrator",
        "director": "Director",
        "doctor": "Doctor",
        "laboratory": "Laboratory assistant",
        "manager": "Manager",
        "nurse": "Nurse",
        "patient": "Patient",
        "warehouse": "Warehouse",
        "family-doctor": "Family doctor"
      },
      "Salary": "Salary",
      "SelectSpeciality": "Select specialty",
      "Speciality": "Speciality",
      "Status": "Status",
      "StatusActivityEmployee": "Employee activity status",
      "Statuses": {
        "active": "Active",
        "average": "Average",
        "normal": "Normal",
        "not_active": "Inactive",
        "serious": "Severe"
      },
      "StudyWork": "Study - work",
      "User": "User",
      "UserCredentialsHint": "Please make a note of the employee's login and password as they are displayed only once",
      "Users": "Users",
      "WasReception": "Had an appointment",
      "Weight": "Weight",
      "WorkExperience": "Work experience",
      "WorkSchedule": "Work schedule",
      "WorkingStatuses": {
        "day_work_end": "Finished the work day",
        "doesnt_come_out": "Did not go out",
        "working": "Working"
      }
    }
  },
  "ru": {
    "User": {
      "Baby": "Младенец",
      "DateBirthChild": "Дата рождения ребёнка",
      "Method": "Метод",
      "NaturalChildbirth": "Естественные роды",
      "Accounting": "Бухгалтерия",
      "ActivationKey": "Ключ активации",
      "AddChildren": "Добавить ребёнка",
      "AddUser": "Добавить пользователя",
      "Address": "Адрес",
      "Age": "Возраст",
      "Answer": "Ответ",
      "AnswerOnComment": "Ответ на комментарий",
      "AnswerToComment": "Ответить на комментарий",
      "AppointmentFee": "Комиссия за назначение",
      "Attendancy": "Посещаемость",
      "Birthdate": "Дата рождения",
      "BloodTypeRhFactor": "Группа крови и резус фактор",
      "Came": "Пришёл",
      "Children": "Дети",
      "ConsultationFee": "Комиссия за консультацию",
      "CountryCity": "Страна, город",
      "Devices": "Устройства",
      "DidntComeOut": "Не вышел",
      "Email": "Почта",
      "EmployeeActivityInfo": "Статус активности отображается на странице профиля сотрудника для определения его текущего статуса доступности",
      "EmployeeClinic": "Сотрудник клиники",
      "EmployeeInfo": "Информация про сотрудника",
      "EmployeeInformation": "Информация о сотруднике",
      "EmployeeLoginAndPassword": "Логин и пароль сотрудника",
      "EmployeeSalary": "Оклад сотрудника",
      "Employees": "Сотрудники",
      "EndAge": "Конечный возраст",
      "FIOEmployee": "ФИО сотрудника",
      "FeedbackByClinic": "Отзыв на клинику",
      "FeedbackByDoctor": "Отзыв на врача",
      "FormatDate": "ДД/ММ/ГГГГ",
      "From": "Откуда",
      "FullName": "ФИО",
      "FullNameFormat": "Латинские буквы",
      "FullNameOrPhoneNumber": "Ф.И.О. или номер телефона",
      "Gender": "Пол",
      "Genders": {
        "man": "Муж",
        "null": "",
        "woman": "Жен"
      },
      "GetStarted": "Начало работы",
      "Gone": "Ушёл",
      "Height": "Рост",
      "IsChildren": "Ребёнок",
      "Is_foreginer": "Иностранец",
      "NumberOfPatients": "Кол-во пациентов",
      "PINFL": "ПИНФЛ",
      "Parent": "Родитель",
      "ParentFullName": "ФИО родителя",
      "PassportSerialAndNumber": "Серия и номер паспорта",
      "Password": "Пароль",
      "Phone": "Телефон",
      "PhoneNumber": "Номер телефона",
      "PlaceResidence": "Место проживания",
      "PlaceTreatment": "Место лечения",
      "PleaseInputAddress": "Введите адрес пациента",
      "PleaseInputNameOrPhone": "Введите номер или ФИО",
      "QueueNumber": "Номер талона",
      "Role": "Роль",
      "Roles": {
        "accountant": "Бухгалтер",
        "admin": "Администратор",
        "director": "Директор",
        "doctor": "Врач",
        "laboratory": "Лаборант",
        "manager": "Менеджер",
        "nurse": "Медсестра",
        "patient": "Пациент",
        "warehouse": "Кладовщик",
        "family-doctor": "Семейный врач"
      },
      "Salary": "Оклад",
      "SelectSpeciality": "Выбрать специальность",
      "Speciality": "Специальность",
      "StartAge": "Стартовый возраст",
      "Status": "Статус",
      "StatusActivityEmployee": "Статус активности сотрудника",
      "Statuses": {
        "active": "Активный",
        "average": "Среднее",
        "normal": "Нормальное",
        "not_active": "Неактивный",
        "serious": "Тяжелое"
      },
      "StudyWork": "Учёба - работа",
      "User": "Пользователь",
      "UserCredentialsHint": "Пожалуйста, запишите логин и пароль сотрудника, так как они отображаются только один раз",
      "Users": "Пользователи",
      "WasReception": "Был на приеме",
      "Weight": "Вес",
      "WorkExperience": "Стаж работы",
      "WorkSchedule": "Режим работы",
      "WorkingHours": "График работы (часы)",
      "WorkingStatuses": {
        "day_work_end": "Закончил рабочий день",
        "doesnt_come_out": "Не вышел",
        "working": "Работает"
      }
    }
  },
  "uz": {
    "User": {
      "Baby": "Bola",
      "DateBirthChild": "Bolaning tug'ilgan sanasi",
      "Method": "Usul",
      "NaturalChildbirth": "Tabiiy tuyulish",
      "Accounting": "Buxgalteriya",
      "ActivationKey": "Aktivlashtirish kaliti",
      "AddChildren": "Bolani qo'shish",
      "AddUser": "Foydalanuvchini qo'shish",
      "Address": "Manzil",
      "Age": "Yosh",
      "Answer": "Javob",
      "AnswerOnComment": "Izohga javob",
      "AnswerToComment": "Izohga javob berish",
      "AppointmentFee": "Qabul uchun komissiya",
      "Attendancy": "Tashriflar",
      "Birthdate": "Tug'ilgan sanasi",
      "BloodTypeRhFactor": "Qon guruhi va Rh-omil",
      "Came": "Kelgan",
      "Children": "Bolalar",
      "ConsultationFee": "Konsultatsiya uchun komissiya",
      "CountryCity": "Mamlakat, shahri",
      "Devices": "Uskunalar",
      "DidntComeOut": "Chiqmagan",
      "Email": "E-mail",
      "EmployeeActivityInfo": "Faoliyat holati xodimning Profil sahifasida uning joriy kirish holatini aniqlash uchun ko'rsatiladi",
      "EmployeeClinic": "Klinika xodimi",
      "EmployeeInfo": "Xodim haqida ma'lumot",
      "EmployeeInformation": "Xodimlar haqida ma'lumot",
      "EmployeeLoginAndPassword": "Xodimning login va paroli",
      "EmployeeSalary": "Xodimning oylik maoshi",
      "Employees": "Xodimlar",
      "EndAge": "Yosh tugashi",
      "FIOEmployee": "Xodimning F.I.SH",
      "FeedbackByClinic": "Klinika haqida fikr-mulohazalar",
      "FeedbackByDoctor": "Shifokor haqida fikr-mulohazalar",
      "FormatDate": "KUN/OY/YIL",
      "From": "Qayerdan",
      "FullName": "F.I.Sh",
      "FullNameFormat": "Lotin harflari",
      "FullNameOrPhoneNumber": "To'liq ismi yoki telefon raqami",
      "Gender": "Jinsi",
      "Genders": {
        "man": "Erkak",
        "woman": "Ayol"
      },
      "GetStarted": "Ishni boshlash",
      "Gone": "Ketgan",
      "Height": "Bo'yi",
      "IsChildren": "Yosh bola",
      "Is_foreginer": "Chet ellik",
      "NumberOfPatients": "Kasallar soni",
      "PINFL": "PINFL",
      "Parent": "Ota-onasi",
      "ParentFullName": "Ota-onasining F.I.Sh",
      "PassportSerialAndNumber": "Pasport seriyasi va  raqami",
      "Password": "Parol",
      "Phone": "Telefon raqami",
      "PhoneNumber": "Telefon raqami",
      "PlaceResidence": "Yashash joyi",
      "PlaceTreatment": "Davolash joyi",
      "PleaseInputAddress": "Yashash manzilini kiriting",
      "PleaseInputNameOrPhone": "Telefon raqamini yoki F.I.SH kiriting",
      "QueueNumber": "Talon raqami",
      "Role": "Rol",
      "Roles": {
        "accountant": "Buxgalter",
        "admin": "Administrator",
        "director": "Direktor",
        "doctor": "Shifokor",
        "laboratory": "Laborant",
        "manager": "Menejer",
        "nurse": "Hamshira",
        "patient": "Bemor",
        "warehouse": "Omborchi",
        "family-doctor": "Oilaviy shifokor"
      },
      "Salary": "Oylik maoshi",
      "SelectSpeciality": "Ixtisoslikni tanlang",
      "Speciality": "Ixtisoslik",
      "StartAge": "Yosh boshlanishi",
      "Status": "Status",
      "StatusActivityEmployee": "Xodimlarning faoliyati holati",
      "Statuses": {
        "active": "Aktiv",
        "average": "O'rtacha",
        "normal": "Normal",
        "not_active": "Aktiv emas",
        "serious": "Og'ir"
      },
      "StudyWork": "O'qish - ishlash",
      "User": "Foydalanuvchi",
      "UserCredentialsHint": "Iltimos, xodimning login va parolini kiriting, chunki ular faqat bir marta ko'rsatiladi",
      "Users": "Foydalanuvchilar",
      "WasReception": "Qabulda bo’lgan",
      "Weight": "Vazni",
      "WorkExperience": "Ish tajribasi",
      "WorkSchedule": "Ish vaqti",
      "WorkingStatuses": {
        "day_work_end": "Ish kunini yakunladi",
        "doesnt_come_out": "Chiqmadi",
        "working": "Ishlamoqda"
      }
    }
  }
}
