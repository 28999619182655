<template>
  <ElForm class="create-treatment-drug" label-position="top" @submit.prevent="createDrug">
    <UiModelsAutocompleteSearch
      v-model="drug.id"
      :model-for-use="Product"
      label="title"
      @select="drug = $event" />
    <ElInput
      v-model.number="drug.count"
      :placeholder="$t('Base.QuantityDays')"
      type="number"
      min="1"
      required />
    <ElButton class="create-treatment-drug__submit" type="primary" native-type="submit">
      {{ $t('Base.Add') }}
    </ElButton>
    <ElCheckbox v-model="this.is_patient_product" :label="$t('Base.DrugFromWarehouse')" />
  </ElForm>
</template>

<script>
import * as icons from '@/enums/icons.enum.js';
import { Treatment } from '@/models/Treatment.model';
import { TreatmentDrug } from '@/models/TreatmentDrug.model';
import { Product } from '@/models/warehouse/Product.model';
import cloneDeep from 'lodash.clonedeep';

import UiModelsAutocompleteSearch from '@/components/ui/UiModelsAutocompleteSearch/index.vue';

export default {
  name: 'CreateTreatmentDrug',
  components: { UiModelsAutocompleteSearch },
  emits: ['drug:create'],
  props: {
    treatment: [Treatment, Object],
  },
  data() {
    return {
      drug: new TreatmentDrug(),
      drugOptions: [],
      drugOption: null,
      is_patient_product: false,
    };
  },

  methods: {
    async getProductionDrugs() {
      const { data } = await Product.getItems({ page: 1, per_page: 30 });
      this.drugOptions = data.data.map((item) => ({
        label: item.title,
        value: item.id,
      }));
    },

    createDrug() {
      this.drugOptions.push({
        label: this.drug.title,
        value: this.drug.id,
      });
      const findData = this.drugOptions.find((item) => item.value === this.drug.id);

      this.$emit(
        'drug:create',
        cloneDeep({
          ...this.drug,
          title: findData.label,
          id: findData.value,
          is_patient_product: this.is_patient_product,
        })
      );
      this.reset();
    },

    reset() {
      this.drug = new TreatmentDrug();
    },
  },

  mounted() {
    this.getProductionDrugs();
  },

  setup: () => ({
    icons,
    Product,
  }),
};
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/treatments.locales.json" />
