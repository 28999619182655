<template>
  <LayoutByUserRole content-class="v-income-content" fix-height>
    <LayoutContentHeader>
      <!-- Date -->
      <ElDatePicker
        class="v-doctors-content-header__date"
        v-model="date.value"
        type="daterange"
        unlink-panels
        :format="DATE_FORMAT"
        :value-format="DATE_FORMAT"
        :start-placeholder="$t('DateAndTime.StartDate')"
        :end-placeholder="$t('DateAndTime.EndDate')" />

      <UiModelsAutocompleteSearch
        class="v-income-content__products"
        v-model="productIds.value"
        :model-for-use="Product"
        method-name="getItems"
        label="title"
        value="id"
        :placeholder="$t('Income.SearchGoods')"
        multiple />

      <!-- Supplier -->
      <UiModelsAutocompleteSearch
        class="v-doctors-content-header__date"
        :model-value="supplier.value.id"
        :model-for-use="Supplier"
        label="title"
        :default-item="supplier.value"
        :placeholder="$t('Income.Supplier')"
        clearable
        @select="supplier.value = $event" />

      <template #actions>
        <a :href="exportDataURL" download>
          <ElButton type="primary">
            {{ $t('Base.DownloadExcel') }}
          </ElButton>
        </a>

        <ElButton type="primary" @click="onCreateButtonClick">
          {{ `+ ${$t('Income.CreateIncome')}` }}
        </ElButton>
      </template>
    </LayoutContentHeader>
    <IncomesTable
      :loading="loading"
      v-model:page="page.value"
      v-model:per-page="perPage.value"
      :items="items"
      :total="total"
      @modal:open="onAcceptButtonClick"
      @modal:cancel="onCanceledButtonClick" />
  </LayoutByUserRole>
</template>

<script lang="ts">
export default {
  name: 'VIncome',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import axios from 'axios';
import { ref, computed, watch } from 'vue';
import { I18nService } from '@/services/i18n.service';
import { ElNotification } from 'element-plus';
import { useStore } from 'vuex';
import { useDatePeriod, usePerPage, usePage } from '@/hooks/query';
import { useModel } from '@/hooks/useModel';
import { compareQueriesThenLoadData } from '@/utils/router.utils';
import { Income } from '@/models/warehouse/Income.model';
import { DATE_FORMAT } from '@/config/dateAndTime.config';
import { getMonthPeriod } from '@/utils/dateAndTime.utils';
import { Supplier } from '@/models/warehouse/Supplier.model';
import { IncomeDto } from '@/types/api';
import { Product } from '@/models/warehouse/Product.model';
import { useQuery } from '@/hooks/useQuery.hook';
import { mergeOrCreateQuery } from '@/utils/http.util';
import { deleteEmptyValueKeys } from '@/utils/object.util';
import { TokenService } from '@/services/token.service';
import { DeviceService } from '@/services/device.service';

import IncomeCrudModal from '@/components/warehouse/modals/IncomeCrudModal/index.vue';
import LayoutByUserRole from '@/components/layouts/LayoutByUserRole/index.vue';
import LayoutContentHeader from '@/components/layouts/assets/LayoutContentHeader/index.vue';
import IncomesTable from '@/components/warehouse/tables/IncomesTable/index.vue';

const loading = ref(false);
const store = useStore(); // TODO типизировать store
const perPage = usePerPage(); // TODO типизировать usePerPage
const page = usePage(); // TODO типизировать usePage

const exportDataURL = computed(() => {
  return mergeOrCreateQuery({
    url: Income.exportIncomeDataURL,
    query: deleteEmptyValueKeys({
      ...query.value,
      lang: I18nService.getLocale(),
      token: TokenService.getAccessToken(),
      device_id: DeviceService.getDeviceId(),
    }),
  });
});

const supplier = useModel({
  fieldNames: {
    id: 'supplier_id',
    name: 'supplier_name',
  },
});

const productIds = useQuery({ field: 'product_ids', valueIsNumberArray: true });
const date = useDatePeriod(getMonthPeriod()); // TODO типизировать useDatePeriod

const total = computed(() => store.state.incomes.total);
const items = computed(() => store.state.incomes.data);
const query = computed(() => ({
  per_page: perPage.value,
  page: page.value,
  start_at: date.value[0],
  end_at: date.value[1],
  // status: status.value,
  supplier_id: supplier.value.id,
  product_ids: productIds.value,
}));

const getIncomes = async () => {
  loading.value = true;
  try {
    const { data } = await Income.getItems(query.value);
    store.dispatch('incomes/setData', {
      items: data.data,
      total: +data.meta.total,
      overwriteDataState: true,
    });
  } catch (err) {
    ElNotification({
      type: 'error',
      title: axios.isAxiosError(err) ? err.message : I18nService.t('Notifications.Error'),
    });
  } finally {
    loading.value = false;
  }
};

watch(
  query,
  (value, oldValue) => {
    compareQueriesThenLoadData({
      query: value,
      oldQuery: oldValue,
      getData: getIncomes,
      resetPage: page.reset,
    });
  },
  { deep: true, immediate: true }
);

const onCreateButtonClick = async () => {
  await store.dispatch('modalAndDrawer/openModal', IncomeCrudModal);
};

const onAcceptButtonClick = async (payload: IncomeDto) => {
  await store.dispatch('incomes/setItemLoading', {
    id: payload.id,
    loading: { accept: true },
  });
  try {
    const { data } = await Income.getItemById(payload.id);
    store.dispatch('modalAndDrawer/openModal', {
      component: IncomeCrudModal,
      payload: {
        formData: data.data,
      },
    });
  } catch (err) {
    ElNotification({
      type: 'error',
      title: axios.isAxiosError(err) ? err.message : I18nService.t('Notifications.Error'),
    });
  } finally {
    await store.dispatch('incomes/setItemLoading', { id: payload.id, loading: undefined });
  }
};

const onCanceledButtonClick = async (payload: Omit<IncomeDto, 'id'> & { id: number }) => {
  await store.dispatch('incomes/setItemLoading', {
    id: payload.id,
    loading: { cancel: true },
  });
  try {
    const { data } = await Income.canceledItem(payload.id);
    await store.dispatch('incomes/editItem', data.data);
  } catch (err) {
    ElNotification({
      type: 'error',
      title: axios.isAxiosError(err) ? err.message : I18nService.t('Notifications.Error'),
    });
  } finally {
    await store.dispatch('incomes/setItemLoading', { id: payload.id, loading: undefined });
  }
};
</script>

<style lang="scss" src="./index.scss"></style>
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/warehouse.locales.json" />
<i18n src="@/locales/ui.locales.json" />
<i18n src="@/locales/notifications.locales.json" />
<i18n src="@/locales/dateAndTime.locales.json" />
