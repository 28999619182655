<template>
  <LayoutByUserRole>
    <div class="v-patient-doc">
      <PersonCard
        class="v-patient-doc__header"
        :person="patient"
        :items="patientItems"
        content-grid>
        <template #actions>
          <ElButton type="primary" @click="goToPatient">{{ $t('Patients.PatientCard') }}</ElButton>
        </template>
      </PersonCard>
      <TinyEditor v-model:content="text" :disabled="isDirectorOrManager" />
      <ElButton v-if="!isDirectorOrManager" class="v-patient-doc__btn" @click="save" type="primary"
        >{{ `${$t('Base.Save')}` }}
      </ElButton>
    </div>
  </LayoutByUserRole>
</template>

<script lang="ts">
export default {
  name: 'VDocuments',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { ref, computed, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { Card } from '@/models/card/Card.model';
import { CardFullDto } from '@/types/api';
import { I18nService } from '@/services/i18n.service';
import { User } from '@/models/User.model';
import { PATIENT_ROUTE } from '@/router/patients.routes';
import TinyEditor from '@/components/common/TinyEditor/Index.vue';

import LayoutByUserRole from '@/components/layouts/LayoutByUserRole/index.vue';
import PersonCard from '@/components/PersonCard/index.vue';

const route = useRoute();
const router = useRouter();
const store = useStore();
const data = ref<CardFullDto | null>(store.state?.card.cardInfo ?? null);
const text = ref(store.state?.card.cardInfo?.basic_data ?? '');

const getCard = async () => {
  const response = await Card.getById(Number(route.params.id));

  if (response?.data) {
    data.value = response.data.data;
    text.value = response.data.data.basic_data;
  }
};

const isDirectorOrManager = computed(
  () =>
    store.state.auth.user.role === User.enum.roles.Director ||
    store.state.auth.user.role === User.enum.roles.Manager
);

const patient = computed(() => {
  return data.value?.user;
});
const patientItems = computed(() => {
  return data.value
    ? [
        {
          label: I18nService.t('Patients.PatientFIO'),
          value: data.value.user.name,
        },
        {
          label: I18nService.t('Patients.DateAndTimeVisit'),
          value: data.value.created_at,
        },
      ]
    : [];
});
const save = async () => {
  if (!data.value) return;

  await Card.update(data.value.id, {
    title: data.value.title as string,
    basic_data: text.value,
    inspection_card_template_id: data.value.template.id,
  });

  goToPatient();
};

const goToPatient = () => {
  router.push({
    name: PATIENT_ROUTE.name,
    params: { id: patient.value?.id },
  });
};

onMounted(() => {
  if (!route.params.mode) {
    getCard();
  }
});
</script>

<style lang="scss" src="./index.scss" scoped></style>
<i18n src="@/locales/patients.locales.json" />
