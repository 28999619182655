import { ElNotification } from 'element-plus';
import { ApiService } from '@/services/api.service';
import {
  CardFullDto,
  CardShortDto,
  MetaDto,
  CardCreateRequest,
  CardUpdateRequest,
} from '@/types/api';

import axios from 'axios';

export class Card {
  static modelName = 'card';
  static tableName = 'cards';

  static async getItems(id: number) {
    try {
      const response = await ApiService.get<{ data: CardShortDto[]; meta: MetaDto }>(
        `b2g/${this.tableName}/${id}/list`
      );

      return {
        data: response.data.data,
        meta: response.data.meta,
      };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: axios.isAxiosError(err) ? err.message : String(err),
      });
    }
  }

  static async create(payload: CardCreateRequest) {
    try {
      const response = await ApiService.post<{ data: CardFullDto }>(
        `b2g/${this.tableName}/create`,
        payload
      );
      return {
        response,
        data: response.data,
      };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: axios.isAxiosError(err) ? err.message : String(err),
      });
    }
  }

  static async update(id: number, payload: CardUpdateRequest) {
    try {
      const response = await ApiService.put<{ data: CardFullDto }>(
        `b2g/${this.tableName}/${id}/update`,
        payload
      );

      return {
        response,
        data: response.data,
      };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: axios.isAxiosError(err) ? err.message : String(err),
      });
    }
  }

  static async getById(id: number) {
    try {
      const response = await ApiService.get<{ data: CardFullDto }>(`b2g/${this.tableName}/${id}`);

      return {
        response,
        data: response.data,
      };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: axios.isAxiosError(err) ? err.message : String(err),
      });
    }
  }
}
