<template>
  <UiTableWithPagination
    table-class="users-table"
    :data="items"
    :loading="loading"
    :page="page"
    :per-page="perPage"
    :total="total"
    @update:page="$emit('update:page', $event)"
    @update:per-page="$emit('update:perPage', $event)"
    @row-click="goToUser">
    <ElTableColumn prop="name" :label="$t('User.FIOEmployee')" width="300px">
      <template #default="{ row }">
        <UiUserAvatarInfo class="users-table__user" :bold="false" :user="row" />
      </template>
    </ElTableColumn>

    <ElTableColumn prop="role" :label="$t('User.Role')">
      <template #default="{ row }">
        {{ row.role ? $t(`User.Roles.${row.role}`) : '...' }}
      </template>
    </ElTableColumn>
    <ElTableColumn prop="specialty" :label="$t('User.Speciality')">
      <template #default="{ row }">
        {{ row.specialty ? row.specialty : '...' }}
      </template>
    </ElTableColumn>
    <ElTableColumn prop="status" :label="$t('User.Status')">
      <template #default="{ row }">
        <StatusTag
          :title="row.status ? $t(`User.Statuses.${row.status}`) : ''"
          :value="row.status"
          :map-status="mapStatus" />
      </template>
    </ElTableColumn>

    <ElTableColumn prop="start_at" :label="$t('User.Came')">
      <template #default="{ row }">
        {{ row.start_at ? row.start_at : $t('User.DidntComeOut') }}
      </template>
    </ElTableColumn>
    <ElTableColumn prop="end_at" :label="$t('User.Gone')"></ElTableColumn>

    <ElTableColumn prop="actions" :label="$t('Base.Actions')" width="380px">
      <template #default="{ row }">
        <ElButton
          class="users-table-actions__edit-btn"
          type="primary"
          size="small"
          plain
          @click.stop="editProfile">
          {{ $t('Base.Edit') }}
        </ElButton>
        <ElButton
          class="users-table-actions__generate-key-button"
          link
          type="primary"
          size="small"
          text
          @click.stop="$emit('activateKey:generate', row)"
          >{{ $t('GenKey') }}
        </ElButton>
      </template>
    </ElTableColumn>
  </UiTableWithPagination>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { USER_ROUTE } from '@/router/users.routes.js';
import { MapStatusType } from '@/components/common/StatusTag/types';
import { EmployeeShortDto } from '@/types/api';
import noop from 'lodash.noop';

import StatusTag from '@/components/common/StatusTag/index.vue';
import * as icons from '@/enums/icons.enum.js';

export default defineComponent({
  name: 'UsersTable',
  components: { StatusTag },
  emits: ['update:perPage', 'update:page', 'activateKey:generate'],
  props: {
    loading: Boolean,
    items: { type: Array as () => EmployeeShortDto[], required: true },
    page: { type: Number, required: true },
    perPage: { type: Number, required: true },
    total: { type: Number, required: true },
  },
  setup: () => {
    const mapStatus: MapStatusType = {
      active: 'success',
      not_active: 'danger',
    };

    return {
      icons,
      mapStatus,
    };
  },
  methods: {
    goToUser(payload: EmployeeShortDto) {
      this.$router.push({
        name: USER_ROUTE.name,
        params: { id: payload.id },
      });
    },
    editProfile() {
      // not implemented
      noop();
    },
  },
});
</script>

<style lang="scss" src="./index.scss" />
<i18n src="./index.locales.json" />
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/user.locales.json" />
