<template>
  <ElDialog
    width="100%"
    class="outcome-crud-modal"
    :title="$t('Outcome.AddOutcome')"
    v-bind="$attrs"
    @update:model-value="$emit('update:modelValue', $event)">
    <FieldGroupWrapper :title="$t('Income.Goods')">
      <OutcomeProductsTableFillingForm @table:add="addProductInTable" />
      <OutcomeProductsTable
        :loading="loading.table"
        :items="tableData.products"
        @table:delete="deleteProductInTable" />
    </FieldGroupWrapper>
    <FormActionsWrapper align="right">
      <ElPopconfirm
        :disabled="isTableEmpty"
        :loading="loading.save"
        :title="$t('Ui.PopConfirm.DefaultTitle')"
        @confirm="onAcceptOutcomeBtnClick">
        <template #reference>
          <ElButton type="primary" plain :loading="loading.accept" :disabled="isTableEmpty">
            {{ $t('Outcome.AddOutcome') }}
          </ElButton>
        </template>
      </ElPopconfirm>
    </FormActionsWrapper>
  </ElDialog>
</template>

<script lang="ts">
export default {
  name: 'OutcomeCrudModal',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { Outcome } from '@/models/warehouse/Outcome.model';
import { OutcomeProductDto } from '@/types/api';
import { reactive, computed } from 'vue';
import { ElNotification } from 'element-plus';
import { I18nService } from '@/services/i18n.service';
import axios from 'axios';

import FormActionsWrapper from '@/components/common/form/FormActionsWrapper/index.vue';
import FieldGroupWrapper from '@/components/common/form/FieldGroupWrapper/index.vue';
import OutcomeProductsTable from './OutcomeProductsTable/index.vue';
import OutcomeProductsTableFillingForm from './OutcomeProductsTableFillingForm/index.vue';

const emit = defineEmits(['update:modelValue', 'action']);

const tableData = reactive<{ products: OutcomeProductDto[] }>({
  products: [],
});
const isTableEmpty = computed(() => {
  return tableData.products.length === 0;
});

const loading = reactive({
  data: false,
  save: false,
  accept: false,
  table: false,
});

const addProductInTable = (eventValue: OutcomeProductDto) => {
  tableData.products.push(eventValue);
};

const deleteProductInTable = (id: number) => {
  tableData.products = tableData.products.filter((f) => f.product_id !== id);
};

const closeModal = () => {
  emit('update:modelValue');
};

const onAcceptOutcomeBtnClick = async () => {
  loading.accept = true;
  if (tableData.products.length > 0) {
    try {
      const { data } = await Outcome.createItem({
        products: tableData.products,
      });
    } catch (err) {
      ElNotification({
        type: 'error',
        title: axios.isAxiosError(err) ? err.message : I18nService.t('Notifications.Error'),
      });
    } finally {
      closeModal();
      loading.accept = false;
    }
  } else {
    ElNotification({
      type: 'error',
      title: 'Заполните таблицу товаров',
    });
    loading.accept = false;
  }
};
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/notifications.locales.json" />
<i18n src="@/locales/validation.locales.json" />
<i18n src="@/locales/warehouse.locales.json" />
<i18n src="@/locales/ui.locales.json" />
