import { onlyLoggedInMiddleware } from '@/middlewares/onlyLoggedIn.middleware';
import { byUserRolesMiddleware } from '@/middlewares/byUserRoles.middleware';
import { User } from '@/models/User.model';

export const APPOINTMENTS_PATH_PREFIX = '/appointments';

export const APPOINTMENTS_ROUTE = {
  name: 'APPOINTMENTS',
  path: APPOINTMENTS_PATH_PREFIX,
  component: 'VAppointments',
  beforeEnter: [onlyLoggedInMiddleware],
  meta: {
    title: 'Base.PatientsRecords',
  },
};

export const HOME_VISIT_ROUTE = {
  name: 'HOME_VISIT',
  path: `${APPOINTMENTS_PATH_PREFIX}/home-visit`,
  component: 'VHomeVisit',
  beforeEnter: [onlyLoggedInMiddleware],
  meta: {
    title: 'Appointments.HomeVisit',
  },
};

export const APPOINTMENT_ROUTE = {
  name: 'APPOINTMENT',
  path: `${APPOINTMENTS_PATH_PREFIX}/:id`,
  component: 'VAppointment',
  beforeEnter: [onlyLoggedInMiddleware],
  meta: {
    title: 'Base.PatientRecord',
  },
  props: true,
  redirect: (route) => route.path + '/default-card',

  childrenMap: {
    APPOINTMENT_ROUTE_DEFAULT_CARD: {
      name: 'APPOINTMENT_DEFAULT_CARD',
      path: 'default-card',
      _fullPath: '/appointments/:id/default-card',
      component: 'VAppointmentDefaultCard',
    },
    APPOINTMENT_ROUTE_INSPECTION_CARD: {
      name: 'APPOINTMENT_INSPECTION_CARD',
      path: 'inspection-card',
      _fullPath: '/appointments/:id/inspection-card',
      component: 'VAppointmentInspectionCard',
      beforeEnter: [
        byUserRolesMiddleware([
          User.enum.roles.Doctor,
          User.enum.roles.Nurse,
          User.enum.roles.Laboratory,
          User.enum.roles.Manager,
          User.enum.roles.Director,
        ]),
      ],
    },
    APPOINTMENT_ROUTE_RECOMMENDATION: {
      name: 'APPOINTMENT_RECOMMENDATION',
      path: 'recommendation',
      _fullPath: '/appointments/:id/recommendation',
      component: 'VRecommendation',
      beforeEnter: [byUserRolesMiddleware([User.enum.roles.Doctor, User.enum.roles.Laboratory])],
    },
  },
  children: [], // утилита сама подставит сюда роуты из childrenMap
};

export const routes = [APPOINTMENTS_ROUTE, APPOINTMENT_ROUTE, HOME_VISIT_ROUTE];
