import axios from 'axios';
import { ApiService } from '@/services/api.service';
import { ElNotification } from 'element-plus';
import { MicrodistrictDto, MetaDto } from '@/types/api';
import { QueryParams } from '@/types/common';
import { deleteEmptyValueKeys } from '@/utils/object.util';
import { mergeOrCreateQuery } from '@/utils/http.util';

export class Microdistrict {
  static modelName = 'microdistrict';
  static tableName = 'microdistricts';

  static async getItems(query: QueryParams = {}) {
    try {
      const response = await ApiService.get<{ data: MicrodistrictDto[]; meta: MetaDto }>(
        mergeOrCreateQuery({
          url: `b2g/${this.tableName}`,
          query: deleteEmptyValueKeys(query),
        })
      );

      return {
        response: response,
        data: response.data,
        meta: response.data.meta,
      };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: axios.isAxiosError(err) ? err.message : String(err),
      });
    }
  }
}
