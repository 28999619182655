import { CRUDModel } from '@/models/CRUD.model';
import { ApiService } from '@/services/api.service';
import { mergeOrCreateQuery } from '@/utils/http.util';
import { deleteEmptyValueKeys } from '@/utils/object.util';

/**
 * @class Invoice
 * @extends CRUDModel
 */
export class Invoice extends CRUDModel {
  static modelName = 'invoice';
  static tableName = 'invoices';

  /**
   * @param {object} [payload]
   * @param {string} payload.type
   * @param {string} payload.status
   * @param {number} payload.discount
   * @param {number} payload.user_id
   * @param {User} payload.user
   * @param {number} payload.clinic_id
   * @param {Clinic} payload.clinic
   * @param {Array<number>} payload.appointments_ids
   * @param {Array<Appointment>} payload.appointments
   * @param {number} payload.reference_id
   * @param {AppointmentSource} payload.reference
   *
   * @param {Array<object>} payload.subjects  {group_service_id: number, count: number}, {service_id: number}
   * @param {Array<InvoicePaymentSubject>} payload.payment_subjects
   * @param {Array<number>} payload.transactions_ids
   * @param {Array<Transaction>} payload.transactions
   *
   * @param {string} payload.description
   * @param {User} payload.creator - создатель
   * @param {number} payload.creator_id - создатель id
   *
   * @param {number} payload.total_amount
   * @param {number} payload.discounted_amount
   * @param {number} payload.left_pay
   * @param {number} payload.total_discount
   * @param {string|Date} payload.created_at
   */
  constructor(payload) {
    super(payload);

    this.type = payload?.type ?? Invoice.enum.types.Custom;
    this.status = payload?.status ?? null;
    this.discount = payload?.discount ?? 0;
    this.user_id = payload?.user_id ?? null;
    this.user = payload?.user ?? null;
    this.clinic_id = payload?.clinic_id ?? null;
    this.clinic = payload?.clinic ?? null;
    this.appointments_ids = payload?.appointments_ids ?? [];
    this.appointments = payload?.appointments ?? [];
    this.reference_id = payload?.reference_id ?? null;
    this.reference = payload?.reference ?? null;

    // TODO: выпилить
    this.subjects = payload?.subjects || []; // only for create
    this.payment_subjects = payload?.payment_subjects ?? [];
    this.transactions_ids = payload?.transactions_ids ?? [];
    this.transactions = payload?.transactions ?? [];

    this.description = payload?.description ?? null;
    this.creator = payload?.creator ?? null;
    this.creator_id = payload?.creator_id ?? null;
    this.total_amount = payload?.total_amount ?? 0;
    this.discounted_amount = payload?.discounted_amount ?? payload?.total_amount ?? 0;
    this.left_pay = payload?.left_pay ?? 0;
    this.total_discount = payload?.total_discount ?? 0;
    this.created_at = payload?.created_at ?? null;
  }

  static exportDataURL = import.meta.env.VITE_API_URL + `exports/${this.tableName}`;

  /**
   * @param {object} payload
   * @return {Promise<{statistic: InvoiceStatistic, data: response.data, response: AxiosResponse<any>}>}
   */
  static async getStatistic(payload) {
    const object = await super.find({
      _url: `statistics/${this.tableName}`,
      ...payload,
    });

    return {
      response: object.response,
      data: object.data,
      statistic: object.data.data,
    };
  }

  /**
   * @param {string} startAt DATE_FORMAT from dateAndTime.config
   * @param {string} endAt DATE_FORMAT from dateAndTime.config
   * @return {Promise<{data: response.data, response: AxiosResponse<any>}>}
   */
  static async getAverageByPeriod({ startAt, endAt }) {
    const response = await ApiService.get(
      mergeOrCreateQuery({
        url: `statistics/${this.tableName}/average`,
        query: { start_at: startAt, end_at: endAt },
      })
    );

    return {
      response: response,
      data: response.data,
    };
  }

  /**
   * @param {string} startAt DATE_FORMAT from dateAndTime.config
   * @param {string} endAt DATE_FORMAT from dateAndTime.config
   * @return {Promise<{data: response.data, response: AxiosResponse<any>}>}
   */
  static async getIncomeByPeriod({ startAt, endAt }) {
    const response = await ApiService.get(
      mergeOrCreateQuery({
        url: `statistics/${this.tableName}/income`,
        query: { start_at: startAt, end_at: endAt },
      })
    );

    return {
      response: response,
      data: response.data,
    };
  }

  /**
   * @param {Object} query
   * @return {Promise<{data: response.data, response: AxiosResponse<any>}>}
   */
  static async getDataByDoctor(query) {
    const response = await ApiService.get(
      mergeOrCreateQuery({
        url: `${this.tableName}/doctor`,
        query: deleteEmptyValueKeys(query),
      })
    );

    return {
      response: response,
      data: response.data,
    };
  }

  /**
   * @param {Object} query
   * @return {Promise<{data: response.data, response: AxiosResponse<any>}>}
   */
  static async getStatisticByDoctor(query) {
    const response = await ApiService.get(
      mergeOrCreateQuery({
        url: `statistics/${this.tableName}/doctor`,
        query: deleteEmptyValueKeys(query),
      })
    );

    return {
      response: response,
      data: response.data,
    };
  }

  static enum = {
    statuses: {
      Paid: 'paid',
      PartiallyPaid: 'partially_paid',
      NotPaid: 'not_paid',
      Overpay: 'overpay',
      Refund: 'refund',
      Canceled: 'canceled',
      Created: 'created',
    },
    types: {
      Services: 'services',
      Treatment: 'treatment',
      Hospital: 'hospital',
      Custom: 'custom',
    },

    /** @typedef {object} InvoiceStatistic
     *  @property {number} count
     *  @property {number} amount
     *  @property {number} refund_amount
     */
    StatisticKeys: {
      Count: 'count',
      Amount: 'amount',
      AmountCash: 'amount_cash',
      AmountCard: 'amount_card',
      AmountOnline: 'amount_online',
    },
  };
}
