import { createStore } from 'vuex';
import { AnyObject } from '@/types/common';

import auth, { AuthState, AuthStore } from '@/store/auth/auth.store';
import analyticsStore from '@/store/analytics';
import appointmentsStore from '@/store/appointments';
import doctorsStore from '@/store/doctors';
import patients, { PatientsStore, PatientsState } from '@/store/patients/patients.store';
import laboratoryStore from '@/store/laboratory';
import patientStore from '@/store/patientGroups';
import patronageStore from '@/store/patronage';
import warehouseStore from '@/store/warehouse';
import accountingOutcomesTable from '@/store/accounting/accountingOutcomesTable.store';
import card, { CardStore, CardState } from '@/store/card/card.store';
import devices from '@/store/devices/devices.store';
import hospital from '@/store/hospital/hospital.store';
import invoices from '@/store/invoices/invoices.store';
import recommendations, {
  RecommendationsState,
  RecommendationsStore,
} from '@/store/recommendations/recommendations.store';
import salaries from '@/store/salaries/salaries.store';
import treatments from '@/store/treatments/treatments.store';
import user, { UserStore, UserState } from '@/store/user/user.store';
import users from '@/store/users/users.store';
import visitLog from '@/store/visitLog/users.store';
import modalAndDrawer, {
  ModalAndDrawerState,
  ModalAndDrawerStore,
} from '@/store/modalAndDrawer/modalAndDrawer.store';

export type RootStateType = {
  auth: AuthState;
  user: UserState;
  card: CardState;
  patients: PatientsState;
  modalAndDrawer: ModalAndDrawerState;
  recommendations: RecommendationsState;
  _modulesNamespaceMap: AnyObject;
};

export const Store = createStore<RootStateType>({
  state: {} as RootStateType,
  getters: {},

  mutations: {},
  actions: {
    // сбрасываем чтобы при смене аккаунта не было старых данных
    resetGlobalDataFromModules({ dispatch }) {
      const modules: string[] = [];
      for (const key in (Store as any)._modulesNamespaceMap) {
        if ((Store as any)._modulesNamespaceMap[key]._rawModule.isCRUDModule) modules.push(key);
      }

      return Promise.all(modules.map((module) => dispatch(`${module}clearAllData`)));
    },
  },
  modules: {
    card,
    auth,
    devices,
    hospital,
    invoices,
    accountingOutcomesTable,
    modalAndDrawer,
    patients,
    recommendations,
    salaries,
    treatments,
    user,
    users,
    visitLog,
    analyticsAnalysis: analyticsStore.analyticsAnalysis,
    analyticsDiagnoses: analyticsStore.analyticsDiagnoses,
    analyticsMicrodistricts: analyticsStore.analyticsMicrodistricts,
    analyticsPatronage: analyticsStore.analyticsPatronage,
    appointments: appointmentsStore.appointments,
    homeVisits: appointmentsStore.homeVisits,
    serviceGroups: appointmentsStore.serviceGroups,
    doctors: doctorsStore.doctors,
    specialties: doctorsStore.specialties,
    analyzes: laboratoryStore.analyzes,
    orders: laboratoryStore.orders,
    referencesGroups: laboratoryStore.referencesGroups,
    referencesValues: laboratoryStore.referencesValues,
    patientsList: patientStore.patientsList,
    patientGroupsDiagnoses: patientStore.patientGroupsDiagnoses,
    userPatronages: patronageStore.userPatronages,
    patronagesTable: patronageStore.patronagesTable,
    warehouses: warehouseStore.warehouses,
    goodsStock: warehouseStore.goodsStock,
    nomenclature: warehouseStore.nomenclature,
    outcomes: warehouseStore.outcomes,
    suppliers: warehouseStore.suppliers,
    incomes: warehouseStore.incomes,
  },
});

export type StoreType = AuthStore<Pick<RootStateType, 'auth'>> &
  UserStore<Pick<RootStateType, 'user'>> &
  CardStore<Pick<RootStateType, 'card'>> &
  ModalAndDrawerStore<Pick<RootStateType, 'modalAndDrawer'>> &
  PatientsStore<Pick<RootStateType, 'patients'>> &
  RecommendationsStore<Pick<RootStateType, 'recommendations'>>;

// Чтобы работала типизация для useStore используем эту useStore а не из 'vuex'
export function useStore(): StoreType {
  return Store as StoreType;
}
