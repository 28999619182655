<template>
  <LayoutDefault v-bind="$attrs">
    <template #header>
      <LaboratoryHeader />
    </template>

    <template #default>
      <slot></slot>
    </template>
  </LayoutDefault>
</template>

<script lang="ts">
export default {
  name: 'LayoutLaboratory',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script setup lang="ts">
import LayoutDefault from '@/components/layouts/LayoutDefault/index.vue';
import LaboratoryHeader from '@/components/laboratory/LaboratoryHeader/index.vue';
</script>
