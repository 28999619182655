<template>
  <LayoutByUserRole fix-height>
    <LayoutContentHeader>
      <template #actions>
        <ElButton type="primary" @click="createTemplate">
          <template #icon>
            <UiIcon :icon="icons.PLUS" />
          </template>
          {{ $t('Templates.AddTemplate') }}
        </ElButton>
      </template>
    </LayoutContentHeader>

    <TemplatesTable
      class="v-templates-content__table"
      :data="items"
      :loading="loading"
      :total="total"
      @update:tableItems="updateTemplates"
      v-model:page="page.value"
      v-model:per-page="perPage.value" />
  </LayoutByUserRole>
</template>

<script lang="ts">
export default {
  name: 'VTemplates',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { compareQueriesThenLoadData } from '@/utils/router.utils';
import { usePage, usePerPage } from '@/hooks/query';
import { GlobalModalCloseAction } from '@/models/client/ModalAndDrawer/GlobalModalCloseAction';
import { DOCTORS_TEMPLATE_ROUTE } from '@/router/doctors.routes';
import { Template } from '@/models/templates/Template.model';
import { TemplateDto } from '@/types/api/template';

import LayoutContentHeader from '@/components/layouts/assets/LayoutContentHeader/index.vue';
import LayoutByUserRole from '@/components/layouts/LayoutByUserRole/index.vue';
import TemplatesTable from '@/components/doctors/TemplatesTable/index.vue';
import CreateTemplateModal from '@/components/templates/modals/CreateTemplateModal.vue';
import * as icons from '@/enums/icons.enum';

const store = useStore();
const router = useRouter();
const page = usePage();
const perPage = usePerPage(99);

const query = computed(() => ({
  page: page.value,
  per_page: perPage.value,
}));
const items = ref<TemplateDto[]>([]);
const loading = ref(false);
const total = ref(0);

const getTemplates = async () => {
  loading.value = true;
  const response = await Template.getItems({
    per_page: perPage.value,
    page: page.value,
  });

  if (response) {
    const {
      data: { data: templates },
    } = response;
    items.value = templates;
    total.value = response.meta.total;
  }

  loading.value = false;
};

const createTemplate = async () => {
  const action = await store.dispatch('modalAndDrawer/openModal', CreateTemplateModal);

  if (!(action instanceof GlobalModalCloseAction)) {
    router.push({
      name: DOCTORS_TEMPLATE_ROUTE.name,
      params: { id: action.data.data.id },
    });
  }
};

const updateTemplates = (id: number) => {
  items.value = items.value.filter((item) => item.id !== id);
};

watch(
  query,
  (value, oldValue) => {
    compareQueriesThenLoadData({
      query: value,
      oldQuery: oldValue,
      getData: getTemplates,
      resetPage: page.reset,
    });
  },
  { deep: true, immediate: true }
);
</script>
<i18n src="@/locales/templates.locales.json" />
