<template>
  <ElDialog
    :title="$t('Laboratory.ReferencesGroup')"
    class="create-or-edit-references-group-modal"
    :model-value="modelValue"
    @update:model-value="$emit('update:modelValue', $event)">
    <ElForm
      id="create-or-edit-references-group-modal-form"
      class="create-or-edit-references-group-modal__content"
      label-position="top"
      @submit.prevent="submitHandler">
      <ElFormItem :label="$t('Base.NamingInRu')">
        <ElInput v-model="referencesGroup.title_ru" required />
      </ElFormItem>
      <ElFormItem :label="$t('Base.NamingInUz')">
        <ElInput v-model="referencesGroup.title_uz" required />
      </ElFormItem>

      <ElFormItem :label="$t('User.Gender')">
        <UiGenderSelect v-model="referencesGroup.gender" required />
      </ElFormItem>

      <ElFormItem :label="$t('User.StartAge')">
        <ElInput v-model="referencesGroup.start_age" type="number" min="0" max="150" required />
      </ElFormItem>
      <ElFormItem :label="$t('User.EndAge')">
        <ElInput v-model="referencesGroup.end_age" type="number" min="0" max="150" required />
      </ElFormItem>
    </ElForm>

    <template #footer>
      <ElButton
        type="primary"
        native-type="submit"
        form="create-or-edit-references-group-modal-form"
        :loading="loading">
        {{ referencesGroup.id ? $t('Base.Save') : $t('Base.Create') }}
      </ElButton>
    </template>
  </ElDialog>
</template>

<script>
import axios from 'axios';
import cloneDeep from 'lodash.clonedeep';
import { ReferencesGroup } from '@/models/laboratory/ReferencesGroup.model';
import { GlobalModalAction } from '@/models/client/ModalAndDrawer/GlobalModalAction';

import UiGenderSelect from '@/components/ui/UiGenderSelect/index.vue';

export default {
  name: 'CreateOrEditReferencesGroupsModal',
  components: { UiGenderSelect },
  emits: ['update:modelValue', 'action'],
  props: {
    modelValue: Boolean,
    /** @type {ReferencesGroup} */
    data: [ReferencesGroup, Object],
  },
  data() {
    return {
      loading: false,
      /** @type {ReferencesGroup} referencesGroup */
      referencesGroup: null,
    };
  },
  watch: {
    modelValue: {
      handler() {
        this.referencesGroup = new ReferencesGroup(this.data ? cloneDeep(this.data) : {});
      },
      immediate: true,
    },
  },

  methods: {
    async submitHandler() {
      if (this.loading) return;
      this.loading = true;

      try {
        const referencesGroup = this.referencesGroup.id ? await this.update() : await this.create();

        this.$notify({
          type: 'success',
          title: this.$t(`Notifications.Success${this.referencesGroup.id ? 'Updated' : 'Created'}`),
        });
        this.$emit(
          'action',
          new GlobalModalAction({
            name: 'created',
            data: { referencesGroup },
          })
        );
      } catch (err) {
        this.$notify({
          type: 'error',
          title: axios.isAxiosError(err) ? err.message : String(err),
        });
      }

      this.loading = false;
    },

    async create() {
      const { referencesGroup } = await ReferencesGroup.create(this.referencesGroup);
      return referencesGroup;
    },
    async update() {
      const { referencesGroup } = await ReferencesGroup.update({
        id: this.referencesGroup.id,
        payload: this.referencesGroup,
      });
      return referencesGroup;
    },
  },
};
</script>

<style lang="scss" src="./index.scss"></style>
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/notifications.locales.json" />
<i18n src="@/locales/laboratory.locales.json" />
<i18n src="@/locales/user.locales.json" />
