<template>
  <LayoutDoctor> Settings </LayoutDoctor>
</template>

<script>
import LayoutDoctor from '@/components/layouts/LayoutDoctor/index.vue';

export default {
  name: 'VSettings',
  components: { LayoutDoctor },
};
</script>

<style lang="scss" src="./index.scss" />
<i18n src="./index.locales.json" />
