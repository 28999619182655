<template>
  <div class="tiny-editor" :key="updateKey">
    <editor
      v-model="localContent"
      api-key="u4fdclope42us345do4ywvobk1ils3zwt1dawqasf5luzo0z"
      :disabled="disabled"
      :init="{
        height: 500,
        menubar: true,
        skin: false,
        content_css: false,
        plugins: [
          'lists',
          'advlist',
          'link',
          'table',
          'image',
          'preview',
          'pagebreak',
          'searchreplace',
          'code',
          'fullscreen',
          'insertdatetime',
          'help',
        ],
        branding: false,
        toolbar:
          'undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | pagebreak | print | help',
      }" />
  </div>
</template>

<script lang="ts">
export default {
  name: 'TinyEditor',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script setup lang="ts">
import { withDefaults, computed, ref, watch } from 'vue';
import Editor from '@tinymce/tinymce-vue';
import 'tinymce/tinymce';
import 'tinymce/themes/silver/theme.min';
import 'tinymce/models/dom/model.min';
import 'tinymce/icons/default/icons.min';
import 'tinymce/skins/ui/oxide/skin.css';

import 'tinymce/plugins/image';
import 'tinymce/plugins/media';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/link';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/code';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/help';
import 'tinymce/plugins/table';
import 'tinymce/plugins/pagebreak';

type Props = {
  content: string;
  disabled?: boolean;
};
const props = withDefaults(defineProps<Props>(), {
  content: '',
  disabled: false,
});
const emit = defineEmits(['update:content']);
const updateKey = ref(0);
const localContent = computed({
  get() {
    return props.content;
  },
  set(value) {
    emit('update:content', value);
  },
});

//  для форс апдейта редактора при изменении disabled
watch(
  () => props.disabled,
  () => {
    updateKey.value++;
  }
);
</script>

<style lang="scss" src="./index.scss"></style>
